import React from 'react'
import { array, bool, func, string, number, oneOfType } from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import FilledInput from '@material-ui/core/FilledInput'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { Button } from '@material-ui/core'
import sortByName from 'utils/sortByName'

// Assets
import styles from './styles'

const StyledSelect = withStyles(styles)(Select)

const MultipleSelect = ({
    items,
    filled,
    label,
    name,
    onChange,
    value,
    disabled,
    sorted = false,
    maxWidth = '100%',
    multiple,
}) => {
    let sortedItems = items
    if (sorted) {
        const [header, ...unsortedItems] = items
        sortedItems = [header, ...unsortedItems.sort((a, b) => sortByName(a.text, b.text))]
    }

    if (value === undefined) return <Typography>Loading...</Typography>
    return (
        <FormControl style={{ minWidth: `${200}px`, maxWidth: maxWidth }} variant={filled ? 'filled' : 'outlined'}>
            {!!label && (
                <InputLabel shrink={true} htmlFor={name}>
                    {label}
                </InputLabel>
            )}
            {multiple && (
                <StyledSelect
                    autoWidth={false}
                    input={filled ? <FilledInput id={name} /> : <Input id={name} />}
                    name={name}
                    onChange={e => onChange(e.target.value)}
                    value={value}
                    disabled={disabled}
                    multiple={multiple || false}
                    displayEmpty
                    renderValue={value => {
                        const count = value?.filter(element => {
                            return element.checked === true
                        }).length

                        if (count === 0) {
                            return '0 selected'
                        }
                        if (count > 1) {
                            return `${count} selected`
                        }
                        if (count === 1) {
                            const selItem = value.filter(item => item.checked === true)
                            if (name === 'platform') {
                                if (selItem.length > 0) return `${selItem[0].ptCarCode}`
                            }
                            if (name === 'lindaroles') {
                                if (selItem.length > 0) return `${selItem[0].lindaRoleName}`
                            }

                            return ''
                        }
                    }}
                >
                    {name === 'platform' &&
                        sortedItems.map(item => (
                            <MenuItem key={item.ptCarId} value={item.ptCarId} data-code={item.ptCarId} id={item.ptCarId}>
                                <Checkbox color="primary" checked={item.checked} />
                                <Typography noWrap={true}>{item.ptCarCode}</Typography>
                            </MenuItem>
                        ))}
                    {name === 'lindaroles' &&
                        sortedItems.map(item => (
                            <MenuItem
                                key={item.lindaRoleCode}
                                value={item.lindaRoleCode}
                                data-code={item.lindaRoleCode}
                                id={item.lindaRoleCode}
                            >
                                <Checkbox color="primary" checked={item.checked} />
                                <Typography noWrap={true}>{item.lindaRoleName}</Typography>
                            </MenuItem>
                        ))}

                    <Divider />
                    <MenuItem key={'button-clear'} value={'button-clear'} alignItems={'center'} button>
                        <Button
                            key={'button-clear'}
                            value={'button-clear'}
                            variant="contained"
                            color="secondary"
                            onClick={() => {}}
                        >
                            {'Clear'}
                        </Button>
                    </MenuItem>
                    <MenuItem key={'button-apply'} value={'button-apply'} alignItems={'center'} button>
                        <Button
                            key={'button-apply'}
                            value={'button-apply'}
                            variant="contained"
                            color="primary"
                            onClick={() => {}}
                        >
                            {'Save'}
                        </Button>
                    </MenuItem>
                </StyledSelect>
            )}
        </FormControl>
    )
}

MultipleSelect.propTypes = {
    items: array.isRequired,
    label: string,
    filled: bool,

    sorted: bool,
    name: string.isRequired,
    onChange: func,
    value: oneOfType([string, array, number]),
    disabled: bool,
    minWidth: number,
    maxWidth: string,
    multiple: bool,
    error: oneOfType([bool, string]),
}

export default MultipleSelect
