const DefaultSettings = [
    {
        pageReference: 'performanceOverview',
        refreshRate: 20,
        startHour: 1,
        endHour: 1,
        warning: 5,
        critical: 10,
        warningSignal: 10,
        isWarningSound: false,
    },
    {
        pageReference: 'taskDetail',
        refreshRate: 20,
        startHour: 1,
        endHour: 1,
        warning: 5,
        critical: 10,
        warningSignal: 10,
        isWarningSound: false,
    },
    {
        pageReference: 'readyForDeparture',
        refreshRate: 20,
        startHour: 1,
        endHour: 1,
        warning: 5,
        critical: 10,
        warningSignal: 10,
        isWarningSound: false,
    },
    {
        pageReference: 'trainTaskOverview',
        refreshRate: 20,
        startHour: 1,
        endHour: 1,
        warning: 5,
        critical: 10,
        warningSignal: 10,
        isWarningSound: false,
    },
    {
        pageReference: 'esemesOverview',
        refreshRate: 20,
        startHour: 1,
        endHour: 1,
        warning: 5,
        critical: 10,
        warningSignal: 10,
        isWarningSound: false,
    },
    {
        pageReference: 'monitoringTable',
        refreshRate: 60,
        startHour: 1,
        endHour: 1,
        warning: 5,
        critical: 10,
        warningSignal: 10,
        isWarningSound: false,
    }
]

export default DefaultSettings
