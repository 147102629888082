import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Typography, withStyles, Button } from '@material-ui/core'
import { withStore } from 'react-ion-store'
import { compose } from 'recompose'

import { withLocalize } from 'react-localize-redux'
import SelectInput from 'components/SelectInput'

//config
import TaskTypes from '../../../../config/taskTypes'
import { SelectPerformanceForTasks } from '../_shared/SelectPerformanceForTasks'
import TaskSelectList from '../TaskSelectList'
import styles from './styles'

export class SecondStep extends Component {
    static propTypes = {
        translate: PropTypes.func,
        persist: PropTypes.func,
        performanceNumbersData: PropTypes.array,
        taskType: PropTypes.string,
        selectedPerformanceNumberPt: PropTypes.string,
        classes: PropTypes.object,
        chooseFromOtherPerformance: PropTypes.bool,
        depotNamePt: PropTypes.string,
        getSelectedPerformanceDetails: PropTypes.func,
        selectedPerformanceDetails: PropTypes.object,
        selectedPerformanceDetailsLoading: PropTypes.bool,
        checkedTasks: PropTypes.array,
        handleCheckTasks: PropTypes.func,
        handleCheckAll: PropTypes.func,
        checkAll: PropTypes.bool,
        addMultipleTasksLoading: PropTypes.bool,
        chooseFromOtherPerformanceClick: PropTypes.func,
        driveRole: PropTypes.string,
        trainRoleValues: PropTypes.array,
        selectedJsonPerformance: PropTypes.any,
        selectedPerformanceForJson: PropTypes.array,
    }

    sortTaskTypes = data => {
        const { translate } = this.props

        const list = data.sort((a, b) => a.text.localeCompare(b.text))
        return [{ id: ' ', text: translate('performance_task_add_label_select_tasktype_select') }, ...list]
    }

    handleEvent = type => e => this.props.persist(type)(e.target.value)

    render() {
        const sortedTasktypes = this.sortTaskTypes(TaskTypes)

        const {
            taskType,
            classes,
            translate,
            chooseFromOtherPerformance,
            selectedPerformanceDetails,
            checkedTasks,
            handleCheckTasks,
            handleCheckAll,
            checkAll,
            addMultipleTasksLoading,
            chooseFromOtherPerformanceClick,
            driveRole,
            trainRoleValues,
            selectedJsonPerformance,
        } = this.props

        const isGroup11 =
            taskType.toUpperCase() === 'DRIVE' ||
            taskType.toUpperCase() === 'HLP' ||
            taskType.toUpperCase() === 'PERQUAI' ||
            taskType.toUpperCase() === 'UITGAR' ||
            taskType.toUpperCase() === 'TRANSFER'

        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant="h6">{translate('performance_task_add_label_select_tasktype')}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <div style={{ display: 'flex' }}>
                        <Grid item xs={12}>
                            <div style={{ display: 'inline-block' }}>
                                <span> {translate('label_task')}</span>
                                <SelectInput
                                    name="taskType"
                                    classes={classes}
                                    items={sortedTasktypes}
                                    value={taskType}
                                    onChange={this.handleEvent('taskType')}
                                />
                            </div>
                        </Grid>
                        {isGroup11 && (
                            <Grid item xs={12}>
                                <div style={{ display: 'inline-block' }}>
                                    <span> {translate('label_role')}</span>
                                    <SelectInput
                                        name="driveRole"
                                        classes={classes}
                                        items={trainRoleValues}
                                        value={driveRole}
                                        onChange={this.handleEvent('driveRole')}
                                    />
                                </div>
                            </Grid>
                        )}
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6">OF</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" color="primary" onClick={() => chooseFromOtherPerformanceClick()}>
                        {translate('performance_task_add_from_other')}
                    </Button>
                </Grid>
                <div className={classes.stepBorder} />

                {chooseFromOtherPerformance && <SelectPerformanceForTasks {...this.props} />}

                {selectedJsonPerformance !== '' && selectedPerformanceDetails && selectedPerformanceDetails.length !== 0 && (
                    <TaskSelectList
                        tasks={selectedPerformanceDetails.tasks}
                        checkedTasks={checkedTasks}
                        handleCheckTasks={handleCheckTasks}
                        handleCheckAll={handleCheckAll}
                        checkAll={checkAll}
                        addMultipleTasksLoading={addMultipleTasksLoading}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default compose(withStore, withLocalize, withStyles(styles))(SecondStep)
