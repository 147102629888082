export default theme => ({
    districtButton: {
        width: '100%',
    },
    districtBody: {
        width: '100%',
        padding: theme.spacing(2),
    },
    leftAlign: {
        textAlign: 'left',
    },
    iconWrapper: {
        textAlign: 'right',
    },
    icon: {
        fill: theme.customColors.iconFront,
        background: theme.customColors.iconBg,
    },
})
