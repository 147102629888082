import React, { useEffect, useState } from 'react'
import { string, object, func, bool, number, oneOfType } from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
//eslint-disable-next-line
import MusicController from 'modules/Performances/views/PerformanceOverview/components/PerformancesTable/components/MusicController'
import IconButton from 'components/IconButton'
import PerformancesFilter from '../PerformancesFilter'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import DateTimePickerModal from './DateTimePickerModal'
import SyncInfo from './SyncInfo'

import styles from './styles'
import { DateTime } from 'luxon'

const DateTimePicker = props => {
    DateTimePicker.propTypes = {
        identifier: string.isRequired,
        classes: object.isRequired,
        startValue: object.isRequired,
        translate: func.isRequired,
        persist: func.isRequired,
        filterValue: string,
        handleFilterValue: func,
        disabled: bool,
        disableFuture: bool,
        toDate: oneOfType([string, number]),
        fromDate: oneOfType([string, number]),
        toggleRealTime: func,
        realTime: bool,
        performancesIsLoading: bool,
        colorValues: object,
        syncData: object,
        refreshPerformances: func,
        storage: object,
        trainView: bool,
        syncInfoRequired: bool,
        validityId: number,
        hideMusicController: bool,
    }

    const [openPicker, setOpenPicker] = useState(false)
    const [start, setStart] = useState(props.startValue)
    const [isTablet, setIsTablet] = useState(window.matchMedia('(max-width:1025px)').matches)

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const isDeviceTablet = () => window.matchMedia('(max-width:1025px)').matches
    const handleResize = () => {
        setIsTablet(isDeviceTablet())
    }

    const setTime = time => {
        if (!isNaN(time) && time) {
            setStart(time)
            props.persist('time')(time)
        }
    }
    const onPickerClose = () => {
        props.persist('time')(DateTime.local())
        setOpenPicker(false)
    }
    const onPickerOk = () => {
        setOpenPicker(false)
        props.persist('date')(start)
    }

    const {
        identifier,
        classes,
        translate,
        disabled,
        disableFuture,
        filterValue,
        handleFilterValue,
        colorValues,
        toDate,
        fromDate,
        syncData,
        toggleRealTime,
        realTime,
        performancesIsLoading,
        startValue,
        refreshPerformances,
        storage,
        trainView,
        syncInfoRequired = true,
        hideMusicController,
    } = props

    return (
        <div
            className={
                identifier === 'performanceOverview' ||
                identifier === 'trainTaskOverview' ||
                identifier === 'readyForDeparture' ||
                identifier === 'vehicleDetails' ||
                identifier === 'brakingPulledTrain' ||
                identifier === 'esemesOverview'
                    ? classes.setDWidth
                    : classes.setTWidth
            }
            data-testid="root"
        >
            {identifier === 'performanceOverview' && (
                <PerformancesFilter
                    filterValue={filterValue}
                    handleFilterValue={handleFilterValue}
                    colorValues={colorValues}
                />
            )}

            <DateTimePickerModal
                handleClose={onPickerClose}
                open={openPicker}
                onChange={setTime}
                value={startValue}
                disableFuture={disableFuture}
                handleOk={onPickerOk}
            />

            <div
                style={
                    identifier !== 'performanceOverview' ||
                    identifier !== 'esemesOverview' ||
                    identifier === 'trainTaskOverview'
                        ? { display: 'flex', justifyContent: 'space-between', width: '100%' }
                        : { display: 'flex' }
                }
                data-testid="container"
            >
                {syncInfoRequired && (
                    <SyncInfo
                        identifier={identifier}
                        syncData={syncData}
                        translate={translate}
                        fromDate={fromDate}
                        toDate={toDate}
                        startValue={startValue}
                        validityId={props.validityId}
                    />
                )}
                <div data-testid="child-1">
                    <IconButton
                        size={isTablet ? 'small' : 'medium'}
                        style={{ marginLeft: '8px' }}
                        className={classes.button}
                        color={'primary'}
                        icon="event"
                        title={translate('button_change')}
                        onClick={() => setOpenPicker(true)}
                        disabled={disabled}
                        data-testid="iconButton"
                    />
                    {(identifier === 'performanceOverview' ||
                        identifier === 'trainTaskOverview' ||
                        identifier === 'readyForDeparture' ||
                        identifier === 'esemesOverview' ||
                        identifier === 'brakingPulledTrain' ||
                        identifier === 'vehicleDetails' ||
                        identifier === 'esemesOverview') && (
                        <React.Fragment>
                            <IconButton
                                size={isTablet ? 'small' : 'medium'}
                                style={
                                    isTablet
                                        ? { minHeight: '40px', marginTop: '10px', display: 'block' }
                                        : { minHeight: '40px' }
                                }
                                className={realTime ? classes.realTimeButtonActive : classes.realTimeButton}
                                icon=""
                                title="RealTime"
                                onClick={() => toggleRealTime('button')}
                                disabled={performancesIsLoading}
                                data-testid="iconButton"
                            />

                            {!trainView && (
                                <IconButton
                                    style={
                                        isTablet
                                            ? { minHeight: '40px', marginTop: '10px', marginLeft: 10, display: 'block' }
                                            : { minHeight: '40px', marginLeft: 10 }
                                    }
                                    className={classes.button}
                                    icon="refresh"
                                    title={translate('performances_refresh')}
                                    onClick={refreshPerformances}
                                    disabled={
                                        performancesIsLoading ||
                                        checkFunctionalityDisabled(
                                            storage,
                                            'refresh',
                                            'performances.functionality.management.performances'
                                        )
                                    }
                                    data-testid="iconButton"
                                />
                            )}
                            {!trainView && !hideMusicController && <MusicController identifier={identifier} />}
                            {identifier === 'readyForDeparture' && !isTablet && !hideMusicController && (
                                <span style={{ marginLeft: '3px' }}>
                                    <MusicController identifier={identifier} />
                                </span>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(DateTimePicker)
