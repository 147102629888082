import React from 'react'
import { object, bool, func, string, array } from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'

import IconButton from 'components/IconButton'
import PersonSearcher from 'components/PersonSearcher'
import UserListForGroup from './UserListForGroup'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import { Grid, TextField, Dialog, DialogTitle, Paper, Box } from '@material-ui/core'

const getOrgsName = selectedProfile => {
    return selectedProfile?.organisations?.map(org => org.organisationCode).join()
}
const AddNewUserDrawerView = ({
    classes,
    open,
    onClose,
    selectedName,
    selectedProfile,
    setName,
    clearName,
    translate,
    addNewUserCall,
    handleDeleteUser,
    userListForGroupId,
    addNewUserCallLoading,
    addNewUserCallError,
    userGroupName,
    existingUserId,
    enforce,
    setEnforce,
    setErrorMessage,
    isUserListLoading,
}) => {
    let orgs = ''
    if (selectedProfile) {
        orgs = getOrgsName(selectedProfile)
    }
    return (
        <Drawer open={open} anchor="right" style={{ zIndex: 98 }} onClose={onClose}>
            <div className={classes.drawerRoot}>
                <div>
                    <Typography variant="h6" style={{ marginBottom: 24 }}>
                        {translate('edit_user_group')}
                    </Typography>
                    <TextField value={userGroupName} label={translate('group_name_label')} disabled={true} />

                    <Typography style={{ marginTop: 16 }} variant="h6">
                        {translate('add_user_label')}
                    </Typography>
                    <Typography variant="h6">{translate('districts_read_label_person')}</Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <div style={{ position: 'relative' }} name="PersonSearcherWrapper" tabIndex={0}>
                        <PersonSearcher {...{ selectedName, setName, clearName }} />
                    </div>

                    {selectedName && (
                        <div className={classes.profileInfo}>
                            <Grid container>
                                <Grid item xs={4}>
                                    {`${translate('search_label_name')}${' :'}`}
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">
                                        {`${selectedProfile.firstName} ${selectedProfile.lastName}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    {`${translate('search_label_idf')}${' :'}`}
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">{selectedProfile.idfNumber}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    {`${translate('search_label_org')}${' :'}`}
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">{orgs}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    {`${translate('search_label_user_name')} :`}
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">{selectedProfile.userName}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                {!existingUserId && !enforce ? (
                                    <Grid item xs={12}>
                                        <IconButton
                                            className={classes.button}
                                            icon="person_add"
                                            title={translate('icon_button_add_user')}
                                            disabled={addNewUserCallLoading}
                                            onClick={() => {
                                                addNewUserCall(false)
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                                {enforce ? (
                                    <Dialog
                                        open={enforce}
                                        classes={{
                                            paper: classes.root,
                                        }}
                                    >
                                        <Paper elevation={0} square>
                                            <DialogTitle classes={{ root: classes.dialogTitle }}>
                                                {translate('user_already_assigned_to_another_user_group')}
                                            </DialogTitle>
                                            <Typography>{translate('transfer_user_to_other_group')}</Typography>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <IconButton
                                                        className={classes.button}
                                                        title={translate('button_keep_user')}
                                                        onClick={() => {
                                                            setEnforce(false)
                                                            setErrorMessage('')
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <IconButton
                                                        className={classes.button}
                                                        title={translate('button_force_user')}
                                                        onClick={() => {
                                                            addNewUserCall(true)
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Dialog>
                                ) : null}
                            </Grid>
                            {existingUserId && (
                                <IconButton
                                    color="secondary"
                                    className={classes.button}
                                    icon="person"
                                    title={translate('delete_user_label')}
                                    disabled={addNewUserCallLoading}
                                    onClick={() => {
                                        handleDeleteUser(existingUserId)
                                    }}
                                />
                            )}
                            {addNewUserCallError.length > 0 && <ErrorMessage spacing={false} error={addNewUserCallError} />}
                        </div>
                    )}
                    {!addNewUserCallLoading && !isUserListLoading ? (
                        <UserListForGroup
                            handleDeleteUser={handleDeleteUser}
                            userData={userListForGroupId}
                            translate={translate}
                            classes={classes}
                            addNewUserCallLoading={addNewUserCallLoading}
                        />
                    ) : (
                        <Box xs={{ margin: '0px auto', width: '200px' }}>
                            <Loading />
                        </Box>
                    )}
                    {addNewUserCallLoading && <p>Loading...</p>}
                </div>
            </div>
        </Drawer>
    )
}

AddNewUserDrawerView.propTypes = {
    classes: object.isRequired,
    open: bool.isRequired,
    addNewUserCallLoading: bool,
    onClose: func.isRequired,
    selectedName: string.isRequired,
    addNewUserCallError: string,
    selectedProfile: object,
    setName: func.isRequired,
    clearName: func.isRequired,
    translate: func,
    addNewUserCall: func,
    handleDeleteUser: func,
    addNewUserToGroupId: string,
    userGroupName: string,
    userListForGroupId: array,
    existingUserId: string,
    enforce: bool.isRequired,
    setEnforce: func.isRequired,
    setErrorMessage: func.isRequired,
    isUserListLoading: bool.isRequired,
}

export default AddNewUserDrawerView
