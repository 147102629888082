import React, { useState } from 'react'
import PropTypes from 'prop-types'

import deletePneumaticSuspension from '../Services/deletePneumaticSuspension'
import DeleteDialog from 'components/DeleteDialog'
import { TranslationContext } from 'context/translation'

const DeletePneumaticDialog = props => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const { translate } = React.useContext(TranslationContext)

    const handleDeleteClick = async () => {
        setLoading(true)
        let response = await deletePneumaticSuspension(deleteData.id)
        if (response.status === 200) {
            reloadPneumaticData()
            onDialogClose()
        } else {
            setError(true)
            setLoading(false)
        }
    }

    const { onDialogClose, dialogOpen, deleteData, reloadPneumaticData } = props
    return (
        <DeleteDialog
            dialogOpen={dialogOpen}
            onClose={onDialogClose}
            title={translate('compositions_delete_pneumatic_suspension_dialog_title')}
            translate={translate}
            handleDelete={handleDeleteClick}
            deleteMsg={translate('compositions_delete_pneumatic_suspension_dialog_confirm_text')}
            error={error}
            errorMsg={translate('error_msg_something_went_wrong')}
            deleteInfo={deleteData.id}
            loading={loading}
        />
    )
}
DeletePneumaticDialog.propTypes = {
    onDialogClose: PropTypes.func,
    reloadPneumaticData: PropTypes.func,
    dialogOpen: PropTypes.bool,
    deleteData: PropTypes.object,
}
export default DeletePneumaticDialog
