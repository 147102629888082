export default theme => ({
    /* PerformanceView.js */
    button: {
        marginRight: theme.spacing(1),
    },

    /* components/AssignDrawer.js */
    drawerRoot: {
        padding: theme.spacing(2),
        width: '500px',
    },

    /* components/PerformancesByDay.js */
    performancesRoot: {
        marginBottom: theme.spacing(3),
    },
    performancesContainer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(3),
        '@media (max-width: 577px)': {
            fontSize: '0.5rem',
        },
    },
})
