import axios from 'axios'
const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export const updatePtdesData = async (ptdesId, data, loadPtdes, onClose, setError, translate) => {
    const { url, subscriptionKey } = requestData
    let fullUrl = `${url}/legacyReferenceData/ptdes/${ptdesId}/update`

    try {
        const response = await axios.post(fullUrl, data, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })

        if (response.status === 204) {
            onClose()
        }
        if (response.status === 200) {
            onClose()
            loadPtdes()
        }
    } catch (e) {
        setError(translate('edit_ptdes_error'))
        console.error(e)
    }
}

export default updatePtdesData
