import React from 'react'
import { object, string, func, bool } from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import axios from 'axios'

// Components
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import IconButton from 'components/IconButton'
import AppPaper from 'components/AppPaper'
import RemarkDialog from './RemarkDialog'
import ErrorMessage from 'components/ErrorMessage'
import StyledTooltip from 'components/StyledTooltip'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

class RemarkFooterView extends React.Component {
    static propTypes = {
        classes: object.isRequired,
        remark: string,
        note: string,
        handleNoteClose: func,
        handleNoteOpen: func,
        translate: func,
        refreshAfterBulkOperation: func,
        performanceId: string,
        idfNumber: string,
        match: object,
        history: object,
        location: object,
        storage: object,
        noteIsOpen: bool,
        signOutTime: string,
    }

    state = {
        isOpen: false,
        note: this.props.note,
        errorMsg: '',
        noteValue: this.props.note !== null ? this.props.note : '',
    }

    onChangeNote = e => {
        this.setState({ noteValue: e.target.value })
    }

    handleClose = () => {
        const { handleNoteClose } = this.props
        handleNoteClose()
        this.setState({ noteValue: this.state.note, errorMsg: '' })
    }
    handleOpen = () => {
        const { handleNoteOpen } = this.props

        handleNoteOpen()
    }

    addNote = async () => {
        const { noteValue } = this.state
        const { performanceId, idfNumber, translate } = this.props

        const { url, subscriptionKey } = requestData
        const fullUrl = `${url}/performance/${performanceId}/setnote/${idfNumber}`

        try {
            const response = await axios.post(fullUrl, {
                Note: noteValue,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            //set state hier
            if (response.status === 200) {
                this.props.refreshAfterBulkOperation()
            }
        } catch (e) {
            if (e.response.status === 400) {
                this.setState({ errorMsg: translate('roles_error') })
            } else {
                this.setState({ errorMsg: 'Request failed' })
            }
        }
    }

    render() {
        const { classes, match, history, location, storage, noteIsOpen, signOutTime } = this.props
        const { errorMsg, note, noteValue } = this.state

        return (
            <AppPaper className={classes.root}>
                <Translate>
                    {({ translate }) => (
                        <React.Fragment>
                            <StyledTooltip
                                placement="top"
                                disableFocusListener
                                title={note || translate('performance_info_label_default_remark')}
                            >
                                <div className={classes.errormsg}>
                                    <IconButton
                                        icon="info"
                                        color="secondary"
                                        title={
                                            <span className={classes.shortenText}>
                                                {note || translate('performance_info_label_default_remark')}
                                            </span>
                                        }
                                        onClick={() => {
                                            this.handleOpen()
                                        }}
                                        disabled={
                                            signOutTime !== null ||
                                            checkFunctionalityDisabled(
                                                storage,
                                                'driverMessageEdit',
                                                'performances.functionality.management.performanceDetail'
                                            )
                                        }
                                    />
                                    {errorMsg && errorMsg.length !== 0 && <ErrorMessage error={errorMsg} />}
                                </div>
                            </StyledTooltip>
                            <RemarkDialog
                                isOpen={noteIsOpen}
                                handleClose={this.handleClose}
                                onChangeNote={this.onChangeNote}
                                addNote={this.addNote}
                                noteValue={noteValue}
                            />
                            <IconButton
                                data-testid="performance-add-task-button"
                                className={classes.button}
                                icon="note_add"
                                title={translate('PerformanceAddTask')}
                                onClick={() => {
                                    history.push(`${match.url}/add/task`, { prevPath: location.pathname })
                                }}
                                disabled={
                                    signOutTime !== null ||
                                    checkFunctionalityDisabled(storage, 'add', 'performances.functionality.management.tasks')
                                }
                            />
                        </React.Fragment>
                    )}
                </Translate>
            </AppPaper>
        )
    }
}

export default withRouter(RemarkFooterView)
