import axios from 'axios'

export default async id => {
    let fullUrl = `/referencedata/platforms/${id}`

    try {
        return await axios.get(fullUrl)
    } catch (error) {
        console.error(error)
        return error.response
    }
}
