import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, Typography } from '@material-ui/core'
import classNames from 'classnames'
import BrakingDetailsListView from './BrakingDetailsListView'
import { Translate } from 'react-localize-redux'

const columns = [
    {
        width: 250,
        label: 'brakingpulled_datatable_min',
        dataKey: 'minimumCoaches',
    },
    {
        width: 250,
        label: 'brakingpulled_datatable_max',
        dataKey: 'maximumCoaches',
    },
    {
        width: 250,
        label: 'brakingpulled_datatable_min_per',
        dataKey: 'minimumBrakePercentage',
    },
    {
        width: 250,
        label: 'brakingpulled_datatable_max_per',
        dataKey: 'maximumBrakePercentage',
    },
    {
        width: 250,
        label: 'brakingpulled_datatable_validity',
        dataKey: 'maximumSpeed',
    },
]
const BrakingDetailsView = props => {
    const headerRenderer = headerProps => {
        const { classes, headerHeight } = props
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: headerHeight,
                    paddingTop: 34,
                    paddingBottom: 34,
                    paddingRight: 0,
                }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }
    const cellRenderer = cellProps => {
        const { classes } = props
        const { dataKey, rowData } = cellProps

        let value
        if (dataKey === 'minimumCoaches') {
            value = rowData.minimumCoaches
        }
        if (dataKey === 'maximumCoaches') {
            value = rowData.maximumCoaches
        }

        if (dataKey === 'minimumBrakePercentage') {
            value = rowData.minimumBrakePercentage
        }
        if (dataKey === 'maximumBrakePercentage') {
            value = rowData.maximumBrakePercentage
        }
        if (dataKey === 'maximumSpeed') {
            value = rowData.maximumSpeed
        }
        return (
            <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: props.rowHeight }}>
                <Typography>{value}</Typography>
            </TableCell>
        )
    }
    const getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }
    return (
        <BrakingDetailsListView
            columns={columns}
            data={props.vehicleData}
            classes={props.classes}
            headerRenderer={headerRenderer}
            cellRenderer={cellRenderer}
            getRowClassName={getRowClassName}
        />
    )
}
BrakingDetailsView.propTypes = {
    vehicleData: PropTypes.arrayOf(PropTypes.object),
    classes: PropTypes.object,
    rowClassName: PropTypes.object,
    onRowClick: PropTypes.func,
    headerHeight: PropTypes.number,
    rowHeight: PropTypes.number,
}

export default BrakingDetailsView
