/* eslint-disable max-len */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TableSortLabel } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { UnfoldMore } from '@material-ui/icons'
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person'

import SelectInput from 'components/SelectInput'

export function FeatureList(props) {
    const [sortActive, setSortActive] = useState(false)
    const [sortOrder, setSortOrder] = useState('asc')

    const handleAddPersonOrOrganisation = (e, featureId) => {
        const { setFeatureIdAndOpenDrawer } = props
        e.stopPropagation()
        setFeatureIdAndOpenDrawer(featureId)
    }

    const handleSort = () => {
        if (!sortActive) {
            setSortOrder('desc')
            setSortActive(true)
        }
        sortOrder === 'desc' ? setSortOrder('asc') : setSortOrder('desc')
    }
    const SortArray = order => (x, y) => {
        const collator = new Intl.Collator('en')
        if (order === 'asc') return collator.compare(x.feature, y.feature)
        else return collator.compare(y.feature, x.feature)
    }

    const sortData = () => {
        if (!featureData) return []
        if (sortActive) {
            if (sortOrder === 'asc') return featureData.sort(SortArray('asc'))
            else return featureData.sort(SortArray('desc'))
        }
        return featureData
    }

    const { classes, translate, featureData} = props
    const sortedData = Array.isArray(featureData) ? sortData() : []

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '25%', cursor: 'pointer' }} onClick={() => handleSort()}>
                            <TableSortLabel active={sortActive} direction={sortOrder}>
                                {translate('mobile_features_name')}
                                {!sortActive && <UnfoldMore fontSize={'small'} />}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: '25%' }}>{translate('mobile_features_status')}</TableCell>
                        <TableCell style={{ width: '25%' }} />
                        <TableCell style={{ width: '25%' }} align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData &&
                        sortedData.map(item => (
                            <React.Fragment key={item.id}>
                                <TableRow
                                    style={{ height: '56px' }}
                                    key={item.id}
                                >
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            className={classes.underline}
                                        >{item.feature}</Typography>
                                    </TableCell>
                                    <TableCell>
                                         {item.status > 1 ?
                                            <div style={{ display: 'inline-block' }}>
                                                <SelectInput
                                                    sorted
                                                    name="status"
                                                    items={[{ id: 2, text: `${translate('mobile_features_status_disabled')}` },
                                                            { id: 3, text: `${translate('mobile_features_status_limited')}` },
                                                            { id: 4, text: `${translate('mobile_features_status_active')}`}]}
                                                    value={item.status}
                                                    onChange={(e) => props.updateStatus(item.id, e.target.value)}
                                                />
                                            </div>
                                            :   <div style={{ display: 'inline-flex', alignItems: 'center', margin: 'auto'}}>
                                                <Typography style={{margin: '6px', marginLeft: '0px'}} variant="body1">{translate('mobile_features_status_in_development')}</Typography>
                                                <LockIcon></LockIcon>
                                                </div>
                                            }
                                        
                                    </TableCell>
                                    <TableCell />
                                    <TableCell align="right">
                                        {item.status == 3 &&
                                        
                                         <PersonIcon
                                            className={classes.actionIcon}
                                            style={{pointerEvents: 'auto',}}
                                            onClick={e => {
                                                handleAddPersonOrOrganisation(e, item.id)
                                            }}
                                        /> 
                                        }
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                </TableBody>
            </Table>
        </div>
    )
}
FeatureList.propTypes = {
    classes: PropTypes.object,
    store: PropTypes.object,
    OrganisationsData: PropTypes.array,
    translate: PropTypes.func,
    setFeatureIdAndOpenDrawer: PropTypes.func,
    featureData: PropTypes.array,
    updateStatus: PropTypes.func
}
export default FeatureList
