import React from 'react'

import { object } from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import { Divider, ListItemIcon, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import getTitle from '../../util'

import styles from './styles'
import FeedbackCategoryIcon from '../FeedbackCategoryIcon'
import { feedbackCategoriesContext } from 'modules/FeedbackCategories/store.feedbackCategories'
import { TranslationContext } from 'context/translation'

const FeedbackCategoryLevelOne = props => {
    const { classes, data } = props

    const { METHODS } = React.useContext(feedbackCategoriesContext)
    const { translate, activeLanguage } = React.useContext(TranslationContext)

    const handleClick = (e, id) => {
        METHODS.openDrawer(e, '', id)
    }
    return (
        <div data-testid="feedback_level_one" style={{ paddingLeft: '32px', paddingBottom: '8px' }}>
            <List>
                {data &&
                    data.subCategories.map(feedback => (
                        <React.Fragment key={feedback.id}>
                            <Paper square elevation={0}>
                                <ListItem>
                                    <Typography color={'secondary'}>{feedback.weight}</Typography>

                                    <ListItemText primary={getTitle(activeLanguage, feedback.description)} />

                                    <ListItemIcon>
                                        <FeedbackCategoryIcon
                                            signed={feedback.signed}
                                            isFirstDeparture={feedback.isFirstDeparture}
                                            e360={feedback.e360}
                                        />
                                    </ListItemIcon>
                                    <EditIcon
                                        className={classes.editIcon}
                                        onClick={e => METHODS.openDrawer(e, feedback, data.id)}
                                    />
                                </ListItem>
                            </Paper>

                            <Divider className={classes.divider}></Divider>
                        </React.Fragment>
                    ))}
            </List>
            <Button variant="contained" color="primary" onClick={e => handleClick(e, data.id)}>
                {translate('add_feedback_sub_category')}
            </Button>
        </div>
    )
}

FeedbackCategoryLevelOne.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
}
export default withStyles(styles)(FeedbackCategoryLevelOne)
