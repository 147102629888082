import React from 'react'
import { Container, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import ListHeaderStyles from './ListHeaderStyles'
import PropTypes from 'prop-types'
import { ESemesContext } from 'modules/eSemes/esemes.store'
import { Translate } from 'react-localize-redux'

const ListHeader = ({ classes }) => {
    const { METHODS, STATE } = React.useContext(ESemesContext)

    const sortForTasks = messages => {
        return messages.filter(el => {
            const allDistrictsForMessage = el.profileInfo
                .map(f => [f.districtDescription[0].translation, f.districtDescription[1].translation])
                .flat()
            const s = allDistrictsForMessage.map(el => STATE.filterState.district.includes(el))
            return s.includes(true)
        })
    }

    const Messages = STATE.filterState.dataFilter.length > 0 ? STATE.filterState.filterdResults : STATE.apiState.eSemesData

    return sortForTasks(Messages).length > 0 ? (
        <Container maxWidth="xl" className={classes.container} style={{ maxWidth: 'calc(100% - 34px)', margin: '0' }}>
            <Typography
                onClick={() => {
                    METHODS.toggleSortOrder('type')
                }}
                className={classes.title}
                style={{ cursor: 'pointer' }}
            >
                <Translate id={'datatable_esemes_type'} />
            </Typography>
            <Typography
                onClick={() => {
                    METHODS.toggleSortOrder('line')
                }}
                className={classes.title}
                style={{ cursor: 'pointer' }}
            >
                <Translate id={'datatable_esemes_line'} />
            </Typography>
            <Typography>
                <span className={classes.title}>
                    <Translate id="datatable_esemes_number" />
                </span>
                <br></br>
                <Translate id="datatable_esemes_time" />
            </Typography>
            <div></div>
            {STATE.filterState.hasTaskApplicable && (
                <>
                    <Typography className={classes.title}>
                        <Translate id={'semes_received'} />
                    </Typography>
                    <Typography className={classes.title}>
                        <Translate id={'semes_read'} />
                    </Typography>
                </>
            )}
        </Container>
    ) : (
        <p className={classes.error}>
            <Translate id={'semes_no_messages'} />
        </p>
    )
}

ListHeader.propTypes = {
    classes: PropTypes.object,
}

export default withStyles(ListHeaderStyles)(ListHeader)
