export default theme => ({
    container: {
        marginLeft: '12px',
        display: 'flex',
        alignItems: 'center',
        padding: '16px',
    },
    readList: {
        maxHeight: `100%`,
        overflowY: 'auto',
        marginBottom: theme.spacing(2),
    },
    profileInfo: {
        marginTop: theme.spacing(2),
    },
    profileInfoTitle: {
        fontWeight: 'bold',
    },
    topSpacingTitle: {
        marginTop: theme.spacing(3),
    },
    chip: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'inline-block',
    },
})
