export default theme => ({
    tableRow: {
        backgroundColor: 'transparent',
    },
    header: {
        backgroundColor: theme.customColors.lightGrey,
    },

    tableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
    },
    documentTableWrapper: {
        width: '100%',
        height: '55vh',
    },
    table: {
        fontFamily: theme.typography.fontFamily,
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    // tableCell: {
    //     flex: 1,
    //     wordWrap: 'break-word',
    //     borderColor: theme.customColors.grey,
    // },
    noClick: {
        cursor: 'initial',
    },
    tableCellRed: {
        backgroundColor: theme.customColors.darkRed,
    },
    tableRowRed: {
        backgroundColor: theme.customColors.darkRed,
    },
    documentDetailCell: {
        // maxWidth: 'calc(100vw / 3)',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        wordWrap: 'break-word',
        borderColor: theme.customColors.grey,
    },
    detailViewTitle: {
        marginBottom: theme.spacing(3),
    },
})
