import React from 'react'
import { compose } from 'recompose'

import Loading from 'components/Loading'

const callKey = 'performances'
const storeKeys = [callKey, 'mappedDistrictData', 'selectedDistrict', 'selectedTCTs', 'selectedDepot']

const withDistrictCheck = Component => props =>
    // eslint-disable-next-line react/prop-types
    props.store.get('selectedDistrict') ? <Component {...props} /> : <Loading />

const withTctData = Component => props => {
    // eslint-disable-next-line react/prop-types
    const { mappedDistrictData, selectedDistrict } = props.store.get(storeKeys)
    const filteredDistrictData = mappedDistrictData
        ? mappedDistrictData.find(districtData => {
              return districtData && districtData.id === selectedDistrict
          })
        : {}

    const tcts = filteredDistrictData && filteredDistrictData.tcts ? filteredDistrictData.tcts : []

    return <Component tcts={tcts} {...props} />
}

export default compose(withDistrictCheck, withTctData)
