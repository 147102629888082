import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus, withCall, enums } from 'react-ion-store'

import ClearCacheButtonView from './ClearCacheButtonView'

const {
    CallStatus: { READY },
} = enums

const callKey = 'documentsClear'

const ClearCacheButton = ({ __ion_status, clearCacheWasSet, idf, ...props }) => {
    const { documentsClearActions } = __ion_status
    const { documentsClear } = props.store.get()

    const disabled = !idf || clearCacheWasSet || documentsClearActions !== READY || typeof documentsClear !== 'undefined'

    return <ClearCacheButtonView {...props} disabled={disabled} />
}

ClearCacheButton.propTypes = {
    clearCacheWasSet: PropTypes.bool.isRequired,
    idf: PropTypes.string,
    deviceId: PropTypes.string,
    __ion_status: PropTypes.object.isRequired,
    store: PropTypes.object,
}

/* eslint-disable react/prop-types */
const withConditionalCall = Component => props => {
    withConditionalCall.propTypes = {
        clearCacheWasSet: PropTypes.bool,
        deviceId: PropTypes.string,
        idf: PropTypes.string,
    }
    if (props.clearCacheWasSet) {
        return withCall(callKey, {
            config: {
                method: 'post',
                url: `/profile/${props.idf}/cleardevicedata${props.deviceId ? `?deviceid=${props.deviceId}` : ''}`,
            },
            reducer: response => response.data === '',
        })(Component)(props)
    } else return <Component {...props} />
}

export default compose(withLocalize, withStore, withConditionalCall, withStatus)(ClearCacheButton)
