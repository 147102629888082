import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/styles'
import { Typography, Button } from '@material-ui/core'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'

import { object, func, string } from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'
import styles from './styles'
import { DateTime } from 'luxon'
import Loading from 'components/Loading'
import { withStore } from 'react-ion-store'
import { withRouter } from 'react-router-dom'
import axios from 'axios'

import ListView from './ListView'
import ListViewMobile from './ListViewMobile'
import getPerformances from '../services/getPerformances'
import Header from 'components/Header'
import DateTimePicker from 'components/DateTimePicker'
import ActionBar from 'components/ActionBar'
import getPtcarPlatforms from 'modules/Manage/components/PtcarPlatform/Services/getPtcarPlatforms'
import GroupedTrainListView from './GroupedTrainListView'
import groupBy from 'lodash/groupBy'
import { chain } from 'lodash'
import FooterView from '../FooterView'
import SelectInput from '../SelectInput'
import TaskDetailDrawer from '../TaskDetailDrawer'
import sortByName from 'utils/sortByName'
import IconButton from 'components/IconButton'
import { isMobile } from 'react-device-detect'
import NoRights from '../../../../../NoRights'
import getStoredRefreshRate from 'utils/getStoredRefreshRate'
import SyncInfo from 'components/DateTimePicker/SyncInfo'
import DefaultSettings from 'components/DefaultSettings'
import trainReadyToGo from '../services/trainReadyToGo'
import confirmTrainHasLeft from '../services/confirmTrainHasLeft'
import { clear, valid, get } from 'utils/tokenUtils'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

const TrainListView = props => {
    const { classes, translate, store, activeLanguage } = props
    const [trainNumberString, setTrainNumberString] = useState('')
    const [performanceData, setPerformanceData] = useState(null)
    const [filteredPerformanceData, setFilteredPerformanceData] = useState(null)
    const [colorValues, setColorvalues] = useState(null)
    const [loading, setLoading] = useState(false)
    const [extraFilter, setExtraFilter] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false)

    const [realTime, setRealTime] = useState(true)
    const [startTime, setStartTime] = useState(DateTime.local())
    const [fromDate, setFromDate] = useState(
        DateTime.local()
            .minus({ hours: 1 })
            .toSeconds()
    )
    const [toDate, setToDate] = useState(
        DateTime.local()
            .plus({ hours: 1 })
            .toSeconds()
    )
    const [platformData, setPlatformData] = useState(null)
    const [platformPtcarsList, setPlatformPtcarsList] = useState([])

    const [selectedDepot, setSelectedDepot] = useState([])
    const [selectedDepotNames, setSelectedDepotNames] = useState('')
    const [departureState, setDepartureState] = useState('TrainsNotLeft')
    const [isPerformanceGrouped, setIsPerformanceGrouped] = useState(false)
    const [groupedPerformances, setGroupedPerformances] = useState(null)
    const [drawerData, setDrawerData] = useState(null)
    const [filterValue, setFilterValue] = useState('')
    const [updateRefreshRate, setUpdateRefreshRate] = useState('')
    const [lindaRolesData, setLindaRolesData] = useState([])
    const [selectedLindaRoles, setSelectedLindaRoles] = useState([])
    const [errorForDrawer, setErrorMsgForDrawer] = useState('')

    const handleFilterValue = e => {
        setFilterValue(e.target.value)
    }

    const onDrawerClose = () => {
        setErrorMsgForDrawer('')
        setDrawerOpen(false)
        setDrawerData(null)
    }

    const onRowClick = data => {
        setDrawerData(data)
        setDrawerOpen(true)
    }

    const mapStatus = data => mapColorStatus(data)

    const mapColorStatus = data => {
        const currentTime = DateTime.local().toSeconds()
        let defaultParams = {
            warning: 5,
            critical: 10,
        }

        const storedSettings = localStorage.getItem('userSettings') ? JSON.parse(localStorage.getItem('userSettings')) : null

        if (storedSettings !== null) {
            let settings = storedSettings.filter(obj => obj.pageReference === 'readyForDeparture')[0]
            defaultParams = {
                warning: parseInt(settings.warning),
                critical: parseInt(settings.critical),
            }
        }
        const warning = defaultParams.warning * 60
        const critical = defaultParams.critical * 60

        if (data && data.length > 0) {
            return data.map(task => {
                if (task.isAbolished) return { ...task, colorStatus: 0 }

                const startSeconds = parseInt(DateTime.fromFormat(task.plannedStartTime, 'HH:mm:ss').toSeconds())
                const diff = currentTime - startSeconds

                if (warning <= diff && diff <= critical && task?.trainDepartureOriginTimeStamp === null) {
                    return { ...task, colorStatus: 1 }
                }
                if (critical <= diff && task?.trainDepartureOriginTimeStamp === null) {
                    return { ...task, colorStatus: 2 }
                }

                if (
                    task?.departureAtFirstPtCar === null &&
                    task?.trainDepartureOriginTimeStamp !== null &&
                    task?.trainDepartureSignalType !== 'TrainNotReady'
                ) {
                    return { ...task, colorStatus: 3 }
                }

                return { ...task, colorStatus: 0 }
            })
        }
        return data
    }

    useEffect(() => {
        const refreshValue = getStoredRefreshRate('readyForDeparture')
        document.title = selectedDepotNames?.length > 0 ? selectedDepotNames : translate('module_ready_for_departure')
        const interval = setInterval(() => {
            if (realTime){
                const currentTime = DateTime.local()
                checkJwtExpiry()
                setStartTime(currentTime)
            } 
        }, refreshValue * 1000)

        return () => clearInterval(interval)
    }, [updateRefreshRate, realTime])


    const checkJwtExpiry = () => {
        const [idToken, accessToken] = get()
        const validToken = valid(idToken, accessToken)
        if(!validToken){
            clear()
            store.set({ auth: undefined })
        }
    }

    const mapColors = tasks => {
        let colors = { yellow: 0, red: 0 }

        if (tasks && tasks.length > 0) {
            tasks.forEach(task => {
                if (task.status !== 0) return false
                if (task.isAbolished) return false

                if (task?.colorStatus === 2) {
                    colors.red += 1
                    return
                }

                if (task?.colorStatus === 1) {
                    colors.yellow += 1
                    return
                }
                return false
            })
        }
        return colors
    }

    const loadListNewPage = () => {
        handleLoadPerformances()
    }

    const handleLoadPerformances = async (extra = false) => {
        if (selectedDepot) {
            store.set({ platforms: selectedDepot })
            setLoading(true)
            setHours()
            let response = await getPerformances(
                selectedDepot,
                startTime.toFormat('ddMMyyyyHHmm'),
                departureState,
                selectedLindaRoles,
                platformData,
                extra ? extra : extraFilter,
                platformPtcarsList
            )
            const filteredList = mapStatus(response)

            const colorValues = mapColors(filteredList)
            setLoading(false)
            setPerformanceData(filteredList)

            setColorvalues(colorValues)
            if (trainNumberString && trainNumberString.length > 0) {
                setFilteredPerformanceData(applyExtraFilters(filteredList))
            }
        }
    }

    const loadPtcarPlatforms = async () => {
        let ptcarplatforms = await getPtcarPlatforms()
        const platformList = chain(ptcarplatforms)
            .groupBy('platformId')
            .map((value, key) => ({ id: key, list: value }))
            .value()
        setPlatformData(platformList)

        setSelectedDepot([])
    }

    useEffect(() => {
        loadPtcarPlatforms()
        getLindaRoles()
    }, [])

    const resetFilters = () => {
        setIsPerformanceGrouped(false)
        setGroupedPerformances(null)
        setPerformanceData(null)
        setFilteredPerformanceData(null)
        setColorvalues(null)
        setSelectedDepot([])
        setExtraFilter(false)
        setTrainNumberString('')
        setDepartureState('TrainsNotLeft')
        setStartTime(DateTime.local())
        setSelectedDepotNames('')
        getLindaRoles()
        setRealTime(true)
    }

    //reload data on change date
    const setHours = () => {
        let defaultParams = {
            lower: 1,
            upper: 1,
        }

        const storedSettings = localStorage.getItem('userSettings') ? JSON.parse(localStorage.getItem('userSettings')) : null
        if (storedSettings !== null) {
            let settings = storedSettings.filter(obj => obj.pageReference === 'readyForDeparture')[0]

            defaultParams = {
                lower: parseInt(settings.startHour),
                upper: parseInt(settings.endHour),
            }
        }

        setFromDate(startTime.minus({ hours: defaultParams.lower }).toSeconds())
        setToDate(startTime.plus({ hours: defaultParams.upper }).toSeconds())
    }

    useEffect(() => {
        if (selectedDepot && performanceData !== null) {
            handleLoadPerformances()
        }
        setHours()
    }, [startTime])

    const changeDepartureState = value => {
        setDepartureState(value)
    }

    useEffect(() => {
        if (selectedDepot && performanceData !== null) refresh()
    }, [departureState])

    const applyExtraFilters = data => {
        if (!data) return []

        let filtered = data

        if (trainNumberString && trainNumberString.length > 0) {
            filtered = data.filter(perf =>
                perf.trainComposed ? perf.trainComposed.toLowerCase().includes(trainNumberString.toLowerCase()) : ''
            )
        }

        const colorValues = mapColors(filtered)
        setColorvalues(colorValues)

        if (isPerformanceGrouped) {
            const groupedData = groupBy(filtered, function(b) {
                return b?.locationFromPtCarUic
            })

            setGroupedPerformances(groupedData)
        }
        return filtered
    }

    const makePtcarList = (data, selected) => {
        const filterSelected = data.filter(item => selected.includes(item.id))

        let array = []
        filterSelected.forEach(item =>
            item.list.forEach(list =>
                array.push({
                    ptCarId: list.ptCarId,
                    ptCarUicCode: list.ptCarUicCode,
                    ptCarCode: list.ptCarCode,
                    checked: true,
                })
            )
        )

        //remove duplicate values and return it
        return [...new Map(array.map(item => [item['ptCarUicCode'], item])).values()]
    }

    const changePtcarCheckbox = array => {
        const id = array[array?.length - 1]
        let selectedItems = platformPtcarsList

        if (id === 'button-apply') {
            setExtraFilter(true)
            handleLoadPerformances(true)
            return
        }

        if (id === 'button-clear') {
            let clearedData = selectedItems.map(item => {
                return { ...item, checked: false }
            })
            setPlatformPtcarsList(clearedData)
            return
        }

        const newData = selectedItems.map(item =>
            item.ptCarId === id ? { ...item, checked: item.checked ? false : true } : item
        )

        setPlatformPtcarsList(newData)
    }

    const changeLindaRoles = array => {
        const id = array[array?.length - 1]
        let selectedItems = selectedLindaRoles

        if (id === 'button-apply') {
            setExtraFilter(true)
            handleLoadPerformances(true)
            return
        }

        if (id === 'button-clear') {
            let clearedData = selectedItems.map(item => {
                return { ...item, checked: false }
            })
            setSelectedLindaRoles(clearedData)
            return
        }

        const newData = selectedItems.map(item =>
            item.lindaRoleCode === id ? { ...item, checked: item.checked ? false : true } : item
        )

        setSelectedLindaRoles(newData)
    }

    useEffect(() => {
        if (!platformData) return
        if (!selectedDepot || selectedDepot?.length === 0) {
            setPlatformPtcarsList([])
            return
        }

        if (!extraFilter) {
            setPlatformPtcarsList(makePtcarList(platformData, selectedDepot))
        }

        setFilteredPerformanceData(applyExtraFilters(performanceData))
    }, [selectedDepot, performanceData])

    useEffect(() => {
        if (platformPtcarsList && platformPtcarsList?.length > 0) {
            setFilteredPerformanceData(applyExtraFilters(performanceData))
        }
    }, [trainNumberString])

    const persist = type => value => {
        if (type === 'time') {
            setStartTime(value)
        }
        if (type === 'date') {
            setRealTime(false)
            setStartTime(value)
        }
    }

    const toggleRealTime = (button = false) => {
        if (button == 'button' && !realTime) {
            setStartTime(DateTime.local())
        }
        setRealTime(!realTime)
    }

    const groupCheckboxHandler = () => {
        setIsPerformanceGrouped(!isPerformanceGrouped)
        const groupedData = groupBy(filteredPerformanceData, function(b) {
            return b?.locationFromPtCarUic
        })

        setGroupedPerformances(groupedData)
    }

    const refresh = () => {
        handleLoadPerformances()
    }

    const onPlatformSelected = (event, index, data) => {
        setSelectedDepot(event.target.value)
        const getNames = data.filter(({ id }) => event.target.value.includes(id))
        let nameList = []
        getNames.forEach(item => nameList.push(item.text))
        setSelectedDepotNames(nameList.join(', '))
    }

    const sortedSelect = platformData
        ? platformData.map(d => ({
              id: d.id,
              text:
                  activeLanguage?.code === 'fr'
                      ? d.list[0].platformName[0].translation
                      : d.list[0].platformName[1].translation,
          }))
        : []
    const merge = sortedSelect.sort((a, b) => sortByName(a.text, b.text))
    const moduleList = store.get('moduleList')
    const reloadData = () => {
        setUpdateRefreshRate(DateTime.local())
    }
    const returnRightview = () => {
        if (isMobile) {
            return (
                <ListViewMobile
                    data={filteredPerformanceData}
                    classes={classes}
                    onRowClick={onRowClick}
                    isPerformanceGrouped={isPerformanceGrouped}
                    checkPerformanceForSound={checkPerformanceForSound}
                    translate={translate}
                />
            )
        }
        return (
            <ListView
                data={filteredPerformanceData}
                classes={classes}
                onRowClick={onRowClick}
                isPerformanceGrouped={isPerformanceGrouped}
                checkPerformanceForSound={checkPerformanceForSound}
                departureState={departureState}
                translate={translate}
            />
        )
    }

    const checkPerformanceForSound = performances => {
        if (!performances) return

        const storedSettings = localStorage.getItem('userSettings')
            ? JSON.parse(localStorage.getItem('userSettings'))
            : DefaultSettings
        let userSettingsSoundTime = storedSettings.filter(obj => obj.pageReference === 'readyForDeparture')[0]?.warningSignal
        const alertSeconds = userSettingsSoundTime * 60
        const currentTime = parseInt(DateTime.local().toSeconds())

        for (let perf of performances) {
            const plannedStart = perf.plannedStartDateTime
            const diff = plannedStart - currentTime

            if (alertSeconds < 0 && diff > 0) {
                //do +40 for refresh at the last seconds of a min
                if (diff - 10 <= Math.abs(alertSeconds) && diff + 40 >= Math.abs(alertSeconds)) {
                    return true
                }
            }
            if (alertSeconds >= 0 && diff)
                if (alertSeconds + diff <= 0) {
                    return true
                }
        }

        return false
    }
    const getLindaRoles = async () => {
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/reference/lindauserroles`

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                setLindaRolesData([])
                setSelectedLindaRoles([])
            }
            if (response.status === 200) {
                const result = mapLindaData(response.data)
                setLindaRolesData(result)
                setSelectedLindaRoles(result)
            }
        } catch (e) {
            setLindaRolesData([])
            setSelectedLindaRoles([])
            console.error(e) // eslint-disable-line
        }
    }

    const mapLindaData = data => {
        let array = []
        data.forEach(role =>
            array.push({
                lindaRoleCode: role.lindaRoleCode,
                lindaRoleName:
                    activeLanguage?.code === 'fr' ? role.description[0].translation : role.description[1].translation,
                checked: role.lindaRoleCode === 'Tdlm' ? true : false,
            })
        )
        return array
    }
    const handleReadyToGo = (performanceId, taskId, idfNumber) => {
        setLoading(true)
        const response = trainReadyToGo(performanceId, taskId, idfNumber)
        response
            .then(() => {
                onDrawerClose()
                refresh()
            })
            .catch(e => {
                setLoading(false)
                setErrorMsgForDrawer(e.response.data.Message)
            })
    }
    const handleConfirmTrainHasLeft = async (performanceId, taskId, idfNumber) => {
        setLoading(true)
        const response = confirmTrainHasLeft(performanceId, taskId, idfNumber)
        response
            .then(() => {
                onDrawerClose()
                refresh()
            })
            .catch(e => {
                setLoading(false)
                setErrorMsgForDrawer(e.response.data.Message)
            })
    }

    if (!!moduleList && !moduleList.includes('/readyfordeparture')) {
        return <NoRights />
    } else {
        let isMobileView = false
        if (isWidthDown('sm', props.width)) {
            isMobileView = true
        }
        const desktopView = (
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!loading && performanceData && (
                        <>
                            <Button
                                style={{ marginRight: '12px' }}
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                onClick={() => resetFilters()}
                            >
                                {translate('agenda_reset_button')}
                            </Button>

                            <input
                                type="text"
                                className={classes.customInput}
                                placeholder={translate('search')}
                                value={trainNumberString}
                                onChange={e => {
                                    setTrainNumberString(e.target.value)
                                }}
                            />
                        </>
                    )}

                    {!loading && colorValues && (
                        <div className={classes.labelContainer}>
                            {colorValues && colorValues.red !== 0 && (
                                <Typography>
                                    <span className={classes.roundedRed}>{colorValues.red}</span>
                                </Typography>
                            )}
                            {colorValues && colorValues.yellow !== 0 && (
                                <Typography>
                                    <span className={classes.roundedYellow}>{colorValues.yellow}</span>
                                </Typography>
                            )}
                        </div>
                    )}
                    {!loading && selectedDepot?.length > 0 && performanceData !== null && (
                        <IconButton
                            className={classes.button}
                            icon="add"
                            title={translate('management_button_add_tab')}
                            onClick={() => {
                                window.open('/readyfordeparture')
                            }}
                        />
                    )}
                </div>
                <div style={{ display: 'flex' }}>
                    <DateTimePicker
                        disabled={loading}
                        identifier="readyForDeparture"
                        startValue={startTime}
                        translate={translate}
                        persist={persist}
                        trainView={true}
                        toggleRealTime={toggleRealTime}
                        realTime={realTime}
                        filterValue={filterValue}
                        handleFilterValue={handleFilterValue}
                        fromDate={fromDate}
                        toDate={toDate}
                    />
                </div>
            </div>
        )

        const mobileView = (
            <div className={classes.mobileView}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {!loading && performanceData && (
                                <>
                                    <Button
                                        className={classes.resetButton}
                                        disabled={loading}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => resetFilters()}
                                    >
                                        Reset
                                    </Button>

                                    <input
                                        type="text"
                                        className={classes.customInput}
                                        placeholder={translate('search')}
                                        value={trainNumberString}
                                        onChange={e => {
                                            setTrainNumberString(e.target.value)
                                        }}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                e.stopPropagation()
                                                document.activeElement.blur()
                                            }
                                        }}
                                    />
                                </>
                            )}

                            {!loading && colorValues && (
                                <div className={classes.labelContainer}>
                                    {colorValues && colorValues.red !== 0 && (
                                        <Typography>
                                            <span className={classes.roundedRed}>{colorValues.red}</span>
                                        </Typography>
                                    )}
                                    {colorValues && colorValues.yellow !== 0 && (
                                        <Typography>
                                            <span className={classes.roundedYellow}>{colorValues.yellow}</span>
                                        </Typography>
                                    )}
                                </div>
                            )}
                        </div>
                        {!loading && selectedDepot?.length > 0 && performanceData !== null && (
                            <IconButton
                                className={classes.button}
                                icon="add"
                                title={translate('management_button_add_tab')}
                                onClick={() => {
                                    window.open('/readyfordeparture')
                                }}
                            />
                        )}
                    </div>
                    <div>
                        <DateTimePicker
                            disabled={loading}
                            identifier="readyForDeparture"
                            startValue={startTime}
                            translate={translate}
                            persist={persist}
                            trainView={true}
                            toggleRealTime={toggleRealTime}
                            realTime={realTime}
                            filterValue={filterValue}
                            handleFilterValue={handleFilterValue}
                            fromDate={fromDate}
                            toDate={toDate}
                            syncInfoRequired={false}
                        />
                    </div>
                </div>
                <SyncInfo
                    identifier={'readyForDeparture'}
                    translate={translate}
                    fromDate={fromDate}
                    toDate={toDate}
                    startValue={startTime}
                />
            </div>
        )
        return (
            <React.Fragment>
                <Header
                    isMenu={true}
                    title={translate('module_ready_for_departure')}
                    pageTitle="readyForDeparture"
                    resetIntervalTaskDetail={reloadData}
                />
                <ActionBar>{isMobileView ? mobileView : desktopView}</ActionBar>
                {loading && !performanceData ? (
                    <Loading />
                ) : performanceData && selectedDepot ? (
                    <>
                        {isPerformanceGrouped ? (
                            <GroupedTrainListView
                                data={groupedPerformances}
                                platformPtcarsList={platformPtcarsList}
                                isPerformanceGrouped={isPerformanceGrouped}
                                onRowClick={onRowClick}
                                checkPerformanceForSound={checkPerformanceForSound}
                            />
                        ) : (
                            returnRightview()
                        )}
                        <FooterView
                            platformData={platformData}
                            selectedDepot={selectedDepot}
                            selectedPlatformPtcarsList={platformPtcarsList}
                            onChange={() => groupCheckboxHandler()}
                            changePtcarCheckbox={changePtcarCheckbox}
                            departureState={departureState}
                            changeDepartureState={changeDepartureState}
                            performancesGrouped={isPerformanceGrouped}
                            isMobile={isMobile}
                            lindaRolesData={lindaRolesData}
                            selectedLindaRoles={selectedLindaRoles}
                            changeLindaRoles={changeLindaRoles}
                        />
                    </>
                ) : (
                    <div className={classes.parentDiv}>
                        <SelectInput
                            filled
                            label="Platform"
                            items={merge}
                            name="platform"
                            onChange={onPlatformSelected}
                            value={selectedDepot}
                            multiple
                        />
                        <Button
                            className={classes.loadButton}
                            disabled={selectedDepot?.length < 1}
                            variant="contained"
                            color="primary"
                            onClick={() => loadListNewPage()}
                        >
                            {translate('add_task_load_train')}
                        </Button>
                    </div>
                )}

                {drawerOpen && (
                    <TaskDetailDrawer
                        handleReadyToGo={handleReadyToGo}
                        handleConfirmTrainHasLeft={handleConfirmTrainHasLeft}
                        open={drawerOpen}
                        onClose={onDrawerClose}
                        data={drawerData}
                        errorMsg={errorForDrawer}
                        isLoading={loading}
                    />
                )}
            </React.Fragment>
        )
    }
}
TrainListView.propTypes = {
    classes: object,
    translate: func,
    store: object,
    activeLanguage: object,
    width: string,
}
export default compose(withStore, withLocalize, withRouter, withStyles(styles), withWidth())(TrainListView)
