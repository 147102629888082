import React, { Component } from 'react'
import { compose } from 'recompose'
import { DateTime } from 'luxon'
import { withLocalize } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import { util } from 'react-ion-store'
import { withStyles, Button, Paper, Typography, Grid } from '@material-ui/core'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import { dateTimeFromEpochSeconds } from 'utils/dateParser'
import IconButton from 'components/IconButton'
import StyledTooltip from 'components/StyledTooltip'
import StatusLabel from 'components/StatusLabel'
import DeleteDialog from '../../../PerformanceDetail/components/Dialogs/DeleteTaskDialog'
import AbolishDialog from '../../../PerformanceDetail/components/Dialogs/AbolishDialog'
import styles from './styles'
import FabButton from '../FabButton'
/* eslint-disable max-len */
import { isCompositionViewAllowed } from 'modules/Performances/views/ReadyForDeparture/components/CompositionView/Utils/isCompoEditingAllowed'
import TaskDetailLindaDrawer from '../TaskDetailLindaDrawer'
import { nonDrivingTasksWithCompositionsFilter } from '../../util/nonDrivingTasksWithCompositions'
const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export class TaskDetailHeader extends Component {
    static propTypes = {
        task: PropTypes.object.isRequired,
        classes: PropTypes.any,
        storage: PropTypes.object,
        history: PropTypes.object,
        match: PropTypes.object,
        translate: PropTypes.func,
        performanceId: PropTypes.string,
        handleClose: PropTypes.func,
        handleAbolishClose: PropTypes.func,
        isOpen: PropTypes.bool,
        isAbolishOpen: PropTypes.bool,
        handleOpen: PropTypes.func,
        handleAbolishOpen: PropTypes.func,
        idfNumber: PropTypes.string,
        signOutTime: PropTypes.string,
        activeLanguage: PropTypes.object,
        refreshAfterBulkOperation: PropTypes.func,
    }

    state = {
        errorMessage: '',
        taskId: '',
        errorOpen: false,
        isTablet: window.matchMedia('(max-width: 1500px)').matches,
        isSmallTablet: window.matchMedia('(max-width: 1200px)').matches,
        isNonDrivingPerformance: false,
    }

    closeError = () => {
        this.setState({ errorOpen: false })
    }

    handleLinda = () => {
        this.setState({ openLindaDrawer: true })
    }
    closeLindaDrawer = () => {
        this.setState({ openLindaDrawer: false })
    }

    changeTaskStatus = (status, performanceNumber, taskNumber, performanceIdf, start, end) => {
        let allActions = false
        let caseProp = 0

        if (end !== null && start !== null) allActions = true

        if (allActions && status === 0) {
            caseProp = 1
        }
        if (!allActions && status === 0) {
            caseProp = 2
        }
        if (status === 1) {
            caseProp = 2
        }

        if (status === 2) {
            caseProp = 0
        }
        this.chooseRightCall(caseProp, performanceNumber, taskNumber, performanceIdf, start, end)
    }
    chooseRightCall = (status, performanceNumber, taskNumber, performanceIdf, start, end) => {
        let startedTasks = []

        this.setState({ errorMessage: '', taskId: taskNumber })
        switch (status) {
            case 0:
                this.doResetCall(performanceNumber, taskNumber, performanceIdf)
                break
            case 1:
                startedTasks = this.findStartedTask()
                this.doStartCall(performanceNumber, taskNumber, performanceIdf, startedTasks, start)

                break
            case 2:
                this.doFinishCall(performanceNumber, taskNumber, performanceIdf, end)
                break

            default:
                break
        }
    }

    findStartedTask = () => {
        const oldPerformanceDetail = this.props.storage.get('performanceDetail')
        const { tasks } = oldPerformanceDetail

        return tasks.filter(task => task.status === 1)
    }

    doResetCall = async (performanceNumber, taskNumber, performanceIdf) => {
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/performance/${performanceNumber}/task/${taskNumber}/resetstatus?idfNumber=${performanceIdf}`

        try {
            const response = await axios.post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                return []
            }
            if (response.status === 200) {
                this.updateTaskStatusInStore(taskNumber, 0)
                this.props.refreshAfterBulkOperation()
                return response.data
            }
        } catch (e) {
            const { translate } = this.props
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({
                        errorMessage: translate('roles_error'),
                        errorOpen: true,
                    })
                    return false
                }
            }
            this.setState({ errorMessage: translate('performance_change_task_status_error'), errorOpen: true })
            console.error(e) // eslint-disable-line
        }
    }

    doStartCall = async (performanceNumber, taskNumber, performanceIdf, startedTasks, start) => {
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/performance/${performanceNumber}/task/${taskNumber}/start`

        const now = DateTime.utc()
        // const realStartTime = now.toFormat('HH:mm')
        const StatusOngoing = now.toISO()
        try {
            const response = await axios.post(fullUrl, {
                IdfNumber: performanceIdf,
                RealStartTime: start,
                StatusOngoing: StatusOngoing,
                RealStartDateTime: StatusOngoing,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            if (response.status === 204) {
                return []
            }
            if (response.status === 200) {
                this.updateTaskStatusInStore(taskNumber, 1)

                if (startedTasks.length > 0) {
                    startedTasks.forEach(task => {
                        this.updateTaskStatusInStore(task.id, 2)
                    })
                }
                this.props.refreshAfterBulkOperation()
                return response.data
            }
        } catch (e) {
            const { translate } = this.props
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({
                        errorMessage: translate('roles_error'),
                        errorOpen: true,
                    })
                    return false
                }
            }
            this.setState({ errorMessage: translate('performance_change_task_status_error'), errorOpen: true })
            console.error(e) // eslint-disable-line
        }
    }

    doFinishCall = async (performanceNumber, taskNumber, performanceIdf, end) => {
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/performance/${performanceNumber}/task/${taskNumber}/finish`

        const now = DateTime.utc()
        // const RealEndTime = now.toFormat('HH:mm')
        const StatusEnded = now.toISO()
        try {
            const response = await axios.post(fullUrl, {
                IdfNumber: performanceIdf,
                RealEndTime: end,
                StatusEnded: StatusEnded,
                RealEndDateTime: StatusEnded,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            if (response.status === 204) {
                return []
            }
            if (response.status === 200) {
                this.updateTaskStatusInStore(taskNumber, 2)
                this.props.refreshAfterBulkOperation()
                return response.data
            }
        } catch (e) {
            const { translate } = this.props
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({
                        errorMessage: translate('roles_error'),
                        errorOpen: true,
                    })
                    return false
                }
            }
            this.setState({ errorMessage: translate('performance_change_task_status_error'), errorOpen: true })
            console.error(e) // eslint-disable-line
        }
    }

    updateTaskStatusInStore = (taskId, status) => {
        let oldPerformanceDetail = this.props.storage.get('performanceDetail')

        const task = oldPerformanceDetail.tasks.find(task => task.id === taskId)

        task.status = status

        util.CreateStoreObject(oldPerformanceDetail)

        //Do setState to force render
        this.setState({ taskId: '' })
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.handleResize())
        this.setState({ isNonDrivingPerformance: nonDrivingTasksWithCompositionsFilter(this.props.task.code) })
        console.log(this.props)
    }

    handleResize() {
        this.setState({
            isTablet: window.matchMedia('(max-width: 1500px)').matches,
            isSmallTablet: window.matchMedia('(max-width: 1200px)').matches,
        })
    }

    isAbolishDisable(code) {
        return (
            code !== 'START' &&
            code !== 'END' &&
            code !== 'FREETEXT_P' &&
            code !== 'FREETEXT_M' &&
            code !== 'OPMRMQ' &&
            code !== 'OPMREM'
        )
    }

    render() {
        const {
            code,
            taskInfo,
            status,
            isAbolished,
            id,
            plannedStartTime,
            plannedEndTime,
            confirmedRivMessageDateTime,
            currentRivMessageDateTime,
            brakingReportAvailable,
            trainComposed,
            plannedStartDateTime,
            locationFromPtCarUic,
            locationFrom,
            locationTo,
            locationToPtCarUic,
        } = this.props.task
        const { isTablet, isSmallTablet, errorOpen } = this.state

        const handleEdit = () => {
            history.push(`${match.url}/edit`)
        }
        const openTC286 = () => {
            window.open(
                `/TC286-report/${trainComposed}/${this.props.performanceId}/${this.props.task.id}/${this.props.idfNumber}/${plannedStartDateTime}/${locationFromPtCarUic}/${locationToPtCarUic}`
            )
        }
        const openComposition = () => {
            window.open(
                `/compositionPtcarView/${this.props.performanceId}/${this.props.task.id}/${encodeURIComponent(
                    trainComposed
                )}/${plannedStartDateTime}/${locationFromPtCarUic}/${idfNumber}/${window.btoa(
                    locationFrom
                )}/${encodeURIComponent(locationToPtCarUic)}/${window.btoa(locationTo)}`
            )
        }

        const openNonDrivingComposition = () => {
            window.open(
                `/compositionPtcarViewNonDriving/${encodeURIComponent(
                    this.props.task.compositionNonDrivingId
                )}/${plannedStartDateTime}/${idfNumber}/`
            )
        }
        const getAbolishTitle = () => {
            if (!isTablet) {
                if (isAbolished) return translate('add_user_reactivate')
                return translate('add_user_abolish')
            }
            return ''
        }

        const openSemes = () => {
            window.open(`${this.props.match.url}/semes`)
        }
        const {
            classes,
            translate,
            match,
            performanceId,
            handleClose,
            handleAbolishClose,
            isOpen,
            isAbolishOpen,
            handleOpen,
            handleAbolishOpen,
            history,
            idfNumber,
            storage,
            signOutTime,
        } = this.props

        let disabledCompos = !isCompositionViewAllowed({ store: { ...this.props.storage } })
        let isLindaAllowed =
            code === 'DRIVE' &&
            !checkFunctionalityDisabled(
                this.props.storage,
                'lindaActions',
                'performances.functionality.management.performanceDetail'
            )
        return (
            <React.Fragment>
                <FabButton
                    abolishDisable={this.isAbolishDisable(code)}
                    isAbolished={isAbolished}
                    signOutTime={signOutTime}
                    code={code}
                    handleOpen={handleOpen}
                    handleAbolishOpen={handleAbolishOpen}
                    handleEdit={handleEdit}
                    openTC286={openTC286}
                    disabledTC286={!brakingReportAvailable}
                    openComposition={openComposition}
                    disabledCompos={disabledCompos}
                    isLindaAllowed={isLindaAllowed}
                    handleLinda={() => this.handleLinda()}
                    storage={storage}
                />
                <Paper className="infoHeader">
                    <div
                        className={classes.flexContainer}
                        style={{
                            padding: code !== 'DRIVE' ? '12px 0px' : '0px',
                        }}
                    >
                        <Grid container xs={12}>
                            <Grid item xs={3} className={classes.infoContainer}>
                                <Typography variant="h5">{code}</Typography>
                            </Grid>
                            <Grid item xs={3} className={classes.infoContainer}>
                                <StyledTooltip placement="bottom" title={taskInfo || ''}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: isTablet ? (isSmallTablet ? '200px' : '300px') : '600px',
                                        }}
                                    >
                                        {taskInfo}
                                    </Typography>
                                </StyledTooltip>
                            </Grid>
                            <Grid item xs={3} className={classes.infoContainer}>
                                <StatusLabel
                                    disableButtons={
                                        signOutTime !== null ||
                                        checkFunctionalityDisabled(
                                            this.props.storage,
                                            'change status',
                                            'performances.functionality.management.tasks'
                                        )
                                    }
                                    status={status}
                                    isAbolished={isAbolished}
                                    changeStatusOnClick={
                                        signOutTime === null &&
                                        !checkFunctionalityDisabled(
                                            this.props.storage,
                                            'change status',
                                            'performances.functionality.management.tasks'
                                        )
                                            ? code === 'START' || code === 'END' || idfNumber === null
                                                ? () => {}
                                                : e => {
                                                      e.stopPropagation()
                                                      this.changeTaskStatus(
                                                          status,
                                                          performanceId,
                                                          id,
                                                          idfNumber,
                                                          plannedStartTime,
                                                          plannedEndTime
                                                      )
                                                  }
                                            : ''
                                    }
                                    startEnd={code === 'START' || code === 'END' || idfNumber === null ? true : false}
                                />
                            </Grid>
                            {code === 'DRIVE' && (
                                <Grid item xs={3} className={classes.versionContainer}>
                                    <p>{`${translate('taskdetail_drive_tablet_message')}${
                                        confirmedRivMessageDateTime === null
                                            ? 'N/A'
                                            : dateTimeFromEpochSeconds(confirmedRivMessageDateTime)
                                    }`}</p>
                                    <p>{`${translate('taskdetail_drive_system_message')}${
                                        currentRivMessageDateTime === null
                                            ? 'N/A'
                                            : dateTimeFromEpochSeconds(currentRivMessageDateTime)
                                    }`}</p>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                    <div>
                        <Grid container spacing={1} xs={12} direction="row">
                            <Grid item xs={8}>
                                <Grid
                                    container
                                    spacing={1}
                                    justifyContent="flex-end"
                                    direction="row"
                                    className={classes.gridContainer}
                                >
                                    {isLindaAllowed && (
                                        <Grid item>
                                            <Button
                                                className={classes.button}
                                                variant="contained"
                                                color="primary"
                                                // disabled={disabledCompos}
                                                onClick={() => {
                                                    this.handleLinda()
                                                }}
                                            >
                                                {translate('label_linda')}
                                            </Button>
                                        </Grid>
                                    )}
                                    {(code === 'DRIVE' ||
                                        code === 'UITGAR' ||
                                        code === 'PERQUAI' ||
                                        code === 'TRANSFER') && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                disabled={!isCompositionViewAllowed({ store: { ...this.props.storage } })}
                                                onClick={() => openComposition()}
                                            >
                                                {translate('label_composition')}
                                            </Button>
                                        </Grid>
                                    )}
                                    {(code === 'DRIVE' ||
                                        code === 'UITGAR' ||
                                        code === 'PERQUAI' ||
                                        code === 'TRANSFER') && (
                                        <Grid item>
                                            <Button
                                                className={classes.button}
                                                color="primary"
                                                variant="contained"
                                                onClick={() => openTC286()}
                                            >
                                                TC286
                                            </Button>
                                        </Grid>
                                    )}
                                    {this.isAbolishDisable(code) && idfNumber !== null && (
                                        <Grid item>
                                            <IconButton
                                                className={classes.button}
                                                color={'primary'}
                                                title={'SEMES'}
                                                onClick={() => {
                                                    openSemes()
                                                }}
                                                disabled={checkFunctionalityDisabled(
                                                    this.props.storage,
                                                    'delete',
                                                    'performances.functionality.management.tasks'
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    xs={4}
                                    md={8}
                                    lg={12}
                                    className={classes.gridContainer}
                                >
                                    {signOutTime === null && code !== 'START' && code !== 'END' && (
                                        <Grid item xs={4} md={4} lg={4}>
                                            <IconButton
                                                icon="edit"
                                                className={classes.button}
                                                title={isTablet ? '' : translate('icon_button_edit')}
                                                onClick={() => {
                                                    handleEdit()
                                                }}
                                                disabled={checkFunctionalityDisabled(
                                                    this.props.storage,
                                                    'add',
                                                    'performances.functionality.management.tasks'
                                                )}
                                            />
                                        </Grid>
                                    )}
                                    {signOutTime === null && code !== 'START' && code !== 'END' && (
                                        <Grid item xs={4} md={4} lg={4}>
                                            <IconButton
                                                className={classes.button}
                                                color={'secondary'}
                                                icon={'delete'}
                                                title={isTablet ? '' : translate('add_user_delete')}
                                                onClick={() => {
                                                    handleOpen()
                                                }}
                                                disabled={checkFunctionalityDisabled(
                                                    this.props.storage,
                                                    'delete',
                                                    'performances.functionality.management.tasks'
                                                )}
                                            />
                                        </Grid>
                                    )}
                                    {signOutTime === null && this.isAbolishDisable(code) && idfNumber !== null && (
                                        <Grid item xs={4} md={4} lg={4}>
                                            <IconButton
                                                className={classes.button}
                                                color={'primary'}
                                                icon={isAbolished ? 'reactivate' : 'block'}
                                                title={getAbolishTitle()}
                                                onClick={() => {
                                                    handleAbolishOpen()
                                                }}
                                                disabled={checkFunctionalityDisabled(
                                                    this.props.storage,
                                                    'delete',
                                                    'performances.functionality.management.tasks'
                                                )}
                                            />
                                        </Grid>
                                    )}
                                    {this.state.isNonDrivingPerformance && (
                                        <Grid item xs={4} md={4} lg={4}>
                                            <IconButton
                                                className={classes.button}
                                                color={'primary'}
                                                title={'composition'}
                                                onClick={() => {
                                                    openNonDrivingComposition()
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                <Snackbar open={errorOpen} autoHideDuration={6000} onClose={this.closeError}>
                    <MuiAlert evation={6} variant="filled" onClose={this.closeError} severity="warning">
                        {this.state.errorMessage}
                    </MuiAlert>
                </Snackbar>
                <DeleteDialog
                    open={isOpen}
                    handleClose={handleClose}
                    idfNumber={idfNumber}
                    performanceId={performanceId}
                    taskId={id}
                    performanceDetailPage={true}
                    history={history}
                    storage={storage}
                />
                <AbolishDialog
                    open={isAbolishOpen}
                    handleClose={handleAbolishClose}
                    idfNumber={idfNumber}
                    performanceId={performanceId}
                    taskId={id}
                    history={history}
                    storage={storage}
                    isAbolished={isAbolished}
                />
                {this.state.openLindaDrawer && (
                    <TaskDetailLindaDrawer
                        trainNumber={this.props.task.trainNumber}
                        trainNumberPrefix={this.props.task.trainNumberPrefix}
                        performanceDate={plannedStartDateTime}
                        open={this.state.openLindaDrawer}
                        onClose={() => this.closeLindaDrawer()}
                        translate={translate}
                        activeLanguage={this.props.activeLanguage}
                        storage={this.props.storage}
                        taskId={this.props.task.id}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default compose(withLocalize, withRouter, withStyles(styles))(TaskDetailHeader)
