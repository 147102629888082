export default theme => ({
    icon: {
        fill: theme.customColors.iconFront,
        background: theme.customColors.iconBg,
        marginRight: theme.spacing(3),
    },
    divider: {
        height: '2px',
    },
})
