import { grey, green, lightBlue, red } from '@material-ui/core/colors'

export default theme => ({
    grayLabel: {
        padding: theme.spacing(1) / 2,
        backgroundColor: grey['A100'],
        textAlign: 'center',
    },
    greenLabel: {
        padding: theme.spacing(1),
        backgroundColor: green['500'],
        textAlign: 'center',
    },
    blueLabel: {
        padding: theme.spacing(1),
        backgroundColor: lightBlue['500'],
        textAlign: 'center',
    },
    redLabel: {
        padding: theme.spacing(1),
        backgroundColor: red['500'],
        textAlign: 'center',
    },
    blackText: {
        color: 'black',
    },
    clickAble: {
        cursor: 'pointer',
    },
})
