import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/styles'
import { object, func } from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'

import ManagePtcarTypesTable from './ManagePtcarTypesTable'
import getPtcarTypes from 'modules/Manage/Services/getPtcarTypes'
import PtcarTypesDrawer from './PtcarTypesDrawer'

import styles from './styles'
import Header from 'components/Header'
import Loading from 'components/Loading'
import { Button, Typography } from '@material-ui/core'
import DeleteDialog from './DeletePtcarTypeDialog'
import addOrUpdateTypes from 'modules/Manage/Services/addOrUpdateTypes'
import { withStore } from 'react-ion-store'
const ManagePtcarTypes = props => {
    const [ptcarTypes, setPtcarTypes] = useState([])
    const [isDrawerOpen, setDrawerOpen] = useState(false)
    const [isDialogOpen, setDialogOpen] = useState(false)
    const [dataForEditing, setDataForEditing] = useState(null)
    const [openDrawerForEditing, setOpenDrawerForEditing] = useState(false)
    const [deleteData, setDataForDelete] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const onDrawerClose = () => {
        setDrawerOpen(false)
        setDataForEditing(null)
    }

    const onDrawerOpen = (data, isEdit) => {
        setDrawerOpen(true)
        setOpenDrawerForEditing(isEdit)
        setDataForEditing(data)
    }
    const onDialogClose = () => {
        setDialogOpen(false)
    }

    const onDialogOpen = (e, rowData) => {
        setDialogOpen(true)
        setDataForDelete(rowData)
    }
    const handleAddOrEdit = async (data, edit) => {
        const response = await addOrUpdateTypes(data, edit)
        if (response) {
            loadPtcarTypes()
            setDrawerOpen(false)
        }
    }
    const loadPtcarTypes = async () => {
        setIsLoading(true)
        setDialogOpen(false)
        const response = await getPtcarTypes()
        if (response.status === 204) {
            setPtcarTypes([])
            setIsLoading(false)
        }
        if (response.status === 200) {
            let types = response.data.map(({ edriveId, description }) => ({
                Id: edriveId,
                desc_nl: description[1].translation,
                desc_fr: description[0].translation,
            }))
            setPtcarTypes(types)
            setIsLoading(false)
        }
    }
    const handleAddOrEditTypes = () => {
        setDrawerOpen(true)
        setDataForEditing(null)
        setOpenDrawerForEditing(false)
    }
    useEffect(() => {
        loadPtcarTypes()
    }, [])
    return (
        <>
            <Header title={props.translate('manage_ptcar_types_button')} />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className={props.classes.addButtonContainer}>
                        <Typography variant="h6">Ptcar Types</Typography>
                        <Button variant="contained" color="primary" onClick={handleAddOrEditTypes}>
                            {props.translate('manage_ptcar_types_add_button')}
                        </Button>
                    </div>
                    <ManagePtcarTypesTable
                        classes={props.classes}
                        ptcarTypes={ptcarTypes}
                        onDrawerClose={onDrawerClose}
                        onDrawerOpen={onDrawerOpen}
                        onDialogClose={onDialogClose}
                        onDialogOpen={onDialogOpen}
                        store={props.store}
                    />
                    {isDrawerOpen && (
                        <PtcarTypesDrawer
                            isOpen={isDrawerOpen}
                            onClose={onDrawerClose}
                            openDrawerForEditing={openDrawerForEditing}
                            data={dataForEditing}
                            handleAddOrEdit={handleAddOrEdit}
                            {...props}
                        />
                    )}
                    <DeleteDialog
                        deleteData={deleteData}
                        dialogOpen={isDialogOpen}
                        onDialogClose={onDialogClose}
                        translate={props.translate}
                        reloadData={loadPtcarTypes}
                    />
                </>
            )}
        </>
    )
}
ManagePtcarTypes.propTypes = {
    classes: object,
    activeLanguage: object,
    translate: func,
    store: object,
}
export default compose(withLocalize, withStore, withStyles(styles))(ManagePtcarTypes)
