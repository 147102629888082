import blue from '@material-ui/core/colors/blue'

export default theme => ({
    /* headerView.js */
    appBar: {
        zIndex: '1400',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '4px',
            paddingRight: '4px',
        },
    },
    afterToolbar: {
        ...theme.mixins.toolbar,
    },
    mobileTitle: {
        '@media (max-width: 577px)': {
            fontSize: '0.8rem!important',
        },
    },
    date: {
        flexGrow: 1,
        textAlign: 'center',
        '& h6': {
            '@media (max-width: 577px)': {
                fontSize: '0.8rem!important',
            },
        },
    },
    button: {
        marginRight: theme.spacing(2),
    },

    /* components/AccountMenu.js */
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {
        marginLeft: theme.spacing(1),
    },

    /* components/LanguagePicker.js */
    languageIcon: {
        marginRight: theme.spacing(1),
    },
    selected: {
        color: blue[300],
    },
})
