import React from 'react'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import { DateTime } from 'luxon'
import { func, object, string, number, array, bool } from 'prop-types'
import classNames from 'classnames'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import StatusLabel from 'components/StatusLabel'
import Typography from '@material-ui/core/Typography'
import PerformanceTableForTrainNumber from '../PerformanceTableForTrainNumber'
import SpeedIcon from '@material-ui/icons/Speed'
import WarningIcon from '@material-ui/icons/Warning'
import TrainIcon from '@material-ui/icons/Train'
import CheckmarkIcon from '@material-ui/icons/Check'
import { Button } from '@material-ui/core'
import calculateTimeDifferenceWithTimestamps from 'utils/calculateTimeDifferenceWithTimestamps'
import MusicPlayer from '../../../../views/PerformanceOverview/components/PerformancesTable/components/MusicPlayer'
import { isCompositionViewAllowed } from '../CompositionView/Utils/isCompoEditingAllowed'
const columns = [
    {
        width: 75,
        label: 'datatable_header_ptcar_blank',
        dataKey: 'icon',
    },
    {
        width: 200,
        label: 'datatable_header_ptcar_blank',
        dataKey: 'button',
    },
    {
        width: 150,
        label: 'datatable_header_ptcar_code',
        dataKey: 'code',
    },
    {
        width: 200,
        label: 'datatable_header_train_ready_to_go',
        dataKey: 'trainReady',
    },
    {
        width: 100,
        label: 'datatable_header_train_ready_infrabel',
        dataKey: 'infrabel',
    },
    {
        width: 150,
        label: 'datatable_header_perfByTrain_date',
        dataKey: 'date',
    },
    {
        width: 300,
        label: 'performance_info_label_from',
        dataKey: 'from',
    },
    {
        width: 300,
        label: 'performance_info_label_till',
        dataKey: 'to',
    },
    {
        width: 150,
        label: 'firstDepartures_label_planned_arrival',
        dataKey: 'plannedArrival',
    },
    {
        width: 400,
        label: 'firstDepartures_label_driver',
        dataKey: 'driver',
    },
    {
        width: 200,
        label: 'datatable_header_task_status',
        dataKey: 'status',
    },
]

function ListView(props) {
    let headerHeight = 56
    let rowHeight = 56

    const getRowClassName = ({ index }) => {
        const { rowClassName } = props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    const headerRenderer = headerProps => {
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: headerHeight,
                    paddingTop: 34,
                    paddingBottom: 34,
                    paddingRight: 0,
                }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }
    const cellRenderer = cellProps => {
        const { departureState } = props
        const { dataKey, rowData } = cellProps
        let task = rowData

        if (dataKey === 'icon') {
            if (rowData?.departureAtFirstPtCar) {
                return (
                    <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                        <CheckmarkIcon className={classes.greenIcon} />
                    </TableCell>
                )
            }
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    {rowData?.trainDepartureSignalType === 'TrainReady' && <SpeedIcon />}
                    {rowData?.trainDepartureSignalType === 'TrainNotReady' && (
                        <WarningIcon className={classes.warningIcon} />
                    )}
                    {rowData?.trainDepartureSignalType === null && !rowData?.departureAtFirstPtCar && (
                        <TrainIcon className={classes.whiteIcon} />
                    )}
                </TableCell>
            )
        }
        if (dataKey === 'button') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        disabled={!isCompositionViewAllowed(props)}
                        onClick={e => {
                            e.stopPropagation()

                            window.open(
                                `/compositionPtcarView/${task.performanceId}/${task.taskId}/${encodeURIComponent(
                                    task.trainComposed
                                )}/${task.plannedStartDateTime}/${task.locationFromPtCarUic}/${task.idfNumber}/${window.btoa(
                                    rowData.locationFrom
                                )}/${encodeURIComponent(rowData.locationToPtCarUic)}/${window.btoa(rowData.locationTo)}`
                            )
                        }}
                    >
                        {props.translate('label_composition')}
                    </Button>
                </TableCell>
            )
        }

        if (dataKey === 'date' && rowData.plannedStartTime) {
            let timeDiff = 0
            if (rowData.plannedStartDateTime && rowData.realStartDateTime !== null) {
                timeDiff = calculateTimeDifferenceWithTimestamps(rowData.plannedStartDateTime, rowData.realStartDateTime)
            }
            if (timeDiff !== 0) {
                return (
                    <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                        <Typography>
                            {`${rowData.plannedStartTime.substring(0, 5)}`}
                            <span style={{ color: 'yellow' }}>
                                {timeDiff > 0 ? ` (+ ${timeDiff.toString()})` : ` (${timeDiff.toString()})`}
                            </span>
                        </Typography>
                    </TableCell>
                )
            }
            if (timeDiff === 0) {
                return (
                    <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                        <Typography>{task.plannedStartTime.substring(0, 5)}</Typography>
                    </TableCell>
                )
            }
        }
        if (dataKey === 'plannedArrival') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{task?.plannedEndTime ? task.plannedEndTime.substring(0, 5) : ''}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'code') {
            let taskCode = task['code']

            if (dataKey === 'code' && taskCode === 'DRIVE') {
                return (
                    <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                        <Typography>{task['trainComposed'] || 'DRIVE'}</Typography>
                    </TableCell>
                )
            }
            if (dataKey === 'code' && rowData.trainComposed && taskCode !== 'DRIVE') {
                return (
                    <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                        <Typography>{task.code}</Typography>
                        <Typography>{task['trainComposed']}</Typography>
                    </TableCell>
                )
            }
            if (dataKey === 'code') {
                return (
                    <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                        <Typography>{task.code}</Typography>
                    </TableCell>
                )
            }
        }
        if (dataKey === 'trainNumber') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{task.trainComposed} </Typography>
                </TableCell>
            )
        }
        if (dataKey === 'driver') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{`${task?.name} ${task?.firstName}`}</Typography>
                    {task?.telephoneNumber && <Typography>{task?.telephoneNumber}</Typography>}
                </TableCell>
            )
        }
        if (dataKey === 'from') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>
                        {task.code === 'AFREL'
                            ? rowData.referencedPerformance
                            : task.code === 'VK.PC'
                            ? task.taskInfo
                            : task.locationFrom}
                    </Typography>
                </TableCell>
            )
        }
        if (dataKey === 'to') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{rowData.locationTo} </Typography>
                </TableCell>
            )
        }
        if (dataKey === 'trainReady') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <div
                        className={
                            departureState === 'TrainsLeft'
                                ? ''
                                : classNames({
                                      [classes.tableCellYellow]: rowData.colorStatus === 1,
                                      [classes.tableCellRed]: rowData.colorStatus === 2,
                                      [classes.tableCellGreen]: rowData.colorStatus === 3,
                                  })
                        }
                    >
                        {rowData?.trainDepartureSignalType === 'TrainReady' && rowData?.trainDepartureOriginTimeStamp && (
                            <Typography>
                                {`${DateTime.fromSeconds(rowData?.trainDepartureOriginTimeStamp).toFormat('HH:mm')}`}
                            </Typography>
                        )}
                    </div>
                </TableCell>
            )
        }
        if (dataKey === 'infrabel') {
            //TODO label as per the status of performance
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight }}
                ></TableCell>
            )
        }
        if (dataKey === 'status') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <div style={{ display: 'flex', position: 'absolute', maxHeight: '80%' }}>
                        {<StatusLabel status={rowData.status} isAbolished={rowData.isAbolished} />}
                    </div>
                </TableCell>
            )
        }
    }

    const { classes, data, onRowClick, isPerformanceGrouped, checkPerformanceForSound } = props

    if (data && data?.length > 0) {
        return (
            <div className={isPerformanceGrouped ? classes.groupPad : classes.padTop}>
                <PerformanceTableForTrainNumber
                    columns={columns}
                    data={data}
                    classes={classes}
                    headerRenderer={headerRenderer}
                    cellRenderer={cellRenderer}
                    getRowClassName={getRowClassName}
                    rowClickHandler={onRowClick}
                    rowHeight={rowHeight}
                />
                {checkPerformanceForSound(data) && <MusicPlayer identifier={'readyForDeparture'} />}
            </div>
        )
    } else {
        return (
            <div className={classes.parentDiv}>
                <Typography>No Data</Typography>
            </div>
        )
    }
}
ListView.propTypes = {
    classes: object.isRequired,
    rowClassName: string,
    onRowClick: func,
    checkPerformanceForSound: func,
    headerHeight: number,
    rowHeight: number,
    history: object,
    match: object,
    data: array,
    store: object,
    isPerformanceGrouped: bool,
    departureState: string,
    translate: func,
}
export default compose(withStore, withRouter)(ListView)
