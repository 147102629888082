import React, { useState } from 'react'
import { object, array, number, func, string } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'
import { withStore, withTake } from 'react-ion-store'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import { AutoSizer, Column, Table, defaultTableRowRenderer } from 'react-virtualized'

import dateParser from 'utils/dateParser'

import styles from '../../styles'
import tableStyles from './styles'

class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
        headerHeight: 56,
        rowHeight: 56,
    }

    getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = this.props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    checkFirstExtendedOrInProfile = data => {
        //@TODO: Add extra check for if both are there, then return the same as the firstOpened
        if (data?.firstOpenedExtended !== null && data?.firstOpenedExtended !== undefined) {
            if (data?.firstOpened && data.firstOpened === data.firstOpenedExtended) {
                return 'Extended'
            }
        }

        if (data?.firstOpenedInProfile !== null && data?.firstOpenedInProfile !== undefined) {
            if (data?.firstOpened && data.firstOpened === data.firstOpenedInProfile) {
                return 'InProfile'
            }
        }
        return '/'
    }

    checkLastExtendedOrInProfile = data => {
        if (data?.lastOpenedExtended !== null && data?.lastOpenedExtended !== undefined) {
            if (data?.lastOpened && data.lastOpened === data.lastOpenedExtended) {
                return 'Extended'
            }
        }

        if (data?.lastOpenedInProfile !== null && data?.lastOpenedInProfile !== undefined) {
            if (data?.lastOpened && data.lastOpened === data.lastOpenedInProfile) {
                return 'InProfile'
            }
        }
        return '/'
    }

    cellRenderer = cellProps => {
        const { classes, rowHeight, onRowClick } = this.props
        const { dataKey, rowData } = cellProps

        let cellData
        let isRed = false

        if (rowData.exceedReadDays) {
            isRed = rowData.exceedReadDays > 0 ? true : false
        }

        if (dataKey !== 'idfNumber') {
            cellData = rowData[dataKey] ? dateParser(rowData[dataKey] * 1000) : '/'
        } else if (dataKey !== 'exceedReadDays') {
            return (
                <TableCell
                    component="div"
                    className={classNames(classes.tableCell, classes.flexContainer, {
                        [classes.noClick]: onRowClick === null,
                        [classes.tableCellRed]: isRed,
                    })}
                    variant="body"
                    style={{ height: rowHeight }}
                >
                    <div>
                        <Typography style={{ textDecoration: 'underline' }} variant="body1">
                            {` ${rowData.lastName} ${rowData.firstName}`}
                        </Typography>

                        <Typography variant="caption">{rowData.idfNumber}</Typography>
                    </div>
                </TableCell>
            )
        }

        if (dataKey === 'firstOpenedInEx') {
            return (
                <TableCell
                    component="div"
                    className={classNames(classes.tableCell, classes.flexContainer, {
                        [classes.noClick]: onRowClick == null,
                        [classes.tableCellRed]: isRed,
                    })}
                    variant="body"
                    style={{ height: rowHeight }}
                >
                    {this.checkFirstExtendedOrInProfile(rowData)}
                </TableCell>
            )
        }
        if (dataKey === 'lastOpenedInEx') {
            return (
                <TableCell
                    component="div"
                    className={classNames(classes.tableCell, classes.flexContainer, {
                        [classes.noClick]: onRowClick == null,
                        [classes.tableCellRed]: isRed,
                    })}
                    variant="body"
                    style={{ height: rowHeight }}
                >
                    {this.checkLastExtendedOrInProfile(rowData)}
                </TableCell>
            )
        }
        if (dataKey === 'exceedReadDays') {
            cellData = rowData[dataKey]
        }
        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                    [classes.tableCellRed]: isRed,
                })}
                variant="body"
                style={{ height: rowHeight }}
            >
                {cellData}
            </TableCell>
        )
    }

    headerRenderer = ({ label, columnIndex, dataKey, params, sortFunction }) => {
        const { headerHeight, columns, classes } = this.props
        const { sort, sortDirection } = params
        let sortDir = sortDirection === 'asc' ? 'desc' : 'asc'
        const inner =
            columns[columnIndex]?.disableSort === undefined && sort !== null ? (
                <TableSortLabel
                    active={sort === dataKey}
                    direction={sortDir}
                    onClick={() => sortFunction(sort, dataKey, sortDirection)}
                >
                    {label}
                </TableSortLabel>
            ) : (
                label
            )

        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{ height: headerHeight }}
            >
                {inner}
            </TableCell>
        )
    }

    render() {
        const { classes, columns, params, sortFunction, data } = this.props

        return (
            <div className={classes.documentTableWrapper}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            className={classes.table}
                            height={height}
                            width={width}
                            rowCount={data.length}
                            rowClassName={this.getRowClassName}
                            rowRenderer={defaultTableRowRenderer}
                            rowGetter={({ index }) => data[index]}
                            rowHeight={56}
                            headerHeight={56}
                        >
                            {columns.map(({ dataKey, ...other }, index) => (
                                <Column
                                    key={dataKey}
                                    headerRenderer={headerProps =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                            params: params,
                                            sortFunction: sortFunction,
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={this.cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            ))}
                        </Table>
                    )}
                </AutoSizer>
            </div>
        )
    }
}

const WrappedVirtualizedTable = withStyles(tableStyles)(MuiVirtualizedTable)

const DocumentDetailView = ({ classes, viewData, params }) => {
    const [paramsToSort, setParams] = useState(params)
    const sortAsc = type => {
        return viewData.sort((a, b) => a[type] - b[type])
    }

    const sortDesc = type => {
        return viewData.sort((a, b) => a[type] - b[type]).reverse()
    }

    const sortWithoutCall = (type, sortDirection) => {
        if (sortDirection === 'asc') {
            return sortAsc(type)
        }
        if (sortDirection === 'desc') {
            return sortDesc(type)
        }
    }

    const sortFunction = (currentSortKey, sortKey, sortDirection) => {
        let newSortDirection = 'asc'

        if (currentSortKey === sortKey) {
            if (sortDirection === 'asc') {
                newSortDirection = 'desc'
            }
            if (sortDirection === 'desc') {
                newSortDirection = 'asc'
            }
        }

        const newParams = {
            sort: sortKey,
            sortDirection: newSortDirection,
        }

        //save new sort to store
        if (sortKey === 'firstOpened' || sortKey === 'lastOpened') {
            sortWithoutCall(sortKey, newSortDirection)
            setParams(newParams)
        }
    }

    return (
        <div className={classes.detailViewRoot}>
            <div className={classes.detailViewTitle}>
                <Typography variant="h6">
                    <Translate id="documents_label_all_results_for" />
                    {` ${viewData[0].name}`}
                </Typography>
            </div>
            <div className={classes.detailViewTitle} style={{ display: 'flex' }}>
                <Typography className={classes.detailViewInfoText}>
                    <Translate id="datatable_header_available" />:
                    {viewData[0].releaseDate ? dateParser(viewData[0].releaseDate * 1000) : '/'}
                </Typography>
                <Typography className={classes.detailViewInfoText}>
                    <Translate id="datatable_header_valid_from" />:
                    {viewData[0].validFrom ? dateParser(viewData[0].validFrom * 1000) : '/'}
                </Typography>
                <Typography className={classes.detailViewInfoText}>
                    <Translate id="datatable_header_valid_to" />:
                    {viewData[0].validTo ? dateParser(viewData[0].validTo * 1000) : '/'}
                </Typography>
            </div>
            {viewData.length > 0 && (
                <Paper className={classes.documentTableWrapper}>
                    <Translate>
                        {({ translate }) => (
                            <WrappedVirtualizedTable
                                data={viewData}
                                onRowClick={() => null}
                                params={paramsToSort}
                                sortFunction={sortFunction}
                                columns={[
                                    {
                                        width: 240,
                                        flexGrow: 3,
                                        label: translate('datatable_header_name'),
                                        dataKey: 'idfNumber',
                                    },
                                    {
                                        width: 120,
                                        flexGrow: 1,
                                        label: translate('datatable_header_first_opened'),
                                        dataKey: 'firstOpened',
                                    },
                                    {
                                        width: 120,
                                        flexGrow: 1,
                                        label: translate('datatable_header_ptcar_blank'),
                                        dataKey: 'firstOpenedInEx',
                                    },
                                    {
                                        width: 120,
                                        flexGrow: 1,
                                        label: translate('datatable_header_last_opened'),
                                        dataKey: 'lastOpened',
                                    },
                                    {
                                        width: 120,
                                        flexGrow: 1,
                                        label: translate('datatable_header_ptcar_blank'),
                                        dataKey: 'lastOpenedInEx',
                                    },
                                    {
                                        width: 120,
                                        flexGrow: 1,
                                        label: translate('datatable_header_exceed'),
                                        dataKey: 'exceedReadDays',
                                    },
                                ]}
                            />
                        )}
                    </Translate>
                </Paper>
            )}
        </div>
    )
}

DocumentDetailView.propTypes = {
    classes: object.isRequired,
    viewData: array.isRequired,
    currentTime: number.isRequired,
    params: object,
    storePar: object,
}
MuiVirtualizedTable.propTypes = {
    classes: object,
    data: array,
    currentTime: number,
    params: object,
    storePar: object,
    rowClassName: string,
    onRowClick: func,
    disableSort: func,
    rowHeight: number,
    headerHeight: number,
    columns: array,
    sortFunction: func,
}

export default compose(withLocalize, withStyles(styles), withStore, withTake('currentTime'))(DocumentDetailView)
