import React from 'react'
import { object, func, string, bool, number } from 'prop-types'
import { withLocalize, Translate } from 'react-localize-redux'
import { compose } from 'recompose'
import { withStore, util, enums, withStatus, withClear } from 'react-ion-store'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import getNormalizedMessage from 'utils/getNormalizedMessage'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import PersonSearcher from 'components/PersonSearcher'
import PrimaryButton from 'components/PrimaryButton'
import IconButton from 'components/IconButton'
import ErrorMessage from 'components/ErrorMessage'
import AssignDriverCall from '../../AssignDriverDialog/AssignDriverCall'
import SignInOutCall from '../../SignInOutDialog/SignInOutCall'
import { ReplaceDialog } from '../../Dialogs/ReplaceDialog'
import unAssignDriverCall from 'utils/unAssignDriverCall'
import assignTeacherService from './assignTeacherService'
import isDepotLeerling from '../isDepotLeerling'

import styles from './styles'
import Loading from 'components/Loading'

const resetState = {
    selectedName: '',
    selectedProfile: undefined,
    allreadyClosed: false,
}
const {
    CallStatus: { LOADING, READY },
} = enums

class SignedOnDetailView extends React.PureComponent {
    static propTypes = {
        classes: object.isRequired,
        closeDrawer: func.isRequired,
        performance: object.isRequired,
        __ion_status: object.isRequired,
        store: object.isRequired,
        clearAssignedCall: func,
        printButtonHandler: func,
        history: object,
        onStartReasonChange: func,
        reasonStartTimeChanged: string,
        adjustStartTime: func,
        translate: func,
        adjustError: string,
        adjustLoading: bool,
        driverRole: number,
        isStartTimeChanged: bool,
    }

    state = {
        ...resetState,
        actionWasSet: false,
        isOpen: false,
        savedDriver: {},
        unassignDisable: false,
        assignTeacherDisable: false,
        teacherError: '',
        responseData: [],
        unassignDriverError: '',
    }

    setName = selectedProfile =>
        this.setState({
            selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
            selectedProfile,
            readStateWasSet: false,
        })

    clearName = () => this.setState(resetState)

    persistAssignDriver = (idf, id, performanceIdfNumber, replace = false) => {
        this.setState(
            {
                ...resetState,
                actionWasSet: 'assignDriverCall',
                savedDriver: { idf, id, performanceIdfNumber },
                replace: replace,
                allreadyClosed: false,
                teacherError: '',
            },
            () => {
                const assignDriverCall = util.CreateStoreObject({ idf, id, performanceIdfNumber, replace })
                this.props.store.call({ assignDriverCall })
            }
        )
    }
    getLoadingComponent = () => {
        return (
            <div style={{ height: '100px' }}>
                <Loading />
            </div>
        )
    }
    async assignTeacher(teacherIdf, performanceId, studentIdf) {
        this.setState({ assignTeacherDisable: true, teacherError: '' })
        let response = await assignTeacherService(teacherIdf, performanceId, studentIdf)
        if (response.status === 200 || response.status === 204) {
            this.props.closeDrawer()
        } else {
            this.setState({ teacherError: response.data.Message })
        }
        this.setState({ assignTeacherDisable: false })
    }
    signOutDriver = (idf, id, isPlanned) => () => {
        this.setState({ teacherError: '', actionWasSet: 'signInOutCall' }, () => {
            const signInOutCall = util.CreateStoreObject({ idf, id, type: 'signOut', isPlanned })
            this.props.store.call({ signInOutCall })
        })
    }

    handleClose = () => {
        this.setState({ isOpen: false, allreadyClosed: true, responseData: [] })
        this.props.clearAssignedCall()
    }

    handleOpen = data => {
        this.setState({ isOpen: true, responseData: data })
    }

    async unAssignDriver(idf, performanceId) {
        this.setState({ teacherError: '', unassignDisable: true })
        const response = await unAssignDriverCall(idf, performanceId)
        const { translate } = this.props
        if (response.status === 200 || response.status === 204) {
            this.setState({ unassignDisable: false })
            this.props.closeDrawer(true)
        }
        if (response === 'error') {
            this.setState({ unassignDriverError: translate('roles_error'), unassignDisable: false })
        }
    }
    componentDidUpdate(prevProps) {
        const { actionWasSet } = this.state

        if (
            actionWasSet &&
            prevProps.__ion_status[actionWasSet] &&
            prevProps.__ion_status[actionWasSet] !== READY &&
            this.props.__ion_status[actionWasSet] === READY
        ) {
            this.props.closeDrawer(true)
        }
    }

    render() {
        const {
            performance,
            classes,
            __ion_status,
            onStartReasonChange,
            reasonStartTimeChanged,
            adjustStartTime,
            adjustError,
            adjustLoading,
            printButtonHandler,
            isStartTimeChanged,
        } = this.props
        const {
            selectedName,
            selectedProfile,
            isOpen,
            savedDriver,
            allreadyClosed,
            unassignDisable,
            teacherError,
            unassignDriverError,
        } = this.state
        const { signInOutCall, assignDriverCall } = __ion_status

        if (assignDriverCall === 4 && this.props.store.assignDriverCall !== undefined && !allreadyClosed) {
            if (
                getNormalizedMessage(this.props.store.assignDriverCall._root.entries[2][1].response.data).indexOf(
                    'Profile already has an overlapping performance'
                ) !== -1
            ) {
                this.handleOpen(this.props.store.assignDriverCall._root.entries[2][1].response.data)
            }
        }
        const isLoading =
            assignDriverCall === LOADING || signInOutCall === LOADING || this.state.assignTeacherDisable || unassignDisable

        return (
            <Translate>
                {({ translate }) => (
                    <React.Fragment>
                        <Typography variant="h6">{translate('performances_label_accountability')}</Typography>
                        <hr style={{ marginBottom: 16 }} />
                        <div className={classes.mb}>
                            <TextField
                                required={isStartTimeChanged && reasonStartTimeChanged.length === 0}
                                fullWidth={true}
                                name="districtFilter"
                                label={translate('start_label')}
                                value={reasonStartTimeChanged}
                                onChange={e => onStartReasonChange(e)}
                                // style={{ marginBottom: 16 }}
                                disabled={checkFunctionalityDisabled(
                                    this.props.store,
                                    'adapt hours',
                                    'performances.functionality.sidebar'
                                )}
                            />
                            {isStartTimeChanged && reasonStartTimeChanged.length === 0 && (
                                <Typography classes={{ root: classes.mandatory_field }}>
                                    {translate('compulsory_field')}
                                </Typography>
                            )}

                            <div className={classes.mb} />
                            <PrimaryButton
                                style={{ width: '100%' }}
                                className={classes.button}
                                title={translate('management_button_edit')}
                                onClick={() => adjustStartTime(performance)}
                                disabled={
                                    checkFunctionalityDisabled(
                                        this.props.store,
                                        'adapt hours',
                                        'performances.functionality.sidebar'
                                    ) ||
                                    reasonStartTimeChanged.length === 0 ||
                                    isLoading
                                }
                            />
                            {adjustError.length > 0 && <ErrorMessage error={adjustError} />}
                        </div>
                        <div className={classes.mb} />

                        {adjustLoading && this.getLoadingComponent()}
                        <Typography variant="h6">
                            {this.props.driverRole === 2 && isDepotLeerling(performance.depotName)
                                ? translate('performace_drawer_select_teacher')
                                : translate('performances_label_change_driver')}
                        </Typography>
                        <hr style={{ marginBottom: 16 }} />
                        <div
                            className={classes.mb}
                            name="PersonSearcherWrapper"
                            tabIndex={0}
                            style={{ position: 'relative' }}
                        >
                            <PersonSearcher
                                selectedName={selectedName}
                                setName={this.setName}
                                clearName={this.clearName}
                                type="app"
                                disabled={checkFunctionalityDisabled(
                                    this.props.store,
                                    'change driver',
                                    'performances.functionality.sidebar'
                                )}
                            />
                        </div>
                        <div className={classes.mb}>
                            {this.props.driverRole === 2 && isDepotLeerling(performance.depotName) ? (
                                <PrimaryButton
                                    className={classes.fullButton}
                                    disabled={!selectedName || isLoading}
                                    onClick={() => {
                                        if (selectedProfile)
                                            this.assignTeacher(
                                                selectedProfile.idfNumber,
                                                performance.id,
                                                performance.idfNumber
                                            )
                                    }}
                                >
                                    {translate('performace_drawer_assign_teacher')}
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton
                                    className={classes.fullButton}
                                    disabled={!selectedName || isLoading}
                                    onClick={() => {
                                        if (selectedProfile)
                                            this.persistAssignDriver(
                                                selectedProfile.idfNumber,
                                                performance.id,
                                                performance.idfNumber
                                            )
                                    }}
                                >
                                    {translate('performances_label_change_driver')}
                                </PrimaryButton>
                            )}
                        </div>

                        {isLoading && this.getLoadingComponent()}
                        {teacherError.length > 1 && <ErrorMessage spacing={false} error={teacherError} />}

                        {assignDriverCall === 4 &&
                            this.props.store.assignDriverCall !== undefined &&
                            getNormalizedMessage(
                                this.props.store.assignDriverCall._root.entries[2][1].response.data
                            ).indexOf('Action not allowed') !== -1 && <ErrorMessage error={translate('roles_error')} />}

                        <div className={classes.mb}>
                            <PrimaryButton
                                color="secondary"
                                className={classes.fullButton}
                                disabled={
                                    isLoading ||
                                    checkFunctionalityDisabled(
                                        this.props.store,
                                        'unassign_driver',
                                        'performances.functionality.sidebar'
                                    )
                                }
                                onClick={() => this.unAssignDriver(performance.idfNumber, performance.id)}
                            >
                                {translate('performances_label_unassign_driver')}
                            </PrimaryButton>
                            {unassignDriverError.length > 1 && <ErrorMessage spacing={false} error={unassignDriverError} />}
                        </div>

                        <Typography variant="h6">{translate('performances_label_sign_out_user')}</Typography>
                        <hr style={{ marginBottom: 16 }} />
                        <div className={classes.flexAlign}>
                            <IconButton
                                className={classes.halfButton}
                                icon="stop"
                                disabled={
                                    checkFunctionalityDisabled(
                                        this.props.store,
                                        'signoff',
                                        'performances.functionality.sidebar'
                                    ) || isLoading
                                }
                                onClick={this.signOutDriver(performance.idfNumber, performance.id, false)}
                                title={translate('performances_label_sign_out_user_now')}
                            />
                            <IconButton
                                className={classes.halfButton}
                                icon="stop"
                                disabled={
                                    checkFunctionalityDisabled(
                                        this.props.store,
                                        'signoff',
                                        'performances.functionality.sidebar'
                                    ) || isLoading
                                }
                                onClick={this.signOutDriver(performance.idfNumber, performance.id, true)}
                                title={translate('performances_label_sign_out_user_planned')}
                            />
                        </div>

                        {signInOutCall === 4 &&
                            this.props.store.signInOutCall !== undefined &&
                            getNormalizedMessage(this.props.store.signInOutCall._root.entries[2][1].response.data).indexOf(
                                'Action not allowed'
                            ) !== -1 && <ErrorMessage error={translate('roles_error')} />}

                        {assignDriverCall === 4 &&
                            this.props.store.assignDriverCall !== undefined &&
                            getNormalizedMessage(
                                this.props.store.assignDriverCall._root.entries[2][1].response.data
                            ).indexOf('No current profile found in request') !== -1 && (
                                <ErrorMessage spacing={false} error="No current profile found in request" />
                            )}

                        <AssignDriverCall />
                        <SignInOutCall />
                        <ReplaceDialog
                            __ion_status={__ion_status}
                            open={isOpen}
                            handleClose={this.handleClose}
                            translate={translate}
                            savedDriver={savedDriver}
                            util={util}
                            store={this.props.store}
                            responseData={this.state.responseData}
                        />
                        <PrimaryButton
                            disabled={checkFunctionalityDisabled(
                                this.props.store,
                                'print_perf_detail',
                                'performances.functionality.sidebar'
                            )}
                            className={classes.fullButton}
                            onClick={() => printButtonHandler(performance)}
                        >
                            <Typography>
                                <Translate id="print_performances" />
                            </Typography>
                        </PrimaryButton>
                    </React.Fragment>
                )}
            </Translate>
        )
    }
}

export default compose(
    withStyles(styles),
    withLocalize,
    withStore,
    withStatus,
    withClear('clearAssignedCall', 'assignDriverCall')
)(SignedOnDetailView)
