import React, { Component } from 'react'
import { Paper, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import Schedule from '@material-ui/icons/Schedule'
import Train from '@material-ui/icons/Train'
import LocationOn from '@material-ui/icons/LocationOn'
import Contact from '@material-ui/icons/AccountCircle'
import Phone from '@material-ui/icons/LocalPhone'
import InsertLink from '@material-ui/icons/InsertLink'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

export class IconBoxEdit extends Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        icon: PropTypes.string,
        classes: PropTypes.any,
    }

    returnIcon = (icon, classes) => {
        switch (icon) {
            case 'train':
                return <Train className={classes.icon} />
            case 'location':
                return <LocationOn className={classes.icon} />
            case 'contact':
                return <Contact className={classes.icon} />
            case 'link':
                return <InsertLink className={classes.icon} />
            case 'phone':
                return <Phone className={classes.icon} />
            default:
                return <Schedule className={classes.icon} />
        }
    }

    render() {
        const { icon, classes, children } = this.props

        return (
            <React.Fragment>
                <Paper>
                    <Grid container spacing={2} className={classes.blackBox}>
                        <Grid item md={2} xs={3} className={classes.centerIcon}>
                            {this.returnIcon(icon, classes)}
                        </Grid>
                        <Grid item md={10} xs={9}>
                            {children}
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(IconBoxEdit)
