import React, { useEffect, useState } from 'react'
import styles from './styles'
import { object, func, string, number } from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'
import Header from 'components/Header'
import { compoDateFromEpochSeconds, dateTimeFromEpochSeconds } from 'utils/dateParser'
import getCompositionByTrain from '../services/getCompositionByTrain'
import CommonCompositionView from '../CompositionView/CommonCompositionView'
import Loading from 'components/Loading'
import getLitteras from '../services/getLitteras'
import getVehicleNumberSubtype from '../services/getVehicleNumberSubtype'
import getVehicleNumberUnits from '../services/getVehicleNumberUnits'
import getMaterialTypes from '../services/getMaterialTypes'
import getMaterialSubTypes from '../services/getMaterialSubTypes'
import getDefectTypes from '../services/getDefectTypes'
import { localDateFormatter } from 'utils/timeParser'
import updateCompositionByTrainNumber from '../services/updateCompositionByTrainNumber'
import checkForCompositionAvailability from '../services/checkForCompositionAvailability'
import { useLocation } from 'react-router-dom'
import getNonDrivingTask from '../services/getNonDrivingTask'
import updateNonDrivingById from '../services/updateNonDrivingById'
import checkForCompositionAvailabilityByTrain from '../services/checkForCompositionAvailabilityByTrain'

const CompositionWithPtcar = props => {
    const [compositionData, setCompositionData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [litteras, setLitteras] = useState([])
    const [vehicleNumberSubType, setVehicleNumberSubType] = useState([])
    const [vehicleNumberUnit, setVehicleNumberUnit] = useState([])
    const [materialTypes, setMaterialTypes] = useState([])
    const [materialSubTypes, setMaterialSubTypes] = useState([])
    const [defectTypes, setDefectTypes] = useState([])

    const location = useLocation()

    const fetchData = async (mounted, trainNumber, fromPtcar, date, idfNumber, toPtcar) => {
        setIsLoading(true)
        getCompositionByTrain(trainNumber, fromPtcar, date, idfNumber, toPtcar)
            .then(response => {
                let results = Promise.all([
                    getLitteras(),
                    getVehicleNumberSubtype(),
                    getVehicleNumberUnits(),
                    getMaterialTypes(),
                    getMaterialSubTypes(),
                    getDefectTypes(),
                ])
                results.then(values => {
                    let litteraResponse = values[0]
                    let subTypeResponse = values[1]
                    let vehicleUnitResponse = values[2]
                    let materialTypesResponse = values[3]
                    let materialSubTypesResponse = values[4]
                    let defectTypesResponse = values[5]
                    if (mounted) {
                        setCompositionData(response.data)
                        setVehicleNumberSubType(subTypeResponse)
                        setVehicleNumberUnit(vehicleUnitResponse)
                        setLitteras(litteraResponse)
                        setMaterialTypes(materialTypesResponse)
                        setMaterialSubTypes(materialSubTypesResponse)
                        setDefectTypes(defectTypesResponse)
                        setIsLoading(false)
                    }
                })
            })
            .catch(e => {
                console.error(e)
                setIsLoading(false)
            })
    }

    const fetchNonDrivingData = async (nonDrivingId, idfNumber) => {
        setIsLoading(true)
        getNonDrivingTask(nonDrivingId, idfNumber)
            .then(res => {
                if (res.deviceUserName) {
                    let results = Promise.all([
                        getLitteras(),
                        getVehicleNumberSubtype(),
                        getVehicleNumberUnits(),
                        getMaterialTypes(),
                        getMaterialSubTypes(),
                        getDefectTypes(),
                    ])

                    results.then(values => {
                        let litteraResponse = values[0]
                        let subTypeResponse = values[1]
                        let vehicleUnitResponse = values[2]
                        let materialTypesResponse = values[3]
                        let materialSubTypesResponse = values[4]
                        let defectTypesResponse = values[5]
                        setCompositionData(res)
                        setVehicleNumberSubType(subTypeResponse)
                        setVehicleNumberUnit(vehicleUnitResponse)
                        setLitteras(litteraResponse)
                        setMaterialTypes(materialTypesResponse)
                        setMaterialSubTypes(materialSubTypesResponse)
                        setDefectTypes(defectTypesResponse)
                        setIsLoading(false)
                    })
                } else {
                    setCompositionData(false)
                    setIsLoading(false)
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        let mounted = true
        if (location.pathname.includes('NonDriving')) {
            // eslint-disable-next-line no-unused-vars
            const { id, idfNumber } = props.match.params
            fetchNonDrivingData(id, idfNumber)
        } else {
            const { trainNumber, fromPtcar, date, idfNumber, toPtcar } = props.match.params
            let compoDate = compoDateFromEpochSeconds(parseInt(date))
            fetchData(mounted, trainNumber, fromPtcar, compoDate, idfNumber, toPtcar)
        }

        return () => (mounted = false)
    }, [])

    useEffect(() => {
        const { trainNumber, date } = props.match.params
        let compoDate = compoDateFromEpochSeconds(parseInt(date))
        let handle = setInterval(() => {
            if (compositionData && !isLoading) {
                if (location.pathname.includes('NonDriving')) {
                    // eslint-disable-next-line no-unused-vars
                    const { id, idfNumber } = props.match.params
                    fetchNonDrivingData(id, idfNumber)
                } else {
                    let from = compositionData.departurePtcarUic
                    let to = compositionData.arrivalPtcarUic
                    const { performanceId, taskId, idfNumber } = props.match.params
                    if (performanceId == undefined) {
                        checkForCompositionAvailabilityByTrain(trainNumber, compoDate, from, to).then(response => {
                            if (response?.data !== compositionData?.compositionId) {
                                getCompositionByTrain(trainNumber, from, compoDate).then(composRes => {
                                    if (composRes) setCompositionData(composRes.data)
                                })
                            }
                        })
                    } else {
                        checkForCompositionAvailability(performanceId, taskId, idfNumber)
                            .then(response => {
                                if (response?.data !== compositionData?.compositionId) {
                                    getCompositionByTrain(trainNumber, from, compoDate).then(composRes => {
                                        if (composRes) setCompositionData(composRes.data)
                                    })
                                }
                            })
                            .catch(e => console.error('error', e))
                    }
                }
            }
        }, 30000)
        return () => clearInterval(handle)
    }, [compositionData, isLoading])

    const onSaveClicked = async data => {
        if (location.pathname.includes('NonDriving')) {
            // eslint-disable-next-line no-unused-vars
            const { id, idfNumber } = props.match.params
            setIsLoading(true)
            updateNonDrivingById(id, data)
                .then(() => {
                    fetchNonDrivingData(id, idfNumber)
                })
                .catch(err => {
                    setIsLoading(false)
                    console.error(err)
                })
        } else {
            const { trainNumber, date } = props.match.params
            let from = data.departurePtcarUic
            let to = data.arrivalPtcarUic
            data.deviceTime = localDateFormatter(new Date().getTime())
            setIsLoading(true)
            let updateResponse = updateCompositionByTrainNumber(trainNumber, date, from, to, data)
            updateResponse
                .then(res => {
                    setIsLoading(false)
                    setCompositionData(res.data)
                })
                .catch(e => {
                    setIsLoading(false)
                    console.error(e)
                })
        }
    }

    const getTitle = () => {
        const { locationFrom, locationTo } = props.match.params
        let from = ''
        let to = ''
        let natureCode = compositionData?.natureCode? compositionData.natureCode: ''
        if (locationFrom) {
            from = window.atob(locationFrom)
        }
        if (locationTo) to = `-${window.atob(locationTo)}`

        return `${dateTimeFromEpochSeconds(parseInt(props.match.params.date))} ${
            compositionData?.trainNumber ? natureCode + compositionData.trainNumber : ''
        } ${from}${to}`
    }
    return (
        <>
            {isLoading && <Loading />}

            {!isLoading && (
                <>
                    <Header showTime={false} isMenu isNothing title={getTitle()} />

                    {compositionData ? (
                        <CommonCompositionView
                            onSaveClicked={onSaveClicked}
                            compositionData={compositionData}
                            litteras={litteras}
                            vehicleNumberSubType={vehicleNumberSubType}
                            vehicleNumberUnit={vehicleNumberUnit}
                            materialTypes={materialTypes}
                            materialSubTypes={materialSubTypes}
                            defectTypes={defectTypes}
                        />
                    ) : (
                        'No Data'
                    )}
                </>
            )}
        </>
    )
}
CompositionWithPtcar.propTypes = {
    classes: object,
    data: object,
    translate: func,
    activeLanguage: object,
    trainNumber: string,
    date: number,
    ptcar: string,
    match: object,
}
export default compose(withLocalize, withStyles(styles))(CompositionWithPtcar)
