import React, { Component } from 'react'
import { PropTypes, func, object, array, string } from 'prop-types'

import SearchIcon from '@material-ui/icons/Search'
import Select from 'react-select'

export default class PtcarSelect extends Component {
    static propTypes = {
        classes: object,
        handleSearchValue: func,
        data: array,
        ptcarSelectValue: PropTypes.oneOfType([object, string]),
        register: func,
        errors: object,
        triggerValidation: func,
    }
    render() {
        const { classes, handleSearchValue, data, ptcarSelectValue, register, errors, triggerValidation } = this.props
        const searchList = data
        const customStyles = {
            option: (provided, state) => ({
                fontFamily: 'Roboto',
                background: '#424242',
                borderBottom: '1px solid grey',
                fontWeight: state.isSelected ? '600' : '8OO',
                color: 'white',
                padding: 20,
            }),
            menu: provided => ({
                ...provided,
                left: 0,
                background: '#424242',
                fontFamily: 'Roboto',
            }),
            input: provided => ({
                ...provided,
                fontFamily: 'Roboto',
                background: '#424242',
                color: '#979797',
            }),
            container: provided => ({
                ...provided,
                paddingLeft: '35px',
                border: 'none',
                background: 'transparent',
                '&:focus': { outline: 'none' },
            }),
            control: provided => ({
                ...provided,
                background: 'transparent',
                border: 0,
                fontFamily: 'Roboto',
                // This line disable the blue border
                boxShadow: 0,
                '&:hover': {
                    border: 0,
                },
            }),
            valueContainer: provided => ({
                ...provided,
                background: '#424242',
                color: '#979797',
                fontFamily: 'Roboto',
            }),
            singleValue: provided => ({
                ...provided,
                color: '#979797',
                fontFamily: 'Roboto',
            }),
        }

        return (
            <div className={errors.lineNumbers ? classes.searchError : classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>

                <Select
                    styles={customStyles}
                    isSearchable={true}
                    blurInputOnSelect={true}
                    isClearable={true}
                    placeholder="Ptcar"
                    name="ptcar"
                    value={ptcarSelectValue}
                    options={searchList}
                    onChange={e => {
                        if (errors.lineNumbers) {
                            handleSearchValue(e, triggerValidation)
                            return
                        }
                        handleSearchValue(e)
                    }}
                />
                {/* Hidden input field to force validation on the react-select */}
                <input
                    tabIndex={-1}
                    readOnly
                    autoComplete="off"
                    name="ptcar"
                    style={{ display: 'none' }}
                    value={typeof ptcarSelectValue === 'object' ? ptcarSelectValue?.code : ptcarSelectValue}
                    ref={register !== undefined ? register({ required: true }) : null}
                />
            </div>
        )
    }
}
