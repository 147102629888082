import React from 'react'
import { object, bool, func, string } from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'

import DriveModeLogsView from '../DriveModeLogsView'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import { DateTime } from 'luxon'
import { isCompositionViewAllowed } from '../CompositionView/Utils/isCompoEditingAllowed'
import ErrorMessage from 'components/ErrorMessage'

const returnTaskStatus = (status, isAbolished, translate) => {
    if (isAbolished) {
        return translate('performance_task_label_abolished')
    }

    switch (status) {
        case 1:
            return translate('performance_task_label_started')
        case 2:
            return translate('performance_task_label_finished')

        case 0:
            return translate('performance_task_label_to_start')
        default:
            return 'N/A'
    }
}

const returnTrainReadyStatus = (time, signalType, translate) => {
    if (time === null && signalType === null) return 'N/A'
    if (time !== null && signalType === 'TrainReady') {
        const timestring = DateTime.fromSeconds(time).toFormat('HH:mm')
        return `${translate('readyfordeparture_train_ready_to_go_at')} ${timestring}`
    }
    if (time !== null && signalType === 'TrainNotReady') {
        const timestring = DateTime.fromSeconds(time).toFormat('HH:mm')
        return `${translate('readyfordeparture_train_not_ready_to_go_at')} ${timestring}`
    }
    if (time === null && signalType === 'TrainNotReady') return translate('readyfordeparture_train_not_ready_to_go_at')
}

const TaskDetailDrawerView = ({
    classes,
    open,
    onClose,
    data,
    store,
    translate,
    handleReadyToGo,
    handleConfirmTrainHasLeft,
    errorMsg,
    isLoading,
}) => {
    return (
        <Drawer open={open} anchor="right" style={{ zIndex: 1500 }} onClose={onClose}>
            <div className={classes.drawerRoot}>
                <div>
                    <Typography variant="h6" style={{ marginBottom: 24 }}>
                        {translate('train_task_detail_title')}
                    </Typography>
                    <Typography variant="h6">{translate('performances_label_performance_detail_general_info')}</Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <div className={classes.mb}>
                        <Typography variant="subtitle1" className={classes.boldText}>
                            {data?.locationFrom && data?.locationTo && data?.trainComposed
                                ? `${data?.trainComposed} ${data?.locationFrom} - ${data?.locationTo} `
                                : data?.performanceId}
                        </Typography>
                    </div>
                    <div className={classes.mb}>
                        <Typography variant="body2" className={classes.boldText}>
                            {data?.name && data?.firstName ? `${data?.firstName} ${data?.name}` : 'Treinbestuurder 1'}
                        </Typography>
                        <Typography variant="caption">{data?.idfNumber ? data?.idfNumber : 'NotAssigned'}</Typography>
                    </div>
                    <div className={classes.mb}>
                        <Typography variant="body2" className={classes.boldText}>
                            {translate('performance_task_label_contact_phone')}
                        </Typography>
                        <Typography variant="caption">
                            {data?.telephoneNumber ? (
                                <a style={{ color: 'white' }} href={`tel:${data?.telephoneNumber}`}>
                                    {data?.telephoneNumber}
                                </a>
                            ) : (
                                '--'
                            )}
                        </Typography>
                    </div>
                    <div className={classes.performanceInfo} style={{ marginBottom: 12 }}>
                        <div className={classes.performanceInfoCell}>
                            <Typography variant="body2" className={classes.boldText}>
                                {translate('datatable_header_planned_start')}
                            </Typography>
                            <Typography variant="caption">
                                {data?.plannedStartTime ? data?.plannedStartTime.substr(0, 5) : 'N/A'}
                            </Typography>
                        </div>
                        <div className={classes.performanceInfoCell}>
                            <Typography variant="body2" className={classes.boldText}>
                                {translate('datatable_header_planned_end')}
                            </Typography>
                            <Typography variant="caption">
                                {data?.plannedEndTime ? data?.plannedEndTime.substr(0, 5) : 'N/A'}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.performanceInfo}>
                        <div className={classes.performanceInfoCell}>
                            <Typography variant="body2" className={classes.boldText}>
                                {translate('performances_label_performance_detail_real_start')}
                            </Typography>
                            <Typography variant="caption">
                                {data?.realStartTime ? data?.realStartTime.substr(0, 5) : 'N/A'}
                            </Typography>
                        </div>
                        <div className={classes.performanceInfoCell}>
                            <Typography variant="body2" className={classes.boldText}>
                                {translate('performances_label_performance_detail_real_end')}
                            </Typography>
                            <Typography variant="caption">
                                {data?.realEndTime ? data?.realEndTime.substr(0, 5) : 'N/A'}
                            </Typography>
                        </div>
                    </div>
                    <Grid style={{ marginTop: '16px' }} item xs={12} className={classes.mb}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={checkFunctionalityDisabled(
                                store,
                                'view',
                                'performances.functionality.management.performanceDetail'
                            )}
                            onClick={() =>
                                window.open(
                                    data?.idfNumber
                                        ? `/performances/detail/${data.performanceId}/profile/${data?.idfNumber}`
                                        : `/performances/detail/${data.performanceId}/notAssigned`
                                )
                            }
                        >
                            {data?.symbolicNameDepot && data?.performanceNumber
                                ? `${data?.symbolicNameDepot} ${data?.performanceNumber}`
                                : translate('perfByTrain_drawer_open_performance_button')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.mb}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={checkFunctionalityDisabled(
                                store,
                                'taskDetail',
                                'performances.functionality.management.performanceDetail'
                            )}
                            onClick={() =>
                                window.open(
                                    data?.idfNumber
                                        ? // eslint-disable-next-line max-len
                                          `/performances/detail/${data?.performanceId}/profile/${data?.idfNumber}/task/${data?.taskId}`
                                        : `/performances/detail/${data?.performanceId}/notAssigned/task/${data?.taskId}`
                                )
                            }
                        >
                            {data?.code ? data?.code : translate('perfByTrain_drawer_open_task_button')}
                        </Button>
                        <Button
                            style={{ marginLeft: '8px' }}
                            variant="contained"
                            color="primary"
                            disabled={!isCompositionViewAllowed({ store })}
                            onClick={() => {
                                window.open(
                                    `/compositionPtcarView/${data.performanceId}/${data.taskId}/${encodeURIComponent(
                                        data.trainComposed
                                    )}/${data.plannedStartDateTime}/${data.locationFromPtCarUic}/${
                                        data.idfNumber
                                    }/${window.btoa(data?.locationFrom)}/${encodeURIComponent(
                                        data?.locationToPtCarUic
                                    )}/${window.btoa(data?.locationTo)}`
                                )
                            }}
                        >
                            {translate('label_composition')}
                        </Button>
                    </Grid>
                    <div className={classes.mb}>
                        <Typography variant="body2" className={classes.boldText}>
                            {translate('datatable_header_task_status')}
                        </Typography>
                        <Typography variant="caption">
                            {returnTaskStatus(data?.status, data?.isAbolished, translate)}
                        </Typography>
                    </div>
                    <div className={classes.mb}>
                        <Typography variant="body2" className={classes.boldText}>
                            {translate('performances_label_performance_detail_signin')}
                        </Typography>
                        <Typography variant="caption">
                            {returnTrainReadyStatus(
                                data?.trainDepartureOriginTimeStamp,
                                data?.trainDepartureSignalType,
                                translate
                            )}
                        </Typography>
                    </div>

                    <div style={{ display: 'inline-grid' }}>
                        {((data.trainDepartureSignalType !== 'TrainReady' && data.status === 0) || data.status === 1) && (
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={
                                    checkFunctionalityDisabled(
                                        store,
                                        'readyToGo',
                                        'performances.functionality.management.tasks'
                                    ) || isLoading
                                }
                                onClick={() => handleReadyToGo(data.performanceId, data.taskId, data.idfNumber)}
                            >
                                {translate('label_ready_to_leave')}
                            </Button>
                        )}

                        {!data.departureAtFirstPtCar && (
                            <Button
                                style={{ marginTop: '8px' }}
                                variant="contained"
                                color="primary"
                                disabled={
                                    checkFunctionalityDisabled(
                                        store,
                                        'hasTrainLeft',
                                        'performances.functionality.management.tasks'
                                    ) || isLoading
                                }
                                onClick={() => handleConfirmTrainHasLeft(data.performanceId, data.taskId, data.idfNumber)}
                            >
                                {translate('label_confirm_departure')}
                            </Button>
                        )}
                        {errorMsg.length > 0 && <ErrorMessage error={errorMsg} spacing={false} />}
                    </div>
                    {data && <DriveModeLogsView data={data} />}
                </div>
            </div>
        </Drawer>
    )
}

TaskDetailDrawerView.propTypes = {
    classes: object.isRequired,
    data: object,
    store: object,
    open: bool.isRequired,
    onClose: func.isRequired,
    translate: func,
    handleReadyToGo: func,
    handleConfirmTrainHasLeft: func,
    errorMsg: string,
    isLoading: bool,
}

export default TaskDetailDrawerView
