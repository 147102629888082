import React, { Component } from 'react'
import TaskDetailHeader from './components/TaskDetailHeader'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import TaskDetailFooter from './components/TaskDetailFooter'

import styles from './styles'

export class TaskDetailView extends Component {
    static propTypes = {
        task: PropTypes.any.isRequired,
        children: PropTypes.any.isRequired,
        tasks: PropTypes.array,
        storage: PropTypes.object,
        history: PropTypes.object,
        classes: PropTypes.object,
        currentTask: PropTypes.string,
        isOpen: PropTypes.bool,
        isAbolishOpen: PropTypes.bool,
        handleClose: PropTypes.func,
        handleAbolishClose: PropTypes.func,
        handleOpen: PropTypes.func,
        handleAbolishOpen: PropTypes.func,
        performanceId: PropTypes.string,
        idfNumber: PropTypes.string,
        signOutTime: PropTypes.string,
        isDriveDetailsLoading: PropTypes.bool,
        refreshAfterBulkOperation: PropTypes.func,
    }

    componentDidMount() {
        this.refreshInterval = setInterval(() => {
            const { isOpen, isAbolishOpen } = this.props
            if (!isOpen && !isAbolishOpen) {
                this.props.storage.call('performanceDetail')
            }
        }, 60000)
        const performanceDetail = this.getPerformanceDetail()
        document.title = `${this.props.task.code} ${performanceDetail.depot}-${performanceDetail.performanceNumber} ${
            performanceDetail.name ? performanceDetail.name : 'notAssigned'
        }`
    }

    componentWillUnmount = () => {
        clearInterval(this.refreshInterval)
        const detail = this.getPerformanceDetail()
        if (detail)
            document.title = `${detail.depot}-${detail.performanceNumber} ${detail.name ? detail.name : 'notAssigned'}`
    }
    getPerformanceDetail() {
        return this.props.storage.get('performanceDetail')
    }

    render() {
        const {
            task,
            children,
            classes,
            tasks,
            currentTask,
            performanceId,
            handleClose,
            handleAbolishClose,
            isOpen,
            handleOpen,
            handleAbolishOpen,
            idfNumber,
            history,
            isAbolishOpen,
            signOutTime,
            refreshAfterBulkOperation,
        } = this.props
        return (
            <React.Fragment>
                <TaskDetailHeader
                    task={task}
                    storage={this.props.storage}
                    performanceId={performanceId}
                    handleClose={handleClose}
                    handleAbolishOpen={handleAbolishOpen}
                    handleAbolishClose={handleAbolishClose}
                    handleOpen={handleOpen}
                    isOpen={isOpen}
                    isAbolishOpen={isAbolishOpen}
                    history={history}
                    idfNumber={idfNumber}
                    signOutTime={signOutTime}
                    refreshAfterBulkOperation={refreshAfterBulkOperation}
                />
                <div className={classes.taskDetailViewBody}>{children}</div>
                <TaskDetailFooter
                    currentTask={currentTask}
                    tasks={tasks}
                    isDriveDetailsLoading={this.props.isDriveDetailsLoading}
                />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(TaskDetailView)
