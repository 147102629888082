import React from 'react'
import { func, object } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus, util } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'
import getNormalizedMessage from 'utils/getNormalizedMessage'

import axios from 'axios'
import { DateTime } from 'luxon'
import AddPerformanceDrawerView from './AddPerformanceDrawerView'
import ReplaceDriverDialog from './ReplaceDriverDialog'

import styles from './styles'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
const resetState = {
    selectedName: '',
    selectedProfile: '',
    errorMsg: '',
}
class AddPerformanceDrawer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            referenceOrg: ' ',
            referenceDepot: ' ',
            selectedDepot: '',
            plannedStartTime: DateTime.local(),
            plannedEndTime: DateTime.local(),
            errorMsg: '',
            selectedName: '',
            selectedProfile: {},
            isOpen: false,
            loading: false,
            performanceType: ' ',
            isThirdPartyPerformance: false,
        }
    }
    static propTypes = {
        onClose: func.isRequired,
        translate: func,
        history: object,
        store: object,
    }

    setName = selectedProfile => {
        this.setState({
            selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
            selectedProfile: selectedProfile,
        })
    }

    persist = type => props => {
        const toSetState = { [type]: props }
        if (type === 'referenceOrg') {
            toSetState.selectedDepot = ''
            toSetState.referenceDepot = ' '
        }
        this.setState(toSetState)
    }
    onClose = () => {
        this.props.onClose()
        this.setState({
            referenceOrg: ' ',
            referenceDepot: ' ',
            selectedDepot: '',
            plannedStartTime: DateTime.local(),
            plannedEndTime: DateTime.local(),
            errorMsg: '',
            performanceType: ' ',
        })
    }
    onStartTimeChange = e => {
        this.setState({ plannedStartTime: e })
    }
    onEndTimeChange = e => {
        this.setState({ plannedEndTime: e })
    }

    onThirdPartyPerformanceChange = e => {
        this.setState({ isThirdPartyPerformance: e })
    }

    getUnixTimeStamp = date => {
        const time = parseInt(Math.floor(new Date(date).getTime() / 1000))
        const rest = time % 60
        //remove seconds
        return parseInt(time - rest)
    }
    clearName = () => this.setState(resetState)
    handleOpen = () => {
        this.setState({ isOpen: true })
    }
    handleClose = () => {
        this.setState({ isOpen: false })
    }
    onClick = async (state, enforce) => {
        this.setState({ loading: true })
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/performance/create?enforce=${enforce}`
        const { plannedStartTime, plannedEndTime, selectedDepot } = this.state
        const { translate } = this.props

        try {
            const response = await axios.post(fullUrl, {
                IdfNumber: state.selectedProfile ? state.selectedProfile.idfNumber : '',
                PerformanceNumber: 0,
                Depot: selectedDepot.substring(selectedDepot.indexOf('-') + 1, selectedDepot.length),
                //performace date is same as start time .
                PerformanceDate: this.getUnixTimeStamp(plannedStartTime),
                PlannedStartTime: this.getUnixTimeStamp(plannedStartTime),
                PlannedEndTime: this.getUnixTimeStamp(plannedEndTime),
                type: this.state.performanceType.toUpperCase(),
                isThirdPartyPerformance:
                    this.state.performanceType === 'ABSENCES' ? false : this.state.isThirdPartyPerformance,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.props.store.call({ performanceDetail: util.CreateStoreObject(undefined) })
                if (state.selectedProfile.idfNumber && typeof response.data === 'string' && response.data.length > 0) {
                    this.onClose()
                    this.props.history.push(
                        `performances/detail/${response.data}/profile/${state.selectedProfile.idfNumber}/add/task`,
                        {
                            prevPath: '/manageperformance',
                        }
                    )
                } else if (typeof response.data === 'string' && response.data.length > 0) {
                    this.onClose()
                    this.props.history.push(`performances/detail/${response.data}/notAssigned/add/task`, {
                        prevPath: '/manageperformance',
                    })
                }
            }
        } catch (e) {
            if (e.response.status === 400) {
                if (getNormalizedMessage(e.response.data).indexOf('Profile already has an overlapping performance') !== -1)
                    this.setState({ errorMsg: getNormalizedMessage(e.response.data), isOpen: true, loading: false })
                else this.setState({ errorMsg: translate('roles_error'), loading: false })
            } else {
                this.setState({ errorMsg: 'Performance request failed', loading: false })
            }
        }
    }

    render() {
        const { isOpen } = this.state

        return (
            <div>
                <AddPerformanceDrawerView
                    {...this.props}
                    {...this.state}
                    onClose={this.onClose}
                    persist={this.persist}
                    referenceOrg={this.state.referenceOrg}
                    referenceDepot={this.state.referenceDepot}
                    plannedStartTime={this.state.plannedStartTime}
                    onStartTimeChange={this.onStartTimeChange}
                    plannedEndTime={this.state.plannedEndTime}
                    onEndTimeChange={this.onEndTimeChange}
                    buttonDisabled={
                        this.state.loading ||
                        this.state.selectedDepot === 'documents_label_select_depot' ||
                        this.state.selectedDepot === '' ||
                        this.state.performanceType === ' '
                    }
                    onClick={() => this.onClick(this.state, false)}
                    errorMsg={this.state.errorMsg}
                    setName={this.setName}
                    selectedName={this.state.selectedName}
                    clearName={this.clearName}
                    isThirdPartyPerformance={this.state.isThirdPartyPerformance}
                    onThirdPartyPerformanceChange={this.onThirdPartyPerformanceChange}
                />

                {isOpen && (
                    <ReplaceDriverDialog
                        open={isOpen}
                        handleClose={this.handleClose}
                        disableReplace={this.state.loading}
                        handleReplace={() => this.onClick(this.state, true)}
                    />
                )}
            </div>
        )
    }
}

export default compose(withStore, withStatus, withStyles(styles), withLocalize)(AddPerformanceDrawer)
