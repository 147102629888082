import React from 'react'
import { string, array, object } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import dateParser from 'utils/dateParser'
import mapKeysToLowerCase from 'utils/mapKeysToLowerCase'

import styles from './styles'

const ClearListView = ({ data, classes, name }) =>
    data.length > 0 ? (
        <React.Fragment>
            {data.reverse().map((_status, index) => {
                const status = mapKeysToLowerCase(_status)
                return (
                    <div key={index} className={classes.status}>
                        <Typography>
                            <Translate id="documents_clear_date_info_1" />
                            {name}
                            {status.deviceId ? (
                                <React.Fragment>
                                    <Translate id="documents_clear_date_info_2b1" />
                                    {status.deviceId}
                                    <Translate id="documents_clear_date_info_2b2" />
                                    {dateParser(status.timestamp * 1000)}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Translate id="documents_clear_date_info_2a" />
                                    {dateParser(status.timestamp * 1000)}
                                </React.Fragment>
                            )}
                        </Typography>
                    </div>
                )
            })}
        </React.Fragment>
    ) : (
        <div className={classes.status}>
            <Typography>
                <Translate id="documents_clear_date_info_1" />
                {name}
                <Translate id="documents_clear_date_info_2c" />
            </Typography>
        </div>
    )

ClearListView.propTypes = {
    name: string.isRequired,
    data: array.isRequired,
    classes: object.isRequired,
}

export default compose(withStyles(styles))(ClearListView)

const Failed = compose(
    withLocalize,
    withStyles(styles)
)(({ classes }) => (
    <div className={classes.status}>
        <Typography>
            <Translate id="districts_read_list_error" />
        </Typography>
    </div>
))

export { Failed }
