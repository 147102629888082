import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core'
import { arrayOf, object, func, bool } from 'prop-types'
import { ThemeContext } from 'theme/ThemeProvider'

import DistrictMarker from './Marker'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'

import styles from './styles'
import getDistrictNameById from 'utils/getDistrictNameById'

import { getLabel } from './labels'
import { withRouter } from 'react-router-dom'
/* eslint-disable */
const coords = {
    'DISTRICT-1':
        '114,46,122,45,127,48,148,41,149,48,149,59,151,68,161,77,172,76,175,65,184,65,190,59,224,73,226,83,233,87,240,86,248,86,266,77,288,67,291,86,297,91,308,89,313,94,319,92,318,115,328,125,336,125,337,131,319,140,321,150,324,166,317,177,313,196,303,199,296,205,292,215,296,221,294,228,258,238,248,236,242,221,235,219,225,219,217,212,210,213,204,217,199,226,188,230,180,226,179,218,171,217,164,217,150,224,148,231,139,229,137,220,119,216,104,209,98,212,75,214,65,234,41,218,34,205,31,199,24,197,17,198,13,190,7,168,18,161,9,147,2,120',
    'DISTRICT-2':
        '291,64,304,41,322,41,339,43,333,28,333,18,355,9,357,21,367,23,379,21,398,4,409,8,412,22,403,23,405,28,424,31,437,23,443,13,451,10,454,21,454,31,467,55,480,58,482,68,492,74,517,69,524,65,526,59,541,65,539,78,555,87,571,91,581,96,591,100,590,108,585,116,584,123,579,130,579,141,569,154,572,164,556,184,564,197,556,204,546,211,537,213,533,218,530,225,516,226,510,217,491,226,481,228,466,229,458,228,451,225,449,215,442,212,433,216,422,214,415,215,404,204,380,205,381,216,373,219,368,214,357,223,351,219,349,206,348,193,336,173,327,177,318,175,324,169,324,151,322,139,331,131,339,126,332,124,323,121,319,109,321,92,314,94,311,86,300,89,292,87,290,76',
    'DISTRICT-3':
        '315,176,324,180,334,173,339,179,344,191,345,199,344,205,353,215,337,223,330,223,328,230,323,226,316,234,307,236,297,232,296,223,295,210,302,198,311,195',
    'DISTRICT-4':
        '118,221,130,223,136,223,141,233,147,232,154,228,161,222,168,222,177,220,180,227,186,233,197,232,201,223,210,216,218,215,221,223,238,223,243,230,249,240,257,241,269,237,279,234,291,231,301,237,312,238,319,233,325,230,339,230,350,234,354,244,369,251,367,266,366,272,374,278,375,293,376,305,380,313,388,316,402,320,405,326,401,331,397,345,399,356,398,366,401,374,407,381,405,389,403,395,398,405,391,408,388,414,389,424,388,434,382,438,372,441,360,450,353,453,345,454,334,449,326,449,315,449,305,447,295,445,297,438,292,431,293,425,301,421,305,411,304,400,293,402,289,394,291,385,292,377,303,367,305,360,300,356,294,355,292,360,286,354,281,347,275,342,272,334,263,336,257,338,251,342,241,337,230,336,221,336,209,345,204,335,203,326,206,316,204,306,200,296,195,290,187,293,179,292,179,283,168,281,163,289,155,292,146,289,137,282,132,278,130,260,128,252,125,242,130,237,125,230',
    'DISTRICT-5':
        '337,227,350,222,354,228,367,220,372,223,382,222,379,210,387,207,401,206,409,213,414,219,420,215,428,222,434,216,441,214,447,218,448,225,452,230,461,233,472,233,477,229,488,229,496,228,511,221,513,227,522,228,528,225,533,220,554,210,557,204,566,201,567,217,578,214,583,211,588,213,602,214,613,214,623,216,632,217,635,224,644,226,651,237,658,249,665,249,669,253,660,268,657,276,662,284,666,290,674,289,682,289,688,295,692,304,692,314,688,320,692,327,696,337,691,341,684,341,678,346,678,354,673,357,665,360,660,364,660,371,661,378,656,381,653,389,649,383,645,378,637,383,628,382,622,383,620,396,611,399,607,406,602,414,602,422,595,432,596,439,588,447,582,455,582,464,585,468,582,473,580,486,584,493,590,495,593,502,595,509,599,514,606,513,608,519,607,526,612,532,612,539,607,547,602,554,600,560,594,562,582,562,576,566,568,566,560,571,551,572,543,569,535,576,527,555,523,546,515,545,509,545,508,533,501,528,497,522,487,523,475,522,468,515,464,503,456,500,449,497,442,492,433,495,424,494,421,481,423,471,424,460,417,449,409,447,412,434,415,422,418,410,426,397,425,389,414,392,408,394,401,392,407,387,407,379,401,374,397,366,400,356,397,348,398,338,404,326,398,320,392,318,384,315,377,309,376,300,376,279,369,272,369,263,371,251,364,249,356,248,356,240,353,234',
}

/* eslint-enable */
export class DistrictsMap extends PureComponent {
    static propTypes = {
        districts: arrayOf(object).isRequired,
        classes: object.isRequired,
        chooseDistrict: func.isRequired,
        profilesWithError: arrayOf(object),
        activeLanguage: object,
        profilesLoading: bool.isRequired,
        location: object.isRequired,
    }

    checkForDirectie = districts => {
        let returnValue = false
        districts.forEach(district => {
            if (district.id === 'DISTRICT-6') returnValue = true
        })
        return returnValue
    }

    getDistrictName = id => {
        let districtName = getDistrictNameById(id)
        let { activeLanguage } = this.props
        if (activeLanguage.code === 'fr') return districtName.Name[0].Translation
        else return districtName.Name[1].Translation
    }

    static contextType = ThemeContext

    render() {
        const {
            classes,
            chooseDistrict,
            districts,
            profilesWithError,
            profilesLoading,
            location,
            activeLanguage,
        } = this.props
        return (
            <div style={{ width: '100%', marginTop: '8%', textAlign: 'center' }}>
                <div className={classes.map_container}>
                    <img
                        className={classes.map_container_img}
                        src={'/img/District-Map.png'}
                        alt="Belgian Map"
                        useMap="#image-map"
                    />
                    <map name="image-map">
                        {districts.map(
                            district =>
                                district.id !== 'DISTRICT-6' && (
                                    <div key={district.id}>
                                        {location.pathname === '/profiles'
                                            ? getLabel(
                                                  district.id,
                                                  districts,
                                                  profilesWithError,
                                                  profilesLoading,
                                                  this.context.themeName,
                                                  activeLanguage
                                              )
                                            : null}
                                        <area
                                            onClick={e => {
                                                chooseDistrict(e, district.id, profilesWithError)
                                            }}
                                            key={district.id}
                                            target=""
                                            alt={district.id}
                                            title={district.id}
                                            href=""
                                            coords={coords[district.id]}
                                            shape="poly"
                                        />
                                    </div>
                                )
                        )}
                    </map>
                    {this.checkForDirectie(districts) && (
                        <DistrictMarker
                            key={'DISTRICT-6'}
                            id={'DISTRICT-6'}
                            classVariable={'DISTRICT-6'}
                            clickHandler={chooseDistrict}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default compose(withLocalize, withStyles(styles), withRouter)(DistrictsMap)
