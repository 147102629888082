export default theme => ({
    stepBorder: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        width: '100%',
        borderBottom: '1px solid grey',
    },
    radioGroupParent: {
        marginTop: `${theme.spacing(2)}px`,
        padding: `${theme.spacing(1)}px`,
    },
    textField: {
        width: '70%',
        marginTop: `${48}px`,
        backgroundColor: 'Black',
        padding: `${theme.spacing(2)}px`,
    },

    row: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.customColors.lightGrey,
            background: theme.customColors.white,
        },
    },
    selectedRow: {
        cursor: 'pointer',
        color: theme.customColors.lightGrey,
        background: theme.customColors.white,
    },
})
