import React from 'react'

import { func, object, string, number, array } from 'prop-types'
import classNames from 'classnames'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { Delete, Edit } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import { Translate } from 'react-localize-redux'

import getVacmaRestriction from './Services/getVacmaRestriction'
import VacmaRestrictionTable from './VacmaRestrictionTable'
import VacmaRestrictionDrawer from './VacmaRestrictionDrawer'
import addEditVacmaRestriction from './Services/addEditVacmaRestriction'
import DeleteDialog from './DeleteVcmaDialog'
import getMaterialTypes from '../service/getMaterialTypes'
import Header from 'components/Header'
import CommonFilter from '../CommonFilter'
import Loading from 'components/Loading'

const columns = [
    {
        width: 400,
        label: 'datatable_header_vacma_restriction_type',
        dataKey: 'code',
    },
    {
        width: 400,
        label: 'datatable_header_vacma_restriction_per1',
        dataKey: 'vacmarestriction1',
    },
    {
        width: 400,
        label: 'datatable_header_vacma_restriction_per2',
        dataKey: 'vacmarestriction2',
    },
    {
        width: 400,
        label: 'datatable_header_actions',
        dataKey: 'actions',
    },
]

class VacmaRestrictionList extends React.Component {
    _isMounted = false
    state = {
        data: [],
        isOpen: false,
        editDrawer: false,
        openDrawer: false,
        editData: '',
        addEditError: '',
        addEditLoading: false,
        filteredData: [],
        openDialog: false,
        rowData: {},
        deleteRowData: '',
        loadingData: false,
        platformFilterValue: '',
        materialTypes: [],
        filterString: '',
    }

    static propTypes = {
        classes: object.isRequired,
        rowClassName: string,
        onRowClick: func,
        headerHeight: number,
        rowHeight: number,
        activeLanguage: object,
        history: object,
        match: object,
        translate: func,
        data: array,
        filteredData: array,
        filterString: string,
    }
    loadVacmaRestrictions = async () => {
        this.setState({ loadingData: true })

        let vacmaRestrictions = await getVacmaRestriction()
        if (this._isMounted)
            this.setState({
                data: vacmaRestrictions,
                loadingData: false,
            })
    }
    handleAddVacma = async data => {
        this.setState({ addEditLoading: true })
        let isEdit = this.state.editDrawer
        if (isEdit) {
            data.id = this.state.editData.id
        }
        let response = await addEditVacmaRestriction(data, isEdit)

        if (response.status === 200) {
            this.setState(
                {
                    addEditError: '',
                    openDrawer: false,
                    editDrawer: false,
                    addEditLoading: false,
                },
                () => this.reloadVacmaList()
            )
        } else {
            this.setState({
                addEditLoading: false,
                addEditError: response.data.Message,
            })
        }
    }
    compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const codeA = a.code.toUpperCase()
        const codeB = b.code.toUpperCase()

        let comparison = 0
        if (codeA > codeB) {
            comparison = 1
        } else if (codeA < codeB) {
            comparison = -1
        }
        return comparison
    }
    loadMaterialTypes = async () => {
        this.setState({ loadingData: true })

        let response = await getMaterialTypes()
        response.sort(this.compare) // sorting on code
        let materialTypes = response.map(({ materialSubTypeId, name }) => ({
            id: materialSubTypeId,
            text: this.props.activeLanguage === 'fr' ? name[0].translation : name[1].translation,
        }))
        if (this._isMounted)
            this.setState({
                materialTypes: materialTypes,
                loadingData: false,
            })
    }
    componentDidMount() {
        this._isMounted = true
        this.loadMaterialTypes()
        this.loadVacmaRestrictions()
    }

    componentWillUnmount() {
        this._isMounted = false
    }
    getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = this.props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    onRowDeleteClick = (e, id) => {
        this.setState({
            openDeleteDialog: true,
            deleteRowData: id,
        })

        e.stopPropagation()
    }

    onRowEditClick = (e, rowData) => {
        this.setState({
            editDrawer: true,
            isOpen: true,
            rowData: rowData,
            openDrawer: true,
            editData: rowData,
        })
        e.stopPropagation()
    }

    handleAddClick = () => {
        this.setState({
            openDrawer: true,
            editDrawer: false,
            editData: '',
        })
    }

    onDrawerClose = () => {
        this.setState({ openDrawer: false, editDrawer: false, editData: '', addEditError: '', addEditLoading: false })
    }
    onDialogClose = () => {
        this.setState({
            openDeleteDialog: false,
            addEditError: '',
        })
    }
    reloadVacmaList = () => {
        this.loadVacmaRestrictions()
    }

    handleFilter = value => {
        if (value) {
            let data = this.state.data
            return data.filter(item => {
                return item.code.toLowerCase().includes(value.toLowerCase())
            })
        }
        return this.state.data
    }
    handleFilterSearch = e => {
        this.setState({ filterString: e.target.value })
    }
    headerRenderer = headerProps => {
        const { classes, headerHeight } = this.props
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: headerHeight,
                    paddingTop: 34,
                    paddingBottom: 34,
                    paddingRight: 0,
                }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }
    cellRenderer = cellProps => {
        const { classes } = this.props
        const { dataKey, rowData } = cellProps

        if (dataKey === 'code') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData.code}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'vacmarestriction1') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData.restrictionPercentageVacma1}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'vacmarestriction2') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData.restrictionPercentageVacma2}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'actions') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <div className={classes.multipleIcons}>
                        <Edit
                            className={classes.editIcon}
                            onClick={e => {
                                this.onRowEditClick(e, rowData)
                            }}
                        />

                        <Delete
                            className={classes.deleteIcon}
                            onClick={e => {
                                this.onRowDeleteClick(e, rowData.id)
                            }}
                        />
                    </div>
                </TableCell>
            )
        }
    }

    render() {
        const { classes, translate } = this.props
        let data = this.handleFilter(this.state.filterString)
        return (
            <React.Fragment>
                <Header isMenu title={translate('compositions_reference_data_title')} />
                <CommonFilter
                    handleFilterSearch={this.handleFilterSearch}
                    filterString={this.state.filterString}
                    {...this.props}
                />

                <div className={classes.parentDiv} style={{ display: 'flex' }}>
                    <Typography variant="h6">{translate('compositions_vacma_restriction_percetage_title')}</Typography>
                    <div>
                        <Button variant="contained" color="primary" onClick={() => this.handleAddClick()}>
                            {translate('compositions_add_vacma_restriction_button')}
                        </Button>
                    </div>
                </div>
                {this.state.loadingData && <Loading />}
                {!this.state.loadingData && (
                    <VacmaRestrictionTable
                        columns={columns}
                        data={data}
                        classes={classes}
                        headerRenderer={this.headerRenderer}
                        cellRenderer={this.cellRenderer}
                        getRowClassName={this.getRowClassName}
                    />
                )}
                {this.state.openDrawer && (
                    <VacmaRestrictionDrawer
                        isOpen={this.state.openDrawer}
                        editDrawer={this.state.editDrawer}
                        onClose={this.onDrawerClose}
                        editData={this.state.editData}
                        handleAdd={this.handleAddVacma}
                        addEditLoading={this.state.addEditLoading}
                        addEditError={this.state.addEditError}
                        materialTypes={this.state.materialTypes}
                        {...this.props}
                    />
                )}
                {this.state.openDeleteDialog && (
                    <DeleteDialog
                        dialogOpen={this.state.openDeleteDialog}
                        onDialogClose={this.onDialogClose}
                        reloadVacmaList={this.reloadVacmaList}
                        deleteData={this.state.deleteRowData}
                        {...this.props}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default VacmaRestrictionList
