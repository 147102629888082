import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Paper, Typography } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'

export class ChangeSwitchDialog extends PureComponent {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        onChange: PropTypes.func,
        disableReplace: PropTypes.bool,
        showText: PropTypes.string,
        translate: PropTypes.func,
    }
    render() {
        const { open, handleClose, onChange, disableReplace, showText, translate } = this.props
        return (
            <Dialog open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{translate('replace_label')}</DialogTitle>
                <Paper>
                    <DialogContent style={{ minWidth: '600px' }}>
                        <Typography variant="subtitle1">{translate(showText)}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                handleClose()
                            }}
                            color="primary"
                        >
                            {translate('manage_switch_dialog_cancel')}
                        </Button>
                        <Button
                            disabled={disableReplace}
                            onClick={() => {
                                onChange()
                            }}
                            color="primary"
                        >
                            {translate('manage_switch_dialog_apply')}
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        )
    }
}
export default compose(withLocalize)(ChangeSwitchDialog)
