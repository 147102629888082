import React from 'react'
import { object, bool, func, array } from 'prop-types'
import { compose } from 'recompose'
import { withStore, withStatus } from 'react-ion-store'
import axios from 'axios'

// Components
import PerformanceDriveDetailsDrawerView from './PerformanceDriveDetailsDrawerView'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

class PerformanceDriveDetailsDrawer extends React.Component {
    static propTypes = {
        close: func.isRequired,
        store: object.isRequired,
        setParentstate: func.isRequired,
        clearPerformances: func,
        performanceDriveDetailsDrawerOpen: bool,
        performanceDriveDetailsDrawerData: array,
        performanceDriveDetailsDrawerParams: object,
    }
    state = {
        data: null,
        downloading: false,
        downloadError: '',
    }

    componentDidUpdate() {
        const {
            performanceDriveDetailsDrawerOpen,
            performanceDriveDetailsDrawerData,
            performanceDriveDetailsDrawerParams,
        } = this.props

        if (
            performanceDriveDetailsDrawerOpen &&
            performanceDriveDetailsDrawerParams &&
            performanceDriveDetailsDrawerData === null
        ) {
            this.getDriveModeData(performanceDriveDetailsDrawerParams)
        }
    }

    getDriveModeData = async params => {
        const { url, subscriptionKey } = requestData
        const { performanceId, idfNumber } = params
        const { setParentstate } = this.props
        const fullUrl = `${url}/performance/${performanceId}/drivemodelogs/${idfNumber}`

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                setParentstate('performanceDriveDetailsDrawerData', [])
            }
            if (response.status === 200) {
                setParentstate('performanceDriveDetailsDrawerData', response.data)
            }
        } catch (e) {
            console.error(e) // eslint-disable-line
        }
    }
    openStream = () => {
        this.setState({ downloading: true, downloadError: '' })
        this.doExportCall()
    }
    doExportCall = async () => {
        const { url, subscriptionKey } = requestData
        const { performanceId, idfNumber } = this.props.performanceDriveDetailsDrawerParams
        let fullUrl = `${url}/performance/${performanceId}/drivemodelogs/${idfNumber}?export=true`

        let data = []

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                data = response.data
                // create a blank element to download csv file.
                var hiddenElement = document.createElement('a')
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data)
                hiddenElement.target = '_blank'
                hiddenElement.download = 'drivedetails.csv'
                hiddenElement.click()
                this.setState({ downloading: false })
            }
        } catch (e) {
            if (e.response.status === 400) {
                this.setState({ downloadError: e.response.data.Message, downloading: false })
            } else {
                this.setState({ downloadError: 'Download failed', downloading: false })
            }
        }
    }
    render() {
        const { close, performanceDriveDetailsDrawerOpen, performanceDriveDetailsDrawerData } = this.props
        return (
            <PerformanceDriveDetailsDrawerView
                {...this.props}
                data={performanceDriveDetailsDrawerData}
                isOpen={performanceDriveDetailsDrawerOpen}
                close={close}
                downloading={this.state.downloading}
                downloadError={this.state.downloadError}
                openStream={this.openStream}
            />
        )
    }
}

export default compose(withStore, withStatus)(PerformanceDriveDetailsDrawer)
