import axios from 'axios'
import { DateTime } from 'luxon'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default async (depot, selectedDate) => {
    const { url, subscriptionKey } = requestData
    let date

    if (typeof selectedDate === 'number') {
        date = DateTime.fromSeconds(selectedDate).toFormat('ddMMyyyy')
    } else {
        date = selectedDate.toFormat('ddMMyyyy')
    }

    const fullUrl = `${url}/performance/bydepot/${depot}?date=${date}&performanceType=All`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            return []
        }
        return response.data
    } catch (e) {
        console.error(e) // eslint-disable-line
    }
}
