export default theme => ({
    listView: {
        maxHight: '70vh',
        overflowY: 'auto',
        height: '60vh',
        [theme.breakpoints.down('xs')]: {
            maxHight: '50vh',
            height: '45vh',
        },
    },
})
