import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

const unAssignDriverCall = async (idf, performanceId) => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/performance/${performanceId}/unassign/${idf}`
    try {
        const response = await axios.post(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        return response
    } catch (e) {
        return 'error'
    }
}
export default unAssignDriverCall
