import React from 'react'
import { object, func, bool } from 'prop-types'
import { Translate } from 'react-localize-redux'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import Header from 'components/Header'
import DistrictsMapper from 'components/DistrictsMapper'
import DocumentFolderMapper from 'components/DocumentFolderMapper'
import IconButton from 'components/IconButton'
import ClearCacheDrawer from './components/ClearCacheDrawer'
import DriverDrawer from './components/DriverDrawer'

import getPerformanceRangeNumber from './Services/getPerformanceRangeNumber'
import setPerformanceRangeNumber from './Services/setPerformanceRangeNumber'
import { Button, Input } from '@material-ui/core'
import getNumberOfPerformancesVisibleInCalender from './Services/getNumberOfPerformancesVisibleInCalender'
import setNumberOfPerformancesVisibleInCalender from './Services/setNumberOfPerformancesVisibleInCalender'
import RivSettingsModal from './components/RivSettingsModal'
// import ReadStatusDrawer from './components/ReadStatusDrawer'
// import ReactivateTaskDrawer from './components/ReactivateTaskDrawer'
// import AbolishTaskDrawer from './components/AbolishTaskDrawer'
// import ChangeTaskStatusDrawer from './components/ChangeTaskStatusDrawer'

const ManageView = ({
    classes,
    drawers,
    persistDrawers,
    roles,
    history,
    store,
    showDBSwitch,
    switchChecked,
    onChangeDBSwitch,
    backupKey,
    onChangeBackupKey,
}) => {
    const {
        clearCache,
        // readStatus,
        // editOrganisation,
        // superUser,
        // changeTaskStatus,
        // abolishTask,
        // reactivateTask,
        calendar,
    } = drawers
    const [performanceRangeNumbers, setPerformanceRangeNumbers] = React.useState({ start: 2001, end: 2199 })
    const [hasSetPerformanceRangeNumbers, setHasSetPerformanceRangeNumbers] = React.useState(true)
    const [performancesVisibleInCalendar, setPerformancesVisibleInCalendar] = React.useState('')
    const [hasSetPerformancesVisibleInCalendar, setHasSetPerofmancesVisibleInCalendar] = React.useState(true)
    const [openRivPicker, setOpenRivPicker] = React.useState(false)

    React.useEffect(() => {
        getPerformanceRangeNumber().then(res => {
            setPerformanceRangeNumbers({ start: res.data.performanceNumberLower, end: res.data.performanceNumberUpper })
        })
        getNumberOfPerformancesVisibleInCalender().then(res => {
            setPerformancesVisibleInCalendar(res.data.numberOfPerformancesVisible)
        })
    }, [])

    const postPerformanceRangeNumbers = () => {
        setHasSetPerformanceRangeNumbers(true)
        setPerformanceRangeNumber(performanceRangeNumbers.start, performanceRangeNumbers.end)
    }

    const postPerformancesVisibleInCalendar = () => {
        setHasSetPerofmancesVisibleInCalendar(true)
        setNumberOfPerformancesVisibleInCalender(performancesVisibleInCalendar)
    }

    const onRivPickerClose = () => {
        setOpenRivPicker(false)
    }

    return (
        <Translate>
            {({ translate }) => (
                <React.Fragment>
                    <Header isMenu title={translate('module_manage')} />
                    <DistrictsMapper>
                        <DocumentFolderMapper>
                            {((roles && roles.performances && roles.performances.write === true) ||
                                (Array.isArray(roles.performances.write) && roles.performances?.write.length > 0)) && (
                                <div className={classes.submenu}>
                                    <Typography className={classes.submenuTitle} variant="h6">
                                        {translate('module_json_format_performance')}
                                    </Typography>
                                    <IconButton
                                        className={classes.button}
                                        icon="search"
                                        title={translate('management_button_search')}
                                        onClick={() => {
                                            history.push('/jsonFormat')
                                        }}
                                        disabled={checkFunctionalityDisabled(
                                            store,
                                            'search',
                                            'performances.functionality.management.performances'
                                        )}
                                    />
                                </div>
                            )}

                            {((roles && roles.users && roles.users.write === true) ||
                                (Array.isArray(roles.users.write) && roles.users?.write.length > 0)) && (
                                <div className={classes.submenu}>
                                    <Typography className={classes.submenuTitle} variant="h6">
                                        {translate('manage_label_users')}
                                    </Typography>
                                    <IconButton
                                        className={classes.button}
                                        icon="search"
                                        title={translate('management_button_search')}
                                        onClick={() => {
                                            history.push('/users')
                                        }}
                                        disabled={checkFunctionalityDisabled(
                                            store,
                                            'search',
                                            'users.functionality.management.users'
                                        )}
                                    />
                                    <IconButton
                                        className={classes.button}
                                        icon="add"
                                        title={translate('management_button_add')}
                                        onClick={() => {
                                            history.push('/users')
                                        }}
                                        disabled={checkFunctionalityDisabled(
                                            store,
                                            'add',
                                            'users.functionality.management.users'
                                        )}
                                    />
                                    <IconButton
                                        className={classes.button}
                                        icon="edit"
                                        title={translate('management_button_edit')}
                                        onClick={() => {
                                            history.push('/users')
                                        }}
                                        disabled={checkFunctionalityDisabled(
                                            store,
                                            'edit',
                                            'users.functionality.management.users'
                                        )}
                                    />
                                </div>
                            )}

                            {roles && roles.manage && roles.manage.write?.length > 0 && (
                                <div className={classes.submenu}>
                                    <Typography className={classes.submenuTitle} variant="h6">
                                        {translate('manage_label_docs')}
                                    </Typography>

                                    <IconButton
                                        className={classes.button}
                                        icon="refresh"
                                        title={translate('management_button_mark_repair_data')}
                                        onClick={() => persistDrawers('clearCache')(true)}
                                        disabled={checkFunctionalityDisabled(
                                            store,
                                            'restore data',
                                            'manage.functionality.management.documents'
                                        )}
                                    />
                                </div>
                            )}

                            {showDBSwitch && (
                                <div className={classes.submenu}>
                                    <Typography className={classes.submenuTitle} variant="h6">
                                        {translate('manage_switch_db_label')}
                                    </Typography>

                                    <Typography component="div" style={{ marginLeft: '24px' }}>
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item>{translate('manage_switch_label_off')}</Grid>
                                            <Grid item>
                                                <Switch
                                                    checked={switchChecked}
                                                    onChange={onChangeDBSwitch}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>{translate('manage_switch_label_on')}</Grid>
                                            <Grid item>
                                                <IconButton
                                                    className={classes.button}
                                                    icon="edit"
                                                    title={translate('management_button_edit')}
                                                    style={{ marginLeft: '20px' }}
                                                    onClick={() => {
                                                        setOpenRivPicker(true)
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </div>
                            )}

                            {!checkFunctionalityDisabled(
                                store,
                                'performanceBackUp',
                                'performances.functionality.management.dbrights'
                            ) && (
                                <div className={classes.submenu}>
                                    <Typography className={classes.submenuTitle} variant="h6">
                                        {translate('manage_performance_backup_label')}
                                    </Typography>

                                    <Typography component="div" style={{ marginLeft: '24px' }}>
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item>{translate('manage_switch_label_off')}</Grid>
                                            <Grid item>
                                                <Switch
                                                    checked={backupKey}
                                                    disabled={checkFunctionalityDisabled(
                                                        store,
                                                        'performanceBackUp',
                                                        'performances.functionality.management.dbrights'
                                                    )}
                                                    onChange={onChangeBackupKey}
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item>{translate('manage_switch_label_on')}</Grid>
                                        </Grid>
                                    </Typography>
                                </div>
                            )}

                            <div className={classes.submenu}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.submenuTitle} variant="h6">
                                            {'Linda Roles'}
                                        </Typography>

                                        <IconButton
                                            className={classes.button}
                                            icon="edit"
                                            title={translate('management_button_edit')}
                                            onClick={() => {
                                                history.push('/lindaroles')
                                            }}
                                            disabled={checkFunctionalityDisabled(
                                                store,
                                                'lindaRolesView',
                                                'manage.functionality.management.linda'
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>

                            {roles && roles.manage && roles.manage.write?.length > 0 && (
                                <div className={classes.submenu}>
                                    <Typography className={classes.submenuTitle} variant="h6">
                                        {translate('manage_ptcar_classification_and_types')}
                                    </Typography>

                                    <IconButton
                                        className={classes.button}
                                        icon="edit"
                                        title={translate('manage_ptcar_types_button')}
                                        onClick={() => history.push('/managePtcarTypes')}
                                        disabled={checkFunctionalityDisabled(
                                            store,
                                            'view',
                                            'manage.functionality.management.ptcarTypesAndClassification'
                                        )}
                                    />
                                    <IconButton
                                        className={classes.button}
                                        icon="edit"
                                        title={translate('manage_ptcar_classification_button')}
                                        onClick={() => history.push('/managePtcarClassifications')}
                                        disabled={checkFunctionalityDisabled(
                                            store,
                                            'view',
                                            'manage.functionality.management.ptcarTypesAndClassification'
                                        )}
                                    />
                                </div>
                            )}

                            {((roles && roles.profiles && roles.profiles.write === true) ||
                                (Array.isArray(roles.profiles.write) && roles.profiles?.write.length > 0)) && (
                                <div className={classes.submenu}>
                                    <Typography className={classes.submenuTitle} variant="h6">
                                        {'Profile status'}
                                    </Typography>
                                    <IconButton
                                        className={classes.button}
                                        icon="edit"
                                        title={translate('manage_profile_status')}
                                        onClick={() => {
                                            history.push('/profiles')
                                        }}
                                        disabled={checkFunctionalityDisabled(
                                            store,
                                            'profileStatus',
                                            'users.functionality.management.users'
                                        )}
                                    />
                                </div>
                            )}
                            <div className={classes.submenu}>
                                <Typography className={classes.submenuTitle} variant="h6">
                                    {'Range new performances'}
                                </Typography>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <Typography>Start</Typography>
                                        <Input
                                            value={performanceRangeNumbers.start}
                                            title="Start"
                                            type="number"
                                            style={{ marginRight: '15px' }}
                                            onChange={e => {
                                                setHasSetPerformanceRangeNumbers(false)
                                                setPerformanceRangeNumbers(prev => ({
                                                    ...prev,
                                                    start: parseInt(e.target.value),
                                                }))
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Typography>Einde</Typography>
                                        <Input
                                            value={performanceRangeNumbers.end}
                                            title="Einde"
                                            type="number"
                                            style={{ marginRight: '15px' }}
                                            onChange={e => {
                                                setHasSetPerformanceRangeNumbers(false)
                                                setPerformanceRangeNumbers(prev => ({
                                                    ...prev,
                                                    end: parseInt(e.target.value),
                                                }))
                                            }}
                                        />
                                    </div>
                                    <Button
                                        onClick={postPerformanceRangeNumbers}
                                        style={{ maxHeight: '36px', alignSelf: 'flex-end' }}
                                        color="primary"
                                        variant="contained"
                                        disabled={hasSetPerformanceRangeNumbers}
                                    >
                                        {translate('add_user_confirm_save')}
                                    </Button>
                                </div>
                            </div>
                            <div className={classes.submenu}>
                                <Typography className={classes.submenuTitle} variant="h6">
                                    {'Performances visible in the driving calendar'}
                                </Typography>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <Input
                                            value={parseInt(performancesVisibleInCalendar)}
                                            type="number"
                                            style={{ marginRight: '15px' }}
                                            onChange={e => {
                                                setHasSetPerofmancesVisibleInCalendar(false)
                                                if (parseInt(e.target.value) < 0) {
                                                    setNumberOfPerformancesVisibleInCalender(0)
                                                } else {
                                                    setPerformancesVisibleInCalendar(parseInt(e.target.value))
                                                }
                                            }}
                                        />
                                    </div>
                                    <Button
                                        onClick={postPerformancesVisibleInCalendar}
                                        style={{ maxHeight: '36px', alignSelf: 'flex-end' }}
                                        color="primary"
                                        variant="contained"
                                        disabled={hasSetPerformancesVisibleInCalendar}
                                    >
                                        {translate('add_user_confirm_save')}
                                    </Button>
                                </div>
                            </div>

                            {/* {roles && roles.documents && !!roles.documents.write && ( */}
                            <React.Fragment>
                                {/* <ReadStatusDrawer 
                                open={readStatus} onClose={() => persistDrawers('readStatus')(false)} /> */}
                                {/* <SuperUserDrawer
                                      open={superUser}
                                      onClose={() => persistDrawers('superUser')(false)}
                                    /> */}

                                {/* <ReactivateTaskDrawer
                                    open={reactivateTask}
                                    onClose={() => persistDrawers('reactivateTask')(false)}
                                /> */}
                                {/* <AbolishTaskDrawer
                                 open={abolishTask} onClose={() => persistDrawers('abolishTask')(false)}
                                /> */}
                                {/* <ChangeTaskStatusDrawer
                                    open={changeTaskStatus}
                                    onClose={() => persistDrawers('changeTaskStatus')(false)}
                                /> */}
                                <ClearCacheDrawer open={clearCache} onClose={() => persistDrawers('clearCache')(false)} />
                                <DriverDrawer
                                    history={history}
                                    open={calendar}
                                    onClose={() => persistDrawers('calendar')(false)}
                                />
                            </React.Fragment>
                            {/* )} */}

                            {/* <ExtendedOrganisationsDrawer
                                open={editOrganisation}
                                onClose={() => persistDrawers('editOrganisation')(false)}
                            /> */}
                        </DocumentFolderMapper>
                    </DistrictsMapper>
                    <RivSettingsModal handleClose={onRivPickerClose} open={openRivPicker} />
                </React.Fragment>
            )}
        </Translate>
    )
}

ManageView.propTypes = {
    classes: object.isRequired,
    persistDrawers: func.isRequired,
    drawers: object.isRequired,
    roles: object.isRequired,
    history: object,
    store: object,
    showDBSwitch: bool,
    switchChecked: bool,
    onChangeDBSwitch: func,
    backupKey: bool,
    onChangeBackupKey: func,
}

export default ManageView
