import React, { PureComponent } from 'react'
import TableCell from '@material-ui/core/TableCell'
import classNames from 'classnames'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Lock from '@material-ui/icons/Lock'
import { func, object, number, string } from 'prop-types'

export class ActionsColumn extends PureComponent {
    static propTypes = {
        openPerformanceNotificationDrawer: func,
        openPerformanceDriveDetailsDrawer: func,
        rowHeight: number,
        task: object,
        classes: object,
        dataKey: string,
        rowData: object,
    }

    render() {
        const {
            classes,
            dataKey,
            rowData,
            rowHeight,
            openPerformanceDriveDetailsDrawer,
            openPerformanceNotificationDrawer,
        } = this.props

        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, {
                    [classes.flexContainer]:
                        dataKey === 'plannedStartTime' || dataKey === 'plannedEndTime' || dataKey === 'timeDifference',
                    [classes.tableRowRed]: rowData.status === 2,
                    [classes.tableRowYellow]: rowData.status === 1,
                })}
                variant="body"
                style={{ height: rowHeight }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignContent: 'flex-start',
                        justifyContent: 'space-between',
                        verticalAlign: 'center',
                    }}
                >
                    {rowData.isLocked && rowData.tab === 2 && <Lock className={classes.lockIcon} />}
                    <img
                        className={classes.driveModeIcon}
                        alt="DriveModeIcon"
                        style={{ width: '24px', height: '24px' }}
                        src={'/img/ic_drive_mode_indication.png'}
                        onClick={e => {
                            openPerformanceDriveDetailsDrawer(e, {
                                idfNumber: rowData.idfNumber,
                                performanceId: rowData.performanceId,
                            })
                            e.stopPropagation()
                        }}
                    />

                    <NotificationsIcon
                        className={classes.driveModeIcon}
                        onClick={e => {
                            openPerformanceNotificationDrawer(e, {
                                idfNumber: rowData.idfNumber,
                                performanceId: rowData.performanceId,
                            })
                            e.stopPropagation()
                        }}
                    />
                </div>
            </TableCell>
        )
    }
}

export default ActionsColumn
