import React from 'react'
import { Translate } from 'react-localize-redux'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { Delete, Lock } from '@material-ui/icons'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import DelayIndicator from '../DelayIndicator'
import TimeDifferenceIndicator from '../TimeDifferenceIndicator'

import timeParser from 'utils/timeParser'

export default (cellProps, { classes, rowHeight, match, storage, openDeletePerformanceDialog }) => {
    const { dataKey, rowData } = cellProps
    let data = rowData[dataKey]
    if (dataKey === 'actions') {
        if (rowData.isCreatedViaPortal && rowData.isCreatedViaPortal === true) {
            return (
                <TableCell
                    component="div"
                    className={classNames(classes.tableCell, {
                        [classes.flexContainer]:
                            dataKey === 'plannedStartTime' || dataKey === 'plannedEndTime' || dataKey === 'timeDifference',
                        [classes.tableRowRed]: rowData.status === 2,
                        [classes.tableRowYellow]: rowData.status === 1,
                    })}
                    variant="body"
                    style={{ height: rowHeight, alignContent: 'flex-start', verticalAlign: 'center' }}
                >
                    <Delete
                        className={classes.actionIcon}
                        onClick={e => {
                            openDeletePerformanceDialog(e, rowData.performanceId)
                        }}
                    />
                    {rowData.isLocked && <Lock className={classes.lockIcon} />}
                </TableCell>
            )
        } else {
            return (
                <TableCell
                    component="div"
                    className={classNames(classes.tableCell, {
                        [classes.flexContainer]:
                            dataKey === 'plannedStartTime' || dataKey === 'plannedEndTime' || dataKey === 'timeDifference',
                        [classes.tableRowRed]: rowData.status === 2,
                        [classes.tableRowYellow]: rowData.status === 1,
                    })}
                    variant="body"
                    style={{ height: rowHeight, alignContent: 'flex-start' }}
                >
                    {rowData.isLocked && <Lock className={classes.lockIcon} />}
                </TableCell>
            )
        }
    }

    if (
        (dataKey === 'plannedStartTime' &&
            (rowData.status !== 2 || (rowData.status === 2 && rowData.plannedStartDelay <= 0))) ||
        dataKey === 'plannedEndTime'
    )
        data = (
            <Typography style={{ color: 'white' }} variant="body2">
                {timeParser(rowData[dataKey])}
            </Typography>
        )
    else if (dataKey === 'plannedStartTime' && rowData.status === 2 && rowData.plannedStartDelay > 0) {
        data = (
            <Typography style={{ color: 'white' }} variant="body2">
                {timeParser(rowData[dataKey])} <DelayIndicator delay={rowData.plannedStartDelay} />
            </Typography>
        )
    } else if (dataKey === 'performanceId') {
        data = [
            <Link
                className={classes.link}
                key={rowData.performanceId + '_id'}
                target="_blank"
                to={`${match.path}/detail/${rowData.performanceId}/notAssigned`}
                style={{
                    pointerEvents: checkFunctionalityDisabled(
                        storage,
                        'view',
                        'performances.functionality.management.performanceDetail'
                    )
                        ? 'none'
                        : 'auto',
                }}
                onClick={e => {
                    e.stopPropagation()
                }}
            >
                <Typography
                    className={classNames(classes.blockText, classes.clickText)}
                    key={rowData.performanceId + '_id'}
                    variant="body2"
                >
                    {rowData.performanceId}
                </Typography>
            </Link>,
            <Typography className={classes.blockText} key={rowData.performanceId + '_depot'} variant="caption">
                Depot <Translate id={`DEPOT-${rowData.depotName}`} />
                {rowData.versionNumber ? ` -${rowData.performanceNumber} (${rowData.versionNumber})` : ''}
            </Typography>,
        ]
    } else if (dataKey === 'profileName') {
        data = [
            <Typography
                key={rowData.performanceId + '_name'}
                className={classNames(classes.blockText, classes.clickText)}
                variant="body2"
            >
                <Translate id="unknown" />
            </Typography>,
            <Typography className={classNames(classes.plainText)} key={rowData.performanceId + '_idf'} variant="caption">
                --
            </Typography>,
        ]
    } else if (dataKey === 'timeDifference') {
        data = <TimeDifferenceIndicator delay={rowData.plannedStartDelay > 0 ? rowData.plannedStartDelay : 0} />
    }

    return (
        <TableCell
            component="div"
            className={classNames(classes.tableCell, {
                [classes.flexContainer]: dataKey === 'plannedStartTime' || dataKey === 'plannedEndTime',
                [classes.tableRowRed]: rowData.status === 2,
                [classes.tableRowYellow]: rowData.status === 1,
            })}
            variant="body"
            style={{ height: rowHeight }}
        >
            {data}
        </TableCell>
    )
}
