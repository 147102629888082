import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
export default async (value) => {
    const { url, subscriptionKey } = requestData

    const fullUrl = `${url}/espeed/settings/processpredrivetasks`

    return await axios.post(fullUrl, {
        value: value,
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    })
}