import React from 'react'
import { string, func, object, bool, arrayOf } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

import SubAppButton from '../SubAppButton'

import styles from './styles'

const CollapsibleModule = props => {
    const generateModule = (module, isSecondLevel) => {
        if (
            module.extraRestriction !== undefined &&
            checkFunctionalityDisabled(store, module.extraRestriction[0], module.extraRestriction[1])
        ) {
            return null
        } else if (module.isCollapsable) {
            return (
                <ListItem onClick={() => handleClick(module.id, true, isOpen)} button className={classes.listItem}>
                    <Typography variant="h6" className={classes.buttonText}>
                        <Translate id={id} />
                    </Typography>
                    <ListItemSecondaryAction style={{cursor: 'pointer'}}>
                        {/* eslint-disable-next-line max-len */}
                        {isOpen ? <ExpandLess onClick={() => handleClick(module.id, true, isOpen)} className={classes.menuIcon} /> : <ExpandMore onClick={() => handleClick(module.id, true, isOpen)} className={classes.menuIcon} />}
                    </ListItemSecondaryAction>
                </ListItem>
            )
        }
        return (
            <SubAppButton
                isChild
                key={module.id}
                id={module.id}
                path={module.path}
                goToPath={goToPath}
                isSecondLevel={isSecondLevel}
            />
        )
    }

    const generateHead = (headId, headIsOpen, isSecondLevel, parentId) => (
        <ListItem
            onClick={() => handleClick(headId, isSecondLevel, headIsOpen, parentId)}
            button
            className={isSecondLevel ? classes.subListItem : classes.listItem}
        >
            <Typography variant="h6" className={classes.buttonText}>
                <Translate id={headId} />
            </Typography>
            <ListItemSecondaryAction style={{cursor: 'pointer'}}>
                {/* eslint-disable-next-line max-len */}
                {headIsOpen ? <ExpandLess onClick={() => handleClick(headId, isSecondLevel, headIsOpen, parentId)} className={classes.menuIcon} /> : <ExpandMore onClick={() => handleClick(headId, isSecondLevel, headIsOpen, parentId)} className={classes.menuIcon} />}
            </ListItemSecondaryAction>
        </ListItem>
    )

    const { classes, id, handleClick, modules, isOpen, goToPath, store } = props

    return (
        <React.Fragment>
            {generateHead(id, isOpen, false)}
            <hr className={classes.divider} />
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List className={classes.menuList}>
                    {modules &&
                        modules.map(module => {
                            if (module.isCollapsable) {
                                return (
                                    <React.Fragment key={module.id}>
                                        {generateHead(module.id, module.isOpen, true, id)}
                                        <Collapse in={module.isOpen} timeout="auto" unmountOnExit>
                                            <List className={classes.menuList}>
                                                {module.subModules.map(el => generateModule(el, true))}
                                            </List>
                                        </Collapse>
                                    </React.Fragment>
                                )
                            } else {
                                return generateModule(module)
                            }
                        })}
                </List>
            </Collapse>
            <hr className={classes.divider} />
        </React.Fragment>
    )
}

CollapsibleModule.propTypes = {
    classes: object.isRequired,
    store: object,
    id: string.isRequired,
    handleClick: func.isRequired,
    goToPath: func.isRequired,
    isOpen: bool,
    modules: arrayOf(object).isRequired,
}

export default compose(withLocalize, withRouter, withStyles(styles))(CollapsibleModule)
