import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
export default async (data, edit) => {
    const { url, subscriptionKey } = requestData
    let fullUrl = `${url}/referencedata/ptcarclassification/add`
    if (edit) fullUrl = `${url}/referencedata/ptcarclassification/update`
    try {
        return await axios.post(fullUrl, {
            ...data,
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
    } catch (e) {
        console.error(e)
        return null
    }
}
