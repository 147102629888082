import React from 'react'

import Header from 'components/Header'
import Grid from '@material-ui/core/Grid'
import { func, array, object } from 'prop-types'

import ProfileSearch from './ProfileSearch'
import { toDateTime } from 'utils/dateParser'
import { Translate } from 'react-localize-redux'
import DepotFilter from '../../../../../../Performances/views/PerformanceOverview/components/DepotFilter'
import Loading from 'components/Loading'
import ProfileStatusTable from '../../ProfileStatusTable'
import RefreshProfilesCalls from './RefreshProfilesCalls'
import ProfileErrorCode from '../../../ProfileErrorCode'

const storeKeys = ['selectedTCTs', 'selectedDepot']
const resetState = {
    radio: 'all',
    date: toDateTime(Date.now()),
    doc: ' ',
    idf: {},
    selectedProfile: '',
    selectedName: '',
    loading: false,
    data: [],
}
class Status extends React.Component {
    state = {
        radio: 'all',
        date: toDateTime(Date.now()),
        doc: ' ',
        idf: {},
        data: [],
        filteredData: [],
        selectedProfile: '',
        selectedName: '',
        loading: false,
    }

    componentDidMount() {
        const callKey = this.props.store.get('selectedDistrict')
        const traindriverprofiles = this.props.store.get(callKey)
        this.setState({ data: traindriverprofiles, filteredData: traindriverprofiles })
    }

    depotQS = props => {
        return props.tcts.reduce(
            (accumulator, tct, index) =>
                tct.depots.reduce(
                    (depotString, depot, depotIndex) =>
                        `${depotString}${index === 0 && depotIndex === 0 ? '' : '%20'}${depot.symbolicName}`,
                    accumulator
                ),
            ''
        )
    }
    onRowRefreshCall = async (e, idfNumber) => {
        e.stopPropagation()
        this.setState({ loading: true })
        const data = await RefreshProfilesCalls(idfNumber)
        if (data) {
            let profileList = [...this.state.filteredData]
            const elementsIndex = profileList.findIndex(element => element.idfNumber === idfNumber)
            profileList[elementsIndex] = data
            this.setState({ loading: false, filteredData: profileList })
        } else this.setState({ loading: false })
    }

    getErrorProfilesCount() {
        let count = 0
        for (let dataObj of this.state.data)
            for (let run of dataObj.runs) {
                if (run.status === 1) {
                    count++
                    break
                }
            }
        return count
    }
    persist = type => props => {
        const toSetState = { [type]: props }
        if (props === 'new') {
            const newProfiles = this.state.data.filter(data => data.newProfile)
            toSetState.filteredData = newProfiles
        }
        if (props === 'error') {
            const errorProfiles = []

            this.state.data.forEach(data => {
                for (let run of data.runs) {
                    if (run.status === ProfileErrorCode.ERROR) {
                        errorProfiles.push(data)
                        break
                    }
                }
            })
            toSetState.filteredData = errorProfiles
        }
        if (props === 'all') {
            toSetState.filteredData = this.state.data
        }
        toSetState.selectedProfile = ''
        toSetState.selectedName = ''

        this.setState(toSetState)
    }

    setName = selectedProfile => {
        const dataByIdf = this.filterByIdf(selectedProfile)
        this.setState({
            selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
            selectedProfile: selectedProfile,
            filteredData: dataByIdf,
        })
    }

    filterByIdf(selectedProfile) {
        const { filteredData } = this.state
        return filteredData.filter(data => data.idfNumber === selectedProfile.idfNumber)
    }
    clearName = () => {
        resetState.filteredData = this.state.data
        this.setState(resetState)
    }

    render() {
        const { selectedDepot, selectedTCTs } = this.props.store.get(storeKeys)
        const { filteredData } = this.state
        const { tcts } = this.props
        let depots = []
        if (selectedDepot === 'performances_label_all_depots') {
            tcts.forEach(tct => {
                if (selectedTCTs.includes(tct.id)) depots = depots.concat(tct.depots.map(depot => depot.id))
            })
        } else {
            depots.push(selectedDepot)
        }

        const mappedPerformances = filteredData.reduce((accumulator, performance) => {
            if (depots[0].includes(`DEPOT-${performance.depotSymbolicName}`)) {
                accumulator.push(performance)
            }

            return accumulator
        }, [])
        const errorProfileCount = this.getErrorProfilesCount()

        return this.state.loading ? (
            <Loading />
        ) : (
            <React.Fragment>
                <Translate>{({ translate }) => <Header isMenu title={translate('manage_profile_status')} />}</Translate>

                <ProfileSearch
                    selectedProfile={this.state.selectedProfile}
                    selectedName={this.state.selectedName}
                    clearName={this.clearName}
                    setName={this.setName}
                    persist={this.persist}
                    radio={this.state.radio}
                    errorProfileCount={errorProfileCount}
                    classes={this.props.classes}
                />

                <ProfileStatusTable data={mappedPerformances} onRowRefreshCall={this.onRowRefreshCall} />

                <Grid item xs={12}>
                    <DepotFilter
                        // onDepotSelected={() => {}}
                        // onTctSelected={() => {}}
                        selectedDepot={selectedDepot}
                        selectedTCTs={selectedTCTs}
                        tcts={this.props.tcts}
                        onChange={this.saveScrollPosition}
                        showGroupedCheckBox={false}
                        disabled={false}
                        // saveScrollPosition={() => ''}
                    />
                </Grid>
            </React.Fragment>
        )
    }
}
Status.propTypes = {
    clearName: func,
    setName: func,
    tcts: array,
    classes: object,
    store: object,
}

export default Status
