import React, { useEffect, useReducer, useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { cloneDeep } from 'lodash'
import { object, func, string, array } from 'prop-types'
import { compose } from 'recompose'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import { withStyles } from '@material-ui/styles'
import { Edit } from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Replay'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import CompositionDrawer from './components/CompositionDrawer'
import MaterialUnit from './components/MaterialUnit'
import VersionInfo from './components/VersionInfo'
import { withStore } from 'react-ion-store'
import ACTION_TYPE from './Utils/ActionType'
import styles from './styles'
import VehicleType from './Utils/VehicleType'
import AddLitteraDialog from './components/AddLitteraDialog'
import LtCode from './Utils/LtCode'
import { isCompoEditingAllowed, isCompositionViewAllowed } from './Utils/isCompoEditingAllowed'
import { withRouter } from 'react-router-dom'
import VehicleInfoDrawer from './components/CompositionDrawer/VehicleInfoDrawer'
import simulateBrakingReport from 'modules/Performances/Services/simulateBrakingReport'
import getTranslationFromLocale from 'utils/returnTranslationForLocale'

//REDUCERS
import {
    INITIAL_STATE_CHECKBOX,
    INITIAL_DRAWER_STATE,
    ACTIONS_CHECKBOX,
    ACTIONS_DRAWER,
    REDUCER_DRAWER,
    REDUCER_CHECKBOX,
} from './commonCompositionViewReducer'
import VehicleNotLitteraAlert from './components/VehicleNotLitteraAlert'

const CommonCompositionView = props => {
    const [compositionData, setCompositionData] = useState()
    const [originalComposition, setOriginalCompositon] = useState()
    const [isEditComposition, setEditComposition] = useState(false)
    const [selectedMUIndex, setMUSelected] = useState()
    const [selectedVehicle, setSelectedVehicle] = useState()
    const [addVehicleAtIndex, setAddVehicleAtIndex] = useState()
    const [options, setOptions] = useState([])
    const [isResetDisable, setResetDisable] = useState(true)
    const [litteraSearchOptions, setLitteraOptions] = useState([])
    const [selectedLitteraVehicle, setSelectedLittera] = useState()
    const [openLitteraDialog, setOpenLitteraDialog] = useState(false)
    const [litteraVehicleToAdd, setSelectedLitteraVehicleToAdd] = useState(null)
    const [vehicleInfoObj, setVehicleInfoObj] = useState(null)
    const [isBrakingReportSimulated, setIsBrakingReportSimulated] = useState(false)
    const [simulatedBrakingReport, setSimulatedBrakingReport] = useState(null)
    const [simulatedBrakingReportLoading, setSimulatedBrakingReportLoading] = useState(false)
    const [usedMaterialSubTypeNames, setUsedMaterialSubTypeNames] = useState([])

    const [checkboxState, checkBoxReducer] = useReducer(REDUCER_CHECKBOX, INITIAL_STATE_CHECKBOX)
    const [drawerState, drawerReducer] = useReducer(REDUCER_DRAWER, INITIAL_DRAWER_STATE)

    const [vehicleNotLittera, setVehicleNotLittera] = useState(undefined)
    const [showVehicleNotLitteraAlert, setShowVehicleNotLitteraAlert] = useState(false)
    const toggleShowVehicleNotLitteraAlert = vehicleNumber => {
        setVehicleNotLittera(vehicleNumber)
        setShowVehicleNotLitteraAlert(!showVehicleNotLitteraAlert)
    }

    const handleAddComposition = index => {
        setAddVehicleAtIndex(index)
        drawerReducer({ type: ACTIONS_DRAWER.ADD_COMP })
    }
    const onDrawerClose = () => {
        drawerReducer({ type: ACTIONS_DRAWER.DRAWER_CLOSE })
    }

    const handleMUEditClick = tractionPosition => {
        setMUSelected(parseInt(tractionPosition))
        drawerReducer({ type: ACTIONS_DRAWER.EDIT_MUE })
    }
    /**
     * To move upwards we will change position of the selected material units to its privous element in array.
     * will interchange their traction position as well.
     */
    const handleUpwardClick = () => {
        let data = cloneDeep(compositionData)
        let mu = [...data.materialUnits]

        let element1 = { ...mu[selectedMUIndex - 1] } // selected material unit
        let pos1 = element1.tractionPosition
        let element2 = mu[selectedMUIndex - 2] // previous to selected material unit
        let pos2 = element2.tractionPosition

        //replace tractionPosition
        element2.tractionPosition = pos1
        element1.tractionPosition = pos2

        let MUC2Length = element2.materialUnitChilds.length
        let apForElement1 = MUC2Length > 0 ? element2.materialUnitChilds[0].absolutePosition : element2.absolutePosition
        let MUC1Length = element1.materialUnitChilds.length

        if (MUC1Length > 0)
            for (let i = 0; i < MUC1Length; i++) {
                element1.materialUnitChilds[i].tractionPosition = element1.tractionPosition
                element1.materialUnitChilds[i].absolutePosition = apForElement1.toString()
                apForElement1 = parseInt(apForElement1) + 1
            }
        else {
            element1.absolutePosition = apForElement1.toString()
            apForElement1 = parseInt(apForElement1) + 1
        }
        if (MUC2Length > 0) {
            for (let i = 0; i < MUC2Length; i++) {
                element2.materialUnitChilds[i].tractionPosition = element2.tractionPosition
                element2.materialUnitChilds[i].absolutePosition = parseInt(apForElement1).toString()
                apForElement1 = parseInt(apForElement1) + 1
            }
        } else element2.absolutePosition = apForElement1.toString()

        //replace index of material units inside array
        mu.splice(selectedMUIndex - 1, 1)
        mu.splice(selectedMUIndex - 2, 0, element1)
        data.materialUnits = mu
        setMUSelected(selectedMUIndex - 1)
        setCompositionData(data)
        setResetDisable(false)
    }
    /**
     * For this we will change the position of selected material unit to its next material unit in array.
     * will replace their traction position as well.
     */
    const handleDownwardClick = () => {
        let data = cloneDeep(compositionData)
        let mu = [...data.materialUnits]

        let element1 = { ...mu[selectedMUIndex - 1] } // selected material unit
        let pos1 = element1.tractionPosition
        let element2 = mu[selectedMUIndex] // next to selected material unit
        let pos2 = element2.tractionPosition

        //replace tractionPosition
        element2.tractionPosition = pos1
        element1.tractionPosition = pos2

        let MUC1Length = element1.materialUnitChilds.length
        let apForElement2 = MUC1Length > 0 ? element1.materialUnitChilds[0].absolutePosition : element1.absolutePosition
        let MUC2Length = element2.materialUnitChilds.length

        if (MUC2Length > 0)
            for (let i = 0; i < MUC2Length; i++) {
                element2.materialUnitChilds[i].tractionPosition = element2.tractionPosition
                element2.materialUnitChilds[i].absolutePosition = apForElement2.toString()
                apForElement2 = parseInt(apForElement2) + 1
            }
        else {
            element2.absolutePosition = apForElement2.toString()
            apForElement2 = parseInt(apForElement2) + 1
        }
        if (MUC1Length > 0) {
            for (let i = 0; i < MUC1Length; i++) {
                element1.materialUnitChilds[i].tractionPosition = element1.tractionPosition
                element1.materialUnitChilds[i].absolutePosition = parseInt(apForElement2).toString()
                apForElement2 = parseInt(apForElement2) + 1
            }
        } else element1.absolutePosition = apForElement2.toString()

        //replace index of material units inside array
        mu.splice(selectedMUIndex - 1, 1)
        mu.splice(selectedMUIndex, 0, element1)
        data.materialUnits = mu
        setMUSelected(selectedMUIndex + 1)
        setCompositionData(data)
        setResetDisable(false)
    }
    const handleMaterialUnitDelete = () => {
        let data = cloneDeep(compositionData)
        let mu = [...data.materialUnits]
        let numberOfVehiclesDeleted = mu[selectedMUIndex - 1].materialUnitChilds.length || 1

        for (let i = selectedMUIndex; i < mu.length; i++) {
            let unit = mu[i]
            let newTractionPosition = (parseInt(unit.tractionPosition) - 1).toString()
            // MU level traction positon and absolute position changed
            unit.tractionPosition = newTractionPosition

            let mucLength = unit.materialUnitChilds.length
            if (mucLength === 0) {
                unit.absolutePosition = (parseInt(unit.absolutePosition) - numberOfVehiclesDeleted).toString()
            } else {
                // MU child level traction positon and absolute position changed
                unit.materialUnitChilds.forEach(child => {
                    child.tractionPosition = newTractionPosition
                    child.absolutePosition = (parseInt(child.absolutePosition) - numberOfVehiclesDeleted).toString()
                })
            }
        }
        mu.splice(selectedMUIndex - 1, 1)
        data.materialUnits = mu
        setCompositionData(data)
        setMUSelected()
        drawerReducer({ type: ACTIONS_DRAWER.DRAWER, payload: false })
        setResetDisable(false)
    }

    const handleChangeInDirection = () => {
        let data = cloneDeep(compositionData)
        let materialUnits = [...data.materialUnits]

        materialUnits.reverse()
        let ap = 1
        let index = 0
        materialUnits.forEach(unit => {
            // change the traction position at unit level
            unit.tractionPosition = (index + 1).toString()
            let len = unit.materialUnitChilds.length

            //invers inDirection
            unit.inDirection = !unit.inDirection
            let materialUnit = { ...unit }

            if (len > 0) {
                unit.absolutePosition = '0'
                let childs = [...materialUnit.materialUnitChilds]
                childs.reverse()
                let childIndex = 0
                childs.forEach(child => {
                    //change the subPosition at child level
                    child.subPosition = (childIndex + 1).toString()
                    child.absolutePosition = ap.toString()
                    child.tractionPosition = unit.tractionPosition
                    ap++
                    childIndex++
                })
                unit.materialUnitChilds = childs
            } else {
                unit.absolutePosition = ap
                ap++
            }
            index++
        })
        data.materialUnits = [...materialUnits]
        setCompositionData(data)
        setResetDisable(false)
    }

    const handleChangeInDirectionMUClevel = () => {
        let data = cloneDeep(compositionData)
        let materialUnits = [...data.materialUnits]
        let MU = { ...materialUnits[selectedMUIndex - 1] }
        MU.inDirection = !MU.inDirection // change the inDirection property at Unit level.
        let len = MU.materialUnitChilds.length
        // In case unit has child we need to inverse absoulute position and subposition
        if (len > 0) {
            let ap = MU.materialUnitChilds[0].absolutePosition
            let sp = MU.materialUnitChilds[0].subPosition
            let mucs = [...MU.materialUnitChilds]
            mucs.reverse()
            mucs.forEach(muc => {
                muc.absolutePosition = ap.toString()
                muc.subPosition = sp.toString()
                ap = parseInt(ap) + 1
                sp = parseInt(sp) + 1
            })
            MU.materialUnitChilds = mucs
        }
        data.materialUnits[selectedMUIndex - 1] = MU
        setCompositionData(data)
        setResetDisable(false)
    }
    const isTractionAvailable = () => {
        let isVehicleLittera = compositionData?.materialUnits[selectedMUIndex - 1]?.groupType === VehicleType.LITTERA
        return {
            isTraction: isVehicleLittera ? false : true,
            IsLocoInUse: compositionData?.materialUnits[selectedMUIndex - 1]?.isLocomotiveInUse,
        }
    }
    const handleInDienstClick = () => {
        let data = cloneDeep(compositionData)
        let materialUnits = [...data.materialUnits]
        let MU = { ...materialUnits[selectedMUIndex - 1] }
        MU.isLocomotiveInUse = !MU.isLocomotiveInUse
        let mucs = [...MU.materialUnitChilds]
        mucs.forEach(muc => {
            muc.isLocomotiveInUse = !muc.isLocomotiveInUse
        })
        MU.materialUnitChilds = mucs
        data.materialUnits[selectedMUIndex - 1] = MU
        setCompositionData(data)
        drawerReducer({ type: ACTIONS_DRAWER.DRAWER, payload: false })
        setResetDisable(false)
    }
    /**
     * In case material unit does not have any child we will update defect type at materilUnit level
     * @param {*} mucs Mucs is updated with defect type selected.
     *  In case of no child it is list of defect selected on MU level
     */
    const handleDamageChange = mucs => {
        let data = cloneDeep(compositionData)
        let materialUnits = [...data.materialUnits]
        let MU = { ...materialUnits[selectedMUIndex - 1] }
        if (MU.materialUnitChilds.length) {
            MU.materialUnitChilds = mucs
        } else {
            MU.compositionDefectTypeCodes = mucs
        }
        data.materialUnits[selectedMUIndex - 1] = MU
        setCompositionData(data)
        setResetDisable(false)
    }

    const handleSearchValue = e => {
        if (e && e?.value) {
            setSelectedVehicle(e)
            if (checkboxState.lit) {
                addCompleteLittera(e)
                return
            }
            let vSubType = props.vehicleNumberSubType.filter(subType => subType.vehicleNumber === e.value)[0]
            let mSubType = props.materialSubTypes.filter(
                subType => subType.name[1].translation === vSubType.description[1].translation
            )[0]
            let materialType = props.materialTypes.filter(material => material.materialTypeId === mSubType.materialTypeId)[0]
            let data = cloneDeep(compositionData)
            let munits = [...data.materialUnits]
            let objectToBeAdded = {}
            let mucToBeAdded = null
            const TPForChild = drawerState.drawerForAddVehicle
                ? (addVehicleAtIndex + 1).toString()
                : munits[selectedMUIndex - 1].tractionPosition
            // Vehicle is Littera
            if (materialType?.ltCode === LtCode.LITTERA) {
                let found = false
                for (let i = 0; i < props.litteras.length; i++) {
                    let currentLittera = props.litteras[i]
                    found = currentLittera.vehicles.find(x => x.vehicleNumber == e.value) != undefined
                    if (found) break
                }
                if (found) {
                    drawerReducer({ type: ACTIONS_DRAWER.DRAWER, payload: false })
                    setOpenLitteraDialog(true)
                    setSelectedLitteraVehicleToAdd(e.value)
                    return
                } else {
                    addCompleteLittera(e)
                    return
                }
            }
            // Locomotive or motorunit
            else {
                mucToBeAdded = props.vehicleNumberUnit
                    .filter(vehicle => {
                        return vehicle.vehicleNumber === e.value
                    })
                    .sort((a, b) => a.vehicleUnitPosition - b.vehicleUnitPosition)
                    .map(({ vehicleUnitNumber, vehicleUnitPosition, vehicleNumber }) => ({
                        vehicleUnit: vehicleUnitNumber,
                        vehicleNumber: vehicleNumber,
                        subPosition: vehicleUnitPosition.toString(),
                        compositionDefectTypeCodes: [],
                        hasDriverCockpit: vSubType.hasDriverCockpit,
                        tractionPosition: TPForChild,
                    }))

                objectToBeAdded.diagramShortName = vSubType.diagramShortName
                objectToBeAdded.serviceNumber = vSubType.serviceNumber
                objectToBeAdded.materialTypeCode = vSubType.materialTypeCode
                objectToBeAdded.materialSubTypeCode = vSubType.materialSubTypeCode
                objectToBeAdded.pullPushType = vSubType.pullPushType
                objectToBeAdded.uicCode = vSubType.uicCode
                objectToBeAdded.vehicleUicCode = vSubType.vehicleUicCode
                objectToBeAdded.vehicleNumber = vSubType.vehicleNumber
                objectToBeAdded.materialName = vSubType.materialTypeCode
                objectToBeAdded.vehicleUicCode = vSubType.uicCode
                objectToBeAdded.isLocomotiveInUse = materialType?.traction !== 3
                objectToBeAdded.inDirection = true

                if (mucToBeAdded.length > 1) {
                    objectToBeAdded.materialUnitChilds = mucToBeAdded
                    objectToBeAdded.groupType = 1
                } else {
                    objectToBeAdded.groupType = 0
                    objectToBeAdded.materialUnitChilds = []
                    objectToBeAdded = { ...objectToBeAdded, ...mucToBeAdded[0] }
                }
                // In case no child unit then add defectType on material unit level
                objectToBeAdded.compositionDefectTypeCodes = []
            }
            if (drawerState.drawerForAddVehicle) {
                addVehicleAtUnitLevel(objectToBeAdded, mucToBeAdded)
                return
            } else {
                replaceVehicle(objectToBeAdded)
            }
        }
    }
    const addVehicleAtUnitLevel = (objectToBeAdded, mucToBeAdded) => {
        let data = cloneDeep(compositionData)
        let munits = [...data.materialUnits]
        let len = objectToBeAdded.materialUnitChilds.length
        let ap = 0
        if (addVehicleAtIndex === 0) {
            objectToBeAdded.absolutePosition = len > 0 ? '0' : '1'
            ap = len > 0 ? '0' : '1'
        }
        if (addVehicleAtIndex > 0) {
            let previousMUCs = munits[addVehicleAtIndex - 1].materialUnitChilds.length
            ap =
                previousMUCs > 0
                    ? munits[addVehicleAtIndex - 1].materialUnitChilds[previousMUCs - 1].absolutePosition
                    : munits[addVehicleAtIndex - 1].absolutePosition
            objectToBeAdded.absolutePosition = len === 0 ? (parseInt(ap) + 1).toString() : '0'
        }
        for (let i = 0; i < len; i++) {
            objectToBeAdded.materialUnitChilds[i].litteraName = objectToBeAdded.litteraName
            objectToBeAdded.materialUnitChilds[i].absolutePosition = (parseInt(ap) + 1).toString()
            ap++
        }

        for (let i = addVehicleAtIndex; i < munits.length; i++) {
            let unit = munits[i]
            let newTractionPosition = (parseInt(unit.tractionPosition) + 1).toString()
            // MU level traction positon and absolute position changed
            unit.tractionPosition = newTractionPosition
            if (unit.materialUnitChilds.length === 0) {
                unit.absolutePosition = (parseInt(unit.absolutePosition) + (mucToBeAdded.length || 1)).toString()
            }
            // MU child level traction positon and absolute position changed
            unit.materialUnitChilds.forEach((child, index) => {
                child.subPosition = (index + 1).toString()
                child.tractionPosition = newTractionPosition
                child.absolutePosition = (parseInt(child.absolutePosition) + (mucToBeAdded.length || 1)).toString()
            })
        }
        objectToBeAdded.subPosition = '0'
        objectToBeAdded.tractionPosition = (addVehicleAtIndex + 1).toString()
        munits.splice(addVehicleAtIndex, 0, objectToBeAdded)
        data.materialUnits = munits
        drawerReducer({ type: ACTIONS_DRAWER.DRAWER, payload: false })
        setCompositionData(data)
        setResetDisable(false)
    }

    const replaceVehicle = objectToBeAdded => {
        let data = cloneDeep(compositionData)
        let munits = [...data.materialUnits]
        let indexToReplaceMU = munits.findIndex(unit => parseInt(unit.tractionPosition) === selectedMUIndex)
        objectToBeAdded.tractionPosition = munits[indexToReplaceMU].tractionPosition.toString()
        objectToBeAdded.diagramShortName = munits[indexToReplaceMU].diagramShortName
        objectToBeAdded.serviceNumber = munits[indexToReplaceMU].serviceNumber
        let ap = 0
        let len = objectToBeAdded.materialUnitChilds.length
        if (indexToReplaceMU === 0) {
            ap = len > 0 ? '0' : '1'
        } else {
            let previousMUCs = munits[indexToReplaceMU - 1].materialUnitChilds.length
            ap =
                previousMUCs > 0
                    ? munits[indexToReplaceMU - 1].materialUnitChilds[previousMUCs - 1].absolutePosition
                    : munits[indexToReplaceMU - 1].absolutePosition
        }

        if (len === 0) {
            objectToBeAdded.absolutePosition = (parseInt(ap) + 1).toString()
            ap++
        } else {
            objectToBeAdded.absolutePosition = '0'
            for (let i = 0; i < len; i++) {
                objectToBeAdded.materialUnitChilds[i].litteraName = objectToBeAdded.litteraName
                objectToBeAdded.materialUnitChilds[i].absolutePosition = (parseInt(ap) + 1).toString()
                ap++
            }
        }

        for (let i = selectedMUIndex; i < munits.length; i++) {
            let unit = munits[i]
            let childLength = unit.materialUnitChilds.length
            if (childLength === 0) {
                unit.absolutePosition = (parseInt(ap) + 1).toString()
                ap++
            } else {
                for (let i = 0; i < childLength; i++) {
                    unit.materialUnitChilds[i].absolutePosition = (parseInt(ap) + 1).toString()
                    ap++
                }
            }
        }

        munits.splice(indexToReplaceMU, 1, objectToBeAdded)
        data.materialUnits = munits
        drawerReducer({ type: ACTIONS_DRAWER.DRAWER, payload: false })
        setCompositionData(data)
        setResetDisable(false)
    }

    const handleNMBSCheckBox = showNmbs => {
        checkBoxReducer({ type: ACTIONS_CHECKBOX.NMBS, payload: showNmbs })
    }
    const handleUICCheckBox = showUic => {
        checkBoxReducer({ type: ACTIONS_CHECKBOX.UICC, payload: showUic })
    }
    const handleLitteraCheckBox = showLittera => {
        checkBoxReducer({ type: ACTIONS_CHECKBOX.LIT, payload: showLittera })
    }

    const getRelevantDamages = vehicleNumber => {
        let vSubType = props.vehicleNumberSubType.filter(
            subType => subType.vehicleNumber.toString() === vehicleNumber.toString()
        )[0]
        if (vSubType) {
            let mSubType = props.materialSubTypes.filter(
                subType => subType.name[1].translation === vSubType.description[1].translation
            )[0]
            let materialType = props.materialTypes.filter(material => material.materialTypeId === mSubType.materialTypeId)[0]
            vSubType.ltCode = materialType?.ltCode
            return vSubType
        }
        return null
    }
    const mapOptions = () => {
        let optionsToBeExculded = []
        compositionData?.materialUnits.map(mu => {
            let mucs = mu.materialUnitChilds
            if (mucs.length > 0)
                mucs.map(muc => {
                    optionsToBeExculded.push(parseInt(muc.vehicleNumber))
                })
            else if (mu.vehicleNumber) optionsToBeExculded.push(parseInt(mu.vehicleNumber))
        })
        const uniqueSet = new Set(optionsToBeExculded)
        optionsToBeExculded = [...uniqueSet]

        let sorted
        let mapped = []
        let mappedUsedMaterialSubTypeNames = []
        if (checkboxState.nmbs) {
            for (let item of props.vehicleNumberSubType) {
                if (!optionsToBeExculded.includes(item.vehicleNumber)){
                    mapped.push({
                        value: item.vehicleNumber,
                        // eslint-disable-next-line max-len
                        label: `${item.description.length ? getTranslationFromLocale(item.description, props.activeLanguage.code === 'fr' ? 'fr-BE' : 'nl-BE') : ''} -${item.vehicleNumber}`,
                    })
                }
                else{
                    mappedUsedMaterialSubTypeNames.push({
                        value: item.vehicleNumber,
                        // eslint-disable-next-line max-len
                        label: `${item.description.length ? getTranslationFromLocale(item.description, props.activeLanguage.code === 'fr' ? 'fr-BE' : 'nl-BE') : ''}`,
                    })
                }
            }
            setUsedMaterialSubTypeNames(mappedUsedMaterialSubTypeNames)
            sorted = mapped.sort((a, b) => a.value - b.value)
        }
        if (checkboxState.uicc) {
            for (let item of props.vehicleNumberSubType) {
                if (!optionsToBeExculded.includes(item.vehicleNumber)){
                    mapped.push({
                        value: item.vehicleNumber,
                        // eslint-disable-next-line max-len
                        label: `${item.description.length ? getTranslationFromLocale(item.description, props.activeLanguage.code === 'fr' ? 'fr-BE' : 'nl-BE') : ''} -${item.uicCode}`,
                    })
                }
                else{
                    mappedUsedMaterialSubTypeNames.push({
                        value: item.vehicleNumber,
                        // eslint-disable-next-line max-len
                        label: `${item.description.length ? getTranslationFromLocale(item.description, props.activeLanguage.code === 'fr' ? 'fr-BE' : 'nl-BE') : ''}`,
                    })
                }
            }
            setUsedMaterialSubTypeNames(mappedUsedMaterialSubTypeNames)
            sorted = mapped.sort((a, b) => a.value - b.value)
        }

        if (checkboxState.lit) {
            let litteraData = [...props.litteras]
            for (let item of litteraData) {
                mapped.push({
                    label: item.name,
                    value: item.id, //parseInt(item.vehicleNumber),
                })
            }
            sorted = mapped.sort(compare)
        }

        return sorted
    }

    function compare(a, b) {
        if (a.value > b.value) return 1
        if (b.value > a.value) return -1
        return 0
    }

    const getLitteraOptions = () => {
        let litteraOptions = []
        let litterasInCompos = compositionData?.materialUnits
            ?.filter(MU => MU.groupType === VehicleType.LITTERA)
            .map(({ materialUnitChilds }) => materialUnitChilds.map(({ vehicleNumber }) => vehicleNumber))[0]

        let data = [...props.vehicleNumberSubType]
        data.forEach(v => {
            if (!litterasInCompos?.includes(v.vehicleNumber)) {
                litteraOptions.push({
                    value: v.vehicleNumber,
                    label: v.vehicleNumber,
                    litteraName: '',
                    isLittera: v.ltCode == LtCode.LITTERA,
                })
            }
        })
        const uniqueVehicleNumbers = {}
        const distinctOptionsByVehicleNumber = litteraOptions.filter(item => {
            if (!uniqueVehicleNumbers[item.label]) {
                uniqueVehicleNumbers[item.label] = true
                return true
            }
            return false
        })

        return distinctOptionsByVehicleNumber.sort((a, b) => a.label - b.label)
    }
    const handleAddLittera = e => {
        setSelectedLittera(e)
    }
    const resetComposition = () => {
        setMUSelected()
        let original = cloneDeep(originalComposition)
        setCompositionData(original)
        setResetDisable(true)
    }

    // This method set the composition and update the original compositon state also.
    const setComposAndOriginal = compoResponse => {
        let compoData = cloneDeep(compoResponse)
        compoData.materialUnits.sort((a, b) => parseInt(a.tractionPosition) - parseInt(b.tractionPosition))
        compoData.materialUnits.forEach(mu => {
            mu.materialUnitChilds.sort((a, b) => parseInt(a.subPosition) - parseInt(b.subPosition))
        })
        setCompositionData(compoData)
        setOriginalCompositon(cloneDeep(compoData))
    }

    const handleRemoveVehicle = subPosition => {
        let data = cloneDeep(compositionData)
        let mu = [...data.materialUnits]
        let selectedVeh = mu[selectedMUIndex - 1]
        let mucs = selectedVeh.materialUnitChilds
        if (mucs.length === 1) {
            handleMaterialUnitDelete()
        } else {
            for (let i = subPosition; i < mucs.length; i++) {
                let child = selectedVeh.materialUnitChilds[i]
                child.subPosition = child.subPosition - 1
                child.absolutePosition = child.absolutePosition - 1
            }

            mucs.splice(subPosition - 1, 1)
            for (let i = selectedMUIndex; i < mu.length; i++) {
                let unit = mu[i]
                if (parseInt(unit.absolutePosition)) unit.absolutePosition = (parseInt(unit.absolutePosition) - 1).toString()
                // MU child level traction positon and absolute position changed
                else {
                    unit.materialUnitChilds.forEach(child => {
                        child.absolutePosition = (parseInt(child.absolutePosition) - 1).toString()
                    })
                }
            }

            data.materialUnits = mu
            setCompositionData(data)
            setResetDisable(false)
        }
    }
    const moveVehicleUpward = subPosition => {
        if (subPosition > 1) {
            let data = cloneDeep(compositionData)
            let mu = [...data.materialUnits]
            let selectedMU = mu[selectedMUIndex - 1]
            let mucs = selectedMU.materialUnitChilds
            let element1 = mucs[subPosition - 1]
            let element1AP = element1.absolutePosition
            let element1SP = element1.subPosition
            let element2 = mucs[subPosition - 2]
            let element2AP = element2.absolutePosition
            let element2SP = element2.subPosition

            element1.absolutePosition = element2AP
            element1.subPosition = element2SP
            element2.absolutePosition = element1AP
            element2.subPosition = element1SP

            mucs[subPosition - 1] = element2
            mucs[subPosition - 2] = element1
            data.materialUnits = mu
            setCompositionData(data)
            setResetDisable(false)
        }
    }
    const moveVehicleDownward = subPosition => {
        let data = cloneDeep(compositionData)
        let mu = [...data.materialUnits]
        let selectedMU = mu[selectedMUIndex - 1]
        let mucs = selectedMU.materialUnitChilds
        let element1 = mucs[subPosition - 1]
        if (subPosition < mucs.length) {
            let element1AP = element1.absolutePosition
            let element1SP = element1.subPosition
            let element2 = mucs[subPosition]
            let element2AP = element2.absolutePosition
            let element2SP = element2.subPosition

            element1.absolutePosition = element2AP
            element1.subPosition = element2SP
            element2.absolutePosition = element1AP
            element2.subPosition = element1SP

            mucs[subPosition - 1] = element2
            mucs[subPosition] = element1
            data.materialUnits = mu
            setCompositionData(data)
            setResetDisable(false)
        }
    }
    const handleEditLitteraClicked = (action, subPosition) => {
        if (selectedLitteraVehicle.isLittera) {
            if (action === ACTION_TYPE.CHANGE_LITTERA) {
                let data = cloneDeep(compositionData)
                let mu = [...data.materialUnits]
                let selectedMU = mu[selectedMUIndex - 1]
                let mucs = selectedMU.materialUnitChilds
                mucs.filter(child => child.subPosition === subPosition).map(vehicle => {
                    vehicle.vehicleNumber = selectedLitteraVehicle.label
                    vehicle.validFrom = selectedLitteraVehicle.validFrom
                    vehicle.validTo = selectedLitteraVehicle.validTo
                })

                data.materialUnits = mu
                setCompositionData(data)
                setResetDisable(false)
                return
            }
            if (action === ACTION_TYPE.ADD_LITTERA_BEFORE) {
                let data = cloneDeep(compositionData)
                let mu = [...data.materialUnits]
                let selectedMU = mu[selectedMUIndex - 1]
                let mucs = selectedMU.materialUnitChilds
                let addLittera = { ...mucs.filter(child => child.subPosition === subPosition)[0] }

                for (let i = subPosition - 1; i < mucs.length; i++) {
                    let obj = mucs[i]
                    obj.absolutePosition = (parseInt(obj.absolutePosition) + 1).toString()
                    obj.subPosition = (parseInt(obj.subPosition) + 1).toString()
                }
                addLittera.compositionDefectTypeCodes = []
                addLittera.vehicleNumber = selectedLitteraVehicle.label
                addLittera.validFrom = selectedLitteraVehicle.validFrom
                addLittera.validTo = selectedLitteraVehicle.validTo
                addLittera['litteraName'] = selectedLitteraVehicle.litteraName
                mucs.splice(subPosition - 1, 0, addLittera)

                for (let i = selectedMUIndex; i < mu.length; i++) {
                    let unit = mu[i]
                    if (parseInt(unit.absolutePosition))
                        unit.absolutePosition = (parseInt(unit.absolutePosition) + 1).toString()
                    // MU child level traction positon and absolute position changed
                    else {
                        unit.materialUnitChilds.forEach(child => {
                            child.absolutePosition = (parseInt(child.absolutePosition) + 1).toString()
                        })
                    }
                }
                data.materialUnits = mu
                setCompositionData(data)
                setResetDisable(false)
                return
            }
            if (action === ACTION_TYPE.ADD_LITTERA_AFTER) {
                let data = cloneDeep(compositionData)
                let mu = [...data.materialUnits]
                let selectedMU = mu[selectedMUIndex - 1]
                let mucs = selectedMU.materialUnitChilds
                let addLittera = {}
                let addAfter = { ...mucs.filter(child => child.subPosition === subPosition)[0] }

                for (let i = subPosition; i < mucs.length; i++) {
                    let obj = mucs[i]
                    obj.absolutePosition = (parseInt(obj.absolutePosition) + 1).toString()
                    obj.subPosition = (parseInt(obj.subPosition) + 1).toString()
                }
                addLittera.vehicleNumber = selectedLitteraVehicle.label
                addLittera.validFrom = selectedLitteraVehicle.validFrom
                addLittera.validTo = selectedLitteraVehicle.validTo
                addLittera.subPosition = (parseInt(addAfter.subPosition) + 1).toString()
                addLittera.tractionPosition = addAfter.tractionPosition
                addLittera.absolutePosiltion = (parseInt(addAfter.absolutePosition) + 1).toString()
                addLittera.compositionDefectTypeCodes = []
                addLittera['litteraName'] = selectedLitteraVehicle.litteraName
                mucs.splice(subPosition, 0, addLittera)
                for (let i = selectedMUIndex; i < mu.length; i++) {
                    let unit = mu[i]
                    if (parseInt(unit.absolutePosition))
                        unit.absolutePosition = (parseInt(unit.absolutePosition) + 1).toString()
                    // MU child level traction positon and absolute position changed
                    else {
                        unit.materialUnitChilds.forEach(child => {
                            child.absolutePosition = (parseInt(child.absolutePosition) + 1).toString()
                        })
                    }
                }
                data.materialUnits = mu
                setResetDisable(false)
                setCompositionData(data)
                return
            }
        } else {
            toggleShowVehicleNotLitteraAlert(selectedLitteraVehicle.label)
        }
    }

    const closeLitteraDialog = () => {
        setOpenLitteraDialog(false)
    }

    const addSingleLitteraVehicle = litteraToAdd => {
        if (!vehicleIsLittera(litteraToAdd.value)) {
            toggleShowVehicleNotLitteraAlert(litteraToAdd.value)
        } else {
            setOpenLitteraDialog(false)
            let data = cloneDeep(compositionData)
            let munits = [...data.materialUnits]
            let muObject = {}
            let mucObject = {}
            const TPForNewVehicle = drawerState.drawerForAddVehicle
                ? (addVehicleAtIndex + 1).toString()
                : munits[selectedMUIndex - 1].tractionPosition
            let objectToBeAdded = props.vehicleNumberSubType.filter(
                subType => subType.vehicleNumber === litteraToAdd.value
            )[0]

            let mSubType = props.materialSubTypes.filter(
                subType => subType.name[1].translation === objectToBeAdded.description[1].translation
            )[0]
            let materialType = props.materialTypes.filter(material => material.materialTypeId === mSubType.materialTypeId)[0]

            let isItemLittera = props.litteras.filter(x =>
                x.vehicles.some(vehicle => vehicle.vehicleNumber === litteraToAdd.value.toString())
            )[0]
            muObject.absolutePosition = 0
            muObject.compositionDefectTypeCodes = []
            muObject.subPosition = 0
            muObject.groupType = 2
            muObject['litteraName'] = ''
            muObject.tractionPosition = TPForNewVehicle
            muObject.vehicleNumber = objectToBeAdded.vehicleNumber
            muObject.vehicleUicCode = objectToBeAdded.uicCode
            muObject.materialName = null
            muObject.serviceNumber = null
            mucObject.hasDriverCockpit = false

            // material unit child level settings
            mucObject.absolutePosition = 0
            mucObject.compositionDefectTypeCodes = []
            mucObject.subPosition = 1
            mucObject.groupType = 2
            mucObject.tractionPosition = TPForNewVehicle
            mucObject.vehicleUicCode = objectToBeAdded.uicCode
            mucObject.vehicleUnit = null
            mucObject.vehicleNumber = objectToBeAdded.vehicleNumber
            mucObject.litteraName = isItemLittera?.name
            mucObject.materialName = null
            mucObject.diagramShortName = null
            mucObject.hasDriverCockpit = false
            mucObject.materialTypeCode = materialType.code
            mucObject.materialSubTypeCode = mSubType.code

            muObject.materialUnitChilds = [mucObject]
            if (drawerState.drawerForAddVehicle) addVehicleAtUnitLevel(muObject, mucObject)
            else replaceVehicle(muObject)
        }
    }
    const addCompleteLittera = litteraToAdd => {
        if (!vehicleIsLittera(litteraToAdd.value)) {
            toggleShowVehicleNotLitteraAlert(litteraToAdd.value)
        } else {
            setOpenLitteraDialog(false)
            let data = cloneDeep(compositionData)
            let munits = [...data.materialUnits]
            let isItemLittera
            // if littera checkbox is checked we are checking whole littera with id else we will check vehicle number
            if (checkboxState.lit) {
                isItemLittera = props.litteras.filter(item => item.id === litteraToAdd.value)
            } else
                isItemLittera = props.litteras.filter(x =>
                    x.vehicles.some(vehicle => vehicle.vehicleNumber === litteraToAdd.value.toString())
                )
            if (isItemLittera.length < 1) addSingleLitteraVehicle(litteraToAdd)
            else {
                const TPForNewVehicle = drawerState.drawerForAddVehicle
                    ? (addVehicleAtIndex + 1).toString()
                    : munits[selectedMUIndex - 1].tractionPosition

                let vehicleNumberFromLittera = isItemLittera[0].vehicles.find(vehicle => vehicle.vehicleNumber !== null)
                let vehicleNSType = props.vehicleNumberSubType.filter(subType => {
                    return subType.vehicleNumber.toString() === vehicleNumberFromLittera.vehicleNumber.toString()
                })[0]
                let mSubType = props.materialSubTypes.filter(
                    subType => subType.name[1].translation === vehicleNSType.description[1].translation
                )[0]
                let materialType = props.materialTypes.filter(
                    material => material.materialTypeId === mSubType.materialTypeId
                )[0]
                let objectToBeAdded = { ...isItemLittera[0] }
                let mucToBeAdded = objectToBeAdded.vehicles
                    ?.sort((a, b) => a.orderNumber - b.orderNumber)
                    .map((obj, index) => ({
                        ...obj,
                        compositionDefectTypeCodes: [],
                        inDirection: true,
                        subPosition: (index + 1).toString(),
                        hasDriverCockpit: false,
                        tractionPosition: TPForNewVehicle,
                        materialTypeCode: materialType.code,
                        materialSubTypeCode: mSubType.code,
                    }))
                objectToBeAdded.subPosition = '0'
                objectToBeAdded['litteraName'] = objectToBeAdded.name
                // delete objectToBeAdded['name']
                objectToBeAdded.groupType = 2
                objectToBeAdded['materialUnitChilds'] = mucToBeAdded // Assign new key
                delete objectToBeAdded['vehicles'] // Delete old key
                if (drawerState.drawerForAddVehicle) addVehicleAtUnitLevel(objectToBeAdded, mucToBeAdded)
                else replaceVehicle(objectToBeAdded)
            }
        }
    }
    const handleMUCInfoClick = vehicleNumberInfo => {
        let vehicleDetails = props.vehicleNumberSubType.filter(subType => subType.vehicleNumber == vehicleNumberInfo)[0]
        setVehicleInfoObj(vehicleDetails)
        drawerReducer({ type: ACTIONS_DRAWER.VEHICLE_DRAWER, payload: true })
    }
    const handleCloseInfoDrawer = () => {
        drawerReducer({ type: ACTIONS_DRAWER.VEHICLE_DRAWER, payload: false })
    }

    const vehicleIsLittera = vehicleNumber => {
        if (typeof vehicleNumber === 'string' && vehicleNumber.indexOf('LITTERA') > -1) {
            return true
        }
        let vehicle = props.vehicleNumberSubType.find(x => x.vehicleNumber == vehicleNumber)
        if (vehicle !== undefined && vehicle.ltCode == LtCode.LITTERA) return true
        return false
    }

    useEffect(() => {
        let opts = mapOptions()
        setOptions(opts)
    }, [compositionData, checkboxState.uicc, checkboxState.nmbs, checkboxState.lit, props.activeLanguage.code])

    useEffect(() => {
        setComposAndOriginal(props.compositionData)
        let litterasOptions = getLitteraOptions()
        setLitteraOptions(litterasOptions)
    }, [props.compositionData])

    useEffect(() => {
        let active = true

        const simulateBrakingreport = async (composition) => {
            setSimulatedBrakingReport(null)
            setSimulatedBrakingReportLoading(true)
            const brakingReport = await simulateBrakingReport(composition)
            // check to prevent race condition
                if(active){
                    setSimulatedBrakingReport(brakingReport)
                    setSimulatedBrakingReportLoading(false)
                    setIsBrakingReportSimulated(true)
                }
        }
        if(compositionData != null){
            if(JSON.stringify(compositionData) !== JSON.stringify(originalComposition) 
            || compositionData.brakingReport == null){
                simulateBrakingreport(compositionData)
            }
            
            else{
                active = false
                setSimulatedBrakingReport(null)
                setIsBrakingReportSimulated(false)
            }
        }

        return () => {
            active = false;
          };
    }, [compositionData])
    
    const { onSaveClicked, store, activeLanguage } = props
    if (!isCompositionViewAllowed(props)) {
        props.history.replace('/')
        return <div />
    } else
        return (
            <>
                <div>
                    {compositionData ? (
                        <>
                            <VersionInfo
                                versionTime={compositionData.versionTime}
                                deviceUser={compositionData.deviceUserName}
                                versionNumber={
                                    compositionData.versionNumber === undefined
                                        ? compositionData.eDriveVersionNumber
                                        : compositionData.versionNumber
                                }
                                isLocalVersion={compositionData.isLocalVersion}
                                translate={props.translate}
                                brakingReport={isBrakingReportSimulated ? simulatedBrakingReport 
                                : compositionData.brakingReport}
                                resetComposition={resetComposition}
                                onSaveClicked={() => onSaveClicked(compositionData)}
                                store={store}
                                isResetDisable={isResetDisable}
                                idfNumber={compositionData.idfNumber}
                                status={compositionData.status}
                                isSaveDisable={compositionData?.materialUnits.length < 1}
                                isBrakingReportSimulated={isBrakingReportSimulated}
                                simulatedBrakingReportLoading={simulatedBrakingReportLoading}
                            />

                            <div
                                style={{
                                    display: isEditComposition ? 'flex' : 'none',
                                    position: 'absolute',
                                    right: '0px',
                                }}
                            >
                                <Typography> {props.translate('composition_edit_direction_text')} </Typography>
                                <ReplayIcon
                                    className={props.classes.changeDirection}
                                    onClick={() => {
                                        handleChangeInDirection()
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div className={props.classes.compositionView}>
                                    <div>
                                        <DoubleArrowIcon className={props.classes.arrowIcon} />

                                        <AddCircleOutlineIcon
                                            style={{ display: isEditComposition ? '' : 'none' }}
                                            className={props.classes.changeDirection}
                                            onClick={() => {
                                                handleAddComposition(0)
                                            }}
                                        />
                                    </div>

                                    {compositionData?.materialUnits?.map((unit, index) => {
                                        return (
                                            <div
                                                key={
                                                    (unit?.vehicleUicCode ? unit.vehicleUicCode : unit?.litteraName) +
                                                    unit?.tractionPosition +
                                                    unit?.diagramShortName +
                                                    unit?.serviceNumber
                                                }
                                            >
                                                <MaterialUnit
                                                    usedMaterialSubTypeNames={usedMaterialSubTypeNames}
                                                    data={unit}
                                                    handleMUEditClick={handleMUEditClick}
                                                    selectedMUIndex={selectedMUIndex}
                                                    store={store}
                                                    handleMUCInfoClick={handleMUCInfoClick}
                                                />
                                                <AddCircleOutlineIcon
                                                    style={{ display: isEditComposition ? '' : 'none' }}
                                                    className={props.classes.changeDirection}
                                                    onClick={() => {
                                                        handleAddComposition(index + 1)
                                                    }}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>

                                <Edit
                                    className={
                                        isCompoEditingAllowed(props) ? props.classes.editIcon : props.classes.disabled
                                    }
                                    onClick={() => {
                                        if (isCompoEditingAllowed(props)) setEditComposition(!isEditComposition)
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        'No Data'
                    )}
                    {drawerState.drawer && (
                        <CompositionDrawer
                            translate={props.translate}
                            isOpen={drawerState.drawer}
                            onClose={onDrawerClose}
                            data={compositionData.materialUnits[selectedMUIndex - 1]}
                            handleUpwardClick={handleUpwardClick}
                            handleDownwardClick={handleDownwardClick}
                            handleMaterialUnitDelete={handleMaterialUnitDelete}
                            upwardDisabled={parseInt(selectedMUIndex) === 1}
                            downwardDisabled={parseInt(selectedMUIndex) === compositionData.materialUnits.length}
                            handleChangeInDirectionMUClevel={handleChangeInDirectionMUClevel}
                            haveTraction={isTractionAvailable().isTraction}
                            IsLocoInUse={isTractionAvailable().IsLocoInUse}
                            handleInDienstClick={handleInDienstClick}
                            handleDamageChange={handleDamageChange}
                            options={options}
                            handleSearchValue={handleSearchValue}
                            selectedVehicle={selectedVehicle}
                            isLittera={checkboxState.lit}
                            isNMBS={checkboxState.nmbs}
                            isUIC={checkboxState.uicc}
                            handleLitteraCheckBox={handleLitteraCheckBox}
                            handleNMBSCheckBox={handleNMBSCheckBox}
                            handleUICCheckBox={handleUICCheckBox}
                            openDrawerForAddVehicle={drawerState.drawerForAddVehicle}
                            getRelevantDamages={getRelevantDamages}
                            handleRemoveVehicle={handleRemoveVehicle}
                            moveVehicleUpward={moveVehicleUpward}
                            moveVehicleDownward={moveVehicleDownward}
                            litteraSearchOptions={litteraSearchOptions}
                            selectedLitteraVehicle={selectedLitteraVehicle}
                            handleAddLittera={handleAddLittera}
                            handleEditLitteraClicked={handleEditLitteraClicked}
                            defectTypes={props.defectTypes}
                            activeLanguage={activeLanguage}
                        />
                    )}
                    {openLitteraDialog && (
                        <AddLitteraDialog
                            open={openLitteraDialog}
                            onClose={closeLitteraDialog}
                            translate={props.translate}
                            vehicleNumber={litteraVehicleToAdd}
                            addSingleLitteraVehicle={addSingleLitteraVehicle}
                            addCompleteLittera={addCompleteLittera}
                            litteraToAdd={selectedVehicle}
                        />
                    )}
                    {drawerState.drawerForVehicleInfo && (
                        <VehicleInfoDrawer
                            isOpen={drawerState.drawerForVehicleInfo}
                            onClose={handleCloseInfoDrawer}
                            vehicleInfo={vehicleInfoObj}
                            translate={props.translate}
                        />
                    )}
                    <VehicleNotLitteraAlert
                        open={showVehicleNotLitteraAlert}
                        toggle={toggleShowVehicleNotLitteraAlert}
                        vehicleNumber={vehicleNotLittera}
                        {...props}
                    />
                </div>
            </>
        )
}
CommonCompositionView.propTypes = {
    classes: object,
    performanceId: object,
    idfNumber: string,
    taskId: object,
    translate: func,
    match: object,
    onSaveClicked: func,
    store: object,
    activeLanguage: object,
    compositionData: object,
    vehicleNumberSubType: array,
    materialSubTypes: array,
    materialTypes: array,
    vehicleNumberUnit: array,
    defectTypes: array,
    litteras: array,
    setIsLoading: func,
    history: object,
}
export default compose(withStore, withRouter, withLocalize, withStyles(styles))(CommonCompositionView)
