export default theme => ({
    buttonText: {
        fontWeight: '400',
    },
    icon: {
        fill: theme.customColors.iconFront,
        background: theme.customColors.iconBg,
        marginRight: theme.spacing(3),
    },
    divider: {
        margin: 0,
        borderWidth: 0,
        height: 1,
        backgroundColor: theme.customColors.lightGrey,
    },
    listItem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    listItemChild: {
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    listItemSubChild: {
        paddingLeft: theme.spacing(6),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
})
