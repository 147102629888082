export default theme => ({
    root: {
        width: '80%',
        height: '50%',
        [theme.breakpoints.down('xs')]: {
            height: '90%',
        },
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            padding: '8px 24px 4px 24px',
        },
    },
    content: {
        [theme.breakpoints.down('sm')]: {
            padding: '4px 24px',
        },
    },
})
