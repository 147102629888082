import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Paper } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DateAndTimePickerWithKeyboard } from 'components/Pickers'
import PropTypes from 'prop-types'
import { TranslationContext } from 'context/translation'

const DateTimePickerModal = props => {
    const { open, handleClose, disableFuture, value, onChange, handleOk } = props
    const { translate } = React.useContext(TranslationContext)

    return (
        <Dialog data-testid="form_dialog" open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{translate('select_date')}</DialogTitle>
            <Paper>
                <DialogContent style={{ minWidth: '600px' }}>
                    <div style={{ marginBottom: '24px' }}>
                        <DateAndTimePickerWithKeyboard
                            ampm={false}
                            format="dd/MM/yyyy HH:mm"
                            openTo={'hours'}
                            value={value}
                            disableFuture={disableFuture}
                            onChange={onChange}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button data-testid="close_button" onClick={() => handleClose()} color="primary">
                        {translate('manage_switch_dialog_cancel')}
                    </Button>
                    <Button data-testid="handle_button" onClick={() => handleOk()} color="primary">
                        {translate('manage_switch_dialog_apply')}
                    </Button>
                </DialogActions>
            </Paper>
        </Dialog>
    )
}

DateTimePickerModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    onChange: PropTypes.func,
    handleOk: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    disableFuture: PropTypes.bool,
}

export default DateTimePickerModal
