import axios from 'axios'
import { DateTime } from 'luxon'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default async (performanceNumber, depot, selectedDate, documentType) => {
    const { url, subscriptionKey } = requestData
    let date

    if (typeof selectedDate === 'number') {
        date = DateTime.fromSeconds(selectedDate).toFormat('ddMMyyyy')
    } else {
        date = selectedDate.toFormat('ddMMyyyy')
    }
    const selectedDepot = depot.substring(depot.indexOf('-') + 1, depot.length)
    const perfNumber = performanceNumber.substring(performanceNumber.indexOf('-') + 1, performanceNumber.indexOf('-') + 5)

    const fullUrl = `${url}/performance/ByParamsInJson/${perfNumber}/${selectedDepot}/${date}/${documentType}`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            return []
        }
        return response.data
    } catch (e) {
        console.error(e)
        return []
    }
}
