import React from 'react'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'

import { withStyles } from '@material-ui/core'

// Components
import DocumentsFooterView from './DocumentsFooterView'

import styles from './styles'

class DocumentsFooter extends React.Component {
    render() {
        return <DocumentsFooterView {...this.props} />
    }
}

export default compose(withStyles(styles), withLocalize)(DocumentsFooter)
