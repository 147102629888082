import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'

import ErrorMessage from 'components/ErrorMessage'
import LineSelect from '../LineSelect'

import styles from './styles'

const textField = (label, value, callback, register, errors, name, type = 'text') => {
    return (
        <TextField
            margin="dense"
            inputRef={register !== undefined ? register({ required: true }) : null}
            error={errors && !!errors[`${name}`]}
            id={name}
            name={name}
            label={label}
            type={type}
            fullWidth
            defaultValue={value}
            onChange={e => {
                callback(e.target.value)
            }}
        />
    )
}

const LineDialog = props => {
    const {
        open,
        handleClose,
        translate,
        error,
        handleAdd,
        handleLineSearchValue,
        lineSearchValue,
        lineNumberData,
        selectedLineSearchItem,
        classes,
    } = props

    const [milestone, setMilestone] = useState('')
    const [distance, setDistance] = useState('')

    const { register, handleSubmit, errors, triggerValidation } = useForm()

    const submitTheForm = data => {
        handleAdd(data.lineNumbers || null, data.milestone, data.distance)
    }

    return (
        <Dialog open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{translate('add_linenumber')}</DialogTitle>
            <Paper>
                <form onSubmit={handleSubmit(submitTheForm)} noValidate autoComplete="off">
                    <DialogContent style={{ minWidth: '600px' }}>
                        <div style={{ marginBottom: '24px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <LineSelect
                                        handleSearchValue={handleLineSearchValue}
                                        searchValue={lineSearchValue}
                                        data={lineNumberData}
                                        lineSelectValue={selectedLineSearchItem}
                                        classes={classes}
                                        register={register !== undefined ? register : null}
                                        errors={errors}
                                        triggerValidation={triggerValidation}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    {textField(
                                        'Milestone',
                                        milestone,
                                        setMilestone,
                                        register,
                                        errors,
                                        'milestone',
                                        'number'
                                    )}
                                </Grid>

                                <Grid item xs={6}>
                                    {textField('Distance', distance, setDistance, register, errors, 'distance', 'number')}
                                </Grid>
                            </Grid>
                            {error && error.length > 0 && <ErrorMessage spacing={false} error={error} />}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                handleClose()
                            }}
                            color="primary"
                        >
                            {translate('manage_switch_dialog_cancel')}
                        </Button>
                        <Button type="submit" color="primary">
                            {translate('button_add')}
                        </Button>
                    </DialogActions>
                </form>
            </Paper>
        </Dialog>
    )
}
LineDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    translate: PropTypes.func,
    error: PropTypes.string,
    handleAdd: PropTypes.func,
    handleLineSearchValue: PropTypes.func,
    lineSearchValue: PropTypes.string,
    lineNumberData: PropTypes.array,
    selectedLineSearchItem: PropTypes.object,
    classes: PropTypes.object,
}
export default compose(withLocalize, withStyles(styles))(LineDialog)
