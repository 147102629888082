import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import { DateAndTimePickerWithKeyboard } from 'components/Pickers'

import styles from '../styles'

import { Paper } from '@material-ui/core'

export class ReasonDialog extends PureComponent {
    state = { loading: false, error: false, reason: '' }

    static propTypes = {
        classes: PropTypes.object,
        open: PropTypes.bool,
        isTouched: PropTypes.bool,
        handleClose: PropTypes.func,
        setReason: PropTypes.func,
        idf: PropTypes.string,
        name: PropTypes.string,
        time: PropTypes.object,
        onChange: PropTypes.func,
    }

    setReasonForChange = reasonForChange => {
        this.setState({
            reason: reasonForChange,
        })
    }
    render() {
        const { open, handleClose, setReason, onChange, isTouched } = this.props

        return (
            <Dialog open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Change time</DialogTitle>
                <Paper>
                    <DialogContent style={{ minWidth: '600px' }}>
                        <DateAndTimePickerWithKeyboard
                            format="dd/MM/yyyy HH:mm"
                            openTo={'hours'}
                            ampm={false}
                            value={this.props.time}
                            onChange={onChange}
                        />

                        <TextField
                            fullWidth={true}
                            label={'reason'}
                            value={this.state.reason}
                            onChange={e => this.setReasonForChange(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                setReason(this.state.reason)
                            }}
                            color="primary"
                            disabled={!this.state.reason.length || !isTouched}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        )
    }
}

export default withStyles(styles)(ReasonDialog)
