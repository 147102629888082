import axios from 'axios'
const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export const UpdatePtDesLindaInteractionSettings = async (data) => {
    const { url, subscriptionKey } = requestData
    let fullUrl = `${url}/legacyReferenceData/ptdesLindaInteractionSettings/update`

    try {
        return await axios.post(fullUrl, data, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
    } catch (e) {
        console.error(e)
        return e.response
    }
}

export default UpdatePtDesLindaInteractionSettings
