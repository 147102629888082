import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus, enums, withCall } from 'react-ion-store'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'
import MobileFeaturesView from './MobileFeaturesView'

import getFeatures from './Services/getFeatures'

import styles from './styles'
import updateFeatureStatus from './Services/updateFeatureStatus'

const {
    CallStatus: { LOADING, FAILED },
} = enums

const Failed = () => <div>Error retrieving Roles!</div>

const UsersAndRoles = props => {
    const [addNewUserToFeatureId, setFeatureIdToAddUser] = useState('')
    const [openAddUserOrOrganisationDrawer, setOpenAddUserOrOrganisationDrawer] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [featureList, setFeatureList] = useState([])

    useEffect(() => {
        let unmounted = false
        setShowLoading(true)
        loadData(unmounted)
        return () => {
            unmounted = true
        }
    }, [])

    const loadData = async unmounted => {
        try {
            setShowLoading(true)
            var features = await getFeatures()
            if (unmounted === undefined || unmounted === false) {
                setFeatureList(features.data)
                setShowLoading(false)
            }
        } catch {
            if (unmounted === undefined || unmounted === false) {
                setFeatureList([])
                setShowLoading(false)
            }
        }
    }

    const updateStatus = async (id, status) => {
        setShowLoading(true)
        updateFeatureStatus(id, status).then((response) => {
            if(response.status == 200){
                reloadPage()
            }
        })
        .catch(() => {
            setShowLoading(false)
    })
    }

    const reloadPage = () => {
        loadData()
    }

    useEffect(() => {
        document.title = props.translate('module_mobile_features')
    }, [])

    const setFeatureIdAndOpenDrawer = featureId => {
        setFeatureIdToAddUser(featureId)
        setOpenAddUserOrOrganisationDrawer(true)
    }
    const resetFeatureIdAndCloseDrawer = () => {
        setFeatureIdToAddUser('')
        setOpenAddUserOrOrganisationDrawer(false)
    }

    const renderOrgSelectList = data => {
        let newArray = []

        if (data && data.length > 0)
            data.forEach(org => {
                const newObj = {
                    value: org.code,
                    label: `${org.description[0].translation}/${org.description[1].translation} (${org.type})`,
                    idString: org.code,
                }
                newArray.push(newObj)
            })

        return newArray
    }

    const { translate, store, __ion_status, history } = props
    const organisationData = props.store.get('OrganisationsData')
    const orgSelectList = renderOrgSelectList(organisationData)

    const { userRoles } = __ion_status

    const moduleList = store.get('moduleList')

    const rolesLoading = !!userRoles && userRoles === LOADING
    const rolesFailed = !!userRoles && userRoles === FAILED

    if (rolesFailed)
        return (
            <React.Fragment>
                <Failed />
            </React.Fragment>
        )
    else if (rolesLoading)
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        )
    else if (!!moduleList && !moduleList.includes('/mobileFeatures')) {
        history.replace(`/`)
    }

    if (showLoading)
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        )
    else
        return (
            <React.Fragment>
                <MobileFeaturesView
                    updateStatus={updateStatus}    
                    translate={translate}
                    featureData={featureList}
                    OrganisationsData={orgSelectList}
                    setFeatureIdAndOpenDrawer={setFeatureIdAndOpenDrawer}
                    resetFeatureIdAndCloseDrawer={resetFeatureIdAndCloseDrawer}
                    openAddUserOrOrganisationDrawer={openAddUserOrOrganisationDrawer}
                    addNewUserToFeatureId={addNewUserToFeatureId}
                    {...props}
                />
            </React.Fragment>
        )
    // }
}
UsersAndRoles.propTypes = {
    activeLanguage: PropTypes.object,
    translate: PropTypes.func,
    store: PropTypes.object,
    history: PropTypes.object,
    __ion_status: PropTypes.object,
}

export default compose(
    withRouter,
    withLocalize,
    withStyles(styles),
    withStore,
    withStatus,
    Component => props =>
        withCall('DistrictData', {
            config: {
                method: 'get',
                url: '/legacyReferenceData/districts',
            },
            reducer: ({ data }) => {
                return data
            },
        })(Component)(props),
    Component => props =>
        withCall('OrganisationsData', {
            config: {
                method: 'get',
                url: '/legacyReferenceData/organisations',
            },
            reducer: ({ data }) => {
                return data
            },
        })(Component)(props)
)(UsersAndRoles)
