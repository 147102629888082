import React from 'react'
import { func, object } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore, withClear, util, enums } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'

import checkWriteAccess from 'utils/checkWriteAccess'
import NoRights from '../../../NoRights'
import MarkAsReadView from './MarkAsReadView'

import styles from './styles'

const {
    CallStatus: { READY },
} = enums

const resetState = {
    selectedName: '',
    selectedProfile: undefined,
    readStateWasSet: false,
}

class MarkAsRead extends React.Component {
    static propTypes = {
        clearReadList: func.isRequired,
        store: object,
    }

    state = resetState

    componentDidUpdate = () => {
        let idf
        if (this.state.selectedProfile) idf = this.state.selectedProfile.idfNumber || this.state.selectedProfile.id

        if (
            this.state.readStateWasSet &&
            idf === this.state.refetchForUser &&
            this.props.store.documentsReadActions &&
            this.props.store.documentsReadActions.get('status') === READY &&
            this.props.store.documentsSetRead &&
            this.props.store.documentsSetRead.get('status') === READY
        )
            this.setState({ readStateWasSet: false, refetchForUser: undefined }, () => {
                const documentsReadActions = util.CreateStoreObject(this.props.store.get('documentsReadActions'))
                this.props.store.call({ documentsReadActions })
            })
    }

    setName = selectedProfile =>
        this.setState(
            {
                selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
                selectedProfile,
                readStateWasSet: false,
            },
            () => {
                this.props.clearReadList()
            }
        )

    clearName = () => this.setState(resetState, () => this.props.clearReadList())

    setToRead = () => {
        let idf
        if (this.state.selectedProfile) idf = this.state.selectedProfile.idfNumber || this.state.selectedProfile.id

        this.setState({ readStateWasSet: true, refetchForUser: idf })
    }

    render() {
        if (checkWriteAccess(this.props, 'mark as read', 'documents.functionality.management.documents')) {
            return (
                <MarkAsReadView
                    {...this.props}
                    {...this.state}
                    setToRead={this.setToRead}
                    setName={this.setName}
                    clearName={this.clearName}
                />
            )
        } else {
            return <NoRights />
        }
    }
}

export default compose(
    withStore,
    withClear('clearReadList', ['documentsReadActions', 'documentsSetRead']),
    withStyles(styles),
    withLocalize
)(MarkAsRead)
