import React from 'react'
import { string, array, object } from 'prop-types'
import { compose } from 'recompose'
import { Translate } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import dateParser from 'utils/dateParser'
import mapKeysToLowerCase from 'utils/mapKeysToLowerCase'

import styles from './styles'

const ReadListView = ({ data, classes, name }) =>
    data.length > 0 ? (
        <React.Fragment>
            {data.map((_status, index) => {
                const status = mapKeysToLowerCase(_status)
                return (
                    <div key={index} className={classes.status}>
                        <Typography>
                            <Translate id="districts_read_date_info_1" />
                            {name}
                            <Translate id="districts_read_date_info_2" />
                            {dateParser(status.timestamp * 1000)}
                        </Typography>
                    </div>
                )
            })}
        </React.Fragment>
    ) : (
        <div className={classes.status}>
            <Typography>
                <Translate id="districts_read_date_info_1" />
                {name}
                <Translate id="districts_read_date_info_3" />
            </Typography>
        </div>
    )

ReadListView.propTypes = {
    name: string.isRequired,
    data: array.isRequired,
    classes: object.isRequired,
}

const Failed = compose(withStyles(styles))(({ classes }) => (
    <div className={classes.status}>
        <Typography>
            <Translate id="districts_read_list_error" />
        </Typography>
    </div>
))

export default compose(withStyles(styles))(ReadListView)
export { Failed }
