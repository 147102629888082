import { blue, grey } from '@material-ui/core/colors'

export default theme => ({
    documentTableWrapper: {
        marginTop: `${theme.spacing(3)}px`,
        padding: `0 ${theme.spacing(3)}px`,
        width: '100%',
        minHeight: theme.readList,
        height: `calc(100vh - ${theme.performanceView}px)`,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    documentTableWrapperClosed: {
        marginTop: `${theme.spacing(3)}px`,
        padding: `0 ${theme.spacing(3)}px`,
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    documentTableWrapperOpen: {
        marginTop: `${theme.spacing(3)}px`,
        marginBottom: `${theme.spacing(12)}px`,
        padding: `0 ${theme.spacing(3)}px`,
        width: '90%',
        minHeight: theme.readList,
        height: `auto`,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableRow: {
        backgroundColor: 'transparent',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
    },
    tableActionCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
    },
    header: {
        borderColor: theme.customColors.grey,
        fontWeight: 'bold',
    },
    headerBold: {
        fontWeight: 'bold',
        margin: 0,
    },
    customInput: {
        backgroundColor: grey['600'],
        height: '35px',
        minWidth: '200px',
        border: `2px solid ${grey['700']}`,
        fontSize: '14px',
        color: theme.customColors.white,
        fontFamily: 'roboto',
        paddingLeft: '8px',
        '&::placeholder': {
            color: `${grey['400']}`,
        },
        '&:focus': {
            outline: 'none',
            borderBottom: `2px solid ${blue['500']}`,
        },
    },
    semesTitle: {
        fontWeight: 'bold',
        margin: 0,
    },
    semesTime: {
        margin: 0,
        display: 'block',
    },
    listView: {
        margin: theme.spacing(2),
        backgroundColor: `${grey['800']}`,
    },
    typeContainer: {
        display: 'grid',
        height: '50px',
        width: '50px',
        placeItems: 'center',
        fontSize: '11px',
        textAlign: 'center',
    },
    redPercentage: {
        color: 'red',
    },
})
