export default theme => ({
    userListContainer: {
        padding: theme.spacing(3),
    },
    eyeIconBackground: {
        backgroundColor: theme.customColors.lightGrey,
        margin: '4px',
        padding: '8px 16px',
        boxSizing: 'content-box',
        borderRadius: '4px',
    },
    cell: {
        borderBottom: '2px solid  rgba(81, 81, 81, 1)',
    },
})
