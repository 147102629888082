import React, { useState, useEffect } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { withStore } from 'react-ion-store'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import DefaultSettings from 'components/DefaultSettings'
import PropTypes from 'prop-types'
import getStoredRefreshRate from 'utils/getStoredRefreshRate'
import updateScreenSettings from 'utils/updateScreenSettings'
import { cloneDeep } from 'lodash'
import { TranslationContext } from 'context/translation'

export const textField = (label, value, callback, register, errors, name, translate) => {
    let pattern = {}

    pattern = { pattern: /^([+-]?[1-9]\d*|0)$/ }

    if (label === 'user_settings_refresh_rate') {
        pattern = { pattern: /^([+-]?[1-9]\d*|0)$/, min: 20 }
    }
    return (
        <TextField
            margin="dense"
            inputRef={register !== undefined ? register({ required: true, ...pattern }) : null}
            error={errors && !!errors[`${name}`]}
            id={name}
            name={name}
            label={translate(label)}
            type={'number'}
            fullWidth
            defaultValue={value}
            onChange={e => {
                callback(e.target.value)
            }}
        />
    )
}

const UserSettingsDialog = props => {
    const { open, handleClose, onAlertSwitched, pageTitle } = props
    const { translate } = React.useContext(TranslationContext)
    const storedSettings = localStorage.getItem('userSettings')
        ? JSON.parse(localStorage.getItem('userSettings'))
        : DefaultSettings
    let pageSettings = storedSettings?.filter(obj => obj.pageReference === pageTitle)[0]
    const defaultValues = DefaultSettings.filter(defaultObj => defaultObj.pageReference === pageTitle)[0]
    const [startHour, setStartHour] = useState(pageSettings ? pageSettings?.startHour : defaultValues.startHour)
    const [endHour, setEndHour] = useState(pageSettings ? pageSettings?.endHour : defaultValues.endHour)
    const [warning, setWarning] = useState(pageSettings ? pageSettings?.warning : defaultValues.warning)
    const [critical, setCritical] = useState(pageSettings ? pageSettings?.critical : defaultValues.critical)
    const [soundTime, setSoundTime] = useState(
        pageSettings?.warningSignal ? pageSettings.warningSignal : defaultValues.warningSignal
    )
    const [enableSound, setEnableSound] = useState(
        pageSettings?.isWarningSound ? JSON.parse(pageSettings.isWarningSound) : defaultValues.isWarningSound
    )

    const [refreshRate, setRefreshRate] = useState(getStoredRefreshRate(pageTitle))

    const refreshRateHandler = value => {
        setRefreshRate(value)
    }

    const { register, handleSubmit, errors, control } = useForm()

    const submitTheForm = async data => {
        if (pageTitle) {
            let storedScreenSettings = cloneDeep(storedSettings)
            let index = storedScreenSettings.findIndex(settings => settings.pageReference === pageTitle)
            index = index === -1 ? storedSettings.length : index
            const obj = {
                pageReference: pageTitle,
                refreshRate: parseInt(data.refreshRate),
                startHour: parseInt(data.startHour),
                endHour: parseInt(data.endHour),
                warning: parseInt(data.warning),
                critical: parseInt(data.critical),
                warningSignal: parseInt(data.soundTime),
                isWarningSound: JSON.parse(data.enableSound),
            }
            storedScreenSettings[index] = obj

            const response = await updateScreenSettings(storedScreenSettings)

            if (response.status === 200) {
                props.store.set({ screenSettings: storedScreenSettings })
                localStorage.setItem('userSettings', JSON.stringify(storedScreenSettings))
                if (props.resetInterval) props.resetInterval()
                if (props.resetIntervalTaskDetail) props.resetIntervalTaskDetail()
            }
        }
        if (onAlertSwitched) onAlertSwitched()
        window.dispatchEvent(new Event('storage'))
        handleClose()
    }

    useEffect(() => {
        const storedUserSettings = localStorage.getItem('userSettings')
            ? JSON.parse(localStorage.getItem('userSettings'))
            : false
        if (storedUserSettings) {
            let settings = storedUserSettings.filter(obj => obj.pageReference === pageTitle)[0]
            try {
                let isSoundEnabled = JSON.parse(settings.isWarningSound)
                setEnableSound(isSoundEnabled)
            } catch {
                setEnableSound(false)
            }
        }
    }, [open])

    return (
        <Dialog
            data-testid="user_settings_dialog"
            open={open}
            onClose={() => handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{translate('user_settings')}</DialogTitle>
            <Paper>
                <form onSubmit={handleSubmit(submitTheForm)} noValidate autoComplete="off">
                    <DialogContent style={{ minWidth: '600px' }}>
                        <div style={{ marginBottom: '24px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    {textField(
                                        'user_settings_start_hour',
                                        startHour,
                                        setStartHour,
                                        register,
                                        errors,
                                        'startHour',
                                        translate
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {textField(
                                        'user_settings_end_hour',
                                        endHour,
                                        setEndHour,
                                        register,
                                        errors,
                                        'endHour',
                                        translate
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {textField(
                                        'user_settings_warning',
                                        warning,
                                        setWarning,
                                        register,
                                        errors,
                                        'warning',
                                        translate
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {textField(
                                        'user_settings_critical',
                                        critical,
                                        setCritical,
                                        register,
                                        errors,
                                        'critical',
                                        translate
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    {textField(
                                        'user_settings_sound_time_setting',
                                        soundTime,
                                        setSoundTime,
                                        register,
                                        errors,
                                        'soundTime',
                                        translate
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        as={
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="enableSound"
                                                        checked={enableSound}
                                                        value={enableSound}
                                                        onChange={() => setEnableSound(!enableSound)}
                                                        color="primary"
                                                    />
                                                }
                                                label={translate('user_settings_enable_sound')}
                                            />
                                        }
                                        onChange={() => setEnableSound(!enableSound)}
                                        defaultValue={enableSound}
                                        inputRef={register !== undefined ? register : null}
                                        name="enableSound"
                                        control={control}
                                    />
                                </Grid>

                                {pageTitle && (
                                    <>
                                        <Grid item xs={12}>
                                            {translate('user_settings_page_specific_setting')}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {textField(
                                                'user_settings_refresh_rate',
                                                refreshRate,
                                                refreshRateHandler,
                                                register,
                                                errors,
                                                'refreshRate',
                                                translate
                                            )}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                handleClose()
                            }}
                            color="primary"
                        >
                            {translate('manage_switch_dialog_cancel')}
                        </Button>
                        <Button type="submit" color="primary">
                            {translate('add_user_confirm_save')}
                        </Button>
                    </DialogActions>
                </form>
            </Paper>
        </Dialog>
    )
}

UserSettingsDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    onAlertSwitched: PropTypes.func,
    pageTitle: PropTypes.string,
    resetInterval: PropTypes.func,
    resetIntervalTaskDetail: PropTypes.func,
    store: PropTypes.object,
}

export default withStore(UserSettingsDialog)
