import React from 'react'
import { object } from 'prop-types'
import { Translate } from 'react-localize-redux'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import styles from './styles'

class ReloadModal extends React.Component {
    static propTypes = {
        classes: object.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
        }
    }

    componentDidMount = () => {
        window.addEventListener('newContentAvailable', () => {
            this.setState({
                visible: true,
            })
        })
    }

    onClick = () => this.setState({ visible: false }, () => window.location.reload(window.location.href))

    onClose = (event, reason) => {
        console.log(reason)
        if (reason === 'clickaway') {
            return
        }

        this.setState({ visible: false })
    }

    render() {
        if (!this.state.visible) {
            return null
        }

        const { classes } = this.props

        return (
            <Snackbar
                data-testid="snackbar"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.visible}
                autoHideDuration={6000}
                onClose={this.onClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={
                    <div className={classes.text}>
                        <Translate id="new_version_label_new_version" />
                    </div>
                }
                action={[
                    <Button data-testid="snackbar_reload" key="undo" color="secondary" size="small" onClick={this.onClick}>
                        <Translate id="new_version_label_action" />
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        data-testid="snackbar_close"
                        className={classes.close}
                        onClick={this.onClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        )
    }
}

export default withStyles(styles)(ReloadModal)
