export default theme => ({
    drawerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
        '@media (max-width: 577px)': {
            width: '85vw',
        },
    },
    buttonOnBottom: {
        position: 'absolute',
        // right: 0,
        bottom: theme.spacing(2),
    },
    // readList: {
    //     maxHeight: `calc(100vh - ${theme.readList + 116}px)`,
    //     overflowY: 'auto',
    //     marginBottom: theme.spacing(2),
    // },
    // profileInfo: {
    //     marginTop: theme.spacing(2),
    // },
    // profileInfoTitle: {
    //     fontWeight: 'bold',
    // },
    // topSpacingTitle: {
    //     marginTop: theme.spacing(3),
    // },
})
