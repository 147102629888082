import React from 'react'
import { Translate } from 'react-localize-redux'
import classNames from 'classnames'

import TableCell from '@material-ui/core/TableCell'

/* eslint-disable */
export default (headerProps, { classes, headerHeight }) => (
    <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }}
    >
        <Translate id={headerProps.label} />
    </TableCell>
)
