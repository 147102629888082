import React from 'react'
import { object, bool } from 'prop-types'
import { compose } from 'recompose'
import { withStore, withCall, enums, util } from 'react-ion-store'
import { Route, Switch, withRouter } from 'react-router-dom'
import { Translate, withLocalize } from 'react-localize-redux'
import { Typography } from '@material-ui/core'

import checkWriteAccess from 'utils/checkWriteAccess'
import Loading from 'components/Loading'
import PerformanceDetailView from './PerformanceDetailView'
import PerformanceTaskDetailView from './PerformanceTaskDetailView'
import PerformanceTaskDetailEdit from './PerformanceTaskDetailEdit'
import AddNewTask from '../AddNewTask'
import Semes from '../Semes'
import NoRights from '../../../NoRights'

import { isIdfSameAsLoggedInUser } from 'utils/isAgendaAllowed'
let idf
const Failed = () => (
    <div style={{ textAlign: 'center', marginTop: '32px' }}>
        <Typography variant="h6">
            <Translate id="performance_detail_failed_msg_part_one"></Translate>[{idf}]
            <Translate id="performance_detail_failed_msg_part_two"></Translate>
        </Typography>
    </div>
)

const {
    CallStatus: { LOADING, FAILED },
} = enums

const progressHandlers = {
    [LOADING]: Loading,
    [FAILED]: Failed,
}

const callKey = 'performanceDetail'

class PerformanceDetail extends React.Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isAbolishOpen: false,
            noteIsOpen: false,
            checkedTasks: [],
            allowDeleteMultipleTasks: false,
            isMultiDialogOpen: false,
            showAbolishTitle: false,
        }
    }

    static propTypes = {
        match: object.isRequired,
        store: object.isRequired,
        refreshRate: bool,
    }

    componentDidMount() {
        const performanceDetail = this.props.store.get(callKey)
        document.title = `${performanceDetail.depot}-${performanceDetail.performanceNumber} ${
            performanceDetail.name ? performanceDetail.name : 'notAssigned'
        }`
    }

    // componentWillUnmount = () => {
    //     this.props.store.call({ performanceDetail: util.CreateStoreObject(undefined) })
    // }

    handleClose = () => {
        this.setState({ isOpen: false })
    }
    handleOpen = () => {
        this.setState({ isOpen: true })
    }

    handleNoteClose = () => {
        this.setState({ noteIsOpen: false })
    }

    handleNoteOpen = () => {
        this.setState({ noteIsOpen: true })
    }

    handleAbolishClose = () => {
        this.setState({ isAbolishOpen: false })
    }

    handleAbolishOpen = () => {
        this.setState({ isAbolishOpen: true })
    }
    deleteMultipleTasks = () => {
        this.setState({ isMultiDialogOpen: true, showAbolishTitle: false })
    }
    AbolishMultipleTasks = () => {
        this.setState({ isMultiDialogOpen: true, showAbolishTitle: true })
    }
    handleMultiClose = () => {
        this.setState({ isMultiDialogOpen: false, showAbolishTitle: false })
    }
    refreshAfterBulkOperation = () => {
        this.props.store.call({ performanceDetail: util.CreateStoreObject(undefined) })
    }

    handleCheckTasks = id => {
        const { checkedTasks } = this.state

        if (checkedTasks.indexOf(id) > -1) {
            const removeFromList = checkedTasks.filter(item => item !== id)
            if (removeFromList.length > 0) this.setState({ checkedTasks: removeFromList, allowDeleteMultipleTasks: true })
            else this.setState({ checkedTasks: removeFromList, allowDeleteMultipleTasks: false })
            return
        }

        const list = [...checkedTasks, id]
        if (list.length > 0) this.setState({ checkedTasks: list, allowDeleteMultipleTasks: true })
        else this.setState({ checkedTasks: list, allowDeleteMultipleTasks: false })
    }

    render() {
        const { store, match } = this.props
        const { isOpen, isAbolishOpen, noteIsOpen } = this.state
        let showDetails = false
        let performanceDetail = store.get(callKey)
        showDetails = isIdfSameAsLoggedInUser(performanceDetail.idfNumber, store)
        if (showDetails || checkWriteAccess(this.props, 'view', 'performances.functionality.management.performanceDetail')) {
            return (
                <React.Fragment>
                    <Switch>
                        <Route
                            exact
                            path={`${match.path}`}
                            render={() => (
                                <PerformanceDetailView
                                    performanceDetail={store.get(callKey)}
                                    storage={store}
                                    isOpen={isOpen}
                                    handleClose={this.handleClose}
                                    handleOpen={this.handleOpen}
                                    noteIsOpen={noteIsOpen}
                                    handleNoteOpen={this.handleNoteOpen}
                                    handleNoteClose={this.handleNoteClose}
                                    deleteMultipleTasks={this.deleteMultipleTasks}
                                    AbolishMultipleTasks={this.AbolishMultipleTasks}
                                    handleCheckTasks={this.handleCheckTasks}
                                    checkedTasks={this.state.checkedTasks}
                                    allowDeleteMultipleTasks={this.state.allowDeleteMultipleTasks}
                                    handleMultiClose={this.handleMultiClose}
                                    isMultiDialogOpen={this.state.isMultiDialogOpen}
                                    showAbolishTitle={this.state.showAbolishTitle}
                                    refreshAfterBulkOperation={this.refreshAfterBulkOperation}
                                    refreshRate={this.props.refreshRate}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={`${match.path}/add/task`}
                            render={() => <AddNewTask performanceDetail={store.get(callKey)} />}
                        />
                        <Route
                            exact
                            path={`${match.path}/task/:taskIndex`}
                            render={() => (
                                <PerformanceTaskDetailView
                                    performanceDetail={store.get(callKey)}
                                    storage={store}
                                    isOpen={isOpen}
                                    isAbolishOpen={isAbolishOpen}
                                    handleClose={this.handleClose}
                                    handleOpen={this.handleOpen}
                                    handleAbolishOpen={this.handleAbolishOpen}
                                    handleAbolishClose={this.handleAbolishClose}
                                    refreshAfterBulkOperation={this.refreshAfterBulkOperation}
                                />
                            )}
                        />
                        <Route
                            path={`${match.path}/task/:taskIndex/edit`}
                            render={() => (
                                <PerformanceTaskDetailEdit performanceDetail={store.get(callKey)} storage={store} />
                            )}
                        />
                        <Route path={`${match.path}/task/:taskIndex/semes`} render={() => <Semes />} />
                        <Route path={`${match.path}/semes`} render={() => <Semes />} />
                    </Switch>
                </React.Fragment>
            )
        } else {
            return <NoRights />
        }
    }
}

export default compose(withStore, withLocalize, withRouter, Component => props => {
    idf = props.match.params.idfNumber
    return withCall(callKey, {
        config: {
            method: 'get',
            url: `/performance/${props.match.params.performanceId}/detail${
                props.match.params.idfNumber ? `?idfNumber=${props.match.params.idfNumber}` : ''
            }`,
        },
        progressHandlers: progressHandlers,
        reducer: ({ data }) => data,
    })(Component)(props)
})(PerformanceDetail)
