//CHECKBOX REDUCER --------------------------- /
export const ACTIONS_CHECKBOX = {
    NMBS: 'NMBS',
    UICC: 'UICC',
    LIT: 'LIT',
}

export const INITIAL_STATE_CHECKBOX = {
    nmbs: true,
    lit: false,
    uicc: false,
}

export const REDUCER_CHECKBOX = (state, action) => {
    switch (action.type) {
        case ACTIONS_CHECKBOX.NMBS:
            return { nmbs: action.payload, lit: false, uicc: false }
        case ACTIONS_CHECKBOX.UICC:
            return { nmbs: false, lit: false, uicc: action.payload }
        case ACTIONS_CHECKBOX.LIT:
            return { nmbs: false, lit: action.payload, uicc: false }
        default:
            return state
    }
}
// ------------------------------------------- /

//DRAWER REDUCER ----------------------------- /
export const ACTIONS_DRAWER = {
    ADD_COMP: 'ADD_COMP',
    DRAWER_CLOSE: 'DRAWER_CLOSE',
    EDIT_MUE: 'EDIT_MUE',
    DRAWER: 'DRAWER',
    ADD_DRAWER: 'ADD_DRAWER',
    VEHICLE_DRAWER: 'VEHICLE_DRAWER',
}

export const INITIAL_DRAWER_STATE = {
    drawerForAddVehicle: false,
    drawer: false,
    drawerForVehicleInfo: false,
}

export const REDUCER_DRAWER = (state, action) => {
    switch (action.type) {
        case ACTIONS_DRAWER.ADD_COMP:
            return { ...state, drawerForAddVehicle: true, drawer: true }
        case ACTIONS_DRAWER.DRAWER_CLOSE:
            return { ...state, drawerForAddVehicle: false, drawer: false }
        case ACTIONS_DRAWER.EDIT_MUE:
            return { ...state, drawerForAddVehicle: false, drawer: true }
        case ACTIONS_DRAWER.DRAWER:
            return { ...state, drawer: action.payload }
        case ACTIONS_DRAWER.VEHICLE_DRAWER:
            return { ...state, drawerForVehicleInfo: action.payload }
        default:
            return state
    }
}
