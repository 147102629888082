import React, { Component } from 'react'

import { withStore } from 'react-ion-store'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import DefaultSettings from 'components/DefaultSettings'
import updateScreenSettings from 'utils/updateScreenSettings'
import PropTypes from 'prop-types'

const getStateFromLocal = identifier => {
    let storedSettings = localStorage.getItem('userSettings')
        ? JSON.parse(localStorage.getItem('userSettings')).filter(setting => setting.pageReference === identifier)[0]
        : false
    return storedSettings?.isWarningSound
}
const updateLocalStorage = (state, store, identifier) => {
    let storedSettings = localStorage.getItem('userSettings')
        ? JSON.parse(localStorage.getItem('userSettings'))
        : DefaultSettings

    let index = storedSettings.findIndex(obj => obj.pageReference === identifier)
    storedSettings[index].isWarningSound = state
    store.set({ screenSettings: storedSettings })
    localStorage.setItem('userSettings', JSON.stringify(storedSettings))
    updateScreenSettings(storedSettings)
    store.set({ screenSettings: storedSettings })
}

class MusicController extends Component {
    updateIcon = (state, store, identifier) => {
        this.setState({ flag: state }, () => updateLocalStorage(state, store, identifier))
    }

    render() {
        const { store, identifier } = this.props

        let flag = getStateFromLocal(identifier)
        if (flag) {
            return (
                <VolumeUpIcon
                    style={{ fill: 'white', cursor: 'pointer' }}
                    onClick={() => {
                        this.updateIcon(false, store, identifier)
                    }}
                />
            )
        }
        return (
            <VolumeOffIcon
                style={{ fill: 'white', cursor: 'pointer' }}
                onClick={() => {
                    this.updateIcon(true, store, identifier)
                }}
            />
        )
    }
}
MusicController.propTypes = {
    store: PropTypes.object,
    identifier: PropTypes.string,
    theme: PropTypes.string,
}

export default withStore(MusicController)
