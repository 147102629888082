import React, { useContext, useState } from 'react'
import { object, string, number, func, bool, array } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core'
import { DateTime } from 'luxon'
import classNames from 'classnames'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from 'components/AppPaper'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import IconButton from 'components/IconButton'
import NotificationsIcon from '@material-ui/icons/Notifications'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'

import dateParser from 'utils/dateParser'
import Button from '@material-ui/core/Button'
import BulkDeleteAndAbolishDialog from '../Dialogs/BulkDeleteAndAbolishDialog'
import styles from './styles'
import { ThemeContext } from 'theme/ThemeProvider'
// eslint-disable-next-line max-len
import PerformanceDriveDetailsDrawer from 'modules/Performances/views/PerformanceOverview/components/PerformanceDriveDetailsDrawer'
// eslint-disable-next-line max-len
import PerformanceNotificationDrawer from 'modules/Performances/views/PerformanceOverview/components/PerformanceNotificationDrawer'

const PerformanceInfo = ({
    classes,
    depot,
    duration,
    performanceDate,
    performanceNumber,
    plannedEndTime,
    plannedStartTime,
    translate,
    name,
    allowDeleteMultipleTasks,
    deleteMultipleTasks,
    AbolishMultipleTasks,
    isMultiDialogOpen,
    handleMultiClose,
    showAbolishTitle,
    checkedTasks,
    performanceId,
    idfNumber,
    refreshAfterBulkOperation,
    lastSyncTime,
    tasks,
    storage,
    gsmNumber,
    performanceSelectedTime,
    performanceCodeAbbreviation,
    activeLanguage,
}) => {
    const { themeName } = useContext(ThemeContext)
    const [openPerformanceDriveDetailsDrawer, setOpenPerformanceDriveDetailsDrawer] = useState(false)
    const [performanceDriveDetailsDrawerData, setPerformanceDriveDetailsDrawerData] = useState(null)
    const [openPerformanceNotificationDrawer, setOpenPerformanceNotificationDrawer] = useState(false)
    const [performanceNotificationDetailsDrawerData, setPerformanceNotificationDetailsDrawerData] = useState(null)
    let params = { idfNumber: idfNumber, performanceId: performanceId }
    let closePerformanceDriveDetailsDrawer = () => {
        setPerformanceDriveDetailsDrawerData(null)
        setOpenPerformanceDriveDetailsDrawer(false)
    }
    let closePerformanceNotificationDrawer = () => {
        setOpenPerformanceNotificationDrawer(false)
        setPerformanceNotificationDetailsDrawerData(null)
    }
    let setParentstate = (type, data) => {
        if (type === 'performanceDriveDetailsDrawerData') setPerformanceDriveDetailsDrawerData(data)
        else setPerformanceNotificationDetailsDrawerData(data)
    }
    return (
        <Paper>
            <Grid container spacing={0}>
                <Grid item md={1} xs={2} className={classNames(classes.infoContainerForMob, classes.spacing)}>
                    <Typography variant="body1">
                        <Translate id="performance_info_label_from" />
                    </Typography>
                    <Typography variant="h6">{plannedStartTime.substr(0, 5)}</Typography>
                </Grid>
                <Grid item md={1} xs={2} className={classNames(classes.infoContainerForMob, classes.spacing)}>
                    <Typography variant="body1">
                        <Translate id="performance_info_label_till" />
                    </Typography>
                    <Typography variant="h6">{plannedEndTime.substr(0, 5)}</Typography>
                </Grid>
                <Grid item md={2} xs={4} className={classNames(classes.infoContainerForMob, classes.spacing)}>
                    <Typography variant="body1">
                        <Translate id="performance_info_label_duration" />
                    </Typography>
                    <Typography variant="h6">{duration.substr(0, 5)}</Typography>
                </Grid>
                <Grid item md={2} xs={4} className={classNames(classes.infoContainer, classes.spacing)}>
                    <Typography variant="body1">
                        <Translate id="performance_info_label_date" />
                    </Typography>
                    <Typography variant="h6">{dateParser(performanceDate * 1000)}</Typography>
                </Grid>
                <Grid item md={2} xs={4} className={classNames(classes.infoContainer, classes.spacing)}>
                    <Typography variant="body1">
                        <Translate id="performance_info_label_depot" />
                    </Typography>
                    <Typography variant="h6">{depot}</Typography>
                </Grid>
                <Grid item md={2} xs={4} className={classNames(classes.infoContainer, classes.spacing)}>
                    <Typography variant="body1">
                        <Translate id="performance_info_label_number" />
                    </Typography>
                    <Typography variant="h6">{performanceNumber}</Typography>
                </Grid>
                <Grid item md={2} xs={4} className={classes.infoContainer}>
                    <Typography variant="body1">
                        <Translate id="performance_info_label_performance_code" />
                    </Typography>
                    <Typography variant="h6">
                        {activeLanguage.code === 'fr'
                            ? performanceCodeAbbreviation[1]?.translation
                            : performanceCodeAbbreviation[0]?.translation}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item md={4} sm={4} xs={12} className={classes.driverActivity}>
                    <Typography variant="body1">
                        {name || translate('name_not_found_unassigned')}
                        {gsmNumber ? (
                            <>
                                <a
                                    style={{ color: `${themeName === 'dark' ? 'white' : 'black'}`, textDecoration: 'none' }}
                                    href={`tel:${gsmNumber}`}
                                >
                                    {`(Tel.: ${gsmNumber})`}
                                </a>
                                <FileCopyOutlinedIcon
                                    onClick={() => {
                                        navigator.clipboard.writeText(gsmNumber)
                                    }}
                                    style={{ marginLeft: '10px', height: '20px', cursor: 'pointer' }}
                                />
                            </>
                        ) : (
                            ''
                        )}
                    </Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={12} className={classes.driverActivity}>
                    <Typography variant="body1">
                        {lastSyncTime !== null
                            ? `${translate('performace_detail_activity')}${DateTime.fromISO(lastSyncTime, {
                                  setZone: true,
                              }).toFormat('dd/MM/yyyy HH:mm')}`
                            : translate('performace_detail_no_activity')}
                    </Typography>
                </Grid>
                <Grid item md={2} sm={2} xs={12} className={classes.driverActivity}>
                    <img
                        alt="DriveModeIcon"
                        style={{ width: '24px', height: '24px' }}
                        src={'/img/ic_drive_mode_indication.png'}
                        onClick={() => {
                            setOpenPerformanceDriveDetailsDrawer(true)
                        }}
                    />
                    <NotificationsIcon
                        className={classes.notificationIcon}
                        onClick={() => {
                            setOpenPerformanceNotificationDrawer(true)
                            // e.stopPropagation()
                        }}
                    />
                </Grid>
                <Grid item md={1} sm={1} className={classes.buttonContainer}>
                    <Button
                        title="SEMES"
                        onClick={() => {
                            window.open(`${window.location.href}/semes`)
                        }}
                        variant="contained"
                        color="primary"
                    >
                        SEMES
                    </Button>
                </Grid>
                <Grid item md={1} sm={1} className={classes.buttonContainer}>
                    <IconButton
                        className={classes.button}
                        icon="note_add"
                        title={'M510'}
                        onClick={() => {
                            window.open(`/performancereport/${performanceNumber}/${idfNumber}/${depot}/${performanceDate}`)
                        }}
                        disabled={
                            performanceSelectedTime === null ||
                            checkFunctionalityDisabled(
                                storage,
                                'performanceReport',
                                'agenda.functionality.management.agenda'
                            )
                        }
                    />

                    {allowDeleteMultipleTasks && (
                        <div>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={deleteMultipleTasks}
                            >
                                {translate('add_user_delete')}
                            </Button>
                            <Button
                                disabled={idfNumber === null}
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={AbolishMultipleTasks}
                            >
                                {translate('add_user_abolish')}
                            </Button>
                        </div>
                    )}
                </Grid>
            </Grid>

            <BulkDeleteAndAbolishDialog
                tasks={tasks}
                open={isMultiDialogOpen}
                handleClose={handleMultiClose}
                showAbolishTitle={showAbolishTitle}
                checkedTasks={checkedTasks}
                performanceId={performanceId}
                idfNumber={idfNumber}
                refreshAfterBulkOperation={refreshAfterBulkOperation}
            />
            <PerformanceDriveDetailsDrawer
                close={closePerformanceDriveDetailsDrawer}
                performanceDriveDetailsDrawerOpen={openPerformanceDriveDetailsDrawer}
                performanceDriveDetailsDrawerData={performanceDriveDetailsDrawerData}
                performanceDriveDetailsDrawerParams={params}
                setParentstate={setParentstate}
            />
            <PerformanceNotificationDrawer
                close={closePerformanceNotificationDrawer}
                performanceNotificationDrawerOpen={openPerformanceNotificationDrawer}
                performanceNotificationDrawerData={performanceNotificationDetailsDrawerData}
                performanceNotificationDrawerParams={params}
                setParentstate={setParentstate}
            />
        </Paper>
    )
}

PerformanceInfo.propTypes = {
    classes: object.isRequired,
    depot: string.isRequired,
    duration: string.isRequired,
    performanceDate: number.isRequired,
    performanceNumber: string.isRequired,
    plannedEndTime: string.isRequired,
    plannedStartTime: string.isRequired,
    name: string,
    translate: func,
    allowDeleteMultipleTasks: bool,
    isMultiDialogOpen: bool,
    showAbolishTitle: bool,
    deleteMultipleTasks: func,
    AbolishMultipleTasks: func,
    handleMultiClose: func,
    checkedTasks: array,
    refreshAfterBulkOperation: func,
    performanceId: string,
    idfNumber: string,
    lastSyncTime: string,
    tasks: array,
    storage: object,
    gsmNumber: string,
    performanceSelectedTime: number,
    performanceCodeAbbreviation: array,
    activeLanguage: object,
}

export default compose(withLocalize, withStyles(styles))(PerformanceInfo)
