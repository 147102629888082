import React, { PureComponent } from 'react'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'
import { array, func, bool, string } from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ErrorMessage from 'components/ErrorMessage'
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

import { Paper, Typography } from '@material-ui/core'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export class BulkDeleteAndAbolishDialog extends PureComponent {
    static propTypes = {
        performanceDetailPage: bool,
        open: bool.isRequired,
        handleClose: func.isRequired,
        performanceId: string,
        idfNumber: string,
        translate: func,
        checkedTasks: array,
        refreshAfterBulkOperation: func,
        showAbolishTitle: bool,
        tasks: array,
    }

    state = { loading: false, error: false, taskNumber: '' }

    deleteTask = (performanceId, idfNumber) => {
        this.setState({ loading: true })

        this.doDeleteTaskCall(performanceId, idfNumber)
    }

    doDeleteTaskCall = async (performanceId, idfNumber) => {
        const { url, subscriptionKey } = requestData
        const { checkedTasks, refreshAfterBulkOperation, translate } = this.props

        let fullUrl = `${url}/performance/${performanceId}/bulkDelete`

        try {
            const response = await axios.post(fullUrl, {
                IdfNumber: idfNumber,
                TaskIds: checkedTasks,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            if (response.status === 200) {
                this.setState({ error: false, loading: false })
                refreshAfterBulkOperation()
            }
        } catch (e) {
            console.error(e) // eslint-disable-line
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({ error: translate('roles_error'), loading: false })
                    return
                }
            }
            this.setState({ error: translate('performance_delete_task_dialog_error'), loading: false })
        }
    }
    abolishTask = (performanceId, idfNumber) => {
        this.setState({ loading: true })

        this.doAbolishTaskCall(performanceId, idfNumber)
    }

    doAbolishTaskCall = async (performanceId, idfNumber) => {
        const { url, subscriptionKey } = requestData
        const { checkedTasks, refreshAfterBulkOperation, tasks, translate } = this.props
        let abolishTaskList = []
        tasks.forEach(task => {
            checkedTasks.forEach(taskId => {
                if (
                    task.id === taskId &&
                    !task.isAbolished &&
                    task.code !== 'FREETEXT_P' &&
                    task.code !== 'FREETEXT_M' &&
                    task.code !== 'OPMRMQ' &&
                    task.code !== 'OPMREM'
                ) {
                    abolishTaskList.push(taskId)
                }
            })
        })

        if (abolishTaskList.length > 0) {
            let fullUrl = `${url}/performance/${performanceId}/bulkAbolish`
            try {
                const response = await axios.post(fullUrl, {
                    IdfNumber: idfNumber,
                    TaskIds: abolishTaskList,
                    headers: {
                        'Content-Type': 'application/json',
                        'Ocp-Apim-Subscription-Key': subscriptionKey,
                    },
                })

                if (response.status === 200) {
                    this.setState({ error: false, loading: false })
                    refreshAfterBulkOperation()
                }
            } catch (e) {
                console.error(e) // eslint-disable-line
                if (e.response.status === 400) {
                    if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                        this.setState({ error: translate('roles_error'), loading: false })
                        return
                    }
                }
                this.setState({ error: translate('performance_delete_task_dialog_error'), loading: false })
            }
        } else {
            this.handleClose()
        }
    }

    handleClose = () => {
        this.setState({ error: false, loading: false })
        this.props.handleClose()
    }

    render() {
        const { open, performanceId, idfNumber, translate, showAbolishTitle, checkedTasks } = this.props

        const { error, loading } = this.state
        if (showAbolishTitle) {
            return (
                <Dialog open={open} onClose={() => this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{translate('add_user_abolish')}</DialogTitle>
                    <Paper>
                        <DialogContent style={{ minWidth: '600px' }}>
                            <DialogContentText>{translate('performance_abolish_task_dialog_title')}</DialogContentText>
                            <Typography variant="subtitle1">
                                {translate('performance_abolish_task_dialog_confirm_text')}
                            </Typography>
                            <Typography variant="subtitle1">
                                {translate('selected_msg_bulk_delete_dialog') + ` ${checkedTasks.length}`}
                            </Typography>
                            {error && <ErrorMessage spacing={false} error={error} />}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleClose()} color="primary">
                                {translate('add_user_confirm_cancel')}
                            </Button>
                            <Button
                                onClick={() => {
                                    this.abolishTask(performanceId, idfNumber)
                                }}
                                color="primary"
                                disabled={loading || error}
                            >
                                {translate('add_user_abolish')}
                            </Button>
                        </DialogActions>
                    </Paper>
                </Dialog>
            )
        } else {
            return (
                <Dialog open={open} onClose={() => this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{translate('add_user_delete')}</DialogTitle>
                    <Paper>
                        <DialogContent style={{ minWidth: '600px' }}>
                            <DialogContentText>{translate('performance_delete_task_dialog_title')}</DialogContentText>
                            <Typography variant="subtitle1">
                                {translate('performance_delete_task_dialog_confirm_text')}
                            </Typography>
                            <Typography variant="subtitle1">
                                {translate('selected_msg_bulk_delete_dialog') + ` ${checkedTasks.length}`}
                            </Typography>
                            {error && <ErrorMessage spacing={false} error={error} />}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleClose()} color="primary">
                                {translate('add_user_confirm_cancel')}
                            </Button>
                            <Button
                                onClick={() => {
                                    this.deleteTask(performanceId, idfNumber)
                                }}
                                color="primary"
                                disabled={loading}
                            >
                                {translate('add_user_delete')}
                            </Button>
                        </DialogActions>
                    </Paper>
                </Dialog>
            )
        }
    }
}

export default compose(withStyles(styles), withLocalize)(BulkDeleteAndAbolishDialog)
