import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import SelectInput from 'components/SelectInput'
import { Typography } from '@material-ui/core'

const PerformanceSelect = props => {
    PerformanceSelect.propTypes = {
        depot: PropTypes.string,
        loadPerformanceNumbers: PropTypes.func,
        depots: PropTypes.object,
        handleEvent: PropTypes.func,
        performanceNumbersData: PropTypes.array,
        selectedPerformanceNumber: PropTypes.string,
        loadingPerformance: PropTypes.bool,
        classes: PropTypes.object,
        translate: PropTypes.func,
    }

    useEffect(() => {
        const { depot, loadPerformanceNumbers, depots } = props
        const depotName = depots.depots.find(dep => dep.depotId === parseInt(depot))

        if (depot !== ' ' && depotName) loadPerformanceNumbers(depotName.symbolicName)
    }, [props.depot])

    const { handleEvent, performanceNumbersData, selectedPerformanceNumber, loadingPerformance, classes, translate } = props
    const perItems = performanceNumbersData.map(per => ({ id: per.performanceId, text: per.performanceNumber }))

    return (
        <React.Fragment>
            {loadingPerformance && <Typography variant="body1">Loading...</Typography>}
            {performanceNumbersData.length === 0 && !loadingPerformance && (
                <Typography data-testid="performance_not_found" variant="body1">
                    {translate('performance_not_found')}
                </Typography>
            )}
            {performanceNumbersData.length > 0 && (
                <div data-testid="performanceNumber" style={{ display: 'inline-block' }}>
                    <SelectInput
                        sorted
                        name="performanceNumber"
                        classes={classes}
                        items={[{ id: ' ', text: 'Performance' }, ...perItems]}
                        value={selectedPerformanceNumber}
                        onChange={handleEvent('selectedPerformanceNumber')}
                    />
                </div>
            )}
        </React.Fragment>
    )
}

export default PerformanceSelect
