import axios from 'axios'

export default async (data, isEdit = false) => {
    let fullUrl = `referencedata/CosmosDbPerformancesTestCase`
    if (isEdit) fullUrl = fullUrl + `/update`
    else fullUrl = fullUrl + '/add'
    try {
        return await axios.post(fullUrl, data)
    } catch (e) {
        console.error(e) // eslint-disable-line
        return e.response
    }
}
