import React from 'react'
import Component from './ptcarPlatorms.component'
import StoreProvider from './ptcarPlatforms.store'

const Platforms = () => {
    return (
        <StoreProvider>
            <Component />
        </StoreProvider>
    )
}

export default Platforms
