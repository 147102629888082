import React, { useContext } from 'react'
import { object } from 'prop-types'
import { Typography, Button } from '@material-ui/core'
import { TranslationContext } from 'context/translation'
import { PtcarPlatformsContext } from '../../ptcarPlatforms.store'

const PtcarPlatformHeader = props => {
    const { classes } = props
    const { translate } = useContext(TranslationContext)
    const { STATE, METHODS } = useContext(PtcarPlatformsContext)

    return (
        <div className={classes.parentDiv} style={{ display: 'flex' }}>
            <div style={{ display: 'flex' }}>
                <Typography style={{ marginRight: 10 }}>{translate('platform_filter')}</Typography>
                <input
                    type="text"
                    className={classes.customInput}
                    placeholder={translate('search')}
                    value={STATE.platformFilterValue}
                    onChange={e => METHODS.handlePlatformFilter(e)}
                />
            </div>
            <Typography style={{ marginLeft: 10 }}>{translate('platform_page')}</Typography>
            <div>
                <Button variant="contained" color="primary" onClick={() => METHODS.handleAddClick()}>
                    {translate('module_add_performance')}
                </Button>
            </div>
        </div>
    )
}

PtcarPlatformHeader.propTypes = {
    classes: object.isRequired,
}

export default PtcarPlatformHeader
