import React, { useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { Grid, TextField } from '@material-ui/core'
import IconButton from 'components/IconButton'
import { useForm } from 'react-hook-form'

const PtcarTypesDrawer = props => {
    const { isOpen, onClose, classes, translate, data, openDrawerForEditing, handleAddOrEdit } = props
    const [descriptionNlShort, setDescNlShort] = useState(data?.ShortNameNl)
    const [descriptionNlLong, setDescNlLong] = useState(data?.LongNameNl)
    const [descriptionFrShort, setDescFrShort] = useState(data?.ShortNameFr)
    const [descriptionFrLong, setDescFrlong] = useState(data?.LongNameFr)
    const textField = (label, value, callback, name) => {
        return (
            <TextField
                margin="dense"
                inputRef={register !== undefined ? register({ required: true }) : null}
                error={errors && !!errors[`${name}`]}
                id={name}
                name={name}
                label={label}
                type="text"
                fullWidth
                defaultValue={value}
                onChange={e => {
                    callback(e.target.value)
                }}
            />
        )
    }
    const submitTheForm = formData => {
        if (openDrawerForEditing) formData.id = data?.Id
        handleAddOrEdit(formData, openDrawerForEditing)
    }
    const { register, handleSubmit, errors } = useForm()
    return (
        <Drawer open={isOpen} anchor="right" onClose={onClose}>
            <div className={classes.drawerRoot}>
                <Typography variant="h6">
                    {openDrawerForEditing
                        ? translate('manage_ptcar_types_drawer_title_edit')
                        : translate('manage_ptcar_types_drawer_title_add')}
                </Typography>
                <form onSubmit={handleSubmit(submitTheForm)} noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {textField('Description Nl short', descriptionNlShort, setDescNlShort, 'ShortNameNl')}
                        </Grid>
                        <Grid item xs={12}>
                            {textField('Description Fr short', descriptionFrShort, setDescFrShort, 'ShortNameFr')}
                        </Grid>
                        <Grid item xs={12}>
                            {textField('Description Nl long', descriptionNlLong, setDescNlLong, 'LongNameNl')}
                        </Grid>
                        <Grid item xs={12}>
                            {textField('Description Fr long', descriptionFrLong, setDescFrlong, 'LongNameFr')}
                        </Grid>
                    </Grid>
                    {openDrawerForEditing ? (
                        <IconButton icon="edit" type="submit" title={translate('management_button_edit')}></IconButton>
                    ) : (
                        <IconButton icon="save" type="submit" title={translate('button_add')}></IconButton>
                    )}
                </form>
            </div>
        </Drawer>
    )
}
PtcarTypesDrawer.propTypes = {
    onClose: PropTypes.func,
    translate: PropTypes.func,
    handleAddOrEdit: PropTypes.func,
    isOpen: PropTypes.bool,
    addEditError: PropTypes.string,
    addEditLoading: PropTypes.bool,
    classes: PropTypes.object,
    data: PropTypes.object,
    openDrawerForEditing: PropTypes.bool,
}
export default PtcarTypesDrawer
