import React from 'react'
import { compose } from 'recompose'
import { withStore, withCall, enums } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'
import { object } from 'prop-types'
import ReadListView, { Failed } from './ReadListView'
import Loading from 'components/Loading'

const {
    CallStatus: { LOADING, FAILED },
} = enums
const ProgressHandlers = {
    [LOADING]: () => <Loading />,
    [FAILED]: () => <Failed />,
}

const callKey = 'documentsReadActions'

class ReadList extends React.Component {
    static propTypes = {
        store: object,
    }
    render() {
        const data = this.props.store.get(callKey)
        return <ReadListView {...this.props} data={data} />
    }
}

const withFilledCall = Component => props => {
    return withCall(callKey, {
        config: {
            method: 'get',
            url: `/elibrary/${props.idf}/getopenedallactions`,
        },
        progressHandlers: ProgressHandlers,
        reducer: response => {
            if (response.status === 200) return response.data.sort((a, b) => b['timestamp'] - a['timestamp'])

            return []
        },
    })(Component)(props)
}

export default compose(withStore, withFilledCall, withLocalize)(ReadList)
