import React, { useContext } from 'react'
import { object } from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import PtcarPlatformForm from './PtcarPlatformForm'
import styles from './styles'
import { withStyles } from '@material-ui/styles'
import { PtcarPlatformsContext } from '../../ptcarPlatforms.store'
import { TranslationContext } from 'context/translation'
const PlatformDrawer = props => {
    const { classes } = props

    const { STATE, METHODS } = useContext(PtcarPlatformsContext)
    const { translate } = useContext(TranslationContext)

    return (
        <Drawer open={STATE.openDrawer} anchor="right" onClose={METHODS.onDrawerClose}>
            <div className={classes.drawerRoot}>
                <Typography variant="h6">
                    {STATE.editDrawer ? translate('ptcarplatform_edit_platform') : translate('ptcarplatform_add_platform')}
                </Typography>
                <PtcarPlatformForm error={STATE.addEditError} classes={classes} />
            </div>
        </Drawer>
    )
}

PlatformDrawer.propTypes = {
    classes: object.isRequired,
}

export default withStyles(styles)(PlatformDrawer)
