import React from 'react'
import { func, object, string } from 'prop-types'

const CommontFilter = props => {
    CommontFilter.propTypes = {
        classes: object.isRequired,
        handleFilterSearch: func,
        filterString: string,
    }
    const { classes, handleFilterSearch, filterString } = props

    return (
        <div className={classes.parentDiv} style={{ display: 'flex' }}>
            <input
                type="text"
                className={classes.customInput}
                placeholder={'enter material sub type '}
                value={filterString}
                onChange={e => handleFilterSearch(e)}
            />
        </div>
    )
}
export default CommontFilter
