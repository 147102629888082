import React from 'react'
import { object, func, number } from 'prop-types'
import { withLocalize, Translate } from 'react-localize-redux'
import { compose } from 'recompose'
import { withStore, util, withStatus, enums, withClear } from 'react-ion-store'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import PersonSearcher from 'components/PersonSearcher'
import PrimaryButton from 'components/PrimaryButton'

import IconButton from 'components/IconButton'
import ErrorMessage from 'components/ErrorMessage'
import AddStudentView from './AddStudentView'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import AssignDriverCall from '../../AssignDriverDialog/AssignDriverCall'
import SignInOutCall from '../../SignInOutDialog/SignInOutCall'
import { ReplaceDialog } from '../../Dialogs/ReplaceDialog'
import { DeselectedPerformanceDialog } from '../../Dialogs/DeselectedPerformanceDialog'

import unAssignDriverCall from 'utils/unAssignDriverCall'
import getNormalizedMessage from 'utils/getNormalizedMessage'
import assignTeacherService from './assignTeacherService'
import isDepotLeerling from '../isDepotLeerling'

import styles from './styles'
import Loading from 'components/Loading'

const resetState = {
    selectedName: '',
    selectedProfile: undefined,
    allreadyClosed: false,
}

const {
    CallStatus: { LOADING, READY },
} = enums

class NotSignedOnDetailView extends React.PureComponent {
    static propTypes = {
        classes: object.isRequired,
        closeDrawer: func.isRequired,
        performance: object.isRequired,
        __ion_status: object.isRequired,
        store: object.isRequired,
        clearAssignedCall: func,
        handleCheckbox: func,
        printButtonHandler: func,
        translate: func,
        driverRole: number,
    }

    state = {
        ...resetState,
        actionWasSet: false,
        isOpen: false,
        savedDriver: {},
        replace: false,
        unassignDisable: false,
        assignTeacherDisable: false,
        teacherError: '',
        responseData: [],
        isLocked: this.props.performance.isLocked,
        isLockedError: '',
        unassignDriverError: '',
        openDeselectedPerformanceDialog: false,
    }

    setName = selectedProfile =>
        this.setState({
            selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
            selectedProfile,
        })

    clearName = () => this.setState(resetState)

    persistAssignDriver = (idf, id, performanceIdfNumber, replace = false) => {
        this.setState(
            {
                ...resetState,
                actionWasSet: 'assignDriverCall',
                savedDriver: { idf, id, performanceIdfNumber },
                replace: replace,
                allreadyClosed: false,
                teacherError: '',
                unassignDriverError: '',
            },
            () => {
                const assignDriverCall = util.CreateStoreObject({ idf, id, performanceIdfNumber, replace })
                this.props.store.call({ assignDriverCall })
            }
        )
    }
    async assignTeacher(teacherIdf, performanceId, studentIdf) {
        this.setState({ assignTeacherDisable: true, teacherError: '', unassignDriverError: '' })
        let response = await assignTeacherService(teacherIdf, performanceId, studentIdf)
        if (response.status === 200 || response.status === 204) {
            this.props.closeDrawer(true)
        } else {
            this.setState({ teacherError: response.data.Message })
        }
        this.setState({ assignTeacherDisable: false })
    }
    async unAssignDriver(idf, performanceId) {
        const { translate } = this.props
        this.setState({ unassignDisable: true, teacherError: '', unassignDriverError: '' })
        const response = await unAssignDriverCall(idf, performanceId)
        if (response.status === 200 || response.status === 204) {
            this.setState({ unassignDisable: false })
            this.props.closeDrawer(true)
        }
        if (response === 'error') {
            this.setState({ unassignDriverError: translate('roles_error'), unassignDisable: false })
        }
    }

    signInDriver = (idf, id, isPlanned) => () => {
        this.setState({ teacherError: '', actionWasSet: 'signInOutCall', unassignDriverError: '' }, () => {
            const signInOutCall = util.CreateStoreObject({ idf, id, type: 'signIn', isPlanned })
            this.props.store.call({ signInOutCall })
        })
    }

    handleClose = () => {
        this.setState({ isOpen: false, allreadyClosed: true, responseData: [] })
        this.props.clearAssignedCall()
    }

    handleOpen = data => {
        this.setState({ isOpen: true, responseData: data })
    }

    lockUnlock = async (e, id, idf) => {
        const { isLocked } = this.state
        const { handleCheckbox, translate } = this.props

        const doCall = await handleCheckbox(e, id, isLocked, idf)

        if (typeof doCall === 'string' && doCall.indexOf('Action not allowed') !== -1) {
            this.setState({ isLockedError: translate('roles_error') })
            return
        }
        if (doCall) {
            this.setState({ isLocked: !isLocked })
        } else {
            this.setState({ isLockedError: 'An error occured' })
        }
    }

    componentDidUpdate(prevProps) {
        const { actionWasSet } = this.state

        if (
            actionWasSet &&
            prevProps.__ion_status[actionWasSet] &&
            prevProps.__ion_status[actionWasSet] !== READY &&
            this.props.__ion_status[actionWasSet] === READY
        ) {
            this.props.closeDrawer(true)
        }
    }

    render() {
        const { performance, classes, __ion_status, driverRole, closeDrawer, printButtonHandler } = this.props
        const {
            selectedName,
            selectedProfile,
            isOpen,
            savedDriver,
            allreadyClosed,
            unassignDisable,
            teacherError,
            isLocked,
            isLockedError,
            unassignDriverError,
        } = this.state

        const { signInOutCall, assignDriverCall } = __ion_status
        if (assignDriverCall === 4 && this.props.store.assignDriverCall !== undefined && !allreadyClosed) {
            const message = getNormalizedMessage(this.props.store.assignDriverCall._root.entries[2][1].response.data)

            if (message.indexOf('Profile already has an overlapping performance') !== -1) {
                this.handleOpen(this.props.store.assignDriverCall._root.entries[2][1].response.data)
            }
            if (message.indexOf('Profile has an deselected performance') !== -1) {
                this.setState({ openDeselectedPerformanceDialog: true })
                this.handleOpen(this.props.store.assignDriverCall._root.entries[2][1].response.data)
            }
        }
        let isLoading =
            signInOutCall === LOADING || assignDriverCall === LOADING || this.state.assignTeacherDisable || unassignDisable
        return (
            <Translate>
                {({ translate }) => (
                    <React.Fragment>
                        <Typography variant="h6">
                            {this.props.driverRole === 2 && isDepotLeerling(performance.depotName)
                                ? translate('performace_drawer_select_teacher')
                                : translate('performances_label_change_driver')}
                        </Typography>
                        <hr style={{ marginBottom: 16 }} />
                        <div
                            className={classes.mb}
                            name="PersonSearcherWrapper"
                            tabIndex={0}
                            style={{ position: 'relative' }}
                        >
                            <PersonSearcher
                                selectedName={selectedName}
                                setName={this.setName}
                                clearName={this.clearName}
                                type="app"
                                disabled={checkFunctionalityDisabled(
                                    this.props.store,
                                    'change driver',
                                    'performances.functionality.sidebar'
                                )}
                            />
                        </div>
                        <div className={classes.mb}>
                            {this.props.driverRole === 2 && isDepotLeerling(performance.depotName) ? (
                                <PrimaryButton
                                    className={classes.fullButton}
                                    disabled={!selectedName || isLoading}
                                    onClick={() => {
                                        if (selectedProfile)
                                            this.assignTeacher(
                                                selectedProfile.idfNumber,
                                                performance.id,
                                                performance.idfNumber
                                            )
                                    }}
                                >
                                    {translate('performace_drawer_assign_teacher')}
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton
                                    className={classes.fullButton}
                                    disabled={!selectedName || isLoading}
                                    onClick={() => {
                                        if (selectedProfile)
                                            this.persistAssignDriver(
                                                selectedProfile.idfNumber,
                                                performance.id,
                                                performance.idfNumber
                                            )
                                    }}
                                >
                                    {translate('performances_label_change_driver')}
                                </PrimaryButton>
                            )}
                            {isLoading && (
                                <div style={{ height: '100px' }}>
                                    <Loading />
                                </div>
                            )}
                            {teacherError.length > 1 && <ErrorMessage spacing={false} error={teacherError} />}
                            {assignDriverCall === 4 &&
                                this.props.store.assignDriverCall !== undefined &&
                                getNormalizedMessage(
                                    this.props.store.assignDriverCall._root.entries[2][1].response.data
                                ).indexOf('Action not allowed') !== -1 && <ErrorMessage error={translate('roles_error')} />}
                        </div>

                        <div className={classes.mb}>
                            <PrimaryButton
                                color="secondary"
                                className={classes.fullButton}
                                disabled={
                                    isLoading ||
                                    checkFunctionalityDisabled(
                                        this.props.store,
                                        'unassign_driver',
                                        'performances.functionality.sidebar'
                                    )
                                }
                                onClick={() => this.unAssignDriver(performance.idfNumber, performance.id)}
                            >
                                {translate('performances_label_unassign_driver')}
                            </PrimaryButton>
                            {unassignDriverError.length > 1 && <ErrorMessage spacing={false} error={unassignDriverError} />}
                        </div>

                        <Typography variant="h6">{translate('performances_label_sign_in_user')}</Typography>
                        <hr style={{ marginBottom: 16 }} />
                        <div className={classes.flexAlign}>
                            <IconButton
                                className={classes.halfButton}
                                icon="start"
                                disabled={
                                    checkFunctionalityDisabled(
                                        this.props.store,
                                        'signoff',
                                        'performances.functionality.sidebar'
                                    ) || isLoading
                                }
                                onClick={this.signInDriver(performance.idfNumber, performance.id, false)}
                                title={translate('performances_label_sign_in_user_now')}
                            />
                            <IconButton
                                className={classes.halfButton}
                                icon="start"
                                disabled={
                                    checkFunctionalityDisabled(
                                        this.props.store,
                                        'signoff',
                                        'performances.functionality.sidebar'
                                    ) || isLoading
                                }
                                onClick={this.signInDriver(performance.idfNumber, performance.id, true)}
                                title={translate('performances_label_sign_in_user_planned')}
                            />
                        </div>

                        {driverRole === 1 && (
                            <AddStudentView
                                closeDrawer={closeDrawer}
                                performanceId={performance.id}
                                performanceIdfNumber={performance.idfNumber}
                            />
                        )}

                        {signInOutCall === 4 &&
                            this.props.store.signInOutCall !== undefined &&
                            getNormalizedMessage(this.props.store.signInOutCall._root.entries[2][1].response.data).indexOf(
                                'Action not allowed'
                            ) !== -1 && <ErrorMessage error={translate('roles_error')} />}

                        {signInOutCall === 4 &&
                            this.props.store.signInOutCall !== undefined &&
                            getNormalizedMessage(this.props.store.signInOutCall._root.entries[2][1].response.data).indexOf(
                                'Already a selected performance'
                            ) !== -1 && <ErrorMessage spacing={false} error={translate('error_sign_on')} />}

                        <AssignDriverCall />
                        {assignDriverCall === 4 &&
                            this.props.store.assignDriverCall !== undefined &&
                            (getNormalizedMessage(
                                this.props.store.assignDriverCall._root.entries[2][1].response.data
                            ).indexOf('No current profile found in request') !== -1 ||
                                getNormalizedMessage(
                                    this.props.store.assignDriverCall._root.entries[2][1].response.data
                                ).indexOf('Profile was not found.') !== -1) && (
                                <ErrorMessage spacing={false} error="No current profile found in request" />
                            )}

                        <SignInOutCall />
                        {this.state.openDeselectedPerformanceDialog ? 
                        <DeselectedPerformanceDialog
                            __ion_status={__ion_status}
                            open={isOpen}
                            handleClose={this.handleClose}
                            translate={translate}
                            savedDriver={savedDriver}
                            util={util}
                            store={this.props.store}
                            responseData={this.state.responseData}
                        />
                        :
                        <ReplaceDialog
                            __ion_status={__ion_status}
                            open={isOpen}
                            handleClose={this.handleClose}
                            translate={translate}
                            savedDriver={savedDriver}
                            util={util}
                            store={this.props.store}
                            responseData={this.state.responseData}
                        />
                        }
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isLocked}
                                        onChange={() =>
                                            this.lockUnlock('isLocked', performance.performanceId, performance.idfNumber)
                                        }
                                        value="isLocked"
                                        color="primary"
                                        disabled={checkFunctionalityDisabled(
                                            this.props.store,
                                            'lock_unlock_performance',
                                            'performances.functionality.sidebar'
                                        )}
                                    />
                                }
                                label={translate('performace_drawer_locked')}
                            />
                            {isLockedError.length > 0 && <ErrorMessage spacing={false} error={isLockedError} />}
                        </div>
                        <PrimaryButton
                            disabled={checkFunctionalityDisabled(
                                this.props.store,
                                'print_perf_detail',
                                'performances.functionality.sidebar'
                            )}
                            className={classes.fullButton}
                            onClick={() => printButtonHandler(performance)}
                        >
                            <Typography>
                                <Translate id="print_performances" />
                            </Typography>
                        </PrimaryButton>
                    </React.Fragment>
                )}
            </Translate>
        )
    }
}

export default compose(
    withStyles(styles),
    withLocalize,
    withStore,
    withStatus,
    withClear('clearAssignedCall', 'assignDriverCall')
)(NotSignedOnDetailView)
