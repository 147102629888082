export default function(a, b) {
    const nameA = a.toUpperCase() // ignore upper and lowercase
    const nameB = b.toUpperCase() // ignore upper and lowercase

    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    // names must be equal
    return 0
}
