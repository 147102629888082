import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import LindaRolesForm from '../LindaRolesForm'

function LindaDrawer(props) {
    const { isOpen, onClose, editDrawer, classes, translate, addEditError, addEditLoading } = props

    return (
        <Drawer open={isOpen} anchor="right" onClose={onClose}>
            <div className={classes.drawerRoot}>
                <Typography variant="h6">
                    {editDrawer ? translate('linda_linda_roles_edit_roles') : translate('linda_linda_roles_add_roles')}
                </Typography>
                <LindaRolesForm loading={addEditLoading} error={addEditError} {...props} />
            </div>
        </Drawer>
    )
}
LindaDrawer.propTypes = {
    onClose: PropTypes.func,
    translate: PropTypes.func,
    isOpen: PropTypes.bool,
    editDrawer: PropTypes.bool,
    addEditError: PropTypes.string,
    addEditLoading: PropTypes.bool,
    classes: PropTypes.object,
}
export default LindaDrawer
