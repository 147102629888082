import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default async (performanceId, taskId, idfNumber, excludeMilestones) => {
    const { url, subscriptionKey } = requestData
    const fullUrl =
        `${url}/performance/${performanceId}/task/${taskId}/timetables` +
        `?excludeMilestones=${excludeMilestones}&idfNumber=${idfNumber ?? ''}`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            return []
        }
        return response.data
    } catch (e) {
        console.error(e) // eslint-disable-line
    }
}
