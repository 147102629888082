export default theme => ({
    drawerRoot: {
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: `${theme.readStatusBoxes}px`,
        padding: `${theme.spacing(3)}px`,
        '@media (max-width: 577px)': {
            width: '85vw',
            overflowX: 'hidden',
        },
    },
    drawerHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: `${theme.readStatusBoxes - 2 * theme.spacing(3)}px`,
        marginBottom: theme.spacing(3),
    },
})
