import React from 'react'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import LindaDrawer from './LindaDrawer'

class LindaDrawerView extends React.Component {
    render() {
        return <LindaDrawer {...this.props} />
    }
}

export default compose(withStore, withStyles(styles), withLocalize)(LindaDrawerView)
