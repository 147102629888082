import React from 'react'
import ESemes from './esemes.component'
import StoreProvider from './esemes.store'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'

const ESemesModule = (props) => {
    return (
        <StoreProvider store={props.store}>
            <ESemes />
        </StoreProvider>
    )
}

ESemesModule.propTypes = {
    store: PropTypes.object,
};

export default compose(withStore)(ESemesModule)
