import React from 'react'
import { object, bool, func, string, array } from 'prop-types'
import ErrorMessage from 'components/ErrorMessage'
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Drawer,
    Badge,
    IconButton,
    TextField,
} from '@material-ui/core'

import { getRoleFromId } from '../../config/UserRoles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DelIcon from '@material-ui/icons/Delete'
import AddOrganisation from './components/AddOrganisation'
import AddRole from './components/AddRole'
import IconButtonC from 'components/IconButton'
import DatePickerOrg from 'components/DatePickerOrg'
import { TranslationContext } from 'context/translation'

const AddNewUserGroupDrawer = props => {
    const mapOrganisations = org => {
        const { OrganisationsData } = props
        const item = OrganisationsData.find(orgs => orgs.value.toString() === org.organisation.toString())
        return item ? item.label : ''
    }

    const { translate } = React.useContext(TranslationContext)

    /**
     *
     * @returns If User Group has more than 1 active roles or have more than one
     * organisations return false else true
     */
    const isDeleteDisabled = () => {
        let totalOrg = 0
        if (userRoles.length) {
            for (let role of userRoles) {
                totalOrg += role.organisations.length
            }
        }
        return totalOrg <= 1
    }
    const printRoles = () => {
        const {
            // userRoles,
            // classes,
            rolesLoading,
            deleteOrg,
            allLocations,
            handleLocationSearchValue,
            clearLocationSearchValue,
            locationSearchValue,
            selectedLocationSearchItem,
            addOrganisation,
            changeOrgDate,
            roleItems
        } = props

        const { content, expansionPanelDetails } = props.classes

        if (rolesLoading) return <Typography> {translate('label_loading')}</Typography>
        if (userRoles?.length === 0) return <Typography>{translate('users_and_roles_no_roles_found')}</Typography>
        return userRoles.map(role => (
            <Accordion key={`${role.validFrom}-${role.portalUserRoleId}`}>
                <AccordionSummary classes={{ content: content }} expandIcon={<ExpandMoreIcon />}>
                    <Typography>{getRoleFromId(roleItems, role.portalUserRoleId.toString())}</Typography>
                    <Badge color="primary" showZero={true} badgeContent={parseInt(role.organisations.length)}>
                        <React.Fragment />
                    </Badge>
                </AccordionSummary>
                <AccordionDetails className={expansionPanelDetails}>
                    <AddOrganisation
                        role={role}
                        classes={classes}
                        allLocations={allLocations}
                        handleLocationSearchValue={handleLocationSearchValue}
                        clearLocationSearchValue={clearLocationSearchValue}
                        locationSearchValue={locationSearchValue}
                        selectedLocationSearchItem={selectedLocationSearchItem}
                        addOrganisation={addOrganisation}
                    />

                    {role.organisations.map(org => (
                        <Grid
                            container
                            key={`${org.organisation}-${role.portalUserRoleId}-${org.validFrom}-${org.validTo}`}
                            spacing={2}
                            className={classes.backgroundPaper}
                        >
                            <Grid item xs={11}>
                                <Typography variant={'subtitle1'} style={{ width: '100%', display: 'block' }}>
                                    {mapOrganisations(org)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    disabled={isDeleteDisabled()}
                                    className={classes.noPadButton}
                                    component="span"
                                    onClick={() => {
                                        deleteOrg(org, role.portalUserRoleId)
                                    }}
                                >
                                    <DelIcon color={isDeleteDisabled() ? 'grey' : 'error'} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={6}>
                                <DatePickerOrg
                                    type="validFrom"
                                    startValue={org.validFrom}
                                    translate={translate}
                                    changeOrgDate={changeOrgDate}
                                    role={role.portalUserRoleId}
                                    org={org.organisation}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePickerOrg
                                    type="validTo"
                                    startValue={org.validTo}
                                    translate={translate}
                                    changeOrgDate={changeOrgDate}
                                    role={role.portalUserRoleId}
                                    org={org.organisation}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </AccordionDetails>
            </Accordion>
        ))
    }

    const {
        classes,
        open,
        onClose,
        editUserGroupId,
        saveRoles,
        addSingleRole,
        userRoles,
        editProfileId,
        handleEvent,
        selectedRole,
        saveRolesError,
        roleAdded,
        // handleConfirmClose,
        // confirmIsOpen,
        // emptyRoles,
        rolesSaving,
        handleGroupName,
        groupName,
        roleItems
    } = props

    return (
        <Drawer open={open} anchor="right" style={{ zIndex: 1300 }} onClose={onClose}>
            <div className={classes.drawerRoot}>
                <div>
                    <Typography variant="h6" style={{ marginBottom: 24 }}>
                        {editUserGroupId ? translate('icon_button_edit_group') : translate('icon_button_add_user')}
                    </Typography>

                    <hr style={{ marginBottom: 16 }} />

                    <React.Fragment>
                        <TextField
                            error={groupName === ''}
                            value={groupName}
                            label={translate('group_name_label')}
                            onChange={e => {
                                handleGroupName(e)
                            }}
                        />
                        <Typography variant="h6">{translate('users_and_roles_add_roles')}</Typography>
                        <AddRole
                            userRoles={userRoles}
                            editProfileId={editProfileId}
                            addSingleRole={addSingleRole}
                            editUserGroupId={editUserGroupId}
                            roleItems={roleItems}
                            handleEvent={handleEvent}
                            classes={classes}
                            selectedRole={selectedRole}
                        />
                        <hr style={{ marginBottom: 16 }} />

                        <Typography variant="h6">{translate('users_and_roles_add_rights')}</Typography>
                        <hr style={{ marginBottom: 16 }} />
                        {printRoles()}
                    </React.Fragment>
                    <div className={classes.buttonContainer}>
                        <IconButtonC
                            icon=""
                            color="primary"
                            title={rolesSaving ? 'Loading...' : translate('add_user_confirm_save')}
                            disabled={userRoles.length === 0 || roleAdded || rolesSaving || groupName === ''}
                            onClick={() => {
                                saveRoles()
                            }}
                        />
                    </div>
                    {roleAdded && <ErrorMessage spacing={false} error={translate('add_user_minimum_organisation')} />}
                    {saveRolesError.length > 0 && <ErrorMessage spacing={false} error={saveRolesError} />}
                </div>
            </div>
            {/* <ConfirmDialog
                userRoles={userRoles}
                saveRoles={saveRoles}
                open={confirmIsOpen}
                editUserGroupId={editUserGroupId}
                handleClose={handleConfirmClose}
                emptyRoles={emptyRoles}
                roleItems={roleItems}
                saveRolesError={saveRolesError}
                translate={translate}
                rolesSaving={rolesSaving}
            /> */}
        </Drawer>
    )
}

AddNewUserGroupDrawer.propTypes = {
    classes: object.isRequired,
    open: bool.isRequired,
    onClose: func.isRequired,
    saveRoles: func,
    addSingleRole: func,
    handleEvent: func,
    // handleConfirmOpen: func,
    // handleConfirmClose: func,
    changeOrgDate: func,
    selectedName: string.isRequired,
    editUserGroupId: string,
    editProfileId: string,
    selectedProfile: object,
    selectedRole: string,
    saveRolesError: string,
    userRoles: array,
    roleAdded: bool,
    confirmIsOpen: bool,
    // emptyRoles: array,
    data: array,
    OrganisationsData: array,
    rolesSaving: bool,
    rolesLoading: bool,
    deleteOrg: func,
    allLocations: array,
    handleLocationSearchValue: func,
    clearLocationSearchValue: func,
    locationSearchValue: string,
    selectedLocationSearchItem: object,
    addOrganisation: func,
    groupName: string,
    handleGroupName: func,
    roleItems: array,
}

export default AddNewUserGroupDrawer
