export default theme => ({
    bbDiv: {
        backgroundColor: theme.customColors.toolbar,
        zIndex: '1199',
        padding: '0 8px',
        [theme.breakpoints.down('xs')]: {
            padding: `0`,
        },
    },
    bbInfo: {
        padding: '8px',
        [theme.breakpoints.down('sm')]: {
            padding: `4px`,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    closeIcon: {
        cursor: 'pointer',
        color: theme.customColors.primaryBlue,
    },
    closeIconDisabled: {
        color: theme.customColors.detailGrey,
    },
    mobileFont: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.75rem',
        },
    },
    versionBackground: {
        background: theme.customColors.toast,
        borderRadius: 6,
        padding: '6px',
        [theme.breakpoints.down('sm')]: {
            padding: `3px`,
        },
    },
})
