import React, { useState } from 'react'
import { MuiThemeProvider } from '@material-ui/core'
import { getThemeByName } from './getThemeByName'
import { object } from 'prop-types'

export const ThemeContext = React.createContext()

const ThemeProvider = props => {
    if (localStorage.getItem('theme') === null) localStorage.setItem('theme', 'dark')
    // State to hold the selected theme name
    const curThemeName = localStorage.getItem('theme')
    const [themeName, _setThemeName] = useState(curThemeName)

    const setThemeName = name => {
        localStorage.setItem('theme', name)
        _setThemeName(name)
    }

    return (
        <ThemeContext.Provider value={{ themeName, setThemeName }}>
            <MuiThemeProvider theme={getThemeByName(themeName)}>{props.children}</MuiThemeProvider>
        </ThemeContext.Provider>
    )
}
ThemeProvider.propTypes = {
    children: object,
}
export default ThemeProvider
