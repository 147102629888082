import React from 'react'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'

import { withStyles } from '@material-ui/core'

// Components
import NewTaskFooterView from './NewTaskFooterView'

import styles from './styles'

class NewTaskFooter extends React.Component {
    render() {
        return <NewTaskFooterView {...this.props} />
    }
}

export default compose(withStyles(styles), withLocalize)(NewTaskFooter)
