import React from 'react'
import { oneOfType, object, bool, func } from 'prop-types'
import { compose } from 'recompose'
import { withStore, withStatus } from 'react-ion-store'
import axios from 'axios'
import { withRouter } from 'react-router-dom'

import { DateTime } from 'luxon'
// Components
import PerformanceActionDrawerView from './PerformanceActionDrawerView'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

class PerformanceActionDrawer extends React.Component {
    static propTypes = {
        performanceInfo: oneOfType([object, bool]).isRequired,
        close: func.isRequired,
        store: object.isRequired,
        refreshPerformances: func,
        translate: func,
    }

    state = {
        driverRole:
            this.props.performanceInfo.performance !== undefined ? this.props.performanceInfo.performance.driverRole : 0,
        reasonEndTimeChanged: '',
        reasonStartTimeChanged: '',
        realStartTime: '',
        realEndTime: '',
        adjustLoading: false,
        adjustError: '',
        startError: '',
        endError: '',
        setMonitorLoading: false,
        setMonitorError: '',
        plannedStartTime:
            this.props.performanceInfo.performance !== undefined
                ? this.props.performanceInfo.performance.plannedStartTime
                : '',
        plannedEndTime:
            this.props.performanceInfo.performance !== undefined
                ? this.props.performanceInfo.performance.plannedEndTime
                : '',
        adjustPlannedTimeError: '',
        adjustPlannedTimeLoading: false,
        isStartTimeChanged: false,
        isThirdPartyPerformance:
            this.props.performanceInfo.performance !== undefined
                ? this.props.performanceInfo.performance.isThirdPartyPerformance
                : false,
    }

    componentDidUpdate = () => {
        const { performanceInfo } = this.props

        const { performance } = performanceInfo || {}
        //on drawer close reset the state if it was changed
        if (!performance && this.state.realEndTime.length !== 0 && this.state.realStartTime.length !== 0) {
            this.setState({
                driverRole: performance !== undefined ? performance.driverRole : 0,
                reasonEndTimeChanged: '',
                reasonStartTimeChanged: '',
                realStartTime: '',
                realEndTime: '',
                setMonitorError: '',
                setMonitorLoading: false,
                adjustError: '',
                startError: '',
                endError: '',
                adjustLoading: false,
                adjustPlannedTimeError: '',
                adjustPlannedTimeLoading: false,
                isStartTimeChanged: false,
                isThirdPartyPerformance: false,
            })
        }

        // DateTime.fromMillis(realStartTime * 1000).setZone("UTC")
        if (
            !!performance &&
            Object.keys(performance).length > 0 &&
            this.state.realEndTime.length === 0 &&
            this.state.realStartTime.length === 0
        ) {
            let realStart = ''
            let realEnd = ''

            if (this.props.performanceInfo.performance.realStartTime !== null) {
                realStart = DateTime.fromMillis(this.props.performanceInfo.performance.realStartTime * 1000)
            } else {
                realStart = DateTime.fromMillis(this.props.performanceInfo.performance.plannedStartTime * 1000)
            }

            if (this.props.performanceInfo.performance.realEndTime !== null) {
                realEnd = DateTime.fromMillis(this.props.performanceInfo.performance.realEndTime * 1000)
            } else {
                realEnd = DateTime.fromMillis(this.props.performanceInfo.performance.plannedEndTime * 1000)
            }

            this.setState({
                reasonEndTimeChanged:
                    this.props.performanceInfo.performance.reasonEndTimeChanged !== null
                        ? this.props.performanceInfo.performance.reasonEndTimeChanged
                        : '',
                reasonStartTimeChanged:
                    this.props.performanceInfo.performance.reasonStartTimeChanged !== null
                        ? this.props.performanceInfo.performance.reasonStartTimeChanged
                        : '',
                realStartTime: realStart,
                realEndTime: realEnd,
                driverRole: performance?.driverRole || 0,
                plannedStartTime:
                    this.props.performanceInfo.performance !== undefined
                        ? DateTime.fromMillis(this.props.performanceInfo.performance.plannedStartTime * 1000)
                        : '',
                plannedEndTime:
                    this.props.performanceInfo.performance !== undefined
                        ? DateTime.fromMillis(this.props.performanceInfo.performance.plannedEndTime * 1000)
                        : '',
                isThirdPartyPerformance:
                    this.props.performanceInfo.performance !== undefined
                        ? this.props.performanceInfo.performance.isThirdPartyPerformance
                        : false,
            })
        }
    }

    persistSetMonitor = (type, idf, id, roleEnum) => () => {
        let role = ''
        if (type === 'isPupil') {
            role = 'Student'
        }
        if (type === 'isMonitor') {
            role = 'Monitor'
        }
        if (type === 'isStandard') {
            role = 'Standard'
        }

        this.setMonitorCall(idf, id, role, roleEnum)
    }

    onStartReasonChange = e => {
        this.setState({ reasonStartTimeChanged: e.target.value })
    }

    onEndReasonChange = e => {
        this.setState({ reasonEndTimeChanged: e.target.value })
    }

    onChangeRealStartTime = e => {
        if (e !== null) this.setState({ realStartTime: e, isStartTimeChanged: true })
    }
    onChangePlannedStartTime = e => {
        this.setState({ plannedStartTime: e })
    }
    onChangePlannedEndTime = e => {
        this.setState({ plannedEndTime: e })
    }

    onChangeRealEndTime = e => {
        if (e !== null) this.setState({ realEndTime: e })
    }
    getUnixTimeStamp = date => {
        return parseInt(new Date(date).getTime()) / 1000
    }

    onThirdPartyChange = async e => {
        this.setState({ setMonitorLoading: true })
        const { url, subscriptionKey } = requestData
        // eslint-disable-next-line max-len
        const fullUrl = `${url}/performance/${this.props.performanceInfo.performance.performanceId}/thirdpartyperformance?idfNumber=${this.props.performanceInfo.performance.idfNumber}&isThirdPartyPerformance=${e}`
        try {
            const response = await axios.post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            if (response.status === 200) {
                this.setState({ isThirdPartyPerformance: e })
                this.setState({ setMonitorLoading: false })
                this.props.refreshPerformances()
                return true
            }
        } catch (e) {
            console.log(e)
            this.setState({
                adjustPlannedTimeError: e.response.data.Message ? e.response.data.Message : '',
                adjustPlannedTimeLoading: false,
            })
        }
        this.setState({ setMonitorLoading: false })
    }

    adjustPlannedTime = async (performance, startReason, endReason) => {
        this.setState({ adjustPlannedTimeError: '', adjustPlannedTimeLoading: true })
        const { url, subscriptionKey } = requestData
        const { translate } = this.props
        const fullUrl = `${url}/performance/${performance.performanceId}/updatePerformanceStartAndEndHours`
        try {
            const response = await axios.post(fullUrl, {
                IdfNumber: performance.idfNumber,
                PlannedStartDateTime: this.getUnixTimeStamp(this.state.plannedStartTime),
                PlannedEndDateTime: this.getUnixTimeStamp(this.state.plannedEndTime),
                ReasonChangeStartTime: startReason,
                ReasonChangeEndTime: endReason,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.setState({ adjustPlannedTimeLoading: false })
                this.props.close(true)
                return true
            }
        } catch (e) {
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({ adjustPlannedTimeError: translate('roles_error'), adjustPlannedTimeLoading: false })
                    return false
                }
                this.setState({ adjustPlannedTimeError: e.response.data.Message, adjustPlannedTimeLoading: false })
            } else {
                this.setState({
                    adjustPlannedTimeError: 'Performance could not be validated',
                    adjustPlannedTimeLoading: false,
                })
            }
        }
    }

    handleCheckbox = async (e, performanceId, isLocked, idf = null) => {
        const { url, subscriptionKey } = requestData

        let fullUrl = `${url}/performance/${performanceId}/LockUnlock?idfNumber=${idf}&isLocked=${!isLocked}`

        if (idf === null) {
            fullUrl = `${url}/performance/${performanceId}/LockUnlock?isLocked=${!isLocked}`
        }

        try {
            await axios.post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            this.props.refreshPerformances()
            return true
        } catch (exception) {
            console.error(exception) // eslint-disable-line
            if (exception.response.status === 400) {
                return exception.response.data.Message
            }
            return false
        }
    }

    validatePerformanceCall = async performance => {
        const { translate } = this.props
        const { url, subscriptionKey } = requestData
        const fullUrl = `${url}/performance/${performance.performanceId}/validate/${performance.idfNumber}?isValidated=true`

        try {
            const response = await axios.post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.setState({ validateLoading: false })
                return true
            }
        } catch (e) {
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({ adjustError: translate('roles_error'), adjustLoading: false })
                    return
                }
                this.setState({ adjustError: e.response.data.Message, adjustLoading: false })
            } else {
                this.setState({ adjustError: 'Performance could not be validated', adjustLoading: false })
            }
        }
    }

    adjustTimes = async performance => {
        const { reasonEndTimeChanged, reasonStartTimeChanged, realStartTime, realEndTime } = this.state
        let adjustStartStatus = false
        let adjustEndStatus = false
        this.setState({ adjustError: '' })

        if (reasonStartTimeChanged.length !== 0) {
            adjustStartStatus = await this.adjustStartCall(performance, reasonStartTimeChanged, realStartTime)
        }

        if (reasonEndTimeChanged.length !== 0) {
            adjustEndStatus = await this.adjustEndCall(performance, reasonEndTimeChanged, realEndTime)
        }

        if (
            reasonStartTimeChanged.length !== 0 &&
            reasonEndTimeChanged.length !== 0 &&
            adjustStartStatus &&
            adjustEndStatus
        ) {
            this.props.close(true)
            return
        }
        if (reasonStartTimeChanged.length !== 0 && adjustStartStatus && reasonEndTimeChanged.length === 0) {
            this.props.close(true)
            return
        }
        if (reasonEndTimeChanged.length !== 0 && adjustEndStatus && reasonStartTimeChanged.length === 0) {
            this.props.close(true)
        }
    }

    adjustStartTime = async performance => {
        const { reasonStartTimeChanged, realStartTime } = this.state
        let adjustStartStatus = false
        this.setState({ adjustError: '' })

        adjustStartStatus = await this.adjustStartCall(performance, reasonStartTimeChanged, realStartTime)

        if (adjustStartStatus) {
            this.props.close(true)
        }
    }

    getLocalISOString = (date) => {
        const offset = date.getTimezoneOffset()
        const offsetAbs = Math.abs(offset)
        const isoString = new Date(date.getTime() - offset * 60 * 1000).toISOString()
        // eslint-disable-next-line max-len
        return `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(Math.floor(offsetAbs / 60)).padStart(2, '0')}:${String(offsetAbs % 60).padStart(2, '0')}`
    }
    adjustStartCall = async (performance, reason, time) => {
        const fullUrl = `/performance/${performance.performanceId}/adjuststart`
        this.setState({ adjustLoading: true })
        let dataToServer = {
            IdfNumber: performance.idfNumber,
            realStartTime: time.toFormat("HH':'mm"),
            realStartDateTime: this.getLocalISOString(new Date(time)),
            reasonChangeStartTime: reason,
        }
        this.startEndTimeChangeCall(fullUrl, dataToServer)
    }

    adjustEndCall = async (performance, reason, time) => {
        const fullUrl = `/performance/${performance.performanceId}/adjustend`
        this.setState({ adjustLoading: true })
        let dataToServer = {
            IdfNumber: performance.idfNumber,
            realEndTime: time.toFormat("HH':'mm"),
            realEndDateTime: this.getLocalISOString(new Date(time)),
            reasonChangeEndTime: reason,
        }
        this.startEndTimeChangeCall(fullUrl, dataToServer)
    }

    startEndTimeChangeCall = async (url, data) => {
        const { translate } = this.props
        try {
            const response = await axios.post(url, data)
            if (response.status === 200) {
                this.setState({ adjustLoading: false })
                if (performance.isValidated === false) {
                    await this.validatePerformanceCall(performance)
                }
                return true
            }
        } catch (e) {
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({ adjustError: translate('roles_error'), adjustLoading: false })
                    return false
                }
                this.setState({ adjustError: e.response.data.Message, adjustLoading: false })
                return false
            } else {
                this.setState({ adjustError: 'Times not successfully changed', adjustLoading: false })
                return false
            }
        }
    }
    setMonitorCall = async (id, idf, role, roleEnum) => {
        const { url, subscriptionKey } = requestData
        const { translate } = this.props
        const fullUrl = `${url}/performance/${idf}/setdriverrole/${id}?role=${role}`
        this.setState({ setMonitorLoading: true, showTeacherError: false })
        try {
            const response = await axios.post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.setState({
                    setMonitorLoading: false,
                    setMonitorError: '',
                    driverRole: roleEnum,
                })
                this.props.refreshPerformances()
                return true
            }
        } catch (e) {
            console.error(e) // eslint-disable-line
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({ setMonitorError: translate('roles_error'), setMonitorLoading: false })
                    return
                }
            }
            this.setState({ setMonitorError: 'Driver role not changed', setMonitorLoading: false })
            return false
        }
    }
    render() {
        const { performanceInfo, close } = this.props
        const { setMonitorError, setMonitorLoading } = this.state
        console.log(performanceInfo)

        return (
            <PerformanceActionDrawerView
                {...this.props}
                isOpen={!!performanceInfo}
                close={close}
                performanceInfo={performanceInfo}
                setMonitorLoading={setMonitorLoading}
                setMonitorError={setMonitorError}
                persistSetMonitor={this.persistSetMonitor}
                isStandard={this.state.isStandard}
                isPupil={this.state.isPupil}
                driverRole={this.state.driverRole}
                onStartReasonChange={this.onStartReasonChange}
                onEndReasonChange={this.onEndReasonChange}
                reasonEndTimeChanged={this.state.reasonEndTimeChanged}
                reasonStartTimeChanged={this.state.reasonStartTimeChanged}
                realStartTime={this.state.realStartTime}
                realEndTime={this.state.realEndTime}
                onChangeRealStartTime={this.onChangeRealStartTime}
                onChangeRealEndTime={this.onChangeRealEndTime}
                onChangePlannedStartTime={this.onChangePlannedStartTime}
                onChangePlannedEndTime={this.onChangePlannedEndTime}
                plannedStartTime={this.state.plannedStartTime}
                plannedEndTime={this.state.plannedEndTime}
                adjustTimes={this.adjustTimes}
                adjustStartTime={this.adjustStartTime}
                adjustLoading={this.state.adjustLoading}
                adjustError={this.state.adjustError}
                handleCheckbox={this.handleCheckbox}
                adjustPlannedTime={this.adjustPlannedTime}
                adjustPlannedTimeLoading={this.state.adjustPlannedTimeLoading}
                adjustPlannedTimeError={this.state.adjustPlannedTimeError}
                isStartTimeChanged={this.state.isStartTimeChanged}
                isThirdPartyPerformance={this.state.isThirdPartyPerformance}
                onThirdPartyChange={this.onThirdPartyChange}
            />
        )
    }
}

export default compose(withRouter, withStore, withStatus)(PerformanceActionDrawer)
