/* eslint-disable */
import React from 'react'
import { compose } from 'recompose'

import { func, object, string, number, array } from 'prop-types'
import classNames from 'classnames'
import { Translate } from 'react-localize-redux'

import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'

import ListView from './ListView'

import styles from './styles'

import { toDateTimeFromSeconds } from 'utils/dateParser'

const columns = [
    {
        width: 250,
        label: 'datatable_header_task_code',
        dataKey: 'code',
    },
    {
        width: 150,
        label: 'linda_actions_datatable_header_ptcar_comm_name',
        dataKey: 'ptcarName',
    },
    {
        width: 100,
        label: 'linda_actions_datatable_header_time_device',
        dataKey: 'timeOfDevice',
    },
    {
        width: 300,
        label: 'linda_actions_datatable_header_name_of_issuer',
        dataKey: 'nameOfIssuer',
    },
]

const LindaActionsList = props => {
    LindaActionsList.defaultProps = {
        headerHeight: 56,
        rowHeight: 56,
    }

    LindaActionsList.propTypes = {
        classes: object.isRequired,
        rowClassName: string,
        onRowClick: func,
        headerHeight: number,
        rowHeight: number,
        history: object,
        match: object,
        data: array,
    }

    const getRowClassName = ({ index }) => {
        const { rowClassName, onRowClick } = props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    const headerRenderer = headerProps => {
        const { headerHeight } = props
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: headerHeight,
                    paddingTop: 34,
                    paddingBottom: 34,
                    paddingRight: 0,
                }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }
    const cellRenderer = cellProps => {
        const { activeLanguage } = props
        const { dataKey, rowData } = cellProps

        let dataToShow = ''
        if (dataKey === 'dateOfDevice') {
            dataToShow = rowData['dateTimeOfDevice'].substring(0, 10)
        } else if (dataKey === 'timeOfDevice') {
            dataToShow = `${
                toDateTimeFromSeconds(rowData['registeredAt']).c.hour < 10
                    ? '0' + toDateTimeFromSeconds(rowData['registeredAt']).c.hour
                    : toDateTimeFromSeconds(rowData['registeredAt']).c.hour
            }:${
                toDateTimeFromSeconds(rowData['registeredAt']).c.minute < 10
                    ? '0' + toDateTimeFromSeconds(rowData['registeredAt']).c.minute
                    : toDateTimeFromSeconds(rowData['registeredAt']).c.minute
            }`
        } else if (dataKey === 'performance') {
            dataToShow = `${rowData['symbolicNameDepot'] ? rowData['symbolicNameDepot'] : ''} ${
                rowData['performanceNumber'] ? rowData['performanceNumber'] : ''
            }`
        } else if (dataKey === 'ptcarName') {
            dataToShow = activeLanguage.code === 'nl' ? rowData['ptcarCommercialNameNl'] : rowData['ptcarCommercialNameFr']
        } else dataToShow = rowData[dataKey]

        return (
            <TableCell
                className={classNames(classes.tableCell, {
                    [classes.tableRowRed]: rowData.colorStatus === 2,
                    [classes.tableRowYellow]: rowData.colorStatus === 1,
                })}
                component="div"
                variant="body"
                style={{ height: props.rowHeight }}
            >
                <Typography className={classes.tableTypo}>{dataToShow}</Typography>
            </TableCell>
        )
    }

    const { classes, data } = props
    if (data && data.length > 0) {
        return (
            <>
                <p className={classes.title}>Registraties</p>
                <ListView
                    columns={columns}
                    data={data}
                    classes={classes}
                    headerRenderer={headerRenderer}
                    cellRenderer={cellRenderer}
                    getRowClassName={getRowClassName}
                    // rowClickHandler={onRowClick}
                />
            </>
        )
    } else {
        return (
            <>
                <p className={classes.title}>Registraties</p>
                <div data-testid={'nodata'} className={classes.noData}>
                    <Typography>No Data</Typography>
                </div>
            </>
        )
    }
}

export default compose(withStyles(styles))(LindaActionsList)
