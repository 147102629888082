import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { Translate, withLocalize } from 'react-localize-redux'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import ProfileStatusListView from './ProfileStatusListView'
import SyncIcon from '@material-ui/icons/Sync'
import styles from './styles'
import { DateTime } from 'luxon'
import ProfileErrorCode from '../../ProfileErrorCode'

const columns = [
    {
        width: 200,
        label: 'search_label_idf',
        dataKey: 'idfNumber',
    },
    {
        width: 200,
        label: 'search_label_name',
        dataKey: 'profileName',
    },
    {
        width: 150,
        label: 'performance_info_label_depot',
        dataKey: 'depotSymbolicName',
    },
    {
        width: 150,
        label: 'profile_table_run3',
        dataKey: 'run3',
    },
    {
        width: 100,
        label: 'performances_refresh',
        dataKey: 'refresh',
    },
    {
        width: 250,
        label: 'profile_table_run2',
        dataKey: 'run2',
    },
    {
        width: 250,
        label: 'profile_table_run1',
        dataKey: 'run1',
    },
    {
        width: 250,
        label: 'sync_last_update',
        dataKey: 'lastModified',
    },
]

export class ProfileStatusTable extends Component {
    constructor(props) {
        super(props)
    }
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.object),
        columns: PropTypes.array,
        classes: PropTypes.object,
        headerHeight: PropTypes.number,
        rowHeight: PropTypes.number,
        onRowRefreshCall: PropTypes.func,
        rowClassName: PropTypes.string,
    }

    static defaultProps = {
        rowHeight: 56,
        headerHeight: 56,
    }

    headerRenderer = headerProps => {
        const { classes, headerHeight } = this.props

        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer)}
                variant="head"
                style={{ height: headerHeight }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }

    getFormatedDate = date => DateTime.fromISO(date).toFormat('dd/MM/yyyy')

    cellRenderer = cellProps => {
        const { classes, rowHeight } = this.props
        const { dataKey, rowData } = cellProps

        if (dataKey === 'idfNumber') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{rowData[dataKey]}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'profileName') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{`${rowData.firstName} ${rowData.lastName} `}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'depotSymbolicName') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{rowData[dataKey]}</Typography>
                </TableCell>
            )
        }

        let runs = rowData['runs']
        if (dataKey === 'run1') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    {runs.length > 0 && (
                        <Typography
                            style={runs[0].status === ProfileErrorCode.ERROR ? { color: 'red' } : { color: '#66bb6a' }}
                        >
                            {this.getFormatedDate(runs[0].runOn)}
                        </Typography>
                    )}
                </TableCell>
            )
        }
        if (dataKey === 'refresh') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <SyncIcon
                        className={classes.syncIcon}
                        onClick={e => {
                            this.props.onRowRefreshCall(e, rowData.idfNumber)
                        }}
                    />
                </TableCell>
            )
        }

        if (dataKey === 'run2') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    {runs.length > 1 && (
                        <Typography
                            style={runs[1].status === ProfileErrorCode.ERROR ? { color: 'red' } : { color: '#66bb6a' }}
                        >
                            {this.getFormatedDate(runs[1].runOn)}
                        </Typography>
                    )}
                </TableCell>
            )
        }
        if (dataKey === 'run3') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <div style={{ display: 'flex' }}>
                        {runs.length > 2 && (
                            <Typography
                                style={runs[2].status === ProfileErrorCode.ERROR ? { color: 'red' } : { color: '#66bb6a' }}
                            >
                                {this.getFormatedDate(runs[2].runOn)}
                            </Typography>
                        )}
                    </div>
                </TableCell>
            )
        }
        if (dataKey === 'lastModified') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    {rowData[dataKey] && <Typography>{this.getFormatedDate(rowData[dataKey])}</Typography>}
                </TableCell>
            )
        }
    }

    getRowClassName = () => {
        const { classes, rowClassName } = this.props
        return classNames(classes.tableRow, classes.flexContainer, rowClassName)
    }

    render() {
        const { data, classes, rowHeight, headerHeight } = this.props
        return (
            <ProfileStatusListView
                profiles={data}
                columns={columns}
                classes={classes}
                getRowClassName={this.getRowClassName}
                cellRenderer={this.cellRenderer}
                headerRenderer={this.headerRenderer}
                rowHeight={rowHeight}
                headerHeight={headerHeight}
            />
        )
    }
}

export default compose(withStyles(styles), withLocalize)(ProfileStatusTable)
