export default theme => ({
    button: {
        margin: theme.spacing(2),
        width: '33%',
        minWidth: '230px',
        height: '100%',
    },
    searchBar: {
        display: 'flex',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.grey['700'],
        alignItems: 'center',
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        },
        height: '96px',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        color: theme.customColors.lightGrey,
        backgroundColor: theme.palette.grey['800'],

        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(1),
        },
        marginLeft: 0,
        width: '100%',
        padding: theme.spacing(1),
    },
    searchIcon: {
        color: theme.customColors.lightGrey,
        width: theme.spacing(3),
        height: '36px',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(8),
        transition: theme.transitions.create('width'),
        width: '100%',
    },
    userListContainer: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    fullWidthRow: { width: '100%', borderBottom: '1px solid grey' },
    actionIcon: {
        marginRight: theme.spacing(3),
        cursor: 'pointer',
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
    clickableRow: {
        cursor: 'pointer',
    },
    fullWidthCell: {
        width: '100%',
    },
    marginButton: {
        marginRight: '24px',
    },
    underline: {
        textDecoration: 'underline',
    },
    testSelect: {
        border: 'none',
        background: theme.customColors.red,
        color: theme.customColors.white,
    },
})
