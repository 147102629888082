/* eslint-disable no-unused-vars */
import React from 'react'
import { bool, arrayOf, string, func, object } from 'prop-types'
import { withStore } from 'react-ion-store'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'

import LanguagePicker from 'components/LanguagePicker'
import CollapsibleModule from './components/CollapsibleModule'
import SubAppButton from './components/SubAppButton'

import { useMaxScreenWidth } from '../../utils/hooks/useMaxScreenWidth'
import { MenuContext } from 'context/menuStore'

const MenuView = props => {
    const { classes, closeMenu, goToPath, isMenuOpen, moduleList, userRoles, auth, store } = props

    const { menuTree, handleOpenState } = React.useContext(MenuContext)

    const isSmartphone = useMaxScreenWidth(641)

    return (
        <Drawer open={isMenuOpen} onClose={closeMenu}>
            <div className={classes.drawerRoot}>
                <List className={classes.menuList}>
                    {menuTree &&
                        menuTree.map(module => {
                            if (isSmartphone && module.hidePhone) {
                                return null
                            } else if (module.subModules === undefined) {
                                return (
                                    <SubAppButton
                                        isChild={false}
                                        key={module.id}
                                        id={module.id}
                                        path={module.path}
                                        goToPath={goToPath}
                                    />
                                )
                            } else {
                                return (
                                    <CollapsibleModule
                                        key={module.id}
                                        id={module.id}
                                        isOpen={module.isOpen}
                                        handleClick={handleOpenState}
                                        modules={module.subModules ? module.subModules : []}
                                        moduleList={moduleList}
                                        goToPath={goToPath}
                                        isSmartphone={isSmartphone}
                                        store={store}
                                        path={module.path ? module.path : null}
                                    />
                                )
                            }
                        })}
                </List>
                <div className={classes.languagePicker}>
                    <LanguagePicker alwaysWhite={false} />
                </div>
            </div>
        </Drawer>
    )
}

MenuView.propTypes = {
    classes: object.isRequired,
    closeMenu: func.isRequired,
    goToPath: func.isRequired,
    isMenuOpen: bool.isRequired,
    moduleList: arrayOf(string),
    userRoles: arrayOf(object),
    auth: string,
    store: object,
}

export default withStore(MenuView)
