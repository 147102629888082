import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default (compositionNonDrivingId, data) => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/compositionnondriving/${compositionNonDrivingId}`

    return axios.post(fullUrl, data, {
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    })
}
