import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
const getPerformancesByIdf = async postData => {
    const { url, subscriptionKey } = requestData
    let fullUrl = `${url}/performance/byidf/`
    fullUrl = `${fullUrl}${postData.idf}?fromdate=${postData.startDate}&todate=${postData.endDate}`
    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            return ''
        }
        if (response.status === 200) {
            return response.data
        }
    } catch (e) {
        console.error(e) // eslint-disable-line

        return ''
    }
}
export default getPerformancesByIdf
