import React from 'react'
import LindaRolesTable from './components/LindaRolesTable'
import LindaDrawer from './components/LindaDrawer'
import DeleteDialog from './components/DeleteLindaRoleDialog'
import Button from '@material-ui/core/Button'

import PropTypes from 'prop-types'

function LindaRolesView(props) {
    const { openDrawer, onDrawerClose, onDrawerOpen, onDialogClose, deleteData, dialogOpen, translate, reloadRoles } = props
    return (
        <div className={props.classes.LindaRolesContainer}>
            <div className={props.classes.buttonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        onDrawerOpen([], false)
                    }}
                    disabled={props.checkFunctionalityDisabled(
                        props.store,
                        'manageLindaRoles',
                        'manage.functionality.management.linda'
                    )}
                >
                    {props.translate('module_add_performance')}
                </Button>
            </div>
            <LindaRolesTable {...props} />
            <LindaDrawer isOpen={openDrawer} onClose={onDrawerClose} {...props} />
            <DeleteDialog
                deleteData={deleteData}
                dialogOpen={dialogOpen}
                onDialogClose={onDialogClose}
                translate={translate}
                reloadRoles={reloadRoles}
            />
        </div>
    )
}

LindaRolesView.propTypes = {
    translate: PropTypes.func,
    reloadRoles: PropTypes.func,
    classes: PropTypes.object,
    store: PropTypes.object,
    checkFunctionalityDisabled: PropTypes.func,
    openDrawer: PropTypes.bool,
    onDrawerClose: PropTypes.func,
    onDrawerOpen: PropTypes.func,
    onDialogClose: PropTypes.func,
    deleteData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    dialogOpen: PropTypes.bool,
}
export default LindaRolesView
