import React, { Component } from 'react'
import { Paper, Grid, Typography } from '@material-ui/core'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { withLocalize, Translate } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

export class IconBoxView extends Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        title: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
        classes: PropTypes.object,
    }

    render() {
        const { children, title, value, classes } = this.props

        return (
            <Paper>
                <Grid container spacing={0} className={classes.blackBox}>
                    <Grid item md={2} xs={4} className={classes.centerIcon}>
                        {children}
                    </Grid>
                    <Grid style={{ paddingLeft: '10px' }} item md={10} xs={8}>
                        <Typography>
                            <Translate id={title} />
                        </Typography>
                        <Typography> {value}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}

export default compose(withLocalize, withStyles(styles))(IconBoxView)
