import * as React from 'react'
import { arrayOf, object, bool, func } from 'prop-types'
import classNames from 'classnames'
import MonitoringTableMobile from '../MonitoringTableMobile'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import styles from './styles'
import { IconButton } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import * as Icons from '@material-ui/icons'

const mapProperties = p => {
    const keys = Object.keys(p)
    const newObj = {}

    keys.forEach(k => {
        const lk = k.toLowerCase()
        newObj[lk] = p[k]
    })

    return newObj
}

const Status = {
    0: 'OK',
    1: 'WARNING:',
    2: 'ERROR',
    OK: 0,
    WARNING: 1,
    ERROR: 2,
}

const mapStatus = {
    [Status.OK]: 'OK',
    [Status.WARNING]: 'WARNING',
    [Status.ERROR]: 'ERROR',
}

const MonitoringTable = props => {
    const { data = [], classes, isTablet } = props

    return (
        <div className={classes.contentDiv}>
            {!isTablet && (
                <table className={isTablet ? classes.tableFixed : classes.table} style={{ width: '100%' }}>
                    <thead>
                        <tr className={classes.tr}>
                            <th className={classes.th} style={{ width: isTablet ? '120px' : '' }}>
                                <Typography className={classes.darkText}>Host</Typography>
                            </th>
                            <th className={classes.th} style={{ width: isTablet ? '120px' : '' }}>
                                <Typography className={classes.darkText}>Service</Typography>
                            </th>
                            <th className={classes.th} style={{ width: isTablet ? '120px' : '' }}>
                                <Typography className={classes.darkText}>Timestamp</Typography>
                            </th>
                            <th className={classes.th} style={{ width: isTablet ? '120px' : '' }}>
                                <Typography className={classes.darkText}>Status</Typography>
                            </th>
                            <th className={classes.th} style={{ width: isTablet ? '400px' : '' }}>
                                <Typography className={classes.darkText}>Description</Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((_d, index) => {
                            const d = mapProperties(_d)
                            return (
                                <tr key={index + d.host} className={classNames(classes.tr, classes[mapStatus[d.status]])}>
                                    <td className={classes.td}>
                                        <Typography className={d.status === Status.WARNING ? classes.darkText : ''}>
                                            {d.host}
                                        </Typography>
                                    </td>
                                    <td className={classes.td}>
                                        <Typography
                                            className={[
                                                d.status === Status.WARNING ? classes.darkText : '',
                                                classes.withIcon,
                                            ]}
                                        >
                                            <p>{d.service}</p>
                                            <IconButton
                                                onClick={() => {
                                                    props.handleDrawerOpen(index)
                                                }}
                                                color={d.status === Status.WARNING ? 'primary' : ''}
                                            >
                                                <CreateIcon />
                                            </IconButton>
                                        </Typography>
                                    </td>
                                    <td className={classes.td}>
                                        <Typography className={d.status === Status.WARNING ? classes.darkText : ''}>
                                            {d.timestamp}
                                        </Typography>
                                    </td>
                                    <td className={classes.td} style={{ width: isTablet ? '120px' : '' }}>
                                        <Typography className={d.status === Status.WARNING ? classes.darkText : ''}>
                                            {mapStatus[d.status]}
                                        </Typography>
                                    </td>
                                    <td className={`${classes.td}`} style={{ width: isTablet ? '400px' : '' }}>
                                        <Typography
                                            className={[
                                                d.status === Status.WARNING ? classes.darkText : '',
                                                classes.withIcon,
                                            ]}
                                        >
                                            <p>{d.description}</p>
                                            {d.status !== 0 && (
                                                <IconButton
                                                    onClick={() => props.handleDetailsDrawerOpen(d)}
                                                    color={d.status === Status.WARNING ? 'primary' : ''}
                                                >
                                                    <Icons.Info />
                                                </IconButton>
                                            )}
                                        </Typography>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )}
            {isTablet && <MonitoringTableMobile data={data} isTablet={isTablet} />}
        </div>
    )
}

MonitoringTable.propTypes = {
    data: arrayOf(object),
    classes: object,
    isTablet: bool,
    handleDrawerOpen: func,
    handleDetailsDrawerOpen: func,
}

export default withStyles(styles)(MonitoringTable)
