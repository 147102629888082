import axios from 'axios'

export default async () => {
    const fullUrl = `settings/numberofperformancesvisibleincalendar`

    try {
        return await axios.get(fullUrl)
    } catch (e) {
        return {
            status: 204,
        }
    }
}
