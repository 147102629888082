import React from 'react'
import PropTypes from 'prop-types'
import getTrainnumberprefixes from 'modules/LindaActions/services/getTrainnumberprefixes'

export const TrainNumberDataStore = React.createContext(null)

export const TrainNumberDataProvider = ({ children }) => {
    const [trainNumberData, setTrainNumberData] = React.useState([])

    const getTrainNumberPrefixes = lang => {
        const defaultValue = { id: ' ', text: 'Prefix' }
        let forSelect = []

        if (lang !== 'nl') {
            forSelect = trainNumberData.map(({ prefix, description }) => ({
                id: prefix,
                text: description[0]?.translation ? `${prefix} - ${description[0]?.translation}` : prefix,
            }))
        } else {
            forSelect = trainNumberData.map(({ prefix, description }) => ({
                id: prefix,
                text: description[1]?.translation ? `${prefix} - ${description[1]?.translation}` : prefix,
            }))
        }

        const filterSelect = forSelect.filter(item => item.id !== null)

        return [defaultValue, ...filterSelect]
    }

    React.useEffect(() => {
        const getPrefixes = async () => {
            const results = await getTrainnumberprefixes()
            if (results) {
                setTrainNumberData(results)
            } else {
                setTrainNumberData([])
            }
        }
        getPrefixes()
    }, [])

    return (
        <TrainNumberDataStore.Provider value={{ trainNumberData, getTrainNumberPrefixes }}>
            {children}
        </TrainNumberDataStore.Provider>
    )
}

TrainNumberDataProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
