import React from 'react'
import PropTypes from 'prop-types'
import {
    addFeedbackCategoryCall,
    deleteFeedbackCategoryCall,
    getFeedbackCall,
    handleSaveSortCall,
    updateFeedbackCategoryCall,
} from './service.feedbackCategories'
import { isObject, getNlString, getFrString } from './util'

export const INITIAL_STATE = {
    data: [],
    nameNl: '',
    nameFr: '',
    titleNl: '',
    titleFr: '',
    businessTags: [],
    fastFeedbackNl: '',
    fastFeedbackFr: '',
    order: '',
    restrictionLabelNl: '',
    restrictionLabelFr: '',
    isOpen: false,
    selectedPerformance: '',
    parentId: '',
    status: 0, // status 0 for Add 1 for Edit and 2 for Delete
    loading: false,
    signed: false,
    standard: false,
    errorMsg: '',
    isSortOpen: false,
    sortError: '',
    editSortLoading: false,
}

export const feedbackCategoriesContext = React.createContext(INITIAL_STATE)

const StoreProvider = ({ children }) => {
    const [e360, setE360] = React.useState(false)
    const [isFirstDeparture, setIsFirstDeparture] = React.useState(false)
    const [data, setData] = React.useState([])
    const [nameNl, setNameNl] = React.useState('')
    const [nameFr, setNameFr] = React.useState('')
    const [titleNl, setTitleNl] = React.useState('')
    const [titleFr, setTitleFr] = React.useState('')
    const [businessTags, setBusinessTags] = React.useState([])
    const [fastFeedbackNl, setFastFeedbackNl] = React.useState('')
    const [fastFeedbackFr, setFastFeedbackFr] = React.useState('')
    const [order, setOrder] = React.useState('')
    const [restrictionLabelNl, setRestrictionLabelNl] = React.useState('')
    const [restrictionLabelFr, setRestrictionLabelFr] = React.useState('')
    const [isOpen, setIsOpen] = React.useState(false)
    const [selectedPerformance, setSelectedPerformance] = React.useState('')
    const [parentId, setParentId] = React.useState('')
    const [status, setStatus] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [signed, setSigned] = React.useState(false)
    const [standard, setStandard] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('')
    const [isSortOpen, setIsSortOpen] = React.useState(false)
    const [sortError, setSortError] = React.useState('')
    const [editSortLoading, setEditSortLoading] = React.useState(false)

    const STATE = {
        data,
        setData,
        nameNl,
        setNameNl,
        nameFr,
        setNameFr,
        titleNl,
        setTitleNl,
        titleFr,
        setTitleFr,
        businessTags,
        setBusinessTags,
        fastFeedbackNl,
        setFastFeedbackNl,
        fastFeedbackFr,
        setFastFeedbackFr,
        order,
        setOrder,
        restrictionLabelNl,
        setRestrictionLabelNl,
        restrictionLabelFr,
        setRestrictionLabelFr,
        isOpen,
        setIsOpen,
        selectedPerformance,
        setSelectedPerformance,
        parentId,
        setParentId,
        status,
        setStatus,
        loading,
        setLoading,
        signed,
        setSigned,
        standard,
        setStandard,
        errorMsg,
        setErrorMsg,
        isSortOpen,
        setIsSortOpen,
        sortError,
        setSortError,
        editSortLoading,
        setEditSortLoading,
        e360,
        setE360,
        isFirstDeparture,
        setIsFirstDeparture,
    }

    const METHODS = {
        resetState() {
            setNameNl('')
            setNameFr('')
            setTitleNl('')
            setTitleFr('')
            setBusinessTags([])
            setFastFeedbackNl('')
            setFastFeedbackFr('')
            setOrder('')
            setRestrictionLabelNl('')
            setRestrictionLabelFr('')
            setIsOpen(false)
            setIsSortOpen(false)
            setStatus(0)
            setLoading(false)
            setSigned(false)
            setStandard(false)
            setErrorMsg('')
            setE360(false)
            setSortError('')
            setEditSortLoading(false)
            setIsFirstDeparture(false)
        },
        getTags(tags) {
            return tags.includes(',') ? tags.split(',') : [tags]
        },
        persist(type) {
            return function(props) {
                const defaultSetter = `set${type.charAt(0).toUpperCase()}${type.slice(1)}`
                switch (type) {
                    case 'signed':
                        setSigned(prev => !prev)
                        break
                    case 'standard':
                        setStandard(prev => !prev)
                        break
                    case 'e360':
                        setE360(prev => !prev)
                        break
                    case 'isFirstDeparture':
                        setIsFirstDeparture(prev => !prev)
                        break
                    case 'businessTags':
                        setBusinessTags(METHODS.getTags(props))
                        break
                    default:
                        STATE[defaultSetter](props)
                        break
                }
            }
        },
        onClose() {
            METHODS.resetState()
        },
        openDrawer(e, performance, newParentId = null) {
            e.stopPropagation()
            if (isObject(performance)) {
                setIsOpen(true)
                setSelectedPerformance(performance)
                setNameFr(getFrString(performance, 'description'))
                setNameNl(getNlString(performance, 'description'))
                setTitleFr(getFrString(performance, 'title'))
                setTitleNl(getNlString(performance, 'title'))
                setBusinessTags(performance.businessTags)
                setFastFeedbackNl(getNlString(performance, 'fastFeedback'))
                setFastFeedbackFr(getFrString(performance, 'fastFeedback'))
                setParentId(newParentId)
                setSigned(performance.signed)
                setOrder(performance.weight)
                setRestrictionLabelNl(getNlString(performance, 'restrictionLabel'))
                setRestrictionLabelFr(getFrString(performance, 'restrictionLabel'))
                setStatus(performance.subCategories.length > 0 ? 1 : 2)
                setE360(performance.e360)
                setIsFirstDeparture(performance.isFirstDeparture)
            } else {
                setIsOpen(true)
                setSelectedPerformance(performance)
                setParentId(newParentId)
                setStatus(0)
            }
        },
        openSortDrawer() {
            setIsSortOpen(true)
        },
        adjustData(newData) {
            return Object.entries(newData).map(([key, value]) => {
                return {
                    Id: key,
                    WeightFastFeedback: value,
                }
            })
        },
    }

    const SERVICES = {
        getFeedback(scope) {
            getFeedbackCall(setLoading, setData, scope)
        },
        updateFeedbackCategory(scope) {
            updateFeedbackCategoryCall({
                setLoading,
                selectedPerformance,
                parentId,
                nameFr,
                nameNl,
                titleFr,
                titleNl,
                fastFeedbackFr,
                fastFeedbackNl,
                signed,
                e360,
                isFirstDeparture,
                businessTags,
                order,
                restrictionLabelNl,
                restrictionLabelFr,
                setIsOpen,
                setErrorMsg,
                METHODS,
                SERVICES,
            }, scope)
        },
        deleteFeedbackCategory(scope) {
            deleteFeedbackCategoryCall({
                setLoading,
                selectedPerformance,
                parentId,
                nameFr,
                nameNl,
                titleFr,
                titleNl,
                businessTags,
                setIsOpen,
                setErrorMsg,
                METHODS,
                SERVICES,
            }, scope)
        },
        addFeedbackCategory(scope) {
            addFeedbackCategoryCall({
                parentId,
                nameFr,
                nameNl,
                titleFr,
                titleNl,
                fastFeedbackFr,
                fastFeedbackNl,
                signed,
                e360,
                isFirstDeparture,
                businessTags,
                order,
                restrictionLabelNl,
                restrictionLabelFr,
                setIsOpen,
                setErrorMsg,
                SERVICES,
                setLoading,
            }, scope)
        },
        handleSaveSort(newData, scope) {
            handleSaveSortCall(
                {
                    setSortError,
                    setEditSortLoading,
                    METHODS,
                    SERVICES,
                },
                newData, 
                scope
            )
        },
    }

    return (
        <feedbackCategoriesContext.Provider value={{ STATE, METHODS, SERVICES }}>
            {children}
        </feedbackCategoriesContext.Provider>
    )
}

StoreProvider.propTypes = {
    children: PropTypes.element.isRequired,
}

export default StoreProvider
