import React from 'react'
import { object, func, string } from 'prop-types'
import { withLocalize, Translate } from 'react-localize-redux'
import { compose } from 'recompose'
import { withStore, withStatus } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PersonSearcher from 'components/PersonSearcher'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

import styles from './styles'

class AddDriverToPerformance extends React.PureComponent {
    static propTypes = {
        setName: func.isRequired,
        selectedName: string.isRequired,
        store: object.isRequired,
        clearName: func.isRequired,
    }

    render() {
        const { setName, selectedName, clearName } = this.props

        return (
            <Translate>
                {({ translate }) => (
                    <React.Fragment>
                        <Typography style={{ marginTop: 16 }} variant='h6'>
                            {translate('assign_driver_label')}
                        </Typography>
                        <hr style={{ marginBottom: 16 }} />
                        <div style={{ marginBottom: 16, position: 'relative' }} name='PersonSearcherWrapper' tabIndex={0}>
                            <PersonSearcher
                                clearName={clearName}
                                selectedName={selectedName}
                                setName={setName}
                                type='app'
                                disabled={checkFunctionalityDisabled(
                                    this.props.store,
                                    'change driver',
                                    'performances.functionality.sidebar'
                                )}
                            />
                        </div>
                    </React.Fragment>
                )}
            </Translate>
        )
    }
}

export default compose(
    withStyles(styles),
    withLocalize,
    withStore,
    withStatus
)(AddDriverToPerformance)
