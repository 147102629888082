import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default (performanceId, taskId, idfNumber) => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/composition/${performanceId}/task/${taskId}/latestid?idfNumber=${idfNumber}`

    return axios.get(fullUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    })
}
