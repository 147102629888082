import { decode } from 'jsonwebtoken'

const accessKeyPath = process.env.REACT_APP_ACCESS_KEY_PATH
const idKeyPath = process.env.REACT_APP_ID_KEY_PATH
const accessKeyPathFb = process.env.REACT_APP_ACCESS_KEY_PATH_FB
const idKeyPathFb = process.env.REACT_APP_ID_KEY_PATH_FB

const localdb = window.localStorage

export const clear = () => {
    localdb.removeItem(accessKeyPath)
    localdb.removeItem(idKeyPath)
}

export const getAndValidate = () => {
    const [idToken, accessToken] = get()

    if (valid(idToken, accessToken)) return [idToken, idKeyPath, accessToken, accessKeyPath]

    clear()
    return ['', idKeyPath, '', accessKeyPath]
}

export const get = () => {
    let idToken = localdb.getItem(idKeyPath)

    //fallback code for client
    if (idToken === null) idToken = localdb.getItem(idKeyPathFb)

    let accessToken = localdb.getItem(accessKeyPath)
    //fallback code for client
    if (accessToken === null) accessToken = localdb.getItem(accessKeyPathFb)

    return [idToken, accessToken]
}

export const valid = (_idToken, _accessToken) => {
    const idToken = decode(_idToken)
    const accessToken = decode(_accessToken)

    if (!idToken && !accessToken) {
        return false
    }

    const now = parseInt(Date.now() / 1000, 10)

    if (!accessToken) {
        return validateTimes(now, idToken.exp)
    }

    return validateTimes(idToken?.exp, accessToken?.exp, true) && validateTimes(now, idToken?.exp)
}

export const validateTimes = (a, b, shouldEqual = false) => (shouldEqual ? a === b : a < b)
