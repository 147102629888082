import React from 'react'
import { compose } from 'recompose'
import { withStore, withStatus } from 'react-ion-store'
import { object, array } from 'prop-types'

import DocumentDetailView from './DocumentDetailView'

import { withStyles } from '@material-ui/styles'
import styles from './styles.js'

class DocumentDetail extends React.Component {
    render() {
        const { viewData, store } = this.props

        const sortParams = this.props.store.get('params') || undefined

        let params = { sort: 'idfNumber', sortDirection: 'asc', filter: 'All' }

        if (sortParams) {
            params = { ...params, sort: sortParams.sort, sortDirection: sortParams.sortDirection }
        }
        return <DocumentDetailView storePar={store} params={params} viewData={viewData} />
    }
}

DocumentDetail.propTypes = {
    viewData: array.isRequired,
    store: object.isRequired,
    __ion_status: object.isRequired,
}

export default compose(withStore, withStatus, withStyles(styles))(DocumentDetail)
