import React from 'react'
import { Translate } from 'react-localize-redux'
import classNames from 'classnames'

import { Link } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import DelayIndicator from '../DelayIndicator'
import TimeDifferenceIndicator from '../TimeDifferenceIndicator'
import ActionsColumn from '../ActionsColumn'

import timeParser from 'utils/timeParser'

export default (
    cellProps,
    { classes, rowHeight, match, storage, openPerformanceDriveDetailsDrawer, openPerformanceNotificationDrawer }
) => {
    const { dataKey, rowData } = cellProps

    let data = rowData[dataKey]

    if (dataKey === 'actions') {
        return (
            <ActionsColumn
                classes={classes}
                dataKey={dataKey}
                rowData={rowData}
                rowHeight={rowHeight}
                openPerformanceDriveDetailsDrawer={openPerformanceDriveDetailsDrawer}
                openPerformanceNotificationDrawer={openPerformanceNotificationDrawer}
            />
        )
    }

    if (
        (dataKey === 'plannedEndTime' && rowData.plannedEndDelay === 0) ||
        (dataKey === 'plannedStartTime' && rowData.plannedStartDelay === 0)
    ) {
        data = (
            <Typography style={{ color: 'white' }} variant="body2">
                {timeParser(rowData[dataKey])}
            </Typography>
        )
    } else if (dataKey === 'plannedStartTime' && rowData.plannedStartDelay !== 0) {
        data = (
            <Typography style={{ color: 'white' }} variant="body2">
                {timeParser(rowData[dataKey])} <DelayIndicator delay={rowData.plannedStartDelay} />
            </Typography>
        )
    } else if (dataKey === 'plannedEndTime' && rowData.plannedEndDelay !== 0) {
        data = (
            <Typography style={{ color: 'white' }} variant="body2">
                {timeParser(rowData[dataKey])} <DelayIndicator delay={rowData.plannedEndDelay} />
            </Typography>
        )
    } else if (dataKey === 'performanceId') {
        data = [
            <Link
                className={classes.link}
                key={rowData.performanceId + '_id'}
                target="_blank"
                style={{
                    pointerEvents: checkFunctionalityDisabled(
                        storage,
                        'view',
                        'performances.functionality.management.performanceDetail'
                    )
                        ? 'none'
                        : 'auto',
                }}
                to={`${match.path}/detail/${rowData.id}/profile/${rowData.idfNumber}`}
                onClick={e => {
                    e.stopPropagation()
                }}
            >
                <Typography
                    className={classNames(classes.blockText, classes.clickText)}
                    key={rowData.performanceId + '_id'}
                    variant="body2"
                >
                    {rowData.performanceId}
                </Typography>
            </Link>,
            <Typography className={classes.blockText} key={rowData.performanceId + '_depot'} variant="caption">
                Depot <Translate id={`DEPOT-${rowData.depotName}`} />{' '}
                {rowData.versionNumber ? ` -${rowData.performanceNumber} (${rowData.versionNumber})` : ''}
            </Typography>,
        ]
    } else if (dataKey === 'profileName') {
        data = [
            <>
                <Typography
                    key={rowData.performanceId + '_name'}
                    className={classNames(classes.blockText, classes.clickText)}
                    variant="body2"
                >
                    {rowData.profileName}
                </Typography>
                <Typography className={classNames(classes.plainText)} key={rowData.performanceId + '_idf'} variant="caption">
                    {rowData.idfNumber}
                </Typography>
            </>,
        ]
    } else if (dataKey === 'timeDifference') {
        data = <TimeDifferenceIndicator delay={rowData.timeDifference} />
    }

    return (
        <TableCell
            component="div"
            className={classNames(classes.tableCell, {
                [classes.flexContainer]:
                    dataKey === 'plannedStartTime' || dataKey === 'plannedEndTime' || dataKey === 'timeDifference',
            })}
            variant="body"
            style={{ height: rowHeight }}
        >
            {data}
        </TableCell>
    )
}
