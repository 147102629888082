import { getAndValidate } from './utils/tokenUtils'

const [auth, idKeyPath, accessToken, accessKeyPath] = getAndValidate()

export const store = {
    /**
     * AUTH DATA
     */
    // roles
    // roles: {
    //   performances: {
    //     read: true,
    //     write: true
    //   },
    //   documents: {
    //     read: true,
    //     write: true
    //   }
    // },

    // Access-Control List
    // moduleList: undefined,

    auth,
    accessToken,

    /**
     * APP DATA
     */
    currentTime: parseInt(Date.now() / 1000, 10), // || 1539783420,
    isMenuOpen: false,
    selectedDistrict: '', // district ID
    selectedTCTs: [''], // tct IDs
    selectedDepot: 'performances_label_all_depots', // depot ID
    autoSignIn: false,

    // API calls
    // districtsAndTctsAndDepots: undefined,
    // documentsReadActions: undefined,
    // documentsSetRead: undefined,
    // performances: undefined,
    // suggestions: undefined,

    // Static data
    accessKeyPath,
    idKeyPath,
    authPath: process.env.REACT_APP_OIDC_PATH,
    apiPath: process.env.REACT_APP_API_PATH,
    client_id: process.env.REACT_APP_CLIENT_ID,
    subKey: process.env.REACT_APP_SUBKEY,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    scope: 'openid edrive-api b_fullProfile',
    response_type: 'code',
}

export const staticKeys = [
    'accessKeyPath',
    'idKeyPath',
    'authPath',
    'apiPath',
    'client_id',
    'redirect_uri',
    'scope',
    'response_type',
]
