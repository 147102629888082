import React from 'react'
import { array, bool, func, object, oneOfType, arrayOf, string, number } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// Components
import ActionBar from 'components/ActionBar'
import DateTimePicker from 'components/DateTimePicker'

// import IconButton from "components/IconButton";
import PerformancesByDay from './components/PerformancesByDay'
import DepotFilter from './components/DepotFilter'
import DepotGroup from './components/DepotGroup'
import PerformanceActionDrawer from './components/PerformanceActionDrawer'
import AssignDrawer from '../../components/AssignDrawer'
import DeletePerformanceDialog from './components/Dialogs/DeletePerformanceDialog'

// import AssignDriverDialog from "../../components/AssignDriverDialog";
// import DelayReasonDialog from "../../components/DelayReasonDialog";
// import UnassignDriverDialog from "../../components/UnassignDriverDialog";

import styles from './styles'
import PerformanceDriveDetailsDrawer from './components/PerformanceDriveDetailsDrawer'
import PerformanceNotificationDrawer from './components/PerformanceNotificationDrawer'

const PerformanceView = ({
    classes,
    tcts,
    selectedTCTs,
    selectedDepot,
    performanceDrawer,
    closePerformanceDrawer,
    isAssignDrawerOpen,
    toggleAssignDrawer,
    // currentTime,
    persist,
    translate,
    timepickerDate,
    performances,
    performancesGrouped,
    toggleGroup,
    performancesIsLoading,
    realTime,
    toggleRealTime,
    store,
    filterValue,
    handleFilterValue,
    colorValues,
    fromDate,
    toDate,
    saveScrollPosition,
    scrollPosition,
    openPerformanceDriveDetailsDrawer,
    closePerformanceDriveDetailsDrawer,
    performanceDriveDetailsDrawerOpen,
    performanceDriveDetailsDrawerData,
    performanceDriveDetailsDrawerParams,
    setParentstate,
    syncData,
    isDeletePerformanceDialogOpen,
    openDeletePerformanceDialog,
    deletePerformanceId,
    deletePerformanceLoading,
    deletePerformanceError,
    doDeletePerformance,
    closeDeletePerformanceDialog,
    openPerformanceNotificationDrawer,
    closePerformanceNotificationDrawer,
    performanceNotificationDrawerOpen,
    performanceNotificationDrawerData,
    performanceNotificationDrawerParams,
    refreshPerformances,
    saveActiveTab,
}) => (
    <Grid container spacing={0}>
        <Grid item xs={12}>
            <ActionBar>
                <DateTimePicker
                    identifier="performanceOverview"
                    startValue={timepickerDate}
                    translate={translate}
                    persist={persist}
                    color={'tertiary'}
                    toggleRealTime={toggleRealTime}
                    realTime={realTime}
                    // disabled={realTime}
                    disableFuture={false}
                    filterValue={filterValue}
                    handleFilterValue={handleFilterValue}
                    colorValues={colorValues}
                    fromDate={fromDate}
                    toDate={toDate}
                    saveScrollPosition={saveScrollPosition}
                    scrollPosition={scrollPosition}
                    syncData={syncData}
                    performancesIsLoading={performancesIsLoading}
                    refreshPerformances={refreshPerformances}
                    storage={store}
                />
            </ActionBar>
        </Grid>
        <Grid item xs={12} className={classes.performancesContainer} translate="no">
            {!performancesGrouped && (
                <PerformancesByDay
                    storage={store}
                    date={timepickerDate.toMillis()}
                    // openDelayReasonDialog={openDelayReasonDialog}
                    selectPerformance={persist('performanceDrawer')}
                    performances={performances}
                    selectedTCTs={selectedTCTs}
                    tcts={tcts}
                    selectedDepot={selectedDepot}
                    performancesIsLoading={performancesIsLoading}
                    saveScrollPosition={saveScrollPosition}
                    scrollPosition={scrollPosition}
                    openPerformanceDriveDetailsDrawer={openPerformanceDriveDetailsDrawer}
                    openDeletePerformanceDialog={openDeletePerformanceDialog}
                    openPerformanceNotificationDrawer={openPerformanceNotificationDrawer}
                    saveActiveTab={saveActiveTab}
                />
            )}
            {/* Group by comes here */}
            {performancesGrouped && (
                <DepotGroup
                    performances={performances}
                    selectedTCTs={selectedTCTs}
                    tcts={tcts}
                    selectedDepot={selectedDepot}
                    saveScrollPosition={saveScrollPosition}
                >
                    {(_performances, _tcts, _selectedTCTs, _selectedDepot) => (
                        <PerformancesByDay
                            storage={store}
                            date={timepickerDate.toMillis()}
                            selectPerformance={persist('performanceDrawer')}
                            performances={_performances}
                            tcts={_tcts}
                            selectedTCTs={_selectedTCTs}
                            selectedDepot={_selectedDepot}
                            performancesIsLoading={performancesIsLoading}
                            saveScrollPosition={saveScrollPosition}
                            scrollPosition={scrollPosition}
                            openPerformanceDriveDetailsDrawer={openPerformanceDriveDetailsDrawer}
                            openDeletePerformanceDialog={openDeletePerformanceDialog}
                            openPerformanceNotificationDrawer={openPerformanceNotificationDrawer}
                        />
                    )}
                </DepotGroup>
            )}
        </Grid>

        <Grid item xs={12}>
            <DepotFilter
                onDepotSelected={/*handleDepotSelected*/ () => {}}
                onTctSelected={/*toggleTctSelected*/ () => {}}
                selectedDepot={selectedDepot}
                selectedTCTs={selectedTCTs}
                tcts={tcts}
                onChange={toggleGroup}
                checked={performancesGrouped}
                disabled={performancesIsLoading}
                saveScrollPosition={saveScrollPosition}
            />
        </Grid>

        <AssignDrawer isOpen={!!isAssignDrawerOpen} toggleDrawer={toggleAssignDrawer} />
        <PerformanceActionDrawer
            performanceInfo={performanceDrawer}
            close={closePerformanceDrawer}
            translate={translate}
            refreshPerformances={refreshPerformances}
        />
        <PerformanceDriveDetailsDrawer
            close={closePerformanceDriveDetailsDrawer}
            performanceDriveDetailsDrawerOpen={performanceDriveDetailsDrawerOpen}
            performanceDriveDetailsDrawerData={performanceDriveDetailsDrawerData}
            performanceDriveDetailsDrawerParams={performanceDriveDetailsDrawerParams}
            setParentstate={setParentstate}
        />
        <PerformanceNotificationDrawer
            close={closePerformanceNotificationDrawer}
            performanceNotificationDrawerOpen={performanceNotificationDrawerOpen}
            performanceNotificationDrawerData={performanceNotificationDrawerData}
            performanceNotificationDrawerParams={performanceNotificationDrawerParams}
            setParentstate={setParentstate}
        />
        {/* <DelayReasonDialog isOpen={isDelayReasonDialogOpen} onClose={closeDelayReasonDialog} /> */}
        <DeletePerformanceDialog
            open={isDeletePerformanceDialogOpen}
            handleClose={closeDeletePerformanceDialog}
            id={deletePerformanceId}
            deletePerformance={doDeletePerformance}
            deletePerformanceLoading={deletePerformanceLoading}
            deletePerformanceError={deletePerformanceError}
            translate={translate}
        />
    </Grid>
)

PerformanceView.propTypes = {
    classes: object.isRequired,
    tcts: arrayOf(object).isRequired,
    selectedTCTs: arrayOf(string).isRequired,
    selectedDepot: array.isRequired,
    toggleAssignDrawer: func.isRequired,
    isAssignDrawerOpen: oneOfType([object, bool]).isRequired,
    performanceDrawer: oneOfType([object, bool]).isRequired,
    closePerformanceDrawer: func.isRequired,
    performances: array.isRequired,
    performancesIsLoading: bool.isRequired,
    performancesGrouped: bool.isRequired,
    toggleGroup: func.isRequired,
    currentTime: number.isRequired,
    persist: func.isRequired,
    timepickerDate: object.isRequired,
    realTime: bool.isRequired,
    toggleRealTime: func.isRequired,
    translate: func,
    store: object,
    filterValue: string,
    handleFilterValue: func,
    colorValues: object,
    fromDate: oneOfType([object, string, number]),
    toDate: oneOfType([object, string, number]),
    saveScrollPosition: func,
    scrollPosition: number,
    openPerformanceDriveDetailsDrawer: func,
    closePerformanceDriveDetailsDrawer: func,
    performanceDriveDetailsDrawerOpen: bool,
    performanceDriveDetailsDrawerData: array,
    performanceDriveDetailsDrawerParams: object,
    setParentstate: func,
    syncData: object,
    isDeletePerformanceDialogOpen: bool,
    openDeletePerformanceDialog: func,
    deletePerformanceId: string,
    deletePerformanceLoading: bool,
    deletePerformanceError: string,
    doDeletePerformance: func,
    closeDeletePerformanceDialog: func,
    openPerformanceNotificationDrawer: func,
    closePerformanceNotificationDrawer: func,
    performanceNotificationDrawerOpen: bool,
    performanceNotificationDrawerData: array,
    performanceNotificationDrawerParams: array,
    refreshPerformances: func,
    saveActiveTab: func,
}

export default compose(withLocalize, withStyles(styles))(PerformanceView)
