export default theme => ({
    taskDetailViewBody: {
        padding: theme.spacing(3),
        paddingBottom: '78px',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
            paddingBottom: '78px',
        },
    },
    expansionPanelDetails: {
        backgroundColor: theme.customColors.expansionBg,
    },
    expansionPanelSummary: {},
    content: { justifyContent: 'space-between' },
    iconButtonStyled: {
        minHeight: '36px',
        background: theme.customColors.red,
        color: theme.customColors.white,
    },
    crossedTime: {
        textDecoration: 'line-through',
    },
    panelRoot: {
        position: 'inherit',
    },
    tableCell: {
        borderColor: 'red',
    }
})
