import React from 'react'
import PropTypes from 'prop-types'
import {
    DatePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    DateTimePicker,
    TimePicker,
    KeyboardDateTimePicker,
} from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'

export const DatePickerWithKeyboard = props => {
    const selected = props.value !== '' ? new Date(props.value) : null
    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardDatePicker inputProps={{ 'data-testid': 'keyboard_date_picker' }} value={selected} {...props} />
        </MuiPickersUtilsProvider>
    )
}
DatePickerWithKeyboard.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
}

export const BasicDatePicker = props => {
    const selected = props.value !== '' ? new Date(props.value) : null
    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker data-testid="BasicDatePicker" value={selected} {...props} />
        </MuiPickersUtilsProvider>
    )
}
BasicDatePicker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
}

export const BasicTimePicker = props => {
    const selected = props.value !== '' ? new Date(props.value) : null
    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <TimePicker value={selected} {...props} />
        </MuiPickersUtilsProvider>
    )
}
BasicTimePicker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
}

export const BasicDateTimePicker = props => {
    const selected = props.value !== '' ? new Date(props.value) : null
    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DateTimePicker value={selected} {...props} />
        </MuiPickersUtilsProvider>
    )
}
BasicDateTimePicker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
}

export const BasicInlineDatePicker = props => {
    const selected = props.value !== '' ? new Date(props.value) : null
    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker variant={'inline'} label={props.label} value={selected} onChange={date => props.onChange(date)} />
        </MuiPickersUtilsProvider>
    )
}
BasicInlineDatePicker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    label: PropTypes.string,
    onChange: PropTypes.func,
}

export const TimePickerWithKeyboardNew = props => {
    const selected = props.value !== '' ? props.value : null
    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardTimePicker value={selected} {...props} />
        </MuiPickersUtilsProvider>
    )
}
TimePickerWithKeyboardNew.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
}
export const TimePickerWithKeyboard = props => {
    const selected = props.value !== '' ? props.value : null
    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardTimePicker
                autoOk={true}
                format="HH:mm"
                ampm={false}
                label={props.label}
                value={selected}
                onChange={date => props.onChange(date)}
                disabled={props.disabled}
                {...props}
            />
        </MuiPickersUtilsProvider>
    )
}
TimePickerWithKeyboard.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    label: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

export const DateAndTimePickerWithKeyboard = props => {
    const selected = props.value !== '' ? props.value : null

    return (
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardDateTimePicker value={selected} {...props} />
        </MuiPickersUtilsProvider>
    )
}
DateAndTimePickerWithKeyboard.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
}
