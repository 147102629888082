import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withStore, enums, withCall } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'
import withFilteredTctData from '../../../../../Performances/views/PerformanceOverview/components/withFilteredTctData'
import Status from './components/Status'
import queryParamEncoder from 'utils/queryParamEncoder'

import styles from './styles'
const {
    CallStatus: { LOADING, FAILED },
} = enums

const Failed = () => <div>Error retrieving Roles!</div>

const progressHandlers = {
    [LOADING]: Loading,
    [FAILED]: Failed,
}

class ProfileStatus extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Status {...this.props} />
            </React.Fragment>
        )
    }
}

const depotQS = props => {
    return props.tcts.reduce(
        (accumulator, tct, index) =>
            tct.depots.reduce(
                (depotString, depot, depotIndex) =>
                    `${depotString}${index === 0 && depotIndex === 0 ? '' : '%20'}${depot.symbolicName}`,
                accumulator
            ),
        ''
    )
}

export default compose(withRouter, withStore, withFilteredTctData, withStyles(styles), Component => props =>
    withCall(props.store.get('selectedDistrict'), {
        config: {
            method: 'get',
            url: '/profile/status',
            params: {
                depot: depotQS(props),
            },
            paramsSerializer: queryParamEncoder,
        },
        progressHandlers: progressHandlers,
        reducer: ({ data }) => {
            if (data.length === 0) {
                return []
            }
            return data
        },
    })(Component)(props)
)(ProfileStatus)
