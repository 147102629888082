import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export const getSemesFromPerformanceTask = async (perfId, taskId, idfNumber) => {
    const { url, subscriptionKey } = requestData

    const fullUrl = `${url}/performance/${perfId}/task/${taskId}/semesreadstatuses?idfNumber=${idfNumber}`
    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        return response
    } catch (e) {
        console.error(e) // eslint-disable-line
    }
}

export const getSemesFromPerformance = async (perfId, idfNumber) => {
    const { url, subscriptionKey } = requestData

    const fullUrl = `${url}/performance/${perfId}/semesreadstatuses?idfNumber=${idfNumber}`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        return response
    } catch (error) {
        console.error(error)
    }
}
