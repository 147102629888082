import React from 'react'
import { compose } from 'recompose'
import { withStore, withCall, enums } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import ClearListView, { Failed } from './ClearListView'
import Loading from 'components/Loading'

const {
    CallStatus: { LOADING, FAILED },
} = enums
const ProgressHandlers = {
    [LOADING]: () => <Loading />,
    [FAILED]: () => <Failed />,
}

const callKey = 'documentsClearActions'

class ClearList extends React.Component {
    static propTypes = {
        store: PropTypes.object,
    }

    render() {
        const data = this.props.store.get(callKey)

        return <ClearListView {...this.props} data={data} />
    }
}

const withFilledCall = Component => props => {
    return withCall(callKey, {
        config: {
            method: 'get',
            url: `/profile/${props.idf}/getallcleardevicedataactions`,
        },
        progressHandlers: ProgressHandlers,
        reducer: response => {
            if (response.status === 200) return response.data
            return []
        },
    })(Component)(props)
}

export default compose(withStore, withFilledCall, withLocalize)(ClearList)
