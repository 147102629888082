import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default async (composition) => {
    const { url, subscriptionKey } = requestData

    const fullUrl = `${url}/composition/simulatebrakingreport`

    try {
        const response = await axios.post(fullUrl, composition, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 200) {
            return response.data
        }
    } catch (e) {
        console.error(e) // eslint-disable-line
    }
}
