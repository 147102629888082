import axios from 'axios'
import { DateTime } from 'luxon'
const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
let cancelToken
export default async (trainNumber, selectedDate) => {
    const { url, subscriptionKey } = requestData
    const startHour = '00' //start.substring(0, 2)
    const startMin = '10' //start.substring(3, 5)
    const endHour = '23' //end.substring(0, 2)
    const endMin = '20' //end.substring(3, 5)

    const startDate = DateTime.fromSeconds(selectedDate).toFormat('ddMMyyyy') + startHour + startMin
    const endDate = DateTime.fromSeconds(selectedDate).toFormat('ddMMyyyy') + endHour + endMin
    // eslint-disable-next-line max-len
    const fullUrl = `${url}/referenceData/trainlocations/${trainNumber}/startDateTime/${startDate}/endDateTime/${endDate}?ignorePrefix=true&driveTasksOnly=false`

    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.')
    }
    cancelToken = axios.CancelToken.source()

    try {
        const response = await axios.get(fullUrl, {
            cancelToken: cancelToken.token,
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            return []
        }
        return response.data.locations
    } catch (e) {
        console.error(e) // eslint-disable-line
        return []
    }
}
