import React, { Component } from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { DateTime } from 'luxon'
import { compose } from 'recompose'
import { withStore, withClear } from 'react-ion-store'
import TextField from '@material-ui/core/TextField'
import { withLocalize } from 'react-localize-redux'
//config
import TaskTypes from '../../config/taskTypes'
import groups from '../../config/groups'

import { TimePickerWithKeyboard } from 'components/Pickers'
import SelectInput from 'components/SelectInput'
import { EditTaskView } from './EditTaskView'
import IconBoxEdit from '../../components/IconBoxEdit'
import LocationSearch from '../../components/LocationSearch'
import ReferencedPerformance from './referencedPerformance'
import DistrictsMapperNoFilter from 'components/DistrictsMapperNoFilter'
import ErrorMessage from 'components/ErrorMessage'

import EditTaskFooter from './EditTaskFooter'
import DriveTaskView from './DriveTaskView'
import NoRights from '../../../NoRights'
import LocationDropDown from './LocationDropDown'

import getTrainLocations from '../../Services/getTrainLocations'
import getAllLocations from '../../Services/getAllLocations'
import getLindaRoles from '../../Services/getLindaRoles'
import getPerformanceNumbers from '../../Services/getPerformanceNumbers'

import checkWriteAccess from 'utils/checkWriteAccess'
import getNormalizedMessage from 'utils/getNormalizedMessage'

import styles from './styles'
import { TrainNumberDataStore } from 'context/trainNumberData'
import { setUTCTimeZone } from 'utils/timeParser'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

class EditTask extends Component {
    static propTypes = {
        task: PropTypes.object,
        performanceDetail: PropTypes.object,
        activeLanguage: PropTypes.object,
        history: PropTypes.object,
        classes: PropTypes.object,
        translate: PropTypes.func,
        clearPerformanceDetail: PropTypes.func,
    }

    state = {
        taskType: this.props.task.code,
        trainComposed: this.props.task.trainComposed || '',
        comment: this.props.task.comment || this.props.task.taskInfo || '',
        diagramNumber: this.props.task.diagramNumber || '',
        startTime: this.props.task.plannedStartTime ? 
            DateTime.fromFormat(this.props.task.plannedStartTime, 'HH:mm:ss') : null,
        endTime: this.props.task.plannedEndTime ? 
            DateTime.fromFormat(this.props.task.plannedEndTime, 'HH:mm:ss') : null,
        allLocations: [],
        locationSearch: '',
        locationFromSearchValue: '',
        locationFromSelected: this.props.task.locationFromPtCarId || '',
        locationToSearchValue: '',
        locationToSelected: this.props.task.locationToPtCarId || '',
        locationSearchValue: '',
        locationSelected: this.props.task.locationPtCarId || '',
        locationFrom: this.props.task.locationFrom || '',
        locationFromPtCarId: this.props.task.locationFromPtCarId || '',
        location: this.props.task.location || '',
        locationPtcarId: this.props.task.locationPtCarId || '',
        locationTo: this.props.task.locationTo || '',
        locationToPtCarId: this.props.task.locationToPtCarId || '',
        selectedReferencePerformanceNumber: this.props.task.referencedPerformance
            ? this.props.task.referencedPerformance.performanceId.substring(
                0,
                this.props.task.referencedPerformance.performanceId.lastIndexOf('-')
            )
            : ' ',
        referenceOrg: ' ',
        referenceDepot: ' ',
        referencedPerformance: this.props.task.referencedPerformance || '',
        loadingReferencePerformance: false,
        referencePerformanceNumbersData: [],
        editLoading: false,
        editError: '',
        performanceDate: this.props.performanceDetail.performanceDate,
        trainLocationsFrom: [],
        trainLocationsTo: [],
        trainLocations: [],
        trainPrefix: this.props.task.trainComposed ? this.getPrefiex(this.props.task.trainComposed) || ' ' : '',
        trainNumber: this.props.task.trainComposed ? this.getTrainNumber(this.props.task.trainComposed) : '',
        timePickerDisable: false,
        isTablet: window.matchMedia('(max-width:1025px)').matches,
        driveRole: this.props.task.lindaRole ? this.props.task.lindaRole : ' ',
        lindaRoles: [],
        originalLindaRoles: [],
        diagramNumberError: false,
    }

    mapComponents = () => {
        const { taskType } = this.state
        let toRender = [
            { component1: false, required: 'comment' },
            { component2: false },
            { component3: false, required: 'startTime' },
            { component4: false, required: 'endTime' },
            { component5: false, required: 'locationFrom' },
            { component6: false, required: 'locationTo' },
            { component7: false, required: 'location' },
            { component8: false },
            { component9: false },
            { component10: false, required: 'diagramNumber' },
        ]

        if (taskType === ' ') return toRender
        //eslint-disable-next-line no-useless-escape
        const groupNumber = TaskTypes.find(
            type => type.id.replace(/[. ,:-]+/g, '').toUpperCase() === taskType.replace(/[. ,:-]+/g, '').toUpperCase()
        )

        const mapping = groups.find(item => item.group === groupNumber.group)

        for (let component of mapping.components) {
            toRender[`component${component}`] = true
        }

        return toRender
    }

    toggleChoosePerfo = () => {
        const { choosePerfo } = this.state
        this.setState({ choosePerfo: !choosePerfo })
    }

    handleEvent = type => e => this.persist(type)(e.target.value)
    
    handleEventDiagramNumber = type => e => {
        this.persist(type)(e.target.value)
        this.validateDiagramNumber(e.target.value)
    }

    sortTaskTypes = data => {
        const list = data.sort((a, b) => a.text.localeCompare(b.text))
        return [...list]
    }

    findTasktypesOfGroup = id => {
        //eslint-disable-next-line no-useless-escape
        const task = TaskTypes.find(
            taskType => taskType.id.replace(/[. ,:-]+/g, '').toUpperCase() === id.replace(/[. ,:-]+/g, '').toUpperCase()
        )
        const group = task.group

        return TaskTypes.filter(taskType => taskType.group === group)
    }

    filterLocationToData = data => {
        const { locationToSearchValue } = this.state

        if (locationToSearchValue === '') return data

        return data.filter(item => item.label === locationToSearchValue || item.code === locationToSearchValue)
    }

    filterLocationData = data => {
        const { locationSearchValue } = this.state

        if (locationSearchValue === '') return data

        return data.filter(item => item.label === locationSearchValue || item.code === locationSearchValue)
    }

    filterLocationFromData = data => {
        const { locationFromSearchValue } = this.state

        if (locationFromSearchValue === '') return data

        return data.filter(item => item.label === locationFromSearchValue || item.code === locationFromSearchValue)
    }

    getPrefiex(trainComposed) {
        let index = this.getIndexForTrainNumber(trainComposed)
        return trainComposed.substr(0, index)
    }
    getTrainNumber(trainComposed) {
        let index = this.getIndexForTrainNumber(trainComposed)
        return trainComposed.substr(index, trainComposed.length)
    }
    getIndexForTrainNumber(trainComposed) {
        let firstDigit = trainComposed.match(/\d/) // will give you the first digit in the string
        return trainComposed.indexOf(firstDigit)
    }

    checkStepToAllowDriveEditing(taskType) {
        if (
            (taskType.toUpperCase() === 'DRIVE' || taskType.toUpperCase() === 'HLP') &&
            this.state.trainLocations.length === 0
        )
            return false
        return true
    }

    getLocationToArray(reducedData) {
        let copiedForToArray = [...reducedData]
        copiedForToArray.splice(1, 1)
        return copiedForToArray
    }
    getLocationFromArray(reducedData) {
        let copiedForFromArray = [...reducedData]
        copiedForFromArray.pop()
        return copiedForFromArray
    }
    loadTrain = async () => {
        this.setState({ loading: true, noTrainData: false, diagramNumber: '' })
        const { trainNumber, trainPrefix, taskType, performanceDate } = this.state
        let data = []
        let reducedData = []

        let tNumber = trainNumber.replace(/\D/g, '')
        const start = this.props.performanceDetail.plannedStartTime
        const end = this.props.performanceDetail.plannedEndTime
        data = await getTrainLocations(tNumber, trainPrefix, performanceDate, start, end)

        if (data.length === 0) {
            this.setState({ noTrainData: true, loading: false })
            return
        }
        reducedData = this.reduceTrainData(data)
        let timePickerDisable = false
        if (taskType.toUpperCase() === 'DRIVE' || taskType === 'HLP') {
            timePickerDisable = true
        }

        let departureTime = ''
        let arrivalTime = ''
        if (reducedData.length > 0) {
            departureTime = this.getDepartureTime(reducedData)

            arrivalTime = this.getArrivalTime(reducedData)
        }

        this.setState({
            trainLocations: reducedData,
            trainLocationsTo: this.getLocationToArray(reducedData),
            trainLocationsFrom: this.getLocationFromArray(reducedData),
            loading: false,
            startTime: departureTime,
            endTime: arrivalTime,
            locationFrom: reducedData.length > 0 ? reducedData[1]?.text : '',
            locationTo: reducedData.length > 0 ? reducedData[reducedData.length - 1]?.text : '',
            timePickerDisable: timePickerDisable,
            locationFromPtCarId: reducedData.length > 0 ? reducedData[1]?.id : '',
            locationToPtCarId: reducedData.length > 0 ? reducedData[reducedData.length - 1]?.id : '',
            ptcarUicTo: reducedData.length > 0 ? reducedData[reducedData.length - 1]?.ptcarUic : '',
            ptcarUicFrom: reducedData.length > 0 ? reducedData[1]?.ptcarUic : '',
        })
    }

    getSeconds(time) {
        let date = new Date(this.state.performanceDate).getTime()
        let secondsString = ''
        if (time) {
            let a = time.split(':') // split it at the colons
            // minutes are worth 60 seconds. Hours are worth 60 minutes.
            let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
            return date + seconds
        }

        return secondsString
    }
    getDepartureTime(data) {
        if (data[1].passTroughTime) return setUTCTimeZone(data[1].passTroughTime)
        else if (data[1].plannedDepartureTime) return setUTCTimeZone(data[1].plannedDepartureTime)
        return ''
    }
    getArrivalTime(data) {
        if (data[data.length - 1].passTroughTime) return setUTCTimeZone(data[data.length - 1].passTroughTime)
        else if (data[data.length - 1].plannedArrivalTime) return setUTCTimeZone(data[data.length - 1].plannedArrivalTime)
        return ''
    }
    setTrainData(data) {
        let reducedData = []
        reducedData = this.reduceDeriveDetails(data)
        let timePickerDisable = false
        if (data.code.toUpperCase() === 'DRIVE' || data.code === 'HLP') {
            timePickerDisable = true
        }

        let departureTime = ''
        let arrivalTime = ''
        if (reducedData.length > 0) {
            departureTime = this.getDepartureTime(reducedData)

            arrivalTime = this.getArrivalTime(reducedData)
        }

        this.setState({
            trainLocations: reducedData,
            trainLocationsTo: this.getLocationToArray(reducedData),
            trainLocationsFrom: this.getLocationFromArray(reducedData),
            loading: false,
            startTime: departureTime,
            endTime: arrivalTime,
            locationFrom: reducedData.length > 0 ? reducedData[1].text : '',
            locationTo: reducedData.length > 0 ? reducedData[reducedData.length - 1].text : '',
            timePickerDisable: timePickerDisable,
            ptcarUicTo: reducedData.length > 0 ? reducedData[reducedData.length - 1].ptcarUic : '',
            ptcarUicFrom: reducedData.length > 0 ? reducedData[1].ptcarUic : '',
            locationFromPtCarId: data.locationFromPtCarId,
            locationToPtCarId: data.locationToPtCarId,
        })
    }

    reduceDeriveDetails = data => {
        const { activeLanguage } = this.props
        const defaultValue = { id: ' ', text: 'Choose location' }
        if (data === undefined || data.length === 0) return []
        if (activeLanguage.code === 'nl') {
            const forSelect = data.driveDetails
                .filter((obj, index) => index === 0 || index === data.driveDetails.length || !obj.passTroughTime)
                .map(({ ptcarId, location, arrivalTime, departureTime, ptcarUic, passTroughTime }) => ({
                    id: ptcarId,
                    text: location[1].translation,
                    plannedArrivalTime: this.getSeconds(arrivalTime),
                    plannedDepartureTime: this.getSeconds(departureTime),
                    ptcarUic,
                    passTroughTime: passTroughTime ? this.getSeconds(passTroughTime) : '',
                }))
            if (forSelect.length) {
                return [defaultValue, ...forSelect]
            } else return forSelect
        }
        if (activeLanguage.code === 'fr') {
            const forSelect = data.driveDetails
                .filter((obj, index) => index === 0 || index === data.driveDetails.length || !obj.passTroughTime)
                .map(({ ptcarId, location, arrivalTime, departureTime, ptcarUic, passTroughTime }) => ({
                    id: ptcarId,
                    text: location[0].translation,
                    plannedArrivalTime: this.getSeconds(arrivalTime),
                    plannedDepartureTime: this.getSeconds(departureTime),
                    ptcarUic,
                    passTroughTime: passTroughTime ? this.getSeconds(passTroughTime) : '',
                }))
            if (forSelect.length) {
                return [defaultValue, ...forSelect]
            } else return forSelect
        }
    }

    reduceTrainData = data => {
        const { activeLanguage } = this.props
        const defaultValue = { id: ' ', text: 'Choose location' }
        if (data === undefined || data.length === 0) return []

        if (activeLanguage.code === 'nl') {
            const forSelect = data.locations
                .filter(location => location.passThrough === false)
                .map(({ ptcarId, shortName, plannedArrivalTime, plannedDepartureTime, ptcarUic }) => {
                    return {
                        id: ptcarId,
                        text: shortName[1].translation,
                        plannedArrivalTime:
                            plannedArrivalTime == null
                                ? null
                                : this.getSeconds(
                                    DateTime.fromSeconds(plannedArrivalTime)
                                        .setZone('UTC')
                                        .toLocal()
                                        .toFormat('HH:mm:ss')
                                ),
                        plannedDepartureTime:
                            plannedDepartureTime == null
                                ? null
                                : this.getSeconds(
                                    DateTime.fromSeconds(plannedDepartureTime)
                                        .setZone('UTC')
                                        .toLocal()
                                        .toFormat('HH:mm:ss')
                                ),
                        ptcarUic,
                    }
                })

            return [defaultValue, ...forSelect]
        }
        if (activeLanguage.code === 'fr') {
            const forSelect = data.locations
                .filter(location => location.passThrough === false)
                .map(({ ptcarId, shortName, plannedArrivalTime, plannedDepartureTime, ptcarUic }) => ({
                    id: ptcarId,
                    text: shortName[0].translation,
                    plannedArrivalTime:
                        plannedArrivalTime == null
                            ? null
                            : this.getSeconds(
                                DateTime.fromSeconds(plannedArrivalTime)
                                    .setZone('UTC')
                                    .toLocal()
                                    .toFormat('HH:mm:ss')
                            ),
                    plannedDepartureTime:
                        plannedDepartureTime == null
                            ? null
                            : this.getSeconds(
                                DateTime.fromSeconds(plannedDepartureTime)
                                    .setZone('UTC')
                                    .toLocal()
                                    .toFormat('HH:mm:ss')
                            ),
                    ptcarUic,
                }))

            return [defaultValue, ...forSelect]
        }
    }
    getLabel = name => {
        const { activeLanguage } = this.props

        let fr = name && name[0] !== undefined ? name[0].translation : ''
        let nl = name && name[1] !== undefined ? name[1].translation : ''

        if (activeLanguage === undefined) return nl
        if (activeLanguage.code === 'nl') return nl
        return fr
    }

    getSearchLabel = name => {
        let fr = name && name[0] !== undefined ? name[0].translation : ''
        let nl = name && name[1] !== undefined ? name[1].translation : ''

        return `${nl} / ${fr}`
    }

    reduceLocations = data => {
        const defaultValue = { value: '', searchValue: '', label: 'Choose location', code: '' }

        if (data.length === 0 || data === undefined) return []
        const forSelect = data.map(({ id, name, code }) => ({
            value: id,
            label: this.getLabel(name),
            searchValue: this.getSearchLabel(name),
            code: code,
        }))
        return [defaultValue, ...forSelect]
    }

    loadLocations = async () => {
        let data = []
        data = await getAllLocations()
        const reducedData = this.reduceLocations(data)
        this.setState({ allLocations: reducedData })
    }

    loadPerformanceNumbers = async depot => {
        this.setState({ loadingPerformance: true })

        let data = []

        if (depot !== '') {
            data = await getPerformanceNumbers(depot)
        }

        this.setState({ performanceNumbersData: data, loadingPerformance: false, depotSymbolic: depot })
    }

    loadReferencePerformanceNumbers = async depot => {
        this.setState({ loadingReferencePerformance: true })

        let data = []

        if (depot !== '') {
            data = await getPerformanceNumbers(depot)
        }

        this.setState({ referencePerformanceNumbersData: data, loadingReferencePerformance: false })
    }

    setStartTime = value => {
        const { validateForm } = this.state
        this.setState({ startTime: value, validateForm: validateForm + 1 })
    }

    setEndTime = value => {
        const { validateForm } = this.state
        this.setState({ endTime: value, validateForm: validateForm + 1 })
    }

    handleLocationToSearchValue = e => {
        if (e === null) {
            this.setState({
                locationToSearchValue: '',
                locationTo: '',
            })
        } else {
            this.setState({
                locationToSearchValue: e.value,
                locationToSelected: e,
                locationTo: e.label,
                locationToPtCarId: e.value,
            })
        }
    }

    clearLocationToValue = () => {
        this.setState({
            locationToSearchValue: '',
            locationToSelected: '',
            locationTo: ' ',
        })
    }

    handleLocationSearchValue = e => {
        if (e === null) {
            this.setState({
                locationSearchValue: '',
                location: '',
            })
        } else {
            this.setState({
                locationSearchValue: e.value,
                locationSelected: e,
                location: e.label,
                locationPtcarId: e.value,
            })
        }
    }

    clearLocationValue = () => {
        this.setState({
            locationSearchValue: '',
            locationSelected: '',
            location: ' ',
        })
    }

    handleLocationFromSearchValue = e => {
        if (e === null) {
            this.setState({
                locationFromSearchValue: '',
                locationFrom: '',
            })
        } else {
            this.setState({
                locationFromSearchValue: e.value,
                locationFromSelected: e,
                locationFrom: e.label,
                locationFromPtCarId: e.value,
            })
        }
    }

    clearLocationFromValue = () => {
        this.setState({
            locationFromSearchValue: '',
            locationFromSelected: '',
            locationFrom: ' ',
        })
    }

    saveAndMapTask = () => {
        const {
            taskType,
            trainNumber,
            trainPrefix,
            comment,
            diagramNumber,
            startTime,
            endTime,
            locationFrom,
            locationFromPtCarId,
            location,
            locationPtcarId,
            locationTo,
            locationToPtCarId,
            referencedPerformance,
            selectedReferencePerformanceNumber,
            driveRole,
            trainComposed,
        } = this.state
        const { performanceId, idfNumber } = this.props.performanceDetail
        const isGroup11 =
            taskType.toUpperCase() === 'DRIVE' ||
            taskType.toUpperCase() === 'HLP' ||
            taskType.toUpperCase() === 'PERQUAI' ||
            taskType.toUpperCase() === 'UITGAR' ||
            taskType.toUpperCase() === 'TRANSFER'

        if (
            taskType === 'FREETEXT_P' ||
            taskType === 'FREETEXT_M' ||
            taskType === 'OPMRMQ' ||
            taskType === 'OPMREM' ||
            taskType === 'AFREL' ||
            taskType === 'AFRELDP' ||
            taskType === 'AFREL DP' ||
            taskType === 'AFLOSDP' ||
            taskType === 'AFLOS DP'
        ) {
            const updatedProps = {
                // IdfNumber: performanceId.length <= 1 ? null : performanceId,
                IdfNumber: idfNumber && idfNumber.length <= 1 ? null : idfNumber,
                Code: taskType.length <= 1 ? null : taskType,
                Comment: comment.length <= 1 ? null : comment,
                PlannedStartTime: startTime !== '' ? startTime.toFormat('HH:mm') : null,
                PlannedEndTime: startTime !== '' ? startTime.toFormat('HH:mm') : null,
                Location: location || null,
                LocationPtcarId: locationPtcarId || null,
                LocationFrom: locationFrom || null,
                locationFromPtCarId: locationFromPtCarId || null,
                LocationTo: locationTo || null,
                locationToPtCarId: locationToPtCarId || null,
                ReferencedPerformanceActualId: selectedReferencePerformanceNumber || null,
                TrainComposed: taskType === 'BUS' ? trainComposed : (trainPrefix + trainNumber).trim() || null,
                DiagramNumber: diagramNumber || null,
                lindaRole: isGroup11 ? driveRole : null,
            }
            this.setState({ editLoading: true, editError: '' })
            this.doUpdateCall(updatedProps, performanceId)
            return
        }
        const mappedProps = {
            // IdfNumber: performanceId.length <= 1 ? null : performanceId,
            IdfNumber: idfNumber && idfNumber.length <= 1 ? null : idfNumber,
            Code: taskType.length <= 1 ? null : taskType,
            Comment: comment.length <= 1 ? null : comment,
            PlannedStartTime: startTime !== '' ? startTime.toFormat('HH:mm') : null,
            PlannedEndTime: endTime !== '' ? endTime.toFormat('HH:mm') : null,
            Location: location || null,
            LocationPtcarId: locationPtcarId || null,
            LocationFrom: locationFrom || null,
            locationFromPtCarId: locationFromPtCarId || null,
            LocationTo: locationTo || null,
            locationToPtCarId: locationToPtCarId || null,
            ReferencedPerformanceActualId: referencedPerformance || null,
            TrainComposed: taskType === 'BUS' ? trainComposed : (trainPrefix + trainNumber).trim() || null,
            DiagramNumber: diagramNumber || null,
            lindaRole: isGroup11 ? driveRole : null,
        }
        this.setState({ editLoading: true, editError: '' })
        this.doUpdateCall(mappedProps, performanceId)
    }

    goBack = () => {
        const { history } = this.props
        history.goBack()
    }

    doUpdateCall = async (data, id) => {
        const { url, subscriptionKey } = requestData
        const { task } = this.props
        const fullUrl = `${url}/performance/${id}/task/${task.id}/update`
        try {
            const response = await axios.post(fullUrl, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.setState({ editLoading: false })
                this.props.clearPerformanceDetail()
                this.goBack()
            }
        } catch (e) {
            if (e.response.status === 400) {
                this.setState({ editError: getNormalizedMessage(e.response.data), editLoading: false })
            } else {
                this.setState({ editError: 'Task could not be saved', editLoading: false })
            }
        }
    }

    loadLindaRoles = async () => {
        let data = await getLindaRoles()

        if (data.length) {
            this.setState({ originalLindaRoles: data })
            const { activeLanguage } = this.props

            if (activeLanguage.code === 'nl') {
                const forSelect = data.map(({ id, description }) => ({
                    id: id,
                    text: description && description[1] !== undefined ? description[1].translation : '',
                }))

                this.setState({ lindaRoles: forSelect })
            }
            if (activeLanguage.code === 'fr') {
                const forSelect = data.map(({ id, description }) => ({
                    id: id,
                    text: description && description[0] !== undefined ? description[0].translation : '',
                }))

                this.setState({ lindaRoles: forSelect })
            }
        }
    }
    getRefrencePerformanceData() {
        let refId = this.state.referencedPerformance.performanceId
        let refValues = refId.split('-')
        let depot = refValues[2]
        let perfNum = refValues[1]
        this.setState({ referenceDepot: depot, referencedPerformance: perfNum })
    }
    componentDidMount() {
        window.addEventListener('resize', () => this.handleResize())
        const isGroup11 =
            this.props.task.code.toUpperCase() === 'DRIVE' ||
            this.props.task.code.toUpperCase() === 'HLP' ||
            this.props.task.code.toUpperCase() === 'PERQUAI' ||
            this.props.task.code.toUpperCase() === 'UITGAR' ||
            this.props.task.code.toUpperCase() === 'TRANSFER'

        this.loadLocations()
        if (isGroup11) {
            this.setTrainData(this.props.task)
            this.loadLindaRoles()
        }

        if (this.props.task.code.includes('AFREL') && this.props.task.referencedPerformance)
            this.getRefrencePerformanceData()
        const detail = this.props.performanceDetail
        document.title = `${this.props.task.code} -${detail.performanceNumber} ${detail.name ? detail.name : 'notAssigned'}`
    }

    componentWillUnmount() {
        const detail = this.props.performanceDetail
        document.title = `${detail.depot}-${detail.performanceNumber} ${detail.name ? detail.name : 'notAssigned'}`
    }
    handleResize() {
        this.setState({ isTablet: window.matchMedia('(max-width:1025px)').matches })
    }
    setDefault = type => props => {
        const toSetState = { [type]: props }
        this.setState(toSetState)
    }
    getStartTime = from => {
        if (from.passTroughTime) return setUTCTimeZone(from.passTroughTime)
        if (from.plannedDepartureTime) return setUTCTimeZone(from.plannedDepartureTime)
        return ''
    }
    getEndTime = to => {
        if (to.passTroughTime) return setUTCTimeZone(to.passTroughTime)
        if (to.plannedArrivalTime) return setUTCTimeZone(to.plannedArrivalTime)
        return ''
    }

    validateDiagramNumber = (diagramNumberValue) => {
        if(!diagramNumberValue.match(/[^A-Za-z0-9 ]/)){
            this.setState({diagramNumberError: false})
        }
        else {
            this.setState({diagramNumberError: true})
        }
    }

    persist = type => props => {
        const toSetState = { [type]: props }
        if (type !== 'driveRole') {
            if (type === 'taskType') {
                if (props.toUpperCase() === 'HLP')
                    toSetState.driveRole = this.state.originalLindaRoles.filter(role => role.lindaRoleCode === 'Tdsh')[0].id
                if (
                    props.toUpperCase() === 'DRIVE' ||
                    props.toUpperCase() === 'UITGAR' ||
                    props.toUpperCase() === 'PERQUAI' ||
                    props.toUpperCase() === 'TRANSFER'
                )
                    toSetState.driveRole = this.state.originalLindaRoles.filter(role => role.lindaRoleCode === 'Tdlm')[0].id
            }
            if (type === 'locationFrom') {
                const { trainLocations } = this.state
                const index = trainLocations.findIndex(x => x.text === props)
                const from = trainLocations[index]
                const startTime = this.getStartTime(from)
                const list = trainLocations.slice(index + 1, trainLocations.length)
                toSetState.trainLocationsTo = list
                toSetState.startTime = startTime
                toSetState.locationFromPtCarId = from.id
                toSetState.ptcarUicFrom = from.ptcarUic || ''
            }
            if (type === 'locationTo') {
                const { trainLocations } = this.state
                const index = trainLocations.findIndex(x => x.text === props)
                const to = trainLocations[index]
                const endTime = this.getEndTime(to)
                const list = trainLocations.slice(0, index)
                toSetState.trainLocationsFrom = list
                toSetState.locationToPtCarId = to.id
                toSetState.endTime = endTime
                toSetState.ptcarUicTo = to.ptcarUic || ''
            }

            if (type === 'trainNumber' || type === 'trainPrefix') {
                toSetState.trainLocations = []
                toSetState.trainLocationsTo = []
                toSetState.trainLocationsFrom = []
                toSetState.diagramNumber = ''
            }
            if (type === 'referenceOrg') {
                if (this.state.referenceOrg !== props) {
                    toSetState.referenceDepot = ' '
                    toSetState.selectedReferencePerformanceNumber = ' '
                }
            }
            if (type === 'referenceDepot') {
                if (this.state.referenceDepot !== props) toSetState.selectedReferencePerformanceNumber = ' '
            }
            if (type === 'selectedReferencePerformanceNumber') {
                toSetState.diagramNumber = ''
            }
            this.setState(toSetState)
        }

        this.setState(toSetState)
    }
    render() {
        const { translate, task, classes } = this.props
        const {
            taskType,
            comment,
            diagramNumber,
            startTime,
            endTime,
            allLocations,
            locationFromSearchValue,
            locationFromSelected,
            locationToSearchValue,
            locationToSelected,
            locationSearchValue,
            locationSelected,
            isTablet,
            locationFrom,
            locationTo,
        } = this.state
        const { performanceId, performanceNumber, depot, plannedStartTime, plannedEndTime } = this.props.performanceDetail
        let components = this.mapComponents()
        const {
            component1,
            //component2,
            component3,
            component4,
            component5,
            component6,
            component7,
            component8,
            component9,
            component10,
        } = components

        const filteredLocationToData = this.filterLocationToData(allLocations)
        const filteredLocationData = this.filterLocationData(allLocations)
        const filteredLocationFromData = this.filterLocationFromData(allLocations)

        const isBus = taskType.toUpperCase() === 'BUS'
        const relatedTasks = this.findTasktypesOfGroup(taskType)
        const sortedTasktypes = this.sortTaskTypes(relatedTasks)
        if (task.code.toUpperCase() === 'DRIVE') {
            for (var i in sortedTasktypes) {
                if (sortedTasktypes[i].id === 'DRIVE') {
                    sortedTasktypes[i].id = 'DRIVE'
                    break //Stop this loop, we found it!
                }
            }
        }
        // to disabled linda dropDown incase task is started or abolished
        let isTaskStarted = task.status !== 0 || task.isAbolished
        const isGroup11 =
            taskType.toUpperCase() === 'DRIVE' ||
            taskType.toUpperCase() === 'HLP' ||
            taskType.toUpperCase() === 'PERQUAI' ||
            taskType.toUpperCase() === 'UITGAR' ||
            taskType.toUpperCase() === 'TRANSFER'

        if (checkWriteAccess(this.props, 'add', 'performances.functionality.management.tasks')) {
            return (
                <React.Fragment>
                    <EditTaskView translate={translate} {...this.props}>
                        {performanceId !== null && performanceNumber !== null && depot !== null && (
                            <Grid container spacing={2}>
                                <Grid item xs={isTablet ? 6 : 2}>
                                    <Typography variant="h6">{`${translate(
                                        'documents_label_select_depot'
                                    )}: ${depot}`}</Typography>
                                </Grid>
                                <Grid item xs={isTablet ? 6 : 3}>
                                    <Typography variant="h6">{`${translate(
                                        'performance_info_label_performance_id'
                                    )}: ${performanceId}`}</Typography>
                                </Grid>
                                <Grid item xs={isTablet ? 6 : 3}>
                                    <Typography variant="h6">{`${translate(
                                        'performance_info_label_performance_number'
                                    )}: ${performanceNumber}`}</Typography>
                                </Grid>
                                <Grid item xs={isTablet ? 6 : 2}>
                                    <Typography variant="h6">{`${translate('start_time')}: ${plannedStartTime.substr(
                                        0,
                                        5
                                    )}`}</Typography>
                                </Grid>
                                <Grid item xs={isTablet ? 6 : 2}>
                                    <Typography variant="h6">{`${translate('end_time')}: ${plannedEndTime.substr(
                                        0,
                                        5
                                    )}`}</Typography>
                                </Grid>
                            </Grid>
                        )}
                        <React.Fragment>
                            <div className={classes.stepBorder} />
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {translate('performance_task_add_label_change_tasktype')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <div style={{ display: 'flex' }}>
                                        <Grid item xs={12}>
                                            <div style={{ display: 'inline-block' }}>
                                                <span> {translate('label_task')}</span>
                                                <SelectInput
                                                    name="taskType"
                                                    classes={classes}
                                                    items={sortedTasktypes}
                                                    value={taskType}
                                                    onChange={this.handleEvent('taskType')}
                                                />
                                            </div>
                                        </Grid>
                                        {isGroup11 && (
                                            <Grid item xs={12}>
                                                <div style={{ display: 'inline-block' }}>
                                                    <span> {translate('label_role')}</span>
                                                    <SelectInput
                                                        name="driveRole"
                                                        classes={classes}
                                                        items={this.state.lindaRoles}
                                                        value={this.state.driveRole}
                                                        onChange={this.handleEvent('driveRole')}
                                                        disabled={isTaskStarted}
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            <div className={classes.stepBorder} />
                        </React.Fragment>
                        {/* eslint-disable-next-line */}
                        {component8 && !isGroup11 && (
                            <React.Fragment>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <div style={{ display: 'inline-block', maxWidth: '100%' }}>
                                            <SelectInput
                                                name="trainPrefix"
                                                classes={classes}
                                                items={this.context.getTrainNumberPrefixes()}
                                                value={this.state.trainPrefix}
                                                onChange={this.handleEvent('trainPrefix')}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth={true}
                                            value={this.state.trainNumber}
                                            onChange={this.handleEvent('trainNumber')}
                                            label={
                                                isBus
                                                    ? translate('performance_task_add_label_select_bus')
                                                    : translate('add_task_train_number')
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <div className={classes.stepBorder} />
                            </React.Fragment>
                        )}
                        {/* eslint-disable-next-line */}
                        {component8 && isGroup11 && (
                            <Grid container spacing={2}>
                                <DriveTaskView
                                    isTablet={false}
                                    // loading={loading}
                                    loadTrain={this.loadTrain}
                                    {...this.state}
                                    persist={this.persist}
                                    {...this.props}
                                />
                            </Grid>
                        )}
                        <React.Fragment>
                            {this.checkStepToAllowDriveEditing(taskType) && (
                                <React.Fragment>
                                    <Grid item container spacing={2} xs={12}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                {translate('performance_task_add_label_general_data')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2} xs={12}>
                                        {component3 && (
                                            <Grid item xs={isTablet ? 6 : 4}>
                                                <IconBoxEdit icon="time">
                                                    <TimePickerWithKeyboard
                                                        label={translate('performance_info_label_from')}
                                                        value={startTime ? startTime : null}
                                                        onChange={this.setStartTime}
                                                        disabled={taskType === 'DRIVE' || taskType === 'HLP' ? true : false}
                                                    />
                                                </IconBoxEdit>
                                            </Grid>
                                        )}
                                        {component4 && (
                                            <Grid item xs={isTablet ? 6 : 4}>
                                                <IconBoxEdit icon="time">
                                                    <TimePickerWithKeyboard
                                                        label={translate('performance_info_label_till')}
                                                        value={endTime ? endTime : null}
                                                        onChange={this.setEndTime}
                                                        disabled={taskType === 'DRIVE' || taskType === 'HLP' ? true : false}
                                                    />
                                                </IconBoxEdit>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {(taskType.toUpperCase() === 'DRIVE' || taskType === 'HLP') &&
                                        this.state.trainLocations.length > 0 && (
                                            <Grid item container xs={12} spacing={2} style={{ marginTop: 16 }}>
                                                {component5 && (
                                                    <Grid item md={isTablet ? 6 : 4} xs={12}>
                                                        <div style={{ display: 'inline-block' }}>
                                                            <LocationDropDown
                                                                name="locationFrom"
                                                                classes={classes}
                                                                items={this.state.trainLocationsFrom}
                                                                value={locationFrom}
                                                                onChange={this.handleEvent('locationFrom')}
                                                            />
                                                        </div>
                                                    </Grid>
                                                )}

                                                {component6 && (
                                                    <Grid item md={isTablet ? 6 : 4} xs={12}>
                                                        <div style={{ display: 'inline-block' }}>
                                                            <LocationDropDown
                                                                name="locationTo"
                                                                classes={classes}
                                                                items={this.state.trainLocationsTo}
                                                                value={locationTo}
                                                                onChange={this.handleEvent('locationTo')}
                                                            />
                                                        </div>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        )}
                                </React.Fragment>
                            )}
                            {taskType.toUpperCase() !== 'DRIVE' &&
                                taskType !== 'HLP' &&
                                (component5 || component6 || component7) && (
                                    <Grid item container xs={12} spacing={2} style={{ marginTop: 16 }}>
                                        {component5 && (
                                            <Grid item xs={isTablet ? 6 : 4}>
                                                <Typography variant="subtitle1">From:</Typography>
                                                <LocationSearch
                                                    handleSearchValue={this.handleLocationFromSearchValue}
                                                    searchValue={locationFromSearchValue}
                                                    allLocations={allLocations}
                                                    clearValue={this.clearLocationFromValue}
                                                    data={filteredLocationFromData}
                                                    locationSelectValue={locationFromSelected}
                                                    doubleLangSearch={true}
                                                />
                                            </Grid>
                                        )}
                                        {component6 && (
                                            <Grid item xs={isTablet ? 6 : 4}>
                                                <Typography variant="subtitle1">To:</Typography>
                                                <LocationSearch
                                                    handleSearchValue={this.handleLocationToSearchValue}
                                                    searchValue={locationToSearchValue}
                                                    clearValue={this.clearLocationToValue}
                                                    data={filteredLocationToData}
                                                    allLocations={allLocations}
                                                    locationSelectValue={locationToSelected}
                                                    doubleLangSearch={true}
                                                />
                                            </Grid>
                                        )}
                                        {component7 && (
                                            <Grid item xs={isTablet ? 6 : 4}>
                                                <Typography variant="subtitle1">Location:</Typography>
                                                <LocationSearch
                                                    handleSearchValue={this.handleLocationSearchValue}
                                                    searchValue={locationSearchValue}
                                                    clearValue={this.clearLocationValue}
                                                    data={filteredLocationData}
                                                    allLocations={allLocations}
                                                    locationSelectValue={locationSelected}
                                                    doubleLangSearch={true}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                    // )
                                )}
                            {component9 && (
                                <Grid container spacing={2}>
                                    <DistrictsMapperNoFilter>
                                        <ReferencedPerformance
                                            persist={this.persist}
                                            referencePerformanceNumbersData={this.state.referencePerformanceNumbersData}
                                            loadReferencePerformanceNumbers={this.loadReferencePerformanceNumbers}
                                            loadPerformanceNumbers={this.loadPerformanceNumbers}
                                            selectedReferencePerformanceNumber={
                                                this.state.selectedReferencePerformanceNumber
                                            }
                                            setDefault={this.setDefault}
                                            {...this.state}
                                            {...this.props}
                                        />
                                    </DistrictsMapperNoFilter>
                                </Grid>
                            )}

                            {component10 && (
                                <Grid item container spacing={2} xs={12}>
                                    <Grid item xs={isTablet ? 6 : 4}>
                                        <TextField
                                            style={{ marginTop: '12px' }}
                                            fullWidth={true}
                                            value={diagramNumber}
                                            onChange={this.handleEventDiagramNumber('diagramNumber')}
                                            placeholder={translate('add_task_diagram_number')}
                                            label={translate('add_task_diagram_number')}
                                            error={this.state.diagramNumberError}
                                        />
                                        {/* eslint-disable-next-line max-len */}
                                        {this.state.diagramNumberError && <ErrorMessage error={translate('add_task_diagram_number_error')} spacing={false}/>}
                                    </Grid>
                                </Grid>
                            )}
                            {component1 && (
                                <Grid item container spacing={2} xs={12}>
                                    <Grid item xs={8}>
                                        <TextField
                                            fullWidth={true}
                                            value={comment}
                                            onChange={this.handleEvent('comment')}
                                            label={'comment'}
                                            placeholder={'Geef hier een commentaar in'}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </React.Fragment>
                    </EditTaskView>
                    <EditTaskFooter saveAndMapTask={this.saveAndMapTask} {...this.state} />
                </React.Fragment>
            )
        } else {
            return <NoRights />
        }
    }
}

EditTask.contextType = TrainNumberDataStore

export default compose(
    withStore,
    withLocalize,
    withRouter,
    withClear('clearPerformanceDetail', 'performanceDetail'),
    withStyles(styles)
)(EditTask)
