import React from 'react'
import { string, object, func, bool } from 'prop-types'
import { compose } from 'recompose'
import { DatePickerWithKeyboard } from 'components/Pickers'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'

import IconButton from 'components/IconButton'
import PrimaryButton from 'components/PrimaryButton'

import dateParser from 'utils/dateParser'

import styles from './styles'
import { TranslationContext } from 'context/translation'

class DateTimeRangePicker extends React.Component {
    static propTypes = {
        identifier: string.isRequired,
        classes: object.isRequired,
        startValue: object.isRequired,
        endValue: object.isRequired,
        persist: func.isRequired,
        disabled: bool,
    }

    static defaultProps = {
        disabled: false,
    }

    constructor(props) {
        super(props)
        this.state = {
            opened: false,
            toast: {
                open: false,
                msg: '',
            },
            start: props.startValue,
            end: props.endValue,
        }
    }

    open = () => this.setState({ opened: true })

    close = () => {
        const { start, end } = this.state
        this.setState({ opened: false }, () => this.props.persist([start, end]))
    }

    closeToast = () => this.setState({ toast: { open: false } })

    setStart = start => {
        const { end } = this.state
        if (end && start >= end)
            this.setState({
                toast: {
                    open: true,
                    msg: 'timepicker_error_start_before_end',
                },
            })
        else if (start.toMillis() > Date.now())
            this.setState({
                toast: { open: true, msg: 'timepicker_error_start' },
            })
        else this.setState({ start })
    }

    setEnd = end => {
        const { start } = this.state
        if (start >= end || end.toMillis() > Date.now())
            this.setState({
                toast: {
                    open: true,
                    msg: 'timepicker_error_end_after_start',
                },
            })
        else this.setState({ end })
    }

    render() {
        const { classes, disabled } = this.props
        const { opened, start, end } = this.state
        const { translate } = this.context

        return (
            <React.Fragment>
                <Typography variant="h6" className={classes.text}>
                    {translate('timepicker_all_reports')}
                    {opened ? (
                        <DatePickerWithKeyboard
                            data-testid="opend_state"
                            disableFuture={true}
                            value={start}
                            onChange={this.setStart}
                            format="dd/MM/yyyy"
                        />
                    ) : (
                        dateParser(start.toMillis())
                    )}
                    {translate('timepicker_till')}
                    {opened ? (
                        <DatePickerWithKeyboard
                            data-testid="opend_state"
                            disableFuture={true}
                            value={end}
                            onChange={this.setEnd}
                            format="dd/MM/yyyy"
                        />
                    ) : (
                        dateParser(end.toMillis())
                    )}
                </Typography>
                {opened ? (
                    <PrimaryButton
                        data-testid="opend_state"
                        className={classes.button}
                        title="Wijziging toepassen"
                        onClick={this.close}
                    />
                ) : (
                    <IconButton
                        data-testid="open_button"
                        className={classes.button}
                        icon="event"
                        title={translate('button_change')}
                        onClick={this.open}
                        disabled={disabled}
                    />
                )}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.toast.open}
                    autoHideDuration={2500}
                    onClose={this.closeToast}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.toast.msg ? translate(this.state.toast.msg) : ''}</span>}
                />
            </React.Fragment>
        )
    }
}

DateTimeRangePicker.contextType = TranslationContext

export default compose(withStyles(styles))(DateTimeRangePicker)
