import React from 'react'
import { string, any, oneOfType, object } from 'prop-types'

// Components
import PrimaryButton from 'components/PrimaryButton'

import iconMap from './util/iconMap'

const IconButton = ({ title, icon, children, ...props }) => {
    return (
        <PrimaryButton color="primary" variant="contained" {...props}>
            {iconMap[icon]}
            {!!title && title}
            {children}
        </PrimaryButton>
    )
}

IconButton.propTypes = {
    title: oneOfType([string, object]).isRequired,
    icon: string.isRequired,
    children: any,
}

export default IconButton
