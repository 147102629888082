import React from 'react'
import { object } from 'prop-types'
import { compose } from 'recompose'
import { Route, Switch, withRouter } from 'react-router-dom'
import { withStore, withStatus, enums } from 'react-ion-store'
import { withLocalize, Translate } from 'react-localize-redux'

import Loading from 'components/Loading'
import Header from 'components/Header'

import PerformanceOverview from './views/PerformanceOverview'
import PerformanceDetail from './views/PerformanceDetail'
import Districts from './views/Districts'

import { toDateTime } from 'utils/dateParser'
import { isPerformanceMenuAllowed } from 'utils/isAgendaAllowed'
import { TrainNumberDataProvider } from 'context/trainNumberData'
const {
    CallStatus: { LOADING, FAILED },
} = enums

const Failed = () => <div>Error retrieving Roles!</div>

class Performances extends React.Component {
    state = {
        intervalStart: toDateTime(Date.now()),
        // date: toDateTime(Date.now()),
        performanceDrawer: false,
        reload: false,
        refreshRate: true, // fake state for the update
    }

    persist = type => props => {
        const toSetState = { [type]: props }
        this.setState(toSetState)
    }
    onAlertSwitched() {
        this.setState({ reload: true }) // to reload the datetimepicker component
    }
    resetInterval = () => {
        this.setState(prevState => ({
            refreshRate: !prevState.refreshRate,
        }))
    }

    render() {
        const { store, history, location, match, __ion_status } = this.props
        const { userRoles } = __ion_status

        const { moduleList, selectedDistrict } = store.get(['moduleList', 'selectedDistrict'])
        const isDriver = isPerformanceMenuAllowed(store.get('userRoles'))
        const rolesLoading = !!userRoles && userRoles === LOADING
        const rolesFailed = !!userRoles && userRoles === FAILED
        if (rolesFailed)
            return (
                <React.Fragment>
                    <Failed />
                </React.Fragment>
            )
        else if (rolesLoading)
            return (
                <React.Fragment>
                    <Loading />
                </React.Fragment>
            )
        else if (!!moduleList && !moduleList.includes('/performances')) {
            history.replace(`/`)
        } else if (
            location.pathname !== `${match.path}/districts` &&
            !selectedDistrict &&
            location.pathname.indexOf('/detail') === -1 &&
            location.pathname.indexOf('/profile') === -1 &&
            location.pathname.indexOf('/task') === -1
        )
            history.replace(`${match.path}/districts`)

        return (
            <TrainNumberDataProvider>
                <React.Fragment>
                    <Translate>
                        {({ translate }) => {
                            if (
                                location.pathname !== `${match.path}/districts` &&
                                location.pathname.indexOf('/detail') === -1 &&
                                location.pathname.indexOf('/profile') === -1
                            )
                                return (
                                    <Header
                                        title={`District ${translate(selectedDistrict || '')}`}
                                        returnPath={`${match.path}/districts`}
                                        onAlertSwitched={() => this.onAlertSwitched()}
                                        pageTitle="performanceOverview"
                                        refreshNotRequired={false}
                                    />
                                )
                            else if (
                                location.pathname.indexOf('/detail') !== -1 &&
                                location.pathname.indexOf('/notAssigned') !== -1 &&
                                location.pathname.indexOf('/profile') === -1 &&
                                location.pathname.indexOf('/task') === -1
                            )
                                return (
                                    <Header
                                        title={translate('module_performances')}
                                        returnPath={`${match.path}/districts`}
                                        pageTitle="taskDetail"
                                        resetIntervalTaskDetail={this.resetInterval}
                                        refreshNotRequired={false}
                                    />
                                )
                            else if (
                                location.pathname.indexOf('/detail') !== -1 &&
                                location.pathname.indexOf('/profile') !== -1 &&
                                location.pathname.indexOf('/task') === -1
                            )
                                if (isDriver) {
                                    return (
                                        <Header
                                            isDriver={true}
                                            title={translate('module_performances')}
                                            returnPath={`${match.path}/districts`}
                                        />
                                    )
                                } else {
                                    return (
                                        <Header
                                            title={translate('module_performances')}
                                            returnPath={`${match.path}/districts`}
                                            pageTitle="taskDetail"
                                            resetIntervalTaskDetail={this.resetInterval}
                                            refreshNotRequired={false}
                                        />
                                    )
                                }
                            else if (
                                location.pathname.indexOf('/detail') !== -1 &&
                                location.pathname.indexOf('/profile') !== -1 &&
                                location.pathname.indexOf('/task') !== -1
                            )
                                return (
                                    <Header
                                        title={translate('module_performances')}
                                        returnPath={location.pathname.substring(0, location.pathname.lastIndexOf('/task'))}
                                    />
                                )
                            else if (
                                location.pathname.indexOf('/detail') !== -1 &&
                                location.pathname.indexOf('/notAssigned') !== -1 &&
                                location.pathname.indexOf('/task') !== -1
                            )
                                return (
                                    <Header
                                        title={translate('module_performances')}
                                        returnPath={location.pathname.substring(0, location.pathname.lastIndexOf('/task'))}
                                    />
                                )
                            else return <Header isMenu title={translate('module_performances')} refreshNotRequired={false} />
                        }}
                    </Translate>
                    <Switch>
                        <Route
                            exact
                            path={`${match.path}`}
                            render={props => (
                                <PerformanceOverview
                                    {...props}
                                    persist={this.persist}
                                    // timepickerDate={this.state.date}
                                    performanceDrawer={this.state.performanceDrawer}
                                    reload={this.state.reload}
                                />
                            )}
                        />
                        <Route exact path={`${match.path}/districts`} component={Districts} />
                        <Route
                            path={`${match.path}/detail/:performanceId/profile/:idfNumber`}
                            render={() => <PerformanceDetail refreshRate={this.state.refreshRate} />}
                        />
                        <Route
                            path={`${match.path}/detail/:performanceId/notAssigned`}
                            render={() => <PerformanceDetail refreshRate={this.state.refreshRate} />}
                        />
                    </Switch>
                </React.Fragment>
            </TrainNumberDataProvider>
        )
    }
}

Performances.propTypes = {
    history: object.isRequired,
    location: object.isRequired,
    match: object.isRequired,
    __ion_status: object.isRequired,
    store: object.isRequired,
}

export default compose(withRouter, withStore, withStatus, withLocalize)(Performances)
