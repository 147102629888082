import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { DateTime } from 'luxon'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withStore } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Divider, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import TaskDetailView from './TaskDetailView'
import IconBox from './components/_shared/IconBox'
import IconButton from '../../../../components/IconButton'
import DriveDetails from './components/DriveDetails'
import ContactInformation from './components/ContactInformation'
import calculateTimeDifferenceWithTimestamps from 'utils/calculateTimeDifferenceWithTimestamps'
import styles from './styles'
import Loading from 'components/Loading'

class TaskDetail extends Component {
    static propTypes = {
        plannedStartTime: PropTypes.string,
        plannedEndTime: PropTypes.string,
        plannedStartDateTime: PropTypes.string,
        plannedEndDateTime: PropTypes.string,
        realStartDateTime: PropTypes.string,
        realEndDateTime: PropTypes.string,
        trainComposed: PropTypes.string,
        duration: PropTypes.string,
        reasonStartTimeChanged: PropTypes.string,
        reasonEndTimeChanged: PropTypes.string,
        signOutTime: PropTypes.string,
        task: PropTypes.object.isRequired,
        classes: PropTypes.object,
        history: PropTypes.object,
        tasks: PropTypes.array,
        translate: PropTypes.func,
        performanceId: PropTypes.string,
        idfNumber: PropTypes.string,
        trainNumber: PropTypes.string,
        trainNumberPrefix: PropTypes.string,
        brakingReportAvailable: PropTypes.bool,
        store: PropTypes.object,
        activeLanguage: PropTypes.object,
        performanceDate: PropTypes.number,
        isDriveDetailsLoading: PropTypes.bool,
        driveDetails: PropTypes.array,
        refreshAfterBulkOperation: PropTypes.func,
    }

    state = {
        expanded: null,
        isTablet: window.matchMedia('(max-width:1025px)').matches,
        lindaRegistrationExpanded: null,
        lindaActionData: null,
    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        })
    }

    printRealStartTime = (realStartTime, plannedStartTime, realStartDateTime, plannedStartDateTime) => {
        const { classes } = this.props
        if (!realStartTime && !plannedStartTime) {
            return '--:--'
        }
        if (!realStartTime && plannedStartTime) {
            return DateTime.fromFormat(plannedStartTime, 'HH:mm:ss').toFormat('HH:mm')
        }
        if (realStartTime) {
            const diff = calculateTimeDifferenceWithTimestamps(plannedStartDateTime, realStartDateTime)
            const check = Math.sign(diff)

            if (check === 0) {
                return DateTime.fromFormat(realStartTime, 'HH:mm:ss').toFormat('HH:mm')
            }
            if (check === 1) {
                return (
                    <React.Fragment>
                        <span className={classes.crossedTime}>
                            {DateTime.fromFormat(plannedStartTime, 'HH:mm:ss').toFormat('HH:mm')}
                        </span>
                        {`  ${DateTime.fromSeconds(realStartDateTime).toFormat("HH:mm")} (+${diff})`}
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <span className={classes.crossedTime}>
                            {DateTime.fromFormat(plannedStartTime, 'HH:mm:ss').toFormat('HH:mm')}
                        </span>
                        {`  ${DateTime.fromSeconds(realStartDateTime).toFormat("HH:mm")} (${diff})`}
                    </React.Fragment>
                )
            }
        }
    }

    printRealEndTime = (realEndTime, plannedEndTime, realEndDateTime, plannedEndDateTime) => {
        const { classes } = this.props
        if (!realEndTime && !plannedEndTime) {
            return '--:--'
        }
        if (!realEndTime && plannedEndTime) {
            return DateTime.fromFormat(plannedEndTime, 'HH:mm:ss').toFormat('HH:mm')
        }
        if (realEndTime) {
            const diff = calculateTimeDifferenceWithTimestamps(plannedEndDateTime, realEndDateTime)
            const check = Math.sign(diff)

            if (check === 0) {
                return DateTime.fromFormat(realEndTime, 'HH:mm:ss').toFormat('HH:mm')
            }
            if (check === 1) {
                return (
                    <React.Fragment>
                        <span className={classes.crossedTime}>
                            {DateTime.fromFormat(plannedEndTime, 'HH:mm:ss').toFormat('HH:mm')}
                        </span>
                        {`  ${DateTime.fromSeconds(realEndDateTime).toFormat("HH:mm")} (+${diff})`}
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <span className={classes.crossedTime}>
                            {DateTime.fromFormat(plannedEndTime, 'HH:mm:ss').toFormat('HH:mm')}
                        </span>
                        {`  ${DateTime.fromSeconds(realEndDateTime).toFormat("HH:mm")} (${diff})`}
                    </React.Fragment>
                )
            }
        }
    }

    componentDidUpdate = () => {
        const { history } = this.props

        if (
            this.state.isUpdated &&
            history &&
            history.location.state.updated !== undefined &&
            history.location.state.updated === true
        ) {
            window.location.reload()
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.handleResize())
    }
    handleResize() {
        this.setState({ isTablet: window.matchMedia('(max-width:1025px)').matches })
    }

    getStartTask(task) {
        return task.code === 'START'
    }
    getEndTask(task) {
        return task.code === 'END'
    }

    getGridColumnItem = code => {
        return code ? 3 : 4
    }

    checkExpansionPanelForChilds = () => {
        const { comment, location, locationFrom, locationTo, referencedPerformance, code } = this.props.task

        const checkRef = referencedPerformance && referencedPerformance !== null ? true : false

        return !(
            !comment &&
            !location &&
            !locationFrom &&
            !locationTo &&
            code !== 'FREETEXT_P' &&
            code !== 'FREETEXT_M' &&
            code !== 'OPMRMQ' &&
            code !== 'OPMREM' &&
            !checkRef
        )
    }

    render() {
        const {
            plannedStartTime,
            plannedEndTime,
            realStartTime,
            realEndTime,
            plannedStartDateTime,
            plannedEndDateTime,
            realStartDateTime,
            realEndDateTime,
            trainComposed,
            comment,
            location,
            locationFrom,
            locationTo,
            referencedPerformance,
            code,
            taskInfo,
        } = this.props.task

        const { duration, reasonStartTimeChanged, reasonEndTimeChanged, signOutTime } = this.props
        const { expansionPanelDetails, content, panelRoot } = this.props.classes
        const { expanded, isTablet } = this.state
        // startTask and endTask is required to show updated time as we don't have this info under the task,
        // thus calculating this info from list of tasks
        // getStartTask and getEndTask returns an array with one task so using 0 to get the only object
        const startTask = this.props.tasks.filter(task => this.getStartTask(task))[0]
        const endTask = this.props.tasks.filter(task => this.getEndTask(task))[0]

        const isGroup11 =
            code.toUpperCase() === 'DRIVE' ||
            code.toUpperCase() === 'HLP' ||
            code.toUpperCase() === 'PERQUAI' ||
            code.toUpperCase() === 'UITGAR' ||
            code.toUpperCase() === 'TRANSFER'
        const gridItem = isTablet ? 6 : this.getGridColumnItem(isGroup11)
        return (
            <TaskDetailView {...this.props}>
                <Grid container spacing={2}>
                    {plannedStartTime && (
                        <Grid item xs={gridItem}>
                            <IconBox
                                icon="time"
                                title={'datatable_header_task_start'}
                                // eslint-disable-next-line max-len
                                value={this.printRealStartTime(realStartTime, plannedStartTime, realStartDateTime, plannedStartDateTime)}
                            />
                            {reasonStartTimeChanged && code === 'START' && (
                                <React.Fragment>
                                    <Typography style={{ marginTop: '10px' }}>{reasonStartTimeChanged}</Typography>
                                    <Divider />
                                </React.Fragment>
                            )}
                        </Grid>
                    )}
                    {startTask && code === 'END' && (
                        <Grid item xs={gridItem}>
                            <IconBox
                                icon="time"
                                title={'datatable_header_task_start'}
                                // eslint-disable-next-line max-len
                                value={this.printRealStartTime(startTask.realStartTime, startTask.plannedStartTime, startTask.realStartDateTime, startTask.plannedStartDateTime)}
                            />
                        </Grid>
                    )}
                    {plannedEndTime && (
                        <Grid item xs={gridItem}>
                            <IconBox
                                icon="time"
                                title={'datatable_header_task_end'}
                                // eslint-disable-next-line max-len
                                value={this.printRealEndTime(realEndTime, plannedEndTime, realEndDateTime, plannedEndDateTime)}
                            />

                            {reasonEndTimeChanged && code === 'END' && (
                                <React.Fragment>
                                    <Typography style={{ marginTop: '10px' }}>{reasonEndTimeChanged}</Typography>
                                    <Divider />
                                </React.Fragment>
                            )}
                        </Grid>
                    )}

                    {endTask && code === 'START' && (
                        <Grid item xs={gridItem}>
                            <IconBox
                                icon="time"
                                title={'datatable_header_task_end'}
                                // eslint-disable-next-line max-len
                                value={this.printRealEndTime(endTask.realEndTime, endTask.plannedEndTime, endTask.realEndDateTime, endTask.plannedEndDateTime)}
                            />
                        </Grid>
                    )}

                    {duration && (code === 'START' || code === 'END') && (
                        <Grid item xs={gridItem}>
                            <IconBox
                                icon="duration"
                                title={'performance_info_label_duration'}
                                value={duration.substr(0, 5)}
                            />
                        </Grid>
                    )}

                    {trainComposed && (
                        <Grid item xs={gridItem}>
                            <IconBox
                                icon="train"
                                title={'performances_label_performance_detail_train_number'}
                                value={trainComposed}
                            />
                        </Grid>
                    )}
                    {isGroup11 && this.props?.task?.lindaRoleCode && (
                        <Grid item xs={gridItem}>
                            <IconBox
                                icon="person"
                                title={'datatable_header_task_details_linda_role'}
                                value={this.props.task.lindaRoleCode}
                            />
                        </Grid>
                    )}
                </Grid>

                {this.props.isDriveDetailsLoading && <Loading />}
                {!this.props.isDriveDetailsLoading && this.props.driveDetails.length > 0 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DriveDetails
                                driveDetails={this.props.driveDetails}
                                lastRegisterPtcar={this.props.task.lastRegisterPtcar}
                            />
                        </Grid>
                    </Grid>
                )}

                {this.checkExpansionPanelForChilds() && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginTop: '12px' }}>
                            <Accordion
                                classes={{ root: panelRoot }}
                                expanded={expanded === 'generalInfo'}
                                onChange={this.handleChange('generalInfo')}
                            >
                                <AccordionSummary classes={{ content: content }} expandIcon={<ExpandMoreIcon />}>
                                    <Typography>
                                        <Translate id="performances_label_performance_detail_general_info" />
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails className={expansionPanelDetails}>
                                    <React.Fragment>
                                        <Grid container spacing={2}>
                                            {referencedPerformance && Object.keys(referencedPerformance).length > 0 && (
                                                <ContactInformation
                                                    {...this.props}
                                                    referencedPerformance={referencedPerformance}
                                                />
                                            )}

                                            {(code === 'FREETEXT_P' ||
                                                code === 'FREETEXT_M' ||
                                                code === 'OPMRMQ' ||
                                                code === 'OPMREM') && (
                                                <Grid item xs={12}>
                                                    <IconButton
                                                        disabled={signOutTime !== null}
                                                        style={{ marginTop: '18px', textAlign: 'left' }}
                                                        icon="info"
                                                        color="secondary"
                                                        title={comment || taskInfo}
                                                        // onClick={() => {}}
                                                    />
                                                </Grid>
                                            )}

                                            {location && (
                                                <Grid item md={isTablet ? 6 : 4} xs={12}>
                                                    <IconBox
                                                        icon="location"
                                                        title={'performances_label_performance_detail_location'}
                                                        value={location}
                                                    />
                                                </Grid>
                                            )}

                                            {locationFrom && (
                                                <Grid item md={isTablet ? 6 : 4} xs={12}>
                                                    <IconBox
                                                        icon="location"
                                                        title={'performances_label_performance_detail_location_from'}
                                                        value={locationFrom}
                                                    />
                                                </Grid>
                                            )}

                                            {locationTo && (
                                                <Grid item md={isTablet ? 6 : 4} xs={12}>
                                                    <IconBox
                                                        icon="location"
                                                        title={'performances_label_performance_detail_location_to'}
                                                        value={locationTo}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                )}
            </TaskDetailView>
        )
    }
}
export default compose(withLocalize, withStore, withRouter, withStyles(styles))(TaskDetail)
