import React from 'react'
import { object, string, func } from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
// Components
import IconButton from 'components/IconButton'
import AppPaper from 'components/AppPaper'
import { Typography, Radio, FormControlLabel } from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

class DocumentsFooterView extends React.Component {
    static propTypes = {
        classes: object.isRequired,
        remark: string,
        documentsFilter: string,
        formValues: object,
        footerData: object,
        persist: func,
        store: object,
        date: string,
    }

    state = {
        downloading: false,
        downloadError: '',
    }

    openStream = () => {
        const { footerData } = this.props
        if (footerData !== undefined) {
            this.setState({ downloading: true, downloadError: '' })
            this.doExportCall(footerData.data[0])
        }
    }

    doExportCall = async callData => {
        const { url, subscriptionKey } = requestData
        const { formValues, date } = this.props
        //eslint-disable-next-line
        let fullUrl = `${url}/document/${callData.fileId}/${callData.version}/profiles?Sort=firstName&SortDirection=asc&export=true&Filter=${this.props.documentsFilter}`

        if (formValues.radio === 'trainDriver') {
            //eslint-disable-next-line
            fullUrl = `${url}/profile/${callData.idfNumber}/documents?date=${date}&Sort=firstName&SortDirection=asc&export=true&Filter=${this.props.documentsFilter}`
        }

        let data = []

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                    responseType: 'blob',
                },
            })

            if (response.status === 200) {
                data = response.data
                const urlWithBolb = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = urlWithBolb
                link.setAttribute('download', 'document.csv')
                document.body.appendChild(link)
                link.click()
                link.remove()
                this.setState({ downloading: false })
            }
        } catch (e) {
            if (e.response.status === 400) {
                this.setState({ downloadError: e.response.data.Message, downloading: false })
            } else {
                this.setState({ downloadError: 'Download failed', downloading: false })
            }
        }
    }
    render() {
        const { classes, persist, documentsFilter } = this.props
        const { downloadError, downloading } = this.state

        return (
            <AppPaper className={classes.root}>
                <Translate>
                    {({ translate }) => (
                        <RadioGroup style={{ display: 'inline-block' }}>
                            <FormControlLabel
                                value="all"
                                control={
                                    <Radio
                                        checked={documentsFilter === 'All'}
                                        onChange={() => persist('documentsFilter')('All')}
                                        value="All"
                                        name="FilterAll"
                                        color="primary"
                                    />
                                }
                                label={translate('documents_radio_all')}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="all"
                                control={
                                    <Radio
                                        checked={documentsFilter === 'AllRead'}
                                        onChange={() => persist('documentsFilter')('AllRead')}
                                        value="AllRead"
                                        name="FilterAllRead"
                                        color="primary"
                                    />
                                }
                                label={translate('documents_radio_read')}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="all"
                                control={
                                    <Radio
                                        checked={documentsFilter === 'AllUnread'}
                                        onChange={() => persist('documentsFilter')('AllUnread')}
                                        value="AllUnread"
                                        name="FilterAllUnread"
                                        color="primary"
                                    />
                                }
                                label={translate('documents_radio_not_read')}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    )}
                </Translate>
                <Translate>
                    {({ translate }) => (
                        <React.Fragment>
                            <IconButton
                                icon="download"
                                color="primary"
                                title={downloading ? 'downloading...' : translate('button_export')}
                                disabled={
                                    checkFunctionalityDisabled(
                                        this.props.store,
                                        'exportReport',
                                        'documents.functionality.management.documents'
                                    ) || downloading
                                }
                                onClick={() => {
                                    this.openStream()
                                }}
                            />

                            {downloadError && downloadError.length !== 0 && (
                                <Typography style={{ color: 'red', paddingLeft: '18px' }}>{downloadError}</Typography>
                            )}
                        </React.Fragment>
                    )}
                </Translate>
            </AppPaper>
        )
    }
}

export default withRouter(DocumentsFooterView)
