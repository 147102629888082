export default {
    map_container: {
        position: 'relative',
        display: 'block',
        textAlign: 'center',
        maxWidth: '700px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    map_container_img: { maxWidth: '700px', height: 'auto', textAlign: 'center' },
}
