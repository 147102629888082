import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withStore, withStatus, enums, util } from 'react-ion-store'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'
import DocumentsView from './DocumentsView'

import { toDateTime, apiDateParser } from 'utils/dateParser'

import styles from './styles'
import { TranslationContext } from 'context/translation'

const {
    CallStatus: { LOADING, FAILED },
} = enums

const Failed = () => <div>Error retrieving Roles!</div>

const resetState = {
    selectedName: '',
    selectedProfile: '',
}
class Documents extends React.Component {
    static propTypes = {
        store: PropTypes.object,
        history: PropTypes.object,
        match: PropTypes.object,
        __ion_status: PropTypes.object,
        clearDocumentSearchResults: PropTypes.func,
    }
    state = {
        radio: 'document',
        date: toDateTime(Date.now()),
        org: ' ',
        depot: ' ',
        doc: ' ',
        map: ' ',
        idf: {},
        file: '',
        drawerOpen: false,
        filterDrawerOpen: false,
        documentsFilter: 'All',
        selectedRadio: 0,
        read: true,
        unread: false,
        selectedName: '',
        selectedProfile: '',
        loading: false,
    }

    changeRadio = value => {
        let type
        // this.props.clearDocumentSearchResults()

        if (value === 0) type = 'document'
        else type = 'trainDriver'
        this.setState({ selectedRadio: value, radio: type })
    }

    persist = type => props => {
        const toSetState = { [type]: props }
        if (type === 'org') {
            toSetState.depot = ' '
            this.setState(toSetState)
            return
        }

        if (type === 'document') toSetState.map = ' '

        if (type === 'documentsFilter') {
            //save new sort to store
            let params = this.props.store.get('params')

            if (params === undefined) {
                params = { Sort: 'idfNumber', SortDirection: 'asc', filter: props }
            } else {
                params.filter = props
            }
            this.props.store.set({ params: params })
            //refetch data
            //this.props.store.call('documentsDetailViewData')
            this.setState(toSetState)
            return
        }
        this.setState(toSetState)
    }

    initCall = props => {
        this.setState({ loading: true })
        const { idfNumber, SearchBy, Sort, SortDirection, Filter, FolderId, OrganisationId, SearchDocument, date } = props

        const callData = util.CreateStoreObject({
            idfNumber,
            SearchBy,
            Sort,
            SortDirection,
            Filter,
            FolderId,
            OrganisationId,
            SearchDocument,
            date: date.toFormat('ddMMyyyy'),
        })

        const documentSearchResults = util.CreateStoreObject(undefined)
        this.props.store.call({ callData, documentSearchResults })

        this.setState({ filterDrawerOpen: false, loading: false })
    }
    componentDidMount() {
        document.title = this.context.translate('module_documents')
    }
    setDetailView = props => {
        const { type, data } = props

        const documentsDetailViewData = util.CreateStoreObject(undefined)
        const documentsDetailView = util.CreateStoreObject({
            type,
            data,
        })

        this.props.store.call({ documentsDetailView, documentsDetailViewData })

        const { history, match } = this.props
        history.push(`${match.path}/detail`)
    }

    clearDetailView = () => {
        const documentsDetailViewData = util.CreateStoreObject(undefined)
        this.props.store.call({ documentsDetailViewData })
    }
    handleCheckbox = event => {
        if (event.target.value === 'read') {
            this.setState(prevState => ({
                read: this.state.unread ? !prevState.read : prevState.read,
            }))
        }
        if (event.target.value === 'unread') {
            this.setState(prevState => ({
                unread: this.state.read ? !prevState.unread : prevState.unread,
            }))
        }
    }
    setName = selectedProfile => {
        this.setState({
            selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
            selectedProfile: selectedProfile,
        })
    }
    clearName = () => this.setState(resetState)
    setDate = date => {
        this.setState({ date: date })
    }

    checkButtonDisable = () => {
        if (this.state.loading) return true
        if (this.state.radio === 'document') {
            if (this.state.doc === ' ' || this.state.map === ' ') return true
        } else if (this.state.radio === 'trainDriver') {
            if (this.state.selectedName.length <= 0) return true
        }
        return false
    }

    sanitize = value => {
        if (value === undefined) {
            return null
        }

        if (value === '') {
            return null
        }

        if (value === ' ') {
            return null
        }

        return value
    }

    readFilterValue = (read, unread) => {
        if (read && !unread) {
            return 'AllRead'
        }
        if (!read && unread) {
            return 'AllUnread'
        }
        return 'All'
    }

    getSelectedOrg = (org, depot, type) => {
        if (org === ' ' && depot === ' ' && type === 'document') {
            return 2
        }
        if (org === ' ' && depot === ' ' && type !== 'document') {
            return null
        }

        if (org !== ' ' && depot === ' ') {
            return org
        }

        if (depot !== ' ') {
            return depot
        }
    }
    onClick = () => {
        const { history } = this.props
        this.clearDetailView()
        history.push(`/documents`)
        this.initCall({
            idfNumber: this.sanitize(this.state.selectedProfile.idfNumber),
            SearchBy: this.state.radio,
            Sort: 'fileName',
            SortDirection: 'asc',
            Filter: this.readFilterValue(this.state.read, this.state.unread),
            FolderId: this.sanitize(this.state.map),
            OrganisationId: this.getSelectedOrg(this.state.org, this.state.depot, this.state.radio),
            SearchDocument: this.sanitize(this.state.file),
            date: this.state.date,
        })
    }

    render() {
        const { store, __ion_status, history } = this.props
        const { userRoles } = __ion_status

        const moduleList = store.get('moduleList')
        const { documents: documentRoles } = store.get('roles') || { documents: {} }

        const rolesLoading = !!userRoles && userRoles === LOADING
        const rolesFailed = !!userRoles && userRoles === FAILED

        const button = this.checkButtonDisable()

        if (rolesFailed)
            return (
                <React.Fragment>
                    <Failed />
                </React.Fragment>
            )
        else if (rolesLoading)
            return (
                <React.Fragment>
                    <Loading />
                </React.Fragment>
            )
        else if (!!moduleList && !moduleList.includes('/documents')) {
            history.replace(`/`)
        }

        return (
            <DocumentsView
                {...this.props}
                persist={this.persist}
                initCall={this.initCall}
                setDetailView={this.setDetailView}
                clearDetailView={this.clearDetailView}
                footerData={this.props.store.get('documentsDetailView')}
                documentsFilter={this.state.documentsFilter}
                formValues={this.state}
                documentRoles={documentRoles}
                reqDate={apiDateParser(this.state.date.toMillis())}
                selectedRadio={this.state.selectedRadio}
                changeRadio={this.changeRadio}
                read={this.state.read}
                unread={this.state.unread}
                handleCheckbox={this.handleCheckbox}
                org={this.state.org}
                depot={this.state.depot}
                setName={this.setName}
                file={this.state.file}
                selectedName={this.state.selectedName}
                clearName={this.clearName}
                onClick={this.onClick}
                buttonDisabled={button}
                setDate={this.setDate}
                isOpen={this.state.drawerOpen}
                filterDrawerOpen={this.state.filterDrawerOpen}
            />
        )
    }
}

Documents.contextType = TranslationContext

export default compose(
    withRouter,
    withStyles(styles),
    withStore,
    withStatus
    // withClear('clearDocumentSearchResults', 'documentSearchResults')
)(Documents)
