import Loading from 'components/Loading'
import React from 'react'
import { withStore } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { SemesView } from './SemesView'
import { getSemesFromPerformance, getSemesFromPerformanceTask } from './service.semesview'

import { object } from 'prop-types'

import { TranslationContext } from 'context/translation'
import TaskSemesProvider from './semes.store'

const Semes = props => {
    // eslint-disable-next-line no-unused-vars
    const [hasData, setHasData] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState([])

    const { translate } = React.useContext(TranslationContext)

    React.useEffect(() => {
        const { match } = props
        if (match.path.includes('/task/')) {
            getSemesFromPerformanceTask(match.params.performanceId, match.params.taskIndex, match.params.idfNumber).then(
                res => {
                    if (res.data.length > 0) {
                        setData(res.data)
                        setHasData(true)
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                }
            )
        } else {
            getSemesFromPerformance(match.params.performanceId, match.params.idfNumber).then(res => {
                if (res.data.length > 0) {
                    setData(res.data)
                    setHasData(true)
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
        }
    }, [])

    if (loading) {
        return <Loading />
    } else {
        return hasData ? (
            <TaskSemesProvider data={data}>
                <SemesView />
            </TaskSemesProvider>
        ) : (
            <p>{translate('semes_no_messages')}</p>
        )
    }
}

Semes.propTypes = {
    match: object,
}

export default compose(withStore, withLocalize, withRouter)(Semes)
