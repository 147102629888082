import React from 'react'
import { object, string } from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from 'components/AppPaper'

import styles from './styles'

const CalendarHeader = ({ classes, firstName, lastName, gsm, idfNumber }) => {
    return (
        <Paper>
            <Grid container spacing={0} style={{ marginBottom: '16px' }}>
                <Grid item md={4} sm={12} xs={12} className={classNames(classes.infoContainer, classes.spacing)}>
                    <Typography variant="body1">
                        <Translate id="performance_report_name_and_surname" />
                    </Typography>
                    <Typography variant="h6"> {`${firstName}  ${lastName}`}</Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12} className={classNames(classes.infoContainer, classes.spacing)}>
                    <Typography variant="body1">
                        <Translate id="performance_report_id_number" />
                    </Typography>
                    <Typography variant="h6">{idfNumber}</Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12} className={classNames(classes.infoContainer, classes.spacing)}>
                    <Typography variant="body1">
                        <Translate id="performance_task_label_contact_phone" />
                    </Typography>
                    <Typography variant="h6">{gsm}</Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

CalendarHeader.propTypes = {
    classes: object.isRequired,
    firstName: string.isRequired,
    lastName: string.isRequired,
    performanceId: string,
    idfNumber: string,
    gsm: string,
}
function idfNumberIsSame(prevProps, nextProps) {
    return prevProps.idfNumber === nextProps.idfNumber
}

export default React.memo(withStyles(styles)(CalendarHeader), idfNumberIsSame)
