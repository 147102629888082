import React, { useContext, useState } from 'react'
import {
    Grid,
    InputLabel,
    TableCell,
    TextField,
    Typography,
    Table,
    TableContainer,
    TableRow,
    TableHead,
    TableBody,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { TranslationContext } from 'context/translation'
import { PtcarPlatformsContext } from '../../ptcarPlatforms.store'
import { useForm } from 'react-hook-form'
import PtcarSelect from './PtcarSelect'
import ErrorMessage from 'components/ErrorMessage'
import IconButton from 'components/IconButton'
import Loading from 'components/Loading'
import returnTranslationForLocale from 'utils/returnTranslationForLocale'

const EditPtcarPlatform = props => {
    const { classes, error } = props

    const { translate, activeLanguage } = useContext(TranslationContext)
    const { STATE, METHODS } = useContext(PtcarPlatformsContext)

    const [loading, setLoading] = useState(true)
    const [ptcar, setPtcar] = useState({})
    const [filterd, setFilterd] = useState([])
    const [newPtCar, setNewPtCar] = useState({ code: 0, label: 'PtCar' })
    const [ptcarList, setPtcarList] = useState([])

    const { register, errors } = useForm()

    const fetchData = async () => {
        const resp = await METHODS.loadPlatformData()
        setFilterd(getPtCarsFromData())
        setPtcar(resp.data)
        setPtcarList(resp.data.ptCars)
        setLoading(false)
    }

    const getPtCarsFromData = () => {
        return STATE.data.filter(el => el.platformId === STATE.editData.platformId)
    }

    const changeHandler = (value, target) => {
        if (target === 'description_fr') {
            setPtcar(prevState => ({
                ...prevState,
                platformName: [
                    { language: 'fr-BE', translation: value },
                    prevState.platformName.find(el => el.language === 'nl-BE'),
                ],
            }))
        } else if (target === 'description_nl') {
            setPtcar(prevState => ({
                ...prevState,
                platformName: [
                    prevState.platformName.find(el => el.language === 'fr-BE'),
                    { language: 'nl-BE', translation: value },
                ],
            }))
        }
        else if (target === 'name') {
            setPtcar(prevState => ({
                ...prevState,
                designation: value
            }))
        }
        else if (target === 'int_phone_number') {
            setPtcar(prevState => ({
                ...prevState,
                intPhoneNumber: value
            }))
        }
        else if (target === 'pub_phone_number') {
            setPtcar(prevState => ({
                ...prevState,
                pubPhoneNumber: value
            }))
        }
    }

    const handlePtcarSearchValue = event => {
        setNewPtCar(event)
    }

    const addNewPtcar = () => {
        if (newPtCar.code === 0) return
        if (filterd.filter(el => el.ptCarUicCode === parseInt(newPtCar.code)).length > 0) return

        const newFilterd = filterd
        const pt = STATE.ptCarsLists.filter(el => el.uicCode === newPtCar.code)[0]
        newFilterd.push({
            ptCarCode: pt.code,
            ptCarCommercialName: pt.commercialName,
            ptCarUicCode: parseInt(newPtCar.code),
        })

        setFilterd(newFilterd)
        setPtcarList(prevState => [...prevState, parseInt(newPtCar.code)])
    }

    const handleDeletePtCar = e => {
        setFilterd(filterd.filter(el => el.ptCarUicCode !== e.ptCarUicCode))
        setPtcarList(ptcarList.filter(el => el !== e.ptCarUicCode))
    }
    const submitTheForm = () => {
        const data = {
            id: ptcar.id,
            platformNameNl: ptcar.platformName[1].translation,
            platformNameFr: ptcar.platformName[0].translation,
            ptCars: ptcarList,
            designation: ptcar.designation,
            intPhoneNumber: ptcar.intPhoneNumber,
            pubPhoneNumber: ptcar.pubPhoneNumber,
            managedByLio: ptcar.managedByLio,
        }
        METHODS.handleUpdatePlatform(data)
    }

    React.useEffect(() => {
        fetchData()
    }, [])

    if (loading)
        return (
            <div>
                <Loading />
            </div>
        )

    return (
        <>
            <div className={classes.textfieldContainer}>
                <div>
                <InputLabel className={classes.inputLabel}>{translate('datatable_header_linda_description_nl')}</InputLabel>
                <TextField
                    onChange={e => {
                        changeHandler(e.target.value, 'description_nl')
                    }}
                    type="text"
                    defaultValue={ptcar.platformName.find(el => el.language === 'nl-BE').translation}
                />
                </div>
                <div>
                <InputLabel className={classes.inputLabel}>{translate('datatable_header_linda_description_fr')}</InputLabel>
                <TextField
                    onChange={e => {
                        changeHandler(e.target.value, 'description_fr')
                    }}
                    type="text"
                    defaultValue={ptcar.platformName.find(el => el.language === 'fr-BE').translation}
                />
                </div>
            </div>
            <div className={classes.textfieldContainer}>
                <div>
                <InputLabel className={classes.inputLabel}>{translate('ptcarplatform_int_phone_number')}</InputLabel>
                <TextField
                    onChange={e => {
                        changeHandler(e.target.value, 'int_phone_number')
                    }}
                    type="text"
                    defaultValue={ptcar.intPhoneNumber}
                    />
                </div>
                <div>
                <InputLabel className={classes.inputLabel}>{translate('ptcarplatform_public_net')}</InputLabel>
                <TextField
                onChange={e => {
                    changeHandler(e.target.value, 'pub_phone_number')
                }}
                type="text"
                defaultValue={ptcar.pubPhoneNumber}
                />
                </div>
            </div>
            <div className={classes.textfieldContainer}>
                <div>
                <InputLabel className={classes.inputLabel}>{translate('ptcarplatform_name')}</InputLabel>
                <TextField
                    onChange={e => {
                        changeHandler(e.target.value, 'name')
                    }}
                    type="text"
                    defaultValue={ptcar.designation}
                />
                </div>
                <div className={classes.checkboxContainer}>
                <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ptcar.managedByLio}
                                    onChange={e => setPtcar(prevState => ({
                                        ...prevState,
                                        managedByLio: e.target.checked
                                    }))}
                                    color="primary"
                                />
                            }
                            label={translate('datatable_header_managed_LIB')}
                        />
                </div>
            </div>
            <Typography className={classes.ptCarSelect} variant="h5">
                ptCars in platform
            </Typography>
            <PtcarSelect
                handleSearchValue={handlePtcarSearchValue}
                data={STATE.ptcars}
                ptcarSelectValue={newPtCar}
                classes={classes}
                register={register !== undefined ? register : null}
                errors={errors}
            />
            <Grid className={classes.ptcarAddButton} item xs={12}>
                <IconButton onClick={addNewPtcar} icon="save" title={translate('module_add_performance')} />
            </Grid>
            <TableContainer className={classes.ptCarlist}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Code</TableCell>
                            <TableCell align="right">Title</TableCell>
                            <TableCell align="right">Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterd.map((car, ind) => (
                            <TableRow key={ind + car}>
                                <TableCell>
                                    {returnTranslationForLocale(
                                        ptcar.platformName,
                                        activeLanguage.code === 'nl' ? 'nl-BE' : 'fr-BE'
                                    )}
                                </TableCell>
                                <TableCell align="right">{car.ptCarCode}</TableCell>
                                <TableCell align="right">
                                    {returnTranslationForLocale(
                                        car.ptCarCommercialName,
                                        activeLanguage.code === 'nl' ? 'nl-BE' : 'fr-BE'
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    <Delete
                                        onClick={() => handleDeletePtCar(car)}
                                        value={car.ptCarCode}
                                        className={classes.deleteIcon}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid item xs={12} className={classes.ptcarAddButton}>
                {error && error?.length > 0 && <ErrorMessage error={error} spacing={false} />}
                <div className={classes.buttonBottom}>
                    <IconButton onClick={e => submitTheForm(e)} icon="save" title={translate('add_user_confirm_save')} />
                </div>
            </Grid>
        </>
    )
}

EditPtcarPlatform.propTypes = {
    classes: PropTypes.object,
    error: PropTypes.string,
}

export default EditPtcarPlatform
