import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import ListHeaderStyles from './ListHeaderStyles'
import PropTypes from 'prop-types'
import { ESemesContext } from 'modules/eSemes/esemes.store'

import { fullZoneName } from 'modules/eSemes/esemes.util'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ListHeader from './ListHeader'
import { ListAccordion } from './ListAccordion'

const ListRows = ({ classes }) => {
    const { STATE, METHODS } = React.useContext(ESemesContext)

    const Messages = STATE.filterState.dataFilter.length > 0 ? STATE.filterState.filterdResults : STATE.apiState.eSemesData

    return STATE.filterState.activeIds.map(id => {
        if (id.pubuId === '6') {
            return null
        }
        const filtered = Messages.filter(d => d.pubuId === id.pubuId)

        return (
            <div
                key={id.pubuId}
                data-testid="listview"
                className={
                    STATE.filterState.zoneToggler.filter(el => el.id === id.pubuId)[0].isOpen
                        ? classes.documentTableWrapperOpen
                        : classes.documentTableWrapperClosed
                }
            >
                <List className={classes.listView}>
                    <ListItem
                        button
                        onClick={() => {
                            METHODS.toggleZone(
                                id.pubuId,
                                STATE.filterState.zoneToggler.filter(el => el.id === id.pubuId)[0].isOpen
                            )
                        }}
                    >
                        <ListItemText primary={fullZoneName(id.name[0].translation)} />
                        <ListItemIcon>
                            <ArrowDownwardIcon />
                        </ListItemIcon>
                    </ListItem>
                    {STATE.filterState.zoneToggler.filter(el => el.id === id.pubuId)[0].isOpen ? <ListHeader /> : null}
                    {filtered.map((el, i) => {
                        return STATE.filterState.zoneToggler.filter(el => el.id === id.pubuId)[0].isOpen ? (
                            <ListAccordion key={i} classes={classes} el={el} i={i} />
                        ) : null
                    })}
                </List>
            </div>
        )
    })
}

ListRows.propTypes = {
    classes: PropTypes.object,
}

export default withStyles(ListHeaderStyles)(ListRows)
