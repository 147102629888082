import checkWriteAccess from 'utils/checkWriteAccess'

export const isCompoEditingAllowed = props =>
    checkWriteAccess(props, 'manageCompositions', 'compositionWithPtcar.functionality.management.compositionWithPtcar') ||
    checkWriteAccess(props, 'manageCompositions', 'compositions.functionality.management.compositions')
export const isCompositionViewAllowed = props => {
    return (
        checkWriteAccess(props, 'compositionView', 'compositions.functionality.management.compositions') ||
        checkWriteAccess(props, 'compositionView', 'compositionWithPtcar.functionality.management.compositionWithPtcar')
    )
}
