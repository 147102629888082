export default theme => ({
    drawerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,

        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    halfButton: {
        width: '45%',
    },
    flexAlign: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
})
