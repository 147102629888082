import React from 'react'
import { number, object} from 'prop-types'
import { compose, mapProps} from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore} from 'react-ion-store'
import { getMonitoringData } from 'modules/Monitoring/services/getMonitoringData'


import MonitoringTable from './components/MonitoringTable'
import Loading from 'components/Loading'
import ActionDrawer from './components/actionDrawer'
import DetailsDrawer from './components/detailsDrawer'


const EdriveMonitor = ({currentTime}) => {
    const [data, setData] = React.useState(undefined)
    const [isTablet, setIsTablet] = React.useState(window.matchMedia('(max-width:1200px)').matches)
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
    const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = React.useState(false)
    const [drawerData, setDrawerData] = React.useState({})
    const [detailsDrawerData, setDetailsDrawerData] = React.useState(null)
    const [firstUseEffectCycle, setFirstUseEffectCycle] = React.useState(true)
    const [scrollPosition, setScrollPosition] = React.useState(0)
    
    React.useEffect(() => {
        window.addEventListener('resize', () => handleResize())
        window.addEventListener('scroll', () => setScrollPosition(scrollY))
        getMonitoringData().then(res => { setData(res)} )
        setFirstUseEffectCycle(false)
    }, [])
    
    React.useEffect(() => {
        if (!firstUseEffectCycle){
            handleDataRefresh()
        }
    }, [currentTime])

    const handleResize = () => {
        setIsTablet(window.matchMedia('(max-width:1200px)').matches)
    }

    const handleDrawerOpen = index => {
        setDrawerData(data[index])
        setIsDrawerOpen(true)
    }

    const handleDetailsDrawerOpen = selectedMonitoringItem => {
        setDetailsDrawerData(selectedMonitoringItem)
        setIsDetailsDrawerOpen(true)
    }

    const handleDataRefresh = async () => {
        setData(null)
        await getMonitoringData().then(res => { setData(res)} )
        window.scrollTo({ top: scrollPosition, behavior: "smooth" })
    }

    return (
        <div className="monitor">
            {data ? (
                <>
                    <MonitoringTable
                        handleDrawerOpen={handleDrawerOpen}
                        handleDetailsDrawerOpen={handleDetailsDrawerOpen}
                        className="contentDiv"
                        data={data}
                        isTablet={isTablet}
                    />
                    <ActionDrawer isOpen={isDrawerOpen} data={drawerData} handleClose={() => setIsDrawerOpen(false)} />
                    <DetailsDrawer
                        isOpen={isDetailsDrawerOpen}
                        data={detailsDrawerData}
                        handleClose={() => setIsDetailsDrawerOpen(false)}
                    />
                </>
            ) : (
                <div className="contentDiv">
                    <Loading />
                </div>
            )}
        </div>
    )
}

EdriveMonitor.propTypes = {
    currentTime: number.isRequired,
    store: object.isRequired
}
export default compose(withStore, withLocalize, 
    mapProps(props => ({
    ...props,
    currentTime: props.store.get('currentTime')
})))(EdriveMonitor) 
