import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
export default async() => {
    const { url, subscriptionKey } = requestData

    const fullUrl = `${url}/role/portaluserroletypes`

    return await axios.get(fullUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    })
}