export default theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
    submenu: {
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    submenuTitle: {
        marginBottom: theme.spacing(2),
    },
    radioGroup: {
        display: 'block',
    },
    buttonBottom: {
        position: 'absolute',
        bottom: `${theme.spacing(3)}px`,
        left: `${theme.spacing(3)}px`,
    },
    radioGroupParent: {
        marginTop: `${theme.spacing(2)}px`,
        padding: `${theme.spacing(1)}px`,
    },
    loadButton: {
        marginTop: `${theme.spacing(3)}px`,
    },
    root: {
        padding: `${theme.spacing(2)}px`,
    },
    textField: {
        width: '70%',
        marginTop: `${48}px`,
        backgroundColor: 'Black',
        padding: `${theme.spacing(2)}px`,
    },
    row: {
        cursor: 'pointer',
        color: 'white',
        '&:hover': {
            color: theme.customColors.detailGrey,
        },
    },
})
