import React from 'react'
import { object, bool, func, array } from 'prop-types'

import axios from 'axios'

// Components
import PerformanceNotificationDrawerView from './PerformanceNotificationDrawerView'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

class PerformanceNotificationDrawer extends React.Component {
    static propTypes = {
        close: func.isRequired,
        setParentstate: func.isRequired,
        clearPerformances: func,
        performanceNotificationDrawerOpen: bool,
        performanceNotificationDrawerData: array,
        performanceNotificationDrawerParams: object,
    }
    state = {
        data: null,
    }

    componentDidUpdate() {
        const {
            performanceNotificationDrawerOpen,
            performanceNotificationDrawerData,
            performanceNotificationDrawerParams,
        } = this.props

        if (
            performanceNotificationDrawerOpen &&
            performanceNotificationDrawerParams &&
            performanceNotificationDrawerData === null
        ) {
            this.getNotificationData(performanceNotificationDrawerParams)
        }
    }

    getNotificationData = async params => {
        const { url, subscriptionKey } = requestData
        const { performanceId, idfNumber } = params

        const { setParentstate } = this.props
        const fullUrl = `${url}/performance/${performanceId}/notifications/${idfNumber}`

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                setParentstate('performanceNotificationDrawerData', [])
            }
            if (response.status === 200) {
                setParentstate('performanceNotificationDrawerData', response.data)
            }
        } catch (e) {
            setParentstate('performanceNotificationDrawerData', [])
            console.error(e) // eslint-disable-line
        }
    }

    render() {
        const { close, performanceNotificationDrawerOpen, performanceNotificationDrawerData } = this.props
        return (
            <PerformanceNotificationDrawerView
                {...this.props}
                data={performanceNotificationDrawerData}
                isOpen={performanceNotificationDrawerOpen}
                close={close}
            />
        )
    }
}

export default PerformanceNotificationDrawer
