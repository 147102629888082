import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default (trainNumber, ptcarUic, date, idfNumber, toPtcar) => {
    const { url, subscriptionKey } = requestData
    let idfPart
    if (idfNumber) idfPart = `?idfNumber=${idfNumber}`
    else idfPart = ''
    let fullUrl
    if (toPtcar !== undefined) {
        fullUrl = `${url}/composition/${trainNumber}/date/${date}/from/${ptcarUic}/to/${toPtcar}${idfPart}`
    } else {
        console.log('trigger')
        fullUrl = `${url}/composition/${trainNumber}/date/${date}/ptcar/${ptcarUic}`
    }

    return axios.get(fullUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    })
}
