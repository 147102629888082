import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import IconButton from 'components/IconButton'
import Grid from '@material-ui/core/Grid'
import ErrorMessage from 'components/ErrorMessage'
import { DatePickerWithKeyboard, TimePickerWithKeyboardNew } from 'components/Pickers'
import { Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import AlertType from '../../Enums/AlertType'
import SettingType from '../../Enums/SettingType'
import { DateTime } from 'luxon'

let rangeArray = [
    {
        name: 'DRIVING',
        DayType: 0,
    },
    {
        name: 'NON_DRIVING',
        DayType: 0,
    },
    {
        name: 'ABSENCE',
        DayType: 0,
    },
    {
        name: 'DRIVING',
        DayType: 1,
    },
    {
        name: 'NON_DRIVING',
        DayType: 1,
    },
    {
        name: 'ABSENCE',
        DayType: 1,
    },
    {
        name: 'DRIVING',
        DayType: 2,
    },
    {
        name: 'NON_DRIVING',
        DayType: 2,
    },
    {
        name: 'ABSENCE',
        DayType: 2,
    },
]
const setEditData = editData => {
    if (editData?.ranges?.length > 0)
        for (let i = 0; i < rangeArray.length; i++) {
            rangeArray[i].upperBound = editData.ranges[i].upperBound
            rangeArray[i].lowerBound = editData.ranges[i].lowerBound
            rangeArray[i].minimumTaskCount = editData.ranges[i].minimumTaskCount
        }
}

const textField = (label, value, callback, register, errors, name, key, index) => {
    return (
        <TextField
            margin="dense"
            inputRef={register !== undefined ? register({ required: true }) : null}
            error={errors && !!errors[`${name}`]}
            id={name}
            name={name}
            label={label}
            type="number"
            fullWidth
            defaultValue={value}
            onChange={e => {
                callback(e.target.value)
                setRangeValues(key, index, e.target.value)
            }}
        />
    )
}

const textFieldTypeText = (label, value, callback, register, errors, name) => {
    return (
        <TimePickerWithKeyboardNew
            fullWidth
            name={label}
            ampm={false}
            openTo="hours"
            views={['hours', 'minutes', 'seconds']}
            format="HH:mm:ss"
            label={label}
            inputRef={register !== undefined ? register({ required: true }) : null}
            error={errors && !!errors[`${name}`]}
            value={value}
            onChange={callback}
        />
    )
}
const setDaysCheck = (callback, range) => {
    const renderCheckBoxes = []
    for (let i = 0; i < 12; i++) {
        renderCheckBoxes.push(
            <Grid item xs={2} key={i}>
                <FormControlLabel
                    control={<Checkbox checked={range.includes(i)} color="primary" onChange={() => callback(i)} />}
                    label={(i > 0 ? '+' : '') + i}
                />
            </Grid>
        )
    }
    return renderCheckBoxes
}
const setRangeValues = (key, index, value) => {
    rangeArray[index][key] = parseInt(value)
}
const changeTime = time => {
    if (time) {
        let [hours, minutes, seconds] = time.split(':')
        let curDate = new Date()
        curDate.setHours(hours)
        curDate.setMinutes(minutes)
        curDate.setSeconds(seconds)
        return DateTime.fromMillis(curDate.getTime())
    }
    return null
}
function ThresholdForm(props) {
    const { translate, handleAdd, error, editDrawer, editData, isRuleOverlapping } = props

    const [actionDelay, setActionDelay] = useState(editDrawer ? changeTime(editData.actionDelay) : new Date())
    const [errorDelay, setErrorDelay] = useState(editDrawer ? changeTime(editData.errorDelay) : new Date())
    const [warningDelay, setWarningDelay] = useState(editDrawer ? changeTime(editData.warningDelay) : new Date())

    const [n67Dupperbond, setn67Dupperbond] = useState(editDrawer ? editData?.ranges[0].upperBound || '' : '')
    const [n67Dlowerbond, setn67Dlowerbond] = useState(editDrawer ? editData?.ranges[0].lowerBound || '' : '')
    const [n67Dmintaskcode, setn67Dmintaskcode] = useState(editDrawer ? editData?.ranges[0].minimumTaskCount || '' : '')

    const [n67NDupperbond, setn67NDupperbond] = useState(editDrawer ? editData?.ranges[1].upperBound || '' : '')
    const [n67NDlowerbond, setn67NDlowerbond] = useState(editDrawer ? editData?.ranges[1].lowerBound || '' : '')
    const [n67NDmintaskcode, setn67NDmintaskcode] = useState(editDrawer ? editData?.ranges[1].minimumTaskCount || '' : '')

    const [n67Aupperbond, setn67Aupperbond] = useState(editDrawer ? editData?.ranges[2].upperBound || '' : '')
    const [n67Alowerbond, setn67Alowerbond] = useState(editDrawer ? editData?.ranges[2].lowerBound || '' : '')
    const [n67Amintaskcode, setn67Amintaskcode] = useState(editDrawer ? editData?.ranges[2].minimumTaskCount || '' : '')

    const [r67Dupperbond, setr67Dupperbond] = useState(editDrawer ? editData?.ranges[3].upperBound || '' : '')
    const [r67Dlowerbond, setr67Dlowerbond] = useState(editDrawer ? editData?.ranges[3].lowerBound || '' : '')
    const [r67Dmintaskcode, setr67Dmintaskcode] = useState(editDrawer ? editData?.ranges[3].minimumTaskCount || '' : '')

    const [r67NDupperbond, setr67NDupperbond] = useState(editDrawer ? editData?.ranges[4].upperBound || '' : '')
    const [r67NDlowerbond, setr67NDlowerbond] = useState(editDrawer ? editData?.ranges[4].lowerBound || '' : '')
    const [r67NDmintaskcode, setr67NDminstaskcode] = useState(editDrawer ? editData?.ranges[4].minimumTaskCount || '' : '')

    const [r67Aupperbond, setr67Aupperbond] = useState(editDrawer ? editData?.ranges[5].upperBound || '' : '')
    const [r67Alowerbond, setr67Alowerbond] = useState(editDrawer ? editData?.ranges[5].lowerBound || '' : '')
    const [r67Amintaskcode, setr67Amintaskcode] = useState(editDrawer ? editData?.ranges[5].minimumTaskCount || '' : '')

    const [fDupperbond, setfDupperbond] = useState(editDrawer ? editData?.ranges[6].upperBound || '' : '')
    const [fDlowerbond, setfDlowerbond] = useState(editDrawer ? editData?.ranges[6].lowerBound || '' : '')
    const [fDmintaskcode, setfDmintaskcode] = useState(editDrawer ? editData?.ranges[6].minimumTaskCount || '' : '')

    const [fNDupperbond, setfNDupperbond] = useState(editDrawer ? editData?.ranges[7].upperBound || '' : '')
    const [fNDlowerbond, setfNDlowerbond] = useState(editDrawer ? editData?.ranges[7].lowerBound || '' : '')
    const [fNDmintaskcode, setfNDmintaskcode] = useState(editDrawer ? editData?.ranges[7].minimumTaskCount || '' : '')

    const [fAupperbond, setfAupperbond] = useState(editDrawer ? editData?.ranges[8].upperBound || '' : '')
    const [fAlowerbond, setfAlowerbond] = useState(editDrawer ? editData?.ranges[8].lowerBound || '' : '')
    const [fAmintaskcode, setfAmintaskcode] = useState(editDrawer ? editData?.ranges[8].minimumTaskCount || '' : '')

    const { register, handleSubmit, errors } = useForm()
    const [plannedStartTime, setPlannedStartTime] = useState(editDrawer ? editData?.validFrom || null : null)
    const [plannedEndTime, setPlannedEndTime] = useState(editDrawer ? editData?.validTo || null : null)

    const [upperIsLess, setUpperIsLess] = useState(false)
    const [timeError, setTimeError] = useState(false)
    const [overLapError, setOveLapError] = useState(false)
    const [noSelection, setNoSelectionError] = useState(false)

    const [generalRange, setGeneralRange] = useState([])
    const [alertLevel, setAlertLevel] = useState(editDrawer ? editData?.triggerType : AlertType.P2)
    const [settingType, setSettingType] = useState(editDrawer ? editData?.type : null)
    const submitTheForm = () => {
        let dataToServer = {}
        setUpperIsLess(false)
        setTimeError(false)
        for (let range of rangeArray) {
            if (range.upperBound < range.lowerBound) {
                setUpperIsLess(true)
                return
            }
        }
        if (plannedEndTime < plannedStartTime) {
            setTimeError(true)
            return
        }
        dataToServer.ranges = rangeArray
        dataToServer.actionDelay = DateTime.fromMillis(new Date(actionDelay).getTime()).toFormat('HH:mm:ss')
        dataToServer.errorDelay = DateTime.fromMillis(new Date(errorDelay).getTime()).toFormat('HH:mm:ss')
        dataToServer.warningDelay = DateTime.fromMillis(new Date(warningDelay).getTime()).toFormat('HH:mm:ss')
        dataToServer.validFrom = plannedStartTime
        dataToServer.validTo = plannedEndTime
        dataToServer.triggerType = alertLevel
        dataToServer.type = settingType
        dataToServer.startInDaysFromToday = settingType === SettingType.GENERAL ? Math.min.apply(Math, generalRange) : null
        dataToServer.endInDaysFromToday = settingType === SettingType.GENERAL ? Math.max.apply(Math, generalRange) : null

        if (settingType === SettingType.GENERAL) {
            if (generalRange.length < 1) {
                setNoSelectionError(true)
                return
            }
            if (isRuleOverlapping(dataToServer)) {
                setOveLapError(true)
                return
            }
        }
        if (!upperIsLess) handleAdd(dataToServer)
    }

    const handleCheckbox = index => {
        let range = [...generalRange]
        let clickedIndex = range.indexOf(index)
        if (clickedIndex !== -1) {
            range.splice(clickedIndex, 1)
        } else {
            range.push(index)
        }
        setGeneralRange(range)
        setPlannedStartTime(null)
        setPlannedEndTime(null)
        setSettingType(SettingType.GENERAL)
        setNoSelectionError(false)
        setOveLapError(false)
    }
    const getTotal = (a, b, c) => {
        let total = parseInt(a || 0) + parseInt(b || 0) + parseInt(c || 0)
        return total || ''
    }
    useEffect(() => {
        let range = []
        if (editData.type === SettingType.GENERAL) {
            let start = editData.startInDaysFromToday
            let end = editData.endInDaysFromToday
            // To checkBox set true in range
            for (let i = start; i <= end; i++) {
                range.push(i)
            }
        }
        setGeneralRange(range)
        setEditData(editData)
    }, [editData])
    let disableDates = generalRange.length > 0
    return (
        <React.Fragment>
            <Typography>{translate('threshold_general_msg')}</Typography>
            <Grid container spacing={2} style={{ marginTop: '8px' }}>
                {setDaysCheck(handleCheckbox, generalRange)}
            </Grid>
            <Typography>{translate('threshold_exception_msg')}</Typography>
            <div style={{ display: 'flex' }}>
                <DatePickerWithKeyboard
                    disablePast={true}
                    animateYearScrolling
                    value={plannedStartTime}
                    onChange={e => {
                        setPlannedStartTime(e)
                        setSettingType(SettingType.EXCEPTION)
                    }}
                    format="dd/MM/yyyy"
                    inputRef={register !== undefined ? register({ required: !disableDates }) : null}
                    error={errors && !!errors[`plannedStartTime`]}
                    id={'plannedStartTime'}
                    name={'plannedStartTime'}
                    disabled={disableDates}
                />
                <DatePickerWithKeyboard
                    minDate={plannedStartTime}
                    animateYearScrolling
                    value={plannedEndTime}
                    onChange={e => {
                        setPlannedEndTime(e)
                        setSettingType(SettingType.EXCEPTION)
                    }}
                    format="dd/MM/yyyy"
                    inputRef={register !== undefined ? register({ required: !disableDates }) : null}
                    error={errors && !!errors[`plannedEndTime`]}
                    id={'plannedEndTime'}
                    name={'plannedEndTime'}
                    disabled={disableDates}
                />
            </div>
            <Typography>{translate('threshold_alert_msg')}</Typography>
            <RadioGroup style={{ display: 'inline-block' }}>
                <FormControlLabel
                    control={
                        <Radio
                            checked={alertLevel === AlertType.P1}
                            onChange={() => {
                                setAlertLevel(AlertType.P1)
                            }}
                            color="primary"
                        />
                    }
                    label={'P1'}
                    labelPlacement="end"
                />
                <FormControlLabel
                    control={
                        <Radio
                            checked={alertLevel === AlertType.P2}
                            onChange={() => {
                                setAlertLevel(AlertType.P2)
                            }}
                            color="primary"
                        />
                    }
                    label={'P2'}
                    labelPlacement="end"
                />
            </RadioGroup>

            <form onSubmit={handleSubmit(submitTheForm)} noValidate autoComplete="off">
                <React.Fragment>
                    {textFieldTypeText('ActionDelay', actionDelay, setActionDelay, register, errors, 'actiondelay')}

                    {textFieldTypeText('WarningDelay', warningDelay, setWarningDelay, register, errors, 'warningdelay')}

                    {textFieldTypeText('ErrorDelay', errorDelay, setErrorDelay, register, errors, 'errordelay')}
                </React.Fragment>
                <Grid container spacing={1} style={{ marginTop: '16px' }}>
                    <Grid item xs={3}>
                        <Typography>N67(Driving)</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Upperbound',
                            n67Dupperbond,
                            setn67Dupperbond,
                            register,
                            errors,
                            'n67Dupperbond',
                            'upperBound',
                            0
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Lowerbound',
                            n67Dlowerbond,
                            setn67Dlowerbond,
                            register,
                            errors,
                            'n67Dlowerbond',
                            'lowerBound',
                            0
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'minTaskCode',
                            n67Dmintaskcode,
                            setn67Dmintaskcode,
                            register,
                            errors,
                            'n67Dmintaskcode',
                            'minimumTaskCount',
                            0
                        )}
                    </Grid>

                    <Grid item xs={3}>
                        <Typography>N67(Non-Driving)</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Upperbound',
                            n67NDupperbond,
                            setn67NDupperbond,
                            register,
                            errors,
                            'drdupperbond',
                            'upperBound',
                            1
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Lowerbound',
                            n67NDlowerbond,
                            setn67NDlowerbond,
                            register,
                            errors,
                            'drdlowerbond',
                            'lowerBound',
                            1
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'minTaskCode',
                            n67NDmintaskcode,
                            setn67NDmintaskcode,
                            register,
                            errors,
                            'drdmintaskcode',
                            'minimumTaskCount',
                            1
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>N67(Absences)</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        {textField(
                            'Upperbound',
                            n67Aupperbond,
                            setn67Aupperbond,
                            register,
                            errors,
                            'dnrdupperbond',
                            'upperBound',
                            2
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Lowerbound',
                            n67Alowerbond,
                            setn67Alowerbond,
                            register,
                            errors,
                            'drndlowerbond',
                            'lowerBound',
                            2
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'minTaskCode',
                            n67Amintaskcode,
                            setn67Amintaskcode,
                            register,
                            errors,
                            'dnrdmintaskcode',
                            'minimumTaskCount',
                            2
                        )}
                    </Grid>
                    <Grid item xs={3} style={{ marginBottom: '5px' }} />
                    <Grid item xs={3} style={{ marginBottom: '5px' }}>
                        <Typography>{getTotal(n67Dupperbond, n67NDupperbond, n67Aupperbond)}</Typography>
                    </Grid>
                    <Grid item xs={3} style={{ marginBottom: '5px' }}>
                        <Typography>{getTotal(n67Dlowerbond, n67NDlowerbond, n67Alowerbond)}</Typography>
                    </Grid>
                    <Grid item xs={3} style={{ marginBottom: '5px' }} />

                    <Grid item xs={3}>
                        <Typography>R67(Driving)</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        {textField(
                            'Upperbound',
                            r67Dupperbond,
                            setr67Dupperbond,
                            register,
                            errors,
                            'ndrdupperbond',
                            'upperBound',
                            3
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Lowerbound',
                            r67Dlowerbond,
                            setr67Dlowerbond,
                            register,
                            errors,
                            'ndndlowerbond',
                            'lowerBound',
                            3
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'minTaskCode',
                            r67Dmintaskcode,
                            setr67Dmintaskcode,
                            register,
                            errors,
                            'ndrdmintaskcode',
                            'minimumTaskCount',
                            3
                        )}
                    </Grid>

                    <Grid item xs={3}>
                        <Typography>R67(Non-Driving)</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Upperbound',
                            r67NDupperbond,
                            setr67NDupperbond,
                            register,
                            errors,
                            'ndnrdupperbond',
                            'upperBound',
                            4
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Lowerbound',
                            r67NDlowerbond,
                            setr67NDlowerbond,
                            register,
                            errors,
                            'ndrndlowerbond',
                            'lowerBound',
                            4
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'minTaskCode',
                            r67NDmintaskcode,
                            setr67NDminstaskcode,
                            register,
                            errors,
                            'ndnrdmintaskcode',
                            'minimumTaskCount',
                            4
                        )}
                    </Grid>

                    <Grid item xs={3}>
                        <Typography>R67(Absences)</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Upperbound',
                            r67Aupperbond,
                            setr67Aupperbond,
                            register,
                            errors,
                            'arddupperbond',
                            'upperBound',
                            5
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Lowerbound',
                            r67Alowerbond,
                            setr67Alowerbond,
                            register,
                            errors,
                            'ardlowerbond',
                            'lowerBound',
                            5
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'minTaskCode',
                            r67Amintaskcode,
                            setr67Amintaskcode,
                            register,
                            errors,
                            'ardmintaskcode',
                            'minimumTaskCount',
                            5
                        )}
                    </Grid>
                    <Grid item xs={3} style={{ marginBottom: '5px' }} />
                    <Grid item xs={3} style={{ marginBottom: '5px' }}>
                        <Typography>{getTotal(r67Dupperbond, r67NDupperbond, r67Aupperbond)}</Typography>
                    </Grid>
                    <Grid item xs={3} style={{ marginBottom: '5px' }}>
                        <Typography>{getTotal(r67Dlowerbond, r67NDlowerbond, r67Alowerbond)}</Typography>
                    </Grid>
                    <Grid item xs={3} style={{ marginBottom: '5px' }} />
                    <Grid item xs={3}>
                        <Typography>F(Driving)</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Upperbound',
                            fDupperbond,
                            setfDupperbond,
                            register,
                            errors,
                            'anrddupperbond',
                            'upperBound',
                            6
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Lowerbound',
                            fDlowerbond,
                            setfDlowerbond,
                            register,
                            errors,
                            'anrdlowerbond',
                            'lowerBound',
                            6
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'minTaskCode',
                            fDmintaskcode,
                            setfDmintaskcode,
                            register,
                            errors,
                            'anrdmintaskcode',
                            'minimumTaskCount',
                            6
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>F(Non-Driving)</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Upperbound',
                            fNDupperbond,
                            setfNDupperbond,
                            register,
                            errors,
                            'anrddupperbond',
                            'upperBound',
                            7
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Lowerbound',
                            fNDlowerbond,
                            setfNDlowerbond,
                            register,
                            errors,
                            'anrdlowerbond',
                            'lowerBound',
                            7
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'minTaskCode',
                            fNDmintaskcode,
                            setfNDmintaskcode,
                            register,
                            errors,
                            'anrdmintaskcode',
                            'minimumTaskCount',
                            7
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>F(Absences)</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Upperbound',
                            fAupperbond,
                            setfAupperbond,
                            register,
                            errors,
                            'anrddupperbond',
                            'upperBound',
                            8
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'Lowerbound',
                            fAlowerbond,
                            setfAlowerbond,
                            register,
                            errors,
                            'anrdlowerbond',
                            'lowerBound',
                            8
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {textField(
                            'minTaskCode',
                            fAmintaskcode,
                            setfAmintaskcode,
                            register,
                            errors,
                            'anrdmintaskcode',
                            'minimumTaskCount',
                            8
                        )}
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <Typography>{getTotal(fDupperbond, fNDupperbond, fAupperbond)}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>{getTotal(fDlowerbond, fNDlowerbond, fAlowerbond)}</Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12}>
                        {error && error?.length > 0 && (
                            <ErrorMessage
                                error={editDrawer ? translate('platform_edit_failed') : translate('platform_add_failed')}
                                spacing={false}
                            />
                        )}
                        {upperIsLess && (
                            <ErrorMessage error={translate('threshold_upper_less_then_lower')} spacing={false} />
                        )}
                        {overLapError && <ErrorMessage error={translate('threshold_overlap_error')} spacing={false} />}
                        {timeError && <ErrorMessage error={translate('threshold_upper_limit_error')} spacing={false} />}
                        {noSelection && <ErrorMessage error={translate('threshold_not_selected')} spacing={false} />}
                        {editDrawer && <IconButton type="submit" icon="save" title={translate('add_user_confirm_save')} />}
                        {editDrawer === false && <IconButton icon="save" type="submit" title={translate('button_add')} />}
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

ThresholdForm.propTypes = {
    translate: PropTypes.func,
    handleAdd: PropTypes.func,
    isRuleOverlapping: PropTypes.func,
    error: PropTypes.string,
    editDrawer: PropTypes.bool,
    editData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default ThresholdForm
