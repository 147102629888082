export default theme => ({
    reportSectionLayout: {
        width: '98%',
        margin: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            marginLeft: '4px',
        },
        padding: theme.spacing(1),
        backgroundColor: '#424242',
        borderRadius: '5px',
        wordWrap: 'break-word',
        height: 'auto',
        display: 'inline-table',
    },
    reportSubSection: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    reportSubHeader: {
        color: theme.customColors.whiteColor,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    reportTitle: {
        color: theme.customColors.whiteColor,
    },
    checkBoxLabel: {
        color: theme.customColors.whiteColor,
    },
})
