import React from 'react'
import { array, bool, func, string, number, oneOfType } from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import FilledInput from '@material-ui/core/FilledInput'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'

// Components
import AppPaper from 'components/AppPaper'

import sortByName from 'utils/sortByName'

// Assets
import styles from './styles'

const StyledSelect = withStyles(styles)(Select)

const SelectInput = ({
    items,
    filled,
    label,
    name,
    onChange,
    value,
    disabled,
    autoWidth = true,
    sorted = false,
    minWidth = 0,
    maxWidth = '100%',
    multiple,
    error = null,
}) => {
    let sortedItems = items
    if (sorted) {
        const [header, ...unsortedItems] = items
        sortedItems = [header, ...unsortedItems.sort((a, b) => sortByName(a.text, b.text))]
    }

    if (value === undefined) return <Typography data-testid="SelectInput-loading">Loading...</Typography>
    return (
        <AppPaper>
            <FormControl
                data-testid="SelectInput-FormControl"
                style={{ minWidth: `${minWidth}px`, maxWidth: maxWidth }}
                variant={filled ? 'filled' : 'outlined'}
            >
                {!!label && (
                    <InputLabel data-testid="SelectInput-inputLabel" htmlFor={name}>
                        {label}
                    </InputLabel>
                )}
                {multiple && (
                    <StyledSelect
                        autoWidth={autoWidth}
                        input={filled ? <FilledInput id={name} /> : <Input id={name} />}
                        name={name}
                        onChange={(e, index) => onChange(e, index, sortedItems)}
                        value={value}
                        disabled={disabled}
                        multiple={multiple || false}
                        displayEmpty
                        renderValue={valueToRender => {
                            let count = valueToRender.length
                            if (valueToRender.includes('performances_label_all_depots')) {
                                count -= 1
                            }
                            if (count === 0) {
                                return `0 ${name}`
                            }
                            if (count > 1) {
                                return `${count} ${name}`
                            }
                            if (count === 1) {
                                const selItem = sortedItems.filter(item => item.id === valueToRender[0])

                                if (selItem.length > 0) return `${selItem[0].text}`
                                return ''
                            }
                        }}
                    >
                        {sortedItems.map(item => (
                            <MenuItem key={item.id} value={item.id} data-code={item.code} id={item.id}>
                                <Checkbox color="primary" checked={value.indexOf(item.id) > -1} />
                                <Typography noWrap={true}>{item.text}</Typography>
                            </MenuItem>
                        ))}
                    </StyledSelect>
                )}

                {!multiple && (
                    <StyledSelect
                        autoWidth={autoWidth}
                        input={filled ? <FilledInput id={name} /> : <Input id={name} />}
                        name={name}
                        id={name}
                        onChange={onChange}
                        value={value}
                        disabled={disabled}
                        error={error !== null ? error : null}
                    >
                        {sortedItems.map(item => (
                            <MenuItem key={item.id} value={item.id} data-code={item.code}>
                                <Typography name={item.id} noWrap={true}>
                                    {item.text}
                                </Typography>
                            </MenuItem>
                        ))}
                    </StyledSelect>
                )}
            </FormControl>
        </AppPaper>
    )
}

SelectInput.propTypes = {
    items: array.isRequired,
    label: string,
    filled: bool,
    autoWidth: bool,
    sorted: bool,
    name: string.isRequired,
    onChange: func,
    value: oneOfType([string, array, number]),
    disabled: bool,
    minWidth: number,
    maxWidth: string,
    multiple: bool,
    error: oneOfType([bool, string]),
}

export default SelectInput
