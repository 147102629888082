export default theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        position: 'fixed',
        bottom: '0px',
        width: '100%',
        zIndex: 2,
        '@media (max-width: 577px)': {
            padding: '5px',
        },
    },
    flex: {
        display: 'flex',
        '@media (max-width: 577px)': {
            justifyContent: 'space-between',
        },
    },
})
