/**
 *
 * !EXCEPTION if user has acces to performances he always has acces to emergencyPerformance
 *
 * @param {*} menuTree
 * @param {*} moduleList
 * @returns filterdMenuTree
 */

import { routeNames } from './initialState'
import { getIdfFromStore } from 'utils/isAgendaAllowed'

export const menuFilter = (menuTree, moduleList, userRoles, auth, allowedDistricts) => {
    //Check if admin
    let isAdmin
    if (userRoles !== undefined) {
        isAdmin = userRoles.find(role => role.portalUserRoleId === 142)
    }
    
    const filterdMenu = []
    // eslint-disable-next-line no-unused-vars
    const authPath = `/agenda/${getIdfFromStore(auth)}`

    menuTree.forEach(firstLevel => {
        //If firstlevel is not collapsable
        if (!firstLevel.isCollapsable) {
            //Check if user has acces to route
            if (moduleList.includes(firstLevel.moduleBase)) {
                if ('admin' in firstLevel) {
                    isAdmin ? filterdMenu.push(firstLevel) : null
                } else {
                    filterdMenu.push(firstLevel)
                }
            }
        } else {
            //Firstlevel is collapsable
            const firstLevelCopy = {
                ...firstLevel,
                subModules: [],
            }
            
            firstLevel.subModules.forEach((secondLevel) => {
                //Check if secondLevel is collapsable
                if (!secondLevel.isCollapsable) {
                    if (moduleList.includes(secondLevel.moduleBase) || isAdmin) {
                        
                        if('admin' in secondLevel){
                            !secondLevel.admin == !isAdmin ? firstLevelCopy.subModules.push(secondLevel) : null
                        }
                        else{
                            firstLevelCopy.subModules.push(secondLevel)
                        }

                    }
                } else {
                    //Secondlevel is collapsable
                    const secondLevelCopy = {
                        ...secondLevel,
                        subModules: [],
                    }
                    //No third level collapsables are present on the portal
                    secondLevel.subModules.forEach(thirdLevel => {
                        if (moduleList.includes(thirdLevel.moduleBase)) {
                            if (secondLevel.moduleName === 'district') {
                                allowedDistricts.data &&
                                    allowedDistricts.data.forEach(el => {
                                        if (
                                            el.name[0].translation === thirdLevel.id.replace('_', '-') ||
                                            el.name[1].translation === thirdLevel.id.replace('_', '-')
                                        ) {
                                            secondLevelCopy.subModules.push(thirdLevel)
                                        }
                                        if (thirdLevel.id === 'Centrale_directie') {
                                            if (
                                                el.name[0].translation === 'Direction centrale' ||
                                                el.name[1].translation === 'Centrale directie'
                                            ) {
                                                secondLevelCopy.subModules.push(thirdLevel)
                                            }
                                        }
                                    })
                            } else {
                                secondLevelCopy.subModules.push(thirdLevel)
                            }
                        }
                    })
                    if (secondLevelCopy.subModules !== undefined && secondLevelCopy.subModules.length > 0) {
                        firstLevelCopy.subModules.push(secondLevelCopy)
                    }
                }
            })
            if (firstLevelCopy.subModules !== undefined && firstLevelCopy.subModules.length > 0) {
                filterdMenu.push(firstLevelCopy)
            }
        }
    })

    //Swap path in filterdMenu
    return filterdMenu.map(el => {
        if (el.moduleName === routeNames.AGENDA) {
            el.subModules[0].path = authPath
            return el
        }
        return el
    })
}
