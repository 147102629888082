import React, { Component } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Add from '@material-ui/icons/Add'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

export class DepotSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterValue: '',
            filteredItems: [],
        }
    }
    static propTypes = {
        classes: PropTypes.object,
        addOrganisation: PropTypes.func,
        disabled: PropTypes.bool,
        districts: PropTypes.array,
    }

    getAllDepots = data => {
        const depotArray = []
        // eslint-disable-next-line array-callback-return
        data.map(item => {
            // eslint-disable-next-line array-callback-return
            item.tcts.map(tct => {
                tct.depots.map(dep => {
                    return depotArray.push(dep)
                })
            })
        })
        return depotArray
    }

    onHandleChange = e => {
        const filteredValues = this.filterDepots(e.target.value)
        this.setState({ filterValue: e.target.value, filteredItems: filteredValues })
    }

    filterDepots = filtervalue => {
        const { allDepots } = this.state

        return allDepots.filter(item => item.symbolicName.indexOf(filtervalue.toUpperCase()) !== -1)
    }

    componentDidMount() {
        const { districts } = this.props

        const allDepots = this.getAllDepots(districts)
        this.setState({ allDepots: allDepots })
    }

    render() {
        const { filterValue, filteredItems } = this.state
        const { classes, addOrganisation, disabled } = this.props

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Translate>
                        {({ translate }) => (
                            <TextField
                                name="districtFilter"
                                disabled={disabled}
                                label={translate('button_search')}
                                value={filterValue}
                                onChange={this.onHandleChange}
                            />
                        )}
                    </Translate>
                </Grid>
                {filterValue.length > 0 &&
                    filteredItems.length > 0 &&
                    !disabled &&
                    filteredItems.map(org => (
                        <Grid item xs={4} key={org.depotId}>
                            <Chip
                                color="primary"
                                style={{ width: '100%', justifyContent: 'space-between' }}
                                label={org.symbolicName}
                                key={org.depotId}
                                onDelete={() => {
                                    addOrganisation(org.depotId, org.symbolicName)
                                }}
                                className={classes.chip}
                                deleteIcon={<Add />}
                            />
                        </Grid>
                    ))}
                <Grid item xs={12}>
                    {filterValue.length > 0 && filteredItems.length > 0 && !disabled && <hr />}
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(DepotSearch)
