// Access-Control List
const mapTlds = {
    performances: '/performances',
    documents: '/documents',
    manage: '/manage',
    monitoring: '/monitoring',
    users: '/users',
    mobileFeatures: '/mobileFeatures',
    performancesfeedback: '/performancesfeedback',
    manageperformance: '/manageperformance',
    readyfordeparture: '/readyfordeparture',
    managePtcar: '/ptcarOverview',
    agenda: '/agenda',
    searchagenda: '/searchCalendar',
    jsonFormat: '/jsonFormat',
    profiles: '/profiles',
    managePlatforms: '/platforms',
    managePtcarPlatforms: '/ptcarplatforms',
    performancethreshold: '/performancethreshold',
    traintaskoverview: '/traintaskoverview',
    compositionWithPtcar: '/compositionWithPtcar',
    compositionWithDate: '/compositionWithDate',
    compositions: '/compositions',
    eSemes: '/eSemes',
    eSpeed: '/eSpeed',
    managePtdes: '/ptdesOverview',
}

export default roles => {
    const rolesTopLevel = Object.keys(roles)
    const allowedPaths = []
    rolesTopLevel.forEach(role => {
        if (roles[role].read === true || roles[role].read.length > 0) allowedPaths.push(mapTlds[role])
        // allowedPaths.push(mapTlds[role]);
    })

    return allowedPaths
}
