import React from 'react'
import { func, object } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { PaperBox } from 'components/PaperBox'

import styles from './styles'

const DriveModeLogsView = ({ classes, data, translate }) => {
    const mapLogs = data => {
        return data.map(log => {
            if (log.isInDriveMode) {
                return (
                    <PaperBox
                        redBox={false}
                        value={`${log.deviceTime.substring(0, 5)} - ${translate(
                            'performances_label_drivedetails_drivemode_opened'
                        )}`}
                        key={`${log.deviceTime.substring(0, 5)} - ${translate(
                            'performances_label_drivedetails_drivemode_opened'
                        )}`}
                        deviceId={`${translate('performances_label_drivedetails_device')} ${log.deviceId}`}
                        speed={`${translate('performances_label_drivedetails_speed')} ${log.speed}`}
                    />
                )
            } else {
                return (
                    <PaperBox
                        redBox={false}
                        value={`${log.deviceTime.substring(0, 5)} - ${translate(
                            'performances_label_drivedetails_drivemode_closed'
                        )}`}
                        key={`${log.deviceTime.substring(0, 5)} - ${translate(
                            'performances_label_drivedetails_drivemode_closed'
                        )}`}
                        deviceId={`${translate('performances_label_drivedetails_device')} ${log.deviceId}`}
                        speed={`${translate('performances_label_drivedetails_speed')} ${log.speed}`}
                    />
                )
            }
        })
    }

    return (
        <>
            <div className={classes.drawerHeader}>
                <Typography variant="h6">
                    <Translate id="performances_label_drivedetails_title" />
                </Typography>
            </div>
            <Typography variant="h6">
                <Translate id="performances_label_drivedetails_sub_title" />
            </Typography>
            <hr style={{ marginBottom: 16 }} />
            {data && data?.driveModeLogs.length === 0 && (
                <PaperBox redBox={true} value={translate('performances_label_drivedetails_drivemode_not_opened')} />
            )}
            {data?.driveModeLogs && data?.driveModeLogs?.length > 0 && mapLogs(data.driveModeLogs)}
        </>
    )
}

DriveModeLogsView.propTypes = {
    classes: object.isRequired,
    translate: func.isRequired,
    data: object,
}

export default compose(withStyles(styles), withLocalize)(DriveModeLogsView)
