export default theme => ({
    root: {
        width: '80%',
    },
    header: {
        background: theme.palette.grey['900'],
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    closeButton: {
        margin: theme.spacing(1),
    },
    actionButton: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
})
