import React from 'react'
import { withStore } from 'react-ion-store'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import Header from 'components/Header'
import FeedbackListView from './components/FeedbackListView/FeedbackListView'

import styles from './styles'
import { feedbackCategoriesContext } from './store.feedbackCategories'
import withHocs from 'utils/hocs/withHocs'
import { TranslationContext } from 'context/translation'

const FeedbackCategories = props => {
    const { SERVICES } = React.useContext(feedbackCategoriesContext)
    const { translate } = React.useContext(TranslationContext)

    React.useEffect(() => {
        SERVICES.getFeedback(props.scope)
        document.title = translate('module_feedback') || 'Feedback'
    }, [props.scope])

    return (
        <div data-testid="feedbackCategories_component">
            <Header isMenu title={translate('module_manage')} />
            <FeedbackListView store={props.store} scope={props.scope}/>
        </div>
    )
}

FeedbackCategories.propTypes = {
    store: PropTypes.object,
    scope: PropTypes.string,
}

export default withHocs(withStore, withStyles(styles))(FeedbackCategories)
