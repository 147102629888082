import React from 'react'
import { bool, func, object, array } from 'prop-types'
import { Button, Typography } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import DeleteIcon from '@material-ui/icons/Delete'
import ReplayIcon from '@material-ui/icons/Replay'
import MaterialUnitListWithDamages from '../MaterialUnitListWithDamages'
import VehicleSearch from '../VehicleSearch'

const EditVehicleView = props => {
    const {
        translate,
        handleUpwardClick,
        handleDownwardClick,
        upwardDisabled,
        downwardDisabled,
        handleMaterialUnitDelete,
        handleChangeInDirectionMUClevel,
        handleInDienstClick,
        haveTraction,
        IsLocoInUse,
        data,
        handleDamageChange,
        selectedVehicle,
        getRelevantDamages,
        handleRemoveVehicle,
        moveVehicleUpward,
        moveVehicleDownward,
        litteraSearchOptions,
        selectedLitteraVehicle,
        handleAddLittera,
        handleEditLitteraClicked,
        defectTypes,
        activeLanguage,
    } = props
    return (
        <div>
            <Typography variant="h6">{translate('composition_edit_vehicle_drawer_title')}</Typography>
            <div>
                <div style={{ display: 'flex', marginTop: '32px' }}>
                    <Typography>{translate('compo_edit_vehicle')}</Typography>
                    <DeleteIcon className={props.classes.deleteIcon} onClick={handleMaterialUnitDelete} />
                </div>
                <div style={{ display: 'flex', marginTop: '16px' }}>
                    <ReplayIcon
                        className={props.classes.changeDirection}
                        onClick={() => {
                            handleChangeInDirectionMUClevel()
                        }}
                    />
                    <div>
                        <ArrowUpwardIcon
                            className={upwardDisabled ? props.classes.arrowButtonDisabled : props.classes.arrow}
                            onClick={!upwardDisabled ? handleUpwardClick : null}
                        />
                        <ArrowDownwardIcon
                            className={downwardDisabled ? props.classes.arrowButtonDisabled : props.classes.arrow}
                            onClick={!downwardDisabled ? handleDownwardClick : null}
                        />
                    </div>

                    <VehicleSearch
                        translate={translate}
                        selectedVehicle={selectedVehicle}
                        options={props.options}
                        handleSearchValue={props.handleSearchValue}
                    />
                </div>

                {haveTraction && data.vehicleNumber !== undefined && (
                    <Button variant="contained" color={IsLocoInUse ? 'primary' : 'default'} onClick={handleInDienstClick}>
                        {translate('composition_drawer_in_dienst_button')}
                    </Button>
                )}
                {data.materialUnitChilds.length > 0 && (
                    <MaterialUnitListWithDamages
                        data={data}
                        handleDamageChange={handleDamageChange}
                        getRelevantDamages={getRelevantDamages}
                        handleRemoveVehicle={handleRemoveVehicle}
                        moveVehicleUpward={moveVehicleUpward}
                        moveVehicleDownward={moveVehicleDownward}
                        litteraSearchOptions={litteraSearchOptions}
                        selectedLitteraVehicle={selectedLitteraVehicle}
                        handleAddLittera={handleAddLittera}
                        handleEditLitteraClicked={handleEditLitteraClicked}
                        defectTypes={defectTypes}
                        activeLanguage={activeLanguage}
                    />
                )}
                {data.materialUnitChilds.length === 0 && data.materialName?.length > 0 && (
                    <MaterialUnitListWithDamages
                        data={data}
                        handleDamageChange={handleDamageChange}
                        getRelevantDamages={getRelevantDamages}
                        handleRemoveVehicle={handleRemoveVehicle}
                        moveVehicleUpward={moveVehicleUpward}
                        moveVehicleDownward={moveVehicleDownward}
                        litteraSearchOptions={litteraSearchOptions}
                        selectedLitteraVehicle={selectedLitteraVehicle}
                        handleAddLittera={handleAddLittera}
                        handleEditLitteraClicked={handleEditLitteraClicked}
                        defectTypes={defectTypes}
                        activeLanguage={activeLanguage}
                    />
                )}
            </div>
        </div>
    )
}
EditVehicleView.propTypes = {
    translate: func,
    classes: object,
    handleUpwardClick: func,
    handleDownwardClick: func,
    upwardDisabled: bool,
    downwardDisabled: bool,
    handleMaterialUnitDelete: func,
    handleChangeInDirectionMUClevel: func,
    handleInDienstClick: func,
    haveTraction: bool,
    IsLocoInUse: bool,
    data: object,
    handleDamageChange: func,
    selectedVehicle: object,
    options: array,
    handleSearchValue: func,
    getRelevantDamages: func,
    handleRemoveVehicle: func,
    moveVehicleUpward: func,
    moveVehicleDownward: func,
    litteraSearchOptions: array,
    selectedLitteraVehicle: object,
    handleAddLittera: func,
    handleEditLitteraClicked: func,
    defectTypes: array,
    activeLanguage: object,
}
export default EditVehicleView
