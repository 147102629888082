import * as React from 'react'
import { arrayOf, object } from 'prop-types'
import classNames from 'classnames'
import StyledTooltip from 'components/StyledTooltip'
import { Swiper, Slide } from 'react-dynamic-swiper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import '../../../../../node_modules/react-dynamic-swiper/lib/styles.css'
import styles from './styles'

const mapProperties = p => {
    const keys = Object.keys(p)
    const newObj = {}

    keys.forEach(k => {
        const lk = k.toLowerCase()
        newObj[lk] = p[k]
    })

    return newObj
}

const Status = {
    0: 'OK',
    1: 'WARNING:',
    2: 'ERROR',
    OK: 0,
    WARNING: 1,
    ERROR: 2,
}

const mapStatus = {
    [Status.OK]: 'OK',
    [Status.WARNING]: 'WARNING',
    [Status.ERROR]: 'ERROR',
}

const MonitoringTableMobile = props => {
    const { data = [], classes } = props

    return (
        <div className={classes.flexContainer}>
            <div className={classes.firstColumn}>
                <div className={classes.tr}>
                    <div className={classes.th}>
                        <Typography className={classes.darkText}>Host</Typography>
                    </div>
                </div>

                {data.map((_d, index) => {
                    const d = mapProperties(_d)

                    return (
                        <div
                            key={index + d.host + d.service}
                            className={classNames(classes.tr, classes[mapStatus[d.status]])}
                        >
                            <div className={classes.td}>
                                <Typography className={d.status === Status.WARNING ? classes.darkText : ''}>
                                    {d.host}
                                </Typography>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={classes.secondColumn}>
                <Swiper navigation={false} paginationClickable={true}>
                    <Slide>
                        <div className={classes.tr}>
                            <div className={classes.th}>
                                <Typography className={classes.darkText}>Service</Typography>
                            </div>
                        </div>

                        {data.map((_d, index) => {
                            const d = mapProperties(_d)
                            return (
                                <div
                                    key={index + d.service + d.timestamp}
                                    className={classNames(classes.tr, classes[mapStatus[d.status]])}
                                >
                                    <div className={classes.td}>
                                        <Typography className={d.status === Status.WARNING ? classes.darkText : ''}>
                                            {d.service}
                                        </Typography>
                                    </div>
                                </div>
                            )
                        })}
                    </Slide>
                    <Slide>
                        <div className={classes.tr}>
                            <div className={classes.th}>
                                <Typography className={classes.darkText}>Timestamp</Typography>
                            </div>
                        </div>

                        {data.map((_d, index) => {
                            const d = mapProperties(_d)
                            return (
                                <div
                                    key={index + d.timestamp + d.service}
                                    className={classNames(classes.tr, classes[mapStatus[d.status]])}
                                >
                                    <div className={classes.td}>
                                        <Typography className={d.status === Status.WARNING ? classes.darkText : ''}>
                                            {d.timestamp}
                                        </Typography>
                                    </div>
                                </div>
                            )
                        })}
                    </Slide>
                    <Slide>
                        <div className={classes.tr}>
                            <div className={classes.th}>
                                <Typography className={classes.darkText}>Status</Typography>
                            </div>
                        </div>

                        {data.map((_d, index) => {
                            const d = mapProperties(_d)
                            return (
                                <div
                                    key={index + d.timestamp + d.status + d.service}
                                    className={classNames(classes.tr, classes[mapStatus[d.status]])}
                                >
                                    <div className={classes.td}>
                                        <Typography className={d.status === Status.WARNING ? classes.darkText : ''}>
                                            {d.status}
                                        </Typography>
                                    </div>
                                </div>
                            )
                        })}
                    </Slide>
                    <Slide>
                        <div className={classes.tr}>
                            <div className={classes.th}>
                                <Typography className={classes.darkText}>Description</Typography>
                            </div>
                        </div>

                        {data.map((_d, index) => {
                            const d = mapProperties(_d)
                            return (
                                <div
                                    key={index + d.description + d.service}
                                    className={classNames(classes.tr, classes[mapStatus[d.status]])}
                                >
                                    <div className={classes.td}>
                                        <StyledTooltip placement="left-start" disableFocusListener title={d.description}>
                                            <Typography className={d.status === Status.WARNING ? classes.darkText : ''}>
                                                {d.description}
                                            </Typography>
                                        </StyledTooltip>
                                    </div>
                                </div>
                            )
                        })}
                    </Slide>
                </Swiper>
            </div>
        </div>
    )
}

MonitoringTableMobile.propTypes = {
    data: arrayOf(object),
    classes: object,
}

export default withStyles(styles)(MonitoringTableMobile)
