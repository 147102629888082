import { DateTime } from 'luxon'

/**
 *
 * @param {valueToCompare} a
 * @param {valueToCompare} b
 */
const numberSorter = (a, b) => Math.abs(a) - Math.abs(b)

/* This method calculate the time difference
       It takes planned time and real time as input parameter
       It returns the time difference after some calculation */
const calculateTimeDifference = (planned, real) => {
    if (!real) return 0
    //data from client comes with weird values after dot
    const strippedReal = real.split('.')[0]
    // this strips that
    const plannedTime = DateTime.fromFormat(planned, 'HH:mm:ss').toSeconds()
    const realTime = DateTime.fromFormat(strippedReal, 'HH:mm:ss').toSeconds()

    let oneDay = 24 * 60 * 60
    let timeDifferences = []
    // Formula to calculate time difference when no midnight cross
    timeDifferences.push(parseInt((realTime - plannedTime) / 60))
    // Formula to calculate time difference when backward midnight cross
    timeDifferences.push(parseInt((realTime - (plannedTime + oneDay)) / 60))
    // Formula to calculate time difference when forward midnight cross
    timeDifferences.push(parseInt((realTime + oneDay - plannedTime) / 60))
    let timeDifference = [...timeDifferences].sort(numberSorter)
    return timeDifference[0]
}

export default calculateTimeDifference
