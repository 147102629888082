export default theme => ({
    drawerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
    },
    drawerHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: `400px`,
        marginBottom: theme.spacing(3),
    },
    mb: {
        marginBottom: theme.spacing(2),
    },
    searchBarRoot: {
        // paddingTop: theme.spacing.double,
        // paddingBottom: theme.spacing.double,
        // paddingLeft: theme.spacing.triple,
        // paddingRight: theme.spacing.triple,
        display: 'flex',
        justifyContent: 'space-between',
    },
    radioGroup: {
        display: 'block',
    },
    buttonBottom: {
        marginTop: '24px',
    },
    divider: {
        marginBottom: 16,
        height: 2,
        backgroundColor: theme.customColors.lightGrey,
    },
})
