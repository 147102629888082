import React, { useContext } from 'react'
import { ThemeContext } from 'theme/ThemeProvider'

/* eslint-disable */
const PortalLogo = props => {
    const { themeName } = useContext(ThemeContext)
    let colorCode = themeName === 'light' ? '#000' : '#FFF'
    return (
        <svg
            data-testid="portal_logo"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 61 77"
            width={61}
            height={77}
            {...props}
        >
            <defs>
                <path
                    id="a"
                    d="M1.613 21.958V.03h2.039v21.926A2.634 2.634 0 1 1 0 24.386c0-1.092.665-2.028 1.613-2.428zm.976 4.244c1.021 0 1.85-.83 1.85-1.854a1.852 1.852 0 0 0-1.85-1.854c-1.021 0-1.85.83-1.85 1.854s.829 1.854 1.85 1.854z"
                />
                <path
                    id="b"
                    d="M1.613 21.958V.03h2.039v21.926A2.634 2.634 0 1 1 0 24.386c0-1.092.665-2.028 1.613-2.428zm.976 4.244c1.021 0 1.85-.83 1.85-1.854a1.852 1.852 0 0 0-1.85-1.854c-1.021 0-1.85.83-1.85 1.854s.829 1.854 1.85 1.854z"
                />
                <path
                    id="c"
                    d="M7.104 7.934H2.986l-.844 2.322H.054L3.944.091h2.288l3.861 10.165H7.948l-.844-2.322zm-3.46-1.807h2.802L5.045 2.285 3.643 6.127z"
                />
                <path id="d" d="M5.298 1.97v8.286H3.311V1.97H.107V.091h8.395V1.97z" />
                <path
                    id="e"
                    d="M.052 5.305c0-.822.143-1.56.43-2.215a5.145 5.145 0 0 1 1.143-1.67A4.939 4.939 0 0 1 3.285.365 5.417 5.417 0 0 1 5.257 0c.686 0 1.347.122 1.98.366.635.243 1.19.595 1.667 1.053a5.145 5.145 0 0 1 1.144 1.67c.286.655.429 1.394.429 2.216 0 .822-.143 1.558-.43 2.208a5.165 5.165 0 0 1-1.143 1.663 4.923 4.923 0 0 1-1.666 1.054 5.473 5.473 0 0 1-1.981.365 5.417 5.417 0 0 1-1.974-.365 4.939 4.939 0 0 1-1.659-1.054A5.165 5.165 0 0 1 .481 7.513C.195 6.863.052 6.127.052 5.305zm2.045-.015c0 .574.09 1.073.272 1.499.181.425.422.776.722 1.053.3.278.64.485 1.016.624a3.31 3.31 0 0 0 2.31 0 3.01 3.01 0 0 0 1.022-.624c.3-.277.541-.628.722-1.053.181-.426.272-.925.272-1.499 0-.573-.09-1.073-.272-1.498a3.02 3.02 0 0 0-.722-1.054 3.01 3.01 0 0 0-1.023-.623 3.31 3.31 0 0 0-2.31 0 3.025 3.025 0 0 0-1.015.623 3.02 3.02 0 0 0-.722 1.054c-.181.425-.272.925-.272 1.498z"
                />
                <path
                    id="f"
                    d="M1.974 6.428v3.828H0V.091h3.79c.496 0 .949.08 1.358.237.41.158.763.378 1.059.66.295.281.522.616.68 1.003.156.387.235.81.235 1.269 0 .459-.079.884-.236 1.276a2.772 2.772 0 0 1-.68 1.004 3.214 3.214 0 0 1-1.058.652c-.41.158-.862.236-1.358.236H1.974zm1.573-1.706c.496 0 .884-.131 1.165-.394.282-.263.422-.614.422-1.054 0-.458-.14-.817-.422-1.075-.281-.258-.67-.387-1.165-.387h-1.56v2.91h1.56z"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(24.592 27.9)">
                    <use
                        data-testid={`logo_fill_color_${colorCode}`}
                        fill="#000"
                        filter="url(#logo-(library)-a)"
                        xlinkHref="#a"
                    />
                    <use fill={colorCode} xlinkHref="#a" />
                    <path
                        fill={colorCode}
                        d="M2.923 24.218a.39.39 0 0 0-.103-.26c-.065-.07-.16-.105-.287-.105a.365.365 0 0 0-.277.114.427.427 0 0 0-.078.117.358.358 0 0 0-.033.134h.778zm.281.454a.627.627 0 0 1-.226.319.67.67 0 0 1-.413.132.747.747 0 0 1-.272-.05.664.664 0 0 1-.386-.39.855.855 0 0 1-.058-.323c0-.113.02-.215.057-.307a.764.764 0 0 1 .149-.237.662.662 0 0 1 .475-.21c.108 0 .206.019.291.056a.586.586 0 0 1 .215.152.677.677 0 0 1 .132.236.96.96 0 0 1 .04.393H2.135a.503.503 0 0 0 .036.175.397.397 0 0 0 .226.227c.052.02.108.031.167.031.117 0 .206-.027.267-.083a.48.48 0 0 0 .133-.204l.24.083z"
                    />
                </g>
                <g transform="matrix(1 0 0 -1 51.777 38.067)">
                    <use fill="#000" filter="url(#logo-(library)-c)" xlinkHref="#b" />
                    <use fill={colorCode} xlinkHref="#b" />
                </g>
                <path
                    fill={colorCode}
                    d="M54.762 13.589a.39.39 0 0 0-.104-.26c-.065-.07-.16-.105-.287-.105a.365.365 0 0 0-.277.114.427.427 0 0 0-.078.117.358.358 0 0 0-.033.134h.779zm.28.453a.627.627 0 0 1-.226.32.67.67 0 0 1-.412.131.747.747 0 0 1-.273-.05.664.664 0 0 1-.386-.389.855.855 0 0 1-.057-.323c0-.113.018-.215.056-.307a.764.764 0 0 1 .15-.237.662.662 0 0 1 .474-.21c.109 0 .206.019.292.055a.586.586 0 0 1 .214.153.677.677 0 0 1 .132.236.96.96 0 0 1 .04.393h-1.071a.503.503 0 0 0 .035.175.397.397 0 0 0 .226.227c.053.02.108.031.168.031.116 0 .205-.028.266-.083a.48.48 0 0 0 .133-.205l.24.083z"
                />
                <g>
                    <path fill={colorCode} d="M53.394 38.065V27.9h1.974v8.273h4.519v1.892z" />
                    <g transform="translate(42 27.809)">
                        <use fill="#000" filter="url(#logo-(library)-e)" xlinkHref="#c" />
                        <use fill={colorCode} xlinkHref="#c" />
                    </g>
                    <g transform="translate(34.11 27.809)">
                        <use fill="#000" filter="url(#logo-(library)-g)" xlinkHref="#d" />
                        <use fill={colorCode} xlinkHref="#d" />
                    </g>
                    <path
                        fill={colorCode}
                        d="M29.226 34.151h-1.03v3.914h-1.988V27.9h3.961c.496 0 .942.082 1.338.244.395.163.734.383 1.015.66.281.277.498.607.65.989.153.382.23.793.23 1.233 0 .717-.194 1.329-.58 1.835-.386.507-.913.856-1.58 1.047l2.174 4.157h-2.203l-1.987-3.914zm.572-1.706c.505 0 .896-.129 1.172-.387.277-.258.415-.597.415-1.018 0-.43-.138-.774-.415-1.032-.276-.258-.667-.387-1.172-.387h-1.602v2.824h1.602z"
                    />
                    <g transform="translate(14.014 27.685)">
                        <use fill="#000" filter="url(#logo-(library)-i)" xlinkHref="#e" />
                        <use fill={colorCode} xlinkHref="#e" />
                    </g>
                    <g transform="translate(6 27.809)">
                        <use fill="#000" filter="url(#logo-(library)-k)" xlinkHref="#f" />
                        <use fill={colorCode} xlinkHref="#f" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default PortalLogo
