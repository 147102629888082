import React, { Component } from 'react'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { UnfoldMore, UnfoldLess } from '@material-ui/icons'

import PerformanceChildFoldableRow from './PerformanceChildFoldableRow'
import Visibility from '@material-ui/icons/Visibility'

import styles from './styles'
import { func, object, array, bool } from 'prop-types'
class PerformanceChildList extends Component {
    state = {
        activeId: false,
        sortActive: false,
        sortkey: 'name',
        sortOrder: 'asc',
    }
    static propTypes = {
        translate: func.isRequired,
        classes: object.isRequired,
        data: array.isRequired,
        checkFunctionalityDisabled: bool.isRequired,
    }
    toggleFold = id => {
        const { activeId } = this.state
        if (activeId === id) {
            this.setState({ activeId: false })
        } else {
            this.setState({ activeId: id })
        }
    }

    render() {
        const { data, classes, translate, checkFunctionalityDisabled } = this.props
        const { activeId } = this.state

        return (
            <div>
                {data.map(
                    driver => (
                        <React.Fragment key={driver.idfNumber}>
                            <div style={{ display: 'flex' }}>
                                <Typography style={{ marginTop: 16 }} variant="h6">
                                    {driver.firstName + ' ' + driver.name + '   ' + driver.idfNumber}
                                </Typography>
                            </div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '5%' }} />

                                        <TableCell style={{ width: '15%' }}>
                                            <Typography
                                                variant="body2"
                                                // className={classes.underline}
                                            >
                                                {translate('task_label')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '15%' }}>
                                            <Typography
                                                variant="body2"
                                                // className={classes.underline}
                                            >
                                                {translate('attachment_label')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '40%' }}>
                                            <Typography
                                                variant="body2"
                                                // className={classes.underline}
                                            >
                                                {translate('short_content_label')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '15%' }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {driver.taskFeedback.map(item => (
                                        <React.Fragment key={item.taskId}>
                                            <TableRow
                                                style={{ height: '80px' }}
                                                // className={classes.clickableRow}
                                                // key={item.idfNumber}
                                                onClick={() => {
                                                    this.toggleFold(item.taskId)
                                                }}
                                            >
                                                <TableCell>
                                                    <Visibility className={classes.eyeIconBackground}></Visibility>
                                                </TableCell>
                                                <TableCell className={classes.cell}>
                                                    <Typography variant="body1"> {item.code}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body1">
                                                        {item.driveDetailFeedback[0]
                                                            ? item.driveDetailFeedback[0].pictures
                                                            : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography noWrap="true" variant="body1">
                                                        {item.comment}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {activeId === item.taskId ? (
                                                        <UnfoldMore
                                                            // className={classes.actionIcon}
                                                            onClick={() => {
                                                                this.toggleFold(item.taskId)
                                                            }}
                                                        />
                                                    ) : (
                                                        <UnfoldLess
                                                            // className={classes.actionIcon}
                                                            onClick={() => {
                                                                this.toggleFold(item.taskId)
                                                            }}
                                                        />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                            {activeId === item.taskId && (
                                                <TableRow style={{ height: '80px', backgroundColor: '#424242' }}>
                                                    <PerformanceChildFoldableRow
                                                        {...this.props}
                                                        taskDetails={item}
                                                        // classes={classes}
                                                        // handleDelete={this.handleDelete}
                                                        // handleEdit={this.handleEdit}
                                                        translate={translate}
                                                        checkFunctionalityDisabled={checkFunctionalityDisabled}
                                                    />
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </React.Fragment>
                    )
                    // ))
                )}
            </div>
        )
    }
}
export default withStyles(styles)(PerformanceChildList)
