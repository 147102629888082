import React, { useEffect} from 'react'
import { bool, func } from 'prop-types'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'

import VehicleSearch from '../VehicleSearch'
const AddVehicleView = props => {
    const { translate, isLittera, isNMBS, isUIC, handleLitteraCheckBox, handleNMBSCheckBox, handleUICCheckBox } = props
    useEffect(() => () => handleNMBSCheckBox(true), []);
    return (
        <div>
            <Typography variant="h6">{translate('composition_add_vehicle_drawer_title')}</Typography>
            <VehicleSearch {...props} />
            <div style={{ display: 'flex' }}>
                <FormControlLabel
                    control={<Checkbox checked={isNMBS} onChange={() => handleNMBSCheckBox(!isNMBS)} color="primary" />}
                    label={<Typography variant="body2">{translate('composition_view_nmbs_nr')}</Typography>}
                />
                <FormControlLabel
                    control={<Checkbox checked={isUIC} onChange={() => handleUICCheckBox(!isUIC)} color="primary" />}
                    label={<Typography variant="body2">{translate('composition_view_uic_nr')}</Typography>}
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={isLittera} onChange={() => handleLitteraCheckBox(!isLittera)} color="primary" />
                    }
                    label={<Typography variant="body2">{translate('composition_view_littera')}</Typography>}
                />
            </div>
        </div>
    )
}
AddVehicleView.propTypes = {
    translate: func,
    isLittera: bool,
    isNMBS: bool,
    isUIC: bool,
    handleLitteraCheckBox: func,
    handleNMBSCheckBox: func,
    handleUICCheckBox: func,
}
export default AddVehicleView
