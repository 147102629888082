export default theme => ({
    map_marker: {
        position: 'absolute',
        textDecoration: 'none',
        cursor: 'pointer',
        opacity: 0.1,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: '25px',
        minWidth: '100px',
    },
    one: { top: '17%', left: '4%' },
    two: { top: '12%', left: '34%' },
    'DISTRICT-1': { top: '20%', left: '15%' },
    'DISTRICT-2': { top: '18%', left: '55%' },
    'DISTRICT-3': { top: '34%', left: '39%' },
    'DISTRICT-4': { top: '51%', left: '30%' },
    'DISTRICT-5': { top: '57%', left: '67%' },
    'DISTRICT-6': { top: '80%', left: '0%', opacity: 1 },
})
