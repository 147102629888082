import axios from 'axios'
import queryParamEncoder from 'utils/queryParamEncoder'
import DefaultSettings from 'components/DefaultSettings'
export default async (trainNumber, date) => {
    let defaultParams = { lower: 1, upper: 1 }
    let fullUrl = `performance/trainNumber?trainNumber=${trainNumber}`
    const storedSettings = localStorage.getItem('userSettings')
        ? JSON.parse(localStorage.getItem('userSettings'))
        : DefaultSettings

    if (storedSettings) {
        let settings = storedSettings.filter(obj => obj.pageReference === 'trainTaskOverview')[0]

        defaultParams = {
            lower: parseInt(settings.startHour),
            upper: parseInt(settings.endHour),
            critical: parseInt(settings.critical),
            notAssignedWarning: parseInt(settings.warning),
        }
    }

    try {
        const response = await axios.get(fullUrl, {
            params: {
                date: date,
                ...defaultParams,
            },
            paramsSerializer: queryParamEncoder,
        })
        if (response.status === 204) {
            return []
        }
        return response.data
    } catch (e) {
        console.error(e) // eslint-disable-line
        return []
    }
}
