import React from 'react'
import { object, bool, func, string } from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import PneumaticSuspensionEditForm from './PneumaticSuspensionEditForm'
import PneumaticSuspensionAddForm from './PneumaticSuspensionAddForm'
import { TranslationContext } from 'context/translation'

const PneumaticSuspensionDrawer = props => {
    const { isOpen, onClose, editDrawer, classes, addEditError } = props

    const { translate } = React.useContext(TranslationContext)

    return (
        <Drawer open={isOpen} anchor="right" onClose={onClose}>
            <div className={classes.drawerRoot}>
                <Typography variant="h6">
                    {editDrawer
                        ? translate('compositions_edit_pneumatic_suspension_drawer_title')
                        : translate('compositions_add_pneumatic_suspension_drawer_title')}
                </Typography>
                {editDrawer ? (
                    <PneumaticSuspensionEditForm error={addEditError} {...props} />
                ) : (
                    <PneumaticSuspensionAddForm error={addEditError} {...props} />
                )}
            </div>
        </Drawer>
    )
}

PneumaticSuspensionDrawer.propTypes = {
    classes: object.isRequired,
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    editDrawer: bool,
    addEditError: string,
}

export default PneumaticSuspensionDrawer
