import React from 'react'
import { func, bool } from 'prop-types'

import PrimaryButton from 'components/PrimaryButton'
import { Translate } from 'react-localize-redux'

const ReadStatusButtonView = ({ setToRead, disabled }) => {
    return (
        <Translate>
            {({ translate }) => (
                <PrimaryButton title={translate('districts_mark_read')} onClick={setToRead} disabled={disabled} />
            )}
        </Translate>
    )
}

ReadStatusButtonView.propTypes = {
    setToRead: func.isRequired,
    disabled: bool.isRequired,
}

export default ReadStatusButtonView
