import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

// Material UI
import Button from '@material-ui/core/Button'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    indicator: {
        color: theme.customColors.white,
        minWidth: '32px',
        padding: theme.spacing(1) / 2,
    },
    onTime: {
        backgroundColor: green[500],
    },
    delayed: {
        backgroundColor: red[500],
    },
})

const Delayed = (classes, delay, openDelayReasonDialog, hours) => (
    <Button onClick={openDelayReasonDialog}>
        <span className={classnames(classes.indicator, classes.delayed)}>{hours ? delay : delay + ' min'}</span>
    </Button>
)

const OnTime = classes => (
    <Button>
        <span className={classnames(classes.indicator, classes.onTime)}>0</span>
    </Button>
)

const TimeDifferenceIndicator = ({ classes, delay, openDelayReasonDialog, hours }) => {
    if (delay <= -5 || delay >= 5) {
        return Delayed(classes, delay, openDelayReasonDialog, hours)
    }

    return OnTime(classes)
}

TimeDifferenceIndicator.propTypes = {
    classes: PropTypes.object.isRequired,
    delay: PropTypes.number,
    openDelayReasonDialog: PropTypes.func,
    hours: PropTypes.bool,
}

export default withStyles(styles)(TimeDifferenceIndicator)
