import React from 'react'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'

import PtCarDrawerStore from './PtCarDrawer.store'
import PtcarDrawer from './PtcarDrawer'
import styles from './styles'

import { object, number, bool, string } from 'prop-types'

const PtcarDrawerView = props => {
    return (
        <PtCarDrawerStore rowData={props.rowData} edit={props.edit} mapView={props.mapView} lineNumber={props.lineNumber}>
            <PtcarDrawer {...props} />
        </PtCarDrawerStore>
    )
}

PtcarDrawerView.propTypes = {
    rowData: object,
    edit: number,
    mapView: bool,
    lineNumber: string,
}

export default compose(withStore, withStyles(styles), withLocalize)(PtcarDrawerView)
