import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export const getFeedbackCall = async (setLoading, setData, scope) => {
    setLoading(true)
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/referencedata/feedbackcategories?scope=${scope}`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            setData([])
            setLoading(false)
        }
        if (response.status === 200) {
            setData(response.data)
            setLoading(false)
        }
    } catch (e) {
        console.error(e) // eslint-disable-line
    }
}

// eslint-disable-next-line no-unused-vars
export const updateFeedbackCategoryCall = async (data, scope) => {
    data.setLoading(true)
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/referencedata/feedbackcategories/update?scope=${scope}`
    try {
        const response = await axios.post(fullUrl, {
            id: data.selectedPerformance.id,
            parentId: data.parentId,
            descriptionFr: data.nameFr,
            descriptionNl: data.nameNl,
            titleFr: data.titleFr,
            titleNl: data.titleNl,
            fastFeedbackFr: data.fastFeedbackFr,
            fastFeedbackNl: data.fastFeedbackNl,
            signed: data.signed,
            e360: data.e360,
            isFirstDeparture: data.isFirstDeparture,
            businessTags: data.businessTags,
            weight: parseInt(data.order),
            restrictionLabelNl: data.restrictionLabelNl,
            restrictionLabelFr: data.restrictionLabelFr,
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            data.setIsOpen(true)
            data.setErrorMsg('')
        }
        if (response.status === 200) {
            data.METHODS.resetState()
            data.SERVICES.getFeedback(scope)
        }
    } catch (e) {
        console.error(e) // eslint-disable-line
    }
}

export const deleteFeedbackCategoryCall = async (data, scope) => {
    data.setLoading(true)
    // const selectedFeedCat = this.state.selectedPerformance;
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/referencedata/feedbackcategories/${data.selectedPerformance.id}/delete?scope=${scope}`
    try {
        const response = await axios.post(fullUrl, {
            id: data.selectedPerformance.id,
            parentId: data.parentId,
            descriptionFr: data.nameFr,
            descriptionNl: data.nameNl,
            titleFr: data.titleFr,
            titleNl: data.titleNl,
            signed: false,
            businessTags: data.businessTags,
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            data.setIsOpen(true)
            data.setErrorMsg('')
        }
        if (response.status === 200) {
            data.METHODS.resetState()
            data.SERVICES.getFeedback(scope)
        }
    } catch (e) {
        console.error(e) // eslint-disable-line
    }
}

export const addFeedbackCategoryCall = async (data, scope) => {
    // const selectedFeedCat = this.state.selectedPerformance;
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/referencedata/feedbackcategories/add?scope=${scope}`
    try {
        const response = await axios.post(fullUrl, {
            id: null,
            parentId: data.parentId,
            descriptionFr: data.nameFr,
            descriptionNl: data.nameNl,
            titleFr: data.titleFr,
            titleNl: data.titleNl,
            fastFeedbackFr: data.fastFeedbackFr,
            fastFeedbackNl: data.fastFeedbackNl,
            signed: data.signed,
            e360: data.e360,
            isFirstDeparture: data.isFirstDeparture,
            businessTags: data.businessTags,
            weight: parseInt(data.order),
            restrictionLabelNl: data.restrictionLabelNl,
            restrictionLabelFr: data.restrictionLabelFr,
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            this.setState({
                isOpen: true,
                errorMsg: '',
            })
            data.setIsOpen(true)
            data.setErrorMsg('')
        }

        if (response.status === 200) {
            data.SERVICES.getFeedback(scope)
            data.setIsOpen(true)
        }
    } catch (e) {
        if (e.response.status === 400) {
            data.setErrorMsg(e.response.data.Message)
            data.setIsOpen(true)
            data.setLoading(false)
        } else {
            data.setErrorMsg('Request failed')
            data.setLoading(false)
        }
        console.error(e) // eslint-disable-line
    }
}

export const handleSaveSortCall = async (props, data, scope) => {
    props.setSortError('')
    props.setEditSortLoading(true)

    const { url } = requestData
    const fullUrl = `${url}/referencedata/fastfeedbackweight/update`
    const bodyObject = JSON.stringify(props.METHODS.adjustData(data))
    try {
        const response = await axios.post(fullUrl, bodyObject)
        if (response.status === 200) {
            props.METHODS.resetState()
            props.SERVICES.getFeedback(scope)
        }
    } catch (e) {
        props.setSortError('error')
        props.setEditSortLoading(false)
        console.error(e) // eslint-disable-line
    }
}
