export default theme => ({
    drawerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '80vh',
        [theme.breakpoints.down('lg')]: {
            height: '75vh',
        },
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
    },
    readList: {
        maxHeight: `calc(100vh - ${theme.readList + 116}px)`,
        overflowY: 'auto',
        marginBottom: theme.spacing(2),
    },
    profileInfo: {
        marginTop: theme.spacing(2),
    },
    profileInfoTitle: {
        fontWeight: 'bold',
    },
    topSpacingTitle: {
        marginTop: theme.spacing(3),
    },
    column: {
        float: 'left',
        width: '50%',
        padding: '10px',
    },
    buttonBottom: {
        position: 'absolute',
        bottom: `${theme.spacing(3)}px`,
        left: `${theme.spacing(3)}px`,
    },
})
