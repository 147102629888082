import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import styles from './styles'
import { Typography } from '@material-ui/core'
import { DateTime } from 'luxon'

const DetailsDrawer = props => {
    return (
        <Drawer anchor={'right'} open={props.isOpen} onClose={props.handleClose}>
            <div className={props.classes.drawer}>
                {props.data != null && (
                    <>
                        {props.data.service !== null && props.data.service !== '' && (
                            <>
                                <Typography>Service</Typography>
                                <Typography className={props.classes.text}>{props.data.service}</Typography>
                            </>
                        )}
                        <Typography>Timestamp</Typography>
                        <Typography className={props.classes.text}>
                            {new DateTime(props.data.timestamp).toFormat('dd-MM-yyyy HH:mm')}
                        </Typography>
                        {props.data.description !== null && props.data.description !== '' && (
                            <>
                                <Typography>Description</Typography>
                                <Typography className={props.classes.text}>{props.data.description}</Typography>
                            </>
                        )}
                        {props.data.details !== null && props.data.details !== '' && (
                            <>
                                <Typography>Details</Typography>
                                <Typography className={props.classes.text}>{props.data.details}</Typography>
                            </>
                        )}
                        {props.data.exception !== null && props.data.exception !== '' && (
                            <>
                                <Typography>Exception</Typography>
                                <Typography className={props.classes.text}>{props.data.exception}</Typography>
                            </>
                        )}
                    </>
                )}
            </div>
        </Drawer>
    )
}

DetailsDrawer.propTypes = {
    isOpen: PropTypes.boolean,
    handleClose: PropTypes.func,
    classes: PropTypes.object,
    data: PropTypes.object,
}

export default withStyles(styles)(DetailsDrawer)
