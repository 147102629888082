import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import axios from 'axios'
import { withStyles } from '@material-ui/core'
import { withStore } from 'react-ion-store'

import Header from 'components/Header'
import LindaRolesView from './LindaRolesView'
import NoRights from '../NoRights'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import getTranslation from 'utils/returnTranslationForLocale'

import styles from './styles'
import checkWriteAccess from 'utils/checkWriteAccess'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export class LindaRoles extends Component {
    static propTypes = {
        translate: PropTypes.func,
        store: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            lindaRolesData: [],
            lindaRolesLoading: false,
            lindaRolesError: '',
            openDrawer: false,
            editDrawer: false,
            dialogOpen: false,
            deleteData: [],
            editData: [],
            addEditError: '',
            addEditLoading: false,
        }
    }

    componentDidMount() {
        const { translate } = this.props
        this.getLindaRoles()
        document.title = translate('module_manage_linda_roles') || 'Linda roles'
    }

    onDrawerClose = () => {
        this.setState({ openDrawer: false, editDrawer: false, addEditError: '', addEditLoading: false })
    }

    onDrawerOpen = (data, edit = false) => {
        this.setState({ openDrawer: true, editDrawer: edit, editData: data })
    }
    onDialogClose = () => {
        this.setState({ dialogOpen: false, deleteData: [] })
    }

    onDialogOpen = (e, rowData) => {
        this.setState({ dialogOpen: true, deleteData: rowData })
    }

    handleAdd = (data, edit) => {
        this.setState({ addEditError: '', addEditLoading: true }, () => {
            if (edit) {
                this.editCall(data)
            } else {
                this.addCall(data)
            }
        })
    }

    addCall = async data => {
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/linda/roles/add`

        try {
            const response = await axios.post(fullUrl, {
                DescriptionNl: data.descriptionNl,
                DescriptionFr: data.descriptionFr,
                EDriveRoleCode: data.edriveRoleCode,
                LindaRoleCode: data.lindaRoleCode,
                AlascaCode: data.alascaCode,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.setState({ addEditError: '', addEditLoading: false }, () => {
                    this.getLindaRoles()
                    this.onDrawerClose()
                })
            }
        } catch (e) {
            this.setState({
                addEditError: 'error',
                addEditLoading: false,
            })
            console.error(e) // eslint-disable-line
        }
    }

    editCall = async data => {
        const { url, subscriptionKey } = requestData
        const { editData } = this.state

        const fullUrl = `${url}/linda/roles/update`

        try {
            const response = await axios.post(fullUrl, {
                canEditCompositions: editData.canEditCompositions,
                id: editData.id,
                DescriptionNl: data.descriptionNl,
                DescriptionFr: data.descriptionFr,
                EDriveRoleCode: data.edriveRoleCode,
                LindaRoleCode: data.lindaRoleCode,
                AlascaCode: data.alascaCode,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.setState({ addEditError: '', addEditLoading: false }, () => {
                    this.getLindaRoles()
                    this.onDrawerClose()
                })
            }
        } catch (e) {
            this.setState({
                addEditError: 'error',
                addEditLoading: false,
            })
            console.error(e) // eslint-disable-line
        }
    }

    getLindaRoles = async () => {
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/reference/lindauserroles`

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.setState({
                    lindaRolesData: [],
                    lindaRolesLoading: false,
                    lindaRolesError: '',
                })
            }
            if (response.status === 200) {
                this.setState({
                    lindaRolesData: response.data,
                    lindaRolesLoading: false,
                    lindaRolesError: '',
                })
            }
        } catch (e) {
            this.setState({
                lindaRolesData: [],
                lindaRolesLoading: false,
                lindaRolesError: 'error',
            })
            console.error(e) // eslint-disable-line
        }
    }

    render() {
        if (checkWriteAccess(this.props, 'lindaRolesView', 'manage.functionality.management.linda')) {
            return (
                <React.Fragment>
                    <Header title={this.props.translate('module_manage_linda_roles')} />
                    <LindaRolesView
                        {...this.props}
                        {...this.state}
                        getTranslation={getTranslation}
                        checkFunctionalityDisabled={checkFunctionalityDisabled}
                        onDrawerClose={this.onDrawerClose}
                        onDrawerOpen={this.onDrawerOpen}
                        onDialogClose={this.onDialogClose}
                        onDialogOpen={this.onDialogOpen}
                        reloadRoles={this.getLindaRoles}
                        handleAdd={this.handleAdd}
                    />
                </React.Fragment>
            )
        } else {
            return <NoRights />
        }
    }
}

export default compose(withLocalize, withStore, withStyles(styles))(LindaRoles)
