import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

import { Paper, Typography } from '@material-ui/core'

export class ReplaceDriverDialog extends PureComponent {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        handleReplace: PropTypes.func,
        disableReplace: PropTypes.bool,
    }

    render() {
        const { open, handleClose, handleReplace, disableReplace } = this.props
        return (
            <Dialog open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Vervangen</DialogTitle>
                <Paper>
                    <DialogContent style={{ minWidth: '600px' }}>
                        <DialogContentText>Driver vervangen</DialogContentText>
                        <Typography variant="subtitle1">
                            {`Deze bestuurder is al aan een andere prestatie toegevoegd.`}
                        </Typography>
                        <Typography variant="subtitle1">{`Toch vervangen?`}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                handleClose()
                            }}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={disableReplace}
                            onClick={() => {
                                handleReplace()
                            }}
                            color="primary"
                            // disabled={loading}
                        >
                            Replace
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        )
    }
}

export default withStyles(styles)(ReplaceDriverDialog)
