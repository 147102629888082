import React from 'react'
import { object, bool, func, string, any } from 'prop-types'
import { Translate } from 'react-localize-redux'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import PersonSearcher from 'components/PersonSearcher'
import ClearCacheButton from '../ClearCacheButton'
import ClearList from '../ClearList'

const ClearCacheDrawerView = ({
    classes,
    open,
    onClose,
    selectedName,
    selectedProfile,
    setToClear,
    setName,
    setDeviceId,
    deviceId,
    clearName,
    clearCacheWasSet,
    refetchForUser,
}) => {
    let idf

    if (selectedProfile) idf = selectedProfile.idfNumber || selectedProfile.id

    return (
        <Drawer open={open} anchor="right" style={{ zIndex: 1500 }} onClose={onClose}>
            <div className={classes.drawerRoot}>
                <div>
                    <Typography variant="h6" style={{ marginBottom: 24 }}>
                        <Translate id="documents_header_clear_cache" />
                    </Typography>
                    <Typography variant="h6">
                        <Translate id="districts_read_label_person" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <div style={{ position: 'relative' }} name="PersonSearcherWrapper" tabIndex={0}>
                        <PersonSearcher {...{ selectedName, setName, clearName }} />
                    </div>
                    {selectedName && selectedProfile && (
                        <div className={classes.profileInfo}>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_name" />:
                                </span>
                                {` ${selectedName}`}
                            </Typography>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_idf" />:
                                </span>
                                {` ${selectedProfile.idfNumber}`}
                            </Typography>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_user_name" />:
                                </span>
                                {` ${selectedProfile.userName}`}
                            </Typography>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_org" />:
                                </span>{' '}
                                {(selectedProfile.organisations || []).map(org => org.organisationCode).join(', ')}
                            </Typography>
                        </div>
                    )}
                    <Typography variant="h6" className={classes.topSpacingTitle}>
                        <Translate id="documents_label_device" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <Translate>
                        {({ translate }) => (
                            <TextField
                                fullWidth={true}
                                value={deviceId}
                                onChange={e => setDeviceId(e.target.value)}
                                placeholder={translate('documents_label_clear_cache_enter_device')}
                            />
                        )}
                    </Translate>
                    <Typography variant="h6" style={{ marginTop: 48 }}>
                        <Translate id="documents_label_clear_cache_history" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <div className={classes.readList}>
                        {selectedName && selectedProfile ? (
                            <ClearList name={selectedName} idf={idf} refetchForUser={refetchForUser} />
                        ) : (
                            <Typography variant="body1">
                                <Translate id="districts_read_history_prompt" />
                            </Typography>
                        )}
                    </div>
                </div>
                <ClearCacheButton {...{ idf, deviceId, setToClear, clearCacheWasSet }} />
            </div>
        </Drawer>
    )
}

ClearCacheDrawerView.propTypes = {
    classes: object.isRequired,
    open: bool.isRequired,
    onClose: func.isRequired,
    selectedName: string.isRequired,
    selectedProfile: object,
    refetchForUser: any,
    deviceId: string,
    setToClear: func.isRequired,
    setName: func.isRequired,
    setDeviceId: func.isRequired,
    clearName: func.isRequired,
    clearCacheWasSet: bool.isRequired,
}

export default ClearCacheDrawerView
