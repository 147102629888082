export default theme => ({
    drawerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: theme.moduleMenu,
        padding: `${56}px 0 ${theme.spacing(3)}px`,
        [theme.breakpoints.up('sm')]: {
            padding: `${64}px 0 ${theme.spacing(3)}px`,
        },
    },
    menuList: {
        paddingTop: 0,
        paddingBottom: theme.spacing(3),
    },
    languagePicker: {
        padding: `0 ${theme.spacing(3)}px`,
    },
})
