import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import IconBox from '../_shared/IconBox'

export class ContactInformation extends Component {
    static propTypes = {
        referencedPerformance: PropTypes.object,
    }
    render() {
        const { performanceId, contactInformationObj } = this.props.referencedPerformance

        let fullName = ''
        let phoneNumber = ''

        if (contactInformationObj) {
            fullName = `${contactInformationObj.firstName} ${contactInformationObj.lastName}`
            phoneNumber = contactInformationObj.telephoneNumber
        }
        return (
            <React.Fragment>
                <Grid item xs={4}>
                    <IconBox icon="link" title={'performance_task_label_contact_afrel'} value={performanceId} />
                </Grid>
                {fullName.length > 0 && (
                    <Grid item xs={4}>
                        <IconBox icon="contact" title={'performance_task_label_contact_person'} value={fullName} />
                    </Grid>
                )}
                {phoneNumber.length > 0 && (
                    <Grid item xs={4}>
                        <IconBox icon="phone" title={'performance_task_label_contact_phone'} value={phoneNumber} />
                    </Grid>
                )}
            </React.Fragment>
        )
    }
}
export default ContactInformation
