import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import IconButton from 'components/IconButton'
import Grid from '@material-ui/core/Grid'
import ErrorMessage from 'components/ErrorMessage'
import InputLabel from '@material-ui/core/InputLabel'
import SelectInput from 'components/SelectInput'
import Loading from 'components/Loading'
const textField = (label, value, callback, register, errors, name, type = 'text') => {
    return (
        <TextField
            margin="dense"
            inputRef={register !== undefined ? register({ required: true }) : null}
            error={errors && !!errors[`${name}`]}
            id={name}
            name={name}
            label={label}
            type={type}
            fullWidth
            defaultValue={value}
            onChange={e => {
                callback(e.target.value)
            }}
        />
    )
}

function VacmaRestrictionForm(props) {
    const { translate, handleAdd, error, editDrawer, editData, materialTypes, addEditLoading } = props

    const [type, setType] = useState(editDrawer ? editData?.materialSubTypeId || '' : materialTypes[0].id)

    const [restrictionPercentageVacma1, setVacma1] = useState(
        editDrawer ? parseInt(editData?.restrictionPercentageVacma1) || null : null
    )
    const [restrictionPercentageVacma2, setVacma2] = useState(
        editDrawer ? parseInt(editData?.restrictionPercentageVacma2) || null : null
    )

    const { register, handleSubmit, errors, control, classes, setValue } = useForm()

    const submitTheForm = data => {
        data.restrictionPercentageVacma1 = parseInt(data.restrictionPercentageVacma1)
        data.restrictionPercentageVacma2 = parseInt(data.restrictionPercentageVacma2)
        handleAdd(data, editDrawer)
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(submitTheForm)} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ display: 'block', marginTop: '18px', marginBottom: '18px' }}>
                            <InputLabel htmlFor={'materialSubTypeId'}>{'materialSubTypeId'}</InputLabel>
                            <Controller
                                as={
                                    <SelectInput
                                        minWidth={200}
                                        error={errors && !!errors[`materialSubTypeId`]}
                                        id="materialSubTypeId"
                                        classes={classes}
                                        name="materialSubTypeId"
                                        items={materialTypes}
                                    />
                                }
                                onChange={([event]) => {
                                    setType(event.target.value)
                                    setTimeout(() => setValue('materialSubTypeId', event.target.value, true))
                                }}
                                defaultValue={type}
                                rules={{ required: true, minLength: 1 }}
                                register={register}
                                name="materialSubTypeId"
                                control={control}
                                disabled={editDrawer}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {textField(
                            'Vacma 1 limitation %',
                            restrictionPercentageVacma1,
                            setVacma1,
                            register,
                            errors,
                            'restrictionPercentageVacma1',
                            'number'
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {textField(
                            'Vacma 2 limitation %',
                            restrictionPercentageVacma2,
                            setVacma2,
                            register,
                            errors,
                            'restrictionPercentageVacma2',
                            'number'
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        {error && error?.length > 0 && (
                            <ErrorMessage
                                error={editDrawer ? translate('platform_edit_failed') : translate('platform_add_failed')}
                                spacing={false}
                            />
                        )}
                        {editDrawer && (
                            <IconButton
                                disabled={addEditLoading}
                                type="submit"
                                icon="save"
                                title={translate('add_user_confirm_save')}
                            />
                        )}
                        {editDrawer === false && (
                            <IconButton
                                disabled={addEditLoading}
                                icon="save"
                                type="submit"
                                title={translate('button_add')}
                            />
                        )}
                        {addEditLoading && <Loading />}
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

VacmaRestrictionForm.propTypes = {
    classes: PropTypes.object,
    translate: PropTypes.func,
    handleAdd: PropTypes.func,
    error: PropTypes.string,
    editDrawer: PropTypes.bool,
    addEditLoading: PropTypes.bool,
    editData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    materialTypes: PropTypes.array,
}

export default VacmaRestrictionForm
