export default theme => ({
    mb: {
        marginBottom: theme.spacing(2),
    },
    boldText: {
        fontWeight: 'bold',
        color: theme.customColors.white,
    },
    flexAlign: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    halfButton: {
        width: '45%',
    },
    fullButton: {
        width: '100%',
    },
    deleteButton: {
        color: theme.customColors.red,
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
    mandatory_field: {
        color: theme.customColors.red,
    },
})
