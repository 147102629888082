import axios from 'axios'

export default async () => {
    const fullUrl = `/timetable/rivassource/rivvalues`

    try {
        return await axios.get(fullUrl)
    } catch (e) {
        return {
            status: 204,
        }
    }
}
