import React from 'react'
import { object, func, string, bool } from 'prop-types'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import { withLocalize, Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import SearchBarTrainDriver from '../SearchBarTrainDriver'
import SearchBarDoc from '../SearchBarDoc'

import TextField from '@material-ui/core/TextField'
import PersonSearcher from 'components/PersonSearcher'
import Typography from '@material-ui/core/Typography'

import styles from './styles'

class SearchBar extends React.Component {
    static propTypes = {
        persist: func.isRequired,
        initCall: func.isRequired,
        classes: object.isRequired,
        store: object.isRequired,
        radio: string.isRequired,
        org: string.isRequired,
        depot: string.isRequired,
        doc: string.isRequired,
        map: string.isRequired,
        idf: object.isRequired,
        file: string.isRequired,
        history: object.isRequired,
        location: object.isRequired,
        match: object.isRequired,
        disabled: bool,
        clearName: func,
        selectedName: string,
        setName: func,
        selectedOrganisation: string,
    }

    handleEvent = type => e => this.props.persist(type)(e.target.value)

    onClick = () => {
        if (this.props.radio === 'doc') {
            this.props.initCall({ type: 'doc', data: this.props.map, search: this.props.file })
        } else if (this.props.radio === 'tbs' && this.props.depot === ' ') {
            this.props.initCall({ type: 'tbs', data: this.props.org, search: this.props.idf })
        } else if (this.props.radio === 'tbs' && this.props.depot !== ' ') {
            this.props.initCall({ type: 'tbs', data: this.props.depot, search: this.props.idf })
        }

        const { history, location, match } = this.props
        if (location.pathname === `${match.path}/detail`) {
            history.replace(`${match.path}`)
        }
    }

    render() {
        const { classes, clearName, selectedName, setName, selectedOrganisation, file, persist } = this.props

        return (
            <Translate>
                {({ translate }) => (
                    <div>
                        <SearchBarDoc
                            className={classes.dropdownGroup + ' ' + classes.paddedGroup}
                            handleEvent={this.handleEvent}
                            doc={this.props.doc}
                            map={this.props.map}
                            search={this.props.file}
                            persist={this.props.persist}
                            disabled={this.props.disabled}
                        />
                        <div style={{ position: 'relative', marginTop: 16 }} name="FilenameTextfield" tabIndex={0}>
                            <TextField
                                fullWidth
                                value={file}
                                placeholder={translate('documents_prompt')}
                                onChange={e => persist('file')(e.target.value)}
                            />
                        </div>
                        <Typography style={{ marginTop: 16 }} variant="h6">
                            <Translate id="search_label_org" />
                        </Typography>
                        <hr style={{ marginBottom: 16 }} />
                        <SearchBarTrainDriver
                            className={classes.dropdownGroup + ' ' + classes.paddedGroup}
                            handleEvent={this.handleEvent}
                            org={this.props.org}
                            depot={this.props.depot}
                            selectedProfile={this.props.idf}
                            persist={this.props.persist}
                            disabled={this.props.disabled}
                            translate={translate}
                        />
                        <div
                            style={{ position: 'relative', marginBottom: 16, marginTop: 16 }}
                            name="PersonSearcherWrapper"
                            tabIndex={0}
                        >
                            <PersonSearcher
                                clearName={clearName}
                                selectedName={selectedName}
                                setName={setName}
                                type="app"
                                selectedOrganisation={selectedOrganisation}
                            />
                        </div>
                    </div>
                )}
            </Translate>
        )
    }
}

export default compose(withStore, withStyles(styles), withLocalize, withRouter)(SearchBar)
