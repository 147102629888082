import axios from 'axios'

export default date => {
    const fullUrl = `/compositionReferenceData/maximumSpeedPulled?date=${date}`

    return axios.get(fullUrl, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
