export default theme => ({
    documentTableWrapper: {
        padding: `0 ${theme.spacing(3)}px`,
        width: '100%',
        minHeight: theme.readList,
        height: `calc(100vh - ${theme.performanceView}px)`,
        marginBottom: theme.spacing(2),
    },

    editIcon: {
        marginLeft: '50px',
        fill: theme.customColors.white,
        cursor: 'pointer',
    },
    icon: {
        fill: theme.customColors.iconFront,
        background: theme.customColors.iconBg,
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        padding: `${theme.spacing(2)}px 0`,
        justifyContent: 'flex-end',
    },
    drawerRoot: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
    },
})
