import React from 'react'
import { object, bool, func, string, any } from 'prop-types'
import { Translate } from 'react-localize-redux'

import Header from 'components/Header'
import Typography from '@material-ui/core/Typography'

import PersonSearcher from 'components/PersonSearcher'
import ReadStatusButton from '../../components/ReadStatusButton'
import ReadList from '../../components/ReadList'

const MarkAsReadView = ({
    classes,
    selectedName,
    selectedProfile,
    setToRead,
    setName,
    clearName,
    readStateWasSet,
    refetchForUser,
}) => {
    let idf

    if (selectedProfile) idf = selectedProfile.idfNumber || selectedProfile.id

    return (
        <React.Fragment>
            <Translate>{({ translate }) => <Header isMenu title={translate('module_markasread')} />}</Translate>
            <div className={classes.container}>
                <div style={{ width: '50%' }}>
                    <Typography variant="h6" style={{ marginBottom: 24 }}>
                        <Translate id="distrist_documents_read" />
                    </Typography>
                    <Typography variant="h6">
                        <Translate id="districts_read_label_person" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <div style={{ position: 'relative' }} name="PersonSearcherWrapper" tabIndex={0}>
                        <PersonSearcher {...{ selectedName, setName, clearName }} />
                    </div>
                    {selectedName && selectedProfile && (
                        <div className={classes.profileInfo}>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_name" />:
                                </span>
                                {` ${selectedName}`}
                            </Typography>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_idf" />:
                                </span>
                                {` ${selectedProfile.idfNumber}`}
                            </Typography>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_user_name" />:
                                </span>
                                {` ${selectedProfile.userName}`}
                            </Typography>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_org" />:
                                </span>{' '}
                                {(selectedProfile.organisations || []).map(org => org.organisationCode).join(', ')}
                            </Typography>
                        </div>
                    )}
                    <div style={{ marginTop: 16 }}>
                        <ReadStatusButton {...{ idf, setToRead, readStateWasSet }} />
                    </div>
                    <Typography variant="h6" style={{ marginTop: 48 }}>
                        <Translate id="districts_read_history" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <div className={classes.readList}>
                        {selectedName && selectedProfile ? (
                            <ReadList name={selectedName} idf={idf} refetchForUser={refetchForUser} />
                        ) : (
                            <Typography variant="body1">
                                <Translate id="districts_read_history_prompt" />
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

MarkAsReadView.propTypes = {
    classes: object.isRequired,
    selectedName: string.isRequired,
    selectedProfile: object,
    refetchForUser: any,
    setToRead: func.isRequired,
    setName: func.isRequired,
    clearName: func.isRequired,
    readStateWasSet: bool.isRequired,
}

export default MarkAsReadView
