/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'

import { withStore } from 'react-ion-store'

import * as INITIAL_STATE from './initialState'
import { menuFilter } from './filterMenu'

import { doCall } from './allowedDistricts'

export const MenuContext = React.createContext(INITIAL_STATE)

const MenuProvider = ({ children, store }) => {
    const [menuTree, setMenuTree] = React.useState(undefined)
    const [roles, setRoles] = React.useState(store.get('roles'))
    const [moduleList, setModuleList] = React.useState(store.get('moduleList'))
    const [allowedDistricts, setAllowedDistricts] = React.useState([])

    const [isMounted, setIsMounted] = React.useState(false)

    const handleOpenState = async (id, isSubModule, isOpen, parentId) => {
        let newTree = menuTree
        if (!isSubModule) {
            const toOpen = newTree.find((el, ind) => el.id === id)
            const ind = newTree.indexOf(toOpen)
            newTree = menuFilter(
                INITIAL_STATE.menuTree,
                moduleList,
                store.get('userRoles'),
                store.get('auth'),
                allowedDistricts
            )
            newTree[ind] = { ...toOpen, isOpen: !isOpen }
            setMenuTree([...newTree])
        } else {
            const toOpen = newTree.find(mod => mod.id === parentId).subModules.find(mod => mod.id === id)
            const ind = newTree.find(mod => mod.id === parentId).subModules.indexOf(toOpen)
            newTree.find(mod => mod.id === parentId).subModules[ind] = { ...toOpen, isOpen: !isOpen }
            setMenuTree([...newTree])
        }
    }

    React.useMemo(async () => {
        if (moduleList) {
            setMenuTree(
                menuFilter(INITIAL_STATE.menuTree, moduleList, store.get('userRoles'), store.get('auth'), allowedDistricts)
            )
        }
    }, [moduleList, roles, allowedDistricts])

    React.useEffect(() => {
        if (store.get('roles') !== undefined) {
            setRoles(store.get('roles'))
        }

        if (store.get('moduleList') !== undefined) {
            setModuleList(store.get('moduleList'))
        }

        const setDistricts = async () => {
            const res = await doCall()
            if (res.status === 200) {
                setAllowedDistricts(res)
            } else {
                setAllowedDistricts([])
            }
        }

        const bearerListener = window.addEventListener('bearerSet', () => {
            setDistricts()
        })

        setDistricts()

        return () => window.removeEventListener('bearerSet', bearerListener)
    }, [store])

    return (
        <MenuContext.Provider value={{ menuTree, moduleList, handleOpenState, allowedDistricts }}>
            {children}
        </MenuContext.Provider>
    )
}

MenuProvider.propTypes = {
    children: PropTypes.node,
    store: PropTypes.object,
}

export default withStore(MenuProvider)
