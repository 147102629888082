export default theme => ({
    container: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    noSearch: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
})
