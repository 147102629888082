import React from 'react'
import { bool, func, object, node, string } from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import { withStyles } from '@material-ui/styles'

import styles from './styles'
import { Typography } from '@material-ui/core'

const AppDrawer = props => {
    const { isOpen, onClose, classes, title, children, width } = props

    return (
        <Drawer data-testid="root" open={isOpen} anchor="right" onClose={onClose}>
            <div className={classes.drawer} style={{ width: width ? width : '500px' }}>
                {!!title && (
                    <Typography data-testid="title" className={classes.text} variant="h6">
                        {title}
                    </Typography>
                )}

                {!!children && children}
            </div>
        </Drawer>
    )
}

AppDrawer.propTypes = {
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    classes: object,
    children: node.isRequired,
    title: string.isRequired,
    width: string,
}

export default withStyles(styles)(AppDrawer)
