import React from 'react'
import { object, bool, string, oneOfType, number, func } from 'prop-types'
import { Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import VehicleType from '../../Utils/VehicleType'

const MUCView = props => {
    const showCockpit = () => {
        if (props.vehicleType === VehicleType.LITTERA) return false
        return props.vehicleType === VehicleType.MOTORUNIT || props.hasDriverCockpit
    }
    const showUnitNumber = () => {
        return props.vehicleType !== VehicleType.LITTERA
    }
    let vehicleUnit = showUnitNumber() && props?.vehicleUnit ? '-' + props.vehicleUnit : ''
    if (!props.vehicleNumber) return null
    return (
        <div className={props.hasDamage ? props.classes.materialUnitChildYellow : props.classes.materialUnitChild}>
            {props.vehicleNumber && (
                <InfoIcon className={props.classes.infoIcon} onClick={() => props.handleMUCInfoClick(props.vehicleNumber)} />
            )}
            {showCockpit() && props.showFront && (
                <Typography variant="body2" className={props.classes.materialUnitNumberField}>
                    {props.inDirection ? 1 : 2}
                </Typography>
            )}
            <Typography variant="body2">{props?.vehicleNumber ? `${props?.vehicleNumber} ${vehicleUnit}` : ''}</Typography>
            <Typography variant="body2">{props.name}</Typography>
            {showCockpit() && props.showBack && (
                <Typography variant="body2" className={props.classes.materialUnitNumberField}>
                    {props.inDirection ? 2 : 1}
                </Typography>
            )}
        </div>
    )
}
MUCView.propTypes = {
    classes: object,
    hasDriverCockpit: bool,
    inDirection: bool,
    showFront: bool,
    showBack: bool,
    vehicleNumber: oneOfType([string, number]),
    vehicleUnit: oneOfType([string, number]),
    name: string,
    litteraName: string,
    hasDamage: bool,
    vehicleType: number,
    handleMUCInfoClick: func,
}
export default MUCView
