import React from 'react'
import { object } from 'prop-types'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'

import styles from './styles'
import { TranslationContext } from 'context/translation'

const LanguagePicker = ({ classes }) => {
    const { activeLanguage, setActiveLanguage } = React.useContext(TranslationContext)

    const changeLanguage = code => {
        setActiveLanguage(code)
        localStorage.setItem('language', code)
    }

    const switchLanguage = {
        nl: 'fr',
        fr: 'nl',
    }

    if (!activeLanguage) {
        return null
    }

    return (
        <div>
            <Typography className={classes.inlineElement}>Nederlands</Typography>

            <Switch
                data-testid="language-switch"
                checked={activeLanguage.code === 'fr'}
                onChange={() => changeLanguage(switchLanguage[activeLanguage.code])}
                value="languageSwitch"
                color="primary"
                classes={{
                    switchBase: classes.colorSwitchBase,
                    checked: classes.colorChecked,
                }}
            />
            <Typography className={classes.inlineElement}>Francais</Typography>
        </div>
    )
}

LanguagePicker.propTypes = {
    classes: object.isRequired,
}

export default withStyles(styles)(LanguagePicker)
