import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import LocationSearchView from './LocationSearchView'
import styles from './styles'

class LocationSearch extends Component {
    static propTypes = {
        allLocations: PropTypes.array,
        locationSelectValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }
    findAndReturnObject = value => {
        const { allLocations } = this.props

        if (allLocations.length <= 0) return { value: '', label: 'Choose location', code: '' }

        return allLocations.find(item => item.value === value)
    }

    makeObjectFromData = value => {
        if (value === '') return { value: '', label: 'Choose location', code: '' }
        if (typeof value === 'object') return value

        if (typeof value === 'string') return this.findAndReturnObject(value)

        return null
    }

    render() {
        const { locationSelectValue } = this.props

        const selectvalue = this.makeObjectFromData(locationSelectValue)

        return <LocationSearchView defaultSelect={selectvalue} {...this.props} />
    }
}

export default withStyles(styles)(LocationSearch)
