/* eslint-disable react/no-deprecated */

import React from 'react'
import ReactDOM from 'react-dom'
import { unregister } from './registerServiceWorker'

import StoreProvider from 'react-ion-store'
import { LocalizeProvider } from 'react-localize-redux'
import TranslationProvider from './context/translation'

import App from './App'
import * as ionStore from './store'

unregister()

ReactDOM.render(
    <StoreProvider store={ionStore.store} staticKeys={ionStore.staticKeys}>
        <LocalizeProvider>
            <TranslationProvider>
                <App />
            </TranslationProvider>
        </LocalizeProvider>
    </StoreProvider>,
    document.getElementById('root')
)
