import React, { useEffect, useReducer } from 'react'
import Header from 'components/Header'
import getVehicleDetails from '../service/getVehicleDetails'
import { INITIAL_STATE, VehicleDetailsReducer } from './VehicleDetailsReducer'
import { object } from 'prop-types'
import styles from './styles'
import { withStyles } from '@material-ui/styles'

import VehicleDetailsActions from './VehicleDetailsActions'
import { apiDateParser } from 'utils/dateParser'
import VehicleDetailsView from './VehicleDetailsView'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import VehicleDetailsDatePicker from './VehicleDetailsDatePicker'
//eslint-disable-next-line max-len
import getVehicleNumberSubtype from 'modules/Performances/views/ReadyForDeparture/components/services/getVehicleNumberSubtype'
import { DateTime } from 'luxon'
import { TranslationContext } from 'context/translation'

const VehicleDetails = props => {
    const [state, dispatch] = useReducer(VehicleDetailsReducer, INITIAL_STATE)
    const { translate } = React.useContext(TranslationContext)

    const loadData = () => {
        dispatch({ type: VehicleDetailsActions.LOADING, payload: true })
        let vehicleResponse = getVehicleDetails(state.selectedVehicle.value, apiDateParser(state.date.toMillis()))

        vehicleResponse
            .then(res => {
                dispatch({
                    type: VehicleDetailsActions.LOAD_VEHICLE_DATA,
                    payload: res.data,
                })
            })
            .catch(() => {
                dispatch({ type: VehicleDetailsActions.SET_ERROR, payload: true })
            })
    }

    const persist = type => value => {
        if (type === 'time') {
            dispatch({ type: VehicleDetailsActions.SET_DATE, payload: value })
        }
        if (type === 'date') {
            dispatch({ type: VehicleDetailsActions.REAL_TIME, payload: false })
        }
    }

    const handleVehicleSearch = vehicle => {
        dispatch({ type: VehicleDetailsActions.SET_VEHICLE, payload: vehicle })
    }
    const toggleRealTime = (button = false) => {
        if (button == 'button' && !state.realTime) {
            dispatch({ type: VehicleDetailsActions.SET_DATE, payload: DateTime.local() })
            loadData()
        }
        dispatch({ type: VehicleDetailsActions.REAL_TIME, payload: !state.realTime })
    }

    useEffect(async () => {
        let mounted = true
        try {
            document.title = translate('vehicle_detail_menu_item')
            dispatch({ type: VehicleDetailsActions.LOADING, payload: true })

            let vehicleData = await getVehicleNumberSubtype()
            let mapped = []
            let sorted = []
            mapped = vehicleData.map(item => {
                return {
                    value: item.vehicleNumber,
                    label: `${item.description.length ? item.description[0].translation : ''} -${item.vehicleNumber}`,
                }
            })
            sorted = mapped.sort((a, b) => a.value - b.value)
            if (mounted) {
                dispatch({ type: VehicleDetailsActions.SET_VEHICLE_LIST, payload: sorted })
                dispatch({ type: VehicleDetailsActions.LOADING, payload: false })
            }
        } catch (e) {
            console.error(e) // eslint-disable-line
        }

        return () => (mounted = false)
    }, [])
    let id =
        state?.vehicleData?.maximumSpeedMotorUnits?.length > 0
            ? state?.vehicleData?.maximumSpeedMotorUnits[0].validityId
            : null
    return (
        <React.Fragment>
            <Header isMenu title={translate('vehicle_detail_menu_item')} pageTitle="vehicleDetails" />
            <VehicleDetailsDatePicker
                persist={persist}
                startValue={state.date}
                translate={translate}
                identifier="vehicleDetails"
                trainNumber={state.trainNumber}
                handleLoad={loadData}
                loadButtonActive={state.loading || !state.selectedVehicle}
                validityId={id}
                vehicleList={state.vehicleList}
                handleVehicleSearch={handleVehicleSearch}
                selectedVehicle={state.selectedVehicle}
                realTime={state.realTime}
                toggleRealTime={toggleRealTime}
                trainView={true}
            />
            {state.loading && <Loading />}
            {state.error && <ErrorMessage error={'No Data'} spacing={false} />}
            {!state.loading && state.vehicleData && (
                <VehicleDetailsView classes={props.classes} translate={translate} vehicleData={state.vehicleData} />
            )}
        </React.Fragment>
    )
}
VehicleDetails.propTypes = {
    classes: object,
    store: object,
}

export default withStyles(styles)(VehicleDetails)
