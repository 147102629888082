import React from 'react'
import NotSignedOn from './NotSignedOnDetailView'
import SignedOn from './SignedOnDetailView'
import SignedOff from './SignedOffDetailView'
import NotAssigned from './NotAssignedDetailView'

import { statusEnum } from 'utils/statusEnum'

// eslint-disable-next-line react/prop-types
export default ({ activeTab, ...props }) => {
    switch (activeTab) {
        case statusEnum.NOT_SIGNED_IN:
            return <NotSignedOn {...props} />
        case statusEnum.SIGNED_IN:
            return <SignedOn {...props} />
        case statusEnum.NOT_ASSIGNED:
            return <NotAssigned {...props} />
        case statusEnum.NOT_VALIDATED:
            return <SignedOff {...props} />
        case statusEnum.SIGNED_OFF:
            return <SignedOff {...props} />
        default:
            return <NotSignedOn {...props} />
    }
}
