/* eslint-disable max-len */
import { withStyles } from '@material-ui/styles'
import React from 'react'
import ListHeaderStyles from './ListHeaderStyles'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

import { Translate } from 'react-localize-redux'

//CHANGE THIS IN TIME WHEN WE RECEIVE CORRECT DATA

// eslint-disable-next-line no-unused-vars
const SummaryTitle = ({ classes, data }) => {
    return (
        <div className={classes.summaryTitle}>
            <Typography className={classes.semesTitle}>
                <Translate id={'search_label_name'} /> - IDF
            </Typography>
            <Typography className={classes.semesTitle}>TCT - Depot</Typography>
            <Typography className={classes.semesTitle}>
                <Translate id="semes_received" />
            </Typography>
            <Typography className={classes.semesTitle}>
                <Translate id="datatable_header_first_opened" />
            </Typography>
            <Typography className={classes.semesTitle}>
                <Translate id="datatable_header_last_opened" />
            </Typography>
            <Typography className={classes.semesTitle}>
                <Translate id="aplicable_tasks" />
            </Typography>
            <Typography className={classes.semesTitle}>
                <Translate id="semes_read" />
            </Typography>
        </div>
    )
}

SummaryTitle.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
}
export default withStyles(ListHeaderStyles)(SummaryTitle)
