import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TableSortLabel } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Edit, Delete, UnfoldMore, UnfoldLess } from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person'

import FoldableRow from './FoldableRow'
import { ThemeContext } from 'theme/ThemeProvider'

export function UserList(props) {
    const [activeIdf, setActiveIdf] = useState('')
    const [sortActive, setSortActive] = useState(false)
    const [sortOrder, setSortOrder] = useState('asc')

    const toggleFold = idf => {
        setSortActive(false)
        if (activeIdf === idf) {
            setActiveIdf('')
        } else {
            setActiveIdf(idf)
        }
    }

    const handleDelete = (e, id, name) => {
        const { handleOpen } = props
        e.stopPropagation()
        handleOpen(id, name)
    }

    const handleEdit = (e, id) => {
        const { setGroupIdForEditAndOpenDrawer } = props
        e.stopPropagation()
        setGroupIdForEditAndOpenDrawer(id)
    }
    const handleAddPerson = (e, groupId) => {
        const { setGroupIdAndOpenDrawer } = props
        e.stopPropagation()
        setGroupIdAndOpenDrawer(groupId)
    }

    const handleSort = () => {
        if (!sortActive) {
            setSortOrder('desc')
            setSortActive(true)
        }
        sortOrder === 'desc' ? setSortOrder('asc') : setSortOrder('desc')
    }
    const SortArray = order => (x, y) => {
        const collator = new Intl.Collator('en')
        if (order === 'asc') return collator.compare(x.name, y.name)
        else return collator.compare(y.name, x.name)
    }

    const sortData = () => {
        if (!data) return []
        if (sortActive) {
            if (sortOrder === 'asc') return data.sort(SortArray('asc'))
            else return data.sort(SortArray('desc'))
        }
        return data
    }

    const { data, classes, OrganisationsData, translate, checkFunctionalityDisabled, roleItems } = props
    const sortedData = Array.isArray(data) ? sortData() : []
    const { themeName } = useContext(ThemeContext)
    let isDark = themeName === 'dark'

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '25%', cursor: 'pointer' }} onClick={() => handleSort()}>
                            <TableSortLabel active={sortActive} direction={sortOrder}>
                                {translate('add_user_roles_name')}
                                {!sortActive && <UnfoldMore fontSize={'small'} />}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: '25%' }}>{translate('add_user_roles_roles')}</TableCell>
                        <TableCell style={{ width: '25%' }} />
                        <TableCell style={{ width: '25%' }} align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData &&
                        sortedData.map(item => (
                            <React.Fragment key={item.id}>
                                <TableRow
                                    style={{ height: '56px' }}
                                    className={classes.clickableRow}
                                    key={item.id}
                                    onClick={e => {
                                        toggleFold(item.id)
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                >
                                    <TableCell>
                                        <Typography
                                            variant="body1"
                                            className={classes.underline}
                                        >{`${item.name}`}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {item.roles
                                                ? `${item.roles.length} ${translate('add_user_roles_assigned')}`
                                                : `0 ${translate('add_user_roles_assigned')}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell />
                                    <TableCell align="right">
                                        <PersonIcon
                                            className={classes.actionIcon}
                                            style={{
                                                pointerEvents: checkFunctionalityDisabled(
                                                    props.store,
                                                    'edit',
                                                    'users.functionality.management.users'
                                                )
                                                    ? 'none'
                                                    : 'auto',
                                                color: checkFunctionalityDisabled(
                                                    props.store,
                                                    'edit',
                                                    'users.functionality.management.users'
                                                )
                                                    ? 'grey'
                                                    : '',
                                            }}
                                            onClick={e => {
                                                handleAddPerson(e, item.id)
                                            }}
                                        />

                                        <Edit
                                            className={classes.actionIcon}
                                            style={{
                                                pointerEvents: checkFunctionalityDisabled(
                                                    props.store,
                                                    'edit',
                                                    'users.functionality.management.users'
                                                )
                                                    ? 'none'
                                                    : 'auto',
                                                color: checkFunctionalityDisabled(
                                                    props.store,
                                                    'edit',
                                                    'users.functionality.management.users'
                                                )
                                                    ? 'grey'
                                                    : '',
                                            }}
                                            onClick={e => {
                                                handleEdit(e, item.id)
                                            }}
                                        />
                                        <Delete
                                            className={classes.actionIcon}
                                            style={{
                                                pointerEvents: checkFunctionalityDisabled(
                                                    props.store,
                                                    'edit',
                                                    'users.functionality.management.users'
                                                )
                                                    ? 'none'
                                                    : 'auto',
                                                color: checkFunctionalityDisabled(
                                                    props.store,
                                                    'edit',
                                                    'users.functionality.management.users'
                                                )
                                                    ? 'grey'
                                                    : '',
                                            }}
                                            onClick={e => {
                                                handleDelete(e, item.id, `${item.name} `)
                                            }}
                                        />
                                        {activeIdf === item.id ? (
                                            <UnfoldMore
                                                className={classes.actionIcon}
                                                onClick={() => {
                                                    toggleFold(item.id)
                                                }}
                                            />
                                        ) : (
                                            <UnfoldLess
                                                className={classes.actionIcon}
                                                onClick={() => {
                                                    toggleFold(item.id)
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                                {activeIdf === item.id && (
                                    <TableRow
                                        style={
                                            isDark
                                                ? { height: '100px', backgroundColor: '#424242' }
                                                : { height: '100px', backgroundColor: 'lightGrey' }
                                        }
                                    >
                                        <FoldableRow
                                            {...props}
                                            userDetails={item}
                                            classes={classes}
                                            handleDelete={handleDelete}
                                            handleEdit={handleEdit}
                                            OrganisationsData={OrganisationsData}
                                            translate={translate}
                                            checkFunctionalityDisabled={checkFunctionalityDisabled}
                                            roleItems={roleItems}
                                        />
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                </TableBody>
            </Table>
        </div>
    )
}
UserList.propTypes = {
    classes: PropTypes.object,
    store: PropTypes.object,
    data: PropTypes.array,
    OrganisationsData: PropTypes.array,
    translate: PropTypes.func,
    checkFunctionalityDisabled: PropTypes.func,
    handleOpen: PropTypes.func,
    setGroupIdForEditAndOpenDrawer: PropTypes.func,
    setGroupIdAndOpenDrawer: PropTypes.func,
    roleItems: PropTypes.array
}
export default UserList
