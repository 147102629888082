import React from 'react'
import { array, number, func, object } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Badge from '@material-ui/core/Badge'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'

import styles from './styles'

const tabs = [
    'datatable_tab_header_not_signed_on',
    'datatable_tab_header_signed_on',
    'datatable_tab_header_not_assigned',
    'datatable_tab_header_not_validated',
    'datatable_tab_header_signed_off',
]

const mapToTab = (tab, index, tabData, translate, classes) => {
    const hasRed = tabData[index]?.red > 0
    const hasYellow = tabData[index]?.yellow > 0

    const redBadge = (
        <Badge
            color="primary"
            classes={{ badge: classes.redBadge }}
            style={{ marginLeft: hasYellow ? 15 : 18 }}
            badgeContent={tabData[index]?.red}
        >
            <React.Fragment />
        </Badge>
    )
    const yellowBadge = (
        <Badge
            color="primary"
            classes={{ badge: classes.yellowBadge }}
            style={{ marginLeft: 18, marginRight: hasRed ? 11 : 0 }}
            badgeContent={tabData[index]?.yellow}
        >
            <React.Fragment />
        </Badge>
    )
    return (
        <Tab
            key={index}
            value={index}
            label={
                <React.Fragment>
                    <Typography>
                        {translate(tab)}
                        {index !== 4 && hasYellow && yellowBadge}
                        {index !== 4 && hasRed && redBadge}
                    </Typography>
                </React.Fragment>
            }
        />
    )
}

const StatusTab = ({ classes, activeTabIndex, onTabIndexChanged, tabData }) => (
    <AppBar className={classes.tabsContainer} color="default" elevation={0} position="static">
        <Translate>
            {({ translate }) => (
                <Tabs onChange={onTabIndexChanged} value={activeTabIndex} indicatorColor="primary" variant="fullWidth">
                    {tabs.map((tab, index) => mapToTab(tab, index, tabData, translate, classes))}
                </Tabs>
            )}
        </Translate>
    </AppBar>
)

StatusTab.propTypes = {
    classes: object,
    activeTabIndex: number,
    onTabIndexChanged: func,
    tabData: array,
}

export default compose(withLocalize, withStyles(styles))(StatusTab)
