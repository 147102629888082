import React from 'react'

import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore } from 'react-ion-store'

import { withStyles } from '@material-ui/core/styles'

import TaskDetailDrawerView from './TaskDetailDrawerView'

import styles from './styles'

function TaskDetailDrawer(props) {
    return <TaskDetailDrawerView {...props} />
}

export default compose(withStore, withStyles(styles), withLocalize)(TaskDetailDrawer)
