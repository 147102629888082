import React from 'react'
import ActionBar from 'components/ActionBar'
import { object, func, bool, string, number, array } from 'prop-types'
import { Button } from '@material-ui/core'
import DateTimePicker from 'components/DateTimePicker'
import styles from './styles'
import { withStyles } from '@material-ui/styles'
import { TranslationContext } from 'context/translation'

const HeaderWithDatePicker = props => {
    const { classes } = props

    const { translate } = React.useContext(TranslationContext)

    return (
        <ActionBar>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {props?.searchBoxRequired && (
                        <>
                            <input
                                type="text"
                                className={classes.customInput}
                                placeholder={translate('search')}
                                value={props.trainNumber}
                                onChange={e => props.handleText(e.target.value)}
                            />

                            <Button
                                data-testid="loadButton"
                                style={{ marginLeft: '12px' }}
                                disabled={props.loadButtonActive}
                                variant="contained"
                                color="primary"
                                onClick={() => props.handleLoad()}
                            >
                                {translate('add_task_load_train')}
                            </Button>
                        </>
                    )}
                </div>
                <div style={{ display: 'flex' }}>
                    <DateTimePicker
                        identifier={props.identifier}
                        startValue={props.startValue}
                        translate={translate}
                        persist={props.persist}
                        trainView={true}
                        validityId={props.validityId}
                        realTime={props.realTime}
                        toggleRealTime={props.toggleRealTime}
                    />
                </div>
            </div>
        </ActionBar>
    )
}
HeaderWithDatePicker.propTypes = {
    classes: object,
    persist: func,
    handleText: func,
    handleLoad: func,
    store: object,
    searchBoxRequired: bool,
    loadButtonActive: bool,
    trainNumber: string,
    startValue: object,
    identifier: string,
    validityId: number,
    realTime: bool,
    toggleRealTime: func,
    vehicleList: array,
    handleVehicleSearch: func,
    selectedVehicle: object,
}
export default withStyles(styles)(HeaderWithDatePicker)
