import { grey } from '@material-ui/core/colors'

export default theme => ({
    infoContainer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        borderBottom: `2px solid ${grey['A400']}`,
    },

    spacing: {
        borderRight: `2px solid ${grey['A400']}`,
    },
})
