import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers } from 'recompose'
import { withTake, withStore } from 'react-ion-store'

import { withStyles } from '@material-ui/core'
import styles from './styles'
import MenuView from './MenuView'

import { MenuContext } from '../../context/menuStore'

const storeKeys = ['isMenuOpen', 'auth', 'userRoles']

const Menu = props => {
    const { moduleList } = React.useContext(MenuContext)

    if (moduleList) {
        return <MenuView {...props} moduleList={moduleList} />
    } else {
        return null
    }
}
export default compose(
    withStore,
    withRouter,
    withHandlers({
        // register handlers before withTake (prevent extra mutability)
        closeMenu: props => () => props.store.set({ isMenuOpen: false }),
        goToPath: props => path => () => {
            // extra () makes it a HoF
            if (path === process.env.REACT_APP_FALLBACK_PERFORMANCES_PATH) {
                window.location.replace(`${path}`)
            } else {
                props.history.push(path)
                props.store.set({ isMenuOpen: false })
            }
        },
    }),
    withTake(storeKeys),
    withStyles(styles)
)(Menu)
