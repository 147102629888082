import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
export default async () => {
    const { url, subscriptionKey } = requestData
    let fullUrl = `${url}/semes/reprocessPtCarsIncluded`
    try {
        const result = await axios
            .post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            .then(res => res)
        return result
    } catch (e) {
        console.error(e) // eslint-disable-line
        return false
    }
}
