export default theme => ({
    autoHeight: {
        borderBottom: `1px solid ${theme.palette.grey['700']}`,
        height: 'auto',
    },
    tableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
    },
    tableCellAlignRight: {
        paddingTop: '16px',
        paddingBottom: '16px',
        borderColor: theme.customColors.grey,
        textAlign: 'right',
    },
    tableRow: {
        backgroundColor: theme.customColors.perfRowBackGroudColor,
    },
    driveModeIcon: {
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.8',
        },
    },
    tableRowRed: {
        backgroundColor: theme.customColors.darkRed,
    },
    tableRowYellow: {
        backgroundColor: theme.customColors.yellow,
    },

    rowDetail: {
        backgroundColor: theme.customColors.detailGrey,
    },
    monitorContainer: {
        textAlign: 'right',
    },
    tableWrapper: {
        width: '100%',
        height: '55vh',
    },
    table: {
        fontFamily: theme.typography.fontFamily,
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    documentTableWrapper: {
        width: '100%',
        height: `calc(100vh - ${theme.performanceView}px)`,
        [theme.breakpoints.down('md')]: {
            height: '45vh',
        },
    },
    blockText: {
        display: 'block',
        color: 'white',
    },
    plainText: {
        color: 'white',
    },
    clickText: {
        fontWeight: 'bold',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    link: {
        paddingTop: theme.spacing(2),
        fontWeight: 'bold',
        cursor: 'pointer',
        textDecoration: 'underline',
        color: theme.customColors.white,
    },
    actionIcon: {
        cursor: 'pointer',
        pointerEvents: 'auto',
        color: theme.customColors.red,
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
    lockIcon: {
        color: 'white',
    },
})
