import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus, enums } from 'react-ion-store'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'
import ManageView from './ManageView'
import ChangeSwitchDialog from './components/ChangeSwitchDialog'
import axios from 'axios'
import checkWriteAccess from 'utils/checkWriteAccess'

import styles from './styles'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import getPerformanceRangeNumber from './Services/getPerformanceRangeNumber'

const {
    CallStatus: { READY, FAILED },
} = enums

const Failed = () => <div>Error retrieving Roles!</div>
const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
class Manage extends React.Component {
    static propTypes = {
        store: PropTypes.object,
        __ion_status: PropTypes.object,
        history: PropTypes.object,
        addPerformanceFromMenu: PropTypes.func,
        translate: PropTypes.func,
    }

    state = {
        drawers: {
            // readStatus: false,
            clearCache: false,
            superUser: false,
            addPerformance: false,
            changeTaskStatus: false,
            calendar: false,
        },
        isDBLoading: false,
        isBackUpLoading: false,
        switchChecked: false,
        openDailogForDBChange: false,
        backupKey: false,
        openDialogForBackupSwitch: false,
        performanceRangeNumbers: [],
    }

    openDialog = () => {
        this.setState({ openDailogForDBChange: true })
    }
    onChangeBackupKey = () => {
        this.setState({ openDialogForBackupSwitch: true })
    }
    persistDrawers = type => props => {
        const toSetState = {
            drawers: {
                ...this.state.drawers,
                [type]: props,
            },
        }

        this.setState(toSetState)
    }

    getSwitch = async () => {
        this.setState({ isDBLoading: true })
        const { url, subscriptionKey } = requestData
        const fullUrl = `${url}/timetable/rivassource`

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.setState({
                    switchChecked: response.data,
                })
            }
            this.setState({ isDBLoading: false })
        } catch (e) {
            this.setState({ isDBLoading: false })
            console.error(e) // eslint-disable-line
        }
    }

    getPerformanceRange = async () => {
        getPerformanceRangeNumber().then(res => {
            console.log(res)
        })
    }

    getBackupKey = async () => {
        this.setState({ isBackUpLoading: true })
        const { url, subscriptionKey } = requestData
        const fullUrl = `${url}/performancebackup/performancebackupkey`

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.setState({
                    backupKey: response.data,
                })
            }
            this.setState({
                isBackUpLoading: false,
            })
        } catch (e) {
            this.setState({ isBackUpLoading: false })
            console.error(e) // eslint-disable-line
        }
    }

    handleDialogClose = () => {
        this.setState({ openDailogForDBChange: false, openDialogForBackupSwitch: false })
    }

    setSwitch = async value => {
        const { url, subscriptionKey } = requestData
        const fullUrl = `${url}/timetable/rivassource`
        try {
            const response = await axios.post(fullUrl, {
                Value: value,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.setState({ switchChecked: value })
                return
            }
            if (response.status === 200) {
                this.setState({ switchChecked: value })
            }
        } catch (e) {
            this.setState({ switchChecked: value })
            console.error(e) // eslint-disable-line
        }
    }

    setBackUpKey = async value => {
        const { url, subscriptionKey } = requestData
        const fullUrl = `${url}/performancebackup/performancebackupkey`
        try {
            const response = await axios.post(fullUrl, {
                Value: value,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.setState({ backupKey: value })
                return
            }
            if (response.status === 200) {
                this.setState({ backupKey: value })
            }
        } catch (e) {
            console.error(e) // eslint-disable-line
        }
    }

    changeSwitch = async () => {
        this.setState({ openDailogForDBChange: false })
        this.setSwitch(!this.state.switchChecked)
    }
    changeBackupKey = async () => {
        this.setState({ openDialogForBackupSwitch: false })
        this.setBackUpKey(!this.state.backupKey)
    }
    componentDidMount() {
        if (this.props.addPerformanceFromMenu) this.persistDrawers('addPerformance')(true)
        document.title = this.props.translate('module_manage')

        this.getSwitch()
        this.getBackupKey()
        this.getPerformanceRange()
    }

    render() {
        const { store, __ion_status, history } = this.props
        const { userRoles } = __ion_status

        const { roles, moduleList } = store.get(['roles', 'moduleList'])

        const rolesLoading = userRoles !== READY || !roles
        const rolesFailed = userRoles === FAILED && !roles
        if (rolesFailed)
            return (
                <React.Fragment>
                    <Failed />
                </React.Fragment>
            )
        else if (rolesLoading || this.state.isDBLoading || this.state.isBackUpLoading)
            return (
                <React.Fragment>
                    <Loading />
                </React.Fragment>
            )
        else if (!!moduleList && !moduleList.includes('/manage')) {
            history.replace(`/`)
        }
        return (
            <React.Fragment>
                <ManageView
                    {...this.props}
                    persistDrawers={this.persistDrawers}
                    drawers={this.state.drawers}
                    roles={roles}
                    switchChecked={this.state.switchChecked}
                    backupKey={this.state.backupKey}
                    onChangeDBSwitch={this.openDialog}
                    onChangeBackupKey={this.onChangeBackupKey}
                    showDBSwitch={checkWriteAccess(
                        this.props,
                        'changeDBswitch',
                        'performances.functionality.management.dbrights'
                    )}
                />
                {this.state.openDailogForDBChange && (
                    <ChangeSwitchDialog
                        showText={this.state.switchChecked ? 'riv_to_opal' : 'opal_to_riv'}
                        open={this.state.openDailogForDBChange}
                        handleClose={this.handleDialogClose}
                        onChange={this.changeSwitch}
                    />
                )}
                {this.state.openDialogForBackupSwitch && (
                    <ChangeSwitchDialog
                        showText={'manage_performance_backup_label'}
                        open={this.state.openDialogForBackupSwitch}
                        handleClose={this.handleDialogClose}
                        onChange={this.changeBackupKey}
                        disableReplace={checkFunctionalityDisabled(
                            store,
                            'performanceBackUp',
                            'performances.functionality.management.dbrights'
                        )}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default compose(withRouter, withLocalize, withStyles(styles), withStore, withStatus)(Manage)
