export const INITIAL_STATE = {
    loading: false,
    vehicleData: null,
    trainNumber: '',
    date: DateTime.local(),
    vehicleList: [],
    selectedVehicle: null,
    realTime: true,
}
import { DateTime } from 'luxon'
import VehicleDetailsActions from './VehicleDetailsActions'
export function VehicleDetailsReducer(state, action) {
    switch (action.type) {
        case VehicleDetailsActions.LOAD_VEHICLE_DATA:
            return { ...state, vehicleData: action.payload, error: false, loading: false }
        case VehicleDetailsActions.LOADING:
            return { ...state, error: false, loading: action.payload }
        case VehicleDetailsActions.SET_DATE:
            return { ...state, date: action.payload }
        case VehicleDetailsActions.SET_ERROR:
            return { ...state, error: true, vehicleData: null, loading: false }
        case VehicleDetailsActions.SET_VEHICLE_LIST:
            return { ...state, vehicleList: action.payload }
        case VehicleDetailsActions.SET_VEHICLE:
            return { ...state, selectedVehicle: action.payload }
        case VehicleDetailsActions.REAL_TIME:
            return { ...state, error: false, realTime: action.payload }
        default:
            return INITIAL_STATE
    }
}
