import React from 'react'
import styles from './styles'
import { object, array, string, func } from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import MUCView from './MUCView'
import { isCompoEditingAllowed } from '../../Utils/isCompoEditingAllowed'

const MaterialUnitChild = props => {
    const getName = vehicleNumber => {
        let materialSubType = props.usedMaterialSubTypeNames.find(el => el.value === parseInt(vehicleNumber))
        return materialSubType != undefined ? materialSubType.label : ''
    }

    const MUC =
        props.data.materialUnitChilds?.length === 0 ? (
            <MUCView
                classes={props.classes}
                inDirection={props.data.inDirection}
                showFront={true}
                showBack={true}
                vehicleNumber={props.data.vehicleNumber}
                name={getName(props.data.vehicleNumber)}
                hasDamage={!!props.data?.compositionDefectTypeCodes?.length}
                hasDriverCockpit={props.data.hasDriverCockpit}
                vehicleType={props.data.groupType}
                {...props}
            />
        ) : (
            <>
                {props.data.materialUnitChilds?.map((child, index) => {
                    let showFront = index === 0
                    let showBack = index + 1 === props.data.materialUnitChilds.length
                    let uniqueKey = child.subPosition ? child.subPosition : child.vehicleNumber
                    return (
                        <MUCView
                            key={uniqueKey}
                            classes={props.classes}
                            showFront={showFront}
                            showBack={showBack}
                            inDirection={props.data.inDirection}
                            name={getName(child.vehicleNumber)}
                            hasDamage={!!child?.compositionDefectTypeCodes?.length}
                            hasDriverCockpit={child.hasDriverCockpit}
                            vehicleType={props.data.groupType}
                            handleMUCInfoClick={props.handleMUCInfoClick}
                            {...child}
                        />
                    )
                })}
            </>
        )
    return (
        <>
            {MUC}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>{`${props.data.diagramShortName ? props.data.diagramShortName : ''} - ${
                    props.data.serviceNumber ? props.data.serviceNumber : ''
                }`}</Typography>
                <Edit
                    className={!isCompoEditingAllowed(props) ? props.classes.disabled : props.classes.editIcon}
                    onClick={() => {
                        if (isCompoEditingAllowed(props)) props.handleMUEditClick(props.data.tractionPosition)
                    }}
                />
            </div>
        </>
    )
}

MaterialUnitChild.propTypes = {
    classes: object,
    materialUnitChild: array,
    inDirection: string,
    diagramShortName: string,
    serviceNumber: string,
    data: object,
    handleMUEditClick: func,
    handleMUCInfoClick: func,
    store: object,
    usedMaterialSubTypeNames: array,
}
export default withStyles(styles)(MaterialUnitChild)
