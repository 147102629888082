import React, { PureComponent } from 'react'
import { object } from 'prop-types'

import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'

import styles from './styles'

export class PerformanceChildFoldableRow extends PureComponent {
    static propTypes = {
        taskDetails: object.isRequired,
    }

    render() {
        const { taskDetails } = this.props

        return (
            <React.Fragment>
                <TableCell style={{ whiteSpace: 'nowrap', padding: 0 }} colSpan={12}>
                    <div></div>
                    <div style={{ display: 'flex', alignItems: 'space-between', flexWrap: 'wrap' }}>
                        <SubdirectoryArrowRightIcon style={{ marginLeft: '5%' }}></SubdirectoryArrowRightIcon>
                        <Typography variant="body1" style={{ marginLeft: '20px' }}>
                            {taskDetails.taskInfo}
                        </Typography>
                    </div>
                </TableCell>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(PerformanceChildFoldableRow)
