export default theme => ({
    icon: {
        fill: theme.customColors.iconFront,
        background: theme.customColors.iconBg,
        marginRight: theme.spacing(3),
    },
    list_style: {
        marginBottom: '96px',
        padding: 0,
    },
})
