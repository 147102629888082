import React, { Component } from 'react'
import { func, bool, object, array } from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import Select from 'react-select'

export default class LocationSearchView extends Component {
    customFilter = (option, searchText) => {
        return (
            (option.data.label !== '' && option.data.label.toLowerCase().includes(searchText.toLowerCase())) ||
            (option.data.code !== undefined && option.data.code.toLowerCase().includes(searchText.toLowerCase()))
        )
    }
    static propTypes = {
        handleSearchValue: func,
        locationSelectValue: object,
        open: bool,
        classes: object,
        data: array,
    }
    render() {
        const { classes, handleSearchValue, data, locationSelectValue } = this.props

        const searchList = data
        const customStyles = {
            option: (provided, state) => ({
                fontFamily: 'Roboto',
                background: '#424242',
                borderBottom: '1px solid grey',
                fontWeight: state.isSelected ? '600' : '8OO',
                color: 'white',
                padding: 20,
            }),
            menu: provided => ({
                ...provided,
                left: 0,
                background: '#424242',
                fontFamily: 'Roboto',
            }),
            input: provided => ({
                ...provided,
                fontFamily: 'Roboto',
                background: '#424242',
                color: '#979797',
            }),
            container: provided => ({
                ...provided,
                paddingLeft: '35px',
                border: 'none',
                background: 'transparent',
                '&:focus': { outline: 'none' },
            }),
            control: provided => ({
                ...provided,
                background: 'transparent',
                border: 0,
                fontFamily: 'Roboto',
                // This line disable the blue border
                boxShadow: 0,
                '&:hover': {
                    border: 0,
                },
            }),
            valueContainer: provided => ({
                ...provided,
                background: '#424242',
                color: '#979797',
                fontFamily: 'Roboto',
            }),
            singleValue: provided => ({
                ...provided,
                color: '#979797',
                fontFamily: 'Roboto',
            }),
        }

        return (
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <Select
                    styles={customStyles}
                    isSearchable={true}
                    blurInputOnSelect={true}
                    isClearable={true}
                    placeholder="Zoek naar een locatie..."
                    name="ptCarList"
                    value={locationSelectValue}
                    filterOption={this.customFilter}
                    options={searchList}
                    onChange={e => {
                        handleSearchValue(e)
                    }}
                    //onFocus={() => clearValue()}
                />
            </div>
        )
    }
}
