import lo from 'lodash'

const checkFunctionalityDisabled = (store, key, path) => {
    //if store is empty or roles not found always disable

    // eslint-disable-next-line no-console
    if (store === undefined) console.log('checkFunctionality - store is undefined!')
    if (store === undefined) return true
    const object = store.get('roles')

    if (object === undefined) return true
    const allowedFunctionality = lo.get(object, path)

    if (allowedFunctionality === undefined) return true
    //@TODO: refactor this function, as it contains a potential security risk
    //eslint-disable-next-line
    if (allowedFunctionality.hasOwnProperty(key)) {
        return !allowedFunctionality[key]
    }
    return true
}

export default checkFunctionalityDisabled
