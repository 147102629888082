import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStore } from 'react-ion-store'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import Grid from '@material-ui/core/Grid'
import { Typography, withStyles, TextField } from '@material-ui/core'

//config
import groups from '../../../../config/groups'
import taskTypes from '../../../../config/taskTypes'

import { TimePickerWithKeyboard } from 'components/Pickers'
import SelectInput from 'components/SelectInput'
import SelectInputLocation from './SelectInputLocation'
import LocationSearch from '../../../../components/LocationSearch'
import IconBoxEdit from '../../../../components/IconBoxEdit'
import ErrorMessage from 'components/ErrorMessage'
import ReferencedPerformance from './referencedPerformance'
import styles from './styles'

const initialState = [
    { component1: false },
    { component2: false },
    { component3: false, required: 'startTime' },
    { component4: false, required: 'endTime' },
    { component5: false, required: 'locationFrom' },
    { component6: false, required: 'locationTo' },
    { component7: false, required: 'location' },
    { component8: false },
    { component9: false },
    { component10: false, required: 'diagramNumber' },
]

export class LastStep extends Component {
    static propTypes = {
        allLocations: PropTypes.array,
        setFormValidation: PropTypes.func,
        validateForm: PropTypes.number,
        taskType: PropTypes.string,
        persist: PropTypes.func,
        classes: PropTypes.object,
        translate: PropTypes.func,
        endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
        startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
        remarkInput: PropTypes.string,
        setStartTime: PropTypes.func,
        setEndTime: PropTypes.func,
        diagramNumber: PropTypes.string,
        trainLocations: PropTypes.array,
        trainLocationsFrom: PropTypes.array,
        trainLocationsTo: PropTypes.array,
        locationFrom: PropTypes.string,
        locationTo: PropTypes.string,
        showStepThree: PropTypes.bool,
        handleLocationToSearchValue: PropTypes.func,
        locationToSearchValue: PropTypes.string,
        clearLocationToValue: PropTypes.func,
        handleLocationFromSearchValue: PropTypes.func,
        locationFromSearchValue: PropTypes.string,
        clearLocationFromValue: PropTypes.func,
        locationFromSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        locationToSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        locationSelected: PropTypes.string,
        handleLocationSearchValue: PropTypes.func,
        locationSearchValue: PropTypes.string,
        clearLocationValue: PropTypes.func,
        location: PropTypes.object,
        timePickerDisable: PropTypes.bool,
        isTablet: PropTypes.bool,
        locationListLoading: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.state = {
            locationToSearchValue: '',
            locationFromSearchData: props.allLocations,
            locationToSearchData: props.allLocations,
            diagramNumberError: false,
            diagramNumberErrorChange: false,
        }
    }

    componentDidUpdate() {
        const { setFormValidation, validateForm } = this.props

        const requiredValues = this.getRequiredValues()

        const sum = this.validateRequired(requiredValues)

        if (sum !== validateForm) setFormValidation(sum)
        if (this.state.locationToSearchData === undefined && this.props.allLocations !== undefined) {
            this.setState({ locationToSearchData: this.props.allLocations })
        }
        if (this.state.locationFromSearchData === undefined && this.props.allLocations !== undefined) {
            this.setState({ locationFromSearchData: this.props.allLocations })
        }
    }

    componentDidMount() {
        const { setFormValidation, validateForm } = this.props

        const requiredValues = this.getRequiredValues()

        const validateTimes = requiredValues.includes('startTime') && requiredValues.includes('endTime')

        const sum = this.validateRequired(requiredValues)

        if (sum !== validateForm) setFormValidation(sum, validateTimes)
    }

    handleEvent = type => e => {
        this.props.persist(type)(e.target.value)
    }

    handleEventDiagramNumber = type => e => {
        this.setState({diagramNumberErrorChange: true})
        this.props.persist(type)(e.target.value)
    }

    mapComponents = () => {
        const { taskType } = this.props
        let toRender = [
            { component1: false, required: 'remarkInput' },
            { component2: false },
            { component3: false, required: 'startTime' },
            { component4: false, required: 'endTime' },
            { component5: false, required: 'locationFrom' },
            { component6: false, required: 'locationTo' },
            { component7: false, required: 'location' },
            { component8: false },
            { component9: false },
            { component10: false, required: 'diagramNumber' },
        ]

        if (taskType === ' ') return initialState

        const groupNumber = taskTypes.find(type => type.id === taskType)
        const mapping = groups.find(item => item.group === groupNumber.group)

        for (let component of mapping.components) {
            toRender[`component${component}`] = true
        }

        return toRender
    }

    getRequiredValues = () => {
        const { taskType } = this.props

        //array is in order from component 1 TO 10
        //if a value is required add it to the component
        const required = [
            'remarkInput',
            null,
            'startTime',
            'endTime',
            'locationFrom',
            'locationTo',
            'location',
            null,
            'selectedReferencePerformanceNumber',
            taskType !== 'DRIVE' && taskType !== 'HLP' ? 'diagramNumber' : null,
        ]

        if (taskType === ' ') return initialState

        const groupNumber = taskTypes.find(type => type.id === taskType)
        const mapping = groups.find(item => item.group === groupNumber.group)

        const arr = mapping.components.map(item => {
            const index = item - 1

            return required[index]
        })

        //this returns an array of required values
        //which will be used in  validateRequired() function.
        return arr.filter(item => item !== null)
    }

    validateDiagramNumber = () => {
        const { diagramNumber } = this.props
        if(!diagramNumber.match(/[^A-Za-z0-9 ]/)){
            if(this.state.diagramNumberErrorChange){
                this.setState({diagramNumberError: false})
                this.setState({diagramNumberErrorChange: false})
            }
            if(diagramNumber.length > 1){
            return 0
            }
            return 1
        }
        if(this.state.diagramNumberErrorChange){
            this.setState({diagramNumberError: true})
            this.setState({diagramNumberErrorChange: false})
        }
        return 1
    }

    validateRequired = required => {
        const val = required.map(item => {
            //check if required parameter has a  value
            if(item == "diagramNumber"){
                const valDiagramNumber = this.validateDiagramNumber()
                return valDiagramNumber
            }
            if (this.props[`${item}`] === undefined || this.props[`${item}`] === null || this.props[`${item}`].length <= 1) {
                return 1
            } else {
                return 0
            }
        })
        //returns the sum of  the array
        //If this is 0 it means all required fields for the tasktype are filled in.
        return val.reduce((a, b) => a + b, 0)
    }

    filterLocationToData = data => {
        const { locationToSearchValue } = this.props

        if (locationToSearchValue === '') return data

        return data.filter(item => item.label === locationToSearchValue || item.code === locationToSearchValue)
    }

    filterLocationData = data => {
        const { locationSearchValue } = this.props

        if (locationSearchValue === '') return data

        return data.filter(item => item.label === locationSearchValue || item.code === locationSearchValue)
    }

    filterLocationFromData = data => {
        const { locationFromSearchValue } = this.props

        if (locationFromSearchValue === '') return data

        return data.filter(item => item.label === locationFromSearchValue || item.code === locationFromSearchValue)
    }

    handleLocationToSearchValueWithFilter = e => {
        const { allLocations } = this.props
        const index = allLocations.findIndex(x => x.label === e.label)
        const list = allLocations.slice(0, index)
        this.setState({
            locationFromSearchData: list,
        })
        this.props.handleLocationToSearchValue(e)
    }

    handleLocationFromSearchValueWithFilter = e => {
        const { allLocations } = this.props
        const index = allLocations.findIndex(x => x.label === e.label)
        const list = allLocations.slice(index, allLocations.length)
        this.setState({
            locationToSearchData: list,
        })
        this.props.handleLocationFromSearchValue(e)
    }

    render() {
        const {
            classes,
            translate,
            endTime,
            startTime,
            remarkInput,
            setStartTime,
            setEndTime,
            diagramNumber,
            trainLocations,
            trainLocationsFrom,
            trainLocationsTo,
            locationFrom,
            locationTo,
            location,
            showStepThree,
            allLocations,
            handleLocationToSearchValue,
            locationToSearchValue,
            clearLocationToValue,
            handleLocationFromSearchValue,
            locationFromSearchValue,
            clearLocationFromValue,
            locationFromSelected,
            locationToSelected,
            locationSelected,
            handleLocationSearchValue,
            locationSearchValue,
            clearLocationValue,
            timePickerDisable,
            isTablet,
            locationListLoading,
        } = this.props
        let components = this.mapComponents()

        const {
            component1,
            //component2,
            component3,
            component4,
            component5,
            component6,
            component7,
            //component8,
            component9,
            component10,
        } = components

        const filteredLocationToData = this.filterLocationToData(this.state.locationToSearchData)
        const filteredLocationData = this.filterLocationData(allLocations)
        const filteredLocationFromData = this.filterLocationFromData(this.state.locationFromSearchData)
        const gridWidth = isTablet ? 6 : 4
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant="h6">{translate('performance_task_add_label_general_data')}</Typography>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    {component3 && (
                        <Grid item xs={gridWidth}>
                            <IconBoxEdit icon="time">
                                <TimePickerWithKeyboard
                                    label={translate('label_enter_start_hour')}
                                    value={startTime}
                                    onChange={setStartTime}
                                    disabled={timePickerDisable}
                                />
                            </IconBoxEdit>
                        </Grid>
                    )}
                    {component4 && (
                        <Grid item xs={gridWidth}>
                            <IconBoxEdit icon="time">
                                <TimePickerWithKeyboard
                                    label={translate('label_enter_end_hour')}
                                    value={endTime}
                                    onChange={setEndTime}
                                    disabled={timePickerDisable}
                                />
                            </IconBoxEdit>
                        </Grid>
                    )}
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    {component5 && showStepThree && trainLocationsFrom.length !== 0 && (
                        <Grid item xs={gridWidth}>
                            <div style={{ display: 'inline-block' }}>
                                <SelectInputLocation
                                    name="locationFrom"
                                    classes={classes}
                                    items={trainLocationsFrom}
                                    value={locationFrom}
                                    onChange={this.handleEvent('locationFrom')}
                                />
                            </div>
                        </Grid>
                    )}
                    {component5 && showStepThree && trainLocationsFrom.length === 0 && (
                        <Grid item xs={gridWidth}>
                            <Typography variant="subtitle1">From:</Typography>
                            <LocationSearch
                                locationLoading={locationListLoading}
                                handleSearchValue={handleLocationFromSearchValue}
                                searchValue={locationFromSearchValue}
                                clearValue={clearLocationFromValue}
                                data={filteredLocationFromData}
                                locationSelectValue={locationFromSelected}
                                doubleLangSearch={true}
                            />
                        </Grid>
                    )}
                    {component5 && !showStepThree && (
                        <Grid item xs={gridWidth}>
                            <Typography variant="subtitle1">From:</Typography>
                            <LocationSearch
                                locationLoading={locationListLoading}
                                handleSearchValue={handleLocationFromSearchValue}
                                searchValue={locationFromSearchValue}
                                clearValue={clearLocationFromValue}
                                data={filteredLocationFromData}
                                locationSelectValue={locationFromSelected}
                                doubleLangSearch={true}
                            />
                        </Grid>
                    )}

                    {component6 && showStepThree && trainLocationsTo.length !== 0 && (
                        <Grid item xs={gridWidth}>
                            <div style={{ display: 'inline-block' }}>
                                <SelectInputLocation
                                    name="locationTo"
                                    classes={classes}
                                    items={trainLocationsTo}
                                    value={locationTo}
                                    onChange={this.handleEvent('locationTo')}
                                />
                            </div>
                        </Grid>
                    )}
                    {component6 && !showStepThree && (
                        <Grid item xs={gridWidth}>
                            <Typography variant="subtitle1">To:</Typography>
                            <LocationSearch
                                locationLoading={locationListLoading}
                                handleSearchValue={handleLocationToSearchValue}
                                searchValue={locationToSearchValue}
                                clearValue={clearLocationToValue}
                                data={filteredLocationToData}
                                locationSelectValue={locationToSelected}
                                doubleLangSearch={true}
                            />
                        </Grid>
                    )}
                    {component6 && showStepThree && trainLocationsTo.length === 0 && (
                        <Grid item xs={gridWidth}>
                            <Typography variant="subtitle1">To:</Typography>
                            <LocationSearch
                                locationLoading={locationListLoading}
                                handleSearchValue={handleLocationToSearchValue}
                                searchValue={locationToSearchValue}
                                clearValue={clearLocationToValue}
                                data={filteredLocationToData}
                                locationSelectValue={locationToSelected}
                                doubleLangSearch={true}
                            />
                        </Grid>
                    )}
                    {component7 && showStepThree && trainLocations.length !== 0 && (
                        <Grid item xs={gridWidth}>
                            <div style={{ display: 'inline-block' }}>
                                <SelectInput
                                    name="location"
                                    classes={classes}
                                    items={trainLocations}
                                    value={location}
                                    onChange={this.handleEvent('location')}
                                />
                            </div>
                        </Grid>
                    )}
                    {component7 && !showStepThree && (
                        <Grid item xs={gridWidth}>
                            <Typography variant="subtitle1">Location:</Typography>
                            <LocationSearch
                                locationLoading={locationListLoading}
                                handleSearchValue={handleLocationSearchValue}
                                searchValue={locationSearchValue}
                                clearValue={clearLocationValue}
                                data={filteredLocationData}
                                locationSelectValue={locationSelected}
                                doubleLangSearch={true}
                            />
                        </Grid>
                    )}
                    {component7 && showStepThree && trainLocations.length === 0 && (
                        <Grid item xs={gridWidth}>
                            <Typography variant="subtitle1">Location:</Typography>
                            <LocationSearch
                                locationLoading={locationListLoading}
                                handleSearchValue={handleLocationSearchValue}
                                searchValue={locationSearchValue}
                                clearValue={clearLocationValue}
                                data={filteredLocationData}
                                locationSelectValue={locationSelected}
                                doubleLangSearch={true}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    {component9 && <ReferencedPerformance {...this.props} />}
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    {component10 && (
                        <Grid item xs={gridWidth}>
                            <TextField
                                data-testid="diagram-number-input"
                                fullWidth={true}
                                value={diagramNumber}
                                onChange={this.handleEventDiagramNumber('diagramNumber')}
                                placeholder={translate('add_task_diagram_number')}
                                label={translate('add_task_diagram_number')}
                                error={this.state.diagramNumberError}
                            />
                            {/* eslint-disable-next-line max-len */}
                            {this.state.diagramNumberError && <ErrorMessage error={translate('add_task_diagram_number_error')} spacing={false}/>}
                        </Grid>
                    )}
                </Grid>
                {component1 && (
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth={true}
                                value={remarkInput}
                                onChange={this.handleEvent('remarkInput')}
                                label={'comment'}
                                placeholder={'Geef hier een commentaar in'}
                                multiline
                                rows={5}
                                rowsMax={5}
                                inputProps={{ maxLength: 250 }}
                            />
                        </Grid>
                    </Grid>
                )}
            </React.Fragment>
        )
    }
}

export default compose(withStore, withLocalize, withStyles(styles))(LastStep)
