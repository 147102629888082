import React from 'react'

import { func, object, string, number, array } from 'prop-types'
import classNames from 'classnames'
import { Translate } from 'react-localize-redux'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { Delete, Edit } from '@material-ui/icons'
import Button from '@material-ui/core/Button'

import Loading from 'components/Loading'
import getPneumaticSuspensionData from './Services/getPneumaticSuspensionData'
import DeleteDialog from './DeletePneumaticDialog'
import PneumaticSuspensionTable from './PneumaticSuspensionTable'
import PneumaticSuspensionDrawer from './PneumaticSuspensionDrawer'
import addEditPneumaticSuspension from './Services/addEditPneumaticSuspension'

import Header from 'components/Header'
import getMaterialTypes from '../service/getMaterialTypes'
import CommonFilter from '../CommonFilter'
import { TranslationContext } from 'context/translation'
const columns = [
    {
        width: 400,
        label: 'datatable_header_vacma_restriction_type',
        dataKey: 'code',
    },
    {
        width: 400,
        label: 'datatable_header_pneumatic_suspensiong_bogies',
        dataKey: 'bogies',
    },
    {
        width: 400,
        label: 'datatable_header_pneumatic_suspensiong_restriction_percentage',
        dataKey: 'restrictionPercentagePercentage',
    },
    {
        width: 400,
        label: 'datatable_header_actions',
        dataKey: 'actions',
    },
]

class PneumaticListView extends React.Component {
    _isMounted = false

    state = {
        data: [],
        isOpen: false,
        editDrawer: false,
        openDrawer: false,
        editData: '',
        addEditError: '',
        filteredData: [],
        openDialog: false,
        rowData: {},
        deleteRowData: [],
        loadingData: false,
        filterValue: '',
        materialTypes: [],
        filterString: '',
        addEditLoading: false,
    }

    static propTypes = {
        classes: object.isRequired,
        rowClassName: string,
        onRowClick: func,
        headerHeight: number,
        rowHeight: number,
        activeLanguage: object,
        history: object,
        match: object,
        translate: func,
        data: array,
        filteredData: array,
        store: object,
    }
    loadPneumaticData = async () => {
        this.setState({ loadingData: true })

        let pneumaticList = await getPneumaticSuspensionData()
        if (this._isMounted)
            this.setState({
                data: pneumaticList,
                loadingData: false,
            })
    }
    compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const codeA = a.code.toUpperCase()
        const codeB = b.code.toUpperCase()

        let comparison = 0
        if (codeA > codeB) {
            comparison = 1
        } else if (codeA < codeB) {
            comparison = -1
        }
        return comparison
    }
    loadMaterialTypes = async () => {
        this.setState({ loadingData: true })

        let response = await getMaterialTypes()
        response.sort(this.compare) // sorting on code
        let materialTypes = response.map(({ materialSubTypeId, name }) => ({
            id: materialSubTypeId,
            text: this.props.activeLanguage === 'fr' ? name[0].translation : name[1].translation,
        }))
        if (this._isMounted)
            this.setState({
                materialTypes: materialTypes,
                loadingData: false,
            })
    }
    handleAddPneumaticSuspension = async data => {
        this.setState({ addEditLoading: true })
        let isEdit = this.state.editDrawer
        if (isEdit) {
            data.id = this.state.editData.id
        }
        let response = await addEditPneumaticSuspension(data, isEdit)
        if (response.status === 200) {
            if (this._isMounted)
                this.setState(
                    {
                        addEditError: '',
                        openDrawer: false,
                        editDrawer: false,
                        addEditLoading: false,
                    },
                    () => this.loadPneumaticData()
                )
        } else {
            if (this._isMounted)
                this.setState({
                    addEditLoading: false,
                    addEditError: 'error:' + response.data.Message,
                })
        }
    }
    componentDidMount() {
        this._isMounted = true
        this.loadPneumaticData()
        this.loadMaterialTypes()
    }
    componentWillUnmount() {
        this._isMounted = false
    }

    getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = this.props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    onRowDeleteClick = (e, rowData) => {
        this.setState({
            openDeleteDialog: true,
            deleteRowData: rowData,
        })

        e.stopPropagation()
    }

    onRowEditClick = (e, rowData) => {
        this.setState({
            editDrawer: true,
            isOpen: true,
            rowData: rowData,
            openDrawer: true,
            editData: rowData,
        })
        e.stopPropagation()
    }

    handleAddClick = () => {
        this.setState({
            openDrawer: true,
            editDrawer: false,
            editData: '',
        })
    }

    onDrawerClose = () => {
        this.setState({ openDrawer: false, editDrawer: false, editData: '', addEditError: '', addEditLoading: false })
    }
    onDialogClose = () => {
        this.setState({
            openDeleteDialog: false,
            addEditError: '',
        })
    }
    reloadPneumaticData = () => {
        this.loadPneumaticData()
    }
    handleFilter = value => {
        if (value) {
            let data = this.state.data
            return data.filter(item => {
                return item.materialSubTypeCode?.toLowerCase().includes(value.toLowerCase())
            })
        } else return this.state.data
    }
    handleFilterSearch = e => {
        this.setState({ filterString: e.target.value })
    }
    headerRenderer = headerProps => {
        const { classes } = this.props
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: 56,
                    // paddingTop: 34,
                    paddingBottom: 34,
                    paddingRight: 0,
                }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }

    cellRenderer = cellProps => {
        const { classes } = this.props
        const { dataKey, rowData } = cellProps
        if (dataKey === 'code') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData.materialSubTypeCode ? rowData.materialSubTypeCode : 'N/A'}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'bogies') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    {rowData.restrictionPercentages.map((data, index) => {
                        return (
                            <Typography key={index}>
                                {data.minimumNumberOfBogies}-{data.maximumNumberOfBogies}
                            </Typography>
                        )
                    })}
                </TableCell>
            )
        }
        if (dataKey === 'restrictionPercentagePercentage') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    {rowData.restrictionPercentages.map((data, index) => {
                        return <Typography key={index}>{data.restrictionPercentage}</Typography>
                    })}
                </TableCell>
            )
        }

        if (dataKey === 'actions') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <div className={classes.multipleIcons}>
                        <Edit
                            className={classes.editIcon}
                            onClick={e => {
                                this.onRowEditClick(e, rowData)
                            }}
                        />

                        <Delete
                            className={classes.deleteIcon}
                            onClick={e => {
                                this.onRowDeleteClick(e, rowData)
                            }}
                        />
                    </div>
                </TableCell>
            )
        }
    }

    render() {
        const { classes } = this.props
        const { translate } = this.context
        let data = this.handleFilter(this.state.filterString)
        return (
            <React.Fragment>
                <Header isMenu title={translate('compositions_reference_data_title')} />
                <CommonFilter
                    handleFilterSearch={this.handleFilterSearch}
                    filterString={this.state.filterString}
                    {...this.props}
                />

                <div className={classes.parentDiv} style={{ display: 'flex' }}>
                    <Typography variant="h6">{translate('compositions_pneumatic_suspension_title')}</Typography>
                    <React.Fragment>
                        <Button variant="contained" color="primary" onClick={() => this.handleAddClick()}>
                            {translate('compositions_add_pneumatic_suspension_button')}
                        </Button>
                    </React.Fragment>
                </div>
                {this.state.loadingData && <Loading />}
                {!this.state.loadingData && (
                    <PneumaticSuspensionTable
                        columns={columns}
                        data={data}
                        classes={classes}
                        headerRenderer={this.headerRenderer}
                        cellRenderer={this.cellRenderer}
                        getRowClassName={this.getRowClassName}
                    />
                )}
                {this.state.openDrawer && (
                    <PneumaticSuspensionDrawer
                        isOpen={this.state.openDrawer}
                        editDrawer={this.state.editDrawer}
                        onClose={this.onDrawerClose}
                        editData={this.state.editData}
                        handleAdd={this.handleAddPneumaticSuspension}
                        addEditError={this.state.addEditError}
                        materialTypes={this.state.materialTypes}
                        addEditLoading={this.state.addEditLoading}
                        {...this.props}
                    />
                )}
                {this.state.openDeleteDialog && (
                    <DeleteDialog
                        dialogOpen={this.state.openDeleteDialog}
                        onDialogClose={this.onDialogClose}
                        reloadPneumaticData={this.reloadPneumaticData}
                        deleteData={this.state.deleteRowData}
                        {...this.props}
                    />
                )}
            </React.Fragment>
        )
    }
}

PneumaticListView.contextType = TranslationContext

export default PneumaticListView
