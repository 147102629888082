import React from 'react'
import { object, arrayOf, func, oneOfType, bool, number } from 'prop-types'
import axios from 'axios'
import { compose, mapProps } from 'recompose'
import { withStore, withClear, withStatus } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'

// Components

import PerformancesView from './PerformancesView'
import withFilteredTctData from './components/withFilteredTctData'
import { toDateTime, apiDateParser } from 'utils/dateParser'
import mapWithBitwise from 'utils/mapWithBitwise'
import callWithAxios from './components/withCall/callWithAxios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
const storeKeys = [
    'performances',
    // "mappedDistrictData",
    // "selectedDistrict",
    'selectedTCTs',
    'selectedDepot',
    'currentTime',
]

class Performances extends React.Component {
    static propTypes = {
        currentTime: number.isRequired,
        __ion_status: object.isRequired,
        tcts: arrayOf(object).isRequired,
        performanceDrawer: oneOfType([bool, object]).isRequired,
        persist: func.isRequired,
        store: object.isRequired,
        clearSignInOutCall: func,
        clearAssignedCall: func,
        translate: func,
    }

    state = {
        isAssignDrawerOpen: false,
        isAssignDriverDialogOpen: false,
        isUnassignDriverDialogOpen: false,
        isDelayReasonDialogOpen: false,
        tabIndex: 0,
        performancesGrouped: false,
        realTime: true,
        filterValue: '',
        scrollPosition: 0,
        performanceDriveDetailsDrawerOpen: false,
        performanceDriveDetailsDrawerData: null,
        performanceDriveDetailsDrawerParams: null,
        isDeletePerformanceDialogOpen: false,
        deletePerformanceId: '',
        deletePerformanceLoading: false,
        deletePerformanceError: '',
        performanceNotificationDrawerOpen: false,
        performanceNotificationDrawerData: null,
        performanceNotificationDrawerParams: null,
        activeTab: 2,
        performances: undefined,
        isLoading: false,
        timepickerDate: toDateTime(Date.now()),
    }
    _isMounted = false
    componentDidMount() {
        document.title = `${this.props.translate(this.props.store.get('selectedDistrict'))}`
        this.getLatestPerformances()
    }

    setParentstate = (type, value) => {
        this.setState({ [type]: value })
    }

    getLatestPerformances = () => {
        this._isMounted = true
        this.setState({ isLoading: true })
        let response = callWithAxios(this.props, this.state.timepickerDate)
        response.then(data => {
            if (this._isMounted) this.setState({ performances: data, isLoading: false })
        })
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    componentDidUpdate(prevProps) {
        // if realtime is disabled don't persist and refresh every minutems
        if (this.state.realTime) {
            if (
                prevProps.currentTime !== this.props.currentTime &&
                apiDateParser(this.state.timepickerDate.toMillis()) === apiDateParser(prevProps.currentTime * 1000)
            ) {
                if (!this.props.performanceDrawer) {
                    this.setState({ timepickerDate: toDateTime(Date.now()) }, () => {
                        this.getLatestPerformances()
                    })
                }
            }
        }

        if (this.props.performanceDrawer) {
            const selectedPerformance = this.props.performanceDrawer.performance
            const selectedTab = this.props.performanceDrawer.activeTab
            const updatedPerformances = this.state.performances.performances.filter(
                p =>
                    p.id === selectedPerformance.id &&
                    p.idfNumber === selectedPerformance.idfNumber &&
                    mapWithBitwise(selectedTab, p.tab)
            )

            if (updatedPerformances.length < 1) this.closePerformanceDrawer()
        }
    }
    refreshPerformances = () => {
        this.getLatestPerformances()
    }
    doDeletePerformance = id => {
        this.setState(
            {
                deletePerformanceId: id,
                deletePerformanceLoading: true,
            },
            () => {
                this.doDeletePerformanceCall(id)
            }
        )
    }

    doDeletePerformanceCall = async id => {
        const { url, subscriptionKey } = requestData
        const fullUrl = `${url}/performance/${id}`

        try {
            const response = await axios.delete(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.setState({
                    deletePerformanceError: '',
                    deletePerformanceLoading: false,
                })
                this.refreshAndCloseDeleteDialog()
            }
            if (response.status === 200) {
                this.setState({
                    deletePerformanceError: '',
                    deletePerformanceLoading: false,
                })
                this.refreshAndCloseDeleteDialog()
            }
        } catch (e) {
            this.setState({
                deletePerformanceError: 'Er ging iets mis',
                deletePerformanceLoading: false,
            })
            console.error(e) // eslint-disable-line
        }
    }

    closeDeletePerformanceDialog = () => {
        this.setState({
            isDeletePerformanceDialogOpen: false,
            deletePerformanceId: '',
            deletePerformanceLoading: false,
            deletePerformanceError: '',
        })
    }

    refreshAndCloseDeleteDialog = () => {
        this.refreshPerformances()
        this.closeDeletePerformanceDialog()
    }

    openDeletePerformanceDialog = (e, id) => {
        e.stopPropagation()
        this.setState({
            isDeletePerformanceDialogOpen: true,
            deletePerformanceId: id,
        })
    }

    saveScrollPosition = value => {
        this.setState({ scrollPosition: value })
    }
    saveActiveTab = value => {
        this.setState({ activeTab: value })
    }
    // openDelayReasonDialog = () => {
    //   this.setState({ isDelayReasonDialogOpen: true });
    // };

    // closeDelayReasonDialog = () => {
    //   this.setState({ isDelayReasonDialogOpen: false });
    // };

    toggleAssignDrawer = isOpen => {
        this.setState({ isAssignDrawerOpen: isOpen })
    }

    closePerformanceDrawer = (reload = false) => {
        this.props.clearSignInOutCall()
        this.props.clearAssignedCall()
        this.props.persist('performanceDrawer')(false)
        if (reload) this.refreshPerformances()
    }

    toggleCollapse = selectedPerformance => {
        const oldSelectedPerformance = this.props.store.get('selectedPerformance')
        if (selectedPerformance === oldSelectedPerformance) this.props.store.set({ selectedPerformance: undefined })
        else this.props.store.set({ selectedPerformance })
    }

    toggleGroup = () => this.setState({ performancesGrouped: !this.state.performancesGrouped, scrollPosition: 0 })

    toggleRealTime = () => {
        const { realTime } = this.state
        if (!realTime) {
            //reset date to now and change state
            this.timePickerChanged('date')(toDateTime(this.props.currentTime * 1000))
        }

        this.setState({ realTime: !realTime })
    }

    filterPerformances = (performances, filterValue) => {
        let filtered = []
        if (filterValue.length === 0) return performances
        if (performances && performances.length > 0) {
            filtered = performances.filter(
                perf =>
                    (perf.profileName ? perf.profileName.toLowerCase().includes(filterValue.toLowerCase()) : '') ||
                    (perf.idfNumber ? perf.idfNumber.toLowerCase().includes(filterValue.toLowerCase()) : '') ||
                    (perf.performanceNumber ? perf.performanceNumber.toLowerCase().includes(filterValue.toLowerCase()) : '')
            )
        }

        return filtered
    }

    handleFilterValue = e => {
        this.setState({ filterValue: e.target.value, scrollPosition: 0 })
    }

    mapColors = performances => {
        let colors = { yellow: 0, red: 0 }
        if (performances && performances.length > 0) {
            // eslint-disable-next-line array-callback-return
            performances.map(p => {
                if (p.status === 1) {
                    return (colors.yellow += 1)
                }
                if (p.status === 2) {
                    return (colors.red += 1)
                }
            })
        }
        return colors
    }

    openPerformanceDriveDetailsDrawer = (e, params) => {
        this.setState({ performanceDriveDetailsDrawerOpen: true, performanceDriveDetailsDrawerParams: params })

        e.stopPropagation()
    }

    closePerformanceDriveDetailsDrawer = () => {
        this.setState({
            performanceDriveDetailsDrawerOpen: false,
            performanceDriveDetailsDrawerData: null,
            performanceDriveDetailsDrawerParams: null,
        })
    }
    openPerformanceNotificationDrawer = (e, params) => {
        this.setState({ performanceNotificationDrawerOpen: true, performanceNotificationDrawerParams: params })

        e.stopPropagation()
    }
    closePerformanceNotificationDrawer = () => {
        this.setState({
            performanceNotificationDrawerOpen: false,
            performanceNotificationDrawerData: null,
            performanceNotificationDrawerParams: null,
        })
    }
    timePickerChanged = type => e => {
        if (type === 'date')
            this.setState({ realTime: false, timepickerDate: e }, () => {
                this.getLatestPerformances()
            })
        if (type === 'time') this.setState({ timepickerDate: e })
        else this.props.persist(type)(e)
    }

    render() {
        const { currentTime, store } = this.props
        const { selectedDepot, selectedTCTs } = store.get(storeKeys)

        const performances = this.state.performances
        const {
            realTime,
            filterValue,
            scrollPosition,
            performanceDriveDetailsDrawerOpen,
            performanceDriveDetailsDrawerData,
            performanceDriveDetailsDrawerParams,
            isDeletePerformanceDialogOpen,
            deletePerformanceId,
            deletePerformanceLoading,
            deletePerformanceError,
            performanceNotificationDrawerOpen,
            performanceNotificationDrawerData,
            performanceNotificationDrawerParams,
        } = this.state
        const syncData = performances ? performances.syncInfo : null

        let filteredPerformances = this.filterPerformances(
            performances !== undefined ? performances?.performances : [],
            filterValue
        )
        let colorValues = this.mapColors(filteredPerformances)

        return (
            <PerformancesView
                store={store}
                handleFilterValue={this.handleFilterValue}
                filterValue={filterValue}
                colorValues={colorValues}
                currentTime={currentTime * 1000}
                persist={e => this.timePickerChanged(e)}
                timepickerDate={this.state.timepickerDate}
                // Depots
                selectedDepot={selectedDepot}
                // TCTs
                tcts={this.props.tcts}
                selectedTCTs={selectedTCTs}
                // Performances
                performances={filteredPerformances || []}
                performancesGrouped={this.state.performancesGrouped}
                toggleGroup={this.toggleGroup}
                // AssignDrawer
                toggleAssignDrawer={this.toggleAssignDrawer}
                toggleCollapse={this.toggleCollapse}
                isAssignDrawerOpen={this.state.isAssignDrawerOpen}
                // PerformanceInfoDrawer
                performanceDrawer={this.props.performanceDrawer}
                closePerformanceDrawer={this.closePerformanceDrawer}
                realTime={realTime}
                toggleRealTime={this.toggleRealTime}
                saveScrollPosition={this.saveScrollPosition}
                scrollPosition={scrollPosition}
                // DelayReasonDialog
                // openDelayReasonDialog={this.openDelayReasonDialog}
                // closeDelayReasonDialog={this.closeDelayReasonDialog}
                // isDelayReasonDialogOpen={this.state.isDelayReasonDialogOpen}
                performancesIsLoading={this.state.isLoading}
                fromDate={performances !== undefined ? performances.fromDate : ''}
                toDate={performances !== undefined ? performances.toDate : ''}
                openPerformanceDriveDetailsDrawer={this.openPerformanceDriveDetailsDrawer}
                closePerformanceDriveDetailsDrawer={this.closePerformanceDriveDetailsDrawer}
                performanceDriveDetailsDrawerOpen={performanceDriveDetailsDrawerOpen}
                performanceDriveDetailsDrawerData={performanceDriveDetailsDrawerData}
                performanceDriveDetailsDrawerParams={performanceDriveDetailsDrawerParams}
                setParentstate={this.setParentstate}
                syncData={syncData}
                doDeletePerformance={this.doDeletePerformance}
                openDeletePerformanceDialog={this.openDeletePerformanceDialog}
                closeDeletePerformanceDialog={this.closeDeletePerformanceDialog}
                isDeletePerformanceDialogOpen={isDeletePerformanceDialogOpen}
                deletePerformanceId={deletePerformanceId}
                deletePerformanceLoading={deletePerformanceLoading}
                deletePerformanceError={deletePerformanceError}
                openPerformanceNotificationDrawer={this.openPerformanceNotificationDrawer}
                closePerformanceNotificationDrawer={this.closePerformanceNotificationDrawer}
                performanceNotificationDrawerOpen={performanceNotificationDrawerOpen}
                performanceNotificationDrawerData={performanceNotificationDrawerData}
                performanceNotificationDrawerParams={performanceNotificationDrawerParams}
                refreshPerformances={this.refreshPerformances}
                saveActiveTab={this.saveActiveTab}
            />
        )
    }
}

export default compose(
    withStore,
    withStatus,
    withLocalize,
    withFilteredTctData,
    withClear('clearSignInOutCall', 'signInOutCall'),
    withClear('clearAssignedCall', 'assignDriverCall'),
    mapProps(props => ({
        ...props,
        currentTime: props.store.get('currentTime'),
    }))
)(Performances)
