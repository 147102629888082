import React, { useContext, useState } from 'react'
import { object, string, func, bool, number } from 'prop-types'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import { withRouter } from 'react-router-dom'
import { Switch } from '@material-ui/core'
// Material UI
import { withStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import PowerSettingsIcon from '@material-ui/icons/PowerSettingsNew'

import Settings from '@material-ui/icons/Settings'
import MoreIcon from '@material-ui/icons/MoreVert'
import CameraIcon from '@material-ui/icons/Camera'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// Components
import AccountMenu from './components/AccountMenu'
import UserSettings from './components/UserSettings'
import ShowTime from './components/ShowTime'

import styles from './styles'

import { ThemeContext } from '../../theme/ThemeProvider'

import { isMobile } from 'react-device-detect'
import { TranslationContext } from 'context/translation'

const HeaderView = props => {
    HeaderView.propTypes = {
        title: string.isRequired,
        classes: object.isRequired,
        currentTime: number.isRequired,
        logout: func.isRequired,
        isMenu: bool,
        isNothing: bool,
        isMenuOpen: bool,
        action: func,
        isDriver: bool,
        store: object,
        history: object,
        onAlertSwitched: func,
        pageTitle: string,
        resetInterval: func,
        resetIntervalTaskDetail: func,
        showTime: bool,
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const [openSettings, setOpenSettings] = useState(false)
    const { themeName, setThemeName } = useContext(ThemeContext)
    const { translate } = React.useContext(TranslationContext)

    const openDrop = event => {
        setAnchorEl(event.currentTarget)
    }
    const closeDrop = () => {
        setAnchorEl(null)
    }

    const closeSettingsDialog = () => {
        setOpenSettings(false)
        setAnchorEl(null)
    }

    const openSettingsDialog = () => {
        setOpenSettings(true)
    }

    const handleThemeChange = () => {
        themeName === 'dark' ? setThemeName('light') : setThemeName('dark')
    }

    const { classes, title, action, isMenu, isNothing, isMenuOpen, logout, isDriver, pageTitle, history, showTime } = props

    let menuButton
    if (isMenu) {
        menuButton = isMenuOpen ? (
            <IconButton data-testid="menu_close_button" component="span" onClick={action} className={classes.button}>
                <CloseIcon />
            </IconButton>
        ) : (
            <IconButton data-testid="menu_open_button" component="span" onClick={action} className={classes.button}>
                <MenuIcon />
            </IconButton>
        )
    } else if (isNothing) {
        menuButton = <span data-testid="menu_no_button"></span>
    } else
        menuButton = (
            <IconButton
                data-testid="menu_backup_button"
                component="span"
                onClick={() => action(title === 'TC286')}
                className={classes.button}
            >
                <ArrowBackIcon />
            </IconButton>
        )

    return (
        <React.Fragment>
            <AppBar
                data-testid="HeaderView-appbar"
                color="default"
                elevation={1}
                className={classes.appBar}
                style={{ zIndex: 1200 }}
            >
                <Toolbar className={classes.flex}>
                    {!isDriver && menuButton}
                    <Typography variant={showTime ? 'h6' : 'body2'} className={classes.mobileTitle}>
                        {title}
                    </Typography>
                    {showTime && (
                        <span className={classes.date}>
                            <ShowTime />
                        </span>
                    )}

                    <AccountMenu />
                    <Button data-testid="open_drop_button" aria-controls="dropdots" aria-haspopup="true" onClick={openDrop}>
                        <MoreIcon />
                    </Button>
                    <Menu
                        data-testid="dropdots_menu"
                        id="dropdots"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={closeDrop}
                    >
                        {pageTitle && !isMobile ? (
                            <MenuItem
                                data-testid="open_dialog_button"
                                onClick={() => {
                                    openSettingsDialog()
                                }}
                            >
                                <Settings /> {translate('user_settings')}
                            </MenuItem>
                        ) : null}

                        <MenuItem
                            onClick={() => {
                                history.push('/ocr')
                            }}
                        >
                            <CameraIcon />
                            OCR POC
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleThemeChange()
                            }}
                        >
                            {translate('theme_dark')}
                            <Switch checked={themeName === 'light'} color="primary" />
                            {translate('theme_light')}
                        </MenuItem>
                        <MenuItem onClick={() => logout(props.store)}>
                            <PowerSettingsIcon />
                            {translate('log_out')}
                        </MenuItem>
                        <MenuItem>
                            <Typography style={{ textAlign: 'center' }}>v 1.118.1</Typography>
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            {openSettings && (
                <UserSettings
                    handleClose={closeSettingsDialog}
                    open={openSettings}
                    onAlertSwitched={props.onAlertSwitched}
                    pageTitle={pageTitle}
                    resetInterval={props.resetInterval}
                    resetIntervalTaskDetail={props.resetIntervalTaskDetail}
                />
            )}
            <div className={classes.afterToolbar} />
        </React.Fragment>
    )
    // }
}

export default compose(withStyles(styles), withStore, withRouter)(HeaderView)
