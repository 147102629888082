/* eslint-disable react/prop-types */
import React from 'react'
import { compose } from 'recompose'
import { withStore, withCall } from 'react-ion-store'
import { EnforceDeselect } from '../../enums/EnforceDeselect'

const callKey = 'assignDriverCall'

const AssignDriverCall = () => null
//"Profile already has an overlapping performance"
export default compose(withStore, Component => props => {
    const callData = props.store.get(callKey) || {}
    const { idf, id, performanceIdfNumber, replace, enforceDeselect=EnforceDeselect.Undefined } = callData
    return idf && id ? (
        withCall(callKey, {
            config: {
                method: 'post',
                url: `/performance/${id}/assign/${idf}${
                    performanceIdfNumber
                        ? `?performanceIdfNumber=${performanceIdfNumber}&enforce=${replace}`
                        : `?enforce=${replace}`
                }${
                    `&enforceDeselect=${enforceDeselect}`
                }`,
            },
        })(Component)(props)
    ) : (
        <Component {...props} />
    )
})(AssignDriverCall)
