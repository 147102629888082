import React from 'react'
import { object, string, func, bool, array } from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import getNormalizedMessage from 'utils/getNormalizedMessage'
// Components
import IconButton from 'components/IconButton'
import AppPaper from 'components/AppPaper'
import ErrorMessage from 'components/ErrorMessage'

class RemarkFooterView extends React.Component {
    static propTypes = {
        classes: object.isRequired,
        remark: string,
        history: object,
        disabled: bool,
        addTask: func,
        checkedTasks: array,
        chooseFromOtherPerformance: bool,
        addMultipleTasksLoading: bool,
        addMultipleTasksError: string,
        __ion_status: object,
        store: object,
    }

    goBack = () => {
        const { history } = this.props
        history.goBack()
    }

    render() {
        const {
            classes,
            disabled,
            addTask,
            checkedTasks,
            chooseFromOtherPerformance,
            addMultipleTasksLoading,
            addMultipleTasksError,
            __ion_status,
        } = this.props

        let disableButton = disabled

        if (checkedTasks && checkedTasks.length > 0 && chooseFromOtherPerformance && !addMultipleTasksLoading) {
            disableButton = false
        }
        if (__ion_status.addTaskCall !== undefined && (__ion_status.addTaskCall === 1 || __ion_status.addTaskCall === 2)) {
            disableButton = true
        }
        return (
            <AppPaper className={classes.root}>
                <Translate>
                    {({ translate }) => (
                        <React.Fragment>
                            <IconButton
                                className={classes.marginButton}
                                icon=""
                                color="primary"
                                title={translate('CancelCaps')}
                                onClick={() => {
                                    this.goBack()
                                }}
                            />
                            <IconButton
                                date-testid="submit-performance-task-button"
                                disabled={disableButton}
                                icon="note_add"
                                color="primary"
                                title={translate('PerformanceAddTask')}
                                onClick={() => addTask()}
                            />
                            {__ion_status['addTaskCall'] === 4 && (
                                <ErrorMessage
                                    error={
                                        getNormalizedMessage(
                                            this.props.store.addTaskCall._root.entries[2][1].response.data
                                        ).indexOf('Task not in performance window.') !== -1
                                            ? translate('add_task_error_msg')
                                            : translate('roles_error') ||
                                              this.props.store.addTaskCall._root.entries[2][1].response.data.Message
                                    }
                                />
                            )}
                            {addMultipleTasksError && addMultipleTasksError.length && (
                                <ErrorMessage error={addMultipleTasksError} />
                            )}
                        </React.Fragment>
                    )}
                </Translate>
            </AppPaper>
        )
    }
}

export default withRouter(RemarkFooterView)
