import React from 'react'
import { func, string, object, bool, number } from 'prop-types'
import { Button, Toolbar, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'
import { dateTimeWithDash } from 'utils/dateParser'
import theme from '../../../../../../../../theme'
import styles from './styles'
import { getIdfFromStore } from 'utils/isAgendaAllowed'
import withWidth from '@material-ui/core/withWidth'
import { compose } from 'recompose'
import { isCompoEditingAllowed } from '../../Utils/isCompoEditingAllowed'
import { deviceOrigin } from './deviceOrigin'

const VersionInfo = props => {
    const {
        versionNumber,
        versionTime,
        deviceUser,
        translate,
        classes,
        brakingReport,
        resetComposition,
        onSaveClicked,
        // store,
        isResetDisable,
        isLocalVersion,
        idfNumber,
        status,
        isSaveDisable,
        isBrakingReportSimulated,
        simulatedBrakingReportLoading,
    } = props
    // Convert UTC time to local time as we are receiving time in UTC.
    const timeString = dateTimeWithDash(new Date(versionTime).getTime())
    const origin = () => {
        if (deviceUser) {
            if (deviceUser === 'TrainDriver') {
                return deviceOrigin.TrainDriver
            }
            if (deviceUser === 'TrainManager') {
                return deviceOrigin.TrainManager
            }
            if (deviceUser === 'eShunting') {
                return deviceOrigin.eShunting
            }
            if (deviceUser === '(Planned)') {
                return deviceOrigin.Planned
            } else {
                return ''
            }
        }
    }

    const getVersionColor = () => {
        let portalUser = getIdfFromStore(props.store.get('auth'))
        if (status) return theme.customColors.red
        if (isLocalVersion && portalUser === idfNumber) return theme.customColors.versionYellow
        if (portalUser === idfNumber) return theme.customColors.green
        if (portalUser !== idfNumber) return theme.customColors.orange
    }

    return (
        <Toolbar className={classes.bbDiv} disableGutters={true}>
            <div style={{ width: '100%' }}>
                <div className={classes.bbInfo}>
                    <div
                        className={classes.versionBackground}
                        style={{
                            borderLeft: `8px solid ${getVersionColor()}`,
                        }}
                    >
                        <Typography variant="body2">
                            {translate('composition_version') +
                                ` ${versionNumber === undefined ? '0' : versionNumber}` +
                                ` ${timeString}`}
                        </Typography>
                        <Typography variant="body2">
                            {` ${deviceUser ? deviceUser : ''}`} {origin()}
                        </Typography>
                    </div>

                </div>
                <div className={classes.bbInfo}>
                    <Typography variant="body2" className={props.classes.mobileFont}>
                        {brakingReport?.brakingPercentageTotal || '-'}%
                    </Typography>
                    <Typography variant="body2" className={props.classes.mobileFont}>
                        #{brakingReport?.numberTotal}
                    </Typography>
                    <Typography variant="body2" className={props.classes.mobileFont}>
                        {brakingReport?.massTotal || '-'}T
                    </Typography>
                    <Typography variant="body2" className={props.classes.mobileFont}>
                        {brakingReport?.lengthTotal || '-'}meter
                    </Typography>
                    <Typography variant="body2" className={props.classes.mobileFont}>
                        {brakingReport?.maxSpeedLimit || '-'}Km/h
                    </Typography>
                    <CancelPresentationIcon
                        className={isResetDisable ? classes.closeIconDisabled : classes.closeIcon}
                        disabled={isResetDisable}
                        onClick={isCompoEditingAllowed(props) ? resetComposition : null}
                        style={{ height: '30px', width: '30px' }}
                    />
                    {isBrakingReportSimulated ?
                    <label>
                    {simulatedBrakingReportLoading ? translate('TC286_report_simulation_label_loading') 
                    : translate('TC286_report_simulation_label')}
                    </label>
                    :
                    simulatedBrakingReportLoading ? 
                        <label>
                            {translate('TC286_report_simulation_label_loading')}
                        </label>
                        : null
                    }
                    <Button
                        size={'small'}
                        variant="contained"
                        color="primary"
                        onClick={onSaveClicked}
                        disabled={isSaveDisable || !isCompoEditingAllowed(props)}
                    >
                        {translate('add_user_confirm_save')}
                    </Button>
                </div>
            </div>
        </Toolbar>
    )
}
VersionInfo.propTypes = {
    classes: object,
    versionNumber: string,
    deviceUser: string,
    versionTime: string,
    translate: func,
    brakingReport: object,
    resetComposition: func,
    onSaveClicked: func,
    store: object,
    isResetDisable: bool,
    isLocalVersion: bool,
    idfNumber: string,
    status: number,
    width: string,
    isSaveDisable: bool,
    isBrakingReportSimulated: bool,
    simulatedBrakingReportLoading: bool,
}
export default compose(withWidth(), withStyles(styles))(VersionInfo)
