import { Typography } from '@material-ui/core'
import React from 'react'
import { func, object, array } from 'prop-types'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
const VehicleDetailsView = props => {
    const getField = (title, value) => {
        return (
            <div style={{ margin: '4px', display: 'flex', justifyContent: 'space-between', width: '70%' }}>
                <Typography style={{ textDecoration: 'underline' }}>{`${props.translate(title)}:`}</Typography>
                <Typography>{value}</Typography>
            </div>
        )
    }
    const getYesOrNo = value => (value ? <DoneIcon color="primary" /> : <CloseIcon style={{ color: 'yellow' }} />)
    const { vehicleNumberSubType, maximumSpeedMotorUnits, relatedVehicleNumberSubTypes } = props.vehicleData
    // sort vehicleNumbers ASC
    relatedVehicleNumberSubTypes.sort((a, b) => a.vehicleNumber - b.vehicleNumber)
    return (
        <div data-testid={'vehicleDetails_View'} style={{ display: 'flex', padding: '20px' }}>
            {relatedVehicleNumberSubTypes.length > 0 && (
                <div className={props.classes.related}>
                    {relatedVehicleNumberSubTypes.map(el => (
                        <p key={el.vehicleNumber}>{el.vehicleNumber}</p>
                    ))}
                </div>
            )}
            <div className={props.classes.childDiv}>
                {getField('label_type', vehicleNumberSubType.materialTypeCode)}
                {getField('label_sub_type', vehicleNumberSubType.materialSubTypeCode)}
                {getField('label_uicCode', vehicleNumberSubType.uicCode)}
                {getField('label_nmbs_nr', vehicleNumberSubType.vehicleNumber)}
                {getField('label_sap_profile', vehicleNumberSubType.catalogProfile)}
                {getField('label_etcs', vehicleNumberSubType.etcsLevel)}
                {getField('label_no_of_units', vehicleNumberSubType.numberVehicleUnits)}
                {getField('label_assen', vehicleNumberSubType.numberAxles)}
                {getField('label_bogies', vehicleNumberSubType.numberBogies)}
            </div>
            <div className={props.classes.childDiv}>
                {getField('label_lengte', `${vehicleNumberSubType.lengthMeter}m`)}
                {getField('label_max_speed', `${vehicleNumberSubType.maxSpeed} km/h`)}
                {getField('label_max_speed_empty', `${vehicleNumberSubType.maxSpeedEmpty} km/h`)}
                {getField('label_max_speed_loc_train', `${vehicleNumberSubType.maxSpeedLocTrain} km/h`)}
                {getField('label_bruto', `${vehicleNumberSubType.brutoWeightTon}T`)}
                {getField('label_tarra', `${vehicleNumberSubType.tarraWeightTon}T`)}
                {getField('label_regime_r', vehicleNumberSubType.brakeMassR)}
                {getField('label_regime_g', vehicleNumberSubType.brakeMassG)}
                {getField('label_regime_p', vehicleNumberSubType.brakeMassP)}
            </div>
            <div className={props.classes.childDiv}>
                {getField('label_one_class', vehicleNumberSubType.numberSeatsClass1)}
                {getField('label_second_class', vehicleNumberSubType.numberSeatsClass2)}
                {getField('label_stuurpost', getYesOrNo(vehicleNumberSubType.hasDriverCockpit))}
                {getField('label_pneumatic_suspension', getYesOrNo(vehicleNumberSubType.hasPneumaticSuspension))}
                {getField('label_magneetrem', getYesOrNo(vehicleNumberSubType.hasMagnetBrake))}
                {getField('label_hogedrukrem', getYesOrNo(vehicleNumberSubType.hasHighPressureBrake))}
                {getField('label_electic_pneumatic', getYesOrNo(vehicleNumberSubType.hasElectroPneumaticBrake))}
                {getField('label_dynamische', getYesOrNo(vehicleNumberSubType.hasDynamicBrake))}
                {getField('label_afzonderen', getYesOrNo(vehicleNumberSubType.hasBrakePerBogie))}
            </div>
            <div data-testid={'tableDiv'} style={{ width: '25%', marginTop: '4px', justifyContent: 'space-evenly' }}>
                {maximumSpeedMotorUnits.length > 0 && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={props.classes.tableCell}>{'%Ts'}</TableCell>
                                <TableCell className={props.classes.tableCell}> V</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {maximumSpeedMotorUnits &&
                                maximumSpeedMotorUnits
                                    .sort((a, b) => a.maximumSpeed - b.maximumSpeed)
                                    .map((item, index) => (
                                        <TableRow
                                            key={index}
                                            onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                            }}
                                        >
                                            <TableCell className={props.classes.tableCell}>
                                                <Typography variant="body1">{`${item.minimumBrakePercentage}`}</Typography>
                                            </TableCell>
                                            <TableCell className={props.classes.tableCell}>
                                                <Typography variant="body1">{item.maximumSpeed}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                )}
            </div>
        </div>
    )
}
VehicleDetailsView.propTypes = {
    vehicleNumberSubType: object,
    maximumSpeedMotorUnits: array,
    vehicleData: object,
    classes: object,
    translate: func,
}
export default VehicleDetailsView
