import React from 'react'
import { object, string, oneOfType, array } from 'prop-types'
import { withStyles } from '@material-ui/styles'
import styles from './styles'
import { Typography } from '@material-ui/core'
const ReportData = props => {
    return (
        <div className={props.classes.reportSectionLayout}>
            <Typography className={props.classes.reportTitle} variant={'h6'}>
                {props.title}
            </Typography>
            {props.children}
        </div>
    )
}
ReportData.propTypes = {
    classes: object.isRequired,
    children: oneOfType([array, object]),
    title: string,
}
export default withStyles(styles)(ReportData)
