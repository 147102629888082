const VehicleDetailsActions = {
    LOAD_VEHICLE_DATA: 'LOAD_VEHICLE_DATA',
    LOADING: 'LOADING',
    REAL_TIME: 'REAL_TIME',
    SET_ERROR: 'SET_ERROR',
    SET_DATE: 'SET_DATE',
    SET_VEHICLE_LIST: 'SET_VEHICLE_LIST',
    SET_VEHICLE: 'SET_VEHICLE',
}
export default VehicleDetailsActions
