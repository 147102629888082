export default () => ({
    link: {
        fontWeight: 'bold',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    row: {
        margin: '8px , 5px',
        width: '450px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
})
