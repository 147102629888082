export default theme => ({
    drawer: {
        margin: '16px',
        [theme.breakpoints.down('xs')]: {
            // width: 'auto',
            // display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '70vw',
            minWidth: '60vw',
        },
    },
    text: {
        marginBottom: '16px',
    },
})
