export default theme => ({
    root: {
        margin: `${theme.spacing(2)}px`,
        border: '1px solid',
        borderColor: theme.customColors.grey,
    },
    column: {
        padding: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
        border: '1px solid',
        borderColor: theme.customColors.grey,
    },
})
