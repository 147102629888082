export default theme => ({
    rootDiv: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        },
    },
    fab: {
        zIndex: '2',
        position: 'fixed',
        bottom: '90px',
        right: theme.spacing(2),
    },
    fabIcons: {
        position: 'absolute',
        bottom: '150px',
        right: theme.spacing(2),
    },
    backdrop: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: '1',
        left: '0',
        top: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
})
