import axios from 'axios'
import { DateTime } from 'luxon'
const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default async (trainNumber, trainPrefix, selectedDate, start, end) => {
    const { url, subscriptionKey } = requestData

    const startHour = start.substring(0, 2)
    const startMin = start.substring(3, 5)
    const endHour = end.substring(0, 2)
    const endMin = end.substring(3, 5)
    let oneDay = 0
    if (parseInt(endHour) < parseInt(startHour)) oneDay = 24 * 60 * 60

    const startDate = DateTime.fromSeconds(selectedDate).toFormat('ddMMyyyy') + startHour + startMin
    const endDate = DateTime.fromSeconds(selectedDate + oneDay).toFormat('ddMMyyyy') + endHour + endMin

    const prefix = trainPrefix !== ' ' ? `?trainPrefix=${trainPrefix}` : ''
    const fullUrl =
        `${url}/referenceData/trainlocations/${trainNumber}/startDateTime/${startDate}/endDateTime/${endDate}` + prefix
    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            return []
        }
        return response.data
    } catch (e) {
        console.error(e) // eslint-disable-line
    }
}
