import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography, withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'
import some from 'lodash/some'
import { object, func, string, oneOfType, number, array, bool } from 'prop-types'

import SelectInput from 'components/SelectInput'
import PerformanceSelect from './performanceSelect'
import { DatePickerWithKeyboard } from 'components/Pickers'
import styles from './styles'

const storeKey = 'mappedDistrictDataNoFilter'

export class FirstStep extends Component {
    static propTypes = {
        persist: func.isRequired,
        store: object.isRequired,
        org: string.isRequired,
        performanceDetail: object,
        handleDateChange: func.isRequired,
        selectedDate: oneOfType([object, number]).isRequired,
        translate: func,
        classes: object,
        depot: string,
        selectedPerformanceNumber: string,
        loadingPerformance: bool,
        loadPerformanceNumbers: func,
        performanceNumbersData: array,
    }

    handleEvent = type => e => this.props.persist(type)(e.target.value)

    handleOrgEvent = value => this.props.persist('org')(value)

    constructor() {
        super()
        this.state = {}
    }

    render() {
        const {
            store,
            classes,
            depot,
            org,
            performanceDetail,
            translate,
            selectedPerformanceNumber,
            loadingPerformance,
            loadPerformanceNumbers,
            performanceNumbersData,
            handleDateChange,
            selectedDate,
            // depotSymbolic
        } = this.props
        const districts = store.get(storeKey) || []

        const tcts = districts.reduce(
            (aggregrate, district) => [...aggregrate, ...district.tcts.map(tct => ({ id: `${tct.tctId}`, text: tct.id }))],
            [{ id: ' ', text: 'documents_label_select_org' }]
        )

        let chosenDistrict = districts.find(district => some(district.tcts, { tctId: parseInt(org, 10) })) || {
            tcts: [],
        }

        const chosenTct = chosenDistrict.tcts.find(tct => tct.tctId === parseInt(org, 10)) || {
            depots: [],
        }

        const depots = chosenTct.depots.reduce(
            (aggregate, chosenDepot) => [...aggregate, { id: `${chosenDepot.depotId}`, text: chosenDepot.id }],
            [{ id: ' ', text: 'documents_label_select_depot' }]
        )

        let performanceId = null
        let performanceNumber = null
        let depotLabel = null
        let startTime = ''
        let endTime = ''

        if (performanceDetail) {
            performanceId = performanceDetail.performanceId
            performanceNumber = performanceDetail.performanceNumber
            depotLabel = performanceDetail.depot
            startTime = performanceDetail.plannedStartTime
            endTime = performanceDetail.plannedEndTime
        }

        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {performanceId !== null && performanceNumber !== null && depotLabel !== null
                            ? 'Prestatie: '
                            : translate('performance_task_add_label_select_performance')}
                    </Typography>
                </Grid>
                {performanceId === null && performanceNumber === null && depotLabel === null && (
                    <React.Fragment>
                        <Grid item xs={3}>
                            <DatePickerWithKeyboard
                                label="DatePicker"
                                animateYearScrolling
                                value={selectedDate}
                                onChange={e => handleDateChange(e)}
                                format="dd/MM/yyyy"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <div style={{ display: 'inline-block' }}>
                                <SelectInput
                                    sorted
                                    name="org"
                                    classes={classes}
                                    items={tcts.map(tct => ({ ...tct, text: translate(tct.text) }))}
                                    value={org}
                                    onChange={this.handleEvent('org')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div style={{ display: 'inline-block' }}>
                                <SelectInput
                                    sorted
                                    name="depot"
                                    classes={classes}
                                    items={depots.map(dep => ({ ...dep, text: translate(dep.text) }))}
                                    value={org === ' ' ? ' ' : depot}
                                    onChange={this.handleEvent('depot')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            {depot !== ' ' && depot && (
                                <PerformanceSelect
                                    depot={depot}
                                    handleEvent={this.handleEvent}
                                    selectedPerformanceNumber={selectedPerformanceNumber}
                                    loadingPerformance={loadingPerformance}
                                    loadPerformanceNumbers={loadPerformanceNumbers}
                                    performanceNumbersData={performanceNumbersData}
                                    classes={classes}
                                    depots={chosenTct}
                                />
                            )}
                        </Grid>
                    </React.Fragment>
                )}

                {performanceId !== null && performanceNumber !== null && depotLabel !== null && (
                    <React.Fragment>
                        <Grid item xs={2}>
                            <Typography variant="h6">{`${translate(
                                'documents_label_select_depot'
                            )}: ${depotLabel}`}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h6">{`${translate(
                                'performance_info_label_performance_id'
                            )}: ${performanceId}`}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h6">{`${translate(
                                'performance_info_label_performance_number'
                            )}: ${performanceNumber}`}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h6">{`${translate('start_time')}: ${startTime.substr(0, 5)}`}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h6">{`${translate('end_time')}: ${endTime.substr(0, 5)}`}</Typography>
                        </Grid>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default compose(withStore, withLocalize, withStyles(styles))(FirstStep)
