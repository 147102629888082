import { createMuiTheme } from '@material-ui/core'
import blue from '@material-ui/core/colors/blue'
import red from '@material-ui/core/colors/red'

export default createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: blue,
        type: 'dark',
        secondary: red,
    },
    headerHeight: {
        sm: 56,
        md: 64,
    },
    languagePicker: blue['500'],
    moduleMenu: 300,
    readStatusBoxes: 500,
    readList: 452,
    performanceView: 324,
    taskList: 250,
    driveDetailList: 330,
    driveDetailsListForXl: 800,
    spacing: 8,
    customColors: {
        errorText: red['400'],
        lightGrey: '#979797',
        yellow: '#494332',
        darkRed: '#442A2A',
        detailGrey: '#424242',
        white: '#fafafa',
        primaryBlue: '#1976d2',
        versionYellow: 'yellow',
        green: 'green',
        red: 'red',
        orange: 'orange',
    },
})
