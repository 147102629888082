export default theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        color: theme.customColors.lightGrey,
        backgroundColor: theme.palette.grey['800'],
        border: '1px solid white',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        padding: theme.spacing(1),
    },
    searchError: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        color: theme.customColors.lightGrey,
        backgroundColor: theme.palette.grey['800'],
        border: '1px solid red',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        padding: theme.spacing(1),
    },
    searchIcon: {
        color: theme.customColors.lightGrey,
        width: theme.spacing(3),
        height: '36px',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})
