import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import SortFeedbackForm from '../SortFeedbackForm'
import { feedbackCategoriesContext } from 'modules/FeedbackCategories/store.feedbackCategories'

export const checkSubCats = (obj, ffb) => {
    if (obj.subCategories && obj.subCategories.length > 0) {
        obj.subCategories.forEach(item => {
            if (item.isFastFeedback) {
                const newObj = {
                    id: item.id,
                    weightFastFeedback: item.weightFastFeedback,
                    fastFeedback: item.fastFeedback,
                }
                ffb.push(newObj)
            }
            checkSubCats(item, ffb)
        })
    }
}
export const filterFastFeedback = feedbackList => {
    let ffb = []
    feedbackList.forEach(first => {
        if (first.isFastFeedback) {
            const newObj = {
                id: first.id,
                weightFastFeedback: first.weightFastFeedback,
                fastFeedback: first.fastFeedback,
            }
            ffb = [newObj, ...ffb]
        }

        if (first.subCategories && first.subCategories.length > 0) {
            checkSubCats(first, ffb)
        }
    })
    return ffb
}

function SortFeedbackDrawer(props) {
    const { STATE, METHODS } = React.useContext(feedbackCategoriesContext)
    const fastFeedback = filterFastFeedback(STATE.data).sort((a, b) => {
        if (a.weightFastFeedback > b.weightFastFeedback) return 1
        if (b.weightFastFeedback > a.weightFastFeedback) return -1
        return 0
    })

    return (
        <Drawer data-testid="SortFeedbackDrawer_root" open={STATE.isSortOpen} anchor="right" onClose={METHODS.onClose}>
            <div className={props.classes.drawerRoot}>
                <Typography variant="h6">{props.translate('sort_feedback_category')}</Typography>
                <SortFeedbackForm fastFeedback={fastFeedback} translate={props.translate} classes={props.classes} 
                scope={props.scope} />
            </div>
        </Drawer>
    )
}
SortFeedbackDrawer.propTypes = {
    translate: PropTypes.func,
    classes: PropTypes.object,
    scope: PropTypes.string,
}
export default SortFeedbackDrawer
