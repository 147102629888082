import axios from 'axios'

export default async data => {
    const fullUrl = `/profile/portal/pagesettings`
    try {
        const response = await axios.post(fullUrl, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response
    } catch (e) {
        console.error(e) // eslint-disable-line
        return []
    }
}
