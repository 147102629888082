import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus, withCall, enums } from 'react-ion-store'

import ReadStatusButtonView from './ReadStatusButtonView'

const {
    CallStatus: { READY },
} = enums

const callKey = 'documentsSetRead'

const ReadStatusButton = ({ readStateWasSet, idf, __ion_status, ...props }) => {
    const { documentsReadActions } = __ion_status
    const { documentsSetRead } = props.store.get()

    const disabled = !idf || readStateWasSet || documentsReadActions !== READY || typeof documentsSetRead !== 'undefined'

    return <ReadStatusButtonView {...props} disabled={disabled} />
}

ReadStatusButton.propTypes = {
    readStateWasSet: PropTypes.bool.isRequired,
    idf: PropTypes.string,
    __ion_status: PropTypes.object.isRequired,
    store: PropTypes.object,
}

/* eslint-disable react/prop-types */
const withConditionalCall = Component => props => {
    withConditionalCall.propTypes = {
        readStateWasSet: PropTypes.bool.isRequired,
        idf: PropTypes.string,
    }
    if (props.readStateWasSet) {
        return withCall(callKey, {
            config: {
                method: 'post',
                url: `/elibrary/${props.idf}/alldocumentsread`,
            },
            reducer: response => response.data === '',
        })(Component)(props)
    } else return <Component {...props} />
}

export default compose(withLocalize, withStore, withConditionalCall, withStatus)(ReadStatusButton)
