export default theme => ({
    drawer: {
        margin: '16px',
        width: '500px',
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '70vw',
        },
    },
    arrow: {
        cursor: 'pointer',
    },
    arrowButtonDisabled: {
        color: 'gray',
        curson: 'none',
    },
    deleteIcon: {
        position: 'absolute',
        right: 10,
        cursor: 'pointer',
        color: theme.customColors.red,
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
    changeDirection: {
        color: theme.customColors.primaryBlue,
        '&:hover': {
            color: theme.customColors.lightGrey,
            [theme.breakpoints.down('xs')]: {
                color: theme.customColors.primaryBlue,
            },
        },
        cursor: 'pointer',
    },
})
