import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import AddPtcarPlatform from './addPtcarPlatform'
import EditPtcarPlatform from './editPtcarPlatform'
import { PtcarPlatformsContext } from '../../ptcarPlatforms.store'

function PtcarPlatformForm(props) {
    const { STATE } = useContext(PtcarPlatformsContext)

    return STATE.editDrawer ? <EditPtcarPlatform {...props} /> : <AddPtcarPlatform {...props} />
}

PtcarPlatformForm.propTypes = {
    classes: PropTypes.object,
    error: PropTypes.string,
}

export default PtcarPlatformForm
