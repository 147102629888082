import React, { PureComponent } from 'react'
import DistrictsMapper from 'components/DistrictsMapper'
import DocumentFolderMapper from 'components/DocumentFolderMapper'
import ExtendOrganisation from './index'
export default class DistrictWrapper extends PureComponent {
    render() {
        return (
            <DistrictsMapper>
                <DocumentFolderMapper>
                    <ExtendOrganisation />
                </DocumentFolderMapper>
            </DistrictsMapper>
        )
    }
}
