import React from 'react'
import { withStore } from 'react-ion-store'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import Header from 'components/Header'
import styles from './styles'

import withHocs from 'utils/hocs/withHocs'
import { TranslationContext } from 'context/translation'
import ESemesViewer from './components'

const ESemes = () => {
    const { translate } = React.useContext(TranslationContext)

    React.useEffect(() => {
        document.title = translate('module_esemes') || 'eSemes'
    }, [])

    return (
        <div data-testid="feedbackCategories_component">
            <Header pageTitle={'esemesOverview'} isMenu title={translate('module_esemes')} />
            <ESemesViewer />
        </div>
    )
}

ESemes.propTypes = {
    store: PropTypes.object,
}

export default withHocs(withStore, withStyles(styles))(ESemes)
