import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import DeleteDialog from 'components/DeleteDialog'
import deleteUserGroup from '../Services/deleteUserGroup'

import styles from './styles'
import { TranslationContext } from 'context/translation'

const DeleteUserDialog = props => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const { translate } = React.useContext(TranslationContext)

    const deleteRoles = () => {
        setLoading(true)
        deleteUserGroup(id)
            .then(() => {
                setLoading(false)
                props.reloadPage()
            })
            .catch(e => {
                console.error(e) // eslint-disable-line
                setLoading(false)
                setError('Delete user group failed')
            })
    }

    const { open, handleClose, id, name } = props

    return (
        <DeleteDialog
            dialogOpen={open}
            onClose={handleClose}
            title={translate('add_user_delete')}
            translate={translate}
            handleDelete={deleteRoles}
            deleteMsg={translate('delete_user_group_label')}
            error={error}
            errorMsg={'Gebruiker kon niet verwijdert worden'}
            deleteInfo={translate('delete_user_group_dialog_msg') + ` ${name}`}
            loading={loading}
        />
    )
}
DeleteUserDialog.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    id: PropTypes.string,
    name: PropTypes.string,
    reloadPage: PropTypes.func,
}
export default withStyles(styles)(DeleteUserDialog)
