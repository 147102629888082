import React from 'react'
import { object } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import styles from './styles'

const NoRights = ({ classes }) => (
    <div className={classes.errorNotFound} data-testid="linda_roles_view">
        <Typography variant="h3">
            <Translate id="no_access_rights" />
        </Typography>
    </div>
)

NoRights.propTypes = {
    classes: object.isRequired,
}

export default compose(withStyles(styles), withLocalize)(NoRights)
