import React from 'react'
import { object, func, array, string } from 'prop-types'
import Select from 'react-select'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { ThemeContext } from '../../../../../../../../theme/ThemeProvider'

const VehicleSearchView = props => {
    const { themeName } = React.useContext(ThemeContext)
    const customStyles = {
        option: (provided, state) => ({
            fontFamily: 'Roboto',
            background: themeName === 'light' ? 'white' : '#424242',
            borderBottom: '1px solid grey',
            fontWeight: state.isSelected ? '600' : '8OO',
            color: themeName === 'light' ? '#424242' : 'white',
            padding: isWidthDown('sm', props.width) ? 10 : 20,
        }),
        menu: provided => ({
            ...provided,
            left: 0,
            background: themeName === 'light' ? 'white' : '#424242',
            fontFamily: 'Roboto',
        }),
        input: provided => ({
            ...provided,
            fontFamily: 'Roboto',
            background: themeName === 'light' ? 'white' : '#424242',
            color: themeName === 'light' ? '#424242' : 'white',
        }),
        // container: provided => ({
        //     ...provided,
        //     // paddingLeft: '35px',
        //     border: 'none',
        //     background: 'transparent',
        //     '&:focus': { outline: 'none' },
        // }),
        control: provided => ({
            ...provided,
            background: 'transparent',
            // border: state.isFocused ? 0 : 0,
            fontFamily: 'Roboto',
            // This line disable the blue border
            // boxShadow: state.isFocused ? 0 : 0,
            // '&:hover': {
            //     border: state.isFocused ? 0 : 0,
            // },
        }),
        // valueContainer: provided => ({
        //     ...provided,
        //     background: '#424242',
        //     color: '#979797',
        //     fontFamily: 'Roboto',
        // }),
        singleValue: provided => ({
            ...provided,
            color: themeName === 'light' ? '#424242' : 'white',
            fontFamily: 'Roboto',
        }),
    }
    return (
        <div data-testid="vehicleSearch" className={props.classes.search}>
            <Select
                styles={customStyles}
                isSearchable={true}
                blurInputOnSelect={true}
                isClearable={true}
                placeholder={'vehicle search'}
                name="vehicles"
                value={typeof props.selectedVehicle === 'object' ? props.selectedVehicle?.name : props.selectedVehicle}
                options={props.options}
                onChange={e => {
                    props.handleSearchValue(e)
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        e.stopPropagation()
                        e.preventDefault()
                    }
                }}
            />
        </div>
    )
}
VehicleSearchView.propTypes = {
    classes: object,
    handleSearchValue: func,
    history: object,
    options: array,
    selectedVehicle: object,
    width: string,
}
export default withWidth()(VehicleSearchView)
