import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import DeleteDialog from 'components/DeleteDialog'

const DeleteLindaRoleDialog = props => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const deleteLindaRoleCall = async () => {
        setLoading(loading)
        const fullUrl = `/linda/role/${deleteData?.id}/delete`

        try {
            const response = await axios.post(fullUrl)

            if (response.status === 200) {
                setError('')
                setLoading(false)
                onDialogClose()
                reloadRoles()
            }
        } catch (e) {
            console.error(e) // eslint-disable-line
            setError(true)
        }
    }

    const { onDialogClose, dialogOpen, deleteData, translate, reloadRoles } = props

    return (
        <DeleteDialog
            dialogOpen={dialogOpen}
            onClose={onDialogClose}
            title={translate('linda_delete_linda_role_dialog_title')}
            translate={translate}
            handleDelete={deleteLindaRoleCall}
            deleteMsg={translate('linda_delete_linda_role_dialog_confirm_text')}
            error={error}
            errorMsg={translate('linda_delete_linda_role_dialog_error')}
            deleteInfo={deleteData.eDriveRoleCode}
            loading={loading}
        />
    )
}
DeleteLindaRoleDialog.propTypes = {
    onDialogClose: PropTypes.func,
    translate: PropTypes.func,
    reloadRoles: PropTypes.func,
    dialogOpen: PropTypes.bool,
    deleteData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
export default DeleteLindaRoleDialog
