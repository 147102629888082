import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
export default (featureId, idfNumber) => {
    const { url, subscriptionKey } = requestData

    const fullUrl = `${url}/referencedata/features/${featureId}/profiles/${idfNumber}`

    return axios.delete(fullUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    })
}