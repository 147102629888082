import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'

import SelectInput from 'components/SelectInput'
import { Typography } from '@material-ui/core'

export class PerformanceSelect extends Component {
    static propTypes = {
        depot: PropTypes.string,
        loadPerformanceNumbers: PropTypes.func,
        depots: PropTypes.array,
        handleEvent: PropTypes.func,
        performanceNumbersData: PropTypes.object,
        selectedPerformanceNumber: PropTypes.string,
        loadingPerformance: PropTypes.bool,
        classes: PropTypes.object,
        translate: PropTypes.object,
    }

    componentDidMount = () => {
        const { depot, loadPerformanceNumbers, depots } = this.props
        const depotName = depots.depots.find(dep => dep.depotId === parseInt(depot))

        this.setState({ prevDepot: depot })
        if (depot !== ' ' && depotName) loadPerformanceNumbers(depotName.symbolicName)
    }

    componentDidUpdate() {
        const { depot, loadPerformanceNumbers, depots } = this.props
        const { prevDepot } = this.state
        if (depot !== prevDepot) {
            const depotName = depots.depots.find(dep => dep.depotId === parseInt(depot))
            if (depot !== ' ' && depotName) loadPerformanceNumbers(depotName.symbolicName)
            this.setState({ prevDepot: depot })
        }
    }

    render() {
        const {
            handleEvent,
            performanceNumbersData,
            selectedPerformanceNumber,
            loadingPerformance,
            classes,
            translate,
        } = this.props
        const perItems = performanceNumbersData.map(per => ({ id: per.performanceId, text: per.performanceNumber }))

        return (
            <React.Fragment>
                {loadingPerformance && <Typography variant="body1">Loading...</Typography>}
                {performanceNumbersData.length === 0 && !loadingPerformance && (
                    <Typography variant="body1"> {translate('performance_not_found')}</Typography>
                )}

                {performanceNumbersData.length > 0 && (
                    <div style={{ display: 'inline-block' }}>
                        <SelectInput
                            sorted
                            name="performanceNumber"
                            classes={classes}
                            items={[{ id: ' ', text: 'Performance' }, ...perItems]}
                            value={selectedPerformanceNumber}
                            onChange={handleEvent('selectedPerformanceNumber')}
                        />
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default withLocalize(PerformanceSelect)
