import React from 'react'
import { object, func, string, bool, array } from 'prop-types'
import { Translate } from 'react-localize-redux'

import Grid from '@material-ui/core/Grid'
import Header from 'components/Header'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import PersonSearcher from 'components/PersonSearcher'
import ErrorMessage from 'components/ErrorMessage'
import DepotSearch from './depotSearch'

const ExtendedOrganisationsView = ({
    classes,
    selectedName,
    selectedProfile,
    setName,
    clearName,
    districts,
    addOrganisation,
    orgsForUser,
    deleteOrg,
    delError,
    addError,
}) => {
    // let idf;

    // if (selectedProfile) idf = selectedProfile.idfNumber || selectedProfile.id;
    return (
        <React.Fragment>
            <Translate>
                {({ translate }) => <Header isMenu title={translate('manage_users_extended_organisations_edit_header')} />}
            </Translate>
            <div className={classes.container}>
                <div style={{ width: '50%' }}>
                    <Typography variant="h6">
                        <Translate id="districts_read_label_person" />
                    </Typography>

                    <hr style={{ marginBottom: 16 }} />
                    <div style={{ position: 'relative' }} name="PersonSearcherWrapper" tabIndex={0}>
                        <PersonSearcher {...{ selectedName, setName, clearName }} />
                    </div>
                    {selectedName && selectedProfile && (
                        <div className={classes.profileInfo}>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_name" />:
                                </span>
                                {` ${selectedName}`}
                            </Typography>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_idf" />:
                                </span>
                                {` ${selectedProfile.idfNumber}`}
                            </Typography>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_user_name" />:
                                </span>
                                {` ${selectedProfile.userName}`}
                            </Typography>
                        </div>
                    )}

                    <Typography variant="h6" className={classes.topSpacingTitle}>
                        <Translate id="manage_users_extended_organisations_edit_data" />
                    </Typography>

                    <hr style={{ marginBottom: 16 }} />

                    <Grid container spacing={2}>
                        {addError && <ErrorMessage error={addError} />}
                        {delError && <ErrorMessage error={delError} />}

                        {orgsForUser &&
                            orgsForUser.length > 0 &&
                            orgsForUser.map(org => (
                                <Grid item xs={6} key={org.organisationId}>
                                    <Chip
                                        style={{ width: '100%', justifyContent: 'space-between' }}
                                        label={org.organisationCode}
                                        key={org.organisationId}
                                        onDelete={() => {
                                            deleteOrg(org.organisationId)
                                        }}
                                        className={classes.chip}
                                        deleteIcon={<Close />}
                                    />
                                </Grid>
                            ))}
                    </Grid>

                    <DepotSearch
                        districts={districts}
                        addOrganisation={addOrganisation}
                        disabled={!selectedName && !selectedProfile}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

ExtendedOrganisationsView.propTypes = {
    classes: object.isRequired,
    selectedName: string.isRequired,
    selectedProfile: object,
    setName: func.isRequired,
    clearName: func.isRequired,
    districts: array,
    addOrganisation: func,
    orgsForUser: array,
    deleteOrg: func,
    delError: bool,
    addError: bool,
}

export default ExtendedOrganisationsView
