import axios from 'axios'
import queryParamEncoder from 'utils/queryParamEncoder'
import DefaultSettings from 'components/DefaultSettings'
import sortBy from 'lodash/sortBy'

export default async (
    platforms,
    date,
    departureState,
    selectedLindaRoles,
    platformData = [],
    extraFilter = false,
    extra = []
) => {
    let defaultParams = {
        lower: 1,
        upper: 1,
    }

    const storedSettings = localStorage.getItem('userSettings')
        ? JSON.parse(localStorage.getItem('userSettings'))
        : DefaultSettings
    let settings = storedSettings.filter(obj => obj.pageReference === 'readyForDeparture')[0]

    if (storedSettings) {
        defaultParams = {
            lower: parseInt(settings.startHour),
            upper: parseInt(settings.endHour),
        }
    }
    const fullUrl = `performance/firstdepartures`

    const selectedPlatformsData = platformData.filter(({ id }) => platforms.includes(id))

    const platformUic = []
    const lindaRoles = []

    if (!extraFilter) {
        selectedPlatformsData.forEach(item =>
            item.list.forEach(li => {
                platformUic.push(li.ptCarUicCode)
            })
        )
    }

    if (extraFilter) {
        extra.forEach(item => {
            if (item.checked === true) {
                platformUic.push(item.ptCarUicCode)
            }
        })
    }
    selectedLindaRoles.forEach(item => {
        if (item.checked === true) {
            lindaRoles.push(item.lindaRoleCode)
        }
    })
    //convert to spaced array
    let platformArray = platformUic.toString().replaceAll(',', '%20')
    let lindaRoleArray = lindaRoles.toString().replaceAll(',', '%20')

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                date: date,
                ptcars: platformArray,
                departureState: departureState,
                lindaRoleCodes: lindaRoleArray,
                ...defaultParams,
            },
            paramsSerializer: queryParamEncoder,
        })
        if (response.status === 204) {
            return []
        }
        let data = response.data
        return sortBy(data, ['plannedStartTime'])
    } catch (e) {
        console.error(e) // eslint-disable-line
        return []
    }
}
