import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import styles from './styles'
import { FormControl, InputLabel, MenuItem, Select, Typography, TextField, Button } from '@material-ui/core'
import { TranslationContext } from 'context/translation'
import { getMonitoringActions } from 'modules/Monitoring/services/getMonitoringActions'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import { postMonitoringActions } from 'modules/Monitoring/services/postMonitoringActions'

const status = ['OK', 'WARNING', 'ERROR']

const ActionDrawer = props => {
    const [statusValue, setStatusValue] = React.useState(1)
    const { translate } = React.useContext(TranslationContext)
    const [statusData, setStatusData] = React.useState()
    const [fetching, setFetching] = React.useState(true)
    const [error, setError] = React.useState('')

    React.useEffect(() => {
        if (props.isOpen) {
            getMonitoringActions(props.data.Service, statusValue).then(res => {
                setStatusData(res)
                setFetching(false)
            })
        }

        return () => setFetching(true)
    }, [props, statusValue])

    const handleOtherStatus = newStatus => {
        setStatusValue(newStatus)
    }

    const handleSave = () => {
        const newData = { ...statusData, status: statusValue }
        if (Object.keys(newData).length < 3) {
            setError(translate('monitoring_fill_all_fields'))
            return null
        }
        setError('')
        setFetching(true)
        postMonitoringActions(props.data.Service, newData.status, newData.details, newData.action).then(() => {
            setFetching(false)
        })
    }
    return (
        <Drawer anchor={'right'} open={props.isOpen} onClose={props.handleClose}>
            <div className={props.classes.drawer}>
                {fetching ? (
                    <Loading />
                ) : (
                    <>
                        <Typography>{translate('monitoring_edit_tasks')}</Typography>
                        <sub>{props.data.Service}</sub>
                        <FormControl className={props.classes.select} variant="filled">
                            <InputLabel>{translate('monitoring_label_status')}</InputLabel>
                            <Select
                                value={status[statusValue - 1]}
                                onChange={e => {
                                    handleOtherStatus(status.indexOf(e.target.value) + 1)
                                }}
                            >
                                <MenuItem value={status[0]}>{status[0]}</MenuItem>
                                <MenuItem value={status[1]}>{status[1]}</MenuItem>
                                <MenuItem value={status[2]}>{status[2]}</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            multiline={true}
                            rows={6}
                            value={statusData ? statusData.action : ''}
                            className={props.classes.textField}
                            label={translate('monitoring_describe_actions')}
                            onChange={e => setStatusData(prev => ({ ...prev, action: e.target.value }))}
                            variant="outlined"
                        />
                        <TextField
                            multiline={true}
                            rows={6}
                            value={statusData ? statusData.details : ''}
                            className={props.classes.textField}
                            label={translate('monitoring_add_details')}
                            onChange={e => setStatusData(prev => ({ ...prev, details: e.target.value }))}
                            variant="outlined"
                        />
                        <Button onClick={handleSave} className={props.classes.button} variant="contained" color="primary">
                            {translate('add_user_confirm_save')}
                        </Button>
                        {error.length > 0 ? <ErrorMessage error={error} /> : null}
                    </>
                )}
            </div>
        </Drawer>
    )
}

ActionDrawer.propTypes = {
    isOpen: PropTypes.boolean,
    handleClose: PropTypes.func,
    classes: PropTypes.object,
    data: PropTypes.object,
}

export default withStyles(styles)(ActionDrawer)
