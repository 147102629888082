import React, { useEffect, useState } from 'react'
import { func, object, array } from 'prop-types'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Divider, ListItemIcon } from '@material-ui/core'
import DamageList from './DamageList'
import { withStyles } from '@material-ui/core'

import styles from './styles'
import damages from './damages'
import VehicleMenu from '../VehicleMenu'
import VehicleType from '../../Utils/VehicleType'
import VehicleSearchDialog from '../VehicleSearchDialog'
import ACTION_TYPE from '../../Utils/ActionType'

const MaterialUnitListWithDamages = props => {
    const [materialUnit, setMaterialUnit] = useState('')
    const [allowedDamages, setAllowedDamages] = useState()
    const [isOpenDialog, setOpenDialog] = useState(false)
    const [selectedVehicleSubPosition, setSelectedVehicleNumber] = useState()
    const [action, setAction] = useState(0)
    const openDamages = unit => {
        setMaterialUnit(IsUnitExpanded(unit) ? '' : unit)
    }
    //this method checks unit is expanded or not.
    const IsUnitExpanded = unit => {
        return isVehicleLittera(props.data.groupType)
            ? materialUnit?.vehicleNumber === unit?.vehicleNumber
            : materialUnit?.vehicleUnit
            ? materialUnit?.vehicleUnit === unit.vehicleUnit
            : materialUnit?.vehicleNumber === unit?.vehicleNumber
    }
    const openDamageForMU = unit => {
        setMaterialUnit(materialUnit?.vehicleNumber === unit.vehicleNumber ? '' : unit)
    }
    const isVehicleLittera = groupType => groupType === VehicleType.LITTERA

    useEffect(() => {
        let dList = getDamageList()
        if (dList) setAllowedDamages(dList)
    }, [materialUnit])

    // this method will only return possible damages
    const getDamageList = () => {
        if (materialUnit && materialUnit.vehicleNumber) {
            let childs = props.data.materialUnitChilds.length > 0 ? props.data.materialUnitChilds : [props.data]
            let vehicleNumber = materialUnit.vehicleNumber
            let obj = props.getRelevantDamages(vehicleNumber)
            if (!obj) return []
            let damageCodes = []

            if (obj.hasBrakeDistributor && obj.hasBrakePerBogie) {
                damageCodes = [...damageCodes, 1]
            }

            if (obj.hasPneumaticSuspension) {
                damageCodes = [...damageCodes, 2, 3]
            }

            if (obj.ltCode === 3 || obj.ltCode === 6 || obj.ltCode === 7) {
                if (materialUnit.groupType !== VehicleType.LITTERA) {
                    damageCodes = [...damageCodes, 4, 5]
                }
            }

            if (obj.hasBrakeDistributor) {
                damageCodes = [...damageCodes, 6]
            }

            if (obj.hasBrakePerBogie) {
                damageCodes = [...damageCodes, 7, 8]
            }

            if (obj.hasHighPressureBrake) {
                damageCodes = [...damageCodes, 9]
            }

            if (obj.ltCode === 3 || obj.ltCode === 6) {
                if (obj.hasElectroPneumaticBrake && obj.hasDynamicBrake) {
                    damageCodes = [...damageCodes, 10]
                }
            }

            if (obj.hasMagnetBrake) {
                damageCodes = [...damageCodes, 11]
            }

            if (obj.hasDynamicBrake) {
                damageCodes = [...damageCodes, 12]
            }

            if (obj.hasDriverCockpit) {
                if (materialUnit.groupType === VehicleType.LOCOMOTIVE) {
                    damageCodes = [...damageCodes, 13, 14]
                }
                if (materialUnit.groupType === VehicleType.MOTORUNIT) {
                    if (materialUnit.vehicleUnit === 1) {
                        damageCodes = [...damageCodes, 13]
                    }
                    if (materialUnit.vehicleUnit !== childs[childs.length - 1].vehicleUnit) {
                        damageCodes = [...damageCodes, 14]
                    }
                }
            }

            const t = damages
                .filter(damage => {
                    let keys = Object.keys(damage.display)
                    let ltCodePresents = damage.ltCode.length > 0 ? damage.ltCode.includes(obj.ltCode) : true
                    let isKeyPresent = checkKeysInObj(obj, keys)
                    return damageCodes.includes(damage.code) && (isKeyPresent || damage.display === '') && ltCodePresents
                })
                .map(damage => {
                    let description = props.defectTypes.filter(defectType => defectType.code === damage.code)[0].description
                    if (props.activeLanguage.code === 'fr') damage.description = description[0].translation
                    else damage.description = description[1].translation
                    return damage
                })
            return t
        } else return []
    }
    const checkKeysInObj = (obj, keys) => {
        for (let key of keys) {
            return obj[key]
        }
    }

    const handleCheckbox = code => {
        let mucs = [...props.data?.materialUnitChilds]

        if (mucs.length) {
            let mucIndex = mucs.findIndex(muc =>
                isVehicleLittera(props.data.groupType)
                    ? muc?.vehicleNumber === materialUnit?.vehicleNumber
                    : muc.vehicleUnit
                    ? muc.vehicleUnit === materialUnit.vehicleUnit
                    : muc.vehicleNumber === materialUnit.vehicleNumber
            )
            let defectCodes = [...mucs[mucIndex].compositionDefectTypeCodes]
            let index = defectCodes.indexOf(code)
            index > -1 ? defectCodes.splice(index, 1) : defectCodes.push(code)
            mucs[mucIndex].compositionDefectTypeCodes = defectCodes
            props.handleDamageChange(mucs)
        } else {
            let defectCodes = [...props.data?.compositionDefectTypeCodes]
            let index = defectCodes?.indexOf(code)
            index > -1 ? defectCodes.splice(index, 1) : defectCodes.push(code)
            props.handleDamageChange(defectCodes)
        }
    }
    const handleEditClicked = subPosition => {
        setSelectedVehicleNumber(subPosition)
        setAction(ACTION_TYPE.CHANGE_LITTERA)
        setOpenDialog(true)
    }
    const handleAddLitteraBefore = subPosition => {
        setSelectedVehicleNumber(subPosition)
        setAction(ACTION_TYPE.ADD_LITTERA_BEFORE)
        setOpenDialog(true)
    }
    const handleAddLitteraAfter = subPosition => {
        setSelectedVehicleNumber(subPosition)
        setAction(ACTION_TYPE.ADD_LITTERA_AFTER)
        setOpenDialog(true)
    }
    const handleDialogClose = () => {
        setOpenDialog(false)
    }
    const sumbitClicked = () => {
        setOpenDialog(false)
        props.handleEditLitteraClicked(action, selectedVehicleSubPosition)
    }

    const getItemText = unit => {
        if (!unit.litteraName) return unit?.vehicleUnit ? '-' + unit.vehicleUnit : ''
        return ''
    }
    return (
        <>
            <List className={props.classes.listView}>
                {props.data?.materialUnitChilds?.length ? (
                    props.data.materialUnitChilds.map(unit => (
                        <React.Fragment
                            key={isVehicleLittera(props.data.groupType) ? unit.vehicleNumber : unit?.vehicleUnit}
                        >
                            <Paper square elevation={0}>
                                <ListItem
                                    style={{ paddingLeft: '0px', paddingRight: '0px' }}
                                    disableGutters={false}
                                    button
                                    onClick={() => openDamages(unit)}
                                >
                                    <ListItemText primary={`${unit.vehicleNumber}${getItemText(unit)}`} />
                                    {isVehicleLittera(props.data.groupType) && (
                                        <ListItemIcon>
                                            <VehicleMenu
                                                removeVehicle={() => props.handleRemoveVehicle(unit.subPosition)}
                                                moveVehicleUpward={() => props.moveVehicleUpward(unit.subPosition)}
                                                moveVehicleDownward={() => props.moveVehicleDownward(unit.subPosition)}
                                                handleEditClicked={() => handleEditClicked(unit.subPosition)}
                                                handleAddLitteraBefore={() => handleAddLitteraBefore(unit.subPosition)}
                                                handleAddLitteraAfter={() => handleAddLitteraAfter(unit.subPosition)}
                                            />
                                        </ListItemIcon>
                                    )}

                                    <ListItemSecondaryAction>
                                        {IsUnitExpanded(unit) ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Paper>
                            {IsUnitExpanded(unit) && allowedDamages && (
                                <DamageList
                                    allowedDamages={allowedDamages}
                                    registeredDamages={unit?.compositionDefectTypeCodes || []}
                                    handleCheckbox={handleCheckbox}
                                />
                            )}
                            <Divider />
                        </React.Fragment>
                    ))
                ) : (
                    <React.Fragment>
                        <Paper square elevation={0}>
                            <ListItem button onClick={() => openDamageForMU(props.data)}>
                                <ListItemText primary={`${props.data?.vehicleNumber} `} />

                                <ListItemSecondaryAction>
                                    {materialUnit?.vehicleNumber === props.data?.vehicleNumber ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Paper>
                        {materialUnit?.vehicleNumber === props.data?.vehicleNumber && allowedDamages && (
                            <DamageList
                                allowedDamages={allowedDamages}
                                registeredDamages={props.data?.compositionDefectTypeCodes}
                                handleCheckbox={handleCheckbox}
                            />
                        )}
                        <Divider />
                    </React.Fragment>
                )}
            </List>
            {isOpenDialog && (
                <VehicleSearchDialog
                    onClose={handleDialogClose}
                    open={isOpenDialog}
                    litteraSearchOptions={props.litteraSearchOptions}
                    selectedLitteraVehicle={props.selectedLitteraVehicle}
                    handleAddLittera={props.handleAddLittera}
                    handleEditLitteraClicked={sumbitClicked}
                />
            )}
        </>
    )
}
MaterialUnitListWithDamages.propTypes = {
    data: object,
    handleDamageChange: func,
    classes: object,
    getRelevantDamages: func,
    handleRemoveVehicle: func,
    moveVehicleUpward: func,
    moveVehicleDownward: func,
    litteraSearchOptions: array,
    selectedLitteraVehicle: object,
    handleAddLittera: func,
    handleEditLitteraClicked: func,
    defectTypes: array,
    activeLanguage: object,
}
export default withStyles(styles)(MaterialUnitListWithDamages)
