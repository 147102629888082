export default theme => ({
    mainDiv: {
        padding: theme.spacing(2),
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            padding: theme.spacing(1),
            margin: 'auto',
        },
        margin: 'auto',
    },
    trainNumber: {
        display: 'flex',
        alignItems: 'center',
    },
    loadButton: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },
})
