import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@material-ui/core'
import { ThemeContext } from 'theme/ThemeProvider'

const PaperBox = ({ value, redBox, deviceId, speed }) => {
    const { themeName } = useContext(ThemeContext)

    const baseStyle = {
        marginTop: 16,
        padding: 16,
        display: 'flex',
        borderLeft: redBox ? '5px solid red' : 'none',
    }

    const lightStyle = {
        ...baseStyle,
        backgroundColor: 'white',
        color: 'black',
    }

    const darkStyle = {
        ...baseStyle,
        backgroundColor: '#424242',
        color: 'white',
    }
    return (
        <Paper data-testid="paperbox" square style={themeName === 'light' ? lightStyle : darkStyle}>
            <div>
                <Typography variant={'body1'}>{value}</Typography>
                {deviceId && (
                    <Typography data-testid="deviceid" variant={'body1'}>
                        {deviceId}
                    </Typography>
                )}
                {speed && (
                    <Typography data-testid="speed" variant={'body1'}>
                        {speed}
                    </Typography>
                )}
            </div>
        </Paper>
    )
}

PaperBox.propTypes = {
    value: PropTypes.any,
    redBox: PropTypes.bool,
    deviceId: PropTypes.any,
    speed: PropTypes.any,
}

export { PaperBox }
