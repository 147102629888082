import React, { Component } from 'react'
import { func, object, bool } from 'prop-types'
import { Map, TileLayer, Marker, Popup, Tooltip, Circle } from 'react-leaflet'
import IconButton from 'components/IconButton'
import PtcarDrawerView from '../PtcarDrawerView'
import LineDialog from '../LineDialog'
import axios from 'axios'
import { getPtcarsByLineNumber } from '../../getLinesInformation'
import Header from 'components/Header'
import { withLocalize } from 'react-localize-redux'

import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

delete L.Icon.Default.prototype._getIconUrl
import Typography from '@material-ui/core/Typography'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'

L.Icon.Default.mergeOptions({
    iconRetinaUrl: iconRetinaUrl,
    iconUrl: iconUrl,
    shadowUrl: shadowUrl,
})

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export class MapView extends Component {
    static propTypes = {
        location: object,
        translate: func,
        useInfrabelUrl: bool,
    }
    state = {
        zoom: 11,
        isOpen: false,
        data: [],
        lineDialogOpen: false,
        lineSearchValue: '',
        selectedLineSearchItem: { code: '', label: '' },
        lineNumberData: [],
        selectedData: '',
        lineError: '',
    }

    handleEditClick = item => {
        this.setState({ isOpen: true, selectedData: item })
    }

    handleLineClose = () => {
        this.setState({ lineDialogOpen: false, lineError: '', lineSearchValue: '', selectedLineSearchItem: '' })
    }
    openLineNumberDialog = () => {
        this.setState({
            lineDialogOpen: true,
        })
    }
    getMapUrl = useInfrabel => {
        if (useInfrabel) return process.env.REACT_APP_INFRABEL_MAP_URL
        return process.env.REACT_APP_BRAILS_MAP_URL
    }
    renderAllPtcars = data => {
        return data.map(item => {
            const position = [item.coordinate.latitude, item.coordinate.longitude]

            const innerBBoxRadius = item.innerBox
            const outerBBoxRadius = item.outerBox

            return (
                <React.Fragment key={item.uic}>
                    <Marker position={position}>
                        <Tooltip>{item.name[1].translation}</Tooltip>
                        <Popup closeButton={false}>
                            <h2>{item.name[1].translation}</h2>
                            <p>Id: {item.id}</p>
                            <p>Code: {item.code}</p>
                            <p>UIC code: {item.uic}</p>
                            <p>Latitude: {item.coordinate.latitude}</p>
                            <p>Longitude: {item.coordinate.longitude}</p>
                            <p>Altitude: {item.altitude}</p>
                            <p>InnerBox: {item.innerBox}</p>
                            <p>OuterBox: {item.outerBox}</p>
                            <IconButton
                                // className={classes.button}
                                icon="edit"
                                title={'edit'}
                                onClick={() => {
                                    this.handleEditClick(item)
                                }}
                                // disabled={disabled}
                            />
                        </Popup>
                    </Marker>
                    {innerBBoxRadius > 0 && <Circle center={position} color="red" radius={innerBBoxRadius} />}
                    {outerBBoxRadius > 0 && <Circle center={position} color="green" radius={outerBBoxRadius} />}
                </React.Fragment>
            )
        })
    }
    onClose = () => {
        this.setState({
            isOpen: false,
            rowData: {},
            selectedLineSearchItem: { code: '', label: '' },
            lineSearchValue: '',
        })
    }
    handleLineSearchValue = (e, triggerValidation = null) => {
        if (e === null) {
            this.setState({
                lineSearchValue: '',
                selectedLineSearchItem: { code: '', label: '' },
            })
        } else {
            if (triggerValidation !== null) {
                this.setState({ lineSearchValue: e.code, selectedLineSearchItem: e }, () => {
                    triggerValidation()
                })
                return
            }
            this.setState({ lineSearchValue: e.code, selectedLineSearchItem: e })
        }
    }

    clearLineSearchValue = () => {
        this.setState({
            selectedLineSearchItem: { code: '', label: '' },
            lineSearchValue: '',
        })
    }
    loadPtcars = async () => {
        let data = await getPtcarsByLineNumber(this.props.location.state.lineNumber)
        this.setState({ data: data })
    }
    addLineNumber = async (lineNumber, milestone, distance) => {
        const { url, subscriptionKey } = requestData
        const fullUrl = `${url}/legacyReferenceData/ptcar/${this.state.selectedData.id}/line/${lineNumber}/add`

        try {
            const response = await axios.post(fullUrl, {
                MileId: parseInt(milestone),
                Distance: parseFloat(distance),
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.handleLineClose()
            }
            if (response.status === 200) {
                this.handleLineClose()
                this.handleDeleteClose()

                this.setState(
                    {
                        lineDialogOpen: false,
                        lineError: '',
                    },
                    () => {
                        this.loadPtcars()
                    }
                )
            }
        } catch (e) {
            if (e.response.status === 400) {
                this.setState({
                    lineError: e.response.data.Message,
                })
            } else {
                this.setState({
                    lineError: 'Er ging iets mis',
                })
            }
        }
    }

    render() {
        const types = this.props.location.state.types
        const classifications = this.props.location.state.classifications

        const data = this.state.data && this.state.data.length ? this.state.data : this.props.location.state.data
        const lineName = this.props.location.state.lineName
        const lineNumber = this.props.location.state.lineNumber
        const lineNumberData =
            this.state.lineNumberData && this.state.lineNumberData.length
                ? this.state.lineNumberData
                : this.props.location.state.lineNumberData

        const position = [data[0].coordinate.latitude, data[0].coordinate.longitude]
        return (
            <div>
                <Header title={this.props.translate('ptcar_overview')} />
                <Typography
                    variant="h6"
                    style={{ position: 'fixed', top: '64px', right: '10px', zIndex: '10', color: 'black' }}
                >
                    {` Line Number : ${lineName}`}
                </Typography>
                <Map
                    center={position}
                    zoom={this.state.zoom}
                    style={{ position: 'fixed', top: '64px', width: '100%', height: '100vh' }}
                >
                    <TileLayer url={this.getMapUrl(this.props.location.state.useInfrabelUrl)} />
                    {this.renderAllPtcars(data)}
                </Map>

                {this.state.isOpen && (
                    <PtcarDrawerView
                        rowData={this.state.selectedData}
                        isOpen={this.state.isOpen}
                        onClose={this.onClose}
                        lineNumber={lineNumber}
                        mapView
                        edit={1}
                        openLineNumberDialog={this.openLineNumberDialog}
                        loadPtcars={this.loadPtcars}
                        handleLineSearchValue={this.handleLineSearchValue}
                        lineNumberData={lineNumberData}
                        types={types}
                        classifications={classifications}
                    />
                )}
                {this.state.lineDialogOpen && (
                    <LineDialog
                        rowData={this.state.selectedData}
                        open={this.state.lineDialogOpen}
                        handleClose={this.handleLineClose}
                        handleAdd={this.addLineNumber}
                        error={this.state.lineError}
                        handleLineSearchValue={this.handleLineSearchValue}
                        clearLineSearchValue={this.clearLineSearchValue}
                        lineNumberData={lineNumberData}
                        lineSearchValue={this.state.lineSearchValue}
                        selectedLineSearchItem={this.state.selectedLineSearchItem}
                    />
                )}
            </div>
        )
    }
}

export default withLocalize(MapView)
