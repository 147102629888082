import React from 'react'
import { func } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus } from 'react-ion-store'
import axios from 'axios'

import { withStyles } from '@material-ui/core/styles'

import SuperUserView from './SuperUserView'
import checkWriteAccess from 'utils/checkWriteAccess'
import NoRights from '../../../NoRights'

import styles from './styles'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

const resetState = {
    selectedName: '',
    selectedProfile: undefined,
    isSuperUser: false,
    touched: false,
}

class SuperUser extends React.Component {
    static propTypes = {
        onClose: func.isRequired,
    }

    state = { isSuperUser: false, ...resetState }

    componentDidUpdate = () => {
        const { selectedProfile } = this.state

        if (selectedProfile) {
            if (this.state.touched === false && this.state.isSuperUser === false && selectedProfile.isSuperUser)
                this.setState({ isSuperUser: selectedProfile.isSuperUser })
        }
    }

    setName = selectedProfile =>
        this.setState({
            selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
            selectedProfile,
        })

    clearName = () => this.setState(resetState)

    onClose = () => {
        this.clearName()
        this.props.onClose()
    }

    handleCheckbox = async key => {
        const { isSuperUser } = this.state
        const { selectedProfile } = this.state
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/profile/setsuperuser`

        try {
            await axios.post(fullUrl, {
                IdfNumber: selectedProfile.idfNumber,
                IsSuperUser: !isSuperUser,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            this.setState({ [key]: !isSuperUser, touched: true })
        } catch (e) {
            console.error(e) // eslint-disable-line
            this.setState({ touched: true })
        }
    }

    render() {
        const { isSuperUser } = this.state
        if (checkWriteAccess(this.props, 'superuser', 'documents.functionality.management.documents')) {
            return (
                <SuperUserView
                    {...this.props}
                    {...this.state}
                    handleCheckbox={this.handleCheckbox}
                    isSuperUser={isSuperUser}
                    setName={this.setName}
                    clearName={this.clearName}
                    onClose={this.onClose}
                />
            )
        } else {
            return <NoRights />
        }
    }
}

export default compose(withStore, withStatus, withStyles(styles), withLocalize)(SuperUser)
