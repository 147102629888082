import React, { Component } from 'react'
import DefectTypesView from './DefectTypesView'
import axios from 'axios'
import { Typography } from '@material-ui/core'
import AddDefectTypeDrawer from './components/AddDefectTypeDrawer'
import addEditDefectType from './components/services/addEditDefectType'
import DeleteDialog from './components/DeleteDefectTypeDialog'
import Loading from 'components/Loading'
import { withStyles } from '@material-ui/styles'

import styles from './styles'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import Header from 'components/Header'
import { TranslationContext } from 'context/translation'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export class DefectTypes extends Component {
    _isMounted = false

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            dataLoading: false,
            dataError: '',
            openDrawer: false,
            addError: '',
            openDeleteDialog: false,
            deleteRowData: '',
            editData: '',
            editDrawer: false,
            addEditLoading: false,
        }
    }

    componentDidMount = () => {
        this._isMounted = true
        document.title = this.context.translate('compositions_reference_data_title') || 'Compositions'
        this.loadDefectTypes()
    }
    componentWillUnmount() {
        this._isMounted = false
    }

    getSortedDefects = data => {
        return data.sort((a, b) => a.code - b.code)
    }
    loadDefectTypes = async () => {
        this.setState({ dataLoading: true })
        const { url } = requestData

        const fullUrl = `${url}/legacyReferenceData/compositionDefectTypes`

        try {
            const response = await axios.get(fullUrl, {})
            if (this._isMounted) {
                if (response.status === 204) {
                    this.setState({
                        data: [],
                        dataLoading: false,
                        dataError: '',
                    })
                }
                if (response.status === 200) {
                    this.setState({
                        data: this.getSortedDefects(response.data),
                        dataLoading: false,
                        dataError: '',
                    })
                }
            }
        } catch (e) {
            this.setState({
                data: [],
                dataLoading: false,
                dataError: 'error',
            })
            console.error(e) // eslint-disable-line
        }
    }

    addDefectClicked = () => {
        this.setState({
            openDrawer: true,
        })
    }
    closeDrawer = () => {
        this.setState({
            openDrawer: false,
            editDrawer: false,
            editDate: '',
        })
    }
    callAddEditDefectTypeApi = async (data, isEdit) => {
        this.setState({ addEditLoading: true })
        if (isEdit) data.id = this.state.editData.id
        let response = await addEditDefectType(data, isEdit)

        if (response.status === 200) {
            this.setState(
                {
                    addError: '',
                    openDrawer: false,
                    editDrawer: false,
                    editDate: '',
                    addEditLoading: false,
                },
                () => this.loadDefectTypes()
            )
        } else {
            this.setState({
                addError: response.data.Message,
                editDrawer: false,
                editDate: '',
                addEditLoading: false,
            })
        }
    }
    deleteDefectClicked = async id => {
        this.setState({
            openDeleteDialog: true,
            deleteRowData: id,
        })
    }

    onDialogClose = () => {
        this.setState({
            openDeleteDialog: false,
        })
    }
    editDefectClicked = async data => {
        this.setState({ openDrawer: true, editDrawer: true, editData: data })
    }
    render() {
        const { data, dataError } = this.state
        return (
            <React.Fragment>
                <Header isMenu title={this.context.translate('compositions_reference_data_title')} />

                {this.state.dataLoading && <Loading />}
                {!this.state.dataLoading && (
                    <DefectTypesView
                        {...this.props}
                        data={data}
                        addDefectTypeHandler={this.addDefectClicked}
                        editDefectTypeHandler={this.editDefectClicked}
                        deleteDefectTypeHandler={this.deleteDefectClicked}
                    />
                )}
                {!this.state.dataLoading && dataError.length > 0 && <Typography>Something went wrong</Typography>}
                {this.state.openDrawer && (
                    <AddDefectTypeDrawer
                        isOpen={this.state.openDrawer}
                        onClose={this.closeDrawer}
                        handleAdd={this.callAddEditDefectTypeApi}
                        addError={this.state.addError}
                        editData={this.state.editData}
                        editDrawer={this.state.editDrawer}
                        addEditLoading={this.state.addEditLoading}
                        {...this.props}
                    />
                )}
                {this.state.openDeleteDialog && (
                    <DeleteDialog
                        dialogOpen={this.state.openDeleteDialog}
                        onDialogClose={this.onDialogClose}
                        loadDefectTypes={this.loadDefectTypes}
                        deleteData={this.state.deleteRowData}
                        {...this.props}
                    />
                )}
            </React.Fragment>
        )
    }
}

DefectTypes.contextType = TranslationContext

export default compose(withStore, withStyles(styles))(DefectTypes)
