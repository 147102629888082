export const listColumns = [
    {
        width: 80,
        label: 'datatable_esemes_type',
        dataKey: 'type',
    },
    {
        width: 80,

        label: 'datatable_esemes_line',
        dataKey: 'line',
    },
    {
        width: 500,
        label: 'datatable_esemes_number',
        dataKey: 'number',
    },
    {
        width: 50,
        flexGrow: 1,
        label: 'NO_TRANSLATION',
        dataKey: 'speed',
    },
    {
        width: 50,
        flexGrow: 1,
        label: 'semes_received',
        dataKey: 'received',
    },
    {
        width: 50,
        flexGrow: 1,
        label: 'semes_first_opened',
        dataKey: 'first_opened',
    },
    {
        width: 50,
        flexGrow: 1,
        label: 'semes_last_opened',
        dataKey: 'last_opened',
    },
]

export const districtFilter = filter =>
    filter === 'Noord-West' ||
    filter === 'Noord-Oost' ||
    filter === 'Centraal-Central' ||
    filter === 'Sud-Ouest' ||
    filter === 'Sud-Est' ||
    filter === 'Centrale-directie'

export const getDistrictId = (name, districts) => {
    return districts.filter(el => el.name[0].translation === name || el.name[1].translation === name)
}

export const ArrayToSpaceString = strs => String(strs).replaceAll(',', ' ')

export const searchFilter = (data, filter) => {
    return (
        data.additionSemesInfrabelNr.toUpperCase().includes(filter.toUpperCase()) ||
        data.lineSection.ptCarFromCommercialMediumName[0]?.translation.toUpperCase().includes(filter.toUpperCase()) ||
        data.lineSection.ptCarToCommercialMediumName[0]?.translation.toUpperCase().includes(filter.toUpperCase())
    )
}

export const fallbackObject = [
    {
        pubuId: '2',
        name: [
            {
                language: 'fr-BE',
                translation: 'Centre',
            },
            {
                language: 'nl-BE',
                translation: 'Centre',
            },
        ],
    },
]

export const colorFilter = type => {
    switch (type) {
        case 'ARQ':
        case 'BIP':
        case 'AD':
        case 'BO':
        case 'ACPA':
        case 'BVNS':
            return 'rgb(0,176,240)'
        case 'ART':
        case 'BTS':
            return 'rgb(112,48,160)'
        default:
            return 'rgb(112,48,160)'
    }
}

const userLineNameSort = (data, dir = 'asc') => {
    //Initially sort purely on initial numbers
    let sorted = data.sort((a, b) => parseInt(a.lineSection.userLineName) - parseInt(b.lineSection.userLineName))
    let subSorted = []
    //Group all same numbers in subArray
    sorted.forEach((el, ind) => {
        //first one can always be inserted as a new array
        if (ind === 0) {
            subSorted[0] = [el]
            return null
        }

        //If userlinename is not the same as currentsubgroup then find a subgroup that includes it else create new group
        let groupfound = false
        subSorted.forEach((subEl, subSortInd) => {
            if (subEl[0].lineSection.userLineName === el.lineSection.userLineName) {
                subSorted[subSortInd].push(el)
                groupfound = true
            }
        })
        if (!groupfound) {
            subSorted.push([el])
        }
        return null
    })

    //Modify order in subSort to match alphabet
    subSorted.sort((A, B) => {
        if (parseInt(A[0].lineSection.userLineName) !== parseInt(B[0].lineSection.userLineName)) return 0
        if (A[0].lineSection.userLineName.length > B[0].lineSection.userLineName.length) return 1
        if (A[0].lineSection.userLineName.length < B[0].lineSection.userLineName.length) return -1

        //CHECK FOR ALPHABET IN FIRST NUMBER
        const indexOfFirstLetterA = A[0].lineSection.userLineName.search(/[a-zA-z]/)
        const indexOfFirstLetterB = B[0].lineSection.userLineName.search(/[a-zA-Z]/)
        return A[0].lineSection.userLineName[indexOfFirstLetterA].localeCompare(
            B[0].lineSection.userLineName[indexOfFirstLetterB]
        )
    })

    //Move () to front

    //Move / in front of Alphabet
    subSorted.sort((A, B) => {
        if (parseInt(A[0].lineSection.userLineName) !== parseInt(B[0].lineSection.userLineName)) return 0
        if (B[0].lineSection.userLineName.search(/[a-zA-Z]/) >= 0 && A[0].lineSection.userLineName.includes('/')) return -1
        else return 0
    })

    subSorted.sort((A, B) => {
        if (parseInt(A[0].lineSection.userLineName) !== parseInt(B[0].lineSection.userLineName)) return 0
        if (B[0].lineSection.userLineName.search(/[a-zA-Z]/) >= 0 && A[0].lineSection.userLineName.includes('(')) return -1
        else return 0
    })

    //SWap / en ()
    subSorted.sort((A, B) => {
        if (B[0].lineSection.userLineName.includes('/') && A[0].lineSection.userLineName.includes('(')) return -1
        else return 0
    })
    sorted = subSorted.flat()

    if (dir === 'desc') return sorted.reverse()
    return sorted
}

export const sortBy = (data, direction = 'asc', key = 'line', lang = 'nl') => {
    if (data === undefined) {
        return []
    }

    if (key === 'line') {
        return userLineNameSort(data, direction)
    }

    if (key === 'type') {
        let sorted
        if (lang === 'nl') {
            sorted = data.sort((a, b) => a.setyDescription[1].translation.localeCompare(b.setyDescription[1].translation))
        } else {
            sorted = data.sort((a, b) => a.setyDescription[0].translation.localeCompare(b.setyDescription[0].translation))
        }
        if (direction === 'asc') return sorted
        return sorted.reverse()
    } else {
        return data
    }
}

export const fullZoneName = zone => {
    switch (zone) {
        case 'Gent':
            return 'IINW Gent'
        case 'Centre':
            return 'IICE Centre'
        case 'Antwerpen':
            return 'IINO Antwerpen'
        case 'Charleroi':
            return 'IISO Charleroi'
        case 'Liège':
            return 'IISE Liège'
        default:
            return zone
    }
}
