import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, Column, defaultTableRowRenderer, Table } from 'react-virtualized'
import { Translate } from 'react-localize-redux'
import classNames from 'classnames'
import { Typography } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import { Edit, Delete } from '@material-ui/icons'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

function ManagePtcarTypesTable({ ptcarTypes, classes, onDrawerOpen, onDialogOpen, store }) {
    ManagePtcarTypesTable.propTypes = {
        ptcarTypes: PropTypes.array,
        classes: PropTypes.object,
        onDrawerOpen: PropTypes.func,
        onDialogOpen: PropTypes.func,
        store: PropTypes.object,
    }
    const columns = [
        {
            width: 200,
            label: 'manage_ptcar_types_datatable_header_edriveCode',
            dataKey: 'edriveId',
        },
        {
            width: 500,
            label: 'manage_ptcar_types_datatable_header_nl_description_short',
            dataKey: 'descriptionNl',
        },
        {
            width: 500,
            label: 'manage_ptcar_types_datatable_header_fr_description_short',
            dataKey: 'descriptionFr',
        },
        {
            width: 200,
            label: 'datatable_header_actions',
            dataKey: 'actions',
        },
    ]

    const headerRenderer = headerProps => {
        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, classes.header)}
                variant="head"
                style={{ height: 50 }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }

    const cellRenderer = cellProps => {
        const { dataKey, rowData } = cellProps

        if (dataKey === 'actions') {
            if (checkFunctionalityDisabled(store, 'edit', 'manage.functionality.management.ptcarTypesAndClassification')) {
                return <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }} />
            }
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                    <div className={classes.multipleIcons}>
                        <Edit
                            className={classes.editIcon}
                            onClick={() => {
                                onDrawerOpen(rowData, true)
                            }}
                        />
                        <Delete
                            className={classes.deleteIcon}
                            onClick={e => {
                                onDialogOpen(e, rowData)
                            }}
                        />
                    </div>
                </TableCell>
            )
        }
        if (dataKey === 'descriptionNl') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                    <Typography>{rowData.desc_nl}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'descriptionFr') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                    <Typography>{rowData.desc_fr}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'edriveId') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                    <Typography>{rowData.Id}</Typography>
                </TableCell>
            )
        }
    }

    if (ptcarTypes && ptcarTypes.length > 0) {
        return (
            <div className={classes.documentTableWrapper}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            className={classes.table}
                            height={height}
                            width={width}
                            rowCount={ptcarTypes.length}
                            rowClassName={classes.tableRow}
                            rowGetter={({ index }) => ptcarTypes[index]}
                            rowRenderer={defaultTableRowRenderer}
                            headerRenderer={headerRenderer}
                            onRowClick={() => null}
                            rowHeight={50}
                        >
                            {columns.map(({ dataKey, ...other }) => (
                                <Column
                                    key={dataKey}
                                    headerRenderer={headerRenderer}
                                    className={classes.flexContainer}
                                    cellRenderer={cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            ))}
                        </Table>
                    )}
                </AutoSizer>
            </div>
        )
    } else {
        return <div>No Data</div>
    }
}

export default ManagePtcarTypesTable
