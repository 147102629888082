import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

import { FormControlLabel, Checkbox } from '@material-ui/core'

import SelectInput from 'components/SelectInput'
import AppPaper from 'components/AppPaper'

import { TranslationContext } from 'context/translation'

import { ESemesContext } from '../esemes.store'

import { fullZoneName } from '../esemes.util'
import { MenuContext } from 'context/menuStore'

const EsemesZoneFilter = ({ classes }) => {
    const { translate, activeLanguage } = React.useContext(TranslationContext)

    const { STATE, METHODS } = React.useContext(ESemesContext)

    const { allowedDistricts } = React.useContext(MenuContext)

    const [visibleDistricts, setVisibleDistricts] = React.useState([])

    const getAllowedDistricts = () => {
        if (allowedDistricts.data?.length > 0) {
            const getTranslated = allowedDistricts.data.map(el => {
                if (activeLanguage.code === 'nl') {
                    return el.name[1].translation
                } else {
                    return el.name[0].translation
                }
            })
            activeLanguage.code === 'nl' ? getTranslated.push('Alle districten') : getTranslated.push('Tous les districts')
            setVisibleDistricts(getTranslated)
        }
    }

    React.useEffect(() => {
        getAllowedDistricts()
    }, [allowedDistricts])

    return (
        <AppPaper className={classes.root}>
            <div className={classes.flex}></div>
            <div className={classes.flex}>
                {!STATE.filterState.hasTaskApplicable && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={() => METHODS.toggleGroupPerZone()}
                                checked={STATE.toggleState.groupPerZone}
                                disabled={false}
                                color="primary"
                            />
                        }
                        label={translate('group_esemes_by_zone')}
                    />
                )}
                {!STATE.filterState.hasTaskApplicable && (
                    <SelectInput
                        filled
                        label="ZONE"
                        items={STATE.apiState.pubuIds.map(el => ({
                            id: fullZoneName(el.name[0].translation),
                            value: fullZoneName(el.name[0].translation),
                            code: fullZoneName(el.name[0].translation),
                            text: fullZoneName(el.name[0].translation),
                        }))}
                        name="depots"
                        onChange={e => {
                            METHODS.changeActiveZonesNew(e.target.value)
                        }}
                        value={STATE.filterState.activeIds.map(el => fullZoneName(el.name[0].translation))}
                        multiple
                    />
                )}
                {STATE.filterState.hasTaskApplicable && (
                    <SelectInput
                        filled
                        label="DISTRICT"
                        items={visibleDistricts.map(el => ({
                            id: el,
                            value: el,
                            code: el,
                            text: el,
                        }))}
                        name="districts"
                        onChange={e => {
                            METHODS.handleDistrictFilter(e.target.value, visibleDistricts.length)
                        }}
                        value={STATE.filterState.district}
                        multiple
                    />
                )}
            </div>
        </AppPaper>
    )
}

EsemesZoneFilter.propTypes = {
    classes: PropTypes.object,
}

export default withStyles(styles)(EsemesZoneFilter)
