import { blue, grey } from '@material-ui/core/colors'

export default theme => ({
    tableCell: {
        width: '20%',
        padding: '4px',
        border: '1px solid #ddd',
    },
    childDiv: {
        width: '20%',
        justifyContent: 'space-evenly',
    },
    related: {
        width: '18%',
        maxHeight: '70vh',
        overflowX: 'hidden',
        overflowY: 'scroll',
        marginRight: '2%',
        border: `1px solid ${theme.customColors.white}`,
        paddingLeft: '10px',
    },
    customInput: {
        backgroundColor: grey['600'],
        height: '35px',
        minWidth: '200px',
        border: `2px solid ${grey['700']}`,
        fontSize: '14px',
        color: theme.customColors.white,
        fontFamily: 'roboto',
        paddingLeft: '8px',
        '&::placeholder': {
            color: `${grey['400']}`,
        },
        '&:focus': {
            outline: 'none',
            borderBottom: `2px solid ${blue['500']}`,
        },
    },
})
