import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import RouteView from '../../ReportPages/RouteView'

const isRouteData = rowData => Object.prototype.hasOwnProperty.call(rowData, 'feedback')

const getFeedback = feedback => {
    let { feedbackCategory3Value, feedbackCategory2Value, feedbackCategory1Value } = feedback
    if (feedbackCategory3Value) return feedbackCategory3Value
    if (feedbackCategory2Value) return feedbackCategory2Value
    return feedbackCategory1Value
}
const FeedbackListView = props => {
    return props.feedbacks.map((rowData, index) => (
        <Grid data-testid="listview" key={index} container spacing={0}>
            <Grid className={props.classes.column} item xs={1}>
                <Typography className={props.classes.text}>{index + 1}</Typography>
            </Grid>
            <Grid item xs={11}>
                {isRouteData(rowData) && (
                    <RouteView
                        rowData={rowData}
                        classes={props.classes}
                        activeLanguage={props.activeLanguage}
                        handleImageClick={props.handleImageClick}
                    />
                )}
                {!isRouteData(rowData) && (
                    <Grid className={props.classes.column} item xs={12}>
                        <Typography className={props.classes.text}>{rowData.feedbackFreeText}</Typography>
                        <hr />
                        <Typography className={props.classes.text}>{getFeedback(rowData)}</Typography>
                        {rowData.feedbackImagesWithBlobUrl && rowData.feedbackImagesWithBlobUrl.length > 0 && (
                            <React.Fragment>
                                <hr />
                                <div style={{ display: 'flex' }}>
                                    {rowData.feedbackImagesWithBlobUrl.map((link, linkIndex) => (
                                        <Typography
                                            key={linkIndex}
                                            className={props.classes.link}
                                            onClick={() => props.handleImageClick(link)}
                                        >
                                            {` Open Image${linkIndex + 1}, `}
                                        </Typography>
                                    ))}
                                </div>
                            </React.Fragment>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    ))
}
export default FeedbackListView
