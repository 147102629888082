/* eslint-disable max-len */
import axios from 'axios'
import { DateTime } from 'luxon'
const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default async (prefix, trainNumber, date, idfNumber, taskId) => {
    const { url, subscriptionKey } = requestData
    const startDate = DateTime.fromSeconds(date).toFormat('ddMMyyyyHHmm')
    const fullUrl = `${url}/linda/actions?trainPrefix=${prefix}&trainNumber=${trainNumber}&plannedStartDateTime=${startDate}&idfNumber=${idfNumber}&taskId=${taskId}`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            return []
        }
        return response.data
    } catch (e) {
        console.error(e) // eslint-disable-line
    }
}
