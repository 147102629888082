import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircle'
import LocationSearch from '../LocationSearch'
import { isEmpty } from 'lodash'

export class AddOrganisation extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        allLocations: PropTypes.array,
        selectedLocationSearchItem: PropTypes.object,
        locationSearchValue: PropTypes.string,
        clearLocationSearchValue: PropTypes.func,
        handleLocationSearchValue: PropTypes.func,
        addOrganisation: PropTypes.func,
        isValidDates: PropTypes.bool,
    }

    checkDisable = (obj, isValidDates)=> {
        if(isValidDates){
            return !obj || isEmpty(obj) 
        }
        return true
    }
    render() {
        const {
            classes,
            allLocations,
            clearLocationSearchValue,
            handleLocationSearchValue,
            selectedLocationSearchItem,
            locationSearchValue,
            addOrganisation,
            isValidDates,
        } = this.props
        return (
            <Grid container spacing={2} className={classes.locationSearcher} >
                <Grid item xs={11} style={{ padding: 0 }}>
                    <LocationSearch
                        handleSearchValue={handleLocationSearchValue}
                        searchValue={locationSearchValue}
                        clearValue={clearLocationSearchValue}
                        data={allLocations}
                        locationSelectValue={selectedLocationSearchItem}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        className={classes.withMarginButton}
                        component="span"
                        disabled={this.checkDisable(selectedLocationSearchItem, isValidDates)}
                        onClick={() => {
                            addOrganisation()
                        }}
                    >
                        {/* eslint-disable-next-line max-len */}
                        <AddIcon color={this.checkDisable(selectedLocationSearchItem, isValidDates) ? 'disabled' : 'primary'} />
                    </IconButton>
                </Grid>
            </Grid>
        )
    }
}

export default AddOrganisation
