/* eslint-disable react/prop-types */
import React from 'react'
import { DateTime } from 'luxon'

// +7200 removed form the current time to solve time issue
const mapStart = (performance, currentTime) => ({
    ...performance,
    plannedStartDelay: parseInt(
        (DateTime.fromMillis(currentTime) - DateTime.fromMillis(performance.plannedStartTime)) / 60,
        10
    ),
})

const mapBoth = (performance, currentTime) => {
    let plannedStartDelay = parseInt(0, 10)

    if (performance.realStartTime) {
        plannedStartDelay = parseInt(
            (DateTime.fromMillis(performance.realStartTime) - DateTime.fromMillis(performance.plannedStartTime)) / 60,
            10
        )
    }
    //currentTime is utc+2 ?
    //planned is allready right
    let plannedEndDelay = parseInt(
        (DateTime.fromMillis(currentTime) - DateTime.fromMillis(performance.plannedEndTime)) / 60,
        10
    )

    if (performance.realEndTime) {
        plannedEndDelay = parseInt(
            (DateTime.fromMillis(currentTime) - DateTime.fromMillis(performance.realEndTime)) / 60,
            10
        )
    }

    const actualEndDelay = plannedStartDelay > 0 ? -1 * plannedEndDelay : plannedEndDelay
    const timeDifference = plannedStartDelay !== 0 ? -1 * (plannedStartDelay + actualEndDelay) : plannedEndDelay

    return {
        ...performance,
        plannedStartDelay,
        plannedEndDelay,
        timeDifference,
    }
}

const mapFull = performance => {
    let plannedStartDelay = parseInt(0, 10)

    let plannedEndDelay = parseInt(0, 10)

    if (performance.realStartTime) {
        plannedStartDelay = parseInt(
            (DateTime.fromMillis(performance.realStartTime) - DateTime.fromMillis(performance.plannedStartTime)) / 60,
            10
        )
    }

    if (performance.realEndTime) {
        plannedEndDelay = parseInt((performance.realEndTime - performance.plannedEndTime) / 60, 10)
    }

    const actualEndDelay = plannedStartDelay > 0 ? -1 * plannedEndDelay : plannedEndDelay
    const timeDifference = plannedStartDelay !== 0 ? -1 * (plannedStartDelay + actualEndDelay) : plannedEndDelay

    return {
        ...performance,
        plannedStartDelay,
        plannedEndDelay,
        timeDifference,
    }
}

export default Component => props => {
    const { performances = [], selectedTCTs, selectedDepot, tcts, currentTime, ...restProps } = props
    let depots = []

    // Use all depots from the selected TCTs
    if (selectedDepot === 'performances_label_all_depots') {
        tcts.forEach(tct => {
            if (selectedTCTs.includes(tct.id)) depots = depots.concat(tct.depots.map(depot => depot.id))
        })
    } else {
        depots.push(selectedDepot)
    }

    const mappedPerformances = performances.reduce((accumulator, performance) => {
        if (depots[0].includes(`DEPOT-${performance.depotName}`)) {
            if (
                !performance.profileName ||
                (!!performance.profileName && !performance.signedOutTime && !performance.signedInTime)
            )
                accumulator.push(mapStart(performance, currentTime))
            else if (!performance.signedOutTime && !!performance.signedInTime)
                accumulator.push(mapBoth(performance, currentTime))
            else if (!!performance.signedOutTime && !!performance.signedInTime) accumulator.push(mapFull(performance))
            else if (!!performance.signedOutTime && !performance.signedInTime) accumulator.push(mapFull(performance))
        }

        return accumulator
    }, [])

    return <Component {...restProps} performances={mappedPerformances} />
}
