import React from 'react'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'

import { withStyles } from '@material-ui/core'

// Components
import EditTaskFooterView from './EditTaskFooterView'

import styles from './styles'

class EditTaskFooter extends React.Component {
    render() {
        return <EditTaskFooterView {...this.props} />
    }
}

export default compose(withStyles(styles), withLocalize)(EditTaskFooter)
