import React from 'react'
import { bool, func, object, any, string, number } from 'prop-types'
import { compose, pure } from 'recompose'
import { Translate } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import IconBoxEdit from '../../../Performances/components/IconBoxEdit'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import SearchBar from '../SearchBar'

import LuxonUtils from '@date-io/luxon'

import styles from './styles'

import PrimaryButton from 'components/PrimaryButton'

const getSelectedOrg = values => {
    if (values.org === ' ' && values.depot === ' ') {
        return ''
    }

    if (values.org !== ' ' && values.depot === ' ') {
        return values.org
    }

    if (values.depot !== ' ') {
        return values.depot
    }
}

const DocumentActionDrawerView = ({
    selectedRadio,
    isOpen,
    classes,
    changeRadio,
    persist,
    read,
    unread,
    handleCheckbox,
    store,
    initCall,
    searchBarValues,
    setName,
    selectedName,
    clearName,
    file,
    onClick,
    setDate,
    buttonDisabled,
}) => {
    return (
        <Drawer open={isOpen} style={{ zIndex: 1300 }} anchor="right" onClose={() => persist('filterDrawerOpen')(false)}>
            <div className={classes.drawerRoot}>
                <div className={classes.drawerHeader}>
                    <Typography variant="h6">
                        <Translate id="documents_label_filter_documents" />
                    </Typography>
                </div>
                <Typography variant="h6">
                    <Translate id="documents_label_group_by" />
                </Typography>
                <Divider className={classes.divider} />

                <React.Fragment>
                    <Grid item xs={12}>
                        <Translate>
                            {({ translate }) => (
                                <FormControlLabel
                                    className={classes.mb}
                                    control={
                                        <Radio
                                            checked={selectedRadio === 0}
                                            color="primary"
                                            value="document"
                                            onChange={() => {
                                                changeRadio(0)
                                            }}
                                        />
                                    }
                                    label={translate('documents_radio_label_doc')}
                                />
                            )}
                        </Translate>
                        {
                            <Translate>
                                {({ translate }) => (
                                    <FormControlLabel
                                        className={classes.mb}
                                        control={
                                            <Radio
                                                checked={selectedRadio === 1}
                                                color="primary"
                                                value="trainDriver"
                                                onChange={() => {
                                                    changeRadio(1)
                                                }}
                                            />
                                        }
                                        label={translate('documents_radio_label_tbs')}
                                    />
                                )}
                            </Translate>
                        }
                    </Grid>
                </React.Fragment>

                <Typography variant="h6">
                    <Translate id="date_lable" />
                </Typography>
                <Divider className={classes.divider} />

                <Translate>
                    {({ translate }) => (
                        <MuiPickersUtilsProvider style={{ marginTop: 8 }} utils={LuxonUtils}>
                            <IconBoxEdit icon="time">
                                <DatePicker
                                    format="dd/MM/yyyy"
                                    label={translate('performance_info_label_till')}
                                    value={searchBarValues.date}
                                    onChange={e => {
                                        setDate(e)
                                    }}
                                />
                            </IconBoxEdit>
                        </MuiPickersUtilsProvider>
                    )}
                </Translate>
                <React.Fragment>
                    <Grid item xs={12} style={{ marginTop: '16px' }}>
                        <Translate>
                            {({ translate }) => (
                                <FormControlLabel
                                    className={classes.mb}
                                    control={
                                        <Checkbox
                                            label={translate('documents_radio_read')}
                                            checked={read}
                                            color="primary"
                                            value="read"
                                            onChange={handleCheckbox}
                                        />
                                    }
                                    label={translate('documents_radio_read')}
                                />
                            )}
                        </Translate>
                        {
                            <Translate>
                                {({ translate }) => (
                                    <FormControlLabel
                                        className={classes.mb}
                                        control={
                                            <Checkbox
                                                checked={unread}
                                                color="primary"
                                                value="unread"
                                                onChange={handleCheckbox}
                                            />
                                        }
                                        label={translate('documents_radio_not_read')}
                                    />
                                )}
                            </Translate>
                        }
                    </Grid>
                </React.Fragment>

                <Typography variant="h6">
                    <Translate id="datatable_header_document" />
                </Typography>
                <Divider className={classes.divider} />

                <SearchBar
                    persist={persist}
                    initCall={initCall}
                    {...searchBarValues}
                    clearName={clearName}
                    selectedName={selectedName}
                    setName={setName}
                    type="app"
                    file={file}
                    selectedOrganisation={getSelectedOrg(searchBarValues)}
                    disabled={checkFunctionalityDisabled(store, 'search', 'documents.functionality.management.documents')}
                />
                <div>
                    <Translate>
                        {({ translate }) => (
                            <PrimaryButton
                                className={classes.buttonBottom}
                                title={translate('button_commit_change')}
                                onClick={onClick}
                                disabled={buttonDisabled}
                            />
                        )}
                    </Translate>
                </div>
            </div>
        </Drawer>
    )
}

DocumentActionDrawerView.propTypes = {
    classes: object.isRequired,
    close: func,
    isOpen: bool,
    changeRadio: func.isRequired,
    persist: func.isRequired,
    referenceOrg: string,
    referenceDepot: string,
    store: any,
    selectedRadio: number,
    read: bool,
    unread: bool,
    handleCheckbox: func,
    initCall: func,
    searchBarValues: any,
    setName: func,
    selectedName: string,
    clearName: func,
    file: string,
    onClick: func,
    setDate: func,
    buttonDisabled: bool,
}

export default compose(withStyles(styles), pure)(DocumentActionDrawerView)
