export default theme => ({
    districtBody: {
        width: '100%',
        padding: theme.spacing(2),
    },
    errorMessage: { color: theme.customColors.red },
    dialogWidth: {
        minWidth: '600px',
        [theme.breakpoints.down('xs')]: {
            minWidth: '300px',
        },
    },
})
