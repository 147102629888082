import axios from 'axios'
export default async id => {
    let fullUrl = `compositionReferenceData/pneumaticSuspensionRestrictionPercentage/${id}/delete`
    try {
        return await axios.post(fullUrl)
    } catch (e) {
        console.error(e) // eslint-disable-line
        return e.response
    }
}
