/* eslint-disable max-len */
import React, { useState } from 'react'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'

import PtdesDrawer from './PtdesDrawer'
import styles from './styles'

import updatePtdesData from './updatePtdesData'
import updatePtdesKmMarkData from './updatePtdesKmMarkData'

import { object, number, string, func } from 'prop-types'

const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
const regexLon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

const PtdesDrawerView = props => {

    const [mileId, setMileId] = useState(props.rowData.ptdesKmMark[props.rowData.lineIndex].mileId)
    const [distance, setDistance] = useState(props.rowData.ptdesKmMark[props.rowData.lineIndex].distance)
    const [latitude, setLatitude] = useState(props.rowData.coordinate.latitude)
    const [latitudeError, setLatitudeError] = useState(false)
    const [longitude, setLongitude] = useState(props.rowData.coordinate.longitude)
    const [longitudeError, setLongitudeError] = useState(false)
    const [ptDesLookupOther , setPtDesLookupOther ] = useState(props.rowData.ptDesLookup.other.length > 0 
        ? [...props.rowData.ptDesLookup.other, ''] : [''])
    const [isChanged, setIsChanged] = useState(false)
    const [ptDesError, setPtdesError] = useState('')
    const [ptDesKmMarkError, setPtdesKmMarkError] = useState('')
    const [ptCarGroup, setPtCarGroup] = useState(props.rowData.ptCarGroup)
    


    function handlePtDesLookupChange (val, index) {
        let data = [...ptDesLookupOther]

        if(val != ''){
            if(data[index] == ''){
                data.push('')
            }
            data[index] = val
        }
        else{
            data[index] = val
            data.splice(index, 1)
        }

        setPtDesLookupOther(data)
    }

    const handlePtDesLookupDelete = (index) => {
        let data = [...ptDesLookupOther]
        data.splice(index, 1)
        setPtDesLookupOther(data)
    }


    const validateAndUpdatePtdes = async () => {
        
        if(!isChanged){
            return props.onClose()
        }

        // validate coords

        let coordsValid = true

        if(!regexLat.test(latitude)){
            coordsValid = false
            setLatitudeError(true)
        }
        if(!regexLon.test(longitude)){
            coordsValid = false
            setLongitudeError(true)
        }

        if(!coordsValid){
            return
        }
    
        const ptDesLookupOtherFiltered = ptDesLookupOther.length > 1 ? ptDesLookupOther.slice(0, -1) : []

        let shouldCallKmMark = false
        let shouldCallPtdes = false

        if(mileId != props.rowData.ptdesKmMark[props.rowData.lineIndex].mileId || distance != props.rowData.ptdesKmMark[props.rowData.lineIndex].distance){
            shouldCallKmMark = true
        }

        if(parseFloat(latitude) != parseFloat(props.rowData.coordinate.latitude) || parseFloat(longitude) != parseFloat(props.rowData.coordinate.longitude) || JSON.stringify(ptDesLookupOtherFiltered) != JSON.stringify(props.rowData.ptDesLookup.other) || ptCarGroup != props.rowData.ptCarGroup){
            shouldCallPtdes = true
        }
        

        if(shouldCallKmMark){
            await updatePtdesKmMarkData(props.rowData.ptDesId, props.rowData.ptdesKmMark[props.rowData.lineIndex].lineId, {mileId: mileId, distance: distance}, shouldCallPtdes, props.loadPtdes, props.onClose, setPtdesKmMarkError, props.translate)
        }

        if(shouldCallPtdes){
            await updatePtdesData(props.rowData.ptDesId, {latitude: latitude, longitude: longitude, ptCarGroup: ptCarGroup,ptDesLookupOther: ptDesLookupOtherFiltered }, props.loadPtdes, props.onClose, setPtdesError, props.translate)
        } 
        
    }


    const handleSetLatitude = (e) => {
        setLatitudeError(false)
        setLatitude(e.target.value)
    }

    const handleSetLongitude = (e) => {
        setLongitudeError(false)
        setLongitude(e.target.value)
    }

   


    return (
            <PtdesDrawer 
            mileId={mileId}
            setMileId={setMileId}
            distance={distance}
            setDistance={setDistance}
            latitude={latitude}
            latitudeError={latitudeError}
            setLatitude={setLatitude}
            longitude={longitude}
            longitudeError={longitudeError}
            setLongitude={setLongitude}
            ptDesLookupOther={ptDesLookupOther}
            setPtDesLookupOther={setPtDesLookupOther}
            handlePtDesLookupChange={handlePtDesLookupChange}
            handlePtDesLookupDelete={handlePtDesLookupDelete}
            validateAndUpdatePtdes={validateAndUpdatePtdes}
            handleSetLatitude={handleSetLatitude}
            handleSetLongitude={handleSetLongitude}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            ptDesError={ptDesError}
            ptDesKmMarkError={ptDesKmMarkError}
            ptCarGroup={ptCarGroup}
            setPtCarGroup={setPtCarGroup}
            {...props} />
    )
}

PtdesDrawerView.propTypes = {
    rowData: object,
    edit: number,
    lineNumber: string,
    onClose: func,
    loadPtdes: func,
    translate: func,
}

export default compose(withStore, withStyles(styles), withLocalize)(PtdesDrawerView)
