import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default (featureId, organisationId, validFrom, validTo) => {
    const { url, subscriptionKey } = requestData

    let fullUrl = `${url}/referencedata/features/${featureId}/organisation`

    return axios.post(fullUrl, {OrganisationId: organisationId, ValidFrom: validFrom, ValidTo: validTo},{
        headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    })
}