/* eslint-disable max-len */
import { withStyles } from '@material-ui/styles'
import React from 'react'
import ListHeaderStyles from './ListHeaderStyles'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

import { semesDateTime } from 'utils/dateParser'
// eslint-disable-next-line no-unused-vars
import Loading from 'components/Loading'

// eslint-disable-next-line no-unused-vars
const Summary = ({ classes, data, semesData, isLoading }) => {
    const filteredSemesData = semesData.find(el => el.idfNumber === data.idfNumber)
    return (
        <div className={filteredSemesData ? classes.summary : classes.loading}>
            <Typography>
                <b style={{ textDecoration: 'underline' }}>
                    {data.lastName.toUpperCase()} {data.firstName.toUpperCase()}
                </b>
                <br></br>
                {data.idfNumber}
            </Typography>
            {filteredSemesData ? (
                <>
                    <Typography>
                        {filteredSemesData?.tct ? filteredSemesData.tct : ''} {' - '}
                        {filteredSemesData?.depot ? filteredSemesData.depot : ''}
                    </Typography>
                    <Typography>
                        {filteredSemesData?.firstReceived ? semesDateTime(filteredSemesData.firstReceived) : '-'}
                    </Typography>
                    <Typography>
                        {filteredSemesData?.firstOpened ? semesDateTime(filteredSemesData.firstOpened) : '-'}
                    </Typography>
                    <Typography>
                        {filteredSemesData?.lastOpened ? semesDateTime(filteredSemesData.lastOpened) : '-'}
                    </Typography>
                    <Typography>{filteredSemesData?.numberOfTasksApplicable}</Typography>
                    <Typography
                        className={
                            filteredSemesData?.numberOfTasksApplicableReadPercentage < 75 ? classes.redPercentage : ''
                        }
                    >
                        {filteredSemesData?.numberOfTasksApplicableRead} (
                        {filteredSemesData?.numberOfTasksApplicableReadPercentage}
                        %)
                    </Typography>
                </>
            ) : (
                <>
                    {isLoading ? (
                        <div style={{ maxHeight: '60px' }}>
                            <Loading />
                        </div>
                    ) : (
                        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>No Data</div>
                    )}
                </>
            )}
        </div>
    )
}

Summary.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    semesData: PropTypes.array,
    isLoading: PropTypes.bool,
}
export default withStyles(ListHeaderStyles)(Summary)
