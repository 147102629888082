/* eslint-disable no-unused-vars */
import React from 'react'
import { arrayOf, object, func, string } from 'prop-types'
import { Translate } from 'react-localize-redux' // translation info comes from with

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import District from './components/District'
import DistrictsMap from './components/DistrictsMap'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { compose } from 'recompose'

import styles from './styles'

import getProfilesWithError from 'modules/Manage/components/ManageProfile/Services/getProfilesWithError'
import Loading from 'components/Loading'

const initialProfilesWithError = districts => {
    return districts.map(district => {
        return {
            districtId: district.id,
            errorCount: 0,
        }
    })
}

const Districts = ({ districts, chooseDistrict, classes, width }) => {
    const { districtViewBody, title } = classes

    const [profilesWithError, setProfilesWithError] = React.useState(initialProfilesWithError(districts))
    const [profilesLoading, setProfilesLoading] = React.useState(false)
    const [loading, setLoading] = React.useState(true)

    const getProfiles = async () => {
        const profiles = await getProfilesWithError()
        if (!profiles) {
            setProfilesWithError(initialProfilesWithError(districts))
            setLoading(false)
        } else {
            if (profiles.length === 0) {
                setProfilesWithError(initialProfilesWithError(districts))
                setLoading(false)
            } else {
                setProfilesWithError(profiles)
                setLoading(false)
            }
        }
        return null
    }

    React.useEffect(() => {
        let cancel = false
        if (cancel) setProfilesLoading(true)
        getProfiles().then(() => {
            if (cancel) return
            setProfilesLoading(false)
        })
        return () => {
            cancel = true
        }
    }, [])

    if (loading) {
        return <Loading />
    }

    if (isWidthUp('sm', width)) {
        return (
            <DistrictsMap
                profilesLoading={profilesLoading}
                profilesWithError={profilesWithError}
                districts={districts}
                chooseDistrict={chooseDistrict}
            />
        )
    } else {
        return (
            <div className={districtViewBody}>
                <Typography variant="h6" className={title}>
                    <Translate id="districts_choose_district" />
                </Typography>
                <Grid container spacing={2}>
                    {districts.map((district, ind) => (
                        <Grid item key={district.id} xs={12} sm={6} md={4} lg={3} xl={2}>
                            <District
                                profilesLoading={profilesLoading}
                                numberOfProfilesWithError={profilesLoading ? false : profilesWithError[ind]?.errorCount}
                                id={district.id}
                                chooseDistrict={chooseDistrict}
                            />
                        </Grid>
                    ))}
                </Grid>
                {districts.length === 0 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                <Translate id="districts_no_valid_organisation_1" />
                            </Typography>
                            <Typography>
                                <Translate id="districts_no_valid_organisation_2" />
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </div>
        )
    }
}

Districts.propTypes = {
    districts: arrayOf(object).isRequired,
    classes: object.isRequired,
    chooseDistrict: func.isRequired,
    width: string,
}

export default compose(withWidth(), withStyles(styles))(Districts)
