import { DateTime } from 'luxon'

export const toDateTime = timestamp => DateTime.fromMillis(timestamp).setZone('Europe/Brussels')
export const toDateTimeFromSeconds = timestamp =>
    timestamp == null ? null : DateTime.fromSeconds(timestamp).setZone('Europe/Brussels')

export const dateParser = date => toDateTime(date).toFormat('dd/MM/yyyy')
export const apiDateParser = date => toDateTime(date).toFormat('ddMMyyyy')
export const apiDateTimeParser = date => toDateTime(date).toFormat('ddMMyyyyHHmm')
export const apiReportingDateTimeParser = date => toDateTimeFromSeconds(date).toFormat('yyyyMMdd')
export const dateTime = date => toDateTime(date).toFormat('dd/MM/yyyy HH:mm')
export const dateTimeWithDash = date => toDateTime(date).toFormat('dd-MM-yyyy HH:mm')

export const dateTimeFromEpochSeconds = timeInSeconds =>
    timeInSeconds == null ? null : DateTime.fromSeconds(timeInSeconds).toFormat('dd/MM/yyyy HH:mm')
export const timeFromEpochSeconds = timeInSeconds =>
    timeInSeconds == null ? null : DateTime.fromSeconds(timeInSeconds).toFormat('HH:mm')
export const dateFromEpochSeconds = timeInSeconds => DateTime.fromSeconds(timeInSeconds).toFormat('dd/MM/yyyy')
export const compoDateFromEpochSeconds = timeInSeconds => DateTime.fromSeconds(timeInSeconds).toFormat('ddMMyyyy')
export const semesDateTime = timeInSeconds => DateTime.fromSeconds(timeInSeconds).toFormat('dd-MM-yyyy HH:mm')
export default dateParser
