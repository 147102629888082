import React from 'react'
import { object, arrayOf, func } from 'prop-types'
//import groupBy from "lodash/groupBy";
import { compose } from 'recompose'

import { withStyles, Divider } from '@material-ui/core'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import DownloadIcon from '@material-ui/icons/GetApp'
import IconButton from '@material-ui/core/IconButton'
import styles from './styles'

class PerformanceGroup extends React.Component {
    static propTypes = {
        performances: arrayOf(object).isRequired,
        children: func.isRequired,
        classes: object.isRequired,
    }

    state = {
        openPerformance: '',
    }

    openPerformance = openPerformance =>
        this.setState({ openPerformance: openPerformance === this.state.openPerformance ? '' : openPerformance })

    handleDownloadClick = () => {
        //console.log("download clicked");
    }

    render() {
        const { performances, classes } = this.props
        //const groupedPerformances = groupBy(performances, "performanceName");

        return (
            <List style={{ paddingRight: '32px' }}>
                {performances &&
                    performances.map(performance => (
                        <React.Fragment key={performance.performanceNumber}>
                            <Paper square elevation={0}>
                                <ListItem button onClick={() => this.openPerformance(performance)}>
                                    <ListItemText primary={performance.performanceNumber} />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="download"
                                            onClick={() => this.handleDownloadClick()}
                                        >
                                            <DownloadIcon />
                                        </IconButton>

                                        {this.state.openPerformance === performance ? (
                                            <ExpandLess className={classes.icon} />
                                        ) : (
                                            <ExpandMore className={classes.icon} />
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Paper>
                            {/* {this.state.openPerformance === performance &&
                              children(groupedPerformances[performance])} */}

                            <Divider className={classes.divider}></Divider>
                        </React.Fragment>
                    ))}
            </List>
        )
    }
}

export default compose(withStyles(styles))(PerformanceGroup)
