export const notSignedInColumns = [
    {
        width: 350,
        label: 'datatable_header_performance',
        dataKey: 'performanceId',
    },
    {
        width: 240,
        flexGrow: 1.0,
        label: 'datatable_header_name',
        dataKey: 'profileName',
    },
    {
        width: 240,
        label: 'datatable_header_planned_start',
        dataKey: 'plannedStartTime',
    },
    {
        width: 240,
        label: 'datatable_header_planned_end',
        dataKey: 'plannedEndTime',
    },
    {
        width: 150,
        label: 'datatable_header_actions',
        dataKey: 'actions',
    },
    // {
    //   width: 240,
    //   label: translate("datatable_header_time_difference"),
    //   dataKey: "timeDifference"
    // }
]

export const signedInColumns = [
    {
        width: 350,
        label: 'datatable_header_performance',
        dataKey: 'performanceId',
    },
    {
        width: 240,
        flexGrow: 1.0,
        label: 'datatable_header_name',
        dataKey: 'profileName',
    },
    {
        width: 240,
        label: 'datatable_header_planned_start',
        dataKey: 'plannedStartTime',
    },
    {
        width: 240,
        label: 'datatable_header_planned_end',
        dataKey: 'plannedEndTime',
    },
    {
        width: 150,
        label: 'datatable_header_actions',
        dataKey: 'actions',
    },
    // {
    //   width: 240,
    //   label: "datatable_header_time_difference",
    //   dataKey: "timeDifference"
    // }
]

export const signedOffColumns = [
    {
        width: 350,
        label: 'datatable_header_performance',
        dataKey: 'performanceId',
    },
    {
        width: 240,
        flexGrow: 1.0,
        label: 'datatable_header_name',
        dataKey: 'profileName',
    },
    {
        width: 240,
        label: 'datatable_header_planned_start',
        dataKey: 'plannedStartTime',
    },
    {
        width: 240,
        label: 'datatable_header_planned_end',
        dataKey: 'plannedEndTime',
    },
    {
        width: 240,
        label: 'datatable_header_time_overage',
        dataKey: 'timeOverage',
    },
    {
        width: 150,
        label: 'datatable_header_actions',
        dataKey: 'actions',
    },
]
