import React from 'react'
import { object, func } from 'prop-types'
import { compose } from 'recompose'
import { withStore, withTake } from 'react-ion-store'

import ProfileDetail from './components/ProfileDetail'
import DocumentDetail from './components/DocumentDetail'

const DetailView = ({ documentsDetailView, clearDetailView }) => {
    const { type, data } = documentsDetailView
    return type === 'document' ? (
        <DocumentDetail viewData={data} clearDetailView={clearDetailView} />
    ) : (
        <ProfileDetail viewData={data} clearDetailView={clearDetailView} />
    )
}

DetailView.propTypes = {
    documentsDetailView: object.isRequired,
    clearDetailView: func.isRequired,
}

export default compose(withStore, withTake('documentsDetailView'))(DetailView)
