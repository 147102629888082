/* eslint-disable no-unused-vars */
import axios from 'axios'

//@ts-ignore
export default async (id, idfNumber) => {
    const fullUrl = `compositionnondriving/${id}?idfnumber=${idfNumber}`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status === 204) {
            return []
        }
        let data = response.data
        return data
    } catch (e) {
        console.error(e) // eslint-disable-line
        return []
    }
}
