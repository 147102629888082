export default theme => ({
    materialUnitLocoNotInUse: {
        borderWidth: 3,
        borderStyle: 'dashed',
        borderColor: theme.customColors.white,
        borderRadius: 15,
        padding: theme.spacing(1),
        marginTop: '4px',
    },
    selectedMaterialUnitLocoNotInUse: {
        borderWidth: 3,
        borderStyle: 'dashed',
        borderColor: theme.customColors.primaryBlue,
        borderRadius: 15,
        padding: theme.spacing(1),
        marginTop: '4px',
    },
    materialUnitLocoInUse: {
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: theme.customColors.white,
        borderRadius: 15,
        padding: theme.spacing(1),
        marginTop: '4px',
    },
    selectedMaterialUnitLocoInUse: {
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: theme.customColors.primaryBlue,
        borderRadius: 15,
        padding: theme.spacing(1),
        marginTop: '4px',
    },
})
