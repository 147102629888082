export default theme => ({
    drawerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
        '@media (max-width: 577px)': {
            width: '85vw',
        },
    },
    readList: {
        maxHeight: `calc(100vh - ${theme.readList + 116}px)`,
        overflowY: 'auto',
        marginBottom: theme.spacing(2),
    },
    profileInfo: {
        marginTop: theme.spacing(2),
    },
    profileInfoTitle: {
        fontWeight: 'bold',
    },
    topSpacingTitle: {
        marginTop: theme.spacing(3),
    },
    boldText: {
        fontWeight: 'bold',
        color: theme.customColors.white,
    },
    mb: {
        marginBottom: theme.spacing(2),
    },
    performanceInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
    },
    performanceInfoCell: {
        width: '33%',
        '@media (max-width: 577px)': {
            width: '50%',
        },
    },
    link: {
        fontWeight: 'bold',
        textDecoration: 'underline',
        color: theme.customColors.white,
        cursor: 'pointer',
        width: 'fit-content',
    },
    disabledLink: {
        fontWeight: 'bold',
        color: 'grey',
        pointerEvents: 'none',
        width: 'fit-content',
    },
})
