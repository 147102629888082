import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'
import { func, object } from 'prop-types'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import EditIcon from '@material-ui/icons/Edit'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { withLocalize, Translate } from 'react-localize-redux'

import styles from './styles'
import { compose } from 'recompose'

const VehicleMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = event => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = event => {
        event.stopPropagation()
        setAnchorEl(null)
    }
    const getMenuText = name => <Typography classes={{ root: props.classes.root }}>{name} </Typography>

    return (
        <div>
            <MoreVertIcon aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} />

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                // PaperProps={{
                //     style: {
                //         width: '25ch',
                //     },
                // }}
            >
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        props.handleEditClicked()
                    }}
                >
                    {getMenuText(<Translate id="compo_change_vehicle" />)}
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        props.handleAddLitteraBefore()
                    }}
                >
                    {getMenuText(<Translate id="compo_add_vehicle_in_front" />)}
                    <ListItemIcon>
                        <AddCircleOutlineIcon />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        props.handleAddLitteraAfter()
                    }}
                >
                    {getMenuText(<Translate id="compo_add_vehicle_behind" />)}
                    <ListItemIcon>
                        <AddCircleOutlineIcon />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        props.moveVehicleUpward()
                    }}
                >
                    {getMenuText(<Translate id="compo_move_vehicle_up" />)}
                    <ListItemIcon>
                        <ArrowUpwardIcon />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        props.moveVehicleDownward()
                    }}
                >
                    {getMenuText(<Translate id="compo_move_vehicle_down" />)}
                    <ListItemIcon>
                        <ArrowDownwardIcon />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        props.removeVehicle()
                    }}
                >
                    {getMenuText(<Translate id="compo_remove_vehicle" />)}
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                </MenuItem>
            </Menu>
        </div>
    )
}
VehicleMenu.propTypes = {
    handleEditClicked: func,
    removeVehicle: func,
    moveVehicleUpward: func,
    moveVehicleDownward: func,
    handleAddLitteraAfter: func,
    handleAddLitteraBefore: func,
    classes: object,
}
export default compose(withStyles(styles), withLocalize)(VehicleMenu)
