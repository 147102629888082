import React from 'react'
import { func, string, object, bool } from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withStore, util } from 'react-ion-store'
import debounce from 'lodash/debounce'

import TextField from '@material-ui/core/TextField'

import Autocomplete from './components/Autocomplete'

class PersonSearcher extends React.Component {
    constructor(props) {
        super(props)
        this.debouncedCall = debounce(this.initCall, 1000)
    }

    static propTypes = {
        selectedName: string.isRequired,
        setName: func.isRequired,
        clearName: func.isRequired,
        store: object.isRequired,
        selectedOrganisation: string,
        disabled: bool,
    }

    state = {
        typedName: '',
        renderAutocomplete: false,
    }

    initCall = () => {
        const toChange = { renderAutocomplete: this.state.typedName.length >= 2 }

        if (toChange.renderAutocomplete) toChange.timestamp = Date.now()
        this.setState(toChange, () => {
            if (this.state.typedName.length >= 2) {
                this.props.store.call({ suggestions: util.CreateStoreObject() })
                this.props.store.call({ appSuggestions: util.CreateStoreObject() })
            }
        })
    }

    closeSuggestions = e => {
        if (e === undefined) {
            setTimeout(() => {
                this.setState({ renderAutocomplete: false, typedName: '' })
            }, 500)
            return
        }

        if (e.relatedTarget === null) {
            setTimeout(() => {
                this.setState({ renderAutocomplete: false, typedName: '' })
            }, 500)
            return
        }

        const name = e.relatedTarget.getAttribute('name')
        if (name === 'PersonSearcherWrapper') {
            document.getElementById('search-input').focus()
        }
        if (name !== 'PersonSearcherWrapper') {
            setTimeout(() => {
                this.setState({ renderAutocomplete: false, typedName: '' })
            }, 500)
        }
    }

    setName = name => {
        this.setState({ renderAutocomplete: false }, () => this.props.setName(name))
    }

    startTyping = e => {
        const typedName = e.target.value

        if (this.props.selectedName) this.props.clearName()

        this.setState({ typedName, renderAutocomplete: false })
    }

    render() {
        return (
            <Translate>
                {({ translate }) => (
                    <React.Fragment>
                        <TextField
                            data-testid="person-searcher"
                            id="search-input"
                            fullWidth={true}
                            value={this.props.selectedName || this.state.typedName}
                            onChange={this.startTyping}
                            onKeyDown={this.debouncedCall}
                            placeholder={translate('districts_read_prompt')}
                            onBlur={e => this.closeSuggestions(e)}
                            disabled={this.props.disabled}
                        />
                        <Autocomplete
                            data-testid="person-searcher-auto"
                            tabIndex={0}
                            selectedOrganisation={this.props.selectedOrganisation}
                            setName={this.setName}
                            text={this.state.typedName}
                            getCurrentState={() => this.state}
                            visible={this.state.renderAutocomplete}
                            onBlur={e => this.closeSuggestions(e)}
                            closeSuggestions={this.closeSuggestions}
                        />
                    </React.Fragment>
                )}
            </Translate>
        )
    }
}

export default withStore(PersonSearcher)
