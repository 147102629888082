import React from 'react'
import { object, func, string, number } from 'prop-types'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import { withLocalize, Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { Grid, Typography } from '@material-ui/core'

import PersonSearcher from 'components/PersonSearcher'

class ProfileSearch extends React.Component {
    static propTypes = {
        persist: func.isRequired,
        classes: object.isRequired,
        store: object.isRequired,
        radio: string.isRequired,
        setName: func.isRequired,
        clearName: func.isRequired,
        errorProfileCount: number,
        selectedName: string,
    }

    handleEvent = type => e => this.props.persist(type)(e.target.value)

    render() {
        const { classes, radio, setName, clearName, selectedName, errorProfileCount } = this.props

        return (
            <Translate>
                {({ translate }) => (
                    <div className={classes.searchBarRoot}>
                        <Grid item xs={6}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="Gender"
                                    name="radio"
                                    className={classes.radioGroup}
                                    value={radio}
                                    onChange={this.handleEvent('radio')}
                                >
                                    <FormControlLabel
                                        value="all"
                                        control={<Radio color="primary" />}
                                        label={translate('profile_label_all_profile')}
                                    />
                                    <FormControlLabel
                                        value="new"
                                        control={<Radio color="primary" />}
                                        label={translate('profile_label_new_profile')}
                                    />
                                    <FormControlLabel
                                        value="error"
                                        control={<Radio color="primary" />}
                                        label={translate('profile_label_error_profile')}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <PersonSearcher selectedName={selectedName} setName={setName} clearName={clearName} type="app" />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography
                                variant={'h6'}
                                style={{ float: 'right' }}
                            >{` ${errorProfileCount} Profile in error today`}</Typography>
                        </Grid>
                    </div>
                )}
            </Translate>
        )
    }
}

export default compose(withStore, withLocalize, withRouter)(ProfileSearch)
