export default theme => ({
    root: {
        color: theme.customColors.black,
        borderRadius: 8,
        backgroundColor: theme.customColors.grey,
        '&:hover': {
            backgroundColor: theme.customColors.white,
        },
        '&:active': {
            backgroundColor: theme.customColors.white,
        },
        '&:focus': {
            backgroundColor: theme.customColors.white,
        },
        margin: theme.spacing(1),

        '&:disabled': {
            color: theme.customColors.black,
            backgroundColor: theme.customColors.grey,
          }
    },
    activeButton: {
        color: theme.customColors.black,
        borderRadius: 8,
        margin: theme.spacing(1),
        backgroundColor: theme.customColors.white,
        '&:hover': {
            backgroundColor: theme.customColors.white,
        },
    },
})
