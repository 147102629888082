import React, { useState } from 'react'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import PropTypes from 'prop-types'

import { getFeedbackOnCategoryLevel } from '../Utils'

import styles from '../styles'
import RouteView from './RouteView'

const isRouteData = rowData => Object.prototype.hasOwnProperty.call(rowData, 'arrivalTime')
const Page3 = ({ data, classes, activeLanguage, handleImageClick }) => {
    const [openFeedbackIndex, setIndex] = useState(-1)
    const feedbacks = getFeedbackOnCategoryLevel(data)

    var keys = Object.keys(feedbacks)
    return (
        <div data-testid="page-3" className={classes.root}>
            <Paper>
                <Grid container spacing={0} className={classes.outerPadding}>
                    <Grid className={classes.column} item xs={12}>
                        <Typography className={classes.text}>Overview per category</Typography>
                    </Grid>
                    {keys.map((key, index) => {
                        const feedbackData = feedbacks[key]
                        return (
                            <React.Fragment key={index}>
                                <Grid className={classes.column} item xs={2}>
                                    <div>
                                        <Typography className={classes.text}>{key}</Typography>
                                        {feedbackData.length > 1 && (
                                            <Typography className={classes.text}>{` (${feedbackData.length})`}</Typography>
                                        )}
                                        {feedbackData.length > 1 ? (
                                            openFeedbackIndex !== -1 && openFeedbackIndex === index ? (
                                                <ExpandLess className={classes.icon} onClick={() => setIndex(-1)} />
                                            ) : (
                                                <ExpandMore className={classes.icon} onClick={() => setIndex(index)} />
                                            )
                                        ) : null}
                                    </div>
                                </Grid>
                                <Grid className={classes.gridItemBorder} item xs={10}>
                                    {feedbackData.length > 1 && openFeedbackIndex === index
                                        ? feedbackData.map((rowData, feedBackDataIndex) => {
                                              return (
                                                  <Grid
                                                      container
                                                      spacing={0}
                                                      key={feedBackDataIndex}
                                                      className={classes.columnBottomBorder}
                                                  >
                                                      <Grid item xs={1}>
                                                          <Typography className={classes.text}>
                                                              {feedBackDataIndex + 1}
                                                          </Typography>
                                                      </Grid>
                                                      <Grid item xs={11}>
                                                          {isRouteData(rowData) && (
                                                              <RouteView
                                                                  rowData={rowData}
                                                                  classes={classes}
                                                                  activeLanguage={activeLanguage}
                                                                  handleImageClick={handleImageClick}
                                                              />
                                                          )}
                                                          {!isRouteData(rowData) && (
                                                              <React.Fragment>
                                                                  <Grid className={classes.columnBorderLeft} item xs={12}>
                                                                      <Typography className={classes.text}>
                                                                          {rowData.feedbackFreeText}
                                                                      </Typography>
                                                                  </Grid>
                                                                  <Grid className={classes.columnNoRight} item xs={12}>
                                                                      <Typography className={classes.text}>
                                                                          {rowData.trainNumber
                                                                              ? rowData.trainNumber
                                                                              : rowData.code
                                                                              ? rowData.code
                                                                              : rowData.feedbackCategory1Value}
                                                                      </Typography>
                                                                  </Grid>
                                                                  <Grid className={classes.columnLeft} item xs={12}>
                                                                      {rowData.feedbackImagesWithBlobUrl &&
                                                                          rowData.feedbackImagesWithBlobUrl.length > 0 && (
                                                                              <div style={{ display: 'flex' }}>
                                                                                  {rowData.feedbackImagesWithBlobUrl.map(
                                                                                      (link, linkIndex) => (
                                                                                          <Typography
                                                                                              key={linkIndex}
                                                                                              className={classes.link}
                                                                                              onClick={() =>
                                                                                                  handleImageClick(link)
                                                                                              }
                                                                                          >
                                                                                              {` Open Image${linkIndex +
                                                                                                  1} , `}
                                                                                          </Typography>
                                                                                      )
                                                                                  )}
                                                                              </div>
                                                                          )}
                                                                  </Grid>
                                                              </React.Fragment>
                                                          )}
                                                      </Grid>
                                                  </Grid>
                                              )
                                          })
                                        : feedbackData.length && (
                                              <Grid item xs={12}>
                                                  {isRouteData(feedbackData[0]) ? (
                                                      <RouteView
                                                          rowData={feedbackData[0]}
                                                          classes={classes}
                                                          activeLanguage={activeLanguage}
                                                          handleImageClick={handleImageClick}
                                                      />
                                                  ) : (
                                                      <Grid item xs={12}>
                                                          <React.Fragment>
                                                              <Typography className={classes.text}>
                                                                  {feedbackData[0].feedbackFreeText}
                                                              </Typography>
                                                              <hr />
                                                              <Typography className={classes.text}>
                                                                  {feedbackData[0].trainNumber
                                                                      ? feedbackData[0].trainNumber
                                                                      : feedbackData[0].code
                                                                      ? feedbackData[0].code
                                                                      : feedbackData[0].feedbackCategory1Value}
                                                              </Typography>

                                                              {feedbackData[0].feedbackImagesWithBlobUrl &&
                                                              feedbackData[0].feedbackImagesWithBlobUrl.length > 0
                                                                  ? feedbackData[0].feedbackImagesWithBlobUrl.map(
                                                                        (link, linkIndex) => {
                                                                            return (
                                                                                <Typography
                                                                                    style={{ marginLeft: '4px' }}
                                                                                    key={linkIndex}
                                                                                    className={classes.link}
                                                                                    onClick={() => handleImageClick(link)}
                                                                                >
                                                                                    {` Open Image${linkIndex + 1}, `}
                                                                                </Typography>
                                                                            )
                                                                        }
                                                                    )
                                                                  : null}
                                                          </React.Fragment>
                                                      </Grid>
                                                  )}
                                              </Grid>
                                          )}
                                </Grid>
                            </React.Fragment>
                        )
                    })}
                </Grid>
            </Paper>
        </div>
    )
}

Page3.propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    translate: PropTypes.func,
    handleImageClick: PropTypes.func,
    activeLanguage: PropTypes.object,
}
export default compose(withStore, withLocalize, withStyles(styles))(Page3)
