/* eslint-disable react/prop-types */

import React from 'react'
import Loading from 'components/Loading'
import { enums } from 'react-ion-store'
import { useLocation } from 'react-router-dom'

const {
    CallStatus: { LOADING, FAILED },
} = enums
const Failed = () => <div>Error retrieving Roles!</div>

const withFunctionalityCheck = Component => props => {
    const location = useLocation()
    const { store, history, __ion_status } = props
    const { userRoles } = __ion_status

    const moduleList = store.get('moduleList')

    const rolesLoading = !!userRoles && userRoles === LOADING
    const rolesFailed = !!userRoles && userRoles === FAILED

    if (rolesFailed)
        return (
            <React.Fragment data-testid="failed">
                <Failed />
            </React.Fragment>
        )
    else if (rolesLoading || !moduleList)
        return (
            <React.Fragment data-testid="loading">
                <Loading />
            </React.Fragment>
        )
    else if (!moduleList.includes(location.pathname)) {
        history.replace(`/`)
        return <div />
    }

    return <Component {...props} data-testid="childComponent" />
}

export default withFunctionalityCheck
