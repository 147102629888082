import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import IconButton from 'components/IconButton'
import dateParser from 'utils/dateParser'

import { getRoleFromId } from '../../config/UserRoles'

export class FoldableRow extends PureComponent {
    static propTypes = {
        districtData: PropTypes.array,
        OrganisationsData: PropTypes.array,
        userDetails: PropTypes.object,
        classes: PropTypes.object,
        store: PropTypes.object,
        handleDelete: PropTypes.func,
        handleEdit: PropTypes.func,
        translate: PropTypes.func,
        checkFunctionalityDisabled: PropTypes.func,
        roleItems: PropTypes.array,
    }

    printRoles = roles => {
        return roles.map(role => {
            const rolesMapped = role.organisations.map(org => (
                <Typography key={`${org.organisation}-${org.validFrom}`} variant="body1">
                    {this.mapOrganisations(org)}
                </Typography>
            ))
            return (
                <div key={`key-${role.portalUserRoleId}`} style={{ padding: '24px' }}>
                    <Typography variant="h6">
                        {getRoleFromId(this.props.roleItems, role.portalUserRoleId.toString())}
                    </Typography>
                    {rolesMapped}
                </div>
            )
        })
    }

    mapOrganisations = org => {
        const { OrganisationsData } = this.props

        const item = OrganisationsData.find(orgs => orgs.value.toString() === org.organisation.toString())
        if (!item) return ''
        return `${item.label} - ${dateParser(org.validFrom * 1000)} - ${dateParser(org.validTo * 1000)}`
    }

    render() {
        const { userDetails, handleDelete, handleEdit, classes, translate, checkFunctionalityDisabled } = this.props
        const portalUserRoles = userDetails.roles

        return (
            <React.Fragment>
                <TableCell style={{ whiteSpace: 'nowrap', padding: 0 }} colSpan={12}>
                    <div>
                        {portalUserRoles && portalUserRoles.length > 0 ? (
                            this.printRoles(portalUserRoles)
                        ) : (
                            <Typography style={{ padding: '24px' }} variant="body1">
                                {translate('users_and_roles_no_added_roles')}
                            </Typography>
                        )}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'space-between', flexWrap: 'wrap' }}>
                        <div style={{ width: '100%', padding: '24px' }}>
                            <IconButton
                                className={classes.marginButton}
                                icon="edit"
                                onClick={e => {
                                    handleEdit(e, userDetails.id)
                                }}
                                title={translate('add_user_edit')}
                                disabled={checkFunctionalityDisabled(
                                    this.props.store,
                                    'edit',
                                    'users.functionality.management.users'
                                )}
                            />

                            <IconButton
                                icon="delete"
                                color="secondary"
                                // disabled
                                onClick={e => {
                                    handleDelete(e, userDetails.id, `${userDetails.name} `)
                                }}
                                title={translate('add_user_delete')}
                                disabled={checkFunctionalityDisabled(
                                    this.props.store,
                                    'edit',
                                    'users.functionality.management.users'
                                )}
                            />
                        </div>
                    </div>
                </TableCell>
            </React.Fragment>
        )
    }
}

export default FoldableRow
