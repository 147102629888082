const groups = [
    { group: 1, components: [1, 2, 3] },
    { group: 2, components: [2, 3, 4, 5, 6] },
    { group: 3, components: [2, 3, 4, 5, 6, 8], trainNumber: true },
    { group: 4, components: [2, 3, 4, 5, 6] },
    { group: 5, components: [2, 3, 4, 7] },
    { group: 6, components: [3, 4, 7, 10] },
    { group: 7, components: [3, 8, 9, 10], trainNumber: true },
    { group: 8, components: [3, 9, 10] },
    { group: 9, components: [3, 4, 7, 8, 10], trainNumber: true },
    { group: 10, components: [3, 4, 7, 10] },
    { group: 11, components: [3, 4, 5, 6, 8, 10], trainNumber: true },
    { group: 12, components: [3, 4, 7] },
    { group: 13, components: [3, 4, 10] },
    { group: 14, components: [1, 2, 3, 10] },
    // { group: 15, components: [3] },
    // { group: 16, components: [4] },
]

export default groups
