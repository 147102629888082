export const translationsMapper = (obj) => {
    let newObj = {}
    for(let translation in obj["nl-BE"]){
        newObj[translation] = [obj["nl-BE"][translation], obj["fr-BE"][translation]]
    }
    
    return newObj
}

export default translationsMapper
