/* eslint-disable max-len */
import React from 'react'
import { Table, TableBody, TableCell, TableRow, TableHead, Typography, Box } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import { object, array, func, bool } from 'prop-types'
import { withStore } from 'react-ion-store'
import { UnfoldMore, UnfoldLess } from '@material-ui/icons'
import Loading from 'components/Loading'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

const UserListForFeature = props => {
    const { userData, classes, translate, handleDeleteUser, addNewUserCallLoading, userListFolded, setUserListFolded, isUserListLoading } = props

    const toggleFold = () => {
        if(!addNewUserCallLoading && !isUserListLoading){
            setUserListFolded(!userListFolded)
        }
    }

    return (
            <Table>
                {userData.length > 0 ? (
                    <TableHead  style={{ height: '32px' }}>
                        <TableRow onClick={() => {toggleFold()}} style={!addNewUserCallLoading && !isUserListLoading ? {cursor: 'pointer'} : null}>
                            <TableCell style={{ width: '50%', padding: '0px' }}>
                                <Typography variant="h6">{translate('manage_label_users')}</Typography>
                            </TableCell>
                            <TableCell align="right" style={{ padding: '8px', paddingRight: '0px' }}>
                                    {!userListFolded ? (
                                    <UnfoldMore
                                    className={classes.actionIcon}
                                    />
                                    ) : (
                                    <UnfoldLess
                                    className={classes.actionIcon}
                                    />
                                    )}     
                            </TableCell>
                        </TableRow>
                    </TableHead>
                ) : null}

                {!addNewUserCallLoading && !isUserListLoading ? (
                    
                <TableBody>
                    {userData && !userListFolded &&
                        userData.map(item => (
                            <React.Fragment key={item.idfNumber}>
                                <TableRow style={{ height: '56px' }}>
                                    <TableCell style={{paddingLeft : '8px'}}>
                                        <Typography variant="body2" className={classes.underline}>
                                            {`${item.firstName} ${item.lastName}`}
                                        </Typography>
                                        <Typography variant="body2">{item.idfNumber}</Typography>
                                    </TableCell>

                                    <TableCell align="right" style={{paddingRight: '0px'}}>
                                        <Delete
                                            disabled={addNewUserCallLoading}
                                            className={classes.actionIcon}
                                            style={{
                                                cursor:
                                                    checkFunctionalityDisabled(
                                                        props.store,
                                                        'edit',
                                                        'users.functionality.management.users'
                                                    ) || addNewUserCallLoading
                                                        ? 'none'
                                                        : 'pointer',
                                                color:
                                                    checkFunctionalityDisabled(
                                                        props.store,
                                                        'edit',
                                                        'users.functionality.management.users'
                                                    ) || addNewUserCallLoading
                                                        ? 'grey'
                                                        : '',
                                            }}
                                            onClick={() => {
                                                handleDeleteUser(item.idfNumber)
                                            }}
                                        />
                                    </TableCell>
                                    
                                </TableRow>
                            </React.Fragment>
                        ))}
                </TableBody>
                ) : (
                    <Box xs={{ margin: '0px auto', width: '200px' }}>
                        <Loading />
                    </Box>
                )}
            </Table>
    )
}
UserListForFeature.propTypes = {
    classes: object,
    store: object,
    userData: array,
    translate: func,
    handleDeleteUser: func,
    addNewUserCallLoading: bool,
    userListFolded: bool,
    setUserListFolded: func,
    isUserListLoading: bool,
}
export default withStore(UserListForFeature)
