export default theme => ({
    districtViewBody: {
        padding: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(3),
    },
    readStatus: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(3),
    },
})
