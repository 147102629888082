import React, { PureComponent } from 'react'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'
import { util } from 'react-ion-store'

import { any, func, bool, string, object } from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import ErrorMessage from 'components/ErrorMessage'

import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

import { Paper, Typography } from '@material-ui/core'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export class AbolishDialog extends PureComponent {
    static propTypes = {
        performanceDetailPage: bool,
        storage: any,
        history: any,
        open: bool.isRequired,
        isAbolished: bool,
        handleClose: func.isRequired,
        performanceId: string,
        taskId: string.isRequired,
        idfNumber: string,
        translate: func,
        classes: object,
    }

    state = {
        loading: false,
        error: false,
    }

    componentWillUnmount() {
        this.setState({ loading: false, error: false })
    }

    abolishTask = (performanceId, taskId, idfNumber) => {
        this.setState({ loading: true })
        this.abolishTaskCall(performanceId, taskId, idfNumber)
    }

    reactivateTask = (performanceId, taskId, idfNumber) => {
        this.setState({ loading: true })
        this.reactivateTaskCall(performanceId, taskId, idfNumber)
    }

    updateAbolishReactivateTaskInStore = (taskId, abolish) => {
        const { handleClose } = this.props

        let oldPerformanceDetail = this.props.storage.get('performanceDetail')

        const task = oldPerformanceDetail.tasks.find(oldTask => oldTask.id === taskId)

        task.isAbolished = abolish
        if (!abolish) task.status = 0
        util.CreateStoreObject(oldPerformanceDetail)
        handleClose()
    }

    abolishTaskCall = async (selectedPerformanceNumber, selectedTaskNumber, selectedPerformanceIdf) => {
        const { url, subscriptionKey } = requestData
        const { translate } = this.props
        const fullUrl = `${url}/performance/${selectedPerformanceNumber}/task/${selectedTaskNumber}/abolish`

        try {
            const response = await axios.post(fullUrl, {
                IdfNumber: selectedPerformanceIdf,
                IssuerFunctionId: null,

                ReasonId: null,

                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.setState({ loading: false })
                return []
            }
            if (response.status === 200) {
                this.updateAbolishReactivateTaskInStore(selectedTaskNumber, true)
                this.setState({ loading: false, error: false })
            }
        } catch (e) {
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({ error: translate('roles_error'), loading: false })
                    return
                }
            }
            this.setState({ loading: false, error: translate('performance_abolish_task_dialog_error') })
            console.error(e) // eslint-disable-line
        }
    }
    reactivateTaskCall = async (selectedPerformanceNumber, selectedTaskNumber, selectedPerformanceIdf) => {
        const { url, subscriptionKey } = requestData
        const { translate } = this.props
        const fullUrl = `${url}/performance/${selectedPerformanceNumber}/task/${selectedTaskNumber}/reactivate`

        try {
            const response = await axios.post(fullUrl, {
                IdfNumber: selectedPerformanceIdf,
                IssuerFunctionId: null,

                ReasonId: null,

                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.setState({ loading: false })
                return []
            }
            if (response.status === 200) {
                this.updateAbolishReactivateTaskInStore(selectedTaskNumber, false)
                this.setState({ loading: false, error: false, reactivateReason: '', reactivateResponsible: '' })
            }
        } catch (e) {
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({ error: translate('roles_error'), loading: false })
                    return
                }
            }
            this.setState({ loading: false, error: translate('performance_reactivate_task_dialog_error') })
            console.error(e) // eslint-disable-line
        }
    }

    handleClose = () => {
        this.setState({ error: false })
        this.props.handleClose()
    }
    render() {
        const { open, performanceId, taskId, idfNumber, translate, isAbolished, classes } = this.props

        const { error, loading } = this.state
        if (!isAbolished) {
            return (
                <Dialog open={open} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{translate('add_user_abolish')}</DialogTitle>
                    <Paper>
                        <DialogContent className={classes.dialogWidth}>
                            <DialogContentText>{translate('performance_abolish_task_dialog_title')}</DialogContentText>
                            <Typography variant="subtitle1">
                                {translate('performance_abolish_task_dialog_confirm_text')}
                            </Typography>
                            {error && <ErrorMessage spacing={false} error={error} />}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleClose()} color="primary">
                                {translate('add_user_confirm_cancel')}
                            </Button>
                            <Button
                                onClick={() => {
                                    this.abolishTask(performanceId, taskId, idfNumber)
                                }}
                                color="primary"
                                disabled={loading || error}
                            >
                                {translate('add_user_abolish')}
                            </Button>
                        </DialogActions>
                    </Paper>
                </Dialog>
            )
        } else {
            return (
                <Dialog open={open} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{translate('add_user_reactivate')}</DialogTitle>
                    <Paper>
                        <DialogContent className={classes.dialogWidth}>
                            <DialogContentText>{translate('management_drawer_reactivate_task')}</DialogContentText>
                            <Typography variant="subtitle1">
                                {translate('performance_reactivate_task_dialog_confirm_text')}
                            </Typography>
                            {error && <ErrorMessage spacing={false} error={error} />}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleClose()} color="primary">
                                {translate('add_user_confirm_cancel')}
                            </Button>
                            <Button
                                onClick={() => {
                                    this.reactivateTask(performanceId, taskId, idfNumber)
                                }}
                                color="primary"
                                disabled={loading}
                            >
                                {translate('add_user_reactivate')}
                            </Button>
                        </DialogActions>
                    </Paper>
                </Dialog>
            )
        }
    }
}

export default compose(withStyles(styles), withLocalize)(AbolishDialog)
