import axios from 'axios'

export default async data => {
    let fullUrl = `/referencedata/platform/update`
    try {
        return await axios.post(fullUrl, data)
    } catch (e) {
        console.error(e) // eslint-disable-line
        return e.response
    }
}
