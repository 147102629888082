import React, { useEffect } from 'react'
import { object, func } from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withStore, withStatus } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'

import Header from 'components/Header'
import PerformanceThresholdView from './PerformanceThresholdView'
import withFunctionlityCheck from 'utils/hocs/withFunctionalityCheck'

const PerformanceThreshold = props => {
    useEffect(() => {
        document.title = props.translate('module_monitoring')
    }, [props])

    return (
        <React.Fragment>
            <React.Fragment>
                <Header isMenu title={props.translate('module_monitoring')} />
            </React.Fragment>
            <PerformanceThresholdView translate={props.translate} />
        </React.Fragment>
    )
}

PerformanceThreshold.propTypes = {
    history: object.isRequired,
    location: object.isRequired,
    store: object.isRequired,
    __ion_status: object.isRequired,
    match: object.isRequired,
    translate: func,
}

export default compose(withRouter, withStore, withStatus, withLocalize, withFunctionlityCheck)(PerformanceThreshold)
