export default theme => ({
    drawerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
        '@media (max-width: 577px)': {
            width: '85vw',
            padding: `${theme.spacing(1)}px`,
        },

        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    profileInfo: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
    },
    actionIcon: {
        //marginRight: theme.spacing(3),
        cursor: 'pointer',
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
    addOrganisationContainer: {
        width : "100%", 
        display: "block", 
        padding: "16px", 
        paddingBottom: "8px",
        backgroundColor: theme.customColors.expansionBg,
    },
    locationSearcher: {
        width : "100%", 
        alignItems: "center", 
        justifyContent: "center", 
        marginBottom: "0px"
    }
})
