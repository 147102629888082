export default theme => ({
    tabsContainer: {
        backgroundColor: theme.palette.grey['700'],
    },
    badge: {
        marginLeft: 11 + theme.spacing(1),
    },
    redBadge: {
        background: theme.customColors.red,
    },
    yellowBadge: {
        background: 'yellow',
        color: 'black',
    },
    tableSpacing: {
        marginBottom: '16px',
    },
})
