import React from 'react'
import ActionBar from 'components/ActionBar'
import { object, func, bool, string, number, array } from 'prop-types'
import { Button } from '@material-ui/core'
import DateTimePicker from 'components/DateTimePicker'
import styles from './styles'
import { withStyles } from '@material-ui/styles'
// eslint-disable-next-line max-len
import VehicleSearch from 'modules/Performances/views/ReadyForDeparture/components/CompositionView/components/VehicleSearch'

const VehicleDetailsDatePicker = props => {
    const { translate } = props

    return (
        <ActionBar>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ width: '25%', display: 'flex', alignItems: 'center' }}>
                    <VehicleSearch
                        translate={translate}
                        selectedVehicle={props.selectedVehicle}
                        options={props.vehicleList}
                        handleSearchValue={props.handleVehicleSearch}
                    />
                    <Button
                        data-testid="loadButton"
                        style={{ marginLeft: '12px' }}
                        disabled={props.loadButtonActive}
                        variant="contained"
                        color="primary"
                        onClick={() => props.handleLoad()}
                    >
                        {translate('add_task_load_train')}
                    </Button>
                </div>
                <div style={{ display: 'flex' }}>
                    <DateTimePicker
                        identifier={props.identifier}
                        startValue={props.startValue}
                        translate={translate}
                        persist={props.persist}
                        trainView={true}
                        validityId={props.validityId}
                        realTime={props.realTime}
                        toggleRealTime={props.toggleRealTime}
                    />
                </div>
            </div>
        </ActionBar>
    )
}
VehicleDetailsDatePicker.propTypes = {
    classes: object,
    translate: func,
    persist: func,
    handleLoad: func,
    store: object,
    loadButtonActive: bool,
    trainNumber: string,
    startValue: object,
    identifier: string,
    validityId: number,
    realTime: bool,
    toggleRealTime: func,
    vehicleList: array,
    handleVehicleSearch: func,
    selectedVehicle: object,
}
export default withStyles(styles)(VehicleDetailsDatePicker)
