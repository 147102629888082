import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, Column, defaultTableRowRenderer, Table } from 'react-virtualized'
import { Translate } from 'react-localize-redux'
import classNames from 'classnames'
import { Typography } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import { Edit, Delete } from '@material-ui/icons'
import getTranslation from 'utils/returnTranslationForLocale'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

function DefectTypesTable({ classes, data, store, editDefectTypeHandler, deleteDefectTypeHandler }) {
    DefectTypesTable.propTypes = {
        classes: PropTypes.object,
        data: PropTypes.array,
        store: PropTypes.object,
        editDefectTypeHandler: PropTypes.func,
        deleteDefectTypeHandler: PropTypes.func,
    }
    const columns = [
        {
            width: 100,
            label: 'datatable_header_compo_defect_types_code',
            dataKey: 'code',
        },
        {
            width: 400,
            label: 'datatable_header_compo_defect_types_description_nl',
            dataKey: 'descriptionNl',
        },
        {
            width: 400,
            label: 'datatable_header_compo_defect_types_description_fr',
            dataKey: 'descriptionFr',
        },
        {
            width: 200,
            label: 'datatable_header_compo_defect_types_speed_limit',
            dataKey: 'speedLimit',
        },
        {
            width: 200,
            label: 'datatable_header_compo_defect_types_remark_code',
            dataKey: 'remarkCode',
        },
        {
            width: 100,
            label: 'datatable_header_compo_defect_types_brake_damage',
            dataKey: 'brakeDamage',
        },
        {
            width: 100,
            label: 'datatable_header_compo_defect_types_itris_specific',
            dataKey: 'itrisSpecific',
        },
        {
            width: 100,
            label: 'datatable_header_actions',
            dataKey: 'actions',
        },
    ]

    const headerRenderer = headerProps => {
        return (
            <TableCell
                data-testid="dft_header"
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, classes.header)}
                variant="head"
                style={{ height: 50 }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }

    const cellRenderer = cellProps => {
        const { dataKey, rowData } = cellProps
        let dataForDatakey = rowData[dataKey]
        if (dataKey === 'actions') {
            if (
                checkFunctionalityDisabled(
                    store,
                    'manageCompositionDefectTypes',
                    'compositions.functionality.management.compositions'
                )
            ) {
                return (
                    <TableCell
                        data-testid="dk_action_disabled"
                        className={classes.tableCell}
                        component="div"
                        variant="body"
                        style={{ height: 50 }}
                    />
                )
            }
            return (
                <TableCell
                    data-testid="dk_action_enabled"
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: 50 }}
                >
                    <div className={classes.multipleIcons}>
                        <Edit
                            className={classes.editIcon}
                            onClick={() => {
                                editDefectTypeHandler(rowData)
                            }}
                        />
                        <Delete
                            className={classes.deleteIcon}
                            onClick={() => {
                                deleteDefectTypeHandler(rowData.id)
                            }}
                        />
                    </div>
                </TableCell>
            )
        }
        if (dataKey === 'descriptionNl') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                    <Typography>{getTranslation(rowData.description)}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'descriptionFr') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                    <Typography>{getTranslation(rowData.description, 'fr-BE')}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'brakeDamage' || dataKey === 'itrisSpecific') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                    <Typography>{dataForDatakey === true ? 'yes' : 'no'}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'remarkCode') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                    <Typography>{rowData.remarkCode}</Typography>
                </TableCell>
            )
        }
        return (
            <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                <Typography>{dataForDatakey !== null ? dataForDatakey : 'N/A'}</Typography>
            </TableCell>
        )
    }

    if (data && data.length > 0) {
        return (
            <div className={classes.documentTableWrapper}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            className={classes.table}
                            height={height}
                            width={width}
                            rowCount={data.length}
                            rowClassName={classes.tableRow}
                            rowGetter={({ index }) => data[index]}
                            rowRenderer={defaultTableRowRenderer}
                            headerRenderer={headerRenderer}
                            // onRowClick={() => {}}
                            rowHeight={50}
                        >
                            {columns.map(({ dataKey, ...other }) => (
                                <Column
                                    key={dataKey}
                                    headerRenderer={headerRenderer}
                                    className={classes.flexContainer}
                                    cellRenderer={cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            ))}
                        </Table>
                    )}
                </AutoSizer>
            </div>
        )
    } else {
        return <div data-testid="no_data">No Data</div>
    }
}

export default DefectTypesTable
