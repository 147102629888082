import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import IconButton from 'components/IconButton'
import Grid from '@material-ui/core/Grid'
import ErrorMessage from 'components/ErrorMessage'
import { Typography } from '@material-ui/core'
import { feedbackCategoriesContext } from 'modules/FeedbackCategories/store.feedbackCategories'

export const printFormFields = (fastFeedback, register, errors) => {
    return fastFeedback.map(item => {
        const [weight, setWeight] = useState(item.weightFastFeedback)
        return (
            <React.Fragment key={item.id}>
                <Grid item xs={8}>
                    <Typography>{item.fastFeedback[0].translation}</Typography>
                </Grid>
                <Grid item xs={3}>
                    {textField(weight, setWeight, register, errors, item.id)}
                </Grid>
            </React.Fragment>
        )
    })
}

export const textField = (value, callback, register, errors, name) => {
    return (
        <TextField
            data-testid="feedbackform_textfield"
            margin="dense"
            inputRef={register !== undefined ? register({ required: true }) : null}
            error={errors && !!errors[`${name}`]}
            id={name}
            name={name}
            type="number"
            fullWidth
            defaultValue={value}
            onChange={e => {
                callback(e.target.value)
            }}
        />
    )
}

function SortFeedbackForm(props) {
    const { STATE, SERVICES } = useContext(feedbackCategoriesContext)

    const { register, handleSubmit, errors } = useForm()

    const submitTheForm = data => {
        SERVICES.handleSaveSort(data, props.scope)
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(submitTheForm)} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant="subtitle2">Feedback</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle2">Weight</Typography>
                    </Grid>
                    {printFormFields(props.fastFeedback, register, errors)}
                    <Grid item xs={12}>
                        {STATE.sortError && STATE.sortError?.length > 0 && (
                            <ErrorMessage error={props.translate('linda_linda_roles_edit_failed')} spacing={false} />
                        )}
                        <IconButton
                            type="submit"
                            icon="save"
                            title={props.translate('add_user_confirm_save')}
                            disabled={STATE.editSortLoading}
                        />
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

SortFeedbackForm.propTypes = {
    translate: PropTypes.func,
    fastFeedback: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    scope: PropTypes.string,
}

export default SortFeedbackForm
