import React, { useState } from 'react'
import { object } from 'prop-types'

import { Translate } from 'react-localize-redux'
import { withStore, withStatus } from 'react-ion-store'

import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { DateTime } from 'luxon'

import Header from 'components/Header'
import PrimaryButton from 'components/PrimaryButton'

import copyToClipBoard from 'utils/copyToClipBoard'
import DistrictsMapper from 'components/DistrictsMapper'
import SelectPerformanceForCopyAndPaste from './SelectPerformanceForCopyAndPaste'
import getPerformanceNumbers from '../AddNewTask/services/getPerformanceNumbersWithDate'
import getPerformance from './Services/getPerformanceAsJson'
import ErrorMessage from 'components/ErrorMessage'
import styles from './styles'
import withFunctionalityCheck from 'utils/hocs/withFunctionalityCheck'
import withHocs from 'utils/hocs/withHocs'

const PerformanceAsJson = props => {
    const [depotPt, setDepotPt] = useState(' ')
    const [orgPt, setOrgPt] = useState(' ')
    const [depotNamePt, setDepotNamePt] = useState('')
    const [selectedDate, setSelectedDate] = useState(DateTime.local())
    const [performanceNumbersData, setPerformanceNumbersData] = useState([])
    const [selectedPerformanceNumber, setSelectedPerformanceNumber] = useState(' ')
    const [radio, setRadio] = useState('Actual')
    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [isPerformanceLoading, setIsPerformanceLoading] = useState(false)
    const [copied, setCopied] = useState(false)
    const [seletedPerformance, setSeletedPerformance] = useState(-1)
    const [loadingPerformance, setLoadingPerformance] = useState(false)

    const loadPerformanceNumbers = async depot => {
        setLoadingPerformance(true)
        let performanceData = []
        if (depot !== '') {
            performanceData = await getPerformanceNumbers(depot, selectedDate)
        }
        setPerformanceNumbersData(performanceData)
        setLoadingPerformance(false)
    }

    const persist = type => values => {
        if (type === 'orgPt') {
            setPerformanceNumbersData([])
            setOrgPt(values)
            setDepotPt(' ')
        }
        if (type === 'depotPt') {
            setDepotPt(values)
            setSelectedPerformanceNumber(' ')
            setPerformanceNumbersData([])
        }
        if (type === 'radio') setRadio(values)

        if (type === 'depotNamePt') setDepotNamePt(values)
        if (type === 'selectedPerformanceNumber') setSelectedPerformanceNumber(values)

        setData([])
        setError(false)
        setCopied(false)
    }
    const handleEvent = type => e => persist(type)(e.target.value)

    const handleDateChange = e => {
        if (e !== null) {
            setSelectedDate(e)
            setPerformanceNumbersData([])
            setDepotPt(' ')
            setOrgPt(' ')
        }
    }
    const resetCheckAllAndErrors = () => {}
    const loadPerformance = async () => {
        setIsPerformanceLoading(true)
        const perfData = await getPerformance(selectedPerformanceNumber, depotNamePt, selectedDate, radio)
        if (perfData.length) {
            setData(perfData)
            setIsPerformanceLoading(false)
        } else {
            setError(true)
            setIsPerformanceLoading(false)
        }
    }

    const checkLoadDisable = () => selectedPerformanceNumber === ' ' || isPerformanceLoading
    const copyPerfToClipBoard = () => {
        const index = data.length > 1 ? seletedPerformance : 0
        copyToClipBoard(JSON.stringify(data[index]))
        setCopied(true)
    }
    const checkCopyButtonDisable = () => {
        if (data.length > 1) return seletedPerformance === -1 || copied
        return copied || data.length < 1
    }
    const setPerformance = index => {
        setSeletedPerformance(index)
        setCopied(false)
    }
    const { classes } = props
    const loadDisabled = checkLoadDisable()
    const copyButtonDisabled = checkCopyButtonDisable()

    return (
        <Translate>
            {({ translate }) => (
                <div data-testid="root" className={classes.root}>
                    <Header isMenu title={translate('module_performances')} />
                    <DistrictsMapper>
                        <Grid container spacing={2}>
                            <SelectPerformanceForCopyAndPaste
                                depotPt={depotPt}
                                seletedPerformance={seletedPerformance}
                                orgPt={orgPt}
                                data={data}
                                selectedDate={selectedDate}
                                selectedPerformanceNumber={selectedPerformanceNumber}
                                translate={translate}
                                loadingPerformance={loadingPerformance}
                                loadPerformanceNumbers={loadPerformanceNumbers}
                                performanceNumbersData={performanceNumbersData}
                                handleDateChange={handleDateChange}
                                persist={persist}
                                resetCheckAllAndErrors={resetCheckAllAndErrors}
                            />
                        </Grid>
                        <div className={classes.radioGroupParent}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="Gender"
                                    name="radio"
                                    className={classes.radioGroup}
                                    value={radio}
                                    onChange={handleEvent('radio')}
                                >
                                    <FormControlLabel
                                        value="Actual"
                                        control={<Radio color="primary" />}
                                        label={translate('performance_actual')}
                                    />
                                    <FormControlLabel
                                        value="Original"
                                        control={<Radio color="primary" />}
                                        label={translate('performance_original')}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <PrimaryButton
                            className={classes.loadButton}
                            title={translate('add_task_load_train')}
                            onClick={loadPerformance}
                            disabled={loadDisabled}
                        />
                        {error && <ErrorMessage error={'No Data'} spacing={false} />}

                        <div className={classes.textField}>
                            {data.map((obj, index) => (
                                <Typography
                                    className={classes.row}
                                    key={obj.idfNumber}
                                    variant="h6"
                                    onClick={() => setPerformance(index)}
                                >{`${obj.name} -${obj.idfNumber}`}</Typography>
                            ))}
                        </div>

                        <PrimaryButton
                            className={classes.buttonBottom}
                            title={translate('performance_copy_to_clipboard')}
                            onClick={copyPerfToClipBoard}
                            disabled={copyButtonDisabled}
                        />
                    </DistrictsMapper>
                </div>
            )}
        </Translate>
    )
}

PerformanceAsJson.propTypes = {
    classes: object.isRequired,
    store: object.isRequired,
    __ion_status: object.isRequired,
    history: object.isRequired,
}
export default withHocs(withStyles(styles), withStore, withStatus, withFunctionalityCheck)(PerformanceAsJson)
