export const nonDrivingTasksWithCompositionsFilter = code => {
    switch (code) {
        case 'PLAT':
        case 'BEVAP':
        case 'RAMAN LO':
        case 'RAMAN MO':
        case 'RAMAN TC':
        case 'PLANT TC':
        case 'PLANT MO':
        case 'PC-EJ':
        case 'BEGAR':
        case 'INMAIN':
        case 'IDREM':
        case 'VK.PC':
        case 'BK.PR':
        case 'CAR-WASH':
        case 'KOPCP DP':
        case 'ONTDC DP':
        case 'KOPCP':
        case 'ONTDC':
            return true
        default:
            return false
    }
}
