import React, { useEffect } from 'react'
import { object } from 'prop-types'

import { withStyles, Divider, Typography, ListItemIcon } from '@material-ui/core'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import getTitle from '../../util'
import FeedbackCategoryLevelOne from '../FeedbackCategoryLevelOne'

import styles from './styles'
import FeedbackCategoryIcon from '../FeedbackCategoryIcon'
import { feedbackCategoriesContext } from 'modules/FeedbackCategories/store.feedbackCategories'
import { TranslationContext } from 'context/translation'

const FeedbackCategoryGroup = props => {
    const [feedback, setFeedback] = React.useState('')

    const { METHODS } = React.useContext(feedbackCategoriesContext)
    const { translate, activeLanguage } = React.useContext(TranslationContext)

    const { performances, store, classes } = props

    useEffect(() => {
        setFeedback(props.store.get('feedbackcategorygroup') ? props.store.get('feedbackcategorygroup') : '')
    }, [])
    const openFeedback = feedbackCop => {
        store.set({ feedbackcategorygroup: feedbackCop === feedback ? '' : feedbackCop })
        setFeedback(feedbackCop.id === feedback.id ? '' : feedbackCop)
    }
    const handleClick = (e, id) => {
        METHODS.openDrawer(e, '', id)
    }
    return (
        <div style={{ paddingLeft: '32px', paddingBottom: '8px' }}>
            <List>
                {performances &&
                    performances.subCategories.map(performance => (
                        <React.Fragment key={performance.id}>
                            <Paper square elevation={0}>
                                <ListItem button onClick={() => openFeedback(performance)}>
                                    <Typography color={'secondary'}>{performance.weight}</Typography>
                                    <ListItemText
                                        primary={
                                            getTitle(activeLanguage, performance.description) +
                                            (performance.subCategories.length > 0
                                                ? ` (${performance.subCategories.length})`
                                                : '')
                                        }
                                    />
                                    <ListItemIcon>
                                        <FeedbackCategoryIcon
                                            signed={performance.signed}
                                            isFirstDeparture={performance.isFirstDeparture}
                                            e360={performance.e360}
                                        />
                                    </ListItemIcon>
                                    <EditIcon
                                        className={classes.editIcon}
                                        onClick={e => METHODS.openDrawer(e, performance, performances.id)}
                                    />
                                    <ListItemSecondaryAction>
                                        {feedback && feedback.id === performance.id ? (
                                            <ExpandLess className={classes.icon} />
                                        ) : (
                                            <ExpandMore className={classes.icon} />
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Paper>
                            {feedback && feedback.id === performance.id && (
                                <FeedbackCategoryLevelOne
                                    key={performance.id}
                                    data={performance}
                                    translate={translate}
                                ></FeedbackCategoryLevelOne>
                            )}
                            <Divider className={classes.divider}></Divider>
                        </React.Fragment>
                    ))}
            </List>
            <Button variant="contained" color="primary" onClick={e => handleClick(e, performances.id)}>
                {translate('add_feedback_sub_category')}
            </Button>
        </div>
    )
}

FeedbackCategoryGroup.propTypes = {
    performances: object.isRequired,
    classes: object.isRequired,
    store: object,
}

export default withStyles(styles)(FeedbackCategoryGroup)
