// Logic
import React from 'react'
import { compose } from 'recompose'
import { withStore, withStatus, enums } from 'react-ion-store'
import { string, object, func } from 'prop-types'

// Presentation
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import ChevronRight from '@material-ui/icons/ChevronRight'

// Components
import Loading from 'components/Loading'
import SearchError from '../../components/SearchError'

// Assets
import mapKeysToLowerCase from 'utils/mapKeysToLowerCase'

import styles from './styles'
import { chain } from 'lodash'

const { CallStatus } = enums

const FilledSearch = ({ classes, store, type, __ion_status, setDetailView }) => {
    const Loader = () => (
        <div className={classes.container}>
            <Loading />
        </div>
    )

    if (__ion_status.documentSearchResults === CallStatus.LOADING) return <Loader />

    let data = []

    if (type !== undefined) data = store.get('documentSearchResults')
    let groupedData = []
    if (data.length > 0)
        if (type === 'document') {
            groupedData = chain(data)
                .groupBy('name')
                .map((value, key) => ({ id: key, list: value }))
                .value()
        } else {
            groupedData = chain(data)
                .groupBy('idfNumber')
                .map((value, key) => ({ id: key, list: value }))
                .value()
        }

    return groupedData.length > 0 ? (
        <div className={classes.container}>
            <Paper>
                <List className={classes.noPaddingList}>
                    {groupedData.map((_item, index) => {
                        const item = mapKeysToLowerCase(_item)
                        return (
                            <React.Fragment key={index}>
                                <ListItem className={classes.listItem}>
                                    <ListItemText
                                        primary={
                                            type === 'document'
                                                ? item.id
                                                : `${item.list[0].firstName} ${item.list[0].lastName} - ${item.id}`
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            aria-label="Detailview"
                                            onClick={() => setDetailView({ type, data: item.list })}
                                        >
                                            <ChevronRight />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {index < data.length - 1 && <Divider />}
                            </React.Fragment>
                        )
                    })}
                </List>
            </Paper>
        </div>
    ) : (
        <SearchError translateKey="documents_label_no_results" />
    )
}

FilledSearch.propTypes = {
    classes: object.isRequired,
    store: object.isRequired,
    __ion_status: object.isRequired,
    type: string.isRequired,
    setDetailView: func.isRequired,
}

export default compose(withStore, withStatus, withStyles(styles))(FilledSearch)
