import React from 'react'
import { Translate } from 'react-localize-redux'

export const LoadingProfilesInError = () => <Translate id="label_loading" />

export const profilesInErrorText = (profiles, key, lang) => {
    let profileWithError = profiles.filter(profile => profile.districtId === key)
    let num = profileWithError.length > 0 ? profileWithError[0].errorCount : 0
    if (lang.code === 'nl') {
        return (
            <>
                {num}{' '}
                {num === 0 ? (
                    <Translate id="profiles_in_error" />
                ) : num === 1 ? (
                    <Translate id="profile_in_error" />
                ) : (
                    <Translate id="profiles_in_error" />
                )}
            </>
        )
    }
    return (
        <>
            {num} {num > 1 ? <Translate id="profiles_in_error" /> : <Translate id="profile_in_error" />}
        </>
    )
}

export const getLabel = (id, districts, profiles, profilesLoading, theme, lang) => {
    const positions = [
        { left: '100px', top: '150px' },
        { left: '370px', top: '145px' },
        { left: '255px', top: '240px' },
        { left: '185px', top: '340px' },
        { left: '440px', top: '370px' },
    ]

    const defaultStyle = {
        textAlign: 'center',
        position: 'absolute',
        backgroundColor: theme === 'dark' ? '#424242' : 'white',
        color: theme === 'dark' ? 'white' : 'black',
        padding: '5px 10px',
        display: 'block',
        minWidth: '150px',
    }
    if (id === districts[0].id) {
        return (
            <label
                key={id + 'label'}
                style={{
                    ...defaultStyle,
                    left: positions[0].left,
                    top: positions[0].top,
                }}
            >
                {profilesLoading ? LoadingProfilesInError() : profilesInErrorText(profiles, 'DISTRICT-1', lang)}
            </label>
        )
    }
    if (id === districts[1].id) {
        return (
            <label
                style={{
                    ...defaultStyle,
                    left: positions[1].left,
                    top: positions[1].top,
                }}
            >
                {profilesLoading ? LoadingProfilesInError() : profilesInErrorText(profiles, 'DISTRICT-2', lang)}
            </label>
        )
    }
    if (id === districts[2].id) {
        return (
            <label
                style={{
                    ...defaultStyle,
                    left: positions[2].left,
                    top: positions[2].top,
                }}
            >
                {profilesLoading ? LoadingProfilesInError() : profilesInErrorText(profiles, 'DISTRICT-3', lang)}
            </label>
        )
    }
    if (id === districts[3].id) {
        return (
            <label
                style={{
                    ...defaultStyle,
                    left: positions[3].left,
                    top: positions[3].top,
                }}
            >
                {profilesLoading ? LoadingProfilesInError() : profilesInErrorText(profiles, 'DISTRICT-4', lang)}
            </label>
        )
    }
    if (id === districts[4].id) {
        return (
            <label
                style={{
                    ...defaultStyle,
                    left: positions[4].left,
                    top: positions[4].top,
                }}
            >
                {profilesLoading ? LoadingProfilesInError() : profilesInErrorText(profiles, 'DISTRICT-5', lang)}
            </label>
        )
    }
}
