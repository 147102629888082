export default theme => ({
    button: {
        marginRight: theme.spacing(1),
        height: theme.spacing(3) + theme.spacing(1) * 2,
    },
    flexContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    underlined: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    text: { display: 'inline' },
    realTimeButton: {
        marginLeft: theme.spacing(1),
        background: '#424242',
        color: 'black',
        '&:hover': {
            background: '#424242',
            color: '#979797',
        },
    },
    realTimeButtonActive: {
        marginLeft: theme.spacing(1),
        background: 'green',
        '&:hover': {
            background: 'green',
            color: '#979797',
        },
    },

    setDWidth: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    setTWidth: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '85%',

        [theme.breakpoints.down('lg')]: {
            width: '80%',
        },
        [theme.breakpoints.down('md')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
    },
})
