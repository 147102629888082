import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_MONITORING_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}


export const getMonitoringData = async () => {
    const { url, subscriptionKey } = requestData

    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })

    if (response.status === 200) {
        return response.data
    } else {
        return []
    }
    } catch (e) {
        console.error(e) // eslint-disable-line
    }

}
