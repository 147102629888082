import React from 'react'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import UserSearch from './UserSearch'
import UserList from './UserList'
import DeleteDialog from './Dialogs/DeleteUserDialog'
import AddNewUserGroup from './Drawers/AddNewUserGroup'
import AddNewUserDrawer from './Drawers/AddNewUserDrawer'
import FindUserDrawer from './Drawers/FindUserDrawer'

const getGroupName = (groupId, userData) => {
    if (groupId) {
        return userData.length > 0 ? userData.filter(userGroup => userGroup.id === groupId)[0].name : ''
    }
}
const UsersAndRolesView = props => {
    const {
        handleSearchValue,
        searchValue,
        data,
        clearValue,
        drawers,
        handleOpen,
        handleClose,
        isOpen,
        deleteGroupId,
        deleteName,
        setGroupIdForEditAndOpenDrawer,
        resetIdfAndCloseDrawer,
        editUserGroupId,
        translate,
        OrganisationsData,
        checkFunctionalityDisabled,
        handleAddNewUserDrawer,
        handleRedirectToManageEndpoints,
        openAddUserDrawer,
        editUserDrawer,
        addNewUserGroupDrawer,
        resetGroupIdAndCloseDrawer,
        addNewUserToGroupId,
        reloadPage,
        invertLocalState,
        closeFindUserDrawer,
        openFindUserDrawer,
        roleItems,
    } = props
    let userGroupName = getGroupName(addNewUserToGroupId, data)

    return (
        <React.Fragment>
            <Header isMenu title={translate('module_users')} />

            <UserSearch
                {...props}
                handleSearchValue={handleSearchValue}
                searchValue={searchValue}
                clearValue={clearValue}
                handleRedirectToManageEndpoints={handleRedirectToManageEndpoints}
            />
            <UserList
                data={data}
                {...props}
                drawers={drawers}
                handleOpen={handleOpen}
                handleClose={handleClose}
                setGroupIdForEditAndOpenDrawer={setGroupIdForEditAndOpenDrawer}
                OrganisationsData={OrganisationsData}
                translate={translate}
                checkFunctionalityDisabled={checkFunctionalityDisabled}
                roleItems={roleItems}
            />
            {addNewUserGroupDrawer && (
                <AddNewUserGroup
                    reloadPage={reloadPage}
                    open={addNewUserGroupDrawer}
                    onClose={() => handleAddNewUserDrawer(false)}
                    editUserGroupId={editUserGroupId}
                    OrganisationsData={OrganisationsData}
                    roleItems={roleItems}
                />
            )}
            {editUserDrawer && (
                <AddNewUserGroup
                    reloadPage={reloadPage}
                    open={editUserDrawer}
                    editUserGroupId={editUserGroupId}
                    onClose={() => resetIdfAndCloseDrawer()}
                    data={data}
                    OrganisationsData={OrganisationsData}
                    roleItems={roleItems}
                />
            )}
            {openAddUserDrawer && (
                <AddNewUserDrawer
                    open={openAddUserDrawer}
                    onClose={resetGroupIdAndCloseDrawer}
                    addNewUserToGroupId={addNewUserToGroupId}
                    userGroupName={userGroupName}
                    invertLocalState={invertLocalState}
                />
            )}
            {openFindUserDrawer && <FindUserDrawer isOpen={openFindUserDrawer} onClose={closeFindUserDrawer} />}

            <DeleteDialog
                open={isOpen}
                id={deleteGroupId}
                name={deleteName}
                handleClose={handleClose}
                reloadPage={reloadPage}
            />
        </React.Fragment>
    )
}
UsersAndRolesView.propTypes = {
    handleSearchValue: PropTypes.func,
    searchValue: PropTypes.string,
    translate: PropTypes.func,
    clearValue: PropTypes.func,
    handleOpen: PropTypes.func,
    handleClose: PropTypes.func,
    deleteGroupId: PropTypes.string,
    deleteName: PropTypes.string,
    setGroupIdForEditAndOpenDrawer: PropTypes.func,
    resetIdfAndCloseDrawer: PropTypes.func,
    editUserGroupId: PropTypes.string,
    checkFunctionalityDisabled: PropTypes.func,
    data: PropTypes.array,
    drawers: PropTypes.object,
    OrganisationsData: PropTypes.array,
    addNewUserCallLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    addNewuserDrawer: PropTypes.bool,
    editUserDrawer: PropTypes.bool,
    handleAddNewUserDrawer: PropTypes.func,
    handleRedirectToManageEndpoints: PropTypes.func,
    setEditUserDrawer: PropTypes.func,
    addNewUserGroupDrawer: PropTypes.bool,
    addNewUserToGroupId: PropTypes.string,
    resetGroupIdAndCloseDrawer: PropTypes.func,
    openAddUserDrawer: PropTypes.bool,
    reloadPage: PropTypes.func,
    invertLocalState: PropTypes.func,
    openFindUserDrawer: PropTypes.bool,
    closeFindUserDrawer: PropTypes.func,
    roleItems: PropTypes.array,
}
export default UsersAndRolesView
