import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export const doCall = async () => {
    const { url, subscriptionKey } = requestData
    const bearer = window.localStorage.getItem('EDRIVEPORT_ACCESS_TOKEN')
    const fullUrl = `${url}/legacyReferenceData/districts?filterByRoles=true`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
                Authorization: `Bearer ${bearer}`,
            },
        })
        return response
    } catch (e) {
        console.error(e) // eslint-disable-line
    }
}
