import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ErrorMessage from 'components/ErrorMessage'
import getNormalizedMessage from 'utils/getNormalizedMessage'
import { withStyles } from '@material-ui/core/styles'
import { EnforceDeselect } from '../../enums/EnforceDeselect'

import styles from './styles'

import { Paper, Typography } from '@material-ui/core'

export class DeselectedPerformanceDialog extends PureComponent {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        savedDriver: PropTypes.object,
        store: PropTypes.object,
        util: PropTypes.object,
        __ion_status: PropTypes.object,
        responseData: PropTypes.array,
        translate: PropTypes.func,
    }
    assignDriver = (idf, id, performanceIdfNumber, replace, enforceDeselect) => {
        const { util, store } = this.props

        const assignDriverCall = util.CreateStoreObject({ idf, id, performanceIdfNumber, replace, enforceDeselect})
        store.call({ assignDriverCall })
    }

    render() {
        const { open, handleClose, savedDriver, __ion_status, store, responseData, translate } = this.props
        const { assignDriverCall } = __ion_status

        return (
            <Dialog open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
                { /* eslint-disable-next-line max-len */ }
                <DialogTitle id="form-dialog-title">{translate('driver_assign_dialog_deselected_version_attention')}</DialogTitle>
                <Paper>
                    <DialogContent style={{ minWidth: '600px' }}>
                        <Typography variant="subtitle1">
                            {translate('driver_assign_dialog_deselected_version')}
                        </Typography>
                        {/* eslint-disable-next-line max-len */}
                        <Typography variant="subtitle1">{translate('driver_assign_dialog_deselected_version_confirmation')}</Typography>
                        {assignDriverCall === 4 &&
                            store.assignDriverCall !== undefined &&
                            getNormalizedMessage(
                                this.props.store.assignDriverCall._root.entries[2][1].response.data
                            ).indexOf('Profile has an deselected performance') === -1 && (
                                <ErrorMessage spacing={false} error="An Error occured." />
                            )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.assignDriver(
                                    savedDriver.idf,
                                    savedDriver.id,
                                    savedDriver.performanceIdfNumber || null,
                                    true,
                                    EnforceDeselect.NotAssigned
                                )
                            }}
                            color="primary"
                        >
                            {translate('label_no')}
                        </Button>
                        <Button
                            onClick={() => {
                                this.assignDriver(
                                    savedDriver.idf,
                                    savedDriver.id,
                                    savedDriver.performanceIdfNumber || null,
                                    true,
                                    EnforceDeselect.Deselect
                                )
                            }}
                            color="primary"
                            disabled={assignDriverCall === 2 || responseData.IsSelected}
                        >
                            {translate('label_yes')}
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        )
    }
}

export default withStyles(styles)(DeselectedPerformanceDialog)
