import axios from 'axios'
const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
const assignTeacherService = async (teacherIdf, performanceId, studentIdf) => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/performance/${performanceId}/addteacher/${studentIdf}/${teacherIdf}`
    try {
        const response = await axios.post(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 200) {
            return response
        }
    } catch (e) {
        if (e.response.status === 400) {
            return e.response
        } else {
            return 'action not allowed'
        }
    }
}
export default assignTeacherService
