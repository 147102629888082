import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import IconButton from 'components/IconButton'
import Grid from '@material-ui/core/Grid'
import ErrorMessage from 'components/ErrorMessage'

const textField = (label, value, callback, register, errors, name) => {
    return (
        <TextField
            margin="dense"
            inputRef={register !== undefined ? register({ required: true }) : null}
            error={errors && !!errors[`${name}`]}
            id={name}
            name={name}
            label={label}
            type="text"
            fullWidth
            defaultValue={value}
            onChange={e => {
                callback(e.target.value)
            }}
        />
    )
}

function LindaRolesForm(props) {
    const { translate, handleAdd, error, loading, editDrawer, editData } = props

    const [edriveRoleCode, setEdriveRoleCode] = useState(editData?.eDriveRoleCode || '')
    const [descriptionNl, setDescriptionNl] = useState(editDrawer ? editData?.description[1]?.translation || '' : '')
    const [descriptionFr, setDescriptionFr] = useState(editDrawer ? editData?.description[0]?.translation || '' : '')
    const [alascaCode, setAlascaCode] = useState(editData?.alascaCode || '')
    const [lindaRoleCode, setLindaRoleCode] = useState(editData?.lindaRoleCode || '')

    const { register, handleSubmit, errors } = useForm()

    const submitTheForm = data => {
        handleAdd(data, editDrawer)
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(submitTheForm)} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {textField('eDrive code', edriveRoleCode, setEdriveRoleCode, register, errors, 'edriveRoleCode')}
                    </Grid>
                    <Grid item xs={12}>
                        {textField('Description NL', descriptionNl, setDescriptionNl, register, errors, 'descriptionNl')}
                    </Grid>
                    <Grid item xs={12}>
                        {textField('Description FR', descriptionFr, setDescriptionFr, register, errors, 'descriptionFr')}
                    </Grid>
                    <Grid item xs={12}>
                        {textField('Alasca code', alascaCode, setAlascaCode, register, errors, 'alascaCode')}
                    </Grid>
                    <Grid item xs={12}>
                        {textField('Code', lindaRoleCode, setLindaRoleCode, register, errors, 'lindaRoleCode')}
                    </Grid>
                    <Grid item xs={12}>
                        {error && error?.length > 0 && (
                            <ErrorMessage
                                error={
                                    editDrawer
                                        ? translate('linda_linda_roles_edit_failed')
                                        : translate('linda_linda_roles_add_failed')
                                }
                                spacing={false}
                            />
                        )}
                        {editDrawer && (
                            <IconButton
                                type="submit"
                                icon="save"
                                title={translate('add_user_confirm_save')}
                                disabled={loading}
                            />
                        )}
                        {editDrawer === false && (
                            <IconButton icon="save" type="submit" title={translate('button_add')} disabled={loading} />
                        )}
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

LindaRolesForm.propTypes = {
    getTranslation: PropTypes.func,
    translate: PropTypes.func,
    handleAdd: PropTypes.func,
    error: PropTypes.string,
    editDrawer: PropTypes.bool,
    loading: PropTypes.bool,
    editData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default LindaRolesForm
