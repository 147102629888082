import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import TaskDetailView from '../TaskDetails/index'
import NoRights from 'modules/NoRights'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import { isIdfSameAsLoggedInUser } from 'utils/isAgendaAllowed'
import getTimeTableById from './services/getTimeTableById'

const PerformanceTaskDetailView = props => {
    const {
        match,
        history,
        isOpen,
        handleClose,
        handleAbolishClose,
        handleOpen,
        handleAbolishOpen,
        isAbolishOpen,
        performanceDetail: {
            tasks,
            duration,
            code,
            performanceId,
            idfNumber,
            reasonStartTimeChanged,
            reasonEndTimeChanged,
            signOutTime,
            performanceDate,
        },
        refreshAfterBulkOperation,
    } = props

    const [currentTask, setCurrentTask] = useState(null)
    const [isDriveDetailsLoading, setIsDriveDetailsLoading] = useState(false)
    const [currentDriveDetails, setCurrentDriveDetails] = useState([])

    useEffect(() => {
        if (
            tasks !== undefined &&
            tasks !== null &&
            tasks.length > 0 &&
            match.params.taskIndex !== undefined &&
            match.params.taskIndex !== null &&
            match.params.taskIndex !== ''
        )
            loadTaskData(match.params.taskIndex)
    }, [match.params.taskIndex])

    useEffect(() => {
        if (currentTask !== undefined && currentTask !== null) {
            loadTimeTable()
        }
    }, [currentTask])

    const loadTaskData = taskIndex => {
        const single = tasks.find(task => task.id === taskIndex)
        setCurrentTask(single)
    }

    const loadTimeTable = async () => {
        let code = currentTask.code.toUpperCase()
        if (code === 'DRIVE' || code === 'UITGAR' || code === 'PERQUAI' || code === 'TRANSFER') {
            setIsDriveDetailsLoading(true)
            let tt = await getTimeTableById(performanceId, currentTask.id, idfNumber, true)
            setCurrentDriveDetails(tt.driveDetails)
            setIsDriveDetailsLoading(false)
        } else {
            setCurrentDriveDetails([])
        }
    }

    return (
        <React.Fragment>
            {isIdfSameAsLoggedInUser(idfNumber, props.storage) ||
            !checkFunctionalityDisabled(
                props.storage,
                'taskDetail',
                'performances.functionality.management.performanceDetail'
            ) ? (
                <React.Fragment>
                    {currentTask !== null && (
                        <TaskDetailView
                            isOpen={isOpen}
                            isAbolishOpen={isAbolishOpen}
                            handleClose={handleClose}
                            handleAbolishClose={handleAbolishClose}
                            handleAbolishOpen={handleAbolishOpen}
                            handleOpen={handleOpen}
                            performanceId={performanceId}
                            currentTask={match.params.taskIndex}
                            isDriveDetailsLoading={isDriveDetailsLoading}
                            driveDetails={currentDriveDetails}
                            tasks={tasks}
                            task={currentTask}
                            storage={props.storage}
                            duration={duration}
                            code={code}
                            history={history}
                            idfNumber={idfNumber}
                            reasonStartTimeChanged={reasonStartTimeChanged}
                            reasonEndTimeChanged={reasonEndTimeChanged}
                            signOutTime={signOutTime}
                            performanceDate={performanceDate}
                            refreshAfterBulkOperation={refreshAfterBulkOperation}
                        />
                    )}
                </React.Fragment>
            ) : (
                <NoRights />
            )}
        </React.Fragment>
    )
}

PerformanceTaskDetailView.propTypes = {
    match: PropTypes.object.isRequired,
    performanceDetail: PropTypes.object.isRequired,
    history: PropTypes.any,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleOpen: PropTypes.func,
    handleAbolishClose: PropTypes.func,
    handleAbolishOpen: PropTypes.func,
    isAbolishOpen: PropTypes.bool,
    storage: PropTypes.any,
    refreshAfterBulkOperation: PropTypes.func,
}

export default compose(withRouter)(PerformanceTaskDetailView)
