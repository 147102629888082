import axios from 'axios'
const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export const getPtdesKmMarkdata = async () => {
    const { url, subscriptionKey } = requestData
    let fullUrl = `${url}/legacyReferenceData/ptdeskmmark`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })

        return response.data
    } catch (e) {
        return []
    }
}

export default getPtdesKmMarkdata
