import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Paper } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import { TranslationContext } from 'context/translation'
import getRivValues from 'modules/Manage/Services/getRivValues'
import Loading from 'components/Loading'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import updateRivValues from 'modules/Manage/Services/updateRivValues'

const RivSettingsModal = ({ open, handleClose }) => {
    const { translate } = React.useContext(TranslationContext)
    const [isLoading, setIsLoading] = useState(false)
    const [rivValues, setRivValues] = useState(null)
    const properties = [
        { property: 'onSelectPerformance', text: translate('riv_before_selection_text') },
        {
            property: 'afterOrOnSelectionBeforeTaskStart',
            text: translate('riv_before_task_start_text'),
        },
        { property: 'afterTaskStartBeforeDriveMode', text: translate('riv_before_drive_mode_text') },
        { property: 'inDriveMode', text: translate('riv_in_drive_mode_text') },
        {
            property: 'forceRivLoadUponNoPlanned',
            text: translate('riv_force_text'),
        },
        { property: 'noDriveMode', text: translate('riv_no_drive_mode') },
    ]

    useEffect(() => {
        if (open) {
            loadRivValues()
        }
    }, [open])

    const loadRivValues = async () => {
        try {
            setIsLoading(true)
            let data = (await getRivValues()).data
            setRivValues(data)
            setIsLoading(false)
        } catch {
            setIsLoading(false)
        }
    }

    const onSwitchChange = prop => {
        setRivValues({
            ...rivValues,
            [prop]: !rivValues[prop],
        })
    }

    const handleSave = async () => {
        await updateRivValues(rivValues)
        handleClose()
    }

    return (
        <Dialog data-testid="riv_settings_modal" open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{translate('manage_switch_db_label')}</DialogTitle>
            <Paper>
                <DialogContent style={{ minWidth: '600px' }}>
                    <div style={{ marginBottom: '24px' }}>
                        {isLoading && <Loading />}
                        {!isLoading &&
                            rivValues !== null &&
                            properties.map((p, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <div style={{ width: '30%' }}>
                                            <Typography>
                                                {translate('manage_switch_label_off')}
                                                <Switch
                                                    checked={rivValues[p.property]}
                                                    onChange={() => onSwitchChange(p.property)}
                                                    color="primary"
                                                />
                                                {translate('manage_switch_label_on')}
                                            </Typography>
                                        </div>
                                        <div style={{ width: '70%' }}>
                                            <Typography>{p.text}</Typography>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button data-testid="close_button" onClick={() => handleClose()} color="primary">
                        {translate('manage_switch_dialog_cancel')}
                    </Button>
                    <Button data-testid="handle_button" onClick={() => handleSave()} color="primary">
                        {translate('manage_switch_dialog_apply')}
                    </Button>
                </DialogActions>
            </Paper>
        </Dialog>
    )
}

RivSettingsModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
}

export default RivSettingsModal
