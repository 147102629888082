import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { func, bool, number, object } from 'prop-types'
import { Button, DialogActions, Typography } from '@material-ui/core'
const VehicleSearchDialog = props => {
    return (
        <Dialog onClose={props.onClose} PaperProps={{ square: true }} open={props.open}>
            <DialogTitle id="responsive-dialog-title">{'Select Vehicle'}</DialogTitle>
            <DialogContent>
                <Typography>
                    {props.translate('compo_add_littera_dialog_title_1') +
                        props.vehicleNumber +
                        props.translate('compo_add_littera_dialog_title_2')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.addSingleLitteraVehicle(props.litteraToAdd)} color="primary">
                    {props.translate('compo_add_single_littera_button')}
                </Button>
                <Button onClick={() => props.addCompleteLittera(props.litteraToAdd)} color="primary">
                    {props.translate('compo_add_complete_littera_button')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
VehicleSearchDialog.propTypes = {
    open: bool,
    onClose: func,
    translate: func,
    vehicleNumber: number,
    addCompleteLittera: func,
    addSingleLitteraVehicle: func,
    litteraToAdd: object,
}
export default VehicleSearchDialog
