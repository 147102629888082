export default theme => ({
    infoContainer: {
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        display: 'grid',
        placeItems: 'center',
    },
    versionContainer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        },
        display: 'grid',
        placeItems: 'center',
    },
    gridContainer: {
        margin: 0,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    flexRight: {
        justifyContent: 'flex-end !important',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    flexContainer: {
        display: 'flex',
        alignContent: 'flex-start',
        alignItems: 'center',
    },
    buttonContainer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },

        columnGap: '5px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        },
    },
    innerDiv: {
        display: 'flex',
        columnGap: '5px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            display: 'grid',
        },
    },
    button: {
        marginTop: '0px',
        [theme.breakpoints.down('md')]: {
            marginTop: '5px',
        },
        width: '100%',
    },
})
