import React, { useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { util } from 'react-ion-store'
import { any, func, bool, string, object } from 'prop-types'

import axios from 'axios'
import DeleteDialog from 'components/DeleteDialog'

const DeleteTaskDialog = props => {
    DeleteTaskDialog.propTypes = {
        performanceDetailPage: bool,
        storage: any,
        history: any,
        open: bool.isRequired,
        handleClose: func.isRequired,
        performanceId: string,
        taskId: string.isRequired,
        idfNumber: string,
        translate: func,
        classes: object,
    }

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    const deleteTask = () => {
        const { performanceDetailPage } = props
        setLoading(true)
        doDeleteTaskCall(performanceDetailPage)
    }

    const doDeleteTaskCall = async (performanceDetailPage = false) => {
        const { history } = props

        let fullUrl = `/performance/${performanceId}/task/${taskId}/delete`

        if (idfNumber && idfNumber !== null) {
            fullUrl = `/performance/${performanceId}/task/${taskId}/delete?idfNumber=${idfNumber}`
        }

        try {
            const response = await axios.post(fullUrl)

            if (response.status === 200) {
                setError(false)
                setLoading(false)
                if (performanceDetailPage) {
                    props.handleClose()
                    let redirectUrl = window.location.pathname
                    const first = redirectUrl.slice(0, redirectUrl.lastIndexOf('/'))
                    const newUrl = first.slice(0, first.lastIndexOf('/'))

                    await updateDeletedTaskInStore()
                    history.push(newUrl)
                    return
                } else {
                    updateDeletedTaskInStore()
                    props.handleClose()
                }
            }
        } catch (e) {
            console.error(e) // eslint-disable-line
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    setError(true)
                    setLoading(false)
                    return
                }
            }
            setError(true)
        }
    }

    const updateDeletedTaskInStore = async () => {
        let oldPerformanceDetail = props.storage.get('performanceDetail')
        oldPerformanceDetail.tasks = oldPerformanceDetail.tasks.filter(task => task.id !== taskId)
        util.CreateStoreObject(oldPerformanceDetail)
    }

    const { open, handleClose, performanceId, taskId, idfNumber, translate } = props

    return (
        <DeleteDialog
            dialogOpen={open}
            onClose={handleClose}
            title={translate('add_user_delete')}
            translate={translate}
            handleDelete={deleteTask}
            deleteMsg={translate('performance_delete_task_dialog_title')}
            error={error}
            errorMsg={translate('performance_delete_task_dialog_error')}
            deleteInfo={translate('performance_delete_task_dialog_confirm_text')}
            loading={loading}
        />
    )
}

export default withLocalize(DeleteTaskDialog)
