const damages = [
    { code: 1, display: { hasBrakeDistributor: true }, ltCode: [] },
    { code: 2, display: { hasPneumaticSuspension: true }, ltCode: [] },
    { code: 3, display: { hasPneumaticSuspension: true }, ltCode: [] },
    { code: 4, display: '', ltCode: [3, 6, 7] },
    { code: 5, display: '', ltCode: [3, 6, 7] },
    { code: 6, display: { hasBrakeDistributor: true }, ltCode: [] },
    { code: 7, display: { hasBrakePerBogie: true }, ltCode: [] },
    { code: 8, display: { hasBrakePerBogie: true }, ltCode: [] },
    { code: 9, display: { hasHighPressureBrake: true }, ltCode: [] },
    { code: 10, display: { hasElectroPneumaticBrake: true, hasDynamicBrake: true }, ltCode: [3, 6] },
    { code: 11, display: { hasMagnetBrake: true }, ltCode: [] },
    { code: 12, display: { hasDynamicBrake: true }, ltCode: [] },
    { code: 13, display: { hasDriverCockpit: true }, ltCode: [] },
    { code: 14, display: { hasDriverCockpit: true }, ltCode: [] },
]
export default damages
