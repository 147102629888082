import React from 'react'
import { compose, withHandlers } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore } from 'react-ion-store'
import { object, array } from 'prop-types'
import { withStyles } from '@material-ui/core'

// Components
import DepotFilterView from './DepotFilterView'

import styles from './styles'

class DepotFilter extends React.Component {
    static propTypes = {
        tcts: array,
        store: object,
    }

    render() {
        const filteredTCTs = this.props.tcts.filter(tct => this.props.store.get('selectedTCTs').includes(tct.id))
        let filteredDepots = []
        filteredTCTs.forEach(tct => {
            filteredDepots = filteredDepots.concat(tct.depots)
        })

        return <DepotFilterView {...this.props} depots={filteredDepots} />
    }
}

export default compose(
    withStyles(styles),
    withLocalize,
    withStore,
    withHandlers({
        onTCTSelected: props => id => {
            const { selectedTCTs: _selectedTCTs } = props.store.get(['selectedTCTs', 'selectedDepot'])
            const { saveScrollPosition } = props

            let selectedTCTs = _selectedTCTs.includes(id)
                ? _selectedTCTs.filter(tct => tct !== id)
                : [id].concat(_selectedTCTs)

            let filteredDepots = props.tcts.reduce(
                (_filteredDepots, tct) =>
                    selectedTCTs.includes(tct.id) ? _filteredDepots.concat(tct.depots) : _filteredDepots,
                []
            )

            let selectedDepot = ['performances_label_all_depots']

            filteredDepots.map(depot => {
                return selectedDepot.push(depot.id)
            })

            // _selectedDepot !== "performances_label_all_depots" &&
            // filteredDepots.findIndex(depot => depot.id === _selectedDepot) < 0
            //   ? selectedDepot.push("performances_label_all_depots")
            //   : selectedDepot.push(_selectedDepot);

            saveScrollPosition(0)
            props.store.set({
                selectedTCTs,
                selectedDepot,
            })
        },
        onDepotSelected: props => (event, index, items) => {
            props.saveScrollPosition(0)
            //select all items
            if (
                (index.key === '.$performances_label_all_depots' || items.length - 1 === event.target.value.length) &&
                !index.props.children[0].props.checked
            ) {
                let itemsArray = []
                // eslint-disable-next-line array-callback-return
                items.map(item => {
                    itemsArray.push(item.id)
                })
                props.store.set({ selectedDepot: itemsArray })
                return
            }
            //deselect all items
            if (index.key === '.$performances_label_all_depots' && index.props.children[0].props.checked) {
                props.store.set({ selectedDepot: [] })
                return
            }
            //deselect all depots item when unchecking another item
            if (index.key !== '.$performances_label_all_depots' && items.length - 1 === event.target.value.length) {
                const newProps = event.target.value.filter(value => value !== 'performances_label_all_depots')
                props.store.set({ selectedDepot: newProps })
                return
            }
            props.store.set({ selectedDepot: event.target.value })
        },
    })
)(DepotFilter)
