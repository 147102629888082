import { grey, blue } from '@material-ui/core/colors'

export default theme => ({
    roundedRed: {
        width: '30px',
        height: '30px',
        paddingTop: '6px',
        paddingBottom: '6px',
        borderRadius: '50%',
        backgroundColor: theme.customColors.red,
        display: 'inline-block',
        textAlign: 'center',
        fontSize: '14px',
    },
    roundedYellow: {
        width: '30px',
        height: '30px',
        paddingTop: '6px',
        paddingBottom: '6px',
        borderRadius: '50%',
        backgroundColor: 'yellow',
        color: 'black',
        display: 'inline-block',
        textAlign: 'center',
        fontSize: '14px',
    },
    customInput: {
        backgroundColor: grey['600'],
        height: '35px',
        minWidth: '200px',
        border: `2px solid ${grey['700']}`,
        fontSize: '14px',
        color: theme.customColors.white,
        fontFamily: 'roboto',
        paddingLeft: '8px',
        '&::placeholder': {
            color: `${grey['400']}`,
        },
        '&:focus': {
            outline: 'none',
            borderBottom: `2px solid ${blue['500']}`,
        },
    },
    container: {
        marginLeft: '12px',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 577px)': {
            fontSize: '0.7rem',
        },
    },
    tabletContainer: {
        marginLeft: '12px',
        display: 'block',
        alignItems: 'center',
    },
    labelContainer: { display: 'inline-block', marginLeft: '12px' },
    tabletLabelContainer: { display: 'none' },
    yellowLabelMargin: {
        marginTop: '5px',
    },
    tabletYellowLabelContainer: {
        marginLeft: '5px',
    },
})
