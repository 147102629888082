import React, { Component } from 'react'
import { withStore } from 'react-ion-store'
import UserSearchView from './UserSearchView'

class UserSearch extends Component {
    render() {
        return <UserSearchView {...this.props} />
    }
}
export default withStore(UserSearch)
