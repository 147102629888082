import React from 'react'
import { any, func } from 'prop-types'
import { Router, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { withLocalize } from 'react-localize-redux'
import { renderToStaticMarkup } from 'react-dom/server'
import { compose } from 'recompose'
import { withStore, setBaseURL, setDefaultHeaders } from 'react-ion-store'

// Material UI
import CssBaseline from '@material-ui/core/CssBaseline'

// Components
import ModuleRouter from 'modules'
import ReloadToast from 'components/ReloadToast'

// Translations
import globalTranslations from 'translations/global.json'

import translationsMapper from 'utils/translationsMapper'

// Theme

import ThemeProvider from 'theme/ThemeProvider'

const history = createBrowserHistory()

//configure languages and set default language to nl, if there's no language chosen in the past
const languages = ['nl', 'fr']
const defaultLanguage = localStorage.getItem('language') || languages[0]

class App extends React.Component {
    static propTypes = {
        initialize: func.isRequired,
        store: any.isRequired,
    }

    constructor(props) {
        super(props)

        const authKeys = ['auth', 'subKey', 'apiPath', 'accessToken']
        const { subKey, apiPath, accessToken } = props.store.get(authKeys)
        setBaseURL(apiPath)
        setDefaultHeaders({
            'Ocp-Apim-Subscription-Key': subKey,
            Authorization: `bearer ${accessToken}`,
        })

        //initialize localisation, with the defaultLanguage or language from localstorage to persist
        this.props.initialize({
            languages,
            translation: translationsMapper(globalTranslations),
            options: { renderToStaticMarkup, defaultLanguage },
        })
    }

    render() {
        return (
            <ThemeProvider>
                <>
                    <CssBaseline />
                    <Router history={history}>
                        <Route path="/" component={ModuleRouter} />
                    </Router>
                    <ReloadToast />
                </>
            </ThemeProvider>
        )
    }
}

export default compose(withStore, withLocalize)(App)
