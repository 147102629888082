import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import IconButton from 'components/IconButton'
import Grid from '@material-ui/core/Grid'
import ErrorMessage from 'components/ErrorMessage'

const textField = (label, value, callback, register, errors, name) => {
    return (
        <TextField
            margin="dense"
            inputRef={register !== undefined ? register({ required: true }) : null}
            error={errors && !!errors[`${name}`]}
            id={name}
            name={name}
            label={label}
            type="text"
            fullWidth
            defaultValue={value}
            onChange={e => {
                callback(e.target.value)
            }}
        />
    )
}

function PlatformForm(props) {
    const { translate, handleAdd, error, editDrawer, editData } = props
    const [descriptionFr, setDescriptionFr] = useState(editDrawer ? editData?.platformName[0].translation || '' : '')
    const [descriptionNl, setDescriptionNl] = useState(editDrawer ? editData?.platformName[1].translation || '' : '')

    const { register, handleSubmit, errors } = useForm()

    const submitTheForm = data => {
        handleAdd(data)
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(submitTheForm)} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {textField('Description NL', descriptionNl, setDescriptionNl, register, errors, 'platformNameNl')}
                    </Grid>
                    <Grid item xs={12}>
                        {textField('Description FR', descriptionFr, setDescriptionFr, register, errors, 'platformNameFr')}
                    </Grid>

                    <Grid item xs={12}>
                        {error && error?.length > 0 && (
                            <ErrorMessage
                                error={editDrawer ? translate('platform_edit_failed') : translate('platform_add_failed')}
                                spacing={false}
                            />
                        )}
                        {editDrawer && <IconButton type="submit" icon="save" title={translate('add_user_confirm_save')} />}
                        {editDrawer === false && <IconButton icon="save" type="submit" title={translate('button_add')} />}
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

PlatformForm.propTypes = {
    translate: PropTypes.func,
    handleAdd: PropTypes.func,
    error: PropTypes.string,
    editDrawer: PropTypes.bool,
    editData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default PlatformForm
