import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

const DelayIndicator = ({ classes, delay }) => <span className={classes.indicator}>{delay} min</span>

DelayIndicator.propTypes = {
    classes: PropTypes.object.isRequired,
    delay: PropTypes.number,
}

export default withStyles(styles)(DelayIndicator)
