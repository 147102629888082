import React  from 'react'
import { withStore } from 'react-ion-store'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import Header from 'components/Header'

import styles from './styles'
import withHocs from 'utils/hocs/withHocs'
import { TranslationContext } from 'context/translation'
import getSpeedAdvicesSwitchValue from './Services/getSpeedAdvicesSwitchValue'
import updateSpeedAdvicesSwitchValue from './Services/updateSpeedAdvicesSwitchValue'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import Loading from 'components/Loading'


const SpeedAdvicesSwitch = (props) => {
    const { translate } = React.useContext(TranslationContext)
    const [speedAdvicesValue, setSpeedAdvicesValue] = React.useState(null)
    const [speedSettingLoading, setSpeedSettingLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    React.useEffect(() => {
        loadData()
        document.title = translate('module_espeed')
    }, [])


    const loadData = async () => {
        setSpeedSettingLoading(true)
        setError(null)
        var response = await getSpeedAdvicesSwitchValue()
        if (response.status === 200) {
            setSpeedAdvicesValue(response.data.value)
        }
        else{
            setError("Error retrieving data!")
        }
        setSpeedSettingLoading(false)
    }

    const onSwitchChange = async () => {
        if((props.roles && props.roles.eSpeed && props.roles.eSpeed.write === true) ||
            (Array.isArray(props.roles.eSpeed.write) && props.roles.eSpeed?.write.length > 0)){
                setSpeedSettingLoading(true)
                await updateSpeedAdvicesSwitchValue(!speedAdvicesValue)
                loadData()
            } 
    }



    return (
        <React.Fragment>
            <Header isMenu title={translate('module_espeed')} />
            {speedSettingLoading ? <Loading></Loading> : <>
            {error != null ? 
            <p style={{marginLeft: '8px'}}>{error}</p> 
            :
            <div className={props.classes.submenu}>
                <Typography style={{marginBottom: '16px'}} variant="h6">Display speed advices</Typography>
                <div style={{marginLeft: '16px'}}>
                    <Typography variant="subtitle1">API speed advices</Typography>
                    <Typography>
                        {translate('manage_switch_label_off')}
                        <Switch
                            style={((props.roles && props.roles.eSpeed && props.roles.eSpeed.write === true) ||
                                (Array.isArray(props.roles.eSpeed.write) && props.roles.eSpeed?.write.length > 0)) ? null : 
                                {cursor: 'unset', backgroundColor: 'transparent'}}
                                checked={speedAdvicesValue}
                                onChange={() => onSwitchChange()}
                                color="primary"
                                />
                        {translate('manage_switch_label_on')}
                    </Typography>
                </div>
            </div>
            }
            </>
            }
        </React.Fragment>
    )
}

SpeedAdvicesSwitch.propTypes = {
    translate: PropTypes.func,
    store: PropTypes.object,
    history: PropTypes.object,
    __ion_status: PropTypes.object,
    roles: PropTypes.object,
    classes: PropTypes.object,
}

export default withHocs(withStore, withStyles(styles))(SpeedAdvicesSwitch)
