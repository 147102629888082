import React from 'react'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'

import { func, object, string, number, array, bool } from 'prop-types'
import classNames from 'classnames'
import axios from 'axios'
import { withLocalize, Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'

import { withStyles, Dialog, DialogTitle } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { Edit, Delete } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import MapIcon from '@material-ui/icons/Map'
import Checkbox from '@material-ui/core/Checkbox'
import mapValues from 'lodash/mapValues'

import Header from 'components/Header'
import Loading from 'components/Loading'
import StyledTooltip from 'components/StyledTooltip'

import PtcarDrawerView from './components/PtcarDrawerView'
import DeleteDialog from './components/DeletePtcarDialog'
import LineDialog from './components/LineDialog'
import getAllLocations from '../../Performances/Services/getAllLocations'
import PtcarTable from './PtcarTable'
import LindaDrawerView from './components/LindaDrawerView'
import { getPtcarsByLineNumber } from './getLinesInformation'

import styles from './styles'
import getPtcarClassification from '../Services/getPtcarClassification'
import getPtcarTypes from '../Services/getPtcarTypes'
import Snackbar from '@material-ui/core/Snackbar'
import recalculateSemes from '../Services/recalculateSemes'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
const seprator = '-'
const columns = [
    {
        width: 50,
        label: 'datatable_header_actions',
        dataKey: 'checkbox',
    },
    {
        width: 100,
        label: 'datatable_header_ptcar_line',
        dataKey: 'ptCarKmMarks',
    },
    {
        width: 100,
        label: 'datatable_header_ptcar_milestone',
        dataKey: 'milestone',
    },
    {
        width: 100,
        label: 'datatable_header_ptcar_code',
        dataKey: 'code',
    },
    {
        width: 250,
        label: 'datatable_header_ptcar_commercialName',
        dataKey: 'commercialName',
    },
    {
        width: 150,
        label: 'datatable_header_ptcar_uic',
        dataKey: 'uic',
    },
    {
        width: 250,
        label: 'datatable_header_ptcar_crossing',
        dataKey: 'crossings',
    },
    {
        width: 100,
        label: 'datatable_header_ptcar_latitude',
        dataKey: 'latitude',
    },
    {
        width: 100,
        label: 'datatable_header_ptcar_longitude',
        dataKey: 'longitude',
    },
    {
        width: 100,
        label: 'datatable_header_ptcar_innerBox',
        dataKey: 'innerBox',
    },
    {
        width: 100,
        label: 'datatable_header_ptcar_outerBox',
        dataKey: 'outerBox',
    },
    {
        width: 180,
        label: 'datatable_header_actions',
        dataKey: 'actions',
    },
]

class PtcarListView extends React.Component {
    state = {
        data: [],
        isOpen: false,
        filteredData: [],
        openDialog: false,
        rowData: {},
        status: 0,
        lineFilterValue: '',
        searchFilterValue: '',
        deleteRowData: [],
        deleteError: '',
        lineDialogOpen: false,
        lineError: '',
        loadingData: false,
        lineSearchValue: '',
        selectedLineSearchItem: { code: '', label: '' },
        checkedTasks: [],
        checkAll: false,
        openLindaDrawer: false,
        lindaSettingDates: {
            registerToLinda: null,
            vgProcedureStarted: null,
            vgExecuted: null,
            departureOnHold: null,
            personWithLimitedPossibility: null,
            extraLindaStop: null,
            notifyAll: null,
            receiveAll: null,
            displayIconuitgesteldvertrek: null,
        },
        lindaSettingUpdateError: '',
        ptcarTypes: [],
        ptcarClassifications: [],
        deleteOnGoing: false,
        toast: {
            open: false,
            msg: '',
        },
        showSemesPopup: false,
    }

    static propTypes = {
        classes: object.isRequired,
        rowClassName: string,
        onRowClick: func,
        headerHeight: number,
        rowHeight: number,
        activeLanguage: object,
        history: object,
        match: object,
        lineNumberData: array,
        translate: func,
        lineFilterValue: string,
        searchFilterValue: string,
        data: array,
        filteredData: array,
        useInfrabelUrl: bool,
    }
    closeToast = () => this.setState({ toast: { open: false } })

    mapDataForLines = data => {
        let newData = []
        data.map(item => {
            if (item.ptCarKmMarks && item.ptCarKmMarks.length === 0) {
                newData.push({ lineIndex: null, ...item })
            }

            if (item.ptCarKmMarks && item.ptCarKmMarks.length > 0) {
                item.ptCarKmMarks.forEach((lineNumber, index) => {
                    return newData.push({ lineIndex: index, ...item })
                })
            }
        })
        return newData
    }

    loadPtcars = async () => {
        let data = []
        let mappedData = []

        this.setState({ loadingData: true })

        data = await getAllLocations()

        if (data && data.length) {
            mappedData = this.mapDataForLines(data)
        }
        this.setState({
            data: mappedData,
            loadingData: false,
        })

        if (this.state.lineFilterValue) {
            let filteredData = this.state.data.filter(item => {
                return item.lineIndex !== null
                    ? item.ptCarKmMarks[item.lineIndex].lineName.toLowerCase() ===
                          this.state.lineFilterValue.toString().toLowerCase()
                    : null
            })
            this.setState({ filteredData: filteredData })
        } else if (this.state.searchFilterValue) {
            let filteredString = this.state.searchFilterValue
            let filteredData = this.state.data.filter(
                item =>
                    item.code.toLowerCase().includes(filteredString.toLowerCase()) ||
                    item.uic.includes(filteredString) ||
                    this.getCommercialName(this.props.activeLanguage, item.commercialName)
                        .toLowerCase()
                        .includes(filteredString.toLowerCase())
            )

            this.setState({ filteredData: filteredData })
        }
    }
    loadPtcarTypes = async () => {
        const response = await getPtcarTypes()
        const { activeLanguage } = this.props
        if (response.status === 204) {
            this.setState({ ptcarTypes: [] })
            return
        }
        if (response.status === 200) {
            let types = response.data.map(({ id, description }) => ({
                id: parseInt(id),
                text: activeLanguage === 'fr' ? description[0]?.translation : description[1]?.translation,
            }))
            this.setState({
                ptcarTypes: types,
            })
        }
    }
    loadPtcarClassifications = async () => {
        const response = await getPtcarClassification()
        const { activeLanguage } = this.props
        if (response.status === 204) {
            this.setState({ ptcarClassifications: [] })
            return
        }
        if (response.status === 200) {
            let classfications = response.data.map(({ id, shortName }) => ({
                id: parseInt(id),
                text: activeLanguage === 'fr' ? shortName[0]?.translation : shortName[1]?.translation,
            }))

            this.setState({
                ptcarClassifications: classfications,
            })
        }
    }

    componentDidMount() {
        document.title = this.props.translate('ptcar_overview')
        this.loadPtcars()
        this.loadPtcarTypes()
        this.loadPtcarClassifications()
    }

    getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = this.props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    onClose = () => {
        this.setState({
            isOpen: false,
            rowData: {},
            lineSearchValue: '',
            selectedLineSearchItem: { code: '', label: '' },
        })
    }
    onCloseLindaDrawer = () => {
        var lindaDates = { ...this.state.lindaSettingDates }
        // set all the dates to null on close drawer
        lindaDates = mapValues(lindaDates, () => null)
        this.setState({
            openLindaDrawer: false,
            lindaSettingDates: lindaDates,
            checkedTasks: [],
            checkAll: false,
            lindaSettingUpdateError: '',
        })
    }

    handleCopyToAll = type => {
        let lindaDates = { ...this.state.lindaSettingDates }
        let toCopy = lindaDates[type]
        let newDates = mapValues(lindaDates, () => toCopy)
        this.setState({ lindaSettingDates: newDates })
    }
    dateChangeHandler = (type, value) => {
        let lindaDates = { ...this.state.lindaSettingDates }
        if (value) lindaDates[type] = value / 1000
        // converting millis to seconds
        else lindaDates[type] = null
        this.setState({ lindaSettingDates: lindaDates })
    }

    onLindaSettingSave = () => {
        const { lindaSettingDates } = this.state
        let checkedPtcars = [...this.state.checkedTasks]
        let dataToSend = []
        for (let checkedPtcar of checkedPtcars) {
            let checkObj = checkedPtcar.split(seprator)
            let lindaSetting = {}

            lindaSetting.LineNumber = checkObj[0]
            lindaSetting.PtcarUic = checkObj[1]
            lindaSetting.DepartureOnHoldActivationDate = lindaSettingDates.departureOnHold
            lindaSetting.DepartureOnHoldActive = !!lindaSettingDates.departureOnHold
            lindaSetting.PersonWithLimitedPossibilityActivationDate = lindaSettingDates.personWithLimitedPossibility

            lindaSetting.PersonWithLimitedPossibilityActive = !!lindaSettingDates.personWithLimitedPossibility
            lindaSetting.ExtraLindaStopActivationDate = lindaSettingDates.extraLindaStop
            lindaSetting.ExtraLindaStopActive = !!lindaSettingDates.extraLindaStop
            lindaSetting.VgProcedureStartedActivationDate = lindaSettingDates.vgProcedureStarted
            lindaSetting.VgProcedureStartedActive = !!lindaSettingDates.vgProcedureStarted
            lindaSetting.VgExecutedActivationDate = lindaSettingDates.vgExecuted
            lindaSetting.VgExecutedActive = !!lindaSettingDates.vgExecuted
            lindaSetting.NotifyAllActivationDate = lindaSettingDates.notifyAll
            lindaSetting.NotifyAllActive = !!lindaSettingDates.notifyAll
            lindaSetting.ReceiveAllActivationDate = lindaSettingDates.receiveAll
            lindaSetting.ReceiveAllActive = !!lindaSettingDates.receiveAll
            lindaSetting.RegisterToLindaActivationDate = lindaSettingDates.registerToLinda
            lindaSetting.RegisterToLindaActive = !!lindaSettingDates.registerToLinda
            lindaSetting.SuspendDepartureActivationDate = lindaSettingDates.displayIconuitgesteldvertrek

            lindaSetting.SuspendDepartureActive = !!lindaSettingDates.displayIconuitgesteldvertrek
            dataToSend.push(lindaSetting)
        }
        this.lindaSettingUpdate(dataToSend)
    }
    handleDeleteClose = () => {
        this.setState({
            deleteOnGoing: false,
            openDialog: false,
            deleteRowData: [],
            deleteError: '',
        })
    }

    handleLineClose = () => {
        this.setState({
            lineDialogOpen: false,
            openDialog: false,
            lineSearchValue: '',
            lineError: '',
            selectedLineSearchItem: { code: '', label: '' },
        })
    }

    handleDelete = async (id, line = null) => {
        const { url, subscriptionKey } = requestData
        const { translate } = this.props
        this.setState({ deleteOnGoing: true })
        let fullUrl = `${url}/legacyReferenceData/ptcar/${id}/line/${line}/delete`
        if (line === null) {
            fullUrl = `${url}/legacyReferenceData/ptcar/${id}/delete`
        }

        try {
            const response = await axios.post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.handleDeleteClose()
            }
            if (response.status === 200) {
                this.handleDeleteClose()
                this.loadPtcars()
            }
        } catch (e) {
            this.setState({
                deleteError: translate('delete_ptcar_error'),
            })
        }
    }

    getPtcarsByLineNumber = async (line, name) => {
        const { translate } = this.props
        let data = await getPtcarsByLineNumber(line)

        if (data.length)
            this.props.history.push(`${this.props.match.path}/mapview`, {
                data: data,
                lineNumber: line,
                lineName: name,
                lineNumberData: this.props.lineNumberData,
                types: this.state.ptcarTypes,
                classifications: this.state.ptcarClassifications,
                useInfrabelUrl: this.props.useInfrabelUrl,
            })
        else
            this.setState({
                deleteError: translate('general_error'),
            })
    }

    handleEditClick = (e, rowData) => {
        this.onRowEditClick(e, rowData)
    }
    addLineNumber = async (lineNumber, milestone, distance) => {
        const { url, subscriptionKey } = requestData
        const { translate } = this.props
        const fullUrl = `${url}/legacyReferenceData/ptcar/${this.state.rowData.id}/line/${lineNumber}/add`

        try {
            const response = await axios.post(fullUrl, {
                MileId: parseInt(milestone),
                Distance: parseFloat(distance),
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.handleLineClose()
            }
            if (response.status === 200) {
                this.handleLineClose()
                this.onClose()
                this.handleDeleteClose()

                this.setState(
                    {
                        lineDialogOpen: false,
                        lineError: '',
                    },
                    () => {
                        this.loadPtcars()
                    }
                )
            }
        } catch (e) {
            if (e.response.status === 400) {
                this.setState({
                    lineError: e.response.data.Message,
                })
            } else {
                this.setState({
                    lineError: translate('add_line_error'),
                })
            }
        }
    }

    setIntialValues = () => {
        this.setState({
            openLindaDrawer: false,
            checkedTasks: [],
            checkAll: false,
            lindaSettingDates: {
                registerToLinda: null,
                vgProcedureStarted: null,
                vgExecuted: null,
                departureOnHold: null,
                personWithLimitedPossibility: null,
                extraLindaStop: null,
                notifyAll: null,
                receiveAll: null,
                displayIconuitgesteldvertrek: null,
            },
        })
    }

    lindaSettingUpdate = async data => {
        const { url, subscriptionKey } = requestData
        let fullUrl = `${url}/legacyReferenceData/lindaInteractionSettings/update`

        try {
            const response = await axios.post(fullUrl, data, {
                // data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.setIntialValues()
            }
            if (response.status === 200) {
                this.setIntialValues()
                this.loadPtcars()
            }
        } catch (e) {
            if (e.response.status === 400) {
                this.setState({
                    lindaSettingUpdateError: e.response.data.Message,
                })
            } else {
                this.setState({
                    lindaSettingUpdateError: 'Error occured',
                })
            }
        }
    }

    onRowDeleteClick = (e, rowData) => {
        this.setState({
            openDialog: true,
            deleteRowData: rowData,
        })
        e.stopPropagation()
    }

    onRowEditClick = (e, rowData) => {
        this.setState({
            isOpen: true,
            rowData: rowData,
            status: 1,
        })
        e.stopPropagation()
    }
    onRowMapClick = (e, rowData) => {
        let index = rowData.lineIndex
        if (index >= 0 && typeof index == 'number')
            this.getPtcarsByLineNumber(rowData.ptCarKmMarks[index].lineNumber, rowData.ptCarKmMarks[index].lineName)
        else
            this.setState({
                toast: {
                    open: true,
                    msg: 'line_number_not_available',
                },
            })
        e.stopPropagation()
    }
    handleAddClick = () => {
        this.setState({
            isOpen: true,
            status: 0,
        })
    }

    openLineNumberDialog = () => {
        this.setState({
            lineDialogOpen: true,
        })
    }

    lindaSettingClick = () => {
        if (this.state.checkedTasks.length === 1) {
            let ptcarList = this.getData()
            let checkedPtcar = this.state.checkedTasks[0]
            let selectedPtcar
            let length = ptcarList.length
            for (let i = 0; i < length; i++) {
                let ptcarObj = ptcarList[i]
                let uniqueKey = ptcarObj['ptCarKmMarks'][ptcarObj.lineIndex]?.lineName + seprator + ptcarObj['uic']
                if (checkedPtcar === uniqueKey) {
                    selectedPtcar = ptcarObj['ptCarKmMarks'][ptcarObj.lineIndex]?.lindaInteractionSettings
                }
            }
            let lindaSettingObj = { ...this.state.lindaSettingDates }
            if (selectedPtcar) {
                lindaSettingObj.registerToLinda = selectedPtcar.registerToLindaActivationDate
                lindaSettingObj.vgProcedureStarted = selectedPtcar.vgProcedureStartedActivationDate
                lindaSettingObj.vgExecuted = selectedPtcar.vgExecutedActivationDate
                lindaSettingObj.departureOnHold = selectedPtcar.departureOnHoldActivationDate
                lindaSettingObj.personWithLimitedPossibility = selectedPtcar.personWithLimitedPossibilityActivationDate
                lindaSettingObj.extraLindaStop = selectedPtcar.extraLindaStopActivationDate
                lindaSettingObj.notifyAll = selectedPtcar.notifyAllActivationDate
                lindaSettingObj.receiveAll = selectedPtcar.receiveAllActivationDate
                lindaSettingObj.displayIconuitgesteldvertrek = selectedPtcar.suspendDepartureActivationDate
                this.setState({ openLindaDrawer: true, lindaSettingDates: lindaSettingObj })
                return
            }
        }
        if (this.state.checkedTasks.length > 0) this.setState({ openLindaDrawer: true })
    }

    handleLineSearchValue = (e, triggerValidation = null) => {
        if (e === null) {
            this.setState({
                lineSearchValue: '',
                selectedLineSearchItem: { code: '', label: '' },
            })
        } else {
            if (triggerValidation !== null) {
                this.setState({ lineSearchValue: e.code, selectedLineSearchItem: e }, () => {
                    triggerValidation()
                })
                return
            }
            this.setState({ lineSearchValue: e.code, selectedLineSearchItem: e })
        }
    }

    clearLineSearchValue = () => {
        this.setState({
            lineSearchValue: '',
        })
    }

    headerRenderer = headerProps => {
        const { classes, headerHeight } = this.props
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: headerHeight,
                    paddingTop: 34,
                    paddingBottom: 34,
                    paddingRight: 0,
                }}
            >
                {headerProps.dataKey !== 'checkbox' && (
                    <Typography>
                        <Translate id={headerProps.label} />
                    </Typography>
                )}
                {headerProps.dataKey === 'checkbox' && (
                    <Checkbox
                        checked={this.state.checkAll}
                        onChange={() => this.handleCheckAll()}
                        value={'all'}
                        color="primary"
                    />
                )}
            </TableCell>
        )
    }
    cellRenderer = cellProps => {
        const { activeLanguage, classes } = this.props
        const { dataKey, rowData } = cellProps
        let checkedTasks = this.state.checkedTasks
        if (dataKey === 'ptCarKmMarks') {
            if (rowData[dataKey] && rowData[dataKey].length === 0) {
                return (
                    <TableCell
                        className={classes.tableCell}
                        component="div"
                        variant="body"
                        style={{ height: this.props.rowHeight }}
                    >
                        <Typography>{'N/A'}</Typography>
                    </TableCell>
                )
            }

            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData[dataKey][rowData.lineIndex].lineName}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'milestone') {
            if (rowData['ptCarKmMarks'] && rowData['ptCarKmMarks'].length === 0) {
                return (
                    <TableCell
                        className={classes.tableCell}
                        component="div"
                        variant="body"
                        style={{ height: this.props.rowHeight }}
                    >
                        <Typography>{'N/A'}</Typography>
                    </TableCell>
                )
            }

            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData['ptCarKmMarks'][rowData.lineIndex].milestone}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'latitude') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData.coordinate.latitude}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'longitude') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData.coordinate.longitude}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'code') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData[dataKey]}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'uic') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData[dataKey]}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'commercialName') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <StyledTooltip
                        placement="top"
                        disableFocusListener
                        title={this.getCommercialName(activeLanguage, rowData[dataKey])}
                    >
                        <Typography
                            style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {this.getCommercialName(activeLanguage, rowData[dataKey])}
                        </Typography>
                    </StyledTooltip>
                </TableCell>
            )
        }
        if (dataKey === 'outerBox') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData[dataKey]}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'innerBox') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData[dataKey]}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'crossings') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    {rowData.ptCarKmMarks.length > 1 ? (
                        <StyledTooltip
                            placement="top"
                            disableFocusListener
                            title={`${rowData.ptCarKmMarks
                                .filter(item => item.lineNumber !== rowData['ptCarKmMarks'][rowData.lineIndex].lineNumber)
                                .map(e => e.lineName)
                                .join(', ')}`}
                        >
                            <Typography
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {`${rowData.ptCarKmMarks
                                    .filter(
                                        item => item.lineNumber !== rowData['ptCarKmMarks'][rowData.lineIndex].lineNumber
                                    )
                                    .map(e => e.lineName)
                                    .join(', ')}`}
                            </Typography>
                        </StyledTooltip>
                    ) : (
                        <Typography>-</Typography>
                    )}
                </TableCell>
            )
        }

        if (dataKey === 'actions') {
            return (
                <TableCell
                    className={classes.tableActionCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <div className={classes.multipleIcons}>
                        <Edit
                            className={classes.editIcon}
                            onClick={e => {
                                this.onRowEditClick(e, rowData)
                            }}
                        />

                        <Delete
                            className={classes.deleteIcon}
                            onClick={e => {
                                this.onRowDeleteClick(e, rowData)
                            }}
                        />

                        <MapIcon
                            className={classes.mapIcon}
                            onClick={e => {
                                this.onRowMapClick(e, rowData)
                            }}
                        />
                    </div>
                </TableCell>
            )
        }
        if (dataKey === 'checkbox') {
            let uniqueKey = rowData['ptCarKmMarks'][rowData.lineIndex]?.lineName + seprator + rowData['uic']
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Checkbox
                        checked={checkedTasks.indexOf(uniqueKey) > -1}
                        onChange={e => this.handleCheckTasks(e, uniqueKey)}
                        value={uniqueKey}
                        color="primary"
                    />
                </TableCell>
            )
        }
    }

    handleLineNumberFilter = e => {
        let filteredString = e.target.value
        this.setState(
            {
                lineFilterValue: filteredString,
                searchFilterValue: '',
            },
            () => {
                let data = this.state.data
                let filteredData = data.filter(item => {
                    return item.lineIndex !== null
                        ? item.ptCarKmMarks[item.lineIndex].lineName.toLowerCase().includes(filteredString.toLowerCase())
                        : null
                })
                this.setState({ filteredData: filteredData })
            }
        )
    }

    handleSearchFilter = e => {
        let filteredString = e.target.value
        this.setState(
            {
                searchFilterValue: filteredString,
                lineFilterValue: '',
            },
            () => {
                let data = this.state.data

                let filteredData = data.filter(
                    item =>
                        item.code.toLowerCase().includes(filteredString.toLowerCase()) ||
                        item.uic.includes(filteredString) ||
                        this.getCommercialNameForFR(item.commercialName)
                            .toLowerCase()
                            .includes(filteredString.toLowerCase()) ||
                        this.getCommercialNameForNL(item.commercialName)
                            .toLowerCase()
                            .includes(filteredString.toLowerCase())
                )

                this.setState({ filteredData: filteredData })
            }
        )
    }

    getCommercialName(activeLanguage, nameObj) {
        if (nameObj === undefined || nameObj[0] === undefined || nameObj[1] === undefined) return ''
        if (activeLanguage.code === 'fr') return nameObj[0].translation
        else return nameObj[1].translation
    }
    getCommercialNameForFR(nameObj) {
        if (nameObj === undefined || nameObj[0] === undefined || nameObj[1] === undefined) return ''
        return nameObj[0].translation
    }
    getCommercialNameForNL(nameObj) {
        if (nameObj === undefined || nameObj[0] === undefined || nameObj[1] === undefined) return ''
        return nameObj[1].translation
    }

    persist = type => props => {
        const toSetState = { [type]: props }
        this.setState(toSetState)
    }

    getData() {
        if (this.state.lineFilterValue || this.state.searchFilterValue) return this.state.filteredData
        else return this.state.data
    }
    handleCheckTasks = (e, id) => {
        e.stopPropagation()
        const { checkedTasks } = this.state
        if (checkedTasks.indexOf(id) > -1) {
            const removeFromList = checkedTasks.filter(item => item !== id)
            this.setState({ checkedTasks: removeFromList })
            return
        }

        const list = [...checkedTasks, id]
        this.setState({ checkedTasks: list })
    }
    handleCheckAll = () => {
        let listData = this.getData()
        let checkedArray = []

        if (this.state.checkAll) {
            this.setState({ checkedTasks: [], checkAll: false, selectedPtcarForLinda: '' })
        } else {
            listData.forEach(item => {
                let uniqueKey = item['ptCarKmMarks'][item.lineIndex]?.lineName + seprator + item['uic']
                checkedArray.push(uniqueKey)
            })

            this.setState({ checkedTasks: checkedArray, checkAll: true, selectedPtcarForLinda: '' })
        }
    }

    handleRecalculateSemes = async () => {
        const res = await recalculateSemes()
        if (!res) {
            this.setState({ showSemesPopup: true })
        }
    }

    render() {
        const { classes, translate, activeLanguage, lineNumberData } = this.props
        const { status, selectedLineSearchItem, lineSearchValue } = this.state

        return (
            <div>
                <Header isMenu title={translate('ptcar_overview')} />
                <div className={classes.parentDiv} style={{ display: 'flex' }}>
                    <div className={classes.left}>
                        <Typography style={{ marginRight: 10 }}>{translate('datatable_header_ptcar_line')}</Typography>
                        <input
                            type="text"
                            className={classes.customInput}
                            placeholder={translate('search')}
                            value={this.state.lineFilterValue}
                            onChange={e => this.handleLineNumberFilter(e)}
                        />
                        <Typography style={{ marginLeft: 10, marginRight: 10 }}>{translate('search')}</Typography>
                        <input
                            className={classes.customInput}
                            placeholder={translate('search')}
                            value={this.state.searchFilterValue}
                            onChange={e => this.handleSearchFilter(e)}
                        />
                    </div>
                    <div className={classes.right}>
                        <Button
                            style={{ marginRight: '5px' }}
                            variant="contained"
                            color="primary"
                            onClick={this.lindaSettingClick}
                            disabled={this.state.checkedTasks.length < 1}
                        >
                            {translate('linda_settings')}
                        </Button>
                        <Button
                            style={{ marginRight: '5px' }}
                            variant="contained"
                            color="primary"
                            onClick={this.handleAddClick}
                        >
                            {translate('module_add_performance')}
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.handleRecalculateSemes}>
                            {translate('module_recalc_semes')}
                        </Button>
                    </div>
                </div>
                {this.state.loadingData && <Loading />}
                {!this.state.loadingData && (
                    <PtcarTable
                        columns={columns}
                        data={this.getData()}
                        classes={classes}
                        headerRenderer={this.headerRenderer}
                        cellRenderer={this.cellRenderer}
                        getRowClassName={this.getRowClassName}
                    />
                )}

                {this.state.isOpen && (
                    <PtcarDrawerView
                        rowData={this.state.status === 1 ? this.state.rowData : {}}
                        isOpen={this.state.isOpen}
                        onClose={this.onClose}
                        persist={this.persist}
                        activeLanguage={activeLanguage}
                        edit={status}
                        openLineNumberDialog={this.openLineNumberDialog}
                        loadPtcars={this.loadPtcars}
                        handleLineSearchValue={this.handleLineSearchValue}
                        clearLineSearchValue={this.clearLineSearchValue}
                        lineNumberData={lineNumberData}
                        lineSearchValue={lineSearchValue}
                        selectedLineSearchItem={selectedLineSearchItem}
                        types={this.state.ptcarTypes}
                        classifications={this.state.ptcarClassifications}
                    />
                )}
                {this.state.openDialog && (
                    <DeleteDialog
                        activeLanguage={activeLanguage}
                        rowData={this.state.deleteRowData}
                        open={this.state.openDialog}
                        handleClose={this.handleDeleteClose}
                        handleDelete={this.handleDelete}
                        deleteError={this.state.deleteError}
                        loading={this.state.deleteOnGoing}
                    />
                )}
                {this.state.lineDialogOpen && (
                    <LineDialog
                        rowData={this.state.status === 1 ? this.state.rowData : {}}
                        open={this.state.lineDialogOpen}
                        handleClose={this.handleLineClose}
                        handleAdd={this.addLineNumber}
                        error={this.state.lineError}
                        handleLineSearchValue={this.handleLineSearchValue}
                        clearLineSearchValue={this.clearLineSearchValue}
                        lineNumberData={lineNumberData}
                        lineSearchValue={lineSearchValue}
                        selectedLineSearchItem={selectedLineSearchItem}
                    />
                )}
                {this.state.openLindaDrawer && (
                    <LindaDrawerView
                        rowData={this.state.status === 1 ? this.state.rowData : {}}
                        isOpen={this.state.openLindaDrawer}
                        onClose={this.onCloseLindaDrawer}
                        // persist={this.persist}
                        onChangeDate={this.dateChangeHandler}
                        activeLanguage={activeLanguage}
                        copyToAllHandler={this.handleCopyToAll}
                        lindaSettingDates={this.state.lindaSettingDates}
                        onSaveClick={this.onLindaSettingSave}
                        lindaSettingUpdateError={this.state.lindaSettingUpdateError}
                    />
                )}

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.toast.open}
                    autoHideDuration={2500}
                    onClose={this.closeToast}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={
                        <Typography id="message-id">
                            {this.state.toast.msg ? translate(this.state.toast.msg) : ''}
                        </Typography>
                    }
                />
                <Dialog open={this.state.showSemesPopup} onClose={() => this.setState({ showSemesPopup: false })}>
                    <DialogTitle>Semes</DialogTitle>
                    <div className={classes.semesDialog}>
                        <Typography style={{ marginBottom: '15px', textAlign: 'center' }}>
                            {translate('semes_still_reprocessing')}
                        </Typography>
                        <Button
                            style={{ width: '100%' }}
                            onClick={() => this.setState({ showSemesPopup: false })}
                            variant="contained"
                            color="primary"
                        >
                            Sluiten
                        </Button>
                    </div>
                </Dialog>
            </div>
        )
    }
}

export default compose(withLocalize, withStore, withRouter, withStyles(styles))(PtcarListView)
