export default theme => ({
    generalContainer: {
        padding: theme.spacing(3),
        paddingBottom: 'calc(84px + 24px)',
        // display: "flex",
        // minHeight: "calc(100vh - 148px)",
        // flexDirection: "column"
    },
    stepBorder: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        width: '100%',
        borderBottom: '1px solid grey',
    },
})
