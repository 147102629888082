import React from 'react'
import { object, func, arrayOf, string, bool, array } from 'prop-types'

// Material UI
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// Components
import AppPaper from 'components/AppPaper'
import SelectInput from 'components/SelectInput'

class DepotFilterView extends React.Component {
    static propTypes = {
        classes: object.isRequired,
        onTCTSelected: func.isRequired,
        onDepotSelected: func.isRequired,
        depots: arrayOf(object).isRequired,
        tcts: arrayOf(object).isRequired,
        selectedDepot: array.isRequired,
        selectedTCTs: arrayOf(string).isRequired,
        translate: func.isRequired,
        onChange: func.isRequired,
        performancesGrouped: bool,
        performancesIsLoading: bool,
        showGroupedCheckBox: bool,
    }
    static defaultProps = {
        showGroupedCheckBox: true,
    }

    isTCTSelected = id => {
        return this.props.selectedTCTs.some(name => name === id)
    }
    toggleGroup = () => {
        this.props.onChange()
    }

    render() {
        const {
            classes,
            depots,
            onDepotSelected,
            onTCTSelected,
            selectedDepot,
            tcts,
            translate,
            performancesGrouped,
            performancesIsLoading,
            showGroupedCheckBox,
        } = this.props

        const selectData = depots.map(d => ({ id: d.id, text: translate(d.id) }))
        const sortedSelect = selectData.sort((a, b) => a.text.localeCompare(b.text))
        // const merge = [...sortedSelect];
        const merge = [
            { id: 'performances_label_all_depots', text: translate('performances_label_all_depots') },
            ...sortedSelect,
        ]
        return (
            <AppPaper className={classes.root}>
                <div className={classes.flex}>
                    {tcts.map(tct => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={this.isTCTSelected(tct.id)}
                                    onChange={() => onTCTSelected(tct.id)}
                                    value={tct.symbolicName}
                                />
                            }
                            key={tct.symbolicName}
                            label={tct.symbolicName}
                        />
                    ))}
                </div>
                <div className={classes.flex}>
                    {showGroupedCheckBox && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => this.toggleGroup()}
                                    checked={performancesGrouped}
                                    disabled={performancesIsLoading}
                                    color="primary"
                                />
                            }
                            label={translate('group_performances_by_depot')}
                        />
                    )}
                    <SelectInput
                        filled
                        label="DEPOT"
                        items={merge}
                        name="depot"
                        onChange={onDepotSelected}
                        value={selectedDepot}
                        multiple
                    />
                </div>
            </AppPaper>
        )
    }
}

export default DepotFilterView
