export default theme => ({
    drawerRoot: {
        // display: "flex",
        // justifyContent: "space-between",
        // flexDirection: "column",
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: `${theme.readStatusBoxes}px`,
        padding: `${theme.spacing(3)}px`,
        '@media (max-width: 577px)': {
            width: '85vw',
            overflowX: 'hidden',
        },
    },
    drawerHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: `${theme.readStatusBoxes - 2 * theme.spacing(3)}px`,
        marginBottom: theme.spacing(3),
    },
    performanceInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
    },
    mb: {
        marginBottom: theme.spacing(2),
    },
    performanceInfoCell: {
        width: '33%',
    },
    boldText: {
        fontWeight: 'bold',
        color: theme.customColors.white,
    },
    buttonBottom: {
        position: 'absolute',
        bottom: `${theme.spacing(3)}px`,
        left: `${theme.spacing(3)}px`,
    },
})
