export default theme => ({
    drawerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '83vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
    },
    textField: {
        fontSize: 10,
        lineHeight: 2.4,
    },
    twoElementDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    saveButton: {
        padding: '16px 32px',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        color: theme.customColors.lightGrey,
        backgroundColor: theme.palette.grey['800'],
        border: '1px solid white',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        padding: theme.spacing(1),
    },
    searchError: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        color: theme.customColors.lightGrey,
        backgroundColor: theme.palette.grey['800'],
        border: '1px solid red',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        padding: theme.spacing(1),
    },
    searchIcon: {
        color: theme.customColors.lightGrey,
        width: theme.spacing(3),
        height: '36px',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})
