import React from 'react'
import { object, func, bool, string, array } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { Divider } from '@material-ui/core'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import Loading from 'components/Loading'
import ListView from './ListView'
import ListViewMobile from './ListViewMobile'
import { isMobile } from 'react-device-detect'
import styles from './styles'

class GroupedTrainListView extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            selectedDepot: '',
        }
    }

    static propTypes = {
        classes: object.isRequired,
        data: object.isRequired,
        translate: func,
        addFeedbackCategory: func,
        updateFeedbackCategory: func,
        persist: func,
        onClose: func,
        openDrawer: func,
        isOpen: bool,
        openDrawerToUpdate: func,
        activeLanguage: object,
        store: object,
        loading: bool,
        onRowClick: func,
        isSortOpen: bool,
        isPerformanceGrouped: bool,
        handleSaveSort: func,
        checkPerformanceForSound: func,
        editSortLoading: bool,
        sortError: string,
        platformPtcarsList: array,
    }

    static defaultProps = {
        rowHeight: 156,
        headerHeight: 56,
    }

    openFeedback = (store, ptcar) => {
        this.setState({ selectedDepot: ptcar === this.state.selectedDepot ? '' : ptcar })
    }

    findPtcarCode = (item, list) => {
        const found = list.find(ptcar => ptcar.ptCarUicCode === parseInt(item))

        if (found) return found.ptCarCode
        return item
    }

    render() {
        const {
            data,
            translate,
            isPerformanceGrouped,
            store,
            loading,
            platformPtcarsList,
            onRowClick,
            checkPerformanceForSound,
        } = this.props
        const { classes } = this.props
        const ptcars = Object.keys(data)

        return (
            <div>
                {loading ? (
                    <Loading />
                ) : (
                    <div className={classes.groupedDocumentTableWrapper}>
                        {ptcars.length > 0 ? (
                            <React.Fragment>
                                <List>
                                    {ptcars &&
                                        ptcars.map(ptcar => (
                                            <React.Fragment key={ptcar}>
                                                <Paper square elevation={0}>
                                                    <ListItem button onClick={() => this.openFeedback(store, ptcar)}>
                                                        <ListItemText
                                                            primary={this.findPtcarCode(ptcar, platformPtcarsList)}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            {this.state.feedback &&
                                                            this.state.feedback.id === performance.id ? (
                                                                <ExpandLess className={classes.icon} />
                                                            ) : (
                                                                <ExpandMore className={classes.icon} />
                                                            )}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Paper>
                                                {this.state.selectedDepot &&
                                                    this.state.selectedDepot === ptcar &&
                                                    !isMobile && (
                                                        <ListView
                                                            data={data[this.state.selectedDepot]}
                                                            classes={classes}
                                                            openDrawer={this.props.openDrawer}
                                                            store={store}
                                                            isPerformanceGrouped={isPerformanceGrouped}
                                                            onRowClick={onRowClick}
                                                            checkPerformanceForSound={checkPerformanceForSound}
                                                        ></ListView>
                                                    )}
                                                {this.state.selectedDepot &&
                                                    this.state.selectedDepot === ptcar &&
                                                    isMobile && (
                                                        <ListViewMobile
                                                            data={data[this.state.selectedDepot]}
                                                            classes={classes}
                                                            openDrawer={this.props.openDrawer}
                                                            store={store}
                                                            isPerformanceGrouped={isPerformanceGrouped}
                                                            onRowClick={onRowClick}
                                                            checkPerformanceForSound={checkPerformanceForSound}
                                                        ></ListViewMobile>
                                                    )}
                                                <Divider className={classes.divider}></Divider>
                                            </React.Fragment>
                                        ))}
                                </List>
                            </React.Fragment>
                        ) : (
                            <Typography className={classes.submenu} variant="h6">
                                {translate('no_data_available')}
                            </Typography>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default compose(withStyles(styles), withRouter, withLocalize)(GroupedTrainListView)
