import React from 'react'
import { object } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'
import Loading from 'components/Loading'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import styles from './styles'

class NoMatch extends React.Component {
    state = { loading: true }

    componentDidMount() {
        this.timeout = setTimeout(() => {
            this.setState({ loading: false })
        }, 3000)
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    render() {
        const { classes } = this.props
        const { loading } = this.state
        return (
            <div className={classes.errorNotFound}>
                {loading && <Loading />}

                {!loading && (
                    <Typography variant="h3">
                        <Translate id="not_found" />
                    </Typography>
                )}
            </div>
        )
    }
}

NoMatch.propTypes = {
    classes: object.isRequired,
}

export default compose(withStyles(styles), withLocalize)(NoMatch)
