export const getNormalizedMessage = (obj, key = 'message') => {
    if (obj === undefined) {
        return ''
    }
    const result = obj[Object.keys(obj).find(k => k.toLowerCase() === key.toLowerCase())]
    if (result === undefined) {
        return ''
    } else {
        return result
    }
}

export default getNormalizedMessage
