/**
 *
 * @description reloads all tabs that introduce this hook on call of the returned function
 *
 * @param {string} key:  key reference for the value stored in localStorage
 * @param {boolean} initialValue: initial value of the state, defaults to true should not be changed in 99% of uses
 *
 * @returns {function: void} invertLocalState: function that toggles the state and dispatchs the event
 */

import React from 'react'

export const useLocalStorageReload = (key, initialValue = true) => {
    const handleRefresh = () => {
        window.location.reload()
    }
    React.useEffect(() => {
        if (localStorage.getItem(key) === null) {
            localStorage.setItem(key, initialValue)
        }
        window.addEventListener('storage', handleRefresh)

        return () => {
            window.removeEventListener('storage', handleRefresh)
        }
    }, [])

    const invertLocalState = () => {
        localStorage.getItem(key) === 'true' ? localStorage.setItem(key, false) : localStorage.setItem(key, true)
        window.dispatchEvent(new Event('storage'))
    }

    return { invertLocalState }
}
