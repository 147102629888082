import React, { useState } from 'react'
import PropTypes from 'prop-types'

import deletePlatform from '../../Services/deletePlatform'

import DeleteDialog from 'components/DeleteDialog'

const DeletePlatformDialog = props => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const handleDeleteClick = async () => {
        setLoading(true)
        let response = await deletePlatform(deleteData.id)
        if (response.status === 200) {
            reloadPlatforms()
            onDialogClose()
        } else {
            setLoading(false)
            setError(true)
        }
    }

    const { onDialogClose, dialogOpen, deleteData, translate, reloadPlatforms } = props
    return (
        <DeleteDialog
            dialogOpen={dialogOpen}
            onClose={onDialogClose}
            title={translate('platform_delete_dialog_title')}
            translate={translate}
            handleDelete={handleDeleteClick}
            deleteMsg={translate('platform_delete_role_dialog_confirm_text')}
            error={error}
            errorMsg={translate('performance_delete_task_dialog_error')}
            deleteInfo={deleteData.id}
            loading={loading}
        />
    )
}
DeletePlatformDialog.propTypes = {
    onDialogClose: PropTypes.func,
    translate: PropTypes.func,
    reloadPlatforms: PropTypes.func,
    dialogOpen: PropTypes.bool,
    deleteData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
export default DeletePlatformDialog
