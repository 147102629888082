import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withStore, withStatus } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'

import PlatformListView from './components/PlatformListView'
import Header from 'components/Header'
import withFunctionlityCheck from 'utils/hocs/withFunctionalityCheck'

function Platforms(props) {
    useEffect(() => {
        document.title = props.translate('platform_page')
    }, [])

    return (
        <React.Fragment>
            <Header title={props.translate('platform_page')} />
            <PlatformListView {...props} />
        </React.Fragment>
    )
}
Platforms.propTypes = {
    store: PropTypes.object,
    history: PropTypes.object,
    __ion_status: PropTypes.object,
    translate: PropTypes.func,
}

export default compose(withRouter, withLocalize, withStore, withStatus, withFunctionlityCheck)(Platforms)
