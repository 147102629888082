import React from 'react'
import { object, func, string, number, bool, oneOfType } from 'prop-types'
import { Translate } from 'react-localize-redux'
import { Route, Switch } from 'react-router-dom'
import { CallStatus } from 'react-ion-store/lib/enums'

// import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

import Grid from '@material-ui/core/Grid'

import Header from 'components/Header'

import ActionBar from 'components/ActionBar'
// import ReadStatusDrawer from './components/ReadStatusDrawer'
import DistrictsMapper from 'components/DistrictsMapper'
import DocumentFolderMapper from 'components/DocumentFolderMapper'
import IconButton from 'components/IconButton'

import DocumentSearch from './components/DocumentSearch'

import NoSearch from './views/NoSearch'
import FilledSearch from './views/FilledSearch'
import RawDetailView from './views/DetailView'
import DocumentsFooter from './views/DetailView/components/DocumentsFooter'
import DocumentActionDrawer from './components/DocumentActionDrawer'
const DocumentsView = ({
    persist,
    initCall,
    setDetailView,
    clearDetailView,
    formValues,
    store,
    history,
    location,
    match,
    documentRoles,
    footerData,
    documentsFilter,
    reqDate,
    selectedRadio,
    changeRadio,
    read,
    unread,
    handleCheckbox,
    org,
    depot,
    setName,
    selectedName,
    clearName,
    onClick,
    buttonDisabled,
    setDate,
    file,
    filterDrawerOpen,
}) => {
    const { ...searchBarValues } = formValues

    const QueryView = () => (
        <React.Fragment>
            {(!store.documentSearchResults || store.documentSearchResults.get('status') === CallStatus.INITIAL) && (
                <NoSearch />
            )}

            {store.documentSearchResults &&
                store.documentSearchResults.get('status') !== CallStatus.INITIAL &&
                filterDrawerOpen === false && <FilledSearch type={searchBarValues.radio} setDetailView={setDetailView} />}
        </React.Fragment>
    )

    const DetailView = () => <RawDetailView clearDetailView={clearDetailView} />

    const detailView = store.get('documentsDetailView')
    if (location.pathname === `${match.path}/detail` && !detailView) {
        history.replace(`${match.path}`)
    }

    return (
        <Translate>
            {({ translate }) => (
                <React.Fragment>
                    {location.pathname === `${match.path}/detail` ? (
                        <Header title={translate('module_documents')} returnPath={match.path} />
                    ) : (
                        <Header isMenu title={translate('module_documents')} />
                    )}
                    <DistrictsMapper>
                        <DocumentFolderMapper>
                            <Grid style={{ marginBottom: '100px' }} container spacing={0}>
                                <Grid item xs={12}>
                                    <ActionBar>
                                        {/* {(documentRoles.write === true ||
                                            (Array.isArray(documentRoles.write) && documentRoles.write.length > 0)) && (
                                            <IconButton
                                                style={{ marginRight: '15px' }}
                                                icon="person"
                                                title={translate('districts_mark_read')}
                                                onClick={() => persist('drawerOpen')(true)}
                                                disabled={checkFunctionalityDisabled(
                                                    store,
                                                    'mark as read',
                                                    'documents.functionality.management.documents'
                                                )}
                                            />
                                        )} */}

                                        {(documentRoles.write === true ||
                                            (Array.isArray(documentRoles.write) && documentRoles.write.length > 0)) && (
                                            <IconButton
                                                icon="filter_list"
                                                title={translate('button_filter')}
                                                onClick={() => persist('filterDrawerOpen')(true)}
                                            />
                                        )}
                                    </ActionBar>
                                    <Switch>
                                        <Route exact path={`${match.path}`} component={QueryView} />
                                        <Route exact path={`${match.path}/detail`} component={DetailView} />
                                    </Switch>
                                </Grid>
                            </Grid>
                            <DocumentSearch value={selectedRadio} />

                            {/* {!!documentRoles.write && (
                                <ReadStatusDrawer open={drawerOpen} onClose={() => persist('drawerOpen')(false)} />
                            )} */}
                            {
                                <DocumentActionDrawer
                                    open={filterDrawerOpen}
                                    persist={persist}
                                    initCall={initCall}
                                    searchBarValues={searchBarValues}
                                    selectedRadio={selectedRadio}
                                    changeRadio={changeRadio}
                                    read={read}
                                    unread={unread}
                                    handleCheckbox={handleCheckbox}
                                    org={org}
                                    depot={depot}
                                    setName={setName}
                                    selectedName={selectedName}
                                    clearName={clearName}
                                    onClick={onClick}
                                    buttonDisabled={buttonDisabled}
                                    setDate={setDate}
                                    file={file}
                                />
                            }

                            {location.pathname === `${match.path}/detail` && (
                                <DocumentsFooter
                                    store={store}
                                    footerData={footerData}
                                    persist={persist}
                                    formValues={formValues}
                                    documentsFilter={documentsFilter}
                                    date={reqDate}
                                />
                            )}
                        </DocumentFolderMapper>
                    </DistrictsMapper>
                </React.Fragment>
            )}
        </Translate>
    )
}

DocumentsView.propTypes = {
    classes: object.isRequired,
    persist: func.isRequired,
    initCall: func.isRequired,
    setDetailView: func.isRequired,
    clearDetailView: func.isRequired,
    formValues: object.isRequired,
    store: object.isRequired,
    history: object.isRequired,
    location: object.isRequired,
    match: object.isRequired,
    documentRoles: object.isRequired,
    footerData: object,
    documentsFilter: string,
    reqDate: oneOfType([string, object, number]),
    selectedRadio: number,
    changeRadio: func,
    read: bool,
    unread: bool,
    handleCheckbox: func,
    org: string,
    depot: string,
    setName: func,
    selectedName: string,
    clearName: func,
    onClick: func,
    buttonDisabled: bool,
    filterDrawerOpen: bool,
    setDate: func,
    file: string,
}

export default DocumentsView
