export default theme => ({
    flex: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    text: {
        flexGrow: 1,
    },
    root: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        },
        [theme.breakpoints.down('xs')]: {
            width: 'fit-content',
        },
    },
})
