import React from 'react'
import { object, bool, func, string } from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import ThresholdForm from './ThresholdForm'
import styles from './styles'
import { withStyles } from '@material-ui/styles'
const PlatformDrawer = props => {
    const { isOpen, onClose, editDrawer, classes, translate, addEditError } = props

    return (
        <Drawer open={isOpen} anchor="right" onClose={onClose}>
            <div className={classes.drawerRoot}>
                <Typography variant="h6">
                    {editDrawer
                        ? translate('cosmosdb_performance_test_case_edit')
                        : translate('cosmosdb_performance_test_case_add')}
                </Typography>
                <ThresholdForm error={addEditError} {...props} />
            </div>
        </Drawer>
    )
}

PlatformDrawer.propTypes = {
    classes: object.isRequired,
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    translate: func,
    editDrawer: bool,
    addEditError: string,
}

export default withStyles(styles)(PlatformDrawer)
