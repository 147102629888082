import React from 'react'

import { colorFilter, listColumns } from '../esemes.util'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { object, array } from 'prop-types'
import { TaskSemesContext } from '../semes.store'
import classNames from 'classnames'

import { TableCell, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'

import FullList from './fullList'
import { TranslationContext } from 'context/translation'
import returnTranslationForLocale from 'utils/returnTranslationForLocale'
import { semesDateTime } from 'utils/dateParser'

const ESemesList = ({ classes }) => {
    let defaultHeaderHeight = 56
    let defaultRowHeight = 56

    const { METHODS } = React.useContext(TaskSemesContext)
    const { activeLanguage } = React.useContext(TranslationContext)

    const headerRenderer = headerProps => {
        return (
            <TableCell
                onClick={() => {
                    METHODS.toggleSortOrder(headerProps.dataKey)
                }}
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: defaultHeaderHeight,
                    paddingRight: 0,
                    cursor: headerProps.dataKey === 'type' || headerProps.dataKey === 'line' ? 'pointer' : 'default',
                }}
            >
                <Typography>
                    {headerProps.dataKey === 'number' ? (
                        <>
                            <span className={classes.headerBold}>
                                <Translate style={{ fontWeight: 'bold' }} id={headerProps.label} />
                            </span>
                            <span className={classes.semesTime}>
                                <Translate id={'datatable_esemes_time'} />
                            </span>
                        </>
                    ) : (
                        <span className={classes.headerBold}>
                            <Translate id={headerProps.label} />
                        </span>
                    )}
                </Typography>
            </TableCell>
        )
    }

    const cellRenderer = cellProps => {
        const { dataKey, rowData } = cellProps

        let value
        if (dataKey === 'type') {
            //CHANGE WITH REAL DATA
            const trans = returnTranslationForLocale(
                rowData.setyDescription,
                activeLanguage.code === 'nl' ? 'nl-BE' : 'fr-BE'
            )
            value = (
                <span className={classes.typeContainer} style={{ backgroundColor: colorFilter(trans) }}>
                    {trans}
                </span>
            )
        }
        if (dataKey === 'line') {
            value = rowData.lineSection.userLineName || ''
        }

        if (dataKey === 'number') {
            value = (
                <>
                    <span className={classes.semesTitle}>
                        {rowData.additionSemesInfrabelNr} -{' '}
                        {returnTranslationForLocale(
                            rowData.lineSection.ptCarFromCommercialMediumName,
                            activeLanguage.code === 'nl' ? 'nl-BE' : 'fr-BE'
                        ) || ''}{' '}
                        -{' '}
                        {returnTranslationForLocale(
                            rowData.lineSection.ptCarToCommercialMediumName,
                            activeLanguage.code === 'nl' ? 'nl-BE' : 'fr-BE'
                        ) || ''}
                    </span>
                    <span className={classes.semesTime}>
                        {semesDateTime(parseInt(rowData.validityPeriod.beginSemesDate))} tot{' '}
                        {semesDateTime(parseInt(rowData.validityPeriod.endSemesDate))}
                    </span>
                </>
            )
        }

        if (dataKey === 'speed') {
            value = `${returnTranslationForLocale(
                rowData.lineSection.trackType,
                activeLanguage.code === 'nl' ? 'nl-BE' : 'fr-BE'
            )}${rowData.lineSection.speedLimitation ? ': ' + rowData.lineSection.speedLimitation : ''}`
        }

        if (dataKey === 'received') {
            value = <span>{rowData.firstReceived ? semesDateTime(rowData.firstReceived) : ' - '}</span>
        }

        if (dataKey === 'first_opened') {
            value = <span>{rowData.firstOpened ? semesDateTime(rowData.firstOpened) : ' - '}</span>
        }

        if (dataKey === 'last_opened') {
            value = <span>{rowData.lastOpened ? semesDateTime(rowData.lastOpened) : ' - '}</span>
        }

        return (
            <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: defaultRowHeight }}>
                <Typography>{value}</Typography>
            </TableCell>
        )
    }

    return (
        <FullList classes={classes} listColumns={listColumns} headerRenderer={headerRenderer} cellRenderer={cellRenderer} />
    )
}

ESemesList.propTypes = {
    classes: object,
    data: array,
}

export default withStyles(styles)(ESemesList)
