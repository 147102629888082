import React from 'react'
import { string } from 'prop-types'

// Material UI
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

const SectionHeader = ({ title }) => (
    <React.Fragment>
        <Typography data-testid='SectionHeader-title' variant="subtitle1">{title}</Typography>
        <Divider />
    </React.Fragment>
)

SectionHeader.propTypes = {
    title: string.isRequired,
}

export default SectionHeader
