import axios from 'axios'

export default async (data, isEdit) => {
    let fullUrl
    if (isEdit) fullUrl = `/legacyReferenceData/compositionDefectType/${data.id}/update`
    else fullUrl = `/legacyReferenceData/compositionDefectType/add`

    try {
        return await axios.post(fullUrl, data)
    } catch (e) {
        console.error(e) // eslint-disable-line
        return e.response
    }
}
