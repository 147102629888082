export default theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        position: 'fixed',
        bottom: '0px',
        width: '100%',
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    flex: {
        display: 'flex',
    },
    errormsg: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '33%',
    },

    shortenText: {
        dispaly: 'block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
})
