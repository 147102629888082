export default theme => ({
    colorSwitchBase: {
        color: theme.languagePicker,
        '& + $colorBar': {
            backgroundColor: theme.languagePicker,
        },
        '&$colorChecked': {
            color: theme.languagePicker,
            '& + $colorBar': {
                backgroundColor: theme.languagePicker,
            },
        },
    },
    colorBar: {},
    colorChecked: {},
    inlineElement: {
        color: theme.customColors.white,
        display: 'inline-block',
    },
    whiteTyphography: {
        color: 'white',
    },
})
