import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { getTime, timeDiffOver5Min } from '../../Utils'

const RouteView = props => {
    const { classes, rowData, activeLanguage, handleImageClick } = props
    return (
        <React.Fragment>
            <div style={{ display: 'flex' }}>
                <Grid className={classes.column} item xs={3}>
                    <Typography className={classes.text}>
                        {rowData.passTroughTime
                            ? rowData.passTroughTime.substring(0, 5)
                            : rowData.arrivalTime
                            ? rowData.arrivalTime.substring(0, 5)
                            : ''}
                    </Typography>
                    <Typography
                        className={classes.text}
                        style={
                            timeDiffOver5Min(
                                rowData.passTroughTime ? rowData.realPassTroughTime : rowData.realArrivalTime,
                                rowData.passTroughTime ? rowData.passTroughTime : rowData.arrivalTime
                            )
                                ? { color: 'orange' }
                                : { color: 'black' }
                        }
                    >
                        {rowData.passTroughTime
                            ? getTime(rowData.realPassTroughTime)
                            : rowData.realArrivalTime
                            ? getTime(rowData.realArrivalTime)
                            : ''}
                    </Typography>
                </Grid>
                <Grid className={classes.column} item xs={3}>
                    <Typography className={classes.text}>
                        {rowData.departureTime
                            ? rowData.departureTime.substring(0, 5)
                            : rowData.passTroughTime
                            ? rowData.passTroughTime.substring(0, 5)
                            : ''}
                    </Typography>

                    <Typography
                        className={classes.text}
                        style={
                            timeDiffOver5Min(
                                rowData.passTroughTime ? rowData.realPassTroughTime : rowData.realDepartureTime,
                                rowData.passTroughTime ? rowData.passTroughTime : rowData.departureTime
                            )
                                ? { color: 'orange' }
                                : { color: 'black' }
                        }
                    >
                        {rowData.passTroughTime
                            ? getTime(rowData.realPassTroughTime)
                            : rowData.realDepartureTime
                            ? getTime(rowData.realDepartureTime)
                            : ''}
                    </Typography>
                </Grid>
                <Grid className={classes.column} item xs={6}>
                    <Typography className={classes.text}>
                        {`${(activeLanguage === 'fr' ? rowData.locationFr : rowData.locationNl) +
                            ' ' +
                            (rowData.feedbackFreeText ? rowData.feedbackFreeText : '')}`}
                    </Typography>
                </Grid>
            </div>
            <Grid className={classes.column} item xs={12}>
                <Typography className={classes.text}>
                    {rowData.feedbackCategory1Value
                        ? rowData.feedbackCategory1Value +
                          `${
                              rowData.feedbackCategory2Value
                                  ? ' > ' +
                                    rowData.feedbackCategory2Value +
                                    (rowData.feedbackCategory3Value ? ' > ' + rowData.feedbackCategory3Value : '')
                                  : ''
                          }`
                        : rowData?.feedback?.feedbackCategory1Value
                        ? rowData?.feedback?.feedbackCategory1Value +
                          `${
                              rowData?.feedback?.feedbackCategory2Value
                                  ? ' > ' +
                                    rowData?.feedback?.feedbackCategory2Value +
                                    (rowData?.feedback?.feedbackCategory3Value
                                        ? ' > ' + rowData?.feedback?.feedbackCategory3Value
                                        : '')
                                  : ''
                          }`
                        : ''}
                </Typography>
                {rowData.feedbackImagesWithBlobUrl && rowData.feedbackImagesWithBlobUrl.length > 0 && (
                    <React.Fragment>
                        <hr />
                        <div style={{ display: 'flex' }}>
                            {rowData.feedbackImagesWithBlobUrl.map((link, linkIndex) => (
                                <Typography key={linkIndex} className={classes.link} onClick={() => handleImageClick(link)}>
                                    {` Open Image${linkIndex + 1}, `}
                                </Typography>
                            ))}
                        </div>
                    </React.Fragment>
                )}
            </Grid>
        </React.Fragment>
    )
}
RouteView.propTypes = {
    classes: PropTypes.object,
    rowData: PropTypes.object,
    activeLanguage: PropTypes.object,
    handleImageClick: PropTypes.func,
}
export default RouteView
