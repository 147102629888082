/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import styles from './styles'
import { object } from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { Button, TextField } from '@material-ui/core'

import SelectInput from 'components/SelectInput'
import getPtcarList from '../services/getPtcarList'
import Header from 'components/Header'
import { timeFromEpochSeconds } from 'utils/dateParser'
import timeParser from 'utils/timeParser'
import Loading from 'components/Loading'
import { TranslationContext } from 'context/translation'
import compose from 'recompose/compose'
import { withStore } from 'react-ion-store'

const CompositionWithPtcar = props => {
    const WAIT_INTERVAL = 1000
    const { classes } = props
    const [trainNumber, setTrainNumber] = useState('')
    const [selectedPtcar, setSelectedPtcar] = useState('')
    const [ptcarList, setPtcarList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const selectedDate = Math.floor(new Date().getTime() / 1000)
    const { translate, activeLanguage } = React.useContext(TranslationContext)

    useEffect(() => {
        setPtcarList([])
        const timer = setTimeout(async () => {
            setIsLoading(true)
            if (trainNumber.length > 2) {
                const data = await getPtcarList(trainNumber, selectedDate)
                if (data.length) {
                    let lastPtcar = data.slice(-1)
                    let lastPtcarObj = lastPtcar[0]
                    // In case last ptcar arrival time is less than current time means task is finished.
                    // so we will show only last ptcar in the list and last ptcar selected.
                    if (timeParser(lastPtcarObj.plannedArrivalTime) < timeParser(selectedDate)) {
                        let ptcarData = mapPtcarData(lastPtcar)
                        setSelectedPtcar(ptcarData[1].id)
                        setPtcarList(ptcarData)
                    } else {
                        let ptcarData = mapPtcarData(data)
                        let selectedId = ptcarData.find((ptcar, index, originalData) => {
                            if (index > 0 && ptcar.plannedDepartureTime) {
                                if (timeParser(ptcar.plannedDepartureTime) > timeParser(selectedDate)) {
                                    if (index === 1) {
                                        return true
                                    }
                                    try {
                                        return (
                                            timeParser(selectedDate) >=
                                            timeParser(originalData[index - 1].plannedDepartureTime)
                                        )
                                    } catch (e) {
                                        return false
                                    }
                                }
                            }
                        })
                        if (selectedId) setSelectedPtcar(selectedId.id)
                        else setSelectedPtcar(' ')
                        setPtcarList(ptcarData)
                    }
                }
            }
            setIsLoading(false)
        }, WAIT_INTERVAL)
        return () => clearTimeout(timer)
    }, [trainNumber])
    useEffect(() => {
        document.title = translate('composition_view_header_title')
    }, [])

    const showComposition = () => {
        window.open(`/compositionOverview/${encodeURIComponent(trainNumber)}/${selectedDate}/${selectedPtcar}`)
    }

    const resetClicked = () => {
        setTrainNumber('')
        setPtcarList([])
    }
    const checkDepartureTimeGreaterThenCurrentTimeBy1Hour = departureTime => {
        if (departureTime) return timeParser(departureTime) > timeParser(selectedDate - 60 * 60)
    }
    const mapPtcarData = data => {
        const defaultValue = { id: ' ', text: 'Choose location' }

        if (data === undefined || data.length === 0) return []
        if (activeLanguage.code === 'nl') {
            const forSelect = data
                .filter((obj, index) => {
                    return (
                        !obj.passThrough &&
                        (index !== data.length - 1
                            ? checkDepartureTimeGreaterThenCurrentTimeBy1Hour(obj.plannedDepartureTime)
                            : true)
                    )
                })
                .map(({ shortName, plannedArrivalTime, plannedDepartureTime, ptcarUic }) => ({
                    id: ptcarUic,
                    text:
                        shortName && shortName[1] !== undefined
                            ? shortName[1].translation +
                              ` ${plannedDepartureTime ? timeFromEpochSeconds(plannedDepartureTime) : ''}`
                            : '',
                    plannedArrivalTime,
                    plannedDepartureTime,
                    ptcarUic,
                }))
            return [defaultValue, ...forSelect]
        }
        if (activeLanguage.code === 'fr') {
            const forSelect = data
                .filter((obj, index) => {
                    return (
                        !obj.passThrough &&
                        (index !== data.length - 1
                            ? checkDepartureTimeGreaterThenCurrentTimeBy1Hour(obj.plannedDepartureTime)
                            : true)
                    )
                })
                .map(({ shortName, plannedArrivalTime, plannedDepartureTime, ptcarUic }) => ({
                    id: ptcarUic,
                    text:
                        shortName && shortName[0] !== undefined
                            ? shortName[0].translation +
                              ` ${plannedDepartureTime ? timeFromEpochSeconds(plannedDepartureTime) : ''}`
                            : '',
                    plannedArrivalTime,
                    plannedDepartureTime,
                    ptcarUic,
                }))

            return [defaultValue, ...forSelect]
        }
    }
    return (
        <>
            <Header
                showTime={false}
                isMenu
                isNothing
                title={`${translate('composition_view_header_title')} ${timeParser(
                    new Date().getTime() / 1000
                )} `}
            />
            <div className={classes.mainDiv}>
                <div className={classes.trainNumber}>
                    <TextField
                        fullWidth={true}
                        name="trainNumber"
                        label={translate('enter_train_number')}
                        required
                        value={trainNumber}
                        onChange={e => {
                            setTrainNumber(e.target.value)
                        }}
                        onKeyPress={e => (e.key.toLocaleUpperCase() === 'ENTER' ? e.target.blur() : null)}
                        style={{ marginBottom: 16 }}
                    />
                    <Button disabled={ptcarList.length < 1} onClick={() => resetClicked()} color="primary">
                        {translate('agenda_reset_button')}
                    </Button>
                </div>
                <SelectInput
                    name="ptcars"
                    classes={classes}
                    items={ptcarList}
                    value={selectedPtcar}
                    onChange={e => {
                        setSelectedPtcar(e.target.value)
                    }}
                />
                <div className={classes.loadButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => showComposition()}
                        disabled={ptcarList.length < 1}
                    >
                        {translate('label_composition')}
                    </Button>
                </div>
                {isLoading ? <Loading /> : null}
            </div>
        </>
    )
}
CompositionWithPtcar.propTypes = {
    classes: object,
    data: object,
}
export default compose(withStore, withStyles(styles))(CompositionWithPtcar)
