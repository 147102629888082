import React, { useState } from 'react'
import { bool, func, object, oneOfType, any, string, number } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'

import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { Drawer, Typography, Radio, FormControlLabel, Button, Checkbox } from '@material-ui/core'

import { TimePickerWithKeyboard } from 'components/Pickers'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import DetailView from './DetailView'
import ReasonDialog from './ReasonDialog'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import { statusEnum } from 'utils/statusEnum'
import { dateTime } from 'utils/dateParser'
import timeParser from 'utils/timeParser'

import styles from './styles'

const handleAgendaClick = (history, idf) => {
    history.push(`/agenda/${idf}`)
}

const printRealStartTime = (performance, realStartTime, onChangeRealStartTime, tab, store) => {
    if (!performance.realStartTime && !performance.signedInTime) {
        return '--:--'
    }

    if (tab === statusEnum.SIGNED_IN) {
        if (realStartTime) {
            return (
                <TimePickerWithKeyboard
                    value={realStartTime}
                    onChange={onChangeRealStartTime}
                    disabled={checkFunctionalityDisabled(store, 'adapt hours', 'performances.functionality.sidebar')}
                />
            )
        }
    }
    if (tab !== statusEnum.NOT_VALIDATED && tab !== statusEnum.SIGNED_OFF) {
        if (!performance.realStartTime && performance.signedInTime) {
            return timeParser(performance.plannedStartTime)
        }
        if (performance.realStartTime) {
            return timeParser(performance.realStartTime)
        }
    }

    if (!performance.realStartTime && performance.signedInTime) {
        return (
            <TimePickerWithKeyboard
                value={realStartTime}
                onChange={onChangeRealStartTime}
                disabled={checkFunctionalityDisabled(store, 'adapt hours', 'performances.functionality.sidebar')}
            />
        )
    }
    if (performance.realStartTime) {
        return (
            <TimePickerWithKeyboard
                value={realStartTime}
                onChange={onChangeRealStartTime}
                disabled={checkFunctionalityDisabled(store, 'adapt hours', 'performances.functionality.sidebar')}
            />
        )
    }
}

const printRealEndTime = (performance, realEndTime, onChangeRealEndTime, tab, store) => {
    if (!performance.realEndTime && !performance.signedOutTime) {
        return '--:--'
    }

    if (tab !== statusEnum.NOT_VALIDATED && tab !== statusEnum.SIGNED_OFF) {
        if (!performance.realEndTime && performance.signedOutTime) {
            return timeParser(performance.plannedEndTime)
        }
        if (performance.realEndTime) {
            return timeParser(performance.realEndTime)
        }
    }

    if (!performance.realEndTime && performance.signedOutTime) {
        return (
            <TimePickerWithKeyboard
                value={realEndTime}
                onChange={onChangeRealEndTime}
                disabled={checkFunctionalityDisabled(store, 'adapt hours', 'performances.functionality.sidebar')}
            />
        )
    }
    if (performance.realEndTime) {
        return (
            <TimePickerWithKeyboard
                value={realEndTime}
                onChange={onChangeRealEndTime}
                disabled={checkFunctionalityDisabled(store, 'adapt hours', 'performances.functionality.sidebar')}
            />
        )
    }
}

const printButtonHandler = performance => {
    window.open(`/performanceDetail/getPrint/${performance.performanceId}/${performance.idfNumber}`)
}

const PerformanceActionDrawerView = ({
    isOpen,
    close,
    persistSetMonitor,
    performanceInfo,
    classes,
    setMonitorLoading,
    setMonitorError,
    driverRole,
    reasonEndTimeChanged,
    reasonStartTimeChanged,
    realStartTime,
    realEndTime,
    onStartReasonChange,
    onEndReasonChange,
    onChangeRealEndTime,
    onChangeRealStartTime,
    onChangePlannedStartTime,
    onChangePlannedEndTime,
    plannedStartTime,
    plannedEndTime,
    adjustPlannedTime,
    adjustTimes,
    adjustStartTime,
    adjustLoading,
    adjustError,
    store,
    history,
    handleCheckbox,
    adjustPlannedTimeLoading,
    adjustPlannedTimeError,
    isStartTimeChanged,
    isThirdPartyPerformance,
    onThirdPartyChange,
}) => {
    const { performance } = performanceInfo ? performanceInfo : {}
    const lockAfterSeconds = parseInt(process.env.REACT_APP_LOCK_CHANGES_AFTER_SECONDS, 10)
    const [openStartDialog, setOpenStartDialog] = useState(false)
    const [openEndDialog, setOpenEndDialog] = useState(false)
    const [isTouched, setIsTouched] = useState(false)

    const closeStartReasonDialog = () => {
        setOpenStartDialog(false)
        setIsTouched(false)
    }
    const closeEndReasonDialog = () => {
        setOpenEndDialog(false)
        setIsTouched(false)
    }
    const setReasonForStartChange = startReason => {
        setOpenStartDialog(false)
        adjustPlannedTime(performance, startReason, '')
        setIsTouched(false)
    }
    const setReasonForEndChange = endReason => {
        setOpenEndDialog(false)
        adjustPlannedTime(performance, '', endReason)
        setIsTouched(false)
    }

    const setPlannedStartTime = time => {
        onChangePlannedStartTime(time)
        setIsTouched(true)
    }
    const setPlannedEndTime = time => {
        onChangePlannedEndTime(time)
        setIsTouched(true)
    }

    const handleStartTimeChange = () => {
        setOpenStartDialog(true)
    }
    const handleEndTimeChange = () => {
        setOpenEndDialog(true)
    }

    const handleThirdPartyPerformanceToggle = e => {
        onThirdPartyChange(e.target.checked)
    }

    return (
        <Drawer open={isOpen} anchor="right" onClose={() => close(false)}>
            <div className={classes.drawerRoot}>
                <div className={classes.drawerHeader}>
                    <Typography variant="h6">
                        <Translate id="performances_label_performance_detail" />
                    </Typography>
                </div>
                <Typography variant="h6">
                    <Translate id="performances_label_performance_detail_general_info" />
                </Typography>
                <hr style={{ marginBottom: 16 }} />
                {!!performanceInfo && (
                    <React.Fragment>
                        <div className={classNames(classes.performanceInfo, classes.mb)}>
                            <div className={classes.performanceInfoCell}>
                                <Typography variant="body2" className={classes.boldText}>
                                    {performance.performanceId || '---'}
                                </Typography>
                                <Typography variant="caption">
                                    <Translate id={'documents_label_select_depot'} />{' '}
                                    {performance.depotName ? <Translate id={`DEPOT-${performance.depotName}`} /> : '---'}
                                </Typography>
                            </div>
                            <div className={classes.performanceInfoCell}>
                                <Typography variant="body2" className={classes.boldText}>
                                    {performance.profileName || '---'}
                                </Typography>
                                <Typography variant="caption">{performance.idfNumber || '---'}</Typography>
                            </div>
                            {performance.idfNumber && (
                                <div
                                    className={classes.agenda}
                                    onClick={() => handleAgendaClick(history, performance.idfNumber)}
                                >
                                    <CalendarTodayIcon className={classes.agendaIcon} />
                                    <Typography variant="body2" className={classes.agendaLink}>
                                        <Translate id="driver_agenda" />
                                    </Typography>
                                </div>
                            )}
                        </div>
                        <div className={classes.performanceInfo}>
                            <div className={classes.performanceInfoCell}>
                                <Typography variant="body2" className={classes.boldText}>
                                    <Translate id="performances_label_performance_detail_signin" />
                                </Typography>
                                <Typography variant="caption">
                                    {performance.signedInTime ? dateTime(performance.signedInTime * 1000) : '--:--'}
                                </Typography>
                            </div>
                            <div className={classes.performanceInfoCell}>
                                <Typography variant="body2" className={classes.boldText}>
                                    <Translate id="datatable_header_planned_start" />
                                </Typography>

                                <Typography variant="caption">{timeParser(plannedStartTime / 1000)}</Typography>

                                <Button
                                    disabled={
                                        checkFunctionalityDisabled(
                                            store,
                                            'edit_planned_hours',
                                            'performances.functionality.sidebar'
                                        ) ||
                                        adjustPlannedTimeLoading ||
                                        setMonitorLoading
                                    }
                                    classes={{ root: classes.root }}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() => handleStartTimeChange()}
                                >
                                    <Translate id="add_user_edit" />
                                </Button>
                            </div>
                            <div className={classes.performanceInfoCell}>
                                <Typography variant="body2" className={classes.boldText}>
                                    <Translate id="performances_label_performance_detail_real_start" />
                                </Typography>
                                <Typography variant="caption">
                                    {printRealStartTime(
                                        performance,
                                        realStartTime,
                                        onChangeRealStartTime,
                                        performanceInfo.activeTab,
                                        store
                                    )}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.performanceInfo}>
                            <div className={classes.performanceInfoCell}>
                                <Typography variant="body2" className={classes.boldText}>
                                    <Translate id="performances_label_performance_detail_signout" />
                                </Typography>
                                <Typography variant="caption">
                                    {performance.signedOutTime ? dateTime(performance.signedOutTime * 1000) : '--:--'}
                                </Typography>
                            </div>
                            <div className={classes.performanceInfoCell}>
                                <Typography variant="body2" className={classes.boldText}>
                                    <Translate id="datatable_header_planned_end" />
                                </Typography>
                                <Typography variant="caption">{timeParser(plannedEndTime / 1000)}</Typography>

                                <Button
                                    disabled={
                                        checkFunctionalityDisabled(
                                            store,
                                            'edit_planned_hours',
                                            'performances.functionality.sidebar'
                                        ) ||
                                        adjustPlannedTimeLoading ||
                                        setMonitorLoading
                                    }
                                    classes={{ root: classes.root }}
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() => handleEndTimeChange()}
                                >
                                    <Translate id="add_user_edit" />
                                </Button>
                            </div>
                            <div className={classes.performanceInfoCell}>
                                <Typography variant="body2" className={classes.boldText}>
                                    <Translate id="performances_label_performance_detail_real_end" />
                                </Typography>
                                <Typography variant="caption">
                                    {printRealEndTime(
                                        performance,
                                        realEndTime,
                                        onChangeRealEndTime,
                                        performanceInfo.activeTab,
                                        store
                                    )}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.performanceInfo}>
                            <div className={classes.performanceInfoCell}>
                                <Typography variant="body2" style={{ marginTop: '12px' }} className={classes.boldText}>
                                    <Translate id="performance_task_label_contact_phone" />
                                </Typography>
                                <Typography variant="caption">
                                    {performance.telephoneNumber ? (
                                        <>
                                            <a style={{ color: 'white' }} href={`tel:${performance.telephoneNumber}`}>
                                                {performance.telephoneNumber}
                                            </a>
                                            <FileCopyOutlinedIcon
                                                onClick={() => {
                                                    navigator.clipboard.writeText(performance.telephoneNumber)
                                                }}
                                                style={{ height: '20px', cursor: 'pointer' }}
                                            />
                                        </>
                                    ) : (
                                        '--'
                                    )}
                                </Typography>
                            </div>
                        </div>

                        {performanceInfo.activeTab !== statusEnum.NOT_ASSIGNED && (
                            <div>
                                <Translate>
                                    {({ translate }) => (
                                        <FormControlLabel
                                            className={classes.mb}
                                            control={
                                                <Radio
                                                    checked={driverRole === 0}
                                                    color="primary"
                                                    value="standardCheckbox"
                                                    onChange={persistSetMonitor(
                                                        'isStandard',
                                                        performance.idfNumber,
                                                        performance.id,
                                                        0
                                                    )}
                                                    disabled={
                                                        driverRole === 0 ||
                                                        checkFunctionalityDisabled(
                                                            store,
                                                            'adapt driver role',
                                                            'performances.functionality.sidebar'
                                                        ) ||
                                                        setMonitorLoading ||
                                                        (performance.signedOutTime &&
                                                            Date.now() > performance.signedOutTime + lockAfterSeconds)
                                                    }
                                                />
                                            }
                                            label={translate('performances_label_performance_standard')}
                                        />
                                    )}
                                </Translate>
                                <Translate>
                                    {({ translate }) => (
                                        <FormControlLabel
                                            className={classes.mb}
                                            control={
                                                <Radio
                                                    checked={driverRole === 1}
                                                    color="primary"
                                                    value="monitorCheckbox"
                                                    onChange={persistSetMonitor(
                                                        'isMonitor',
                                                        performance.idfNumber,
                                                        performance.id,
                                                        1
                                                    )}
                                                    disabled={
                                                        driverRole === 1 ||
                                                        checkFunctionalityDisabled(
                                                            store,
                                                            'adapt driver role',
                                                            'performances.functionality.sidebar'
                                                        ) ||
                                                        setMonitorLoading ||
                                                        (performance.signedOutTime &&
                                                            Date.now() > performance.signedOutTime + lockAfterSeconds)
                                                    }
                                                />
                                            }
                                            label={translate('performances_label_performance_monitor')}
                                        />
                                    )}
                                </Translate>
                                <Translate>
                                    {({ translate }) => (
                                        <FormControlLabel
                                            className={classes.mb}
                                            control={
                                                <Radio
                                                    checked={driverRole === 2}
                                                    color="primary"
                                                    value="pupilCheckbox"
                                                    onChange={persistSetMonitor(
                                                        'isPupil',
                                                        performance.idfNumber,
                                                        performance.id,
                                                        2
                                                    )}
                                                    disabled={
                                                        driverRole === 2 ||
                                                        checkFunctionalityDisabled(
                                                            store,
                                                            'adapt driver role',
                                                            'performances.functionality.sidebar'
                                                        ) ||
                                                        setMonitorLoading ||
                                                        (performance.signedOutTime &&
                                                            Date.now() > performance.signedOutTime + lockAfterSeconds)
                                                    }
                                                />
                                            }
                                            label={translate('performances_label_performance_pupil')}
                                        />
                                    )}
                                </Translate>
                                {setMonitorError.length !== 0 && <ErrorMessage spacing={false} error={setMonitorError} />}
                                {adjustPlannedTimeError.length > 0 && (
                                    <ErrorMessage spacing={false} error={adjustPlannedTimeError} />
                                )}
                            </div>
                        )}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isThirdPartyPerformance}
                                    onChange={e => handleThirdPartyPerformanceToggle(e)}
                                    color="primary"
                                />
                            }
                            label={'Third Party Performance'}
                        />
                        {setMonitorLoading && (
                            <div style={{ height: '100px' }}>
                                <Loading />
                            </div>
                        )}
                        {!setMonitorLoading && (
                            <DetailView
                                {...performanceInfo}
                                reasonEndTimeChanged={reasonEndTimeChanged}
                                reasonStartTimeChanged={reasonStartTimeChanged}
                                realStartTime={realStartTime}
                                realEndTime={realEndTime}
                                onStartReasonChange={onStartReasonChange}
                                onEndReasonChange={onEndReasonChange}
                                adjustTimes={adjustTimes}
                                adjustStartTime={adjustStartTime}
                                adjustLoading={adjustLoading}
                                adjustError={adjustError}
                                closeDrawer={close}
                                driverRole={driverRole}
                                handleCheckbox={handleCheckbox}
                                printButtonHandler={printButtonHandler}
                                isStartTimeChanged={isStartTimeChanged}
                            />
                        )}
                        {openStartDialog && (
                            <ReasonDialog
                                open={openStartDialog}
                                handleClose={closeStartReasonDialog}
                                setReason={setReasonForStartChange}
                                time={plannedStartTime}
                                onChange={setPlannedStartTime}
                                isTouched={isTouched}
                            ></ReasonDialog>
                        )}
                        {openEndDialog && (
                            <ReasonDialog
                                open={openEndDialog}
                                handleClose={closeEndReasonDialog}
                                setReason={setReasonForEndChange}
                                time={plannedEndTime}
                                onChange={setPlannedEndTime}
                                isTouched={isTouched}
                            ></ReasonDialog>
                        )}
                    </React.Fragment>
                )}
            </div>
        </Drawer>
    )
}

PerformanceActionDrawerView.propTypes = {
    classes: object.isRequired,
    close: func.isRequired,
    persistSetMonitor: func.isRequired,
    isOpen: bool.isRequired,
    setMonitorLoading: bool.isRequired,
    setMonitorError: string.isRequired,
    driverRole: number.isRequired,
    performanceInfo: oneOfType([object, bool]).isRequired,
    reasonEndTimeChanged: string,
    reasonStartTimeChanged: string,
    realEndTime: any,
    realStartTime: any,
    onStartReasonChange: func,
    onEndReasonChange: func,
    onChangeRealEndTime: func,
    onChangeRealStartTime: func,
    onChangePlannedStartTime: func,
    onChangePlannedEndTime: func,
    adjustPlannedTime: func,
    adjustTimes: func,
    adjustStartTime: func,
    handleCheckbox: func,
    adjustLoading: bool,
    adjustError: string,
    store: any,
    history: object,
    plannedStartTime: oneOfType([object, string]),
    plannedEndTime: oneOfType([object, string]),
    adjustPlannedTimeLoading: bool,
    adjustPlannedTimeError: string,
    isStartTimeChanged: bool,
    isThirdPartyPerformance: bool,
    onThirdPartyChange: func,
}

export default compose(withStyles(styles), withLocalize)(PerformanceActionDrawerView)
