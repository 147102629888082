import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ErrorMessage from 'components/ErrorMessage'
import getNormalizedMessage from 'utils/getNormalizedMessage'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

import { Paper, Typography } from '@material-ui/core'

export class ReplaceDialog extends PureComponent {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        savedDriver: PropTypes.object,
        store: PropTypes.object,
        util: PropTypes.object,
        __ion_status: PropTypes.object,
        responseData: PropTypes.array,
        translate: PropTypes.func,
    }
    replaceDriver = (idf, id, performanceIdfNumber, replace) => {
        const { util, store } = this.props

        const assignDriverCall = util.CreateStoreObject({ idf, id, performanceIdfNumber, replace })
        store.call({ assignDriverCall })
    }

    render() {
        const { open, handleClose, savedDriver, __ion_status, store, responseData, translate } = this.props
        const { assignDriverCall } = __ion_status

        return (
            <Dialog open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{translate('driver_replace_dialog_title')}</DialogTitle>
                <Paper>
                    <DialogContent style={{ minWidth: '600px' }}>
                        <DialogContentText>{translate('driver_replace_dialog_subtitle')}</DialogContentText>
                        <Typography variant="subtitle1">
                            {responseData.isSelected
                                ? translate('driver_replace_dialog_other_signed_in_performance')
                                : translate('driver_replace_dialog_other_performance')}
                        </Typography>
                        {Object.keys(responseData).length > 0 && responseData.PerformanceId ? (
                            <Typography variant="subtitle1">{responseData.PerformanceId}</Typography>
                        ) : null}
                        {Object.keys(responseData).length > 0 && responseData.Depot ? (
                            <Typography variant="subtitle1">{responseData.Depot}</Typography>
                        ) : null}
                        {Object.keys(responseData).length > 0 &&
                        responseData.PlannedStartTime &&
                        responseData.PlannedEndTime ? (
                            <Typography variant="subtitle1">
                                {responseData.PlannedStartTime} - {responseData.PlannedEndTime}
                            </Typography>
                        ) : null}
                        {!responseData.IsSelected && (
                            <Typography variant="subtitle1">{translate('driver_replace_dialog_confirmation')}</Typography>
                        )}
                        {assignDriverCall === 4 &&
                            store.assignDriverCall !== undefined &&
                            getNormalizedMessage(
                                this.props.store.assignDriverCall._root.entries[2][1].response.data
                            ).indexOf('Profile already has an overlapping performance') === -1 && (
                                <ErrorMessage spacing={false} error="An Error occured." />
                            )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                handleClose()
                            }}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                this.replaceDriver(
                                    savedDriver.idf,
                                    savedDriver.id,
                                    savedDriver.performanceIdfNumber || null,
                                    true
                                )
                            }}
                            color="primary"
                            disabled={assignDriverCall === 2 || responseData.IsSelected}
                        >
                            Replace
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        )
    }
}

export default withStyles(styles)(ReplaceDialog)
