import React from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'

export const TranslationContext = React.createContext({})

const TranslationProvider = ({ children, translate, activeLanguage, setActiveLanguage }) => {
    return (
        <TranslationContext.Provider value={{ translate, activeLanguage, setActiveLanguage }}>
            {children}
        </TranslationContext.Provider>
    )
}

export default withLocalize(TranslationProvider)

TranslationProvider.propTypes = {
    children: PropTypes.element.isRequired,
    translate: PropTypes.func,
    activeLanguage: PropTypes.object,
    setActiveLanguage: PropTypes.func.isRequired,
}
