import React, { useState } from 'react'
import dateParser from 'utils/dateParser'
import timeParser from 'utils/timeParser'
export const useDate = () => {
    const [today, setDate] = useState(Date.now()) // Save the current date to be able to trigger an update
    React.useEffect(() => {
        const timer = setInterval(() => {
            // Creates an interval which will update the current data every second
            // This will trigger a rerender every component that uses the useDate hook.
            setDate(Date.now())
        }, 1000)
        return () => {
            clearInterval(timer) // Return a funtion to clear the timer so that it will stop being called on unmount
        }
    }, [])

    let currentTime = parseInt(parseInt(today) / 1000, 10)
    const date = dateParser(currentTime * 1000)
    const time = timeParser(currentTime, 'Europe/Brussels')

    return {
        date,
        time,
    }
}
