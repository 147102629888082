import Typography from '@material-ui/core/Typography'
import PrimaryButton from 'components/PrimaryButton'
import React from 'react'
import PersonSearcher from 'components/PersonSearcher'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import { withLocalize, Translate } from 'react-localize-redux'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { Delete } from '@material-ui/icons'
import ErrorMessage from 'components/ErrorMessage'
import { func, string, object } from 'prop-types'
import styles from './styles'
import Loading from 'components/Loading'

const resetState = {
    selectedName: '',
    selectedProfile: undefined,
}

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

class AddStudentView extends React.PureComponent {
    static propTypes = {
        closeDrawer: func.isRequired,
        translate: func,
        performanceId: string.isRequired,
        performanceIdfNumber: string,
        store: object.isRequired,
        classes: object.isRequired,
    }
    constructor(props) {
        super(props)
        this.state = {
            selectedName: '',
            selectedProfile: undefined,
            students: [],
            errorMsg: '',
            disabled: false,
            studentLoading: false,
        }
    }

    generate(classes) {
        const students = this.state.students
        return students.map(student => (
            <ListItem key={student.IdfNumber}>
                <ListItemText
                    style={{ textDecoration: 'underline', color: 'white' }}
                    primary={student.firstName + ' ' + student.lastName + '(' + student.idfNumber + ')'}
                />
                <ListItemSecondaryAction>
                    <Delete className={classes.deleteButton} onClick={() => this.removeStudent(student.idfNumber)} />
                </ListItemSecondaryAction>
            </ListItem>
        ))
    }
    setName = selectedProfile =>
        this.setState({
            selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
            selectedProfile: selectedProfile,
        })

    addStudent = async (performanceId, performanceIdfNumber) => {
        this.setState({ disabled: true, studentLoading: true })
        const { url, subscriptionKey } = requestData

        // eslint-disable-next-line max-len
        const fullUrl = `${url}/performance/${performanceId}/addstudent/${this.state.selectedProfile.idfNumber}/${performanceIdfNumber}`
        try {
            const response = await axios.post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.setState({ disabled: false, studentLoading: false })
                this.props.closeDrawer(true)
            }
            if (response.status === 204) {
                this.setState({ disabled: false, studentLoading: false })
                this.props.closeDrawer(true)
            }
        } catch (e) {
            if (e.response.status === 400) {
                this.setState({ errorMsg: e.response.data.Message, disabled: false, studentLoading: false })
            } else {
                this.setState({ errorMsg: 'Add student request failed', disabled: false, studentLoading: false })
            }
        }
    }
    removeStudent = async idfNumber => {
        const { url, subscriptionKey } = requestData
        const { translate } = this.props
        this.setState({ studentLoading: true })
        const fullUrl = `${url}/performance/${this.props.performanceId}/unassign/${idfNumber}`
        try {
            const response = await axios.post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200 || response.status === 204) {
                this.setState({ studentLoading: false })
                this.props.closeDrawer(true)
            }
        } catch (e) {
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({ errorMsg: translate('roles_error'), studentLoading: false })
                    return false
                }
                this.setState({ errorMsg: e.response.data.Message, studentLoading: false })
            } else {
                this.setState({ errorMsg: 'Delete student request failed', studentLoading: false })
            }
        }
    }

    getStudentList = async performanceId => {
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/performance/${performanceId}/students`
        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.setState({ students: response.data })
            }
            if (response.status === 204) {
                this.setState({
                    students: [],
                })
            }
        } catch (e) {
            this.setState({ students: [] })
        }
    }
    componentDidMount() {
        this.getStudentList(this.props.performanceId)
    }

    clearName = () => this.setState(resetState)
    render() {
        const { classes, performanceId, performanceIdfNumber } = this.props

        if (this.state.studentLoading)
            return (
                <div style={{ height: '100px' }}>
                    <Loading />
                </div>
            )
        return (
            <Translate>
                {({ translate }) => (
                    <div style={{ position: 'relative' }}>
                        <Typography style={{ marginTop: 16 }} variant="h6">
                            {translate('performances_label_pupils')}
                        </Typography>
                        <hr style={{ marginBottom: 16 }} />
                        <div name="PersonSearcherWrapper" tabIndex={0}>
                            <PersonSearcher
                                selectedName={this.state.selectedName}
                                setName={this.setName}
                                clearName={this.clearName}
                                type="app"
                                disabled={checkFunctionalityDisabled(
                                    this.props.store,
                                    'add_student',
                                    'performances.functionality.sidebar'
                                )}
                            />
                        </div>

                        <PrimaryButton
                            style={{ marginTop: 16 }}
                            className={classes.fullButton}
                            disabled={
                                checkFunctionalityDisabled(
                                    this.props.store,
                                    'add_student',
                                    'performances.functionality.sidebar'
                                ) ||
                                this.state.selectedName.length === 0 ||
                                this.state.disabled
                            }
                            onClick={() => this.addStudent(performanceId, performanceIdfNumber)}
                            title={
                                this.state.studentLoading
                                    ? translate('label_loading')
                                    : translate('Performance_add_student_button')
                            }
                        />
                        <List>{this.generate(classes)}</List>
                        {this.state.errorMsg && this.state.errorMsg.length !== 0 && (
                            <ErrorMessage spacing={false} error={this.state.errorMsg} />
                        )}
                    </div>
                )}
            </Translate>
        )
    }
}

export default compose(withStyles(styles), withLocalize, withStore)(AddStudentView)
