import React from 'react'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'
import styles from '../styles'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core'
import NMBSLogo from '../components/NMBSLogo'
import PropTypes from 'prop-types'

import { getTime, timeDiffOver5Min, getDeepFeedback, getVehicleNumber } from '../Utils'

const isAbolished = code => {
    let status = 'Abolished'
    return code.toUpperCase() === status.toUpperCase()
}
const changePerfId = id => {
    let perf = id.split('-')
    let date = perf[0] // Date of performance
    let year = date.substring(0, 4)
    let month = date.substring(4, 6)
    let day = date.substring(6, 8)

    return `${day}/${month}/${year} ${perf[2]} ${perf[1]}`
}
const Page1 = ({ data, translate, classes, handleImageClick, isTablet }) => {
    const feedBackOnPerfLevel = getDeepFeedback(data.feedback)

    return (
        <div data-testid="page-1" className={classes.root}>
            <Paper>
                <Grid container spacing={0} className={classes.outerPadding}>
                    <Grid className={classes.columnNoBorder} item xs={4}>
                        <div style={{ display: 'flex' }}>
                            <NMBSLogo />
                        </div>
                    </Grid>
                    <Grid className={classes.columnNoBorder} item xs={8}>
                        {
                            <Typography align="right" variant={!isTablet ? 'h5' : 'subtitle1'}>
                                {translate('performance_report_driver_report')} <br />
                            </Typography>
                        }
                    </Grid>

                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.text}>
                            {changePerfId(data.id)}
                            {data.version ? ` (${data.version}) ` : ''}
                            {`- ${data?.serviceNumber}`}
                        </Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.textBold}>
                            {translate('performances_label_performance_detail_signin')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.textBold}>{translate('datatable_header_planned_start')}</Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.textBold}>
                            {translate('performances_label_performance_detail_real_start')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.textBold}>{translate('performance_report_pause')}</Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.text}>{data.pauLength}</Typography>
                    </Grid>

                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.text}>{` ${data.organisationCode}
                         ${data.depotNumber}`}</Typography>
                    </Grid>

                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.text}>
                            {data.signInTime ? data.signInTime.substring(11, 16) : '--:--'}
                        </Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.text}>{getTime(data.plannedStartTime)}</Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography
                            className={classes.text}
                            style={
                                timeDiffOver5Min(data.realStartTime, data.plannedStartTime)
                                    ? { color: 'orange' }
                                    : { color: 'black' }
                            }
                        >
                            {getTime(data.realStartTime)}
                        </Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.textBold}>{'Monitor'}</Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.text}>{data.isTrainDriverMoniter ? 'True' : 'False'}</Typography>
                    </Grid>

                    <Grid className={classes.noBorderOnBottom} item xs={2}>
                        <Typography className={classes.text}>{`${data.firstName} ${data.lastName}`}</Typography>
                    </Grid>

                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.textBold}>
                            {translate('performances_label_performance_detail_signout')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.textBold}>{translate('datatable_header_planned_end')}</Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.textBold}>
                            {translate('performances_label_performance_detail_real_end')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.textBold}> {'Decuit'}</Typography>
                    </Grid>
                    <Grid className={classes.noBorderOnBottom} item xs={2}>
                        <Typography className={classes.text}>{data.uitdecDecuitLength}</Typography>{' '}
                    </Grid>

                    <Grid className={classes.noBorderOnTop} item xs={2}>
                        <Typography className={classes.text}>{`${data.idfNumber}`}</Typography>
                    </Grid>

                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.text}>
                            {data.signOutTime ? data.signOutTime.substring(11, 16) : '--:--'}
                        </Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.text}>{getTime(data.plannedEndTime)}</Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography
                            className={classes.text}
                            style={
                                timeDiffOver5Min(data.realEndTime, data.plannedEndTime)
                                    ? { color: 'orange' }
                                    : { color: 'black' }
                            }
                        >
                            {getTime(data.realEndTime)}
                        </Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.textBold}> {'Uitdec'}</Typography>
                    </Grid>
                    <Grid className={classes.noBorderOnTop} item xs={2}></Grid>
                    <Grid className={classes.column} container item xs={12}>
                        <Grid item md={2} sm={4}>
                            <Typography className={classes.textBold}>{`${translate(
                                'performance_report_service_announcements'
                            )}`}</Typography>
                        </Grid>
                        <Grid item md={10} sm={8}>
                            {data.remark && <Typography className={classes.textBold}>{data.remark}</Typography>}
                            {feedBackOnPerfLevel.length > 0
                                ? feedBackOnPerfLevel.map((feedback, index) => (
                                      <div key={index}>
                                          <Typography className={classes.text}>
                                              {`${feedback.freetext ? feedback.freetext : ''} - ${feedback.category}`}
                                          </Typography>
                                          <div style={{ display: 'flex' }}>
                                              {feedback.imageLinks.length > 0
                                                  ? feedback.imageLinks.map((link, linkIndex) => (
                                                        <Typography
                                                            data-testid="imageLink"
                                                            key={linkIndex}
                                                            className={classes.link}
                                                            onClick={() => handleImageClick(link)}
                                                        >
                                                            {` Open Image${linkIndex + 1}, `}
                                                        </Typography>
                                                    ))
                                                  : null}
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid className={classes.column} item xs={2}>
                            <Typography className={classes.textBold}>{translate('performance_report_vehicle')}</Typography>
                        </Grid>
                        <Grid className={classes.column} item xs={2}>
                            <Typography className={classes.textBold}>{translate('datatable_header_task_code')}</Typography>
                        </Grid>
                        <Grid className={classes.column} item xs={2}>
                            <Typography className={classes.textBold}>{translate('datatable_header_task_info')}</Typography>
                        </Grid>
                        <Grid className={classes.column} item xs={1}>
                            <Typography className={classes.textBold}>{translate('performance_report_max_speed')}</Typography>
                        </Grid>
                        <Grid container item xs={3}>
                            <Grid className={classes.column} item xs={6}>
                                <Typography className={classes.textBold}>
                                    {translate('datatable_header_task_start')}
                                </Typography>
                            </Grid>
                            <Grid className={classes.column} item xs={6}>
                                <Typography className={classes.textBold}>
                                    {translate('datatable_header_task_end')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid className={classes.column} item xs={2}>
                            <Typography className={classes.textBold}>{translate('datatable_header_task_status')}</Typography>
                        </Grid>

                        {data.tasks.map((row, index) => {
                            if (row.code.toUpperCase() === 'DRIVE') {
                                return (
                                    <React.Fragment key={index}>
                                        <Grid className={classes.column} item xs={2}>
                                            <Typography className={classes.text}>
                                                {getVehicleNumber(row.materials)}
                                            </Typography>
                                        </Grid>
                                        <Grid className={classes.column} item xs={2}>
                                            <Typography className={classes.text}>{row.trainNumber}</Typography>
                                        </Grid>
                                        <Grid className={classes.column} item xs={2}>
                                            <Typography className={classes.text}> {row.taskInfo}</Typography>
                                        </Grid>
                                        <Grid className={classes.column} item xs={1}>
                                            <Typography className={classes.text}> {row.technicalSpeed}</Typography>
                                        </Grid>
                                        <Grid container item xs={3}>
                                            <Grid className={classes.column} item xs={6}>
                                                <Typography className={classes.text}>
                                                    {getTime(row.plannedStartTime)}
                                                </Typography>
                                                <Typography
                                                    className={classes.text}
                                                    style={
                                                        timeDiffOver5Min(row.plannedStartTime, row.realStartTime)
                                                            ? { color: 'orange' }
                                                            : { color: 'black' }
                                                    }
                                                >
                                                    {getTime(row.realStartTime)}
                                                </Typography>
                                            </Grid>
                                            <Grid className={classes.column} item xs={6}>
                                                <Typography className={classes.text}>
                                                    {getTime(row.plannedEndTime)}
                                                </Typography>
                                                <Typography
                                                    className={classes.text}
                                                    style={
                                                        timeDiffOver5Min(row.plannedEndTime, row.realEndTime)
                                                            ? { color: 'orange' }
                                                            : { color: 'black' }
                                                    }
                                                >
                                                    {getTime(row.realEndTime)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.column} item xs={2}>
                                            <Typography
                                                className={classes.text}
                                                style={isAbolished(row.state) ? { color: 'orange' } : { color: 'black' }}
                                            >
                                                {row.state}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                        })}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}
Page1.propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    translate: PropTypes.func,
    handleImageClick: PropTypes.func,
    isTablet: PropTypes.bool,
}
export default compose(withStore, withLocalize, withStyles(styles))(Page1)
