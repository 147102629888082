import React from 'react'
import { bool } from 'prop-types'

import GestureIcon from '@material-ui/icons/Gesture'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import Dynamic_form from 'components/Dynamic_form'

const FeedbackCategoryIcon = props => {
    const { signed, isFirstDeparture, e360 } = props
    return (
        <>
            {signed && <GestureIcon data-testid="icon_gesture" />}
            {isFirstDeparture && <WatchLaterIcon data-testid="icon_watchlater" />}
            {e360 && <Dynamic_form />}
        </>
    )
}
FeedbackCategoryIcon.propTypes = {
    signed: bool,
    isFirstDeparture: bool,
    e360: bool,
}
export default FeedbackCategoryIcon
