import React from 'react'
import { object, node, string } from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

import { TranslationContext } from 'context/translation'

import ActionBar from 'components/ActionBar'
import DateTimePicker from 'components/DateTimePicker'

import { ESemesContext } from '../esemes.store'
import IconButton from 'components/IconButton'

const EsemesActionBar = ({ classes }) => {
    const { translate } = React.useContext(TranslationContext)
    const { STATE, METHODS } = React.useContext(ESemesContext)

    return (
        <ActionBar>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="text"
                        className={classes.customInput}
                        placeholder={translate('search')}
                        value={STATE.filterState.dataFilter ? STATE.filterState.dataFilter : ''}
                        onChange={e => {
                            if (e.target.value.length > 0) {
                                METHODS.filterResults(e.target.value)
                            } else {
                                METHODS.filterResults(false)
                            }
                        }}
                    />
                </div>
                <div style={{ display: 'flex', fontSize: '12px' }}>
                    <DateTimePicker
                        identifier="esemesOverview"
                        startValue={STATE.timeState.apiDate}
                        color={'tertiary'}
                        fromDate={STATE.timeState.apiDate.minus({ hours: STATE.timeState.timeMargins.lower }).ts / 1000}
                        toDate={STATE.timeState.apiDate.plus({ hours: STATE.timeState.timeMargins.upper }).ts / 1000}
                        translate={translate}
                        persist={id => date => {
                            if (id === 'date') {
                                METHODS.handleNewDate(date)
                            } else {
                                METHODS.handleNewTime(date)
                            }
                        }}
                        trainView={true}
                        toggleRealTime={() => {
                            METHODS.handleRealTime()
                        }}
                        realTime={STATE.timeState.allowRefresh}
                        disableFuture={false}
                        hideMusicController={true}
                        syncData={STATE.timeState.syncTime}
                    />
                    <IconButton
                        style={{ minHeight: '40px', marginLeft: 10 }}
                        className={classes.button}
                        icon="refresh"
                        title={translate('performances_refresh')}
                        data-testid="iconButton"
                        onClick={() => {
                            METHODS.handleNewDate()
                        }}
                    />
                </div>
            </div>
        </ActionBar>
    )
}

EsemesActionBar.propTypes = {
    classes: object.isRequired,
    children: node,
    title: string,
}

export default withStyles(styles)(EsemesActionBar)
