import React from 'react'
import { object, bool, func, string } from 'prop-types'
import ErrorMessage from 'components/ErrorMessage'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'
import LindaSettingView from './LindaSettingView'
import { DateTime } from 'luxon'

const changeDate = date => {
    if (date) return DateTime.fromSeconds(parseInt(date))
    else return null
}
const LindaDrawer = props => {
    const {
        isOpen,
        onClose,
        translate,
        onChangeDate,
        lindaSettingDates,
        onSaveClick,
        copyToAllHandler,
        lindaSettingUpdateError,
    } = props

    return (
        <Drawer open={isOpen} anchor="right" onClose={onClose}>
            <form noValidate autoComplete="off">
                <Grid
                    container
                    spacing={2}
                    style={{ width: 500, margin: 16, display: 'flex', justifyContent: 'space-between' }}
                >
                    <Typography variant="h6">{translate('linda_settings_drawer_title')}</Typography>
                    <LindaSettingView
                        copyToAllHandler={copyToAllHandler}
                        value={changeDate(lindaSettingDates.registerToLinda)}
                        title={translate('linda_register_to_linda')}
                        changeDate={onChangeDate}
                        type={'registerToLinda'}
                    />
                    <LindaSettingView
                        copyToAllHandler={copyToAllHandler}
                        value={changeDate(lindaSettingDates.vgProcedureStarted)}
                        title={translate('linda_vg_procedure_started')}
                        changeDate={onChangeDate}
                        type={'vgProcedureStarted'}
                    />
                    <LindaSettingView
                        copyToAllHandler={copyToAllHandler}
                        value={changeDate(lindaSettingDates.vgExecuted)}
                        title={translate('linda_vg_executed')}
                        type={'vgExecuted'}
                        changeDate={onChangeDate}
                    />
                    <LindaSettingView
                        copyToAllHandler={copyToAllHandler}
                        value={changeDate(lindaSettingDates.departureOnHold)}
                        title={translate('linda_departure_on_hold')}
                        type={'departureOnHold'}
                        changeDate={onChangeDate}
                    />
                    <LindaSettingView
                        copyToAllHandler={copyToAllHandler}
                        value={changeDate(lindaSettingDates.personWithLimitedPossibility)}
                        title={translate('linda_person_with_limited_possiblity')}
                        type={'personWithLimitedPossibility'}
                        changeDate={onChangeDate}
                    />
                    <LindaSettingView
                        copyToAllHandler={copyToAllHandler}
                        value={changeDate(lindaSettingDates.extraLindaStop)}
                        title={translate('linda_extra_linda_stop')}
                        changeDate={onChangeDate}
                        type={'extraLindaStop'}
                    />
                    <LindaSettingView
                        copyToAllHandler={copyToAllHandler}
                        value={changeDate(lindaSettingDates.notifyAll)}
                        title={translate('linda_notify_all')}
                        type={'notifyAll'}
                        changeDate={onChangeDate}
                    />
                    <LindaSettingView
                        copyToAllHandler={copyToAllHandler}
                        value={changeDate(lindaSettingDates.receiveAll)}
                        title={translate('linda_receive_all')}
                        type={'receiveAll'}
                        changeDate={onChangeDate}
                    />
                    <LindaSettingView
                        copyToAllHandler={copyToAllHandler}
                        value={changeDate(lindaSettingDates.displayIconuitgesteldvertrek)}
                        title={translate('linda_display_icon_uitgesteld_vertrek')}
                        type={'displayIconuitgesteldvertrek'}
                        changeDate={onChangeDate}
                    />
                    <div>
                        <Button
                            style={{ marginTop: '16px', marginBottom: '16px', marginRight: '5px' }}
                            variant="contained"
                            color="primary"
                            onClick={onSaveClick}
                            >
                            {translate('add_user_confirm_save')}
                        </Button>
                        {lindaSettingUpdateError.length > 0 && 
                        <ErrorMessage error={lindaSettingUpdateError} spacing={false} />}
                    </div>
                </Grid>
            </form>
        </Drawer>
    )
}

LindaDrawer.propTypes = {
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    lindaSettingDates: object,
    onSaveClick: func,
    lindaSettingUpdateError: string,
    onChangeDate: func,
    translate: func,
    copyToAllHandler: func,
}

export default LindaDrawer
