import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import EditTask from '../EditTask'

export class PerformanceTaskDetailEdit extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
        storage: PropTypes.object,
        performanceDetail: PropTypes.object.isRequired,
    }

    getTaskData = (tasks, taskIndex) => {
        const single = tasks.filter(task => task.id === taskIndex)
        return single[0]
    }
    render() {
        const {
            match,
            performanceDetail: { tasks },
        } = this.props

        if (match.params.taskIndex !== undefined)
            return (
                <EditTask
                    storage={this.props.storage}
                    performanceDetail={this.props.performanceDetail}
                    currentTask={match.params.taskIndex}
                    tasks={tasks}
                    task={this.getTaskData(tasks, match.params.taskIndex)}
                />
            )
    }
}

export default compose(withRouter)(PerformanceTaskDetailEdit)
