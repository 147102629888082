export default theme => ({
    documentTableWrapper: {
        padding: `0 ${theme.spacing(3)}px`,
        [theme.breakpoints.down('xs')]: {
            padding: '0px',
        },
        width: '100%',
        minHeight: theme.driveDetailList,
        height: theme.driveDetailList,
        [theme.breakpoints.up('xl')]: {
            height: theme.driveDetailsListForXl,
        },
        marginBottom: theme.spacing(2),
    },
    tableRow: {
        backgroundColor: 'transparent',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
    },
    iconTableCell: {
        width: '100%',
        borderColor: theme.customColors.grey,
    },
    trainIcon: {
        color: '#e0e0e0',
    },
    customFont: {
        fontFamily: 'nmbs',
    },
})
