export default theme => ({
    detailViewRoot: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(8)
    },
    detailViewTitle: {
        marginBottom: theme.spacing(3),
    },
    detailViewInfoText: {
        marginBottom: theme.spacing(1) / 2,
        marginLeft: theme.spacing(1) / 2,
    },
    headerRow: {
        height: 32,
    },
    headerCell: {
        paddingTop: 16,
        paddingBottom: 0,
        borderBottom: 'none',
    },
    line: {
        marginBottom: 0,
    },
    loadingWrapper: {
        height: 150,
    },
    tableRow: {
        backgroundColor: 'transparent',
    },
    tableRowRed: {
        backgroundColor: theme.customColors.darkRed,
    },
    profileDetailCell: {
        maxWidth: 'calc(100vw / 6)',
        paddingTop: '16px',
        paddingBottom: '16px',
        borderColor: theme.customColors.grey,
        wordWrap: 'break-word',
    },
    documentDetailCell: {
        maxWidth: 'calc(100vw / 3)',
        paddingTop: '16px',
        paddingBottom: '16px',
        wordWrap: 'break-word',
        borderColor: theme.customColors.grey,
    },
    profileTableWrapper: {
        width: '100%',
        height: '60vh',
    },
    documentTableWrapper: {
        width: '100%',
        height: '55vh',
    },
})
