import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Paper, Typography } from '@material-ui/core'
import { bool, func, any } from 'prop-types'

const VehicleNotLitteraAlert = ({ open, toggle, translate, vehicleNumber }) => {
    return (
        <Dialog open={open} onClose={toggle} aria-labelledby="form-dialog-title">
            <Paper>
                <DialogContent style={{ minWidth: '600px' }}>
                    <Typography variant="subtitle1">
                        {translate('vehicle_not_littera_alert').replace('{vehicleNumber}', vehicleNumber)}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggle} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Paper>
        </Dialog>
    )
}
VehicleNotLitteraAlert.propTypes = {
    open: bool,
    toggle: func,
    translate: func,
    vehicleNumber: any
}
export default VehicleNotLitteraAlert
