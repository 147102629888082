import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default async (performanceId, taskId, idfNumber) => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/performance/${performanceId}/task/${taskId}/confirm?idfNumber=${idfNumber}`

    return await axios.post(fullUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    })
}
