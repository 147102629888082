import React, { useEffect, useState } from 'react'
import { func, string, object } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'
import { decode } from 'jsonwebtoken'

import AddNewUserDrawerView from './AddNewUserDrawerView'

import removeUserFromGroup from 'modules/UsersAndRoles/Services/removeUserFromGroup'
import getUsersByGroupId from 'modules/UsersAndRoles/Services/getUsersByGroupId'
import addUserToGroup from 'modules/UsersAndRoles/Services/addUserToGroup'

import styles from './styles'

const AddNewUserDrawer = props => {
    AddNewUserDrawer.propTypes = {
        onClose: func.isRequired,
        addNewUserToGroupId: string,
        userGroupName: string,
        store: object.isRequired,
        invertLocalState: func.isRequired,
    }
    const [selectedName, setSelectedName] = useState('')
    const [selectedProfile, setSelectedProfile] = useState(undefined)
    const [userListForGroupId, setUserList] = useState([])
    const [addNewUserCallError, setAddNewUserError] = useState('')
    const [addNewUserCallLoading, setAddNewUserCallLoading] = useState(false)
    const [existingUserId, setUserId] = useState(null)
    const [showEnforce, setShowEnforce] = useState(false)
    const [isUserListLoading, setIsUserListLoading] = useState(false)

    const setName = profile => {
        setSelectedName(`${profile.firstName} ${profile.lastName}`)
        setSelectedProfile(profile)
        let index = isUserExistInGroup(profile)
        if (index > -1) setUserId(userListForGroupId[index].id)
    }
    const isUserExistInGroup = profile => {
        if (userListForGroupId)
            return userListForGroupId.findIndex(function(el) {
                return el.idfNumber === profile.idfNumber
            })
        else return -1
    }
    useEffect(() => {
        getUserListForGroupId()
    }, [])
    const getUserListForGroupId = async () => {
        setIsUserListLoading(true)
        if (props.addNewUserToGroupId) {
            let response = await getUsersByGroupId(props.addNewUserToGroupId)

            if (typeof response.data === 'string') {
                setUserList([])
            } else {
                setUserList(response.data)
            }
            resetToDefault()
        }
        setIsUserListLoading(false)
    }
    const resetToDefault = () => {
        setAddNewUserCallLoading(false)
        setAddNewUserError('')
        setUserId(null)
        setSelectedName('')
        setSelectedProfile(undefined)
    }
    const clearName = () => {
        setSelectedName('')
        setSelectedProfile(undefined)
        setAddNewUserError('')
    }

    const onClose = () => {
        clearName()
        props.onClose()
    }

    const handleDeleteUser = idfNumber => {
        setAddNewUserError('')
        setAddNewUserCallLoading(true)
        let response = removeUserFromGroup(props.addNewUserToGroupId, idfNumber)
        response
            .then(() => {
                idfNumber.toString() === decode(props.store.get('auth')).b_employeeid.toString()
                    ? props.invertLocalState()
                    : getUserListForGroupId()
            })
            .catch(e => {
                console.error(e)
                setAddNewUserCallLoading(false)
                setAddNewUserError(e.response.data.Message)
            })
    }
    const addNewUserCall = force => {
        setAddNewUserCallLoading(true)
        setAddNewUserError('')
        let response = addUserToGroup(props.addNewUserToGroupId, selectedProfile.idfNumber, force)
        response
            .then(() => {
                selectedProfile.idfNumber.toString() === decode(props.store.get('auth')).b_employeeid.toString()
                    ? props.invertLocalState()
                    : getUserListForGroupId()
            })
            .catch(e => {
                console.error(e)
                if (
                    e.response.status === 400 &&
                    e.response.data.Message === 'User group is already assigned to this profile.'
                ) {
                    setShowEnforce(!showEnforce)
                }
                setAddNewUserCallLoading(false)
                setAddNewUserError(e.response.data.Message)
            })
    }
    return (
        <AddNewUserDrawerView
            {...props}
            selectedName={selectedName}
            selectedProfile={selectedProfile}
            handleDeleteUser={handleDeleteUser}
            setName={setName}
            clearName={clearName}
            onClose={onClose}
            userListForGroupId={userListForGroupId}
            addNewUserCall={addNewUserCall}
            addNewUserCallError={addNewUserCallError}
            addNewUserCallLoading={addNewUserCallLoading}
            userGroupName={props.userGroupName}
            existingUserId={existingUserId}
            enforce={showEnforce}
            setEnforce={setShowEnforce}
            setErrorMessage={setAddNewUserError}
            isUserListLoading={isUserListLoading}
        />
    )
}

export default compose(withStore, withStatus, withStyles(styles), withLocalize)(AddNewUserDrawer)
