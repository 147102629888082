import { DateTime } from 'luxon'

export const timeParser = (time, tz = 'Europe/Brussels') =>
    DateTime.fromMillis(time * 1000)
        .setZone(tz)
        .toFormat("HH':'mm")

export const setUTCTimeZone = time => DateTime.fromSeconds(time).setZone('UTC')

export const utcTimeParser = (time, tz = 'UTC') =>
    DateTime.fromSeconds(time)
        .setZone(tz)
        .toFormat("HH':'mm")

export const utcDateTimeParser = (time, tz = 'UTC') =>
    DateTime.fromSeconds(time)
        .setZone(tz)
        .toFormat('ddMMyyyy HH:mm')

export const utcDateTimeParserSlash = (time, tz = 'UTC') =>
    DateTime.fromSeconds(time)
        .setZone(tz)
        .toFormat('HH:mm dd/MM/yyyy')

export const utcDateTimeParserSlashReformat = (time, tz = 'UTC') =>
    DateTime.fromSeconds(time)
        .setZone(tz)
        .toFormat('dd/MM/yyyy HH:mm')

export const localDateFormatter = (time, tz = 'Europe/Brussels') => DateTime.fromMillis(time).setZone(tz)

export default timeParser
