export default theme => ({
    drawerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '550px',
        padding: `${theme.spacing(3)}px`,
    },
    textField: {
        fontSize: 10,
        lineHeight: 2.4,
    },
    twoElementDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    saveButton: {
        padding: '16px 24px',
    },
    deleteIcon: {
        zIndex: 1,
        cursor: 'pointer',
        color: theme.customColors.red,
        '&:hover': {
            color: theme.customColors.grey,
        },
        marginLeft: '-20px', 
        marginBottom: '8px', 
        fontSize: '20px',
    },
    textfield: {
        flexShrink: 0,
        '& input': {
            paddingRight: '24px',
        },
    },
    ptdesLookupContainer: {
        display: 'flex', 
        alignItems: 'end',
        marginBottom: '8px'
    }
})
