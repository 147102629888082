import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Typography, withStyles, TextField } from '@material-ui/core'
import { withStore } from 'react-ion-store'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'

import ErrorMessage from 'components/ErrorMessage'
import IconButton from 'components/IconButton'
import SelectInput from 'components/SelectInput'

import styles from './styles'
import { TrainNumberDataStore } from 'context/trainNumberData'

const ThirdStep = props => {
    const { trainPrefix, trainNumber, classes, translate, loadTrain, loading, taskType, noTrainData, activeLanguage } = props

    const { getTrainNumberPrefixes } = React.useContext(TrainNumberDataStore)

    const handleEvent = type => e => props.persist(type)(e.target.value)

    //@INFO: case of drive, no prefix.
    // IF no trainnumber required skip this step.

    const trainNumberPrefixes = getTrainNumberPrefixes(activeLanguage?.code)
    const isBus = taskType.toUpperCase() === 'BUS'

    if (trainNumberPrefixes.length === 0) {
        return <ErrorMessage spacing={false} error="Fetch of train prefixes failed" />
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h6">
                    {isBus
                        ? translate('performance_task_add_label_select_bus')
                        : translate('performance_task_add_label_select_train')}
                </Typography>
            </Grid>
            {/* {taskType !== "Drive" && ( */}
            {!isBus && (
                <Grid item xs={3}>
                    <div style={{ display: 'inline-block', maxWidth: '100%' }}>
                        <SelectInput
                            name="trainPrefix"
                            classes={classes}
                            items={trainNumberPrefixes}
                            value={trainPrefix}
                            onChange={handleEvent('trainPrefix')}
                        />
                    </div>
                </Grid>
            )}

            <Grid item xs={3}>
                <TextField
                    data-testid="train-number-input"
                    fullWidth={true}
                    value={trainNumber}
                    onChange={handleEvent('trainNumber')}
                    placeholder={
                        isBus ? translate('performance_task_add_label_select_bus') : translate('add_task_train_number')
                    }
                />
            </Grid>

            {(taskType === 'DRIVE' || taskType === 'HLP') && (
                <Grid item xs={3}>
                    <IconButton
                        data-testId="add-train-number-task-button"
                        disabled={loading}
                        className={classes.marginButton}
                        icon=""
                        color="primary"
                        title={loading ? 'loading...' : translate('add_task_load_train')}
                        onClick={() => {
                            loadTrain()
                        }}
                    />
                </Grid>
            )}
            {noTrainData && (
                <Grid item xs={3}>
                    <ErrorMessage spacing={false} error={translate('performances_label_drivedetails_no_data')} />
                </Grid>
            )}

            {/*
    <Grid item xs={5}>
    Button
    </Grid> */}
            <div className={classes.stepBorder} />
        </React.Fragment>
    )
}

ThirdStep.propTypes = {
    classes: PropTypes.object,
    trainPrefix: PropTypes.string,
    trainNumber: PropTypes.string,
    translate: PropTypes.func,
    loadTrain: PropTypes.func,
    loading: PropTypes.bool,
    taskType: PropTypes.string,
    noTrainData: PropTypes.bool,
    persist: PropTypes.func,
    activeLanguage: PropTypes.object,
}

export default compose(withStore, withLocalize, withStyles(styles))(ThirdStep)
