import React from 'react'
import { bool, func, object, array } from 'prop-types'
import { compose, pure } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import { PaperBox } from 'components/PaperBox'

import Loading from 'components/Loading'
import { timeParser } from 'utils/timeParser'

import styles from './styles'

const getReadTime = time => {
    if (time) return `( ${timeParser(time)} )`
    else return ''
}
const PerformanceNotificationDrawerView = ({ isOpen, close, classes, data, translate }) => {
    const showNotifications = notification => {
        if (notification.isConfirmed) {
            return (
                <PaperBox
                    redBox={false}
                    value={`${getReadTime(notification.confirmationDate)} - ${translate(
                        'performances_label_notification_marked_read'
                    )}`}
                />
            )
        } else {
            return <PaperBox redBox={true} value={translate('performances_label_notification_no_action')} />
        }
    }

    return (
        <Drawer open={isOpen} style={{ zIndex: 1300 }} anchor="right" onClose={close}>
            {!data && (
                <div className={classes.drawerRoot}>
                    <div>
                        <Loading />
                    </div>
                </div>
            )}
            {data && data.length === 0 && (
                <div className={classes.drawerRoot}>
                    <div className={classes.drawerHeader}>
                        <Typography variant="h6">
                            <Translate id="performances_label_notification_title" />
                        </Typography>
                    </div>
                    <Typography variant="h6">
                        <Translate id="performances_label_notification_sub_title" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <Typography variant="body1">{translate('performances_label_drivedetails_no_data')}</Typography>
                </div>
            )}
            {data && data.length > 0 && (
                <div className={classes.drawerRoot}>
                    <div className={classes.drawerHeader}>
                        <Typography variant="h6">
                            <Translate id="performances_label_notification_title" />
                        </Typography>
                    </div>
                    <Typography variant="h6">
                        <Translate id="performances_label_notification_sub_title" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />

                    {data.map(item => {
                        return (
                            <div key={item.notificationId} style={{ marginBottom: '36px' }}>
                                <Typography variant="h6">{`${item.message} ${item.notificationTitle}`}</Typography>
                                <Typography variant="body1">{`${item.creationTime.substring(0, 5)} `}</Typography>
                                {showNotifications(item)}
                            </div>
                        )
                    })}
                </div>
            )}
        </Drawer>
    )
}

PerformanceNotificationDrawerView.propTypes = {
    classes: object.isRequired,
    close: func.isRequired,
    translate: func.isRequired,
    isOpen: bool.isRequired,
    data: array,
}

export default compose(withStyles(styles), withLocalize, pure)(PerformanceNotificationDrawerView)
