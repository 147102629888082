export default theme => ({
    drawerRoot: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '80vh',
        maxHeight: '100vh',
        // overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
    },
    readList: {
        maxHeight: `calc(100vh - ${theme.readList + 116}px)`,
        overflowY: 'auto',
        marginBottom: theme.spacing(2),
    },
    profileInfo: {
        marginTop: theme.spacing(2),
    },
    profileInfoTitle: {
        fontWeight: 'bold',
    },
    inputLabel: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    topSpacingTitle: {
        marginTop: theme.spacing(3),
    },
    bottomTextField: {
        marginBottom: theme.spacing(3),
    },
    ptCarSelect: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(6),
    },
    ptcarAddButton: {
        flexBasis: 0,
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    column: {
        float: 'left',
        width: '50%',
        padding: '10px',
    },
    ptCarlist: {
        maxHeight: '50%',
        marginBottom: '32px'
    },
    deleteIcon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        color: theme.customColors.red,
        '&:hover': {
            color: theme.customColors.grey,
        },
    },
    buttonBottom: {
        position: 'absolute',
        bottom: `${theme.spacing(3)}px`,
        left: `${theme.spacing(3)}px`,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        color: theme.customColors.lightGrey,
        backgroundColor: theme.palette.grey['800'],
        border: '1px solid white',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        padding: theme.spacing(1),
    },
    searchError: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        color: theme.customColors.lightGrey,
        backgroundColor: theme.palette.grey['800'],
        border: '1px solid red',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        padding: theme.spacing(1),
    },
    searchIcon: {
        color: theme.customColors.lightGrey,
        width: theme.spacing(3),
        height: '36px',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textfieldContainer:{
        display: 'flex', 
        justifyContent: 'space-between',
    },
    checkboxContainer: {
        width: '43.5%', 
        position: 'relative', 
        top: '42px' ,
    },
})
