import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import IconButton from 'components/IconButton'
import Grid from '@material-ui/core/Grid'
import { uid } from 'react-uid'
import ErrorMessage from 'components/ErrorMessage'
import { Typography } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import InputLabel from '@material-ui/core/InputLabel'
import SelectInput from 'components/SelectInput'
import Loading from 'components/Loading'
import { TranslationContext } from 'context/translation'

const overlapErrorMsg = 'Please check your values.'

const textField = (label, value, callback, register, errors, name, index, required) => {
    return (
        <TextField
            margin="dense"
            inputRef={register !== undefined ? register({ required: required }) : null}
            error={errors && !!errors[`${name + index}`]}
            name={name + index}
            label={label}
            type={'number'}
            fullWidth
            defaultValue={value}
            onChange={e => {
                callback(index, e.target.value, name)
            }}
        />
    )
}

function PneumaticSuspensionEditForm(props) {
    const { handleAdd, error, editDrawer, editData, classes, materialTypes, addEditLoading } = props

    const { translate } = React.useContext(TranslationContext)

    const [type, setType] = useState(editDrawer ? editData?.materialSubTypeId || '' : 1)
    const { register, handleSubmit, errors, control, setValue } = useForm()
    const [isValidationRequired, setIsValidationRequired] = useState(false)
    const [newValues, setNewValues] = useState({})
    const [isExist, setIsExist] = useState(false)
    const [errorMsg, setErrorMsg] = useState()

    const submitTheForm = () => {
        let dataToServer = {}
        let restrictionPercentages = [...data]
        dataToServer.id = props.editData.id
        dataToServer.materialSubTypeId = type
        let valueExists = false

        //check for edit all min values are smaller than max
        for (let x of restrictionPercentages) {
            if (x.minimumNumberOfBogies > x.maximumNumberOfBogies || x.maximumNumberOfBogies === 0) {
                valueExists = true
                setIsExist(true)
                setErrorMsg(overlapErrorMsg)
                break
            }
        }

        if (newValues.maximumNumberOfBogies === 0) {
            // valueExists = true
            setIsExist(true)
            setErrorMsg(overlapErrorMsg)
            return
        }

        //check if new values added
        if (newValues.maximumNumberOfBogies) {
            // make sure max value is greater than min for new values to be added.
            if (newValues.maximumNumberOfBogies < newValues.minimumNumberOfBogies && newValues.maximumNumberOfBogies !== 0) {
                // valueExists = true
                setIsExist(true)
                setErrorMsg(overlapErrorMsg)
                return
            }
            // make sure new values are not super set of old values
            // make sure new values are not sub set of old values
            for (let x of restrictionPercentages) {
                if (
                    (x.minimumNumberOfBogies >= newValues.minimumNumberOfBogies &&
                        x.maximumNumberOfBogies <= newValues.maximumNumberOfBogies) ||
                    (newValues.minimumNumberOfBogies >= x.minimumNumberOfBogies &&
                        newValues.minimumNumberOfBogies <= x.maximumNumberOfBogies)
                ) {
                    valueExists = true
                    setIsExist(true)
                    setErrorMsg(overlapErrorMsg)
                    break
                }
            }
        }

        if (isValidationRequired) {
            restrictionPercentages.push(newValues)
        }
        if (!valueExists) {
            dataToServer.RestrictionPercentages = restrictionPercentages
            handleAdd(dataToServer, editDrawer)
        }
    }

    const setEditData = (index, value, key) => {
        let dataToEdit = [...data]
        if (key !== 'restrictionPercentage') {
            let valueExists = checkValueAlreadyExist(value, dataToEdit)
            setIsExist(valueExists)
            setErrorMsg(overlapErrorMsg)
        }
        dataToEdit[index][key] = parseInt(value)
        setData(dataToEdit)
    }
    const checkValueAlreadyExist = (value, dataToCheck) => {
        let valueExists = false
        for (let x of dataToCheck) {
            if (value <= x.maximumNumberOfBogies && value >= x.minimumNumberOfBogies) {
                valueExists = true
                break
            }
        }
        return valueExists
    }

    const addNewData = (value, key) => {
        setIsExist(false)
        setErrorMsg('')
        let addNewValues = { ...newValues }
        let valueExists = false
        let oldData = [...data]
        if (key !== 'restrictionPercentage') {
            valueExists = checkValueAlreadyExist(value, oldData)
            setErrorMsg(overlapErrorMsg)
            setIsExist(valueExists)
        }
        addNewValues[key] = parseInt(value)
        setNewValues(addNewValues)
        let keys = Object.keys(addNewValues)
        let flag = false
        for (let newValueKey of keys) {
            if (addNewValues[newValueKey]) {
                flag = true
                break
            }
        }

        setIsValidationRequired(flag)
    }

    const handleDelete = index => {
        let dataArrayCopy = [...data]
        dataArrayCopy.splice(index, 1)
        setData(dataArrayCopy)
    }

    let dataArray = props.editData.restrictionPercentages.map(a => ({ ...a }))
    const [data, setData] = useState(dataArray)

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(submitTheForm)} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ display: 'block', marginTop: '18px', marginBottom: '18px' }}>
                            <InputLabel htmlFor={'materialSubTypeId'}>{'materialSubTypeId'}</InputLabel>
                            <Controller
                                as={
                                    <SelectInput
                                        minWidth={200}
                                        error={errors && !!errors[`materialSubTypeId`]}
                                        id="materialSubTypeId"
                                        classes={classes}
                                        name="materialSubTypeId"
                                        items={materialTypes}
                                    />
                                }
                                onChange={([event]) => {
                                    setType(event.target.value)
                                    setTimeout(() => setValue('materialSubTypeId', event.target.value, true))
                                }}
                                defaultValue={type}
                                rules={{ required: true, minLength: 1 }}
                                register={register}
                                name="materialSubTypeId"
                                control={control}
                                disabled={true}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography>{'#Bogies'}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{'#Limitations'}</Typography>
                    </Grid>
                    {data.map((row, index) => {
                        let key = uid(row)

                        return (
                            <React.Fragment key={key}>
                                <Grid item xs={6}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ marginRight: '5px' }}>
                                            {textField(
                                                'from',
                                                row.minimumNumberOfBogies,
                                                setEditData,
                                                register,
                                                errors,
                                                'minimumNumberOfBogies',
                                                index,
                                                true
                                            )}
                                        </div>
                                        {textField(
                                            'to',
                                            row.maximumNumberOfBogies,
                                            setEditData,
                                            register,
                                            errors,
                                            'maximumNumberOfBogies',
                                            index,
                                            true
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    {textField(
                                        'limitations',
                                        row.restrictionPercentage,
                                        setEditData,
                                        register,
                                        errors,
                                        'restrictionPercentage',
                                        index,
                                        true
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    <Delete className={classes.deleteIcon} onClick={() => handleDelete(index)} />
                                </Grid>
                            </React.Fragment>
                        )
                    })}

                    <React.Fragment>
                        <Grid item xs={6}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: '5px' }}>
                                    {textField(
                                        'from',
                                        '',
                                        addNewData,
                                        register,
                                        errors,
                                        'minimumNumberOfBogies',
                                        data.length,
                                        isValidationRequired
                                    )}
                                </div>
                                {textField(
                                    'to',
                                    '',
                                    addNewData,
                                    register,
                                    errors,
                                    'maximumNumberOfBogies',
                                    data.length,
                                    isValidationRequired
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            {textField(
                                'limitations',
                                '',
                                addNewData,
                                register,
                                errors,
                                'restrictionPercentage',
                                data.length,
                                isValidationRequired
                            )}
                        </Grid>
                    </React.Fragment>

                    <Grid item xs={12}>
                        {error && error?.length > 0 && (
                            <ErrorMessage
                                error={editDrawer ? translate('platform_edit_failed') : translate('platform_add_failed')}
                                spacing={false}
                            />
                        )}
                        {isExist && <ErrorMessage error={errorMsg} spacing={false} />}
                        <IconButton
                            disabled={isExist || addEditLoading}
                            type="submit"
                            icon="save"
                            title={translate('add_user_confirm_save')}
                        />
                        {addEditLoading && <Loading />}
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

PneumaticSuspensionEditForm.propTypes = {
    classes: PropTypes.object,
    handleAdd: PropTypes.func,
    error: PropTypes.string,
    editDrawer: PropTypes.bool,
    addEditLoading: PropTypes.bool,
    editData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    materialTypes: PropTypes.array,
}

export default PneumaticSuspensionEditForm
