export default theme => ({
    calendarContainer: {
        fontFamily: theme.typography.fontFamily,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    },
    calendarButton: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    calendarDatePicker: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '12px',
        },
    },
    'tui-full-calendar-weekday-grid-date': {
        color: 'blue',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    submenu: {
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    submenuTitle: {
        marginBottom: theme.spacing(2),
    },
})
