import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'
import { withStore, withStatus } from 'react-ion-store'
import PtdesListView from './PtdesListView'


import { withLocalize } from 'react-localize-redux'

import withFunctionlityCheck from 'utils/hocs/withFunctionalityCheck'
import withHocs from 'utils/hocs/withHocs'

function PtdesOverview() {

    return <PtdesListView/>
    
}
PtdesOverview.propTypes = {
    activeLanguage: PropTypes.object,
}
export default withHocs(withLocalize, withRouter, withStore, withStatus, withFunctionlityCheck)(PtdesOverview)
