export default theme => ({
    compositionView: {
        textAlign: 'center',
        width: '12%',
        marginLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '33%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '40%',
        },
    },
    arrowIcon: {
        transform: 'rotate(-90deg)',
    },
    editIcon: {
        color: theme.customColors.primaryBlue,
        '&:hover': {
            color: theme.customColors.lightGrey,
            [theme.breakpoints.down('xs')]: {
                color: theme.customColors.primaryBlue,
            },
        },
        cursor: 'pointer',
    },
    changeDirection: {
        color: theme.customColors.primaryBlue,
        '&:hover': {
            color: theme.customColors.lightGrey,
            [theme.breakpoints.down('xs')]: {
                color: theme.customColors.primaryBlue,
            },
        },
        cursor: 'pointer',
    },
    disabled: {
        color: theme.customColors.lightGrey,
    },
})
