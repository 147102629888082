import React from 'react'
import { Translate } from 'react-localize-redux'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'

import ActionsColumn from '../ActionsColumn'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import DelayIndicator from '../DelayIndicator'

import timeParser from 'utils/timeParser'

export default (
    cellProps,
    { classes, rowHeight, match, storage, openPerformanceDriveDetailsDrawer, openPerformanceNotificationDrawer }
) => {
    const { dataKey, rowData } = cellProps

    let data = rowData[dataKey]
    if (dataKey === 'actions') {
        return (
            <ActionsColumn
                classes={classes}
                dataKey={dataKey}
                rowData={rowData}
                rowHeight={rowHeight}
                openPerformanceDriveDetailsDrawer={openPerformanceDriveDetailsDrawer}
                openPerformanceNotificationDrawer={openPerformanceNotificationDrawer}
            />
        )
    }
    if (
        (dataKey === 'plannedStartTime' &&
            (rowData.status !== 2 || (rowData.status === 2 && rowData.plannedStartDelay <= 0))) ||
        dataKey === 'plannedEndTime'
    )
        data = (
            <Typography style={{ color: 'white' }} variant="body2">
                {timeParser(rowData[dataKey])}
            </Typography>
        )
    else if (dataKey === 'plannedStartTime' && rowData.status === 2 && rowData.plannedStartDelay > 0) {
        data = (
            <Typography style={{ color: 'white' }} variant="body2">
                {timeParser(rowData[dataKey])} <DelayIndicator delay={rowData.plannedStartDelay} />
            </Typography>
        )
    } else if (dataKey === 'performanceId') {
        data = [
            <Link
                className={classes.link}
                key={rowData.performanceId + '_id'}
                target="_blank"
                to={`${match.path}/detail/${rowData.id}/profile/${rowData.idfNumber}`}
                style={{
                    pointerEvents: checkFunctionalityDisabled(
                        storage,
                        'view',
                        'performances.functionality.management.performanceDetail'
                    )
                        ? 'none'
                        : 'auto',
                }}
                onClick={e => {
                    e.stopPropagation()
                }}
            >
                <Typography
                    className={classNames(classes.blockText, classes.clickText)}
                    key={rowData.performanceId + '_id'}
                    variant="body2"
                >
                    {rowData.performanceId}
                </Typography>
            </Link>,
            <Typography className={classes.blockText} key={rowData.performanceId + '_depot'} variant="caption">
                Depot <Translate id={`DEPOT-${rowData.depotName}`} />
                {rowData.versionNumber ? ` -${rowData.performanceNumber} (${rowData.versionNumber})` : ''}
            </Typography>,
        ]
    } else if (dataKey === 'profileName') {
        data = [
            <>
                <Typography
                    key={rowData.performanceId + '_name'}
                    className={classNames(classes.blockText, classes.clickText)}
                    variant="body2"
                >
                    {rowData.profileName}
                </Typography>

                <Typography className={classNames(classes.plainText)} key={rowData.performanceId + '_idf'} variant="caption">
                    {rowData.idfNumber}
                </Typography>
            </>,
        ]
    }

    return (
        <TableCell
            component="div"
            className={classNames(classes.tableCell, {
                [classes.flexContainer]: dataKey === 'plannedStartTime' || dataKey === 'plannedEndTime',
                [classes.tableRowRed]: rowData.status === 2,
                [classes.tableRowYellow]: rowData.status === 1,
            })}
            variant="body"
            style={{ height: rowHeight }}
        >
            {data}
        </TableCell>
    )
}
