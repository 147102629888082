import React from 'react'
import { AutoSizer, Column, Table, defaultTableRowRenderer } from 'react-virtualized'
import { TaskSemesContext } from '../semes.store'

import { func, array, object } from 'prop-types'
import classNames from 'classnames'

const FullList = ({ classes, listColumns, headerRenderer, cellRenderer }) => {
    let defaultHeaderHeight = 56
    let defaultRowHeight = 60

    const { STATE } = React.useContext(TaskSemesContext)

    return (
        <div data-testid="listview" className={classes.documentTableWrapper}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        className={classes.table}
                        height={height}
                        width={width}
                        rowCount={
                            STATE.filterState.dataFilter
                                ? STATE.filterState.filterdResults.length
                                : STATE.apiState.eSemesData.length
                        }
                        rowClassName={classNames(classes.tableRow, classes.flexContainer)}
                        rowRenderer={defaultTableRowRenderer}
                        rowGetter={({ index }) =>
                            STATE.filterState.dataFilter
                                ? STATE.filterState.filterdResults[index]
                                : STATE.apiState.eSemesData[index]
                        }
                        rowHeight={defaultRowHeight}
                        headerHeight={defaultHeaderHeight}
                    >
                        {listColumns.map(({ dataKey, ...other }) => (
                            <Column
                                key={dataKey}
                                headerRenderer={headerRenderer}
                                className={classes.flexContainer}
                                cellRenderer={cellRenderer}
                                dataKey={dataKey}
                                {...other}
                            />
                        ))}
                    </Table>
                )}
            </AutoSizer>
        </div>
    )
}

FullList.propTypes = {
    classes: object,
    listColumns: array,
    headerRenderer: func,
    cellRenderer: func,
}

export default FullList
