import mapKeysToLowerCase from 'utils/mapKeysToLowerCase'

export default dataElement => {
    const { nl, fr } = mapTranslationDataToObject(dataElement)

    return {
        [dataElement.id]: [nl, fr],
    }
}

export const mapTranslationDataToObject = ({ translations }) => {
    const translationObject = { nl: '', fr: '' }

    translations.forEach(translation => {
        const { language, translation: langTranslation } = mapKeysToLowerCase(translation)
        if (language === 'nl-BE') translationObject.nl = langTranslation
        if (language === 'fr-BE') translationObject.fr = langTranslation
    })

    return translationObject
}
