import React from 'react'
import { object, func, string, bool } from 'prop-types'
import { withLocalize, Translate } from 'react-localize-redux'
import { compose } from 'recompose'
import { withStore, util, enums, withStatus } from 'react-ion-store'
import axios from 'axios'
// Material UI
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ErrorMessage from 'components/ErrorMessage'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import PrimaryButton from 'components/PrimaryButton'

import styles from './styles'
import Loading from 'components/Loading'

const resetState = {
    selectedName: '',
    selectedProfile: undefined,
    validateError: '',
    validateLoading: false,
    gprError: '',
    resetLoading: false,
    resetError: '',
}

const {
    CallStatus: { READY },
} = enums

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

class SignedOffDetailView extends React.PureComponent {
    static propTypes = {
        classes: object.isRequired,
        closeDrawer: func.isRequired,
        performance: object.isRequired,
        __ion_status: object.isRequired,
        store: object.isRequired,
        printButtonHandler: func,
        reasonEndTimeChanged: string,
        reasonStartTimeChanged: string,
        onStartReasonChange: string,
        onEndReasonChange: func,
        adjustTimes: func,
        translate: func,
        adjustError: string,
        adjustLoading: bool,
    }

    state = {
        ...resetState,
        actionWasSet: false,
        validateError: '',
        validateLoading: false,
        gprLoading: false,
        resetLoading: false,
        resetError: '',
    }

    setName = selectedProfile =>
        this.setState({
            selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
            selectedProfile,
            readStateWasSet: false,
        })

    clearName = () => this.setState(resetState)

    persistAssignDriver = (idf, id, performanceIdfNumber) => () => {
        this.setState({ ...resetState, actionWasSet: 'assignDriverCall' }, () => {
            const assignDriverCall = util.CreateStoreObject({ idf, id, performanceIdfNumber })
            this.props.store.call({ assignDriverCall })
        })
    }

    validatePerformance = performance => {
        this.setState({ validateLoading: true, validateError: '', gprError: '' }, () =>
            this.validatePerformanceCall(performance)
        )
    }
    validatePerformanceCall = async performance => {
        const { url, subscriptionKey } = requestData
        const { translate } = this.props
        const fullUrl = `${url}/performance/${performance.performanceId}/validate/${performance.idfNumber}?isValidated=true`

        try {
            const response = await axios.post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.setState({ validateLoading: false })
                this.props.closeDrawer(true)
            }
        } catch (e) {
            if (e.response && e.response.status && e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({ validateError: translate('roles_error'), validateLoading: false })
                    return false
                }
                this.setState({ validateError: e.response.data.Message, validateLoading: false })
            } else {
                this.setState({ validateError: 'Performance could not be validated', validateLoading: false })
            }
        }
    }

    sendToGprCall = async performance => {
        this.setState({ validateError: '', gprLoading: true })
        const { url, subscriptionKey } = requestData
        const { translate } = this.props
        const fullUrl = `${url}/gprResend?performanceId=${performance.performanceId}&idfNumber=${performance.idfNumber}`

        //create a new axios instance to overwrite the global ocpm sub key
        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        try {
            const response = await instance.post(fullUrl, {})
            if (response.status === 200) {
                this.props.closeDrawer()
                this.setState({ gprError: '', gprLoading: false })
            }
        } catch (e) {
            if (e.response && e.response.status && e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({ gprError: translate('roles_error'), gprLoading: false })
                    return false
                }
                this.setState({ gprError: e.response.data.Message, gprLoading: false })
            } else {
                this.setState({ gprError: 'Error occured', gprLoading: false })
            }
        }
    }
    resetPerformanceCall = async performance => {
        this.setState({ resetError: '', resetLoading: true })
        const { url, subscriptionKey } = requestData
        const { translate } = this.props

        const fullUrl = `${url}/performance/${performance.performanceId}/reset/${performance.idfNumber}`

        try {
            const response = await axios.post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 200) {
                this.props.closeDrawer(true)
                this.setState({ resetError: '', resetLoading: false })
            }
        } catch (e) {
            if (e.response && e.response.status && e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({ resetError: translate('roles_error'), resetLoading: false })
                    return false
                }
                this.setState({ resetError: e.response.data.Message, resetLoading: false })
            } else {
                this.setState({ resetError: 'Error occured', resetLoading: false })
            }
        }
    }
    componentDidUpdate(prevProps) {
        const { actionWasSet } = this.state

        if (
            actionWasSet &&
            prevProps.__ion_status[actionWasSet] &&
            prevProps.__ion_status[actionWasSet] !== READY &&
            this.props.__ion_status[actionWasSet] === READY
        ) {
            this.props.closeDrawer(true)
        }
    }
    checkDisabled = () => {
        const { reasonEndTimeChanged, reasonStartTimeChanged, adjustLoading } = this.props

        if (adjustLoading) {
            return true
        }
        if (reasonEndTimeChanged.length === 0 && reasonStartTimeChanged.length === 0) {
            return true
        }
        return false
    }
    getLoadingComponent = () => {
        return (
            <div style={{ height: '100px' }}>
                <Loading />
            </div>
        )
    }
    render() {
        const {
            performance,
            classes,
            reasonEndTimeChanged,
            reasonStartTimeChanged,
            onStartReasonChange,
            onEndReasonChange,
            adjustTimes,
            adjustError,
            printButtonHandler,
        } = this.props

        const { validateLoading, validateError, resetLoading } = this.state

        const { isValidated } = performance

        return (
            <Translate>
                {({ translate }) => (
                    <React.Fragment>
                        {
                            <PrimaryButton
                                style={{ width: '100%', marginBottom: 16 }}
                                className={classes.button}
                                title={translate('performance_reset_sign_out')}
                                onClick={() => this.resetPerformanceCall(performance)}
                                disabled={
                                    resetLoading ||
                                    checkFunctionalityDisabled(
                                        this.props.store,
                                        'reset',
                                        'performances.functionality.sidebar'
                                    )
                                }
                            />
                        }
                        {resetLoading && this.getLoadingComponent()}

                        {this.state.resetError.length > 0 && <ErrorMessage error={this.state.resetError} />}
                        <Typography variant="h6">{translate('performances_label_accountability')}</Typography>
                        <hr style={{ marginBottom: 16 }} />
                        <div className={classes.mb}>
                            <TextField
                                fullWidth={true}
                                name="districtFilter"
                                label={translate('start_label')}
                                value={reasonStartTimeChanged}
                                onChange={e => onStartReasonChange(e)}
                                style={{ marginBottom: 16 }}
                                disabled={checkFunctionalityDisabled(
                                    this.props.store,
                                    'adapt hours',
                                    'performances.functionality.sidebar'
                                )}
                            />
                            <TextField
                                fullWidth={true}
                                name="districtFilter"
                                label={translate('end_label')}
                                value={reasonEndTimeChanged}
                                onChange={e => onEndReasonChange(e)}
                                disabled={checkFunctionalityDisabled(
                                    this.props.store,
                                    'adapt hours',
                                    'performances.functionality.sidebar'
                                )}
                            />
                            <div className={classes.mb} />
                            <PrimaryButton
                                style={{ width: '100%' }}
                                className={classes.button}
                                title={translate('management_button_edit')}
                                onClick={() => adjustTimes(performance)}
                                disabled={
                                    checkFunctionalityDisabled(
                                        this.props.store,
                                        'adapt hours',
                                        'performances.functionality.sidebar'
                                    ) || this.checkDisabled()
                                }
                            />
                            {adjustError.length > 0 && <ErrorMessage error={adjustError} />}
                        </div>
                        <div className={classes.mb} />
                        {this.props.adjustLoading && this.getLoadingComponent()}

                        <Typography variant="h6">{translate('performances_label_validate')}</Typography>
                        <hr style={{ marginBottom: 16 }} />
                        {!isValidated ? (
                            <React.Fragment>
                                <Button
                                    style={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        width: '100%',
                                    }}
                                    variant="contained"
                                    disabled={
                                        checkFunctionalityDisabled(
                                            this.props.store,
                                            'validate',
                                            'performances.functionality.sidebar'
                                        ) || validateLoading
                                    }
                                    onClick={() => {
                                        this.validatePerformance(performance)
                                    }}
                                >
                                    {translate('performances_label_validate')}
                                </Button>
                                {validateError.length > 0 && <ErrorMessage error={validateError} />}
                            </React.Fragment>
                        ) : (
                            <Typography variant="body1">{translate('performances_drawer_validated')}</Typography>
                        )}
                        <div className={classes.mb} />
                        <Typography variant="h6">{translate('performances_label_pupils')}</Typography>
                        <hr style={{ marginBottom: 16 }} />
                        {isValidated && performance.sentToGpr && (
                            <PrimaryButton
                                style={{ width: '100%' }}
                                className={classes.button}
                                title={'Resend To GPR'}
                                onClick={() => this.sendToGprCall(performance)}
                                disabled={this.state.gprLoading}
                            />
                        )}
                        {this.state.gprError.length > 0 && <ErrorMessage error={this.state.gprError} />}
                        <PrimaryButton
                            disabled={checkFunctionalityDisabled(
                                this.props.store,
                                'print_perf_detail',
                                'performances.functionality.sidebar'
                            )}
                            className={classes.fullButton}
                            onClick={() => printButtonHandler(performance)}
                        >
                            <Typography>
                                <Translate id="print_performances" />
                            </Typography>
                        </PrimaryButton>
                    </React.Fragment>
                )}
            </Translate>
        )
    }
}

export default compose(withStyles(styles), withLocalize, withStore, withStatus)(SignedOffDetailView)
