import React from 'react'

import { func, object, string, number, array } from 'prop-types'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { Delete, Edit } from '@material-ui/icons'
import Button from '@material-ui/core/Button'

import Loading from 'components/Loading'
import DeleteDialog from './components/DeleteThresholdDialog'
import ThresholdTable from './components/ThresholdTable'
import ThresholdDrawer from './components/ThresholdDrawer'

import getCosmosDbPerformancesTestCase from './Services/getCosmosDbPerformancesTestCase'
import addEditTestCase from './Services/addEditTestCase'

import AlertType from './Enums/AlertType'
import SettingType from './Enums/SettingType'

import styles from './styles'

import { cloneDeep } from 'lodash'

const columns = [
    {
        width: 150,
        label: 'datatable_header_threshold_name',
        dataKey: 'name',
    },
    {
        width: 150,
        label: 'datatable_header_threshold_range_text',
        dataKey: 'range_text',
    },
    {
        width: 150,
        label: 'datatable_header_threshold_n67d',
        dataKey: 'n67d',
    },
    {
        width: 150,
        label: 'datatable_header_threshold_n67nd',
        dataKey: 'n67nd',
    },
    {
        width: 150,
        label: 'datatable_header_threshold_n67a',
        dataKey: 'n67a',
    },

    {
        width: 100,
        label: 'datatable_header_threshold_name',
        dataKey: 'total',
    },
    {
        width: 150,
        label: 'datatable_header_threshold_r67d',
        dataKey: 'r67d',
    },
    {
        width: 150,
        label: 'datatable_header_threshold_r67nd',
        dataKey: 'r67nd',
    },
    {
        width: 150,
        label: 'datatable_header_threshold_r67a',
        dataKey: 'r67a',
    },
    {
        width: 100,
        label: 'datatable_header_threshold_name',
        dataKey: 'total',
    },
    {
        width: 150,
        label: 'datatable_header_threshold_fd',
        dataKey: 'fd',
    },
    {
        width: 150,
        label: 'datatable_header_threshold_fnd',
        dataKey: 'fnd',
    },
    {
        width: 150,
        label: 'datatable_header_threshold_fa',
        dataKey: 'fa',
    },
    {
        width: 250,
        label: 'datatable_header_actions',
        dataKey: 'actions',
    },
]

class PerformanceThresholdView extends React.Component {
    state = {
        data: [],
        isOpen: false,
        editDrawer: false,
        openDrawer: false,
        editData: '',
        addEditError: '',
        openDialog: false,
        rowData: {},
        deleteRowData: [],
        loadingData: false,
        action_delay: '',
        warning_delay: '',
        error_delay: '',
    }

    static propTypes = {
        classes: object.isRequired,
        rowClassName: string,
        onRowClick: func,
        headerHeight: number,
        rowHeight: number,
        match: object,
        translate: func,
        data: array,
        store: object,
    }

    loadTestCases = async () => {
        this.setState({ loadingData: true })
        let testCases = await getCosmosDbPerformancesTestCase()
        this.setState({
            data: testCases,
            loadingData: false,
        })
    }

    valueIsInRange = (value, min, max) => {
        return value >= min && value <= max
    }
    isRuleOverlapping = rule => {
        let oldData = cloneDeep(this.state.data)
        let start = rule.startInDaysFromToday
        let end = rule.endInDaysFromToday
        let valueOverlap = false
        if (this.state.editDrawer) {
            oldData = oldData.filter(row => row.id !== this.state.editData.id)
        }

        for (const element of oldData) {
            if (element.type !== SettingType.EXCEPTION) {
                valueOverlap =
                    this.valueIsInRange(element.startInDaysFromToday, start, end) ||
                    this.valueIsInRange(element.endInDaysFromToday, start, end)
                if (valueOverlap) return valueOverlap
            }
        }
        return valueOverlap
    }

    handleAddEditTestCases = async data => {
        let isEdit = this.state.editDrawer
        if (isEdit) {
            data.id = this.state.editData.id
        }
        let response = await addEditTestCase(data, isEdit)
        if (response.status === 200) {
            this.setState(
                {
                    addEditError: '',
                    openDrawer: false,
                    editDrawer: false,
                },
                () => this.loadTestCases()
            )
        } else {
            this.setState({
                addEditError: response.data.Message,
            })
        }
    }
    componentDidMount() {
        document.title = this.props.translate('module_monitor')
        this.loadTestCases()
    }

    getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = this.props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    onRowDeleteClick = (e, rowData) => {
        this.setState({
            openDeleteDialog: true,
            deleteRowData: rowData,
        })

        e.stopPropagation()
    }

    onRowEditClick = (e, rowData) => {
        this.setState({
            editDrawer: true,
            isOpen: true,
            rowData: rowData,
            openDrawer: true,
            editData: rowData,
        })
        e.stopPropagation()
    }

    handleAddClick = () => {
        this.setState({
            openDrawer: true,
            editDrawer: false,
            editData: '',
        })
    }

    onDrawerClose = () => {
        this.setState({ openDrawer: false, editDrawer: false, editData: '', addEditError: '', addEditLoading: false })
    }
    onDialogClose = () => {
        this.setState({
            openDeleteDialog: false,
            addEditError: '',
        })
    }

    handleActionDelay = actionDelay => {
        this.setState({
            action_delay: actionDelay,
        })
    }
    handleErrorDelay = errorDelay => {
        this.setState({
            error_delay: errorDelay,
        })
    }
    handleWarningDelay = warningDelay => {
        this.setState({
            warning_delay: warningDelay,
        })
    }
    getTriggerType = type => {
        if (type === AlertType.P1) return 'P1'
        else if (type === AlertType.P2) return 'P2'
    }

    geValidDays = (start, end) => {
        let days = ''
        for (let i = start; i <= end; i++) {
            let comma = `${i !== end ? ', ' : ''}`
            days += i === 0 ? '0, ' : '+' + i + comma
        }
        return days
    }
    headerRenderer = headerProps => {
        const { classes, headerHeight } = this.props
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: headerHeight,
                    paddingTop: 34,
                    paddingBottom: 34,
                    paddingRight: 0,
                }}
            >
                <Typography>{this.props.translate(`${headerProps.label}`)}</Typography>
            </TableCell>
        )
    }

    rowRenderer = props => {
        const { classes, translate } = this.props
        return (
            <div style={props.style} className={classes.tableCell} key={props.rowData.id}>
                <div style={{ padding: '8px', display: 'flex' }}>
                    <div style={{ width: '200px' }}>
                        {props.rowData.type === SettingType.EXCEPTION ? (
                            <React.Fragment>
                                <Typography>
                                    {`(${this.getTriggerType(props.rowData.triggerType)})`}
                                    {props.rowData.validFrom && props.rowData.validFrom.substring(0, 10)}
                                </Typography>
                                <Typography>{props.rowData.validFrom && 'to'}</Typography>
                                <Typography>{props.rowData.validTo && props.rowData.validTo.substring(0, 10)}</Typography>
                            </React.Fragment>
                        ) : (
                            <div>
                                <Typography>
                                    {'General'} {this.getTriggerType(props.rowData.triggerType)}
                                </Typography>
                                <Typography>
                                    {this.geValidDays(props.rowData.startInDaysFromToday, props.rowData.endInDaysFromToday)}
                                </Typography>
                            </div>
                        )}
                    </div>
                    <div style={{ width: '200px' }}>
                        <React.Fragment>
                            <Typography>{translate('threshold_upperbound_msg')}</Typography>
                            <Typography>{translate('threshold_lowerbound_msg')}</Typography>
                            <Typography>{translate('threshold_minTaskCount_msg')}</Typography>
                        </React.Fragment>
                    </div>
                    <div style={{ width: '200px' }}>
                        <React.Fragment>
                            <Typography>{props.rowData.ranges[0].upperBound}</Typography>
                            <Typography>{props.rowData.ranges[0].lowerBound}</Typography>
                            <Typography>{props.rowData.ranges[0].minimumTaskCount}</Typography>
                        </React.Fragment>
                    </div>
                    <div style={{ width: '200px' }}>
                        <React.Fragment>
                            <Typography>{props.rowData.ranges[1].upperBound}</Typography>
                            <Typography>{props.rowData.ranges[1].lowerBound}</Typography>
                            <Typography>{props.rowData.ranges[1].minimumTaskCount}</Typography>
                        </React.Fragment>
                    </div>
                    <div style={{ width: '200px' }}>
                        <React.Fragment>
                            <Typography>{props.rowData.ranges[2].upperBound}</Typography>
                            <Typography>{props.rowData.ranges[2].lowerBound}</Typography>
                            <Typography>{props.rowData.ranges[2].minimumTaskCount}</Typography>
                        </React.Fragment>
                    </div>
                    <div style={{ width: '150px' }}>
                        <React.Fragment>
                            <Typography style={{ color: 'orange' }}>
                                {props.rowData.ranges[0].upperBound +
                                    props.rowData.ranges[1].upperBound +
                                    props.rowData.ranges[2].upperBound}
                            </Typography>
                            <Typography style={{ color: 'orange' }}>
                                {props.rowData.ranges[0].lowerBound +
                                    props.rowData.ranges[1].lowerBound +
                                    props.rowData.ranges[2].lowerBound}
                            </Typography>
                        </React.Fragment>
                    </div>

                    <div style={{ width: '200px' }}>
                        <React.Fragment>
                            <Typography>{props.rowData.ranges[3].upperBound}</Typography>
                            <Typography>{props.rowData.ranges[3].lowerBound}</Typography>
                            <Typography>{props.rowData.ranges[3].minimumTaskCount}</Typography>
                        </React.Fragment>
                    </div>
                    <div style={{ width: '200px' }}>
                        <React.Fragment>
                            <Typography>{props.rowData.ranges[4].upperBound}</Typography>
                            <Typography>{props.rowData.ranges[4].lowerBound}</Typography>
                            <Typography>{props.rowData.ranges[4].minimumTaskCount}</Typography>
                        </React.Fragment>
                    </div>
                    <div style={{ width: '200px' }}>
                        <React.Fragment>
                            <Typography>{props.rowData.ranges[5].upperBound}</Typography>
                            <Typography>{props.rowData.ranges[5].lowerBound}</Typography>
                            <Typography>{props.rowData.ranges[5].minimumTaskCount}</Typography>
                        </React.Fragment>
                    </div>

                    <div style={{ width: '150px' }}>
                        <React.Fragment>
                            <Typography style={{ color: 'orange' }}>
                                {props.rowData.ranges[3].upperBound +
                                    props.rowData.ranges[4].upperBound +
                                    props.rowData.ranges[5].upperBound}
                            </Typography>
                            <Typography style={{ color: 'orange' }}>
                                {props.rowData.ranges[3].lowerBound +
                                    props.rowData.ranges[4].lowerBound +
                                    props.rowData.ranges[5].lowerBound}
                            </Typography>
                        </React.Fragment>
                    </div>
                    <div style={{ width: '200px' }}>
                        <React.Fragment>
                            <Typography>{props.rowData.ranges[6].upperBound}</Typography>
                            <Typography>{props.rowData.ranges[6].lowerBound}</Typography>
                            <Typography>{props.rowData.ranges[6].minimumTaskCount}</Typography>
                        </React.Fragment>
                    </div>
                    <div style={{ width: '200px' }}>
                        <React.Fragment>
                            <Typography>{props.rowData.ranges[7].upperBound}</Typography>
                            <Typography>{props.rowData.ranges[7].lowerBound}</Typography>
                            <Typography>{props.rowData.ranges[7].minimumTaskCount}</Typography>
                        </React.Fragment>
                    </div>
                    <div style={{ width: '200px' }}>
                        <React.Fragment>
                            <Typography>{props.rowData.ranges[8].upperBound}</Typography>
                            <Typography>{props.rowData.ranges[8].lowerBound}</Typography>
                            <Typography>{props.rowData.ranges[8].minimumTaskCount}</Typography>
                        </React.Fragment>
                    </div>
                    <div style={{ width: '150px' }}>
                        <React.Fragment>
                            <Typography style={{ color: 'orange' }}>
                                {props.rowData.ranges[6].upperBound +
                                    props.rowData.ranges[7].upperBound +
                                    props.rowData.ranges[8].upperBound}
                            </Typography>
                            <Typography style={{ color: 'orange' }}>
                                {props.rowData.ranges[6].lowerBound +
                                    props.rowData.ranges[7].lowerBound +
                                    props.rowData.ranges[8].lowerBound}
                            </Typography>
                        </React.Fragment>
                    </div>

                    <div style={{ display: 'flex', width: '200px' }}>
                        <Edit
                            className={classes.editIcon}
                            onClick={e => {
                                this.onRowEditClick(e, props.rowData)
                            }}
                        />

                        {props.rowData.validFrom && (
                            <Delete
                                className={classes.deleteIcon}
                                onClick={e => {
                                    this.onRowDeleteClick(e, props.rowData)
                                }}
                            />
                        )}
                    </div>
                </div>
                <div
                    style={{
                        marginTop: '8px',
                    }}
                >
                    <Typography>
                        {`ActionDelay: ${props.rowData.actionDelay} 
                        WarningDelay: ${props.rowData.warningDelay} ErrorDelay: ${props.rowData.errorDelay}`}
                    </Typography>
                </div>
                <hr className={classes.divider} />
            </div>
        )
    }
    render() {
        const { classes, ...restProps } = this.props

        return (
            <div>
                <div className={classes.filterDiv}>
                    <Button
                        style={{ marginLeft: 'auto' }}
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleAddClick()}
                    >
                        {this.props.translate('threshold_add_rule_msg')}
                    </Button>
                </div>

                {this.state.loadingData && <Loading />}
                {!this.state.loadingData && (
                    <ThresholdTable
                        columns={columns}
                        data={this.state.data}
                        classes={classes}
                        headerRenderer={this.headerRenderer}
                        getRowClassName={this.getRowClassName}
                        rowRenderer={this.rowRenderer}
                    />
                )}
                {this.state.openDrawer && (
                    <ThresholdDrawer
                        isOpen={this.state.openDrawer}
                        editDrawer={this.state.editDrawer}
                        onClose={this.onDrawerClose}
                        editData={this.state.editData}
                        handleAdd={this.handleAddEditTestCases}
                        addEditError={this.state.addEditError}
                        isRuleOverlapping={this.isRuleOverlapping}
                        {...restProps}
                    />
                )}
                {this.state.openDeleteDialog && (
                    <DeleteDialog
                        dialogOpen={this.state.openDeleteDialog}
                        onDialogClose={this.onDialogClose}
                        reloadData={this.loadTestCases}
                        deleteData={this.state.deleteRowData}
                        {...restProps}
                    />
                )}
            </div>
        )
    }
}

export default withStyles(styles)(PerformanceThresholdView)
