import React from 'react'
import FeedbackCategories from './component.feedbackCategories'
import StoreProvider from './store.feedbackCategories'
import PropTypes from 'prop-types'

const FeedbackCategoriesModule = (props) => {
    return (
        <StoreProvider>
            <FeedbackCategories scope={props.scope}/>
        </StoreProvider>
    )
}
FeedbackCategoriesModule.propTypes = {
    scope: PropTypes.string,
};
export default FeedbackCategoriesModule
