export default theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        position: 'fixed',
        bottom: '0px',
        width: '100%',
        zIndex: 2,
    },
    flex: {
        display: 'flex',
    },
    navIcon: {
        cursor: 'pointer',
        color: theme.customColors.white,
        fontSize: '1.5em',
        top: '10px',
    },
    text: {
        display: 'inline',
    },
})
