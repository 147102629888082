import React from 'react'
import { object, arrayOf, string, number } from 'prop-types'
import { AutoSizer, Column, Table } from 'react-virtualized'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import calculateTimeDifference from 'utils/calculateTimeDifference'

import styles from './styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import TrainIcon from '@material-ui/icons/Train'
import StyledTooltip from 'components/StyledTooltip'
import { dateTimeFromEpochSeconds } from 'utils/dateParser'
import { DateTime } from 'luxon'

const columnsForDesk = [
    {
        width: 70,
        label: 'performances_header_drivedetails_last_registration',
        dataKey: 'lastregistration',
    },
    {
        width: 240,
        label: 'performances_header_drivedetails_driveline',
        dataKey: 'lineNumber',
    },
    {
        width: 240,
        // flexGrow: 1.0,
        label: 'performances_header_drivedetails_driveroute',
        dataKey: 'ptcarId',
    },
    {
        width: 240,
        label: 'performances_header_drivedetails_drivetrack',
        dataKey: 'driveTrack',
    },
    {
        width: 240,
        label: 'performances_header_drivedetails_drivearrival',
        dataKey: 'arrivalTime',
    },
    {
        width: 240,
        label: 'performances_header_drivedetails_passthrough',
        dataKey: 'passTroughTime',
    },
    {
        width: 240,
        label: 'performances_header_drivedetails_departure',
        dataKey: 'departureTime',
    },
    {
        width: 150,
        label: 'datatable_header_task_info_text',
        dataKey: 'info',
    },
]
const columnsForMob = [
    {
        width: 150,
        label: 'performances_header_drivedetails_driveline',
        dataKey: 'lineNumber',
    },
    {
        width: 200,
        label: 'performances_header_drivedetails_driveroute',
        dataKey: 'ptcarId',
    },
    {
        width: 300,
        label: 'performances_header_drivedetails_mob_timing',
        dataKey: 'times',
    },
]

class DriveDetails extends React.PureComponent {
    static propTypes = {
        classes: object.isRequired,
        driveDetails: arrayOf(object).isRequired,
        lastRegisterPtcar: object,
        rowClassName: string,
        headerHeight: number,
        rowHeight: number,
        activeLanguage: object,
        width: string,
    }

    static defaultProps = {
        rowHeight: 56,
        headerHeight: 56,
    }

    getRowClassName = () => {
        const { classes, rowClassName } = this.props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName)
    }

    headerRenderer = headerProps => {
        const { classes, headerHeight } = this.props

        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{ height: headerHeight }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }

    /**
     * @param {*} activeLanguage
     * @param {*} rowData
     * @returns location name as per selected language
     */
    getPtcarName = (activeLanguage, rowData) => {
        if (activeLanguage.code === 'fr') {
            const ptcar = rowData.location.find(item => item.language === 'fr-BE')
            return ptcar.translation
        }
        if (activeLanguage.code === 'nl') {
            const ptcar = rowData.location.find(item => item.language === 'nl-BE')
            return ptcar.translation
        }
    }

    cellRenderer = cellProps => {
        const { classes, activeLanguage } = this.props
        const { dataKey, rowData } = cellProps

        let data = rowData[dataKey]
        if (dataKey === 'departureTime' && rowData.departureTime) {
            let timeDiff = 0
            if (rowData.realDepartureDateTime && rowData.realDepartureDateTime !== null) {
                timeDiff = calculateTimeDifference(
                    rowData.departureTime,
                    DateTime.fromISO(rowData.realDepartureDateTime).toFormat('HH:mm:ss')
                )
            }
            if (timeDiff !== 0) {
                return (
                    <TableCell
                        className={classes.tableCell}
                        component="div"
                        variant="body"
                        style={{ height: this.props.rowHeight }}
                    >
                        <Typography>
                            {`${rowData.departureTime.substring(0, 5)}`}
                            <span style={{ color: 'yellow' }}>
                                {timeDiff > 0 ? ` (+ ${timeDiff.toString()})` : ` (${timeDiff.toString()})`}
                            </span>
                        </Typography>
                    </TableCell>
                )
            }
            if (timeDiff === 0) {
                return (
                    <TableCell
                        className={classes.tableCell}
                        component="div"
                        variant="body"
                        style={{ height: this.props.rowHeight }}
                    >
                        <Typography>{rowData.departureTime.substring(0, 5)}</Typography>
                    </TableCell>
                )
            }
        }

        if (dataKey === 'passTroughTime' && rowData.passTroughTime) {
            let timeDiff = 0
            if (rowData.realPassTroughDateTime && rowData.realPassTroughDateTime !== null) {
                timeDiff = calculateTimeDifference(
                    rowData.passTroughTime,
                    DateTime.fromISO(rowData.realPassTroughDateTime).toFormat('HH:mm:ss')
                )
            }
            if (timeDiff !== 0) {
                return (
                    <TableCell
                        className={classes.tableCell}
                        component="div"
                        variant="body"
                        style={{ height: this.props.rowHeight }}
                    >
                        <Typography>
                            {`${rowData.passTroughTime.substring(0, 5)}`}
                            <span style={{ color: 'yellow' }}>
                                {timeDiff > 0 ? ` (+ ${timeDiff.toString()})` : ` (${timeDiff.toString()})`}
                            </span>
                        </Typography>
                    </TableCell>
                )
            }
            if (timeDiff === 0) {
                return (
                    <TableCell
                        className={classes.tableCell}
                        component="div"
                        variant="body"
                        style={{ height: this.props.rowHeight }}
                    >
                        <Typography>{rowData.passTroughTime.substring(0, 5)}</Typography>
                    </TableCell>
                )
            }
        }
        if (dataKey === 'arrivalTime' && rowData.arrivalTime) {
            let timeDiff = 0
            if (rowData.realArrivalDateTime && rowData.realArrivalDateTime !== null) {
                timeDiff = calculateTimeDifference(
                    rowData.arrivalTime,
                    DateTime.fromISO(rowData.realArrivalDateTime).toFormat('HH:mm:ss')
                )
            }
            if (timeDiff !== 0) {
                return (
                    <TableCell
                        className={classes.tableCell}
                        component="div"
                        variant="body"
                        style={{ height: this.props.rowHeight }}
                    >
                        <Typography>
                            {`${rowData.arrivalTime.substring(0, 5)}`}
                            <span style={{ color: 'yellow' }}>
                                {timeDiff > 0 ? ` (+ ${timeDiff.toString()})` : ` (${timeDiff.toString()})`}
                            </span>
                        </Typography>
                    </TableCell>
                )
            }
            if (timeDiff === 0) {
                return (
                    <TableCell
                        className={classes.tableCell}
                        component="div"
                        variant="body"
                        style={{ height: this.props.rowHeight }}
                    >
                        <Typography>{rowData.arrivalTime.substring(0, 5)}</Typography>
                    </TableCell>
                )
            }
        }

        if (dataKey === 'info') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>
                        <span className={classes.customFont}>{rowData.lineInfo} </span>
                    </Typography>
                </TableCell>
            )
        }
        if (dataKey === 'lastregistration' && this.props.lastRegisterPtcar?.ptcarUic === rowData.ptcarUic) {
            return (
                <TableCell
                    className={classes.iconTableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <StyledTooltip
                        placement="top"
                        disableFocusListener
                        title={
                            (this.props.lastRegisterPtcar?.lastModified
                                ? dateTimeFromEpochSeconds(this.props.lastRegisterPtcar?.lastModified)
                                : '') +
                            ' ' +
                            this.props.lastRegisterPtcar?.trackingMode
                        }
                    >
                        <TrainIcon className={classes.trainIcon} />
                    </StyledTooltip>
                </TableCell>
            )
        }
        //@ToDo: Write general apiData translation function for global use
        if (dataKey === 'ptcarId' && rowData.location && rowData.location.length > 0) {
            data = this.getPtcarName(activeLanguage, rowData)
        }

        return (
            <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: this.props.rowHeight }}>
                <div style={{ display: 'flex' }}>
                    <Typography>{data}</Typography>
                </div>
            </TableCell>
        )
    }

    MobcellRenderer = cellProps => {
        const { classes, activeLanguage } = this.props
        const { dataKey, rowData } = cellProps
        let data = rowData[dataKey]
        let passThroughView = null
        let arrvialTimeView = null
        let departureTimeView = null
        if (dataKey === 'times') {
            if (rowData.passTroughTime) {
                let timeDiff = 0
                if (rowData.realPassTroughDateTime && rowData.realPassTroughDateTime !== null) {
                    timeDiff = calculateTimeDifference(
                        rowData.passTroughTime,
                        DateTime.fromISO(rowData.realPassTroughDateTime).toFormat('HH:mm:ss')
                    )
                }
                if (timeDiff !== 0) {
                    passThroughView = (
                        <TableCell className={classes.tableCell} style={{ height: this.props.rowHeight }}>
                            <Typography>
                                {`${rowData.passTroughTime.substring(0, 5)}`}
                                <span style={{ color: 'yellow' }}>
                                    {timeDiff > 0 ? ` (+ ${timeDiff.toString()})` : ` (${timeDiff.toString()})`}
                                </span>
                            </Typography>
                        </TableCell>
                    )
                } else {
                    passThroughView = (
                        <TableCell
                            className={classes.tableCell}
                            component="div"
                            variant="body"
                            style={{ height: this.props.rowHeight }}
                        >
                            <Typography>{rowData.passTroughTime.substring(0, 5)}</Typography>
                        </TableCell>
                    )
                }
            } else {
                if (rowData.arrivalTime) {
                    let arrivalTimeDiff = 0
                    if (rowData.realArrivalDateTime && rowData.realArrivalDateTime !== null) {
                        arrivalTimeDiff = calculateTimeDifference(
                            rowData.arrivalTime,
                            DateTime.fromISO(rowData.realArrivalDateTime).toFormat('HH:mm:ss')
                        )
                    }
                    if (arrivalTimeDiff !== 0) {
                        arrvialTimeView = (
                            <Typography>
                                {`${rowData.arrivalTime.substring(0, 5)}`}
                                <span style={{ color: 'yellow' }}>
                                    {arrivalTimeDiff > 0
                                        ? ` (+ ${arrivalTimeDiff.toString()})`
                                        : ` (${arrivalTimeDiff.toString()})`}
                                </span>
                            </Typography>
                        )
                    } else {
                        arrvialTimeView = <Typography>{rowData.arrivalTime.substring(0, 5)}</Typography>
                    }
                }

                if (rowData.departureTime) {
                    let departTimeDiff = 0

                    if (rowData.realDepartureDateTime && rowData.realDepartureDateTime !== null) {
                        departTimeDiff = calculateTimeDifference(
                            rowData.departureTime,
                            DateTime.fromISO(rowData.realDepartureDateTime).toFormat('HH:mm:ss')
                        )
                    }
                    if (departTimeDiff !== 0) {
                        departureTimeView = (
                            <Typography>
                                {`${rowData.departureTime.substring(0, 5)}`}
                                <span style={{ color: 'yellow' }}>
                                    {departTimeDiff > 0
                                        ? ` (+ ${departTimeDiff.toString()})`
                                        : ` (${departTimeDiff.toString()})`}
                                </span>
                            </Typography>
                        )
                    } else {
                        departureTimeView = <Typography>{rowData.departureTime.substring(0, 5)}</Typography>
                    }
                }
            }
            return (
                <React.Fragment>
                    {passThroughView}
                    <TableCell
                        component="div"
                        variant="body"
                        className={classes.tableCell}
                        style={{ height: this.props.rowHeight }}
                    >
                        {arrvialTimeView}
                        {departureTimeView}
                    </TableCell>
                </React.Fragment>
            )
        }
        if (dataKey === 'ptcarId' && rowData.location && rowData.location.length > 0) {
            data = this.getPtcarName(activeLanguage, rowData)
        }
        return (
            <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: this.props.rowHeight }}>
                <Typography>{data}</Typography>
            </TableCell>
        )
    }

    render() {
        const { classes, driveDetails } = this.props
        let columns = null
        let cellrenderer = null
        if (isWidthUp('sm', this.props.width)) {
            columns = columnsForDesk
            cellrenderer = this.cellRenderer
        } else {
            columns = columnsForMob
            cellrenderer = this.MobcellRenderer
        }
        return (
            <div className={classes.documentTableWrapper}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            className={classes.table}
                            height={height}
                            width={width}
                            rowClassName={this.getRowClassName}
                            rowCount={driveDetails.length}
                            rowGetter={({ index }) => driveDetails[index]}
                            rowHeight={this.props.rowHeight}
                            headerHeight={this.props.headerHeight}
                        >
                            {columns.map(({ dataKey, ...other }) => (
                                <Column
                                    key={dataKey}
                                    headerRenderer={this.headerRenderer}
                                    className={classes.flexContainer}
                                    cellRenderer={cellrenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            ))}
                        </Table>
                    )}
                </AutoSizer>
            </div>
        )
    }
}

export default compose(withWidth(), withStyles(styles), withRouter, withLocalize)(DriveDetails)
