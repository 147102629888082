import React, { useState } from 'react'
import { object, array, number, func, string, PropTypes } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'
import { withStore, withTake } from 'react-ion-store'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import { AutoSizer, Column, Table, defaultTableRowRenderer } from 'react-virtualized'

import SearchError from '../../../../components/SearchError'

import dateParser from 'utils/dateParser'

import styles from '../../styles'
import tableStyles from './styles'

class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
        headerHeight: 56,
        rowHeight: 56,
    }

    getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = this.props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }
    cellRenderer = cellProps => {
        const { classes, rowHeight, onRowClick } = this.props
        const { dataKey, rowData } = cellProps

        let cellData
        if (dataKey !== 'name' && dataKey !== 'exceedReadDays') {
            cellData = rowData[dataKey] ? dateParser(rowData[dataKey] * 1000) : '/'
        } else cellData = rowData[dataKey]

        let isRed = false

        if (rowData.exceedReadDays) {
            isRed = rowData.exceedReadDays > 0 ? true : false
        }

        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                    [classes.tableCellRed]: isRed,
                })}
                variant="body"
                style={{ height: rowHeight }}
            >
                {cellData}
            </TableCell>
        )
    }

    headerRenderer = ({ label, columnIndex, dataKey, params, sortFunction }) => {
        const { headerHeight, columns, classes } = this.props

        const { sort, sortDirection } = params
        let sortDir = sortDirection === 'asc' ? 'desc' : 'asc'
        const inner =
            columns[columnIndex]?.disableSort === undefined && sort !== null ? (
                <TableSortLabel
                    active={dataKey === sort}
                    direction={sortDir}
                    onClick={() => sortFunction(sort, dataKey, sortDirection)}
                >
                    {label}
                </TableSortLabel>
            ) : (
                label
            )

        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{ height: headerHeight }}
            >
                {inner}
            </TableCell>
        )
    }

    render() {
        const { classes, columns, params, sortFunction, data } = this.props
        return (
            <div className={classes.documentTableWrapper}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            className={classes.table}
                            height={height}
                            width={width}
                            rowCount={data.length}
                            rowClassName={this.getRowClassName}
                            rowRenderer={defaultTableRowRenderer}
                            rowGetter={({ index }) => data[index]}
                            rowHeight={56}
                            headerHeight={56}
                        >
                            {columns.map(({ dataKey, ...other }, index) => (
                                <Column
                                    key={dataKey}
                                    headerRenderer={headerProps =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                            params: params,
                                            sortFunction: sortFunction,
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={this.cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            ))}
                        </Table>
                    )}
                </AutoSizer>
            </div>
        )
    }
}

const WrappedVirtualizedTable = withStyles(tableStyles)(MuiVirtualizedTable)

const ProfileDetailView = ({ classes, viewData, params }) => {
    const [paramsToSort, setParams] = useState(params)

    const sortAsc = type => {
        return viewData.sort((a, b) => a[type] - b[type])
    }

    const sortDesc = type => {
        return viewData.sort((a, b) => a[type] - b[type]).reverse()
    }

    const sortWithoutCall = (type, sortDirection) => {
        if (sortDirection === 'asc') {
            return sortAsc(type)
        }
        if (sortDirection === 'desc') {
            return sortDesc(type)
        }
    }

    const sortFunction = (currentSortKey, sortKey, sortDirection) => {
        let newSortDirection = 'asc'
        if (currentSortKey === sortKey) {
            if (sortDirection === 'asc') {
                newSortDirection = 'desc'
            }
            if (sortDirection === 'desc') {
                newSortDirection = 'asc'
            }
        }
        const newParams = {
            sort: sortKey,
            sortDirection: newSortDirection,
            filter: params?.filter || 'All',
        }

        //save new sort to store
        if (sortKey !== 'name') {
            sortWithoutCall(sortKey, newSortDirection)
            setParams(newParams)
        }
    }

    return (
        <div className={classes.detailViewRoot}>
            <div className={classes.detailViewTitle}>
                <Typography variant="h6">
                    <Translate id="documents_label_all_results_for" />
                    {` ${viewData[0].firstName} ${viewData[0].lastName} - ${viewData[0].idfNumber}`}
                </Typography>
            </div>
            {viewData.length === 0 && <SearchError translateKey="documents_label_no_results" />}

            {viewData.length > 0 && (
                <Paper className={classes.profileTableWrapper}>
                    <Translate>
                        {({ translate }) => (
                            <WrappedVirtualizedTable
                                sortFunction={sortFunction}
                                params={paramsToSort}
                                data={viewData}
                                translate
                                columns={[
                                    {
                                        width: 240,
                                        flexGrow: 3,
                                        label: translate('datatable_header_document'),
                                        dataKey: 'name',
                                    },
                                    {
                                        width: 120,
                                        flexGrow: 1,
                                        label: translate('datatable_header_available'),
                                        dataKey: 'releaseDate',
                                    },
                                    {
                                        width: 120,
                                        flexGrow: 1,
                                        label: translate('datatable_header_valid_from'),
                                        dataKey: 'validFrom',
                                    },
                                    {
                                        width: 120,
                                        flexGrow: 1,
                                        label: translate('datatable_header_valid_to'),
                                        dataKey: 'validTo',
                                    },
                                    {
                                        width: 120,
                                        flexGrow: 1,
                                        label: translate('datatable_header_first_opened'),
                                        dataKey: 'firstOpened',
                                    },
                                    {
                                        width: 120,
                                        flexGrow: 1,
                                        label: translate('datatable_header_last_opened'),
                                        dataKey: 'lastOpened',
                                    },
                                    {
                                        width: 120,
                                        flexGrow: 1,
                                        disableSort: true,
                                        label: translate('datatable_header_exceed'),
                                        dataKey: 'exceedReadDays',
                                    },
                                ]}
                            />
                        )}
                    </Translate>
                </Paper>
            )}
        </div>
    )
}

ProfileDetailView.propTypes = {
    classes: PropTypes.object.isRequired,
    viewData: array.isRequired,
    counter: number,
    currentTime: number.isRequired,
    params: object,
    storePar: object,
    onRowClick: func,
    rowClassName: string,
    rowHeight: number,
}

MuiVirtualizedTable.propTypes = {
    classes: PropTypes.object,
    data: array,
    currentTime: number,
    params: object,
    storePar: object,
    onRowClick: func,
    rowClassName: string,
    rowHeight: number,
    headerHeight: number,
    columns: array,
    sortFunction: func,
}

export default compose(withLocalize, withStyles(styles), withStore, withTake('currentTime'))(ProfileDetailView)
