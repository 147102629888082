import React, { useContext } from 'react'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import { func, object, string, array, bool } from 'prop-types'
import classNames from 'classnames'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'

import TableCell from '@material-ui/core/TableCell'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import SpeedIcon from '@material-ui/icons/Speed'
import WarningIcon from '@material-ui/icons/Warning'
import TrainIcon from '@material-ui/icons/Train'
import PersonIcon from '@material-ui/icons/Person'
import CheckmarkIcon from '@material-ui/icons/Check'
import Grid from '@material-ui/core/Grid'

import calculateTimeDifferenceWithTimestamps from 'utils/calculateTimeDifferenceWithTimestamps'
import { ThemeContext } from 'theme/ThemeProvider'
import PerformanceTableForTrainNumber from '../PerformanceTableForTrainNumber'
import { isCompositionViewAllowed } from '../CompositionView/Utils/isCompoEditingAllowed'

const columns = [
    {
        width: 80,
        label: 'datatable_header_ptcar_blank',
        dataKey: 'icon',
    },
    {
        width: 120,
        label: 'datatable_header_ptcar_code',
        dataKey: 'code',
    },
    {
        width: 120,
        label: 'datatable_header_train_ready_infrabel',
        dataKey: 'infrabel',
    },

    {
        width: 200,
        label: 'performance_info_label_from',
        dataKey: 'from',
    },
    {
        width: 200,
        label: 'performance_info_label_till',
        dataKey: 'to',
    },

    {
        width: 80,
        label: 'datatable_header_ptcar_blank',
        dataKey: 'personIcon',
    },
]

function ListViewMobile(props) {
    let headerHeight = 36
    let rowHeight = 66
    const { classes, data, isPerformanceGrouped } = props
    const { themeName } = useContext(ThemeContext)
    let isDark = themeName === 'dark'

    const getRowClassName = ({ index }) => {
        const { rowClassName, onRowClick } = props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }
    const returnTaskStatus = (status, isAbolished, isDark) => {
        if (isAbolished) {
            return 'Red'
        }

        switch (status) {
            case 1:
                return 'Blue'
            case 2:
                return 'Green'
            case 0:
                return isDark ? 'white' : 'grey'
            default:
                return isDark ? 'white' : 'grey'
        }
    }

    const headerRenderer = headerProps => {
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: 36,
                    // paddingTop: 20,
                    // paddingBottom: 20,
                    paddingRight: 0,
                }}
            >
                <Typography variant="caption">
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }

    const getDataForFromColumn = rowData => {
        const timeDiff = calculateTimeDifferenceWithTimestamps(rowData.plannedStartDateTime, rowData.realStartDateTime)
        if (timeDiff !== 0) {
            let from =
                rowData.code === 'AFREL'
                    ? rowData.referencedPerformance
                    : rowData.code === 'VK.PC'
                    ? rowData.taskInfo
                    : rowData.locationFrom

            from += timeDiff > 0 ? ` (+ ${timeDiff.toString()})` : ` (${timeDiff.toString()})`
            return from
        }
        if (timeDiff === 0) {
            let from =
                rowData.code === 'AFREL'
                    ? rowData.referencedPerformance
                    : rowData.code === 'VK.PC'
                    ? rowData.taskInfo
                    : rowData.locationFrom
            return from
        }
    }

    const getTextStyle = (isYellowBG, isRedBG) => {
        if (isYellowBG || isRedBG || isDark) return 'white'
        return 'black'
    }

    const getRowRenderer = rowProps => {
        let isRedBG = rowProps.rowData.colorStatus === 2
        let isYellowBG = rowProps.rowData.colorStatus === 1

        return (
            <div
                className={classNames(classes.tableCell, {
                    [classes.tableRowRed]: isRedBG,
                    [classes.tableRowYellow]: isYellowBG,
                })}
                key={rowProps.rowData.taskId}
                style={rowProps.style}
                onClick={() => props.onRowClick(rowProps.rowData)}
            >
                <Grid container spacing={0}>
                    <Grid item xs={1}>
                        {rowProps.rowData?.departureAtFirstPtCar && <CheckmarkIcon className={classes.greenIcon} />}
                        {!rowProps.rowData?.departureAtFirstPtCar &&
                            rowProps.rowData?.trainDepartureSignalType === 'TrainReady' && <SpeedIcon />}
                        {!rowProps.rowData?.departureAtFirstPtCar &&
                            rowProps.rowData?.trainDepartureSignalType === 'TrainNotReady' && (
                                <WarningIcon className={classes.warningIcon} />
                            )}
                        {!rowProps.rowData?.departureAtFirstPtCar &&
                            rowProps.rowData?.trainDepartureSignalType === null &&
                            !rowProps.rowData?.departureAtFirstPtCar && (
                                <TrainIcon className={isRedBG || isDark ? classes.whiteIconForMobile : classes.greyIcon} />
                            )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{ color: getTextStyle(isYellowBG, isRedBG) }} variant="caption">
                            {rowProps.rowData['trainComposed'] || 'DRIVE'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{ color: getTextStyle(isYellowBG, isRedBG) }} variant="caption">
                            {'N/A'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <div>
                            <Typography
                                style={{ color: getTextStyle(isYellowBG, isRedBG) }}
                                variant="caption"
                                className={classes.ellipsis}
                            >
                                {getDataForFromColumn(rowProps.rowData)}
                            </Typography>
                            <Typography style={{ color: getTextStyle(isYellowBG, isRedBG) }} variant="caption">
                                {rowProps.rowData.plannedStartTime.substring(0, 5)}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div>
                            <Typography
                                style={{ color: getTextStyle(isYellowBG, isRedBG) }}
                                variant="caption"
                                className={classes.ellipsis}
                            >
                                {rowProps.rowData.locationTo}
                            </Typography>
                            <Typography style={{ color: getTextStyle(isYellowBG, isRedBG) }} variant="caption">
                                {rowProps.rowData?.plannedEndTime
                                    ? ' ' + rowProps.rowData.plannedEndTime.substring(0, 5)
                                    : ''}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <PersonIcon
                            style={{
                                color: returnTaskStatus(
                                    rowProps.rowData.status,
                                    rowProps.rowData.isAbolished,
                                    isDark || isRedBG
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            classes={
                                isRedBG || isDark ? { root: classes.whiteTyhpograhpy } : { root: classes.blackTyhpograhpy }
                            }
                            color="primary"
                            onClick={e => {
                                e.stopPropagation()
                                if (isCompositionViewAllowed(props))
                                    window.open(
                                        `/compositionPtcarView/${rowProps.rowData.performanceId}/${
                                            rowProps.rowData.taskId
                                        }/${encodeURIComponent(rowProps.rowData.trainComposed)}/${
                                            rowProps.rowData.plannedStartDateTime
                                        }/${rowProps.rowData.locationFromPtCarUic}/${
                                            rowProps.rowData.idfNumber
                                        }/${window.btoa(rowProps.rowData.locationFrom)}/${encodeURIComponent(
                                            rowProps.rowData.locationToPtCarUic
                                        )}/${window.btoa(rowProps.rowData.locationTo)}`
                                    )
                            }}
                        >
                            {props.translate('label_composition')}
                        </Typography>
                    </Grid>
                </Grid>

                <Divider style={{ backgroundColor: isRedBG || isDark ? 'white' : 'gray' }} />
            </div>
        )
    }

    if (data && data?.length > 0) {
        return (
            <div className={isPerformanceGrouped ? classes.groupPad : classes.padTop}>
                <PerformanceTableForTrainNumber
                    columns={columns}
                    data={data}
                    classes={classes}
                    headerRenderer={headerRenderer}
                    getRowClassName={getRowClassName}
                    // rowClickHandler={onRowClick}
                    rowRenderer={getRowRenderer}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                />
            </div>
        )
    } else {
        return (
            <div className={classes.parentDiv}>
                <Typography>No Data</Typography>
            </div>
        )
    }
}
ListViewMobile.propTypes = {
    classes: object.isRequired,
    rowClassName: string,
    onRowClick: func,
    history: object,
    match: object,
    data: array,
    store: object,
    isPerformanceGrouped: bool,
    rowData: object,
    translate: func,
}
export default compose(withStore, withRouter)(ListViewMobile)
