import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import IconButton from 'components/IconButton'
import Grid from '@material-ui/core/Grid'
import ErrorMessage from 'components/ErrorMessage'
import { Typography } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import SelectInput from 'components/SelectInput'
import Loading from 'components/Loading'
import { TranslationContext } from 'context/translation'

const textField = (label, value, callback, register, errors, name, type = 'text', extra = 0) => {
    let minimum = {}

    if (name === 'MaximumNumberOfBogies' && extra) {
        minimum = { min: parseInt(extra) }
    }

    return (
        <TextField
            margin="dense"
            inputRef={register !== undefined ? register({ required: true, ...minimum }) : null}
            error={errors && !!errors[`${name}`]}
            id={name}
            name={name}
            label={label}
            type={type}
            fullWidth
            defaultValue={value}
            onChange={e => {
                callback(e.target.value)
            }}
        />
    )
}

function PneumaticSuspensionAddForm(props) {
    const { handleAdd, error, materialTypes, addEditLoading } = props
    const [type, setType] = useState(materialTypes[0].id)
    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)
    const [limitations, setLimitations] = useState(null)

    const { register, handleSubmit, errors, control, classes, setValue } = useForm()

    const { translate } = React.useContext(TranslationContext)

    const submitTheForm = data => {
        let RestrictionPercentages = []
        let obj = {}
        let dataToServer = {}
        dataToServer.materialSubTypeId = type

        obj.MinimumNumberOfBogies = parseInt(data.MinimumNumberOfBogies)
        obj.MaximumNumberOfBogies = parseInt(data.MaximumNumberOfBogies)
        obj.RestrictionPercentage = parseInt(data.RestrictionPercentagePercentage)
        RestrictionPercentages.push(obj)
        dataToServer.RestrictionPercentages = RestrictionPercentages
        handleAdd(dataToServer)
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(submitTheForm)} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ display: 'block', marginTop: '18px', marginBottom: '18px' }}>
                            <InputLabel htmlFor={'materialSubTypeId'}>{'materialSubTypeId'}</InputLabel>
                            <Controller
                                as={
                                    <SelectInput
                                        minWidth={200}
                                        error={errors && !!errors[`materialSubTypeId`]}
                                        id="materialSubTypeId"
                                        classes={classes}
                                        name="materialSubTypeId"
                                        items={materialTypes}
                                    />
                                }
                                onChange={([event]) => {
                                    setType(event.target.value)
                                    setTimeout(() => setValue('materialSubTypeId', event.target.value, true))
                                }}
                                defaultValue={type}
                                rules={{ required: true, minLength: 1 }}
                                register={register}
                                name="materialSubTypeId"
                                control={control}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>bogies</Typography>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '5px' }}>
                                {textField('from', from, setFrom, register, errors, 'MinimumNumberOfBogies', 'number')}
                            </div>
                            {textField('to', to, setTo, register, errors, 'MaximumNumberOfBogies', 'number', from)}
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>Limitations</Typography>
                        {textField(
                            'limitations',
                            limitations,
                            setLimitations,
                            register,
                            errors,
                            'RestrictionPercentagePercentage',
                            'number'
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        {error && error?.length > 0 && <ErrorMessage error={error} spacing={false} />}
                        {<IconButton disabled={addEditLoading} icon="save" type="submit" title={translate('button_add')} />}
                        {addEditLoading && <Loading />}
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

PneumaticSuspensionAddForm.propTypes = {
    handleAdd: PropTypes.func,
    error: PropTypes.string,
    editDrawer: PropTypes.bool,
    addEditLoading: PropTypes.bool,
    editData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    materialTypes: PropTypes.array,
}

export default PneumaticSuspensionAddForm
