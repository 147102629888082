import React, { useEffect, useState } from 'react'
import { func, bool, string, object, array } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus } from 'react-ion-store'
import { DateTime } from 'luxon'
import { withStyles } from '@material-ui/core/styles'

import AddNewUserGroupDrawer from './AddNewUserGroupDrawer'

import createUserGroup from '../../Services/createUserGroup'
import updateUserGroup from 'modules/UsersAndRoles/Services/updateUserGroup'
import { cloneDeep } from 'lodash'

import styles from './styles'

const AddNewUserGroup = props => {
    const [selectedName, setSelectedName] = useState('')
    const [selectedProfile, setSelectedProfile] = useState(undefined)
    const [userRoles, setUserRoles] = useState([])
    const [locationSearchValue, setLocationSearchValue] = useState({})
    const [selectedLocationSearchItem, setSelectedLocationSearchItem] = useState({})
    const [selectedRole, setSelectedRole] = useState(' ')
    const [saveRolesError, setSaveRolesError] = useState('')
    const [roleAdded, setRoleAdded] = useState(false)
    // const [confirmIsOpen, setConfirmIsOpen] = useState(false)
    // const [emptyRoles, setEmptyRoles] = useState([])
    const [rolesSaving, setRolesSaving] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [groupName, setGroupName] = useState('')

    AddNewUserGroup.propTypes = {
        onClose: func.isRequired,
        editUserGroupId: string,
        open: bool,
        activeLanguage: object,
        OrganisationsData: array,
        data: array,
        reloadPage: func,
        roleItems: array,
    }

    useEffect(() => {
        let unmounted = false
        if (props.editUserGroupId) {
            let selectedGroup =
                props.data.length > 0 ? props.data.filter(userGroup => userGroup.id === props.editUserGroupId)[0] : []
            if (!unmounted) {
                setUserRoles(selectedGroup.roles)
                setGroupName(selectedGroup.name)
            }
        }
        return () => {
            unmounted = true
        }
    }, [])

    const onClose = () => {
        props.onClose()
    }

    const handleLocationSearchValue = e => {
        if (e === null) {
            setLocationSearchValue({})
            setSelectedLocationSearchItem({})
        } else {
            setLocationSearchValue(e.value)
            setSelectedLocationSearchItem(e)
        }
    }

    const clearLocationSearchValue = () => {
        setSearchValue('')
    }

    const persist = type => value => {
        if (type === 'selectedRole') setSelectedRole(value)
    }

    const changeOrgDate = (value, type, role, org) => {
        const newRoles = cloneDeep(userRoles)
        const findRole = newRoles.find(roles => roles.portalUserRoleId === role)
        const findOrg = findRole.organisations.find(organisation => organisation.organisation === org)

        if (type === 'validFrom') {
            findOrg.validFrom = value
        }
        if (type === 'validTo') {
            findOrg.validTo = value
        }
        setUserRoles(newRoles)
    }

    const handleEvent = type => e => persist(type)(e.target.value)
    const handleGroupName = e => setGroupName(e.target.value)

    const addOrganisation = id => {
        if (selectedLocationSearchItem.value) {
            const updateRole = userRoles.find(role => role.portalUserRoleId === id)
            let isRoleAdded = true
            const orgObject = {
                organisation: selectedLocationSearchItem.value.toString(),
                validFrom: DateTime.local().setZone('Europe/Brussels'),
                validTo: DateTime.local()
                    .plus({ years: 20 })
                    .setZone('Europe/Brussels'),
            }

            if (
                updateRole.organisations.findIndex(
                    org => org.organisation === selectedLocationSearchItem.value.toString()
                ) === -1
            ) {
                updateRole.organisations.push(orgObject)
                isRoleAdded = false
            } else {
                return
            }
            setUserRoles(userRoles)
            setSelectedLocationSearchItem({})
            setLocationSearchValue({})
            setRoleAdded(isRoleAdded)
        }
    }

    const saveRoles = () => {
        const changeThis = [...userRoles]
        setRolesSaving(true)
        const newRoles = changeThis
            .map(role => {
                return {
                    ...role,
                    portalUserRoleId: role.portalUserRoleId,
                    organisations: role.organisations.map(org => {
                        return {
                            ...org,
                            validFrom:
                                typeof org.validFrom === 'object'
                                    ? parseInt(org.validFrom.toMillis() / 1000)
                                    : org.validFrom,
                            validTo: typeof org.validTo === 'object' ? parseInt(org.validTo.toMillis() / 1000) : org.validTo,
                        }
                    }),
                }
            })
            .filter(role => role.organisations.length !== 0)

        if (props.editUserGroupId) {
            const newOptions = [...props.data]
            let oldRoleObj = newOptions.filter(role => role.id === props.editUserGroupId)[0]
            let updatedUserServerObj = {
                Name: groupName,
                id: oldRoleObj.id,
                DescriptionFr: oldRoleObj.description[0].translation,
                DescriptionNl: oldRoleObj.description[1].translation,
                roles: newRoles,
            }
            let response = updateUserGroup(updatedUserServerObj)
            response
                .then(() => {
                    saveRolesSuccessfully()
                })
                .catch(() => {
                    setSaveRolesError('Edit Roles failed')
                    setRolesSaving(false)
                })
        } else {
            let serverObj = {
                Name: groupName,
                DescriptionFr: groupName,
                DescriptionNl: groupName,
                roles: newRoles,
            }
            let response = createUserGroup(serverObj)
            response
                .then(() => {
                    saveRolesSuccessfully()
                })
                .catch(e => {
                    setSaveRolesError(JSON.parse(JSON.stringify(e)).response.data.Message)
                    setRolesSaving(false)
                })
        }
    }
    const saveRolesSuccessfully = () => {
        onClose()
        setRolesSaving(false)
        props.reloadPage()
    }

    const addSingleRole = d => {
        let roles = [...d]
        if (roles.findIndex(role => role.portalUserRoleId.toString() === selectedRole.toString()) === -1) {
            const rolesObj = roles
            const emptyRoleObject = {
                portalUserRoleId: parseInt(selectedRole),
                organisations: [],
            }

            rolesObj.push(emptyRoleObject)
            setUserRoles(rolesObj)
            setRoleAdded(true)
        } else {
            return
        }
    }

    const deleteOrg = (org, portalUserRoleId) => {
        let users = cloneDeep(userRoles)
        const role = users.find(userRole => userRole.portalUserRoleId === portalUserRoleId)
        const index = role.organisations.findIndex(orgItem => orgItem.organisation === org.organisation.toString())
        role.organisations.splice(index, 1)
        setUserRoles(users)
    }

    const filterLocationData = data => {
        if (locationSearchValue === '') return data
        return data.filter(item => item.label === locationSearchValue || item.code === locationSearchValue)
    }

    // const handleConfirmOpen = () => {
    //     const allRoles = cloneDeep(userRoles)

    //     const emptyRoles = allRoles.filter(role => role.organisations.length === 0)

    //     if (emptyRoles.length === 0) {
    //         saveRoles()
    //     } else {
    //         setConfirmIsOpen(true)
    //         setEmptyRoles(emptyRoles)
    //     }
    // }

    // const handleConfirmClose = () => {
    //     setConfirmIsOpen(false)
    // }

    const { OrganisationsData, roleItems } = props
    const locationList = OrganisationsData

    const filtered = locationList ? filterLocationData(locationList) : []
    return (
        <AddNewUserGroupDrawer
            {...props}
            handleEvent={handleEvent}
            deleteOrg={deleteOrg}
            handleLocationSearchValue={handleLocationSearchValue}
            clearLocationSearchValue={clearLocationSearchValue}
            filteredLocations={filtered}
            allLocations={locationList}
            addOrganisation={addOrganisation}
            onClose={onClose}
            saveRoles={saveRoles}
            selectedRole={selectedRole}
            addSingleRole={addSingleRole}
            persist={persist}
            changeOrgDate={changeOrgDate}
            OrganisationsData={OrganisationsData}
            // handleConfirmClose={handleConfirmClose}
            // handleConfirmOpen={handleConfirmOpen}
            selectedName={selectedName}
            setSelectedName={setSelectedName}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
            setSelectedRole={setSelectedRole}
            saveRolesError={saveRolesError}
            roleAdded={roleAdded}
            // confirmIsOpen={confirmIsOpen}
            // emptyRoles={emptyRoles}
            rolesSaving={rolesSaving}
            searhValue={searchValue}
            userRoles={userRoles}
            selectedLocationSearchItem={selectedLocationSearchItem}
            handleGroupName={handleGroupName}
            groupName={groupName}
            roleItems={roleItems}
        />
    )
}

export default compose(withStore, withStatus, withStyles(styles), withLocalize)(AddNewUserGroup)
