import React, { Component } from 'react'
import Schedule from '@material-ui/icons/Schedule'
import PropTypes from 'prop-types'
import Train from '@material-ui/icons/Train'
import LocationOn from '@material-ui/icons/LocationOn'
import Contact from '@material-ui/icons/AccountCircle'
import Phone from '@material-ui/icons/LocalPhone'
import InsertLink from '@material-ui/icons/InsertLink'
import Timer from '@material-ui/icons/Timer'
import PersonIcon from '@material-ui/icons/Person'
import IconBoxView from './IconBoxView'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

export class IconBox extends Component {
    static propTypes = {
        classes: PropTypes.object,
        icon: PropTypes.string,
        title: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    }

    returnIcon = (icon, classes) => {
        switch (icon) {
            case 'train':
                return <Train className={classes.icon} />
            case 'location':
                return <LocationOn className={classes.icon} />
            case 'contact':
                return <Contact className={classes.icon} />
            case 'link':
                return <InsertLink className={classes.icon} />
            case 'phone':
                return <Phone className={classes.icon} />
            case 'duration':
                return <Timer className={classes.icon} />
            case 'person':
                return <PersonIcon className={classes.icon} />
            default:
                return <Schedule className={classes.icon} />
        }
    }

    render() {
        const { icon, title, value, classes } = this.props

        return (
            <IconBoxView title={title} value={value}>
                {this.returnIcon(icon, classes)}
            </IconBoxView>
        )
    }
}

export default withStyles(styles)(IconBox)
