import React from 'react'
import { func, object } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus } from 'react-ion-store'

import { withStyles } from '@material-ui/core/styles'

import DriverDrawerView from './DriverDrawerView'

import styles from './styles'

const resetState = {
    selectedName: '',
    selectedProfile: undefined,
    performanceData: {},
}

class DriverDrawer extends React.Component {
    static propTypes = {
        onClose: func,
        history: object,
    }

    state = { ...resetState }

    setName = selectedProfile =>
        this.setState({
            selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
            selectedProfile,
        })

    clearName = () => this.setState(resetState)

    onClose = () => {
        this.clearName()
        this.props.onClose()
    }

    openCalenderView = () => {
        this.props.onClose()
        const { selectedProfile } = this.state
        this.props.history.push(`/agenda/${selectedProfile.idfNumber}`)
    }
    render() {
        return (
            <DriverDrawerView
                {...this.props}
                {...this.state}
                openCalenderView={this.openCalenderView}
                setName={this.setName}
                clearName={this.clearName}
                onClose={this.onClose}
            />
        )
    }
}

export default compose(withStore, withStatus, withStyles(styles), withLocalize)(DriverDrawer)
