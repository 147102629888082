export default theme => ({
    blackBox: {
        padding: theme.spacing(1),
        backgroundColor: theme.customColors.darkBox,
        color: theme.customColors.white,
        display: 'flex',
    },
    centerIcon: {
        alignSelf: 'center',
        backgroundColor: theme.customColors.darkBox,
        color: theme.customColors.white,
    },
    icon: {
        fill: theme.customColors.iconFront,
        background: theme.customColors.iconBg,
        height: '45px',
        width: '45px',
        padding: '10px',
        borderRadius: '50%',
    },
})
