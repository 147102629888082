import React from 'react'
import { string, object, func, number, oneOfType } from 'prop-types'
import { DatePickerWithKeyboard } from 'components/Pickers'
import { DateTime } from 'luxon'

import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

export const pickerValue = startValue => {
    if (typeof startValue === 'number') {
        return DateTime.fromSeconds(startValue)
    }
    return startValue
}
const DatePickerOrg = ({ org, role, changeOrgDate, type, startValue }) => {
    return (
        <DatePickerWithKeyboard
            inputProps={{ 'data-testid': 'datePicker' }}
            label="DatePicker"
            animateYearScrolling
            value={pickerValue(startValue)}
            onChange={e => changeOrgDate(e, type, role, org)}
            format="dd/MM/yyyy"
        />
    )
}

DatePickerOrg.propTypes = {
    org: string,
    role: number,
    changeOrgDate: func,
    type: string.isRequired,
    startValue: oneOfType([object, number]).isRequired,
}

export default withStyles(styles)(DatePickerOrg)
