import React, { PureComponent } from 'react'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { Paper, IconButton } from '@material-ui/core'
import PrimaryButton from 'components/PrimaryButton'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { bool, func, object, string } from 'prop-types'
import { Translate } from 'react-localize-redux'

class RemarkDialog extends PureComponent {
    static propTypes = {
        isOpen: bool.isRequired,
        handleClose: func.isRequired,
        addNote: func.isRequired,
        onChangeNote: func.isRequired,
        noteValue: string.isRequired,
        classes: object.isRequired,
    }

    render() {
        const { isOpen, handleClose, addNote, onChangeNote, noteValue, classes } = this.props

        return (
            <Dialog
                classes={{ scrollPaper: classes.scrollPaper }}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <MuiDialogTitle disableTypography className={classes.header}>
                    <Typography variant="h6">
                        <Translate id="performance_remark_dialog_header" />
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <Paper>
                    <DialogContent style={{ minWidth: '600px' }}>
                        <TextField
                            margin="dense"
                            id="name"
                            label={<Translate id="performance_remark_dialog_text" />}
                            type="text"
                            fullWidth
                            value={noteValue}
                            onChange={e => onChangeNote(e)}
                        />
                    </DialogContent>

                    <DialogActions className={classes.actionButton}>
                        <PrimaryButton
                            onClick={() => {
                                addNote()
                                handleClose()
                            }}
                            color="primary"
                        >
                            <Translate id="performance_remark_dialog_button" />
                        </PrimaryButton>
                    </DialogActions>
                </Paper>
            </Dialog>
        )
    }
}
export default withStyles(styles)(RemarkDialog)
