import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default async Id => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/referencedata/ptcartype/${Id}/delete`

    try {
        return await axios.post(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
    } catch (e) {
        return null
    }
}
