import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

import { Typography, withStyles, TextField } from '@material-ui/core'
import { withStore, withCall, enums } from 'react-ion-store'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'

import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import IconButton from 'components/IconButton'
import SelectInput from 'components/SelectInput'

import styles from './styles'

const Failed = () => <div>Failed to load train prefixes</div>
const {
    CallStatus: { LOADING, FAILED },
} = enums

const progressHandlers = {
    [LOADING]: Loading,
    [FAILED]: Failed,
}

const callKey = 'trainnumberPrefixes'

export class DriveTaskView extends Component {
    static propTypes = {
        persist: PropTypes.func,
        translate: PropTypes.func,
        trainPrefix: PropTypes.string,
        trainNumber: PropTypes.string,
        classes: PropTypes.object,
        store: PropTypes.object,
        activeLanguage: PropTypes.object,
        loadTrain: PropTypes.func,
        loading: PropTypes.bool,
        taskType: PropTypes.string,
        noTrainData: PropTypes.bool,
    }

    mapForSelect = (data, lang) => {
        const defaultValue = { id: ' ', text: 'Prefix' }
        let forSelect = []

        if (lang !== 'nl') {
            forSelect = data.map(({ prefix, description }) => ({
                id: prefix,
                text: description[0]?.translation ? `${prefix} - ${description[0]?.translation}` : prefix,
            }))
        } else {
            forSelect = data.map(({ prefix, description }) => ({
                id: prefix,
                text: description[1]?.translation ? `${prefix} - ${description[1]?.translation}` : prefix,
            }))
        }

        const filterSelect = forSelect.filter(item => item.id !== null)

        return [defaultValue, ...filterSelect]
    }

    handleEvent = type => e => this.props.persist(type)(e.target.value)

    //@INFO: case of drive, no prefix.
    // IF no trainnumber required skip this step.
    render() {
        const {
            trainPrefix,
            trainNumber,
            classes,
            translate,
            store,
            loadTrain,
            loading,
            taskType,
            noTrainData,
            activeLanguage,
        } = this.props

        const trainNumberData = store.get(callKey)
        const trainNumberPrefixes = this.mapForSelect(trainNumberData, activeLanguage?.code)
        if (trainNumberPrefixes.length === 0) {
            return <ErrorMessage spacing={false} error="Fetch of train prefixes failed" />
        }
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant="h6">{translate('performance_task_add_label_select_train')}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <div style={{ display: 'inline-block', maxWidth: '100%' }}>
                        <SelectInput
                            name="trainPrefix"
                            classes={classes}
                            items={trainNumberPrefixes}
                            value={trainPrefix}
                            onChange={this.handleEvent('trainPrefix')}
                        />
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth={true}
                        value={trainNumber}
                        onChange={this.handleEvent('trainNumber')}
                        placeholder={translate('add_task_train_number')}
                    />
                </Grid>

                {(taskType.toUpperCase() === 'DRIVE' || taskType === 'HLP') && (
                    <Grid item xs={3}>
                        <IconButton
                            disabled={loading || !trainNumber}
                            className={classes.marginButton}
                            icon=""
                            color="primary"
                            title={loading ? 'loading...' : translate('add_task_load_train')}
                            onClick={() => {
                                loadTrain()
                            }}
                        />
                    </Grid>
                )}
                {noTrainData && (
                    <Grid item xs={3}>
                        <ErrorMessage spacing={false} error={translate('performances_label_drivedetails_no_data')} />
                    </Grid>
                )}

                <div className={classes.stepBorder} />
            </React.Fragment>
        )
    }
}

export default compose(withStore, withLocalize, withStyles(styles), HOComponent => props =>
    withCall(callKey, {
        config: {
            method: 'get',
            url: '/referenceData/trainnumberprefixes',
        },
        progressHandlers: progressHandlers,
        reducer: ({ data }) => {
            if (data.length === 0) {
                return []
            }
            return data
        },
    })(HOComponent)(props)
)(DriveTaskView)
