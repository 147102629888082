import lo from 'lodash'

//For blocking add or edit views on root level
const checkWriteAccess = (props, key, path) => {
    //if store is empty or roles not found always disable
    if (props === undefined) return false
    if (props.store === undefined) return false

    const object = props.store.get('roles')

    if (object === undefined) return false
    const allowedFunctionality = lo.get(object, path)

    if (allowedFunctionality === undefined) return false
    //@TODO: refactor this function, as it contains a potential security risk
    //eslint-disable-next-line
    if (allowedFunctionality.hasOwnProperty(key)) {
        return allowedFunctionality[key]
    }
    return false
}

export default checkWriteAccess
