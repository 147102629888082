/* eslint-disable max-len */
import React, { useContext } from 'react'
import { PropTypes ,object } from 'prop-types'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Paper } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// Components
import ErrorMessage from 'components/ErrorMessage'
import IconButton from 'components/IconButton'

import styles from './styles'
import { feedbackCategoriesContext } from 'modules/FeedbackCategories/store.feedbackCategories'
import { TranslationContext } from 'context/translation'
import FeedbackCategoryScope from 'modules/FeedbackCategories/Enums/FeedbackCategoryScope.js'

function textField(label, value, callback, persistTag) {
    return (
        <TextField
            margin="dense"
            id={label}
            label={label}
            type="text"
            fullWidth
            value={value}
            onChange={e => {
                callback(persistTag)(e.target.value)
            }}
        />
    )
}

const FeedbackDrawerView = ({ classes, scope }) => {
    const { STATE, METHODS, SERVICES } = useContext(feedbackCategoriesContext)
    const { translate } = useContext(TranslationContext)   
    return (
        <Drawer data-testid="feedback_drawer_view" anchor="right" open={STATE.isOpen} onClose={METHODS.onClose}>
            <div className={classes.drawerRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Add Category</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{ display: 'flex' }}>
                            <Paper style={{ padding: '16px', paddingTop: '0px', paddingBottom: '8px', marginBottom: '8px'}}>
                                {textField('Name NL', STATE.nameNl, METHODS.persist, 'nameNl')}
                                {textField('Name FR', STATE.nameFr, METHODS.persist, 'nameFr')}
                                {textField('Title NL', STATE.titleNl, METHODS.persist, 'titleNl')}
                                {textField('Title FR', STATE.titleFr, METHODS.persist, 'titleFr')}
                                {textField('businessTags', STATE.businessTags, METHODS.persist, 'businessTags')}
                                {textField('Fast feedback NL', STATE.fastFeedbackNl, METHODS.persist, 'fastFeedbackNl')}
                                {textField('Fast feedback FR', STATE.fastFeedbackFr, METHODS.persist, 'fastFeedbackFr')}
                                {textField('Order', STATE.order, METHODS.persist, 'order')}
                                {scope != FeedbackCategoryScope.eShunting && 
                                <>
                                    {textField('Restriction Label NL', STATE.restrictionLabelNl, METHODS.persist, 'restrictionLabelNl')}
                                    {textField('Restriction Label FR', STATE.restrictionLabelFr, METHODS.persist, 'restrictionLabelFr')}
                                </>
                                }
                                {scope != FeedbackCategoryScope.eShunting &&
                                    <FormControlLabel
                                    control={
                                        <Checkbox
                                        checked={STATE.signed}
                                        onChange={e => METHODS.persist('signed')(e.target.value)}
                                        color="primary"
                                        />
                                    }
                                    label={translate('feedback_category_signed_checkbox_label')}
                                    />
                                }
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={STATE.standard}
                                            onChange={e => METHODS.persist('standard')(e.target.value)}
                                            color="primary"
                                        />
                                    }
                                    label={translate('feedback_category_standard_checkbox_label')}
                                />
                                {scope != FeedbackCategoryScope.eShunting &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={STATE.e360}
                                            onChange={e => METHODS.persist('e360')(e.target.value)}
                                            color="primary"
                                        />
                                    }
                                    label={'e360'}
                                />
                                }
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={STATE.isFirstDeparture}
                                            onChange={e => METHODS.persist('isFirstDeparture')(e.target.value)}
                                            color="primary"
                                        />
                                    }
                                    label={translate('module_ready_for_departure')}
                                />
                            </Paper>
                        </div>
                    </Grid>
                </Grid>
                {STATE.errorMsg.length > 0 && (
                    <ErrorMessage data-testid="error_message" error={STATE.errorMsg} spacing={false} />
                )}
                {STATE.status === 0 && (
                    <div data-testid="status_0" className={classes.flexAlign}>
                        <IconButton
                            className={classes.halfButton}
                            icon="cancel"
                            onClick={METHODS.onClose}
                            title={translate('add_user_confirm_cancel')}
                        />
                        <IconButton
                            className={classes.halfButton}
                            icon="add"
                            disabled={STATE.nameFr === '' || STATE.nameNl === '' || STATE.order === '' || STATE.restrictionLabelNl !== STATE.restrictionLabelFr || STATE.restrictionLabelNl.length > 4 || STATE.restrictionLabelFr.length > 4}
                            onClick={() => SERVICES.addFeedbackCategory(scope)}
                            title={translate('add_user_confirm_save')}
                        />
                    </div>
                )}
                {STATE.status === 1 && (
                    <div data-testid="status_1" className={classes.flexAlign}>
                        <IconButton
                            className={classes.halfButton}
                            icon="cancel"
                            onClick={METHODS.onClose}
                            title={translate('add_user_confirm_cancel')}
                        />
                        <IconButton
                            className={classes.halfButton}
                            icon="edit"
                            disabled={STATE.nameFr === '' || STATE.nameNl === '' || STATE.order === '' || STATE.restrictionLabelNl !== STATE.restrictionLabelFr || STATE.restrictionLabelNl.length > 4 || STATE.restrictionLabelFr.length > 4}
                            onClick={() => SERVICES.updateFeedbackCategory(scope)}
                            title={translate('add_user_confirm_save')}
                        />
                    </div>
                )}
                {STATE.status === 2 && (
                    <div data-testid="status_2" className={classes.flexAlign}>
                        <IconButton
                            className={classes.halfButton}
                            icon="edit"
                            disabled={STATE.nameFr === '' || STATE.nameNl === '' || STATE.order === '' || STATE.restrictionLabelNl !== STATE.restrictionLabelFr || STATE.restrictionLabelNl.length > 4 || STATE.restrictionLabelFr.length > 4}
                            onClick={() => SERVICES.updateFeedbackCategory(scope)}
                            title={translate('add_user_confirm_save')}
                        />
                        <IconButton
                            className={classes.halfButton}
                            icon="delete"
                            color="secondary"
                            onClick={() => SERVICES.deleteFeedbackCategory(scope)}
                            title={translate('management_button_delete')}
                        />
                    </div>
                )}
            </div>
        </Drawer>
    )
}

FeedbackDrawerView.propTypes = {
    classes: object,
    scope: PropTypes.string
}

export default withStyles(styles)(FeedbackDrawerView)
