import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircle'
import LocationSearch from '../LocationSearch'
import { isEmpty } from 'lodash'

export class AddOrganisation extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        allLocations: PropTypes.array,
        role: PropTypes.object,
        selectedLocationSearchItem: PropTypes.object,
        locationSearchValue: PropTypes.string,
        clearLocationSearchValue: PropTypes.func,
        handleLocationSearchValue: PropTypes.func,
        addOrganisation: PropTypes.func,
    }

    checkDisable = obj => {
        return !obj || isEmpty(obj)
    }
    render() {
        const {
            role,
            classes,
            allLocations,
            clearLocationSearchValue,
            handleLocationSearchValue,
            selectedLocationSearchItem,
            locationSearchValue,
            addOrganisation,
        } = this.props
        return (
            <Grid container key={role.portalUserRoleId} spacing={2} className={classes.noBackgroundPaper}>
                <Grid item xs={11} style={{ padding: 0 }}>
                    <LocationSearch
                        handleSearchValue={handleLocationSearchValue}
                        searchValue={locationSearchValue}
                        clearValue={clearLocationSearchValue}
                        data={allLocations}
                        locationSelectValue={selectedLocationSearchItem}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        className={classes.withMarginButton}
                        component="span"
                        disabled={this.checkDisable(selectedLocationSearchItem)}
                        onClick={() => {
                            addOrganisation(role.portalUserRoleId)
                        }}
                    >
                        <AddIcon color={this.checkDisable(selectedLocationSearchItem) ? 'disabled' : 'primary'} />
                    </IconButton>
                </Grid>
            </Grid>
        )
    }
}

export default AddOrganisation
