import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, Column, defaultTableRowRenderer, Table } from 'react-virtualized'
import { Translate } from 'react-localize-redux'
import classNames from 'classnames'
import { Typography } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import { Edit, Delete } from '@material-ui/icons'

function LindaRolesTable({
    classes,
    lindaRolesData,
    getTranslation,
    onDrawerOpen,
    onDialogOpen,
    store,
    checkFunctionalityDisabled,
}) {
    LindaRolesTable.propTypes = {
        classes: PropTypes.object,
        lindaRolesData: PropTypes.array,
        getTranslation: PropTypes.func,
        onDrawerOpen: PropTypes.func,
        onDialogOpen: PropTypes.func,
        store: PropTypes.object,
        checkFunctionalityDisabled: PropTypes.func,
    }

    const columns = [
        {
            width: 200,
            label: 'datatable_header_linda_edrivecode',
            dataKey: 'eDriveRoleCode',
        },
        {
            width: 300,
            label: 'datatable_header_linda_description_nl',
            dataKey: 'descriptionNl',
        },
        {
            width: 300,
            label: 'datatable_header_linda_description_fr',
            dataKey: 'descriptionFr',
        },
        {
            width: 200,
            label: 'datatable_header_linda_alascacode',
            dataKey: 'alascaCode',
        },
        {
            width: 200,
            label: 'datatable_header_linda_lindacode',
            dataKey: 'lindaRoleCode',
        },
        {
            width: 100,
            label: 'datatable_header_actions',
            dataKey: 'actions',
        },
    ]

    const headerRenderer = headerProps => {
        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, classes.header)}
                variant="head"
                style={{ height: 50 }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }

    const cellRenderer = cellProps => {
        const { dataKey, rowData } = cellProps

        let data = rowData[dataKey]
        if (dataKey === 'actions') {
            if (checkFunctionalityDisabled(store, 'manageLindaRoles', 'manage.functionality.management.linda')) {
                return <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }} />
            }
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                    <div className={classes.multipleIcons}>
                        <Edit
                            className={classes.editIcon}
                            onClick={() => {
                                onDrawerOpen(rowData, true)
                            }}
                        />
                        <Delete
                            className={classes.deleteIcon}
                            onClick={e => {
                                onDialogOpen(e, rowData)
                            }}
                        />
                    </div>
                </TableCell>
            )
        }
        if (dataKey === 'descriptionNl') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                    <Typography>{getTranslation(rowData.description)}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'descriptionFr') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                    <Typography>{getTranslation(rowData.description, 'fr-BE')}</Typography>
                </TableCell>
            )
        }
        return (
            <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: 50 }}>
                <Typography>{data !== null ? data : 'N/A'}</Typography>
            </TableCell>
        )
    }

    if (lindaRolesData && lindaRolesData.length > 0) {
        return (
            <div className={classes.documentTableWrapper}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            className={classes.table}
                            height={height}
                            width={width}
                            rowCount={lindaRolesData.length}
                            rowClassName={classes.tableRow}
                            rowGetter={({ index }) => lindaRolesData[index]}
                            rowRenderer={defaultTableRowRenderer}
                            headerRenderer={headerRenderer}
                            rowHeight={50}
                        >
                            {columns.map(({ dataKey, ...other }) => (
                                <Column
                                    key={dataKey}
                                    headerRenderer={headerRenderer}
                                    className={classes.flexContainer}
                                    cellRenderer={cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            ))}
                        </Table>
                    )}
                </AutoSizer>
            </div>
        )
    } else {
        return <div>No Data</div>
    }
}

export default LindaRolesTable
