import React from 'react'
import styles from './styles'
import { object, number } from 'prop-types'
import { withStyles } from '@material-ui/styles'
import MaterialUnitChild from '../MaterialUnitChild'

const MaterialUnit = props => {
    const { classes, ...others } = props

    const isLocoInUse = () => props.data.isLocomotiveInUse
    const isLocoSelected = () => props?.selectedMUIndex === parseInt(props.data.tractionPosition)
    const getClassName = () => {
        if (isLocoSelected()) {
            if (isLocoInUse()) return classes.selectedMaterialUnitLocoInUse
            else return classes.selectedMaterialUnitLocoNotInUse
        } else {
            if (isLocoInUse()) return classes.materialUnitLocoInUse
            else return classes.materialUnitLocoNotInUse
        }
    }
    return (
        <div data-testid="material-unit" className={getClassName()}>
            <MaterialUnitChild {...others} />
        </div>
    )
}
MaterialUnit.propTypes = {
    classes: object,
    data: object,
    selectedMUIndex: number,
}
export default withStyles(styles)(MaterialUnit)
