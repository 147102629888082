import React from 'react'
import { object, string, bool } from 'prop-types'
import { decode } from 'jsonwebtoken'
import { compose } from 'recompose'
import { withStore, withTake } from 'react-ion-store'

// Material UI
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import styles from '../../styles'
import CheckForMobileView from 'components/CheckForMobileView'
import StyledTooltip from 'components/StyledTooltip'
import { ClickAwayListener } from '@material-ui/core'

class AccountMenu extends React.Component {
    static propTypes = {
        classes: object.isRequired,
        auth: string.isRequired,
        isMobileView: bool,
    }
    state = {
        open: false,
    }
    setOpen = () => {
        this.setState({ open: true })
    }

    handleTooltipClose = () => {
        this.setState({ open: false })
    }
    render() {
        const { classes, auth, isMobileView } = this.props

        const { b_firstname, b_lastname } = decode(auth)
        if (isMobileView) {
            return (
                <React.Fragment>
                    <ClickAwayListener onClickAway={this.handleTooltipClose}>
                        <StyledTooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={this.handleTooltipClose}
                            open={this.state.open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={`${b_firstname} ${b_lastname}`}
                        >
                            <Button onClick={this.setOpen}>
                                <div className={classes.root}>
                                    <AccountCircleIcon className={classes.icon} />
                                </div>
                            </Button>
                        </StyledTooltip>
                    </ClickAwayListener>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <Button data-testid="acm_desktop">
                    <div className={classes.root}>
                        {b_firstname} {b_lastname}
                        <AccountCircleIcon className={classes.icon} />
                    </div>
                </Button>
            </React.Fragment>
        )
    }
}

export default compose(withStore, withTake('auth'), withStyles(styles), CheckForMobileView)(AccountMenu)
