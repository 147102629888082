// import { withCall } from 'react-ion-store'
import axios from 'axios'
import queryParamEncoder from 'utils/queryParamEncoder'
import { apiDateTimeParser } from 'utils/dateParser'
import DefaultSettings from 'components/DefaultSettings'
// const callKey = 'performances'
let currentDistrict = ''

let defaultParams = {
    lower: 1,
    upper: 1,
}

export default async (props, date) => {
    // Keep track on differingdistricts => helps define whether progresshandlers should be used in the future
    const selectedDistrict = props.store.get('selectedDistrict')
    if (currentDistrict !== selectedDistrict) {
        currentDistrict = selectedDistrict
    }

    const depotQS = props.tcts.reduce(
        (accumulator, tct, index) =>
            tct.depots.reduce(
                (depotString, depot, depotIndex) =>
                    `${depotString}${index === 0 && depotIndex === 0 ? '' : '%20'}${depot.symbolicName}`,
                accumulator
            ),
        ''
    )

    const storedSettings = localStorage.getItem('userSettings')
        ? JSON.parse(localStorage.getItem('userSettings'))
        : DefaultSettings

    let settings = storedSettings.filter(obj => obj.pageReference === 'performanceOverview')[0]
    if (storedSettings) {
        defaultParams = {
            lower: parseInt(settings?.startHour),
            upper: parseInt(settings?.endHour),
            critical: parseInt(settings?.critical),
            notAssignedWarning: parseInt(settings?.warning),
        }
    }
    const fullUrl = `/performance/byparams`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                sort: 'name',
                sortdir: 'asc',
                date: apiDateTimeParser(date.toMillis()),
                depot: depotQS,
                ...defaultParams,
            },
            paramsSerializer: queryParamEncoder,
        })
        if (response.status === 204) {
            return []
        }
        return response.data
    } catch (e) {
        console.error(e) // eslint-disable-line
        return []
    }
    // return withCall(
    //     callKey,
    //     // TODO: provide state check that sets progressComponents to false once it's been loaded initially
    //     {
    //         config: {
    //             method: 'get',
    //             url: '/performance/byparams',
    //             params: {
    //                 sort: 'name',
    //                 sortdir: 'asc',
    //                 date: apiDateTimeParser(props.timepickerDate.toMillis()),
    //                 depot: depotQS,
    //                 ...defaultParams,
    //             },
    //             paramsSerializer: queryParamEncoder,
    //         },

    //         reducer: ({ data }) => data || [],
    //     }
    // )(Component)(props)
}

// export default callWithAxios
