import React from 'react'
import { object, arrayOf, func, string, oneOfType, array } from 'prop-types'
import groupBy from 'lodash/groupBy'
import { withLocalize, Translate } from 'react-localize-redux'
import { compose } from 'recompose'

import { withStyles } from '@material-ui/core'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import styles from './styles'

class DepotGroup extends React.Component {
    static propTypes = {
        performances: arrayOf(object).isRequired,
        children: func.isRequired,
        saveScrollPosition: func,
        classes: object.isRequired,
        tcts: arrayOf(object).isRequired,
        selectedTCTs: arrayOf(string).isRequired,
        selectedDepot: oneOfType([string, array]).isRequired,
    }

    state = {
        openedDepot: '',
    }

    openDepot = openedDepot => {
        this.setState({ openedDepot: openedDepot === this.state.openedDepot ? '' : openedDepot }, () => {
            this.props.saveScrollPosition(0)
        })
    }

    render() {
        const { performances, tcts, selectedTCTs, selectedDepot, children, classes } = this.props
        const groupedPerformances = groupBy(performances, 'depotName')

        const depotsInPerformances = Object.keys(groupedPerformances)
        const allDepots = tcts.reduce((_accumulator, tct) => {
            const accumulator = [..._accumulator]

            if (selectedTCTs.includes(tct.id)) {
                tct.depots.forEach(depot => {
                    if (depotsInPerformances.includes(depot.symbolicName)) accumulator.push(depot.symbolicName)
                })
            }
            return accumulator
        }, [])

        //  selectedDepot.forEach(item => allDepots.filter(depo => depo !== item.substr(6)))
        const fromDropDown = selectedDepot.map(depot => depot.substr(6))

        const mixed = allDepots.filter(value => fromDropDown.includes(value))

        const activeDepots = mixed

        return (
            <Translate>
                {({ translate }) => (
                    <List className={classes.list_style}>
                        {activeDepots.map(depot => (
                            <React.Fragment key={depot}>
                                <Paper square elevation={0}>
                                    <ListItem button onClick={() => this.openDepot(depot)}>
                                        <ListItemText primary={translate(`DEPOT-${depot}`)} />
                                        <ListItemSecondaryAction>
                                            {this.state.openedDepot === depot ? (
                                                <ExpandLess className={classes.icon} />
                                            ) : (
                                                <ExpandMore className={classes.icon} />
                                            )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Paper>
                                {this.state.openedDepot === depot &&
                                    children(groupedPerformances[depot], tcts, selectedTCTs, selectedDepot)}
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </Translate>
        )
    }
}

export default compose(withLocalize, withStyles(styles))(DepotGroup)
