import React, { useState } from 'react'
import PropTypes from 'prop-types'
import deleteVacmaRestriction from '../Services/deleteVacmaRestriction'
import DeleteDialog from 'components/DeleteDialog'

const DeleteVcmaDialog = props => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const handleDeleteClick = async () => {
        setLoading(true)
        let response = await deleteVacmaRestriction(deleteData)
        if (response.status === 200) {
            reloadVacmaList()
            onDialogClose()
        } else {
            setLoading(false)
            setError(true)
        }
    }

    const { onDialogClose, dialogOpen, deleteData, translate, reloadVacmaList } = props
    return (
        <DeleteDialog
            dialogOpen={dialogOpen}
            onClose={onDialogClose}
            title={translate('compositions_vacma_delete_dialog_title')}
            translate={translate}
            handleDelete={handleDeleteClick}
            deleteMsg={translate('compositions_vacma_delete_dialog_confirm_text')}
            error={error}
            errorMsg={translate('compositions_delete_defect_dialog_error')}
            deleteInfo={deleteData}
            loading={loading}
        />
    )
}
DeleteVcmaDialog.propTypes = {
    onDialogClose: PropTypes.func,
    translate: PropTypes.func,
    reloadVacmaList: PropTypes.func,
    dialogOpen: PropTypes.bool,
    deleteData: PropTypes.string,
}
export default DeleteVcmaDialog
