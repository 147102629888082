import React from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'

import DeleteDialog from 'components/DeleteDialog'

const DeletePtcarDialog = props => {
    DeletePtcarDialog.propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        handleDelete: PropTypes.func,
        translate: PropTypes.func,
        rowData: PropTypes.object,
        activeLanguage: PropTypes.object,
        deleteError: PropTypes.string,
        loading: PropTypes.bool,
    }

    const handleDeleteButton = () => {
        if (rowData.ptCarKmMarks.length === 0) {
            handleDelete(rowData.id)
        } else {
            handleDelete(rowData.id, rowData.ptCarKmMarks[rowData.lineIndex].lineNumber)
        }
    }
    const { open, handleClose, handleDelete, translate, rowData, activeLanguage, deleteError, loading } = props
    const getDeleteMsg = () => {
        if (rowData.ptCarKmMarks.length === 0) return translate('delete_ptcar_confirm')
        else return translate('delete_line_confirm')
    }
    const getInfo = () => {
        if (rowData.ptCarKmMarks.length === 0)
            return `${rowData.uic} - ${
                activeLanguage.code === 'nl' ? rowData.commercialName[1].translation : rowData.commercialName[0].translation
            }`
        else
            return `${rowData.ptCarKmMarks[rowData.lineIndex].lineName} - ${
                activeLanguage.code === 'nl' ? rowData.commercialName[1].translation : rowData.commercialName[0].translation
            }`
    }
    return (
        <DeleteDialog
            dialogOpen={open}
            onClose={handleClose}
            title={translate('cosmosdb_performance_delete_dialog_title')}
            translate={translate}
            handleDelete={handleDeleteButton}
            error={deleteError}
            errorMsg={translate('cosmosdb_performance_delete_dialog_error')}
            deleteMsg={getDeleteMsg()}
            deleteInfo={getInfo()}
            loading={loading}
        />
    )
}
export default withLocalize(DeletePtcarDialog)
