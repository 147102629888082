import React, { useState } from 'react'
import PropTypes from 'prop-types'

import DeleteDialog from 'components/DeleteDialog'
import deletePtcarClassification from 'modules/Manage/Services/deletePtcarClassification'

const DeletePtcarClassificationDialog = props => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const handleDelete = async Id => {
        setLoading(true)
        const response = await deletePtcarClassification(Id)
        if (response) {
            props.reloadData()
            setLoading(false)
        } else {
            setLoading(false)
            setError(true)
        }
    }
    const onClosing = () => {
        setError(false)
        props.onDialogClose()
    }
    const { dialogOpen, deleteData, translate } = props
    return (
        <DeleteDialog
            dialogOpen={dialogOpen}
            onClose={onClosing}
            title={translate('manage_ptcar_classification_delete_title')}
            translate={translate}
            handleDelete={handleDelete}
            error={error}
            errorMsg={translate('platform_delete_platform_dialog_error')}
            deleteMsg={translate('manage_ptcar_classification_delete_msg')}
            deleteInfo={deleteData?.Id}
            loading={loading}
        />
    )
}
DeletePtcarClassificationDialog.propTypes = {
    onDialogClose: PropTypes.func,
    translate: PropTypes.func,
    reloadData: PropTypes.func,
    dialogOpen: PropTypes.bool,
    deleteData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    handleDelete: PropTypes.func,
}
export default DeletePtcarClassificationDialog
