import { withStyles } from '@material-ui/styles'
import React from 'react'
import styles from './styles'
import { object, func, string, bool } from 'prop-types'
import { Button } from '@material-ui/core'

const RoundedButton = props => {
    return (
        <Button 
            data-testid='RoundedButton' 
            classes={{ root: props.isActive ? props.classes.activeButton : props.classes.root }} 
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.title}
        </Button>
    )
}
RoundedButton.propTypes = {
    classes: object,
    onClick: func,
    title: string,
    isActive: bool,
    disabled: bool,
}
export default withStyles(styles)(RoundedButton)
