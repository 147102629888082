import React, { Component } from 'react'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import CalendarM510 from './components/CalendarM510'
import Header from 'components/Header'
import isAgendaAllowed, { getIdfFromStore } from 'utils/isAgendaAllowed'
import NoMatch from 'modules/NoMatch'
import styles from './styles'

export class DriverAgenda extends Component {
    static propTypes = {
        translate: PropTypes.func,
        location: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
        store: PropTypes.object,
    }

    getIdf() {
        return this.props.location.state ? this.props.location.state.idf : getIdfFromStore(this.props.store.get('auth'))
    }
    componentDidMount() {
        let idf = this.props.match.params.idfNumber
        if (!idf) {
            idf = this.getIdf()
            this.props.history.push(`/agenda/${idf}`)
        }
        document.title = this.props.translate('driver_agenda')
    }
    render() {
        const { translate } = this.props
        const isAgenda = isAgendaAllowed(this.props)
        const uniqueKey = this.props.match.params.idfNumber
        return (
            <React.Fragment>
                <Header isMenu title={translate('driver_agenda')} />
                {isAgenda ? <CalendarM510 key={uniqueKey} {...this.props} /> : <NoMatch />}
            </React.Fragment>
        )
    }
}
export default compose(withRouter, withStore, withLocalize, withStyles(styles))(DriverAgenda)
