import React from 'react'
import { object, string, any } from 'prop-types'

import AppPaper from 'components/AppPaper'

class TaskDetailFooterView extends React.Component {
    static propTypes = {
        classes: object.isRequired,
        remark: string,
        children: any,
    }

    render() {
        const { classes, children } = this.props
        return <AppPaper className={classes.root}>{children}</AppPaper>
    }
}

export default TaskDetailFooterView
