import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'

import { func, object, string, number, array } from 'prop-types'
import classNames from 'classnames'
import { withLocalize, Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { Delete, Edit } from '@material-ui/icons'
import Button from '@material-ui/core/Button'

import Loading from 'components/Loading'
import PlatformsTable from '../PlatformsTable'
import PlatformDrawer from '../PlatformDrawer'
import DeleteDialog from '../DeletePlatformDialog'
import getPlatforms from '../../Services/getPlatforms'
import addEditPlatform from '../../Services/addEditPlatform'

import styles from './styles'

const columns = [
    {
        width: 1500,
        label: 'datatable_header_platformName',
        dataKey: 'id',
    },
    {
        width: 200,
        label: 'datatable_header_actions',
        dataKey: 'actions',
    },
]

function PlatformListView(props) {
    const [data, setData] = useState([])
    const [editDrawer, setEditDrawer] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [editData, setEditData] = useState('')
    const [addEditError, setAddEditError] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [deleteRowData, setDeleteRowData] = useState([])
    const [platformFilterValue, setPlatformFilterValue] = useState('')

    const loadPlatforms = async () => {
        setLoadingData(true)

        let platforms = await getPlatforms()
        setData(platforms)
        setLoadingData(false)
    }
    const handleAddPlatform = async dataToSend => {
        let isEdit = editDrawer
        if (isEdit) {
            dataToSend.id = editData.id
        }
        let response = await addEditPlatform(dataToSend, isEdit)

        if (response.status === 200) {
            setAddEditError('')
            setOpenDrawer(false)
            setEditDrawer(false)
            reloadPlatforms()
        } else {
            setLoadingData(false)
            setAddEditError(response.data.Message)
        }
    }
    useEffect(() => {
        loadPlatforms()
        document.title = props.translate('ptcar_overview')
    }, [])

    const getRowClassName = ({ index }) => {
        const { rowClassName, onRowClick } = props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    const onRowDeleteClick = (e, rowData) => {
        setOpenDeleteDialog(true)
        setDeleteRowData(rowData)

        e.stopPropagation()
    }

    const onRowEditClick = (e, rowData) => {
        setEditDrawer(true)
        setOpenDrawer(true)
        setEditData(rowData)

        e.stopPropagation()
    }

    const handleAddClick = () => {
        setOpenDrawer(true)
        setEditDrawer(false)
        setEditData('')
    }

    const onDrawerClose = () => {
        setOpenDrawer(false)
        setEditDrawer(false)
        setEditData('')
        setAddEditError('')
    }
    const onDialogClose = () => {
        setOpenDeleteDialog(false)
        setAddEditError('')
    }
    const reloadPlatforms = () => {
        loadPlatforms()
    }
    useEffect(() => {
        let code = props.activeLanguage.code
        let platformFilteredData = data.filter(item => {
            if (code === 'fr')
                return item.platformName[0].translation.toLowerCase().includes(platformFilterValue.toLowerCase())
            else return item.platformName[1].translation.toLowerCase().includes(platformFilterValue.toLowerCase())
        })
        setFilteredData(platformFilteredData)
    }, [platformFilterValue])

    const handlePlatformFilter = e => {
        let filteredString = e.target.value
        setPlatformFilterValue(filteredString)
    }

    const headerRenderer = headerProps => {
        const { headerHeight } = props
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: headerHeight,
                    paddingTop: 34,
                    paddingBottom: 34,
                    paddingRight: 0,
                }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }
    const cellRenderer = cellProps => {
        const { activeLanguage } = props
        const { dataKey, rowData } = cellProps
        if (dataKey === 'id') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: props.rowHeight }}>
                    <Typography>
                        {activeLanguage.code === 'fr'
                            ? rowData.platformName[0].translation
                            : rowData.platformName[1].translation}
                    </Typography>
                </TableCell>
            )
        }

        if (dataKey === 'actions') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: props.rowHeight }}>
                    <div className={classes.multipleIcons}>
                        <Edit
                            className={classes.editIcon}
                            onClick={e => {
                                onRowEditClick(e, rowData)
                            }}
                        />

                        <Delete
                            className={classes.deleteIcon}
                            onClick={e => {
                                onRowDeleteClick(e, rowData)
                            }}
                        />
                    </div>
                </TableCell>
            )
        }
    }

    const { classes, translate } = props
    let filterString = platformFilterValue
    let listData = filterString.length > 1 ? filteredData : data
    return (
        <div>
            <div className={classes.parentDiv} style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                    <Typography style={{ marginRight: 10 }}>{translate('platform_filter')}</Typography>
                    <input
                        type="text"
                        className={classes.customInput}
                        placeholder={translate('search')}
                        value={filterString}
                        onChange={e => handlePlatformFilter(e)}
                    />
                </div>
                <Typography style={{ marginLeft: 10 }}>{translate('platform_page')}</Typography>
                <div>
                    <Button variant="contained" color="primary" onClick={() => handleAddClick()}>
                        {translate('module_add_performance')}
                    </Button>
                </div>
            </div>
            {loadingData && <Loading />}
            {!loadingData && (
                <PlatformsTable
                    columns={columns}
                    data={listData}
                    classes={classes}
                    headerRenderer={headerRenderer}
                    cellRenderer={cellRenderer}
                    getRowClassName={getRowClassName}
                />
            )}
            {openDrawer && (
                <PlatformDrawer
                    isOpen={openDrawer}
                    editDrawer={editDrawer}
                    onClose={onDrawerClose}
                    editData={editData}
                    handleAdd={handleAddPlatform}
                    addEditError={addEditError}
                    {...props}
                />
            )}
            {openDeleteDialog && (
                <DeleteDialog
                    dialogOpen={openDeleteDialog}
                    onDialogClose={onDialogClose}
                    reloadPlatforms={reloadPlatforms}
                    deleteData={deleteRowData}
                    {...props}
                />
            )}
        </div>
    )
}
PlatformListView.propTypes = {
    classes: object.isRequired,
    rowClassName: string,
    onRowClick: func,
    headerHeight: number,
    rowHeight: number,
    activeLanguage: object,
    history: object,
    match: object,
    translate: func,
    data: array,
    filteredData: array,
    store: object,
}
export default compose(withLocalize, withRouter, withStyles(styles))(PlatformListView)
