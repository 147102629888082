import React from 'react'
import { bool, func, object } from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/styles'

import AddVehicleView from './AddVehicleView'
import EditVehicleView from './EditVehicleView'

import styles from './styles'

const CompositionDrawer = props => {
    const { isOpen, onClose, openDrawerForAddVehicle, classes, ...others } = props
    const { drawer, ...restClasses } = classes
    return (
        <Drawer open={isOpen} anchor="right" onClose={onClose}>
            <form noValidate autoComplete="off">
                <Grid className={drawer}>
                    {openDrawerForAddVehicle ? (
                        <AddVehicleView {...others} />
                    ) : (
                        <EditVehicleView classes={restClasses} {...others} />
                    )}
                </Grid>
            </form>
        </Drawer>
    )
}

CompositionDrawer.propTypes = {
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    classes: object,
    data: object,
    openDrawerForAddVehicle: bool,
}

export default withStyles(styles)(CompositionDrawer)
