import axios from 'axios'

export default async Id => {
    const fullUrl = `/referencedata/ptcarclassification/${Id}/delete`

    try {
        return await axios.post(fullUrl)
    } catch (e) {
        return null
    }
}
