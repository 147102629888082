import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, Column, Table } from 'react-virtualized'

function PerformanceTableForTrainNumber(props) {
    const {
        data,
        columns,
        classes,
        rowHeight,
        headerHeight,
        headerRenderer,
        cellRenderer,
        getRowClassName,
        rowClickHandler,
    } = props
    return (
        <div className={classes.documentTableWrapper}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        className={classes.table}
                        height={height}
                        width={width}
                        rowCount={data.length}
                        rowClassName={getRowClassName}
                        onRowClick={({ rowData }) => {
                            rowClickHandler(rowData)
                        }}
                        // rowRenderer={defaultTableRowRenderer}
                        rowRenderer={props.rowRenderer}
                        rowGetter={({ index }) => data[index]}
                        rowHeight={rowHeight}
                        headerHeight={headerHeight}
                    >
                        {columns.map(({ dataKey, ...other }) => (
                            <Column
                                key={dataKey}
                                headerRenderer={headerRenderer}
                                className={classes.flexContainer}
                                cellRenderer={cellRenderer}
                                dataKey={dataKey}
                                {...other}
                            />
                        ))}
                    </Table>
                )}
            </AutoSizer>
        </div>
    )
}
PerformanceTableForTrainNumber.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.array,
    classes: PropTypes.object,
    headerHeight: PropTypes.number,
    rowHeight: PropTypes.number,
    headerRenderer: PropTypes.func,
    cellRenderer: PropTypes.func,
    getRowClassName: PropTypes.func,
    rowClickHandler: PropTypes.func,
    rowRenderer: PropTypes.func,
}
export default PerformanceTableForTrainNumber
