export default theme => ({
    drawerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
    },
    readList: {
        maxHeight: `calc(100vh - ${theme.readList + 116}px)`,
        overflowY: 'auto',
        marginBottom: theme.spacing(2),
    },
    profileInfo: {
        marginTop: theme.spacing(2),
    },
    profileInfoTitle: {
        fontWeight: 'bold',
    },
    topSpacingTitle: {
        marginTop: theme.spacing(3),
    },
})
