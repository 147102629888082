import React from 'react'
import { func, object } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus } from 'react-ion-store'
import { DateTime } from 'luxon'
import axios from 'axios'

import { withStyles } from '@material-ui/core/styles'

import ExtendedOrganisationsView from './ExtendedOrganisationsView'
import checkWriteAccess from 'utils/checkWriteAccess'
import NoRights from '../../../NoRights'

import styles from './styles'

const storeKey = 'mappedDistrictData'

const resetState = {
    selectedName: '',
    org: ' ',
    selectedProfile: undefined,
    delError: false,
    addError: false,
    orgsForUser: [],
}

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

class ExtendedOrganisations extends React.Component {
    static propTypes = {
        __ion_status: object.isRequired,
        onClose: func.isRequired,
        store: object,
    }

    state = resetState

    persist = type => props => {
        const toSetState = { [type]: props }
        this.setState(toSetState)
    }

    addToOrgs = (id, code) => {
        const { selectedProfile } = this.state

        if (id && code && selectedProfile && selectedProfile.idfNumber !== undefined) {
            this.addOrgForUser(id, selectedProfile, code)
        } else {
            this.setState({ addError: true })
        }
    }

    deleteOrg = id => {
        const { selectedProfile, orgsForUser } = this.state

        if (typeof id === 'number' && selectedProfile) {
            this.delOrgDataForUser(id, selectedProfile.idfNumber)
            //del from state
            const reducedOrgs = orgsForUser.filter(org => org.organisationId !== id)
            this.setState({ orgsForUser: reducedOrgs })
        } else {
            this.setState({ delError: true })
        }
    }

    addOrganisation = (id, name) => {
        this.addToOrgs(id, name)
    }

    setName = selectedProfile => {
        if (selectedProfile) this.getOrgDataForUser(selectedProfile)
        this.setState({
            selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
            selectedProfile,
        })
    }

    delOrgDataForUser = async (id, idf) => {
        const { url, subscriptionKey } = requestData
        const fullUrl = `${url}/profile/${idf}/extendedorganisation/${id}`
        // eslint-disable-next-line no-unused-vars
        let data = []

        try {
            const response = await axios.delete(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            data = response.data
            return data
        } catch (e) {
            console.error(e) // eslint-disable-line
        }
    }

    getOrgDataForUser = async selectedProfile => {
        const { url, subscriptionKey } = requestData
        const fullUrl = `${url}/profile/${selectedProfile.idfNumber}/extendedorganisations`
        let data = []

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            data = response.data
        } catch (e) {
            console.error(e) // eslint-disable-line
        }

        this.setState({ orgsForUser: data })
    }

    addOrgForUser = async (id, selectedProfile, code) => {
        const { url, subscriptionKey } = requestData
        const fullUrl = `${url}/profile/${selectedProfile.idfNumber}/saveextendedorganisation`
        // eslint-disable-next-line no-unused-vars
        let callbackData = []

        try {
            const response = await axios.post(fullUrl, {
                Id: parseInt(id),
                Code: code,
                BeginDate: parseInt(
                    DateTime.local()
                        .setZone('Europe/Brussels')
                        .toMillis() / 1000
                ),
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            callbackData = response.data
            this.getOrgDataForUser(selectedProfile)
            return callbackData
        } catch (e) {
            console.error(e) // eslint-disable-line
        }

        this.setState({ org: ' ' })
    }

    clearName = () => this.setState(resetState)

    onClose = () => {
        this.clearName()
        this.props.onClose()
    }

    render() {
        if (checkWriteAccess(this.props, 'edit organisation', 'documents.functionality.management.users')) {
            const districts = this.props.store.get(storeKey) || []

            const tcts = districts.reduce(
                (aggregrate, district) => [
                    ...aggregrate,
                    ...district.tcts.map(tct => ({ id: `${tct.tctId}`, text: tct.id })),
                ],
                [{ id: ' ', text: 'documents_label_select_org' }]
            )
            const { org, orgsForUser } = this.state
            return (
                <ExtendedOrganisationsView
                    {...this.props}
                    {...this.state}
                    org={org}
                    tcts={tcts}
                    districts={districts}
                    addOrganisation={this.addOrganisation}
                    deleteOrg={this.deleteOrg}
                    setName={this.setName}
                    clearName={this.clearName}
                    onClose={this.onClose}
                    orgsForUser={orgsForUser}
                />
            )
        } else {
            return <NoRights />
        }
    }
}

export default compose(withStore, withStatus, withStyles(styles), withLocalize)(ExtendedOrganisations)
