const districts = [
    {
        Id: 'DISTRICT-1',
        Name: [
            {
                Language: 'fr-BE',
                Translation: 'Nord-Ouest',
            },
            {
                Language: 'nl-BE',
                Translation: 'Noord-West',
            },
        ],
    },
    {
        Id: 'DISTRICT-2',
        Name: [
            {
                Language: 'fr-BE',
                Translation: 'Nord-Est',
            },
            {
                Language: 'nl-BE',
                Translation: 'Noord-Oost',
            },
        ],
    },
    {
        Id: 'DISTRICT-3',
        Name: [
            {
                Language: 'fr-BE',
                Translation: 'Centrale',
            },
            {
                Language: 'nl-BE',
                Translation: 'Centraal',
            },
        ],
    },
    {
        Id: 'DISTRICT-4',
        Name: [
            {
                Language: 'fr-BE',
                Translation: 'Sud-Ouest',
            },
            {
                Language: 'nl-BE',
                Translation: 'Zuid-West',
            },
        ],
    },
    {
        Id: 'DISTRICT-5',
        Name: [
            {
                Language: 'fr-BE',
                Translation: 'Sud-Est',
            },
            {
                Language: 'nl-BE',
                Translation: 'Zuid-Oost',
            },
        ],
    },
    {
        Id: 'DISTRICT-6',
        Name: [
            {
                Language: 'fr-BE',
                Translation: 'Centrale-direcie',
            },
            {
                Language: 'nl-BE',
                Translation: 'Centrale-direcie',
            },
        ],
    },
]

const getDistrictNameById = districtId => districts.filter(district => district.Id === districtId)[0]

export default getDistrictNameById
