import leerlingDepots from './leerlingdepots.json'

const isDepotLeerling = depotName => {
    let matched = false

    leerlingDepots.forEach(element => {
        if (element.symbolicName === depotName) {
            matched = true
        }
    })
    return matched
}
export default isDepotLeerling
