import React from 'react'
import { object, string, func } from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { DatePickerWithKeyboard } from 'components/Pickers'
import styles from './styles'

const LindaSettingView = props => {
    return (
        <div style={{ marginTop: '8px' }}>
            <Typography variant={'h6'}>{props.title}</Typography>
            <div className={props.classes.row}>
                <Typography>{'Valid from'}</Typography>
                <DatePickerWithKeyboard
                    label="DatePicker"
                    animateYearScrolling
                    value={props.value}
                    onChange={e => props.changeDate(props.type, e)}
                    format="dd/MM/yyyy"
                />
                <Typography onClick={() => props.copyToAllHandler(props.type)} className={props.classes.link}>
                    {'Copy To All'}
                </Typography>
            </div>
        </div>
    )
}
LindaSettingView.propTypes = {
    title: string,
    classes: object,
    changeDate: func,
    copyToAllHandler: func,
    type: string,
    value: object,
}

export default withStyles(styles)(LindaSettingView)
