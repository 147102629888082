export default theme => ({
    drawerRoot: {
        width: '500px',
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    },
    readList: {
        maxHeight: `calc(100vh - ${theme.readList}px)`,
        overflowY: 'auto',
        marginBottom: theme.spacing(2),
    },
    profileInfo: {
        marginTop: theme.spacing(2),
    },
    profileInfoTitle: {
        fontWeight: 'bold',
    },
})
