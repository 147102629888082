import React from 'react'
import { object, bool, func, string } from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import PlatformForm from './PlatformForm'

const PlatformDrawer = props => {
    const { isOpen, onClose, editDrawer, classes, translate, addEditError } = props

    return (
        <Drawer open={isOpen} anchor="right" style={{ zIndex: 1500 }} onClose={onClose}>
            <div className={classes.drawerRoot}>
                <Typography variant="h6">
                    {editDrawer ? translate('platform_edit_platform') : translate('platform_add_platform')}
                </Typography>
                <PlatformForm error={addEditError} {...props} />
            </div>
        </Drawer>
    )
}

PlatformDrawer.propTypes = {
    classes: object.isRequired,
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    translate: func,
    editDrawer: bool,
    addEditError: string,
}

export default PlatformDrawer
