import NotSignedOn from './NotSignedOn'
import SignedOn from './SignedOn'
import NotAssigned from './NotAssigned'
import NotValidated from './NotValidated'

import { statusEnum } from 'utils/statusEnum'
import SignedOff from './SignedOff'

export default (cellProps, props) => {
    const { activeTab } = props

    switch (activeTab) {
        case statusEnum.NOT_SIGNED_IN:
            return NotSignedOn(cellProps, props)
        case statusEnum.SIGNED_IN:
            return SignedOn(cellProps, props)
        case statusEnum.SIGNED_OFF:
            return SignedOff(cellProps, props)
        case statusEnum.NOT_ASSIGNED:
            return NotAssigned(cellProps, props)
        case statusEnum.NOT_VALIDATED:
            return NotValidated(cellProps, props)
        default:
            // console.log("Something else"); // eslint-disable-line
            return NotSignedOn(cellProps, props)
    }
}
