import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import Header from 'components/Header'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { func, object, bool } from 'prop-types'
import ActionBar from 'components/ActionBar'
import DateTimeRangePicker from '../../../../components/DateTimeRangePicker'
import { apiDateParser } from 'utils/dateParser'
import { Typography } from '@material-ui/core'
import PerformanceGroup from './components/PerformanceGroup'
import PerformanceChildList from './components/PerformanceGroup/PerformanceChildList'
import { DateTime } from 'luxon'
import groupBy from 'lodash/groupBy'
import Loading from 'components/Loading'
import { isTablet } from 'react-device-detect'
import { withOrientationChange } from 'react-device-detect'

import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
class Feedback extends PureComponent {
    static propTypes = {
        translate: func.isRequired,
        classes: object.isRequired,
        isPortrait: bool,
        isTablet: bool,
    }
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            fromDate: DateTime.local().minus({ days: 1 }),
            toDate: DateTime.local(),
            datesArray: [],
            loading: false,
        }
    }

    persist = type => props => {
        if (type === 'feedbackdate') {
            this.setState({ data: [] })
            this.loadFeedback(apiDateParser(new Date(props[0]).getTime()), apiDateParser(new Date(props[1]).getTime()))
            return
        }
    }

    async loadFeedback(fromDate, toDate) {
        this.setState({ loading: true })
        const { url, subscriptionKey } = requestData
        let fullUrl = `${url}/performance/feedback?fromDate=${fromDate}&toDate=${toDate}`

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            if (response.status === 204) {
                this.setState({ data: [], loading: false })
            }

            if (response.status === 200) {
                const groupedPerformances = groupBy(response.data, 'performanceDate')
                const dates = Object.keys(groupedPerformances)

                this.setState({ data: Object.values(groupedPerformances), datesArray: dates, loading: false })
            }
        } catch (e) {
            this.setState({ data: [], loading: false })
            // if (e.response.status === 400) {
            //   this.setState({ downloadError: e.response.data.Message, downloading: false });
            // } else {
            //   this.setState({ downloadError: "Download failed", downloading: false });
            // }
        }
    }
    componentDidMount() {
        this.loadFeedback(
            apiDateParser(new Date(this.state.fromDate).getTime()),
            apiDateParser(new Date(this.state.toDate).getTime())
        )
        document.title = this.props.translate('module_feedback')
    }

    render() {
        const { isPortrait } = this.props
        if (isTablet && isPortrait) {
            return <div>Please change device orientation to Landscape</div>
        }

        const { classes, translate } = this.props
        const { datesArray, data } = this.state
        return (
            <div>
                <Header isMenu title={translate('module_feedback')} />
                <ActionBar>
                    <DateTimeRangePicker
                        persist={this.persist('feedbackdate')}
                        identifier="feedback"
                        startValue={this.state.fromDate}
                        endValue={this.state.toDate}
                        color={'tertiary'}
                        disableFuture={false}
                    />
                </ActionBar>

                {this.state.loading ? (
                    <Loading />
                ) : data.length > 0 ? (
                    data.map((d, i) => (
                        <div key={d} className={classes.submenu}>
                            <Typography className={classes.submenuTitle} variant="h6">
                                {DateTime.fromSeconds(parseInt(datesArray[i], 10)).toFormat('dd/MM/yyyy')}
                            </Typography>
                            <PerformanceGroup performances={d}>
                                {() => <PerformanceChildList key={d} data={d} translate={translate} />}
                            </PerformanceGroup>
                        </div>
                    ))
                ) : (
                    <Typography className={classes.submenu} variant="h6">
                        {translate('no_data_available')}
                    </Typography>
                )}
            </div>
        )
    }
}

export default compose(withOrientationChange, withLocalize, withStyles(styles))(Feedback)
