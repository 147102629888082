import React from 'react'
import { object, bool, func, string, array, dateTime} from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'

import IconButton from 'components/IconButton'
import PersonSearcher from 'components/PersonSearcher'
import UserListForFeature from './UserListForFeature'
import OrganisationListForFeature from './OrganisationListForFeature'
import ErrorMessage from 'components/ErrorMessage'
import { DatePickerWithKeyboard } from 'components/Pickers'
import {pickerValue} from 'components/DatePickerOrg'
import { Grid, TextField} from '@material-ui/core'
import { isEmpty } from 'lodash'

import AddOrganisation from './components/AddOrganisation'

const getOrgsName = selectedProfile => {
    return selectedProfile?.organisations?.map(org => org.organisationCode).join()
}
const AddNewUserDrawerView = ({
    classes,
    open,
    onClose,
    selectedName,
    selectedProfile,
    setName,
    clearName,
    translate,
    addNewUserCall,
    handleDeleteUser,
    userListForFeatureId,
    organosationListForFeatureId,
    addNewUserCallLoading,
    addNewUserCallError,
    featureName,
    existingUserId,
    isUserListLoading,
    OrganisationsData,
    handleLocationSearchValue,
    clearLocationSearchValue,
    locationSearchValue,
    selectedLocationSearchItem,
    addOrganisation,
    userListFolded,
    setUserListFolded,
    organisationListFolded,
    setOrganisationListFolded,
    handleDeleteOrganisation,
    addNewOrganisationCallLoading,
    isOrganisationListLoading,
    addNewOrganisationError,
    validFrom,
    setValidFrom,
    validTo,
    setValidTo,
    addNewOrganisationDatesError,
    isValidDates,
}) => {
    
    let orgs = ''
    if (selectedProfile) {
        orgs = getOrgsName(selectedProfile)
    }
    return (
        <Drawer open={open} anchor="right" style={{ zIndex: 98 }} onClose={onClose}>
            <div className={classes.drawerRoot}>
                <div>
                    {/* eslint-disable-next-line max-len */}
                    <TextField style={{marginTop: 56}} value={featureName} label={translate('feature_name_label')} disabled={true} />

                    <Typography style={{ marginTop: 16 }} variant="h6">
                        {translate('add_organisation_label')}
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />

                    <div className={classes.addOrganisationContainer}>
                        <AddOrganisation
                            classes={classes}
                            allLocations={OrganisationsData}
                            handleLocationSearchValue={handleLocationSearchValue}
                            clearLocationSearchValue={clearLocationSearchValue}
                            locationSearchValue={locationSearchValue}
                            selectedLocationSearchItem={selectedLocationSearchItem}
                            addOrganisation={addOrganisation}
                            isValidDates={isValidDates}
                        />
                        {!isEmpty(selectedLocationSearchItem) && 
                        <div style={{ marginTop: '8px' ,width: '100%' , display: 'flex'}}>
                            <Grid item xs={6} style={{paddingRight: '8px'}}>
                                <DatePickerWithKeyboard
                                    label="DatePicker"
                                    animateYearScrolling
                                    value={pickerValue(validFrom)}
                                    onChange={e => setValidFrom(e)}
                                    format="dd/MM/yyyy"
                                />
                            </Grid>
                            <Grid item xs={6} style={{paddingLeft: '8px'}}>
                                <DatePickerWithKeyboard
                                    label="DatePicker"
                                    animateYearScrolling
                                    value={pickerValue(validTo)}
                                    onChange={e => setValidTo(e)}
                                    format="dd/MM/yyyy"
                                />
                            </Grid>
                    </div>
                    }
                    {addNewOrganisationError.length > 0 && <ErrorMessage spacing={false} error={addNewOrganisationError} />}
                    {/* eslint-disable-next-line max-len */}
                    {addNewOrganisationDatesError.length > 0 && <ErrorMessage spacing={false} error={addNewOrganisationDatesError} />}
                    </div>

                    
                    <Typography style={{ marginTop: 16 }} variant="h6">
                        {translate('add_user_label')}
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <div style={{ position: 'relative', marginBottom: 16 }} name="PersonSearcherWrapper" tabIndex={0}>
                        <PersonSearcher {...{ selectedName, setName, clearName }} />
                    </div>
                    {selectedName && (
                        
                           
                        <div className={classes.profileInfo}>
                            <Grid container>
                                <Grid item xs={4}>
                                    {`${translate('search_label_name')}${' :'}`}
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">
                                        {`${selectedProfile.firstName} ${selectedProfile.lastName}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    {`${translate('search_label_idf')}${' :'}`}
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">{selectedProfile.idfNumber}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    {`${translate('search_label_org')}${' :'}`}
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">{orgs}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    {`${translate('search_label_user_name')} :`}
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">{selectedProfile.userName}</Typography>
                                </Grid>
                            </Grid>


                            <Grid container>
                                {!existingUserId ? (
                                    <Grid item xs={12}>
                                        <IconButton
                                            className={classes.button}
                                            icon="person_add"
                                            title={translate('icon_button_add')}
                                            disabled={addNewUserCallLoading}
                                            onClick={() => {
                                                addNewUserCall()
                                            }}
                                        />
                                    </Grid>
                                ) : null}

                            </Grid>
                            {existingUserId && (
                                <IconButton
                                    color="secondary"
                                    className={classes.button}
                                    icon="person"
                                    title={translate('delete_user_label')}
                                    disabled={addNewUserCallLoading}
                                    onClick={() => {
                                        handleDeleteUser(existingUserId)
                                    }}
                                />
                            )}
                            {addNewUserCallError.length > 0 && <ErrorMessage spacing={false} error={addNewUserCallError} />}
                        </div>
                        
                    )}

                        <OrganisationListForFeature
                            handleDeleteOrganisation={handleDeleteOrganisation}
                            organosationListForFeatureId={organosationListForFeatureId}
                            translate={translate}
                            classes={classes}
                            addNewOrganisationCallLoading={addNewOrganisationCallLoading}
                            organisationListFolded={organisationListFolded}
                            setOrganisationListFolded={setOrganisationListFolded}
                            locationList={OrganisationsData}
                            isOrganisationListLoading={isOrganisationListLoading}
                        />

                        <UserListForFeature
                            handleDeleteUser={handleDeleteUser}
                            userData={userListForFeatureId}
                            translate={translate}
                            classes={classes}
                            addNewUserCallLoading={addNewUserCallLoading}
                            userListFolded={userListFolded}
                            setUserListFolded={setUserListFolded}
                            isUserListLoading={isUserListLoading}
                        />
                </div>
            </div>
        </Drawer>
    )
}

AddNewUserDrawerView.propTypes = {
    classes: object.isRequired,
    open: bool.isRequired,
    addNewUserCallLoading: bool,
    onClose: func.isRequired,
    selectedName: string.isRequired,
    addNewUserCallError: string,
    selectedProfile: object,
    setName: func.isRequired,
    clearName: func.isRequired,
    translate: func,
    addNewUserCall: func,
    handleDeleteUser: func,
    featureName: string,
    userListForFeatureId: array,
    organosationListForFeatureId: array,
    existingUserId: string,
    isUserListLoading: bool.isRequired,
    OrganisationsData: array,
    handleLocationSearchValue: func,
    clearLocationSearchValue: func,
    locationSearchValue: string,
    selectedLocationSearchItem: object,
    addOrganisation: func,
    userListFolded: bool,
    setUserListFolded: func,
    organisationListFolded: bool,
    setOrganisationListFolded: func,
    handleDeleteOrganisation: func,
    addNewOrganisationCallLoading: bool,
    isOrganisationListLoading: bool,
    addNewOrganisationError: string,
    validFrom: dateTime,
    setValidFrom: func,
    validTo: dateTime,
    setValidTo: func,
    addNewOrganisationDatesError: string,
    isValidDates: bool,
}

export default AddNewUserDrawerView
