export default theme => ({
    container: {
        marginLeft: '12px',
        display: 'flex',
        alignItems: 'center',
        padding: '16px',
    },
    readList: {
        maxHeight: `calc(100vh - ${theme.readList}px)`,
        overflowY: 'auto',
        marginBottom: theme.spacing(2),
    },
    profileInfo: {
        marginTop: theme.spacing(2),
    },
    profileInfoTitle: {
        fontWeight: 'bold',
    },
})
