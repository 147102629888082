/* eslint-disable react/prop-types */
import React from 'react'
import { compose } from 'recompose'
import { withStore, withCall } from 'react-ion-store'

const callKey = 'addTaskCall'

const AddTaskCall = () => null

export default compose(withStore, Component => props => {
    const callData = props.store.get(callKey) || {}
    const {
        type,
        remarkInput,
        taskType,
        trainPrefix,
        trainNumber,
        location,
        locationPtcarId,
        locationFrom,
        locationFromPtcarId,
        locationTo,
        locationToPtcarId,
        diagramNumber,
        startTime,
        endTime,
        performanceId,
        idfNumber,
        selectedPerformanceNumber,
        selectedReferencePerformanceNumber,
        ptcarUicTo,
        ptcarUicFrom,
        LindaRole,
    } = callData
    let trainComposed = null

    if (trainPrefix !== ' ' || (trainPrefix !== '' && trainNumber.length > 0)) {
        trainComposed = `${trainPrefix}${trainNumber}`
    }

    if (trainPrefix === ' ' || (trainPrefix === '' && trainNumber.length > 0)) {
        trainComposed = trainNumber
    }

    const perf = performanceId || selectedPerformanceNumber
    return perf && perf !== undefined && type ? (
        withCall(callKey, {
            config: {
                method: 'post',
                url: `/performance/${perf}/task/add`,
                data: {
                    IdfNumber: idfNumber || null,
                    Code: taskType,
                    Comment: remarkInput.length !== 0 ? remarkInput : null,
                    PlannedStartTime: startTime || null,
                    PlannedEndTime: endTime || null,
                    LocationFrom: locationFrom !== undefined && locationFrom.length > 1 ? locationFrom : null,
                    LocationFromPtcarId:
                        locationFromPtcarId !== undefined && locationFromPtcarId.length > 1 ? locationFromPtcarId : null,
                    LocationTo: locationTo !== undefined && locationTo.length > 1 ? locationTo : null,
                    LocationToPtcarId:
                        locationToPtcarId !== undefined && locationToPtcarId.length > 1 ? locationToPtcarId : null,
                    Location: location !== undefined && location.length > 1 ? location : null,
                    LocationPtcarId: locationPtcarId !== undefined && locationPtcarId.length > 1 ? locationPtcarId : null,
                    TrainComposed: trainComposed !== undefined && trainComposed.length > 0 ? trainComposed : null,
                    DiagramNumber: diagramNumber !== undefined && diagramNumber.length > 1 ? diagramNumber : null,
                    ReferencedPerformanceActualId:
                        selectedReferencePerformanceNumber !== undefined && selectedReferencePerformanceNumber.length > 1
                            ? selectedReferencePerformanceNumber
                            : null,
                    LocationFromPtcarUic: ptcarUicFrom,
                    LocationToPtcarUic: ptcarUicTo,
                    LindaRole: LindaRole,
                },
            },
        })(Component)(props)
    ) : (
        <Component {...props} />
    )
})(AddTaskCall)
