const languageFR = 'fr-BE'
const languageNL = 'nl-BE'

const getTitle = (activeLanguage, title) => {
    if (activeLanguage.code === 'fr') {
        const des = title.find(item => item.language === 'fr-BE')
        return des.translation
    }
    if (activeLanguage.code === 'nl') {
        const des = title.find(item => item.language === 'nl-BE')
        return des.translation
    }
}

export const isObject = value => {
    return value && typeof value === 'object' && value.constructor === Object
}
export const getNlString = (obj, valueFor) => {
    const nlValue = obj[valueFor].find(item => item.language === languageNL)
    return nlValue ? nlValue.translation : ''
}
export const getFrString = (obj, valueFor) => {
    const frValue = obj[valueFor].find(item => item.language === languageFR)
    return frValue ? frValue.translation : ''
}

export default getTitle
