import DefaultSettings from 'components/DefaultSettings'

const getStoredRefreshRate = (pageTitle = 'performanceOverview') => {
    let storedSettings = localStorage.getItem('userSettings') ? JSON.parse(localStorage.getItem('userSettings')) : false
    const defaultSettings = JSON.stringify(DefaultSettings)
    if (!storedSettings) {
        localStorage.setItem('userSettings', defaultSettings)
        storedSettings = JSON.parse(localStorage.getItem('userSettings'))
    }

    if (!Array.isArray(storedSettings)) {
        localStorage.setItem('userSettings', defaultSettings)
        storedSettings = JSON.parse(localStorage.getItem('userSettings'))
    }
    let refreshRate = null
    if (storedSettings) {
        let rateString = storedSettings.filter(obj => obj.pageReference === pageTitle)[0]?.refreshRate
        if(!rateString){
            let userSettingsObj = DefaultSettings.find(obj => obj.pageReference === pageTitle)
            if(userSettingsObj){
                storedSettings.push(userSettingsObj)
                localStorage.setItem('userSettings', JSON.stringify(storedSettings))
                rateString = storedSettings.filter(obj => obj.pageReference === pageTitle)[0]?.refreshRate
            }
        }
        let rate = parseInt(rateString)
        refreshRate = rate < 20 ? 20 : rate
        //if rate is undefined, avoid crash (NaN < 20 gives false)
        if (isNaN(rate)) {
            refreshRate = 20
        }
    } else refreshRate = DefaultSettings.filter(obj => obj.pageReference === pageTitle)[0].refreshRate
    return refreshRate
}
export default getStoredRefreshRate
