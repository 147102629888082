import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, Column, Table, defaultTableRowRenderer } from 'react-virtualized'

function PtcarTable(props) {
    let defaultHeaderHeight = 56
    let defaultRowHeight = 56

    const { data, columns, classes, rowHeight, headerHeight, headerRenderer, cellRenderer, getRowClassName } = props

    return (
        <div data-testid="ptcar_table" className={classes.documentTableWrapper}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        className={classes.table}
                        height={height}
                        width={width}
                        rowCount={data.length}
                        rowClassName={getRowClassName}
                        rowRenderer={defaultTableRowRenderer}
                        rowGetter={({ index }) => data[index]}
                        rowHeight={rowHeight || defaultRowHeight}
                        headerHeight={headerHeight || defaultHeaderHeight}
                    >
                        {columns.map(({ dataKey, ...other }) => (
                            <Column
                                key={dataKey}
                                headerRenderer={headerRenderer}
                                className={classes.flexContainer}
                                cellRenderer={cellRenderer}
                                dataKey={dataKey}
                                {...other}
                            />
                        ))}
                    </Table>
                )}
            </AutoSizer>
        </div>
    )
}
PtcarTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.array,
    classes: PropTypes.object,
    headerHeight: PropTypes.number,
    rowHeight: PropTypes.number,
    headerRenderer: PropTypes.func,
    cellRenderer: PropTypes.func,
    getRowClassName: PropTypes.func,
}

export default PtcarTable
