import React from 'react'
import { bool, func, object, any, array, string } from 'prop-types'
import { compose, pure } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import Loading from 'components/Loading'
import { PaperBox } from 'components/PaperBox'
import IconButton from 'components/IconButton'
import ErrorMessage from 'components/ErrorMessage'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

import styles from './styles'

const PerformanceDriveDetailsDrawerView = ({
    isOpen,
    close,
    classes,
    data,
    translate,
    downloading,
    downloadError,
    openStream,
    store,
}) => {
    const mapLogs = logs => {
        return logs.map(log => {
            if (log.isInDriveMode) {
                return (
                    <PaperBox
                        key={log.deviceTime}
                        redBox={false}
                        value={`${log.deviceTime.substring(0, 5)} - ${translate(
                            'performances_label_drivedetails_drivemode_opened'
                        )}`}
                        deviceId={`${translate('performances_label_drivedetails_device')} ${log.deviceId}`}
                        speed={`${translate('performances_label_drivedetails_speed')} ${log.speed}`}
                    />
                )
            } else {
                return (
                    <PaperBox
                        key={log.deviceTime}
                        redBox={false}
                        value={`${log.deviceTime.substring(0, 5)} - ${translate(
                            'performances_label_drivedetails_drivemode_closed'
                        )}`}
                        deviceId={`${translate('performances_label_drivedetails_device')} ${log.deviceId}`}
                        speed={`${translate('performances_label_drivedetails_speed')} ${log.speed}`}
                    />
                )
            }
        })
    }

    return (
        <Drawer open={isOpen} style={{ zIndex: 1300 }} anchor="right" onClose={close}>
            {!data && (
                <div className={classes.drawerRoot}>
                    <div>
                        <Loading />
                    </div>
                </div>
            )}
            {data && data.length === 0 && (
                <div className={classes.drawerRoot}>
                    <div className={classes.drawerHeader}>
                        <Typography variant="h6">
                            <Translate id="performances_label_drivedetails_title" />
                        </Typography>
                    </div>
                    <Typography variant="h6">
                        <Translate id="performances_label_drivedetails_sub_title" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <Typography variant="body1">{translate('performances_label_drivedetails_no_data')}</Typography>
                </div>
            )}
            {data && data.length > 0 && (
                <div className={classes.drawerRoot}>
                    <div className={classes.drawerHeader}>
                        <Typography variant="h6">
                            <Translate id="performances_label_drivedetails_title" />
                        </Typography>
                    </div>
                    <Typography variant="h6">
                        <Translate id="performances_label_drivedetails_sub_title" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />

                    {data.map(item => {
                        return (
                            <div key={item.taskId} style={{ marginBottom: '36px' }}>
                                <Typography variant="h6"> {item.route}</Typography>
                                <Typography variant="body1">{`${item.plannedStartTime.substring(
                                    0,
                                    5
                                )} - ${item.plannedEndTime.substring(0, 5)}`}</Typography>
                                {item.driveModeLogs && item.driveModeLogs.length === 0 && (
                                    <PaperBox
                                        redBox={true}
                                        value={translate('performances_label_drivedetails_drivemode_not_opened')}
                                    />
                                )}
                                {item.driveModeLogs && item.driveModeLogs.length > 0 && mapLogs(item.driveModeLogs)}
                            </div>
                        )
                    })}
                </div>
            )}

            {downloadError && downloadError.length !== 0 && <ErrorMessage error={downloadError} spacing={false} />}
            <IconButton
                className={classes.buttonBottom}
                icon="download"
                color="primary"
                title={downloading ? 'downloading...' : translate('button_export')}
                disabled={
                    !(data && data.length > 0) ||
                    checkFunctionalityDisabled(
                        store,
                        'exportDriveDetails',
                        'performances.functionality.management.performanceDetail'
                    ) ||
                    downloading
                }
                onClick={() => {
                    openStream()
                }}
            />
        </Drawer>
    )
}

PerformanceDriveDetailsDrawerView.propTypes = {
    classes: object.isRequired,
    close: func.isRequired,
    translate: func.isRequired,
    isOpen: bool.isRequired,
    store: any,
    data: array,
    downloading: bool.isRequired,
    downloadError: string.isRequired,
    openStream: func.isRequired,
}

export default compose(withStyles(styles), withLocalize, pure)(PerformanceDriveDetailsDrawerView)
