import React from 'react'
import { object, bool, func, string } from 'prop-types'
import { Translate } from 'react-localize-redux'

import Header from 'components/Header'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import PersonSearcher from 'components/PersonSearcher'

const SuperUserView = ({ classes, selectedName, selectedProfile, setName, clearName, isSuperUser, handleCheckbox }) => {
    return (
        <React.Fragment>
            <Translate>{({ translate }) => <Header isMenu title={translate('icon_button_add_superuser')} />}</Translate>
            <div className={classes.container}>
                <div style={{ width: '50%' }}>
                    <Typography variant="h6">
                        <Translate id="districts_read_label_person" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <div style={{ position: 'relative' }} name="PersonSearcherWrapper" tabIndex={0}>
                        <PersonSearcher {...{ selectedName, setName, clearName }} />
                    </div>
                    {selectedName && selectedProfile && (
                        <div className={classes.profileInfo}>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_name" />:
                                </span>
                                {` ${selectedName}`}
                            </Typography>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_idf" />:
                                </span>
                                {` ${selectedProfile.idfNumber}`}
                            </Typography>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_user_name" />:
                                </span>
                                {` ${selectedProfile.userName}`}
                            </Typography>
                            <Typography>
                                <span className={classes.profileInfoTitle}>
                                    <Translate id="search_label_org" />:
                                </span>
                                {(selectedProfile.organisations || []).map(org => org.organisationCode).join(', ')}
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isSuperUser}
                                        onChange={() => handleCheckbox('isSuperUser')}
                                        value="isSuperUser"
                                        color="primary"
                                    />
                                }
                                label="Is Super User"
                            />
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

SuperUserView.propTypes = {
    classes: object.isRequired,
    selectedName: string.isRequired,
    selectedProfile: object,
    setName: func.isRequired,
    clearName: func.isRequired,
    isSuperUser: bool,
    handleCheckbox: func,
}

export default SuperUserView
