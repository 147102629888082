import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import { object, array, func, bool } from 'prop-types'
import { withStore } from 'react-ion-store'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

const UserListForGroup = props => {
    const { userData, classes, translate, handleDeleteUser, addNewUserCallLoading } = props

    return (
        <div>
            <Table>
                {userData.length > 0 ? (
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '50%', cursor: 'pointer' }} onClick={() => this.handleSort()}>
                                {translate('add_user_roles_name')}
                            </TableCell>
                            <TableCell style={{ width: '50%' }} />
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {userData &&
                        userData.map(item => (
                            <React.Fragment key={item.id}>
                                <TableRow style={{ height: '56px' }} key={item.id}>
                                    <TableCell>
                                        <Typography variant="body1" className={classes.underline}>
                                            {`${item.firstName} ${item.lastName}`}
                                        </Typography>
                                        <Typography variant="body2">{item.idfNumber}</Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                        <Delete
                                            disabled={addNewUserCallLoading}
                                            className={classes.actionIcon}
                                            style={{
                                                cursor:
                                                    checkFunctionalityDisabled(
                                                        props.store,
                                                        'edit',
                                                        'users.functionality.management.users'
                                                    ) || addNewUserCallLoading
                                                        ? 'none'
                                                        : 'pointer',
                                                color:
                                                    checkFunctionalityDisabled(
                                                        props.store,
                                                        'edit',
                                                        'users.functionality.management.users'
                                                    ) || addNewUserCallLoading
                                                        ? 'grey'
                                                        : '',
                                            }}
                                            onClick={() => {
                                                handleDeleteUser(item.id)
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                </TableBody>
            </Table>
        </div>
    )
}
UserListForGroup.propTypes = {
    classes: object,
    store: object,
    userData: array,
    translate: func,
    handleDeleteUser: func,
    addNewUserCallLoading: bool,
}
export default withStore(UserListForGroup)
