import React, { useContext } from 'react'

import { func, object, string, number, array } from 'prop-types'
import classNames from 'classnames'
import { Translate } from 'react-localize-redux'

import { withStyles, Checkbox } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { Delete, Edit } from '@material-ui/icons'

import Loading from 'components/Loading'
import PtcarPlatformTable from '../PtcarPlatformTable'
import PtcarPlatformDrawer from '../PtcarPlatformDrawer'
import DeleteDialog from '../DeletePtcarPlatformDialog'

import styles from './styles'

import { PtcarPlatformsContext } from '../../ptcarPlatforms.store'
import { TranslationContext } from 'context/translation'
import PtcarPlatformHeader from '../PtcarPlatformHeader'

import getTranslationFromLocale from 'utils/returnTranslationForLocale'

function PtcarPlatformList(props) {
    const { STATE, METHODS } = useContext(PtcarPlatformsContext)
    const { translate, activeLanguage } = useContext(TranslationContext)
    const { classes } = props

    document.title = translate('ptcar_platform_overview')

    const getRowClassName = ({ index }) => {
        const { rowClassName, onRowClick } = props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }
    const headerRenderer = headerProps => {
        const { headerHeight } = props
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: headerHeight,
                    paddingTop: 34,
                    paddingBottom: 34,
                    paddingRight: 0,
                }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }
    const cellRenderer = cellProps => {
        const { dataKey, rowData } = cellProps

        if (dataKey === 'platformName') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: props.rowHeight }}>
                    <Typography>
                        {getTranslationFromLocale(rowData.text, activeLanguage.code === 'fr' ? 'fr-BE' : 'nl-BE')}
                    </Typography>
                </TableCell>
            )
        }
        if (dataKey === 'intPhoneNumber') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: props.rowHeight }}>
                    <Typography >
                    {/* eslint-disable-next-line max-len */}
                    <p style={{height: '25px'}} className='MuiTypography-root MuiTypography-body1'>{rowData.intPhoneNumber}</p>
                    </Typography>
                </TableCell>
            )
        }
        if (dataKey === 'managedByLio') {
            return (
                // eslint-disable-next-line max-len
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: props.rowHeight, paddingBottom: '0px', paddingTop: '0px', margin: 0}}>
                        <Checkbox style={{ color: 'grey', height: '40px'}} disabled checked={rowData.managedByLio} />
                </TableCell>
            )
        }

        if (dataKey === 'actions') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: props.rowHeight }}>
                    <div className={classes.multipleIcons} style={{justifyContent: 'end' }}>
                        <Edit
                            className={classes.editIcon}
                            onClick={e => {
                                METHODS.onRowEditClick(e, rowData, true)
                            }}
                        />

                        <Delete
                            className={classes.deleteIcon}
                            onClick={e => {
                                METHODS.onRowDeleteClick(e, rowData)
                            }}
                        />
                    </div>
                </TableCell>
            )
        }
    }

    return (
        <div>
            <PtcarPlatformHeader classes={classes} />
            {STATE.loadingData && <Loading />}
            {!STATE.loadingData && (
                <PtcarPlatformTable
                    classes={classes}
                    headerRenderer={headerRenderer}
                    cellRenderer={cellRenderer}
                    getRowClassName={getRowClassName}
                />
            )}
            {STATE.openDrawer && <PtcarPlatformDrawer />}
            {STATE.openDeleteDialog && <DeleteDialog />}
        </div>
    )
}
PtcarPlatformList.propTypes = {
    classes: object.isRequired,
    rowClassName: string,
    onRowClick: func,
    headerHeight: number,
    rowHeight: number,
    activeLanguage: object,
    match: object,
    data: array,
    filteredData: array,
}
export default withStyles(styles)(PtcarPlatformList)
