import React, { useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { ThemeContext } from 'theme/ThemeProvider'
import { Typography } from '@material-ui/core'
import { bool, string } from 'prop-types'

function ThemedTyphography(props) {
    let { isRedBG, data } = props
    let { themeName } = useContext(ThemeContext)
    let isDark = themeName === 'dark'
    const PrintData = withStyles({
        root: {
            color: isRedBG || isDark ? 'white' : 'black',
        },
    })(Typography)
    return <PrintData data-testid="printData">{data}</PrintData>
}
ThemedTyphography.propTypes = {
    isRedBG: bool,
    data: string,
}

export default ThemedTyphography
