import React from 'react'
import { object, string, bool, func, number } from 'prop-types'
import axios from 'axios'
import { compose, mapProps } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withStore, withTake } from 'react-ion-store'
import Typography from '@material-ui/core/Typography'
import { Translate } from 'react-localize-redux'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import { AuthorizationServiceConfiguration } from '@openid/appauth'
// Components
import HeaderView from './HeaderView'

import { clear, valid, get } from 'utils/tokenUtils'
import getStoredRefreshRate from 'utils/getStoredRefreshRate'

class Header extends React.PureComponent {
    static propTypes = {
        history: object.isRequired,
        title: string.isRequired,
        toggleMenu: func.isRequired,
        updateTime: func.isRequired,
        logout: func.isRequired,
        currentTime: number.isRequired,
        isMenuOpen: bool.isRequired, // required because from store
        isMenu: bool,
        isNothing: bool,
        returnPath: string,
        isDriver: bool,
        onAlertSwitched: func,
        pageTitle: string,
        resetIntervalTaskDetail: func,
        refreshNotRequired: bool,
        showTime: bool,
    }
    static defaultProps = {
        refreshNotRequired: true,
        showTime: true,
    }
    timer
    resetSeconds

    setNewTime = () => {
        const currentTime = parseInt(Date.now() / 1000, 10)
        this.checkJwtExpiry()
        this.props.updateTime(currentTime)
    }

    checkJwtExpiry = () => {
        const [idToken, accessToken] = get()
        const validToken = valid(idToken, accessToken)
        if(!validToken){
            this.props.logout()
        }
    }

    componentDidMount = () => {
        AuthorizationServiceConfiguration.fetchFromIssuer(process.env.REACT_APP_OIDC_PATH).then(response => {
            this.configuration = response
        })
        if (!this.props.refreshNotRequired) {
            const currentTime = parseInt(Date.now() / 1000, 10)
            const secondReset = (60 - (currentTime % 60)) * 1000
            let refreshValue = this.getRefreshRate()
            this.resetSeconds = setTimeout(() => {
                // this.setNewTime()
                if (!this.timer) this.timer = setInterval(this.setNewTime, refreshValue * 1000)
            }, secondReset)
        }
    }

    resetInterval = () => {
        clearInterval(this.timer)
        this.timer = null
        let refreshValue = this.getRefreshRate()
        this.timer = setInterval(this.setNewTime, refreshValue * 1000)
    }

    getRefreshRate = () => {
        const { pageTitle } = this.props
        return getStoredRefreshRate(pageTitle)
    }

    closeCurrentTab = () => {
        window.close()
    }

    goBackTo = (close = false) => {
        const { history, returnPath } = this.props

        if (close === true) {
            this.closeCurrentTab()
            return
        }

        if (returnPath && returnPath.indexOf('/add') !== -1) {
            history.goBack()
            return
        }
        if (returnPath) {
            history.replace(returnPath)
        } else {
            history.goBack()
        }
    }

    componentWillUnmount = () => {
        if (this.resetSeconds) clearTimeout(this.resetSeconds)
        if (this.timer) clearInterval(this.timer)
        this.resetSeconds = null
        this.timer = null
    }

    logout = async store => {
        var instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*',
            },
        })
        delete instance.defaults.headers.common['Authorization']
        delete instance.defaults.headers.common['Ocp-Apim-Subscription-Key']
        localStorage.setItem('loggedOut', '1')

        try {
            await instance.get(
                `${this.configuration.endSessionEndpoint}?id_token_hint=${store.get('auth')}&post_logout_redirect_uri=${
                    process.env.REACT_APP_REDIRECT_URI
                }`,
                {}
            )
        } catch (e) {
            console.error(e) // eslint-disable-line
        }

        clear()
        store.set({ auth: undefined })
    }

    checkForEdriveApp = () => {
        const UA = navigator.userAgent

        return UA.includes('eDriveApp')
    }

    render() {
        const { title, isMenu, isMenuOpen, isDriver, history, onAlertSwitched, pageTitle, isNothing } = this.props
        const edriveApp = this.checkForEdriveApp()
        const isAgenda = history.location.pathname.indexOf('/agenda') === -1 ? false : true

        if (edriveApp) {
            if (isAgenda) {
                return null
            }
            return (
                <Typography data-testid="edrive_app" variant="body1" style={{ padding: '12px' }}>
                    <IconButton component="span" onClick={() => history.push('/agenda')}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Translate id="module_calendar" />
                </Typography>
            )
        }

        return (
            <HeaderView
                title={title}
                isMenu={isMenu}
                isNothing={isNothing}
                isMenuOpen={isMenuOpen}
                action={isMenu ? this.props.toggleMenu : this.goBackTo}
                logout={this.logout}
                isDriver={isDriver}
                onAlertSwitched={onAlertSwitched}
                pageTitle={pageTitle}
                resetInterval={this.resetInterval}
                resetIntervalTaskDetail={this.props.resetIntervalTaskDetail}
                currentTime={this.props.currentTime}
                showTime={this.props.showTime}
            />
        )
    }
}

export default compose(
    withRouter,
    withStore,
    mapProps(props => ({
        ...props,
        toggleMenu: () => props.store.set({ isMenuOpen: !props.store.get('isMenuOpen') }),
        updateTime: currentTime => props.store.set({ currentTime }),
        logout: () => {
            clear()
            props.store.set({ auth: undefined })
        },
    })),
    withTake(['isMenuOpen', 'currentTime'])
)(Header)
