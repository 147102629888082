import React, { useState, useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import axios from 'axios'
import Loading from 'components/Loading'
import { object } from 'prop-types'
import { withStore } from 'react-ion-store'
import { Translate } from 'react-localize-redux'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import { apiReportingDateTimeParser } from 'utils/dateParser'
import { isIdfSameAsLoggedInUser } from 'utils/isAgendaAllowed'
import NoRights from '../../../NoRights'
import Page1 from './ReportPages/Page1'
import Page2 from './ReportPages/Page2'
import Page3 from './ReportPages/Page3'
import { handleImageClick } from './Utils'

const PerfomanceReport = props => {
    useEffect(() => {
        let _isMounted = true
        window.addEventListener('resize', () => handleResize())
        const performanceNumber = props.match.params.performanceNumber
        const depot = props.match.params.depot
        const performanceDate = props.match.params.date
        const date = apiReportingDateTimeParser(parseInt(performanceDate, 10))
        getReportDetails(date, idfNumber, depot, performanceNumber, _isMounted)
        document.title = `${performanceNumber}-${depot}`
        return () => (_isMounted = false)
    }, [])

    const [reportDetail, setReportDetail] = useState({})
    const [loading, setLoading] = useState(true)
    const [showError, setShowError] = useState(false)
    const [imageLink, setImageLink] = useState('')
    const [showImage, setShowImage] = useState(false)
    const [isTablet, setIsTablet] = useState(window.matchMedia('(max-width:1025px)').matches)

    const handleResize = () => {
        setIsTablet(window.matchMedia('(max-width:1025px)').matches)
    }
    const checkForEdriveApp = () => {
        const UA = navigator.userAgent

        return UA.includes('eDriveApp')
    }

    const handleImageClickForApp = link => {
        setImageLink(link)
        setShowImage(true)
    }
    /**
     * This method is used to get report details from server
     * @param {string} date
     * Date for report
     * @param {string} idf
     * @param {string} depot
     * @param {string} performanceNumber
     * PerformanceNumber for the report
     */
    const getReportDetails = async (date, idf, depot, performanceNumber, mounted) => {
        //eslint-disable-next-line max-len
        const fullUrl = `/gprdetail?date=${date}&idf=${idf}&depot=${depot}&performanceNumber=${performanceNumber}&setGprSent=false`
        try {
            const response = await axios.get(fullUrl)
            if (response.status === 200) {
                if (mounted) {
                    setReportDetail(response.data)
                    setLoading(false)
                    setShowError(false)
                }
            } else {
                if (mounted) {
                    setReportDetail({})
                    setLoading(false)
                    setShowError(true)
                }
            }
        } catch (e) {
            if (mounted) {
                setReportDetail({})
                setLoading(false)
                setShowError(true)
            }
            console.error('error', e) // eslint-disable-line
        }
    }

    const hideImage = () => {
        setImageLink('')
        setShowImage(false)
    }
    const { store, history } = props
    const edriveApp = checkForEdriveApp()
    let idfNumber = props.match.params.idfNumber
    let showReport = isIdfSameAsLoggedInUser(idfNumber, store)
    let imageClick = edriveApp ? handleImageClickForApp : handleImageClick

    if (showReport || !checkFunctionalityDisabled(store, 'performanceReport', 'agenda.functionality.management.agenda')) {
        if (loading) return <Loading />
        if (showError) return <Typography variant="h4">No Data</Typography>
        return (
            <div data-testid="parentDiv">
                {edriveApp && !showImage && (
                    <Typography variant="body1" style={{ padding: '12px' }}>
                        <IconButton component="span" onClick={() => history.push('/agenda')}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Translate id="module_calendar" />
                    </Typography>
                )}
                {!showImage && (
                    <>
                        <Page1 data={reportDetail} handleImageClick={imageClick} isTablet={isTablet} />
                        <Page2 data={reportDetail} handleImageClick={imageClick} isTablet={isTablet} />
                        <Page3 data={reportDetail} handleImageClick={imageClick} />
                    </>
                )}

                {edriveApp && showImage && (
                    <>
                        <Typography variant="body1" style={{ padding: '12px' }}>
                            <IconButton component="span" onClick={hideImage}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Translate id="module_calendar" />
                        </Typography>
                        <img src={imageLink} alt="Image" height="70%" width="70%" />
                    </>
                )}
            </div>
        )
    }

    return <NoRights />
}
PerfomanceReport.propTypes = {
    match: object.isRequired,
    history: object,
    store: object,
}
export default withStore(PerfomanceReport)
