import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import VehicleSearch from '../VehicleSearch'
import { func, object, array, bool, string } from 'prop-types'
import styles from './styles'
import { withStyles } from '@material-ui/styles'
import { Button, DialogActions } from '@material-ui/core'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { compose } from 'recompose'

const VehicleSearchDialog = props => {
    return (
        <div>
            <Dialog
                classes={{ paper: props.classes.root }}
                onClose={props.onClose}
                PaperProps={{ square: true }}
                open={props.open}
            >
                <DialogTitle
                    classes={{ root: props.classes.title }}
                    id="responsive-dialog-title"
                    disableTypography={isWidthDown('sm', props.width) ? true : false}
                >
                    {'Select Vehicle'}
                </DialogTitle>
                <DialogContent classes={{ root: props.classes.content }}>
                    <VehicleSearch
                        selectedVehicle={props.selectedVehicle}
                        options={props.litteraSearchOptions}
                        handleSearchValue={props.handleAddLittera}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={props.handleEditLitteraClicked} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
VehicleSearchDialog.propTypes = {
    classes: object,
    open: bool,
    selectedVehicle: object,
    litteraSearchOptions: array,
    handleAddLittera: func,
    onClose: func,
    handleEditLitteraClicked: func,
    width: string,
}
export default compose(withWidth(), withStyles(styles))(VehicleSearchDialog)
