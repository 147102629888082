/* eslint-disable react/prop-types */
import React from 'react'
import { compose } from 'recompose'
import { withStore, withCall } from 'react-ion-store'

const callKey = 'signInOutCall'

const SignInOutCall = () => null

export default compose(withStore, Component => props => {
    const callData = props.store.get(callKey) || {}
    const { idf, id, type, isPlanned } = callData
    return idf && id && type ? (
        withCall(callKey, {
            config: {
                method: 'post',
                url: `/performance/${id}/${type === 'signIn' ? 'start' : 'finish'}/${idf}?asPlanned=${isPlanned}`,
            },
        })(Component)(props)
    ) : (
        <Component {...props} />
    )
})(SignInOutCall)
