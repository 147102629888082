import axios from 'axios'

export default async () => {
    const fullUrl = `/compositionReferenceData/pneumaticSuspensionRestrictionPercentages`

    try {
        const response = await axios.get(fullUrl)
        if (response.status === 204) {
            return []
        }
        return response.data
    } catch (e) {
        console.error(e) // eslint-disable-line
        return []
    }
}
