import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default (userGroupId, idfNumber, force) => {
    const { url, subscriptionKey } = requestData

    let fullUrl;
    if(force) {
        fullUrl = `${url}/referencedata/usergroup/${userGroupId}/adduser/${idfNumber}?enforce=true
        `
    } else {
        fullUrl = `${url}/referencedata/usergroup/${userGroupId}/adduser/${idfNumber}
        `
    }


    return axios.post(fullUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    })
}
