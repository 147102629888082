export default theme => ({
    materialUnitChild: {
        position: 'relative',
        textAlign: 'center',
        background: theme.customColors.toast,
        borderRadius: 8,
        margin: '4px',
    },
    materialUnitChildYellow: {
        position: 'relative',
        textAlign: 'center',
        background: theme.customColors.toast,
        borderRadius: 10,
        borderLeft: '6px solid yellow',
        margin: '4px',
    },

    materialUnitNumberField: {
        width: '40%',
        margin: 'auto',
        background: theme.customColors.white,
        color: theme.customColors.muicFont,
    },
    editIcon: {
        color: theme.customColors.primaryBlue,
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
        cursor: 'pointer',
    },
    disabled: {
        color: theme.customColors.lightGrey,
    },
    infoIcon: {
        cursor: 'pointer',
        position: 'absolute',
        margin: 0,
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
})
