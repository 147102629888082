import { grey, blue } from '@material-ui/core/colors'

export default theme => ({
    documentTableWrapper: {
        padding: `0 ${theme.spacing(3)}px`,
        width: '100%',
        minHeight: theme.driveDetailList,
        [theme.breakpoints.down('xs')]: {
            minHeight: theme.moduleMenu,
        },
        height: `calc(100vh - 265px)`,
        '@media (max-width: 577px)': {
            height: `calc(100vh - 362px)`,
            padding: `0 ${theme.spacing(1)}px`,
        },
    },
    groupedDocumentTableWrapper: {
        padding: `0 ${theme.spacing(3)}px`,
        width: '100%',
        minHeight: theme.taskList,
        height: `calc(100vh - 280px)`,
        marginBottom: '8px',
    },
    tableRow: {
        backgroundColor: 'transparent',
    },
    tableRowRed: {
        backgroundColor: theme.customColors.darkRed,
    },
    tableRowYellow: {
        backgroundColor: theme.customColors.yellow,
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            padding: '0px',
        },
        width: '100%',
        borderColor: theme.customColors.grey,
    },
    tableCellYellow: {
        height: '70%',
        backgroundColor: theme.customColors.versionYellow,
        color: theme.customColors.black,
        textAlign: 'center',
    },
    tableCellRed: {
        height: '70%',
        backgroundColor: theme.customColors.red,
        color: theme.customColors.black,
        textAlign: 'center',
    },
    tableCellGreen: {
        height: '70%',
        backgroundColor: theme.customColors.green,
        color: theme.customColors.black,
        textAlign: 'center',
    },
    header: {
        backgroundColor: theme.customColors.lightGrey,
        '@media (max-width: 577px)': {
            padding: '3px',
        },
    },
    editIcon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        fill: theme.customColors.white,
    },
    deleteIcon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        color: theme.customColors.red,
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
    parentDiv: {
        justifyContent: 'space-between',
        padding: `${theme.spacing(3)}px`,
    },
    padTop: {
        paddingTop: `${theme.spacing(2)}px`,
        [theme.breakpoints.down('xs')]: {
            paddingTop: `${theme.spacing(1)}px`,
        },
    },
    groupPad: {
        paddingTop: `${theme.spacing(1)}px`,
        marginBottom: `${theme.spacing(1)}px`,
    },
    customInput: {
        backgroundColor: grey['600'],
        height: '35px',
        minWidth: '200px',
        border: `2px solid ${grey['700']}`,
        fontSize: '14px',
        color: theme.customColors.white,
        fontFamily: 'roboto',
        paddingLeft: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100px',
            minWidth: '100px',
            paddingLeft: '4px',
        },
        '&::placeholder': {
            color: `${grey['400']}`,
        },
        '&:focus': {
            outline: 'none',
            borderBottom: `2px solid ${blue['500']}`,
        },
    },
    multipleIcons: {
        display: 'flex',
        alignContent: 'space-between',
    },
    buttonContainer: {
        textAlign: 'right',
        padding: `${theme.spacing(3)}px`,
    },
    drawerRoot: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
    },
    labelContainer: {
        marginLeft: '6px',
    },
    roundedRed: {
        width: '30px',
        height: '30px',
        paddingTop: '6px',
        paddingBottom: '6px',
        borderRadius: '50%',
        backgroundColor: theme.customColors.red,
        display: 'inline-block',
        textAlign: 'center',
        fontSize: '14px',
    },
    roundedYellow: {
        width: '30px',
        height: '30px',
        paddingTop: '6px',
        paddingBottom: '6px',
        borderRadius: '50%',
        backgroundColor: 'yellow',
        color: 'black',
        display: 'inline-block',
        textAlign: 'center',
        fontSize: '14px',
    },
    button: {
        marginLeft: '8px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
        },
    },
    warningIcon: {
        width: '1.2em',
        height: '1.2em',
        color: 'yellow',
        '@media (max-width: 577px)': {
            width: '0.7em',
            height: '0.7em',
        },
    },
    greenIcon: {
        width: '1.2em',
        height: '1.2em',
        color: 'green',
        '@media (max-width: 577px)': {
            width: '0.7em',
            height: '0.7em',
        },
    },
    whiteIcon: {
        color: theme.customColors.white,
        width: '1.2em',
        height: '1.2em',
        '@media (max-width: 577px)': {
            width: '0.7em',
            height: '0.7em',
        },
    },
    whiteIconForMobile: {
        color: theme.customColors.whiteColor,
        width: '1.2em',
        height: '1.2em',
        '@media (max-width: 577px)': {
            width: '0.7em',
            height: '0.7em',
        },
    },
    greyIcon: {
        color: theme.customColors.grey,
        width: '1.2em',
        height: '1.2em',
        '@media (max-width: 577px)': {
            width: '0.7em',
            height: '0.7em',
        },
    },

    blockText: {
        display: 'block',
    },
    ellipsis: { display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', whiteSpace: 'nowrap' },
    resetButton: {
        marginRight: '12px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '4px',
        },
    },
    loadButton: {
        marginLeft: '12px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '4px',
            marginTop: '8px',
        },
    },
    whiteTypography: {
        color: theme.customColors.whiteColor,
    },
    blackTypography: {
        color: theme.customColors.blackColor,
    },
    mobileView: {
        fontSize: '0.7rem',
    },
})
