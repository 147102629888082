import React from 'react'
import { func, bool } from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import AppDrawer from 'components/AppDrawer'
import FindUserGroupDrawerView from './FindUserGroupDrawerView'

const FindUserDrawer = props => {
    FindUserDrawer.propTypes = {
        onClose: func.isRequired,
        isOpen: bool,
        translate: func,
    }

    return (
        <AppDrawer isOpen={props.isOpen} onClose={props.onClose} title={props.translate('icon_button_find_userGroup')}>
            <FindUserGroupDrawerView translate={props.translate} />
        </AppDrawer>
    )
}

export default withLocalize(FindUserDrawer)
