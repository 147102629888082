import React from 'react'
import { object, func, node } from 'prop-types'
import { compose } from 'recompose'
import { withStore, withCall, enums } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'

import Loading from 'components/Loading'

import mapKeysToLowerCase from 'utils/mapKeysToLowerCase'
import mapApiTranslations from 'translations/mapApiTranslations'

const { CallStatus } = enums

const Failed = () => <div>Error retrieving Document folders!</div>
const ProgressHandlers = {
    [CallStatus.LOADING]: Loading,
    [CallStatus.FAILED]: Failed,
}

const callKey = 'documentFolders'

class DistrictsMapper extends React.Component {
    static propTypes = {
        children: node.isRequired,
        store: object.isRequired,
        addTranslation: func.isRequired,
    }

    render() {
        return this.props.children
    }
}

export default compose(withLocalize, withStore, Component => props =>
    withCall(callKey, {
        config: {
            method: 'get',
            url: '/folders',
        },
        progressHandlers: ProgressHandlers,
        reducer: ({ data }) => {
            const { documentFolders, communicationFolders } = mapKeysToLowerCase(data)
            let translations = {}

            const document = documentFolders.map(folder => {
                const { id, name } = mapKeysToLowerCase(folder)

                translations = {
                    ...translations,
                    ...mapApiTranslations({ id: 'document_folder_' + id, translations: name }),
                }

                return { id: 'document_folder_' + id, folderId: id }
            })

            const communication = communicationFolders.map(folder => {
                const { id, name } = mapKeysToLowerCase(folder)

                translations = {
                    ...translations,
                    ...mapApiTranslations({ id: 'communication_folder_' + id, translations: name }),
                }

                return { id: 'communication_folder_' + id, folderId: id }
            })

            props.addTranslation(translations)
            return { document, communication }
        },
    })(Component)(props)
)(DistrictsMapper)
