import React, { Component } from 'react'
import { Paper } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import some from 'lodash/some'
import { Translate, withLocalize } from 'react-localize-redux'
import { object, func, string } from 'prop-types'

import SelectInput from 'components/SelectInput'

const storeKey = 'mappedDistrictData'
const performanceTypes = [
    { id: ' ', text: 'performance_type' },
    { id: 'Driving', text: 'performance_type_driving' },
    { id: 'Non_Driving', text: 'performance_type_non_driving' },
]
export class AddPerformance extends Component {
    static propTypes = {
        persist: func.isRequired,
        store: object.isRequired,
        referenceOrg: string.isRequired,
        referenceDepot: string.isRequired,
        classes: object.isRequired,
        performanceType: string,
    }

    handleEvent = (type, depots) => e => {
        this.props.persist(type)(e.target.value)
        if (type === 'referenceDepot') {
            var d = depots
            var selectedDepots = d.filter(depot => depot.id === e.target.value)
            const selectedDepot = selectedDepots[0]['text']
            this.props.persist('selectedDepot')(selectedDepot)
        }
    }

    render() {
        const { store, classes, referenceDepot, referenceOrg, performanceType } = this.props

        const districts = store.get(storeKey) || []

        const tcts = districts.reduce(
            (aggregrate, district) => [...aggregrate, ...district.tcts.map(tct => ({ id: `${tct.tctId}`, text: tct.id }))],
            [{ id: ' ', text: 'documents_label_select_org' }]
        )

        let chosenDistrict = districts.find(district => some(district.tcts, { tctId: parseInt(referenceOrg, 10) })) || {
            tcts: [],
        }

        const chosenTct = chosenDistrict.tcts.find(tct => tct.tctId === parseInt(referenceOrg, 10)) || {
            depots: [],
        }

        const depots = chosenTct.depots.reduce(
            (aggregate, depot) => [...aggregate, { id: `${depot.depotId}`, text: depot.id }],
            [{ id: ' ', text: 'documents_label_select_depot' }]
        )

        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Paper>
                        <Translate>
                            {({ translate }) => (
                                <div style={{ display: 'block', padding: '16px' }}>
                                    <SelectInput
                                        sorted
                                        name="referenceOrg"
                                        classes={classes}
                                        items={tcts.map(tct => ({ ...tct, text: translate(tct.text) }))}
                                        value={referenceOrg}
                                        onChange={this.handleEvent('referenceOrg')}
                                    />
                                </div>
                            )}
                        </Translate>
                    </Paper>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '16px' }}>
                    <Paper>
                        <Translate>
                            {({ translate }) => (
                                <div style={{ display: 'block', padding: '16px' }}>
                                    <SelectInput
                                        sorted
                                        name="referenceDepot"
                                        classes={classes}
                                        items={depots.map(depot => ({ ...depot, text: translate(depot.text) }))}
                                        value={referenceOrg === ' ' ? ' ' : referenceDepot}
                                        onChange={this.handleEvent('referenceDepot', depots)}
                                    />
                                </div>
                            )}
                        </Translate>
                    </Paper>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '16px' }}>
                    <Paper>
                        <Translate>
                            {({ translate }) => (
                                <div style={{ display: 'block', padding: '16px' }}>
                                    <SelectInput
                                        sorted
                                        name="performanceType"
                                        classes={classes}
                                        items={performanceTypes.map(perf => ({
                                            ...perf,
                                            text: translate(perf.text),
                                        }))}
                                        value={performanceType}
                                        onChange={this.handleEvent('performanceType')}
                                    />
                                </div>
                            )}
                        </Translate>
                    </Paper>
                </Grid>
            </React.Fragment>
        )
    }
}

export default compose(withStore, withLocalize)(AddPerformance)
