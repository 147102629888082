import React from 'react'
import { object, func } from 'prop-types'
import { Translate } from 'react-localize-redux'

import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import PrimaryButton from 'components/PrimaryButton'
import LanguagePicker from 'components/LanguagePicker'
import PortalLogo from 'components/PortalLogo'

const LoginView = ({ classes, handleLogin, loginProps }) => (
    <React.Fragment>
        <AppBar color="default" elevation={1}>
            <Toolbar className={classes.flex}>
                <PortalLogo className={classes.logo} />
                <Typography />
                <Typography variant="h6" className={classes.title}>
                    Login
                </Typography>
                <Typography />
            </Toolbar>
        </AppBar>
        <div className={classes.loginBody}>
            <Paper className={classes.loginDialog}>
                <Typography variant="h6" component="h2" className={classes.loginTitle}>
                    <Translate id="login_welcome" />
                </Typography>
                <Typography className={classes.loginText}>
                    <Translate id="login_button_info" />
                </Typography>
                <PrimaryButton
                    title="LOGIN"
                    className={classes.loginButton}
                    onClick={handleLogin}
                    disabled={!loginProps.loginPossible}
                />
                {loginProps.error && (
                    <Typography className={classes.errorText}>
                        <Translate id="page_error_label" />
                    </Typography>
                )}
            </Paper>
            <div className={classes.languagePicker}>
                <LanguagePicker alwaysWhite />
            </div>
        </div>
    </React.Fragment>
)

LoginView.propTypes = {
    classes: object.isRequired,
    handleLogin: func.isRequired,
    loginProps: object.isRequired,
}

export default LoginView
