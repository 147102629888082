import React, { useState } from 'react'

import { Fab } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import Block from '@material-ui/icons/Block'
import Restore from '@material-ui/icons/Restore'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DirectionsRailwayIcon from '@material-ui/icons/DirectionsRailway'
import { withStyles } from '@material-ui/styles'
import { object, bool, string, func } from 'prop-types'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import PersonIcon from '@material-ui/icons/Person'

/* eslint-disable */
const SummarizeIcon = createSvgIcon(
    <path d="M15,3H5C3.9,3,3.01,3.9,3.01,5L3,19c0,1.1,0.89,2,1.99,2H19c1.1,0,2-0.9,2-2V9L15,3z M5,19V5h9v5h5v9H5z M9,8 c0,0.55-0.45,1-1,1S7,8.55,7,8s0.45-1,1-1S9,7.45,9,8z M9,12c0,0.55-0.45,1-1,1s-1-0.45-1-1s0.45-1,1-1S9,11.45,9,12z M9,16 c0,0.55-0.45,1-1,1s-1-0.45-1-1s0.45-1,1-1S9,15.45,9,16z" />,
    'SummarizeIcon'
)

import styles from './styles'

const FabButton = props => {
    const {
        abolishDisable,
        isAbolished,
        signOutTime,
        code,
        handleOpen,
        handleAbolishOpen,
        handleEdit,
        openTC286,
        disabledTC286,
        openComposition,
        disabledCompos,
        storage,
        handleLinda,
        isLindaAllowed,
    } = props
    const [isOpen, setIsOpen] = useState(false)
    let showTC286AndCompo = code === 'DRIVE' || code === 'UITGAR' || code === 'PERQUAI'
    return (
        <div className={props.classes.rootDiv}>
            {signOutTime === null && code !== 'START' && code !== 'END' && (
                <Fab aria-label={'actions'} className={props.classes.fab} color={'primary'}>
                    <MoreVertIcon
                        onClick={() => {
                            setIsOpen(!isOpen)
                        }}
                    />
                </Fab>
            )}

            {isOpen && (
                <div
                    className={props.classes.backdrop}
                    onClick={() => {
                        setIsOpen(!isOpen)
                    }}
                >
                    <div className={props.classes.fabIcons}>
                        <Fab
                            disabled={checkFunctionalityDisabled(
                                storage,
                                'add',
                                'performances.functionality.management.tasks'
                            )}
                            aria-label={'actions'}
                            color={'primary'}
                        >
                            <EditIcon
                                onClick={e => {
                                    e.stopPropagation()
                                    handleEdit()
                                }}
                            />
                        </Fab>
                        {isAbolished ? (
                            <Fab
                                disabled={checkFunctionalityDisabled(
                                    storage,
                                    'reactivate',
                                    'performances.functionality.management.tasks'
                                )}
                                aria-label={'actions'}
                                color={'primary'}
                            >
                                <Restore
                                    onClick={e => {
                                        e.stopPropagation()
                                        handleAbolishOpen()
                                    }}
                                />
                            </Fab>
                        ) : (
                            <Fab
                                disabled={checkFunctionalityDisabled(
                                    storage,
                                    'delete',
                                    'performances.functionality.management.tasks' || abolishDisable
                                )}
                                aria-label={'actions'}
                                color={'primary'}
                            >
                                <Block
                                    onClick={e => {
                                        e.stopPropagation()
                                        handleAbolishOpen()
                                    }}
                                />
                            </Fab>
                        )}
                        <Fab
                            disabled={checkFunctionalityDisabled(
                                storage,
                                'delete',
                                'performances.functionality.management.tasks'
                            )}
                            aria-label={'actions'}
                            color={'secondary'}
                        >
                            <DeleteIcon
                                onClick={e => {
                                    e.stopPropagation()
                                    handleOpen()
                                }}
                            />
                        </Fab>
                        {isLindaAllowed && (
                            <Fab aria-label={'actions'} color={'primary'}>
                                <PersonIcon
                                    onClick={e => {
                                        e.stopPropagation()
                                        handleLinda()
                                    }}
                                />
                            </Fab>
                        )}
                        {showTC286AndCompo && (
                            <>
                                <Fab disabled={disabledTC286} aria-label={'actions'} color={'primary'}>
                                    <SummarizeIcon
                                        onClick={e => {
                                            e.stopPropagation()
                                            openTC286()
                                        }}
                                    />
                                </Fab>
                                <Fab disabled={disabledCompos} aria-label={'actions'} color={'primary'}>
                                    <DirectionsRailwayIcon
                                        onClick={e => {
                                            e.stopPropagation()
                                            openComposition()
                                        }}
                                    />
                                </Fab>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
FabButton.propTypes = {
    classes: object,
    abolishDisable: bool,
    isAbolished: bool,
    signOutTime: string,
    code: string,
    handleOpen: func,
    handleAbolishOpen: func,
    handleLinda: func,
    handleEdit: func,
    openTC286: func,
    disabledTC286: bool,
    openComposition: func,
    disabledCompos: bool,
    storage: object,
    isLindaAllowed: bool,
}
export default withStyles(styles)(FabButton)
