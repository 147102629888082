import React from 'react'
import { object, string } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import styles from './styles'

const SearchError = ({ classes, translateKey }) => (
    <div data-testid="search_error" className={classes.container}>
        <Paper className={classes.noSearch}>
            <Typography>
                <Translate id={translateKey} />
            </Typography>
        </Paper>
    </div>
)

SearchError.propTypes = {
    classes: object.isRequired,
    translateKey: string.isRequired,
}

export default compose(withStyles(styles), withLocalize)(SearchError)
