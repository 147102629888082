export default theme => ({
    root: {
        padding: `${theme.spacing(3)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `4px`,
        },
    },
    logo: {
        height: '30px',
        width: '40px',
    },
    column: {
        padding: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `4px`,
        },
        // textColor: black,
        backgroundColor: theme.customColors.m510Background,
        border: '1px solid',
        borderColor: theme.customColors.grey,
        [theme.breakpoints.down('sm')]: {
            fontSize: '8px!important',
            padding: `2px`,
        },
        borderBottom: 'none',
    },
    columnNoRight: {
        padding: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `4px`,
        },
        border: '1px solid',
        borderColor: theme.customColors.grey,
        [theme.breakpoints.down('sm')]: {
            fontSize: '8px!important',
            padding: `2px`,
        },
        borderRight: 'none',
        borderBottom: 'none',
    },
    columnLeft: {
        padding: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `4px`,
        },
        borderColor: theme.customColors.grey,
        [theme.breakpoints.down('sm')]: {
            fontSize: '8px!important',
            padding: `2px`,
        },
        borderLeft: '1px solid',
    },
    columnBorderLeft: {
        padding: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `4px`,
        },
        // textColor: black,

        [theme.breakpoints.down('sm')]: {
            fontSize: '8px!important',
            padding: `2px`,
        },
        borderLeft: '1px solid',
        borderColor: theme.customColors.grey,
    },
    text: {
        fontSize: 16,
        color: 'black',
        padding: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '8px!important',
            padding: `2px`,
        },
    },
    textBold: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'black',
        [theme.breakpoints.down('sm')]: {
            fontSize: '8px!important',
            fontWeight: '500',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '8px!important',
            fontWeight: '500',
        },
    },
    noBorderOnBottom: {
        padding: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `4px`,
        },
        border: '1px solid',
        borderBottom: '0px solid',
        backgroundColor: theme.customColors.m510Background,
        borderColor: theme.customColors.grey,
    },
    noBorderOnTop: {
        padding: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `4px`,
        },
        textColor: theme.customColors.white,
        border: '1px solid',
        borderTop: '0px solid',
        backgroundColor: theme.customColors.m510Background,
        borderColor: theme.customColors.grey,
    },
    noBorderOnTopAndLeft: {
        padding: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `4px`,
        },
        textColor: theme.customColors.white,
        border: '1px solid',
        borderTop: '0px solid',
        borderLeft: '0px solid',
        borderColor: theme.customColors.grey,
    },
    noBorderOnTopAndRight: {
        padding: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `4px`,
        },
        textColor: theme.customColors.white,
        border: '1px solid',
        borderTop: '0px solid',
        borderRight: '0px solid',
        borderColor: theme.customColors.grey,
    },

    outerPadding: {
        border: '1px solid',
        borderColor: theme.customColors.grey,
    },
    columnNoBorder: {
        padding: `${theme.spacing(2)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(1)}px`,
        },
        textColor: theme.customColors.white,
    },
    thickText: {
        fontWeight: 'bold',
    },
    columnBottomBorder: {
        border: '1px solid',
        borderTop: '0px solid',
        borderLeft: '0px solid',
        borderRight: '0px solid',
        borderColor: theme.customColors.grey,
        '&:last-child': {
            borderBottom: '0px solid',
        },
    },
    columnTopBorder: {
        border: '1px solid',
        borderBottom: '0px solid',
        borderLeft: '0px solid',
        borderRight: '0px solid',
        borderColor: theme.customColors.grey,
    },
    remarkHeader: {
        padding: `${theme.spacing(2)}px`,
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(1)}px`,
        },
        textColor: theme.customColors.white,
    },
    remarks: {
        padding: `${theme.spacing(2)}px`,
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(1)}px`,
        },
        textColor: theme.customColors.white,
        textDecoration: 'underline',
        width: '100%',
    },
    icon: {
        fill: theme.customColors.iconFront,
        background: theme.customColors.iconBg,
        float: 'right',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        },
    },
    iconMobile: {
        fill: 'Black',
        float: 'right',
        fontSize: '1rem',
    },
    divider: {
        margin: 5,
        borderWidth: 0,
        height: 1,
        backgroundColor: theme.customColors.lightGrey,
    },
    link: {
        fontWeight: 'bold',
        fontSize: 16,
        [theme.breakpoints.down('xs')]: {
            fontSize: 8,
            fontWeight: `normal`,
        },
        cursor: 'pointer',
        textDecoration: 'underline',
        color: 'black',
    },
    categoryColor: {
        backgroundColor: theme.customColors.m510Background,
    },
    gridItemBorder: {
        backgroundColor: theme.customColors.m510Background,
        border: '1px solid grey',
        borderLeft: 'none',
        borderBottom: 'none',
    },
    containerBorder: {
        backgroundColor: theme.customColors.white,
        border: '1px solid grey',
    },
    categoryMargin: {
        margin: `${theme.spacing(1)}px`,
        [theme.breakpoints.down('sm')]: {
            margin: `4px`,
        },
    },
})
