import React from 'react'

export const useMaxScreenWidth = maxWidth => {
    const [isOfMaxWidth, setIsOfMaxWidth] = React.useState(window.matchMedia(`(max-width: ${maxWidth}px)`).matches)

    const handleResize = () => {
        setIsOfMaxWidth(window.matchMedia(`(max-width: ${maxWidth}px)`).matches)
    }

    React.useEffect(() => {
        window.addEventListener('resize', () => handleResize())

        return () => {
            window.removeEventListener('resize', () => handleResize())
        }
    })

    return isOfMaxWidth
}
