import React from 'react'
import { object } from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import { withLocalize } from 'react-localize-redux'
import { withStore, setDefaultHeaders } from 'react-ion-store'
import {
    AuthorizationNotifier,
    AuthorizationRequest,
    RedirectRequestHandler,
    AuthorizationServiceConfiguration,
    TokenRequest,
    BaseTokenRequestHandler,
    GRANT_TYPE_AUTHORIZATION_CODE,
} from '@openid/appauth'
import { withStyles } from '@material-ui/core'

import LoginView from './LoginView'

import { valid } from 'utils/tokenUtils'

import styles from './styles'

const storeKeys = ['client_id', 'redirect_uri', 'scope', 'response_type']
const tokenKeys = ['idKeyPath', 'accessKeyPath', 'subKey']

class Login extends React.Component {
    static propTypes = {
        classes: object.isRequired,
        history: object.isRequired,
        store: object.isRequired,
    }

    configuration

    authorizationHandler = new RedirectRequestHandler()
    notifier = new AuthorizationNotifier()

    constructor(props) {
        super(props)

        this.state = {
            loginPossible: false,
            error: false,
        }

        this.authorizationHandler.setAuthorizationNotifier(this.notifier)
        this.notifier.setAuthorizationListener((request, response, error) => {
            if (error) console.error(error) // eslint-disable-line

            if (response) {
                this.code = response.code

                const tokenRequest = new TokenRequest({
                    client_id: request.clientId,
                    client_secret: undefined,
                    redirect_uri: request.redirectUri,
                    grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                    refresh_token: undefined,
                    code: response.code,
                    extras: {
                        code_verifier: request.internal.code_verifier,
                    },
                })

                const tokenHandler = new BaseTokenRequestHandler()

                const jsonconf =
                    window.localStorage.getItem('configjson') !== undefined
                        ? JSON.parse(window.localStorage.getItem('configjson'))
                        : this.configuration

                tokenHandler.performTokenRequest(jsonconf, tokenRequest).then(token => {
                    const { idToken, accessToken } = token

                    if (valid(idToken, accessToken)) {
                        const { idKeyPath, accessKeyPath, subKey } = props.store.get(tokenKeys)

                        setDefaultHeaders({
                            'Ocp-Apim-Subscription-Key': subKey,
                            Authorization: `bearer ${accessToken}`,
                        })
                        props.store.set({ auth: accessToken })
                        window.localStorage.setItem(idKeyPath, idToken)
                        window.localStorage.setItem(accessKeyPath, accessToken)
                        window.localStorage.removeItem('configjson')
                        window.dispatchEvent(new Event('bearerSet'))

                        props.history.replace('/')
                    }
                })
            }
        })
    }

    componentDidMount = async () => {
        if (location.hostname !== 'localhost') {
            await this.authorizationHandler.completeAuthorizationRequestIfPossible()
            this.authorizationHandler.setAuthorizationNotifier(this.notifier)
        }

        AuthorizationServiceConfiguration.fetchFromIssuer(process.env.REACT_APP_OIDC_PATH)
            .then(configuration => {
                window.localStorage.setItem('configjson', JSON.stringify(configuration))

                this.configuration = configuration
                this.setState({ loginPossible: true, error: false })
                if(this.props.store.get('autoSignIn')) {
                    this.props.store.set({ autoSignIn: false })
                    this.handleLogin()
                }
            })
            .catch(error => {
                console.error(error)
                this.setState({ loginPossible: false, error: true })
            })
    }

    handleLogin = () => {
        const { client_id, redirect_uri, scope, response_type } = this.props.store.get(storeKeys)
        const request = new AuthorizationRequest(
            {
                client_id,
                redirect_uri,
                scope,
                response_type,
                state: undefined,
            },
            undefined,
            true
        )

        this.authorizationHandler.performAuthorizationRequest(this.configuration, request)
    }

    render() {
        return <LoginView {...this.props} handleLogin={this.handleLogin} loginProps={{ ...this.state }} />
    }
}

export default compose(withRouter, withLocalize, withStyles(styles), withStore)(Login)
