import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import PrimaryButton from 'components/PrimaryButton'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import StatusLabel from 'components/StatusLabel'
import { dateTimeFromEpochSeconds } from 'utils/dateParser'
// eslint-disable-next-line max-len
import { isCompositionViewAllowed } from 'modules/Performances/views/ReadyForDeparture/components/CompositionView/Utils/isCompoEditingAllowed'

function TaskDetailDrawerView(props) {
    const { translate, rowData, store } = props
    let task = rowData.task
    //TODO label as per the status of performance
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid style={{ marginTop: '16px', marginBottom: '16px' }} item xs={12}>
                    <Typography>
                        {task.trainComposed +
                            (task.code === 'AFREL'
                                ? ` ${
                                      task.referencedPerformance?.performanceId
                                          ? task.referencedPerformance?.performanceId
                                          : ''
                                  }`
                                : task.code === 'VK.PC'
                                ? ` ${task?.taskInfo}`
                                : task.code === 'DRIVE'
                                ? ` ${task.locationFrom} - ${task.locationTo}`
                                : '')}
                    </Typography>
                </Grid>
                <Grid style={{ marginTop: '16px' }} item xs={12}>
                    <Typography>{rowData.name}</Typography>
                    <Typography>{rowData.idfNumber}</Typography>
                </Grid>
                <Grid style={{ marginTop: '16px' }} item xs={6}>
                    <Typography>{translate('perfByTrain_drawer_task_planned_departure')}</Typography>
                    {task.plannedStartDateTime ? dateTimeFromEpochSeconds(task.plannedStartDateTime) : '--:--'}
                </Grid>
                <Grid style={{ marginTop: '16px' }} item xs={6}>
                    <Typography>{translate('perfByTrain_drawer_task_actual_departure')}</Typography>
                    {task.realStartDateTime ? dateTimeFromEpochSeconds(task.realStartDateTime) : '--:--'}
                </Grid>

                <Grid item xs={6}>
                    <Typography>{translate('perfByTrain_drawer_task_planned_arrival')}</Typography>
                    {task.plannedEndDateTime ? dateTimeFromEpochSeconds(task.plannedEndDateTime) : '--:--'}
                </Grid>
                <Grid item xs={6}>
                    <Typography>{translate('perfByTrain_drawer_task_actual_arrival')}</Typography>
                    {task.realEndDateTime ? dateTimeFromEpochSeconds(task.realEndDateTime) : '--:--'}
                </Grid>

                <Grid style={{ marginTop: '16px' }} item xs={6}>
                    <StatusLabel disableButtons={true} status={task['status']} isAbolished={task['isAbolished']} />
                </Grid>
                <Grid style={{ marginTop: '16px' }} item xs={12}>
                    <PrimaryButton
                        disabled={checkFunctionalityDisabled(
                            store,
                            'view',
                            'performances.functionality.management.performanceDetail'
                        )}
                        onClick={() =>
                            window.open(`/performances/detail/${rowData.performanceId}/profile/${rowData.idfNumber}`)
                        }
                    >
                        <Typography>{translate('perfByTrain_drawer_open_performance_button')}</Typography>
                    </PrimaryButton>
                </Grid>
                <Grid item xs={12}>
                    <PrimaryButton
                        disabled={checkFunctionalityDisabled(
                            store,
                            'taskDetail',
                            'performances.functionality.management.performanceDetail'
                        )}
                        onClick={() =>
                            window.open(
                                `/performances/detail/${rowData.performanceId}/profile/${rowData.idfNumber}/task/${task.id}`
                            )
                        }
                    >
                        <Typography>{translate('perfByTrain_drawer_open_task_button')}</Typography>
                    </PrimaryButton>
                </Grid>
                <Grid item xs={12}>
                    <PrimaryButton
                        disabled={!isCompositionViewAllowed(props)}
                        onClick={() => {
                            window.open(
                                `/compositionPtcarView/${rowData.performanceId}/${task.id}/${encodeURIComponent(
                                    task.trainComposed
                                )}/${task.plannedStartDateTime}/${task.locationFromPtCarUic}/${
                                    rowData.idfNumber
                                }/${window.btoa(task.locationFrom)}/${encodeURIComponent(
                                    task.locationToPtCarUic
                                )}/${window.btoa(task.locationTo)}`
                            )
                        }}
                    >
                        <Typography>{translate('perfByTrain_drawer_open_composition_button')}</Typography>
                    </PrimaryButton>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

TaskDetailDrawerView.propTypes = {
    translate: PropTypes.func,
    handleAdd: PropTypes.func,
    error: PropTypes.string,
    rowData: PropTypes.object,
    editData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    store: PropTypes.object,
}

export default TaskDetailDrawerView
