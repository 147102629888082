import { grey, blue } from '@material-ui/core/colors'

export default theme => ({
    documentTableWrapper: {
        padding: `0 ${theme.spacing(3)}px`,
        width: '100%',
        minHeight: theme.readList,
        height: `calc(100vh - 210px)`,
    },
    tableRow: {
        backgroundColor: 'transparent',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
    },
    header: {
        backgroundColor: theme.customColors.lightGrey,
    },
    editIcon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        fill: theme.customColors.white,
    },
    deleteIcon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        color: theme.customColors.red,
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
    parentDiv: {
        justifyContent: 'space-between',
        padding: `${theme.spacing(2)}px`,
    },
    customInput: {
        backgroundColor: grey['600'],
        height: '35px',
        minWidth: '200px',
        border: `2px solid ${grey['700']}`,
        fontSize: '14px',
        color: theme.customColors.white,
        fontFamily: 'roboto',
        paddingLeft: '8px',
        '&::placeholder': {
            color: `${grey['400']}`,
        },
        '&:focus': {
            outline: 'none',
            borderBottom: `2px solid ${blue['500']}`,
        },
    },
    multipleIcons: {
        display: 'flex',
        alignContent: 'space-between',
    },
    buttonContainer: {
        textAlign: 'right',
        padding: `${theme.spacing(3)}px`,
    },
    drawerRoot: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
    },
})
