import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default async NumberOfPerformancesVisible => {
    const { subscriptionKey } = requestData
    const fullUrl = `settings/numberofperformancesvisibleincalendar`

    try {
        return await axios.post(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
            NumberOfPerformancesVisible,
        })
    } catch (e) {
        return {
            status: 204,
        }
    }
}
