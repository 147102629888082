import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SelectInput from 'components/SelectInput'

import { IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircle'

export class AddRole extends Component {
    static propTypes = {
        classes: PropTypes.object,
        roleItems: PropTypes.array,
        selectedRole: PropTypes.string,
        userRoles: PropTypes.array,
        handleEvent: PropTypes.func,
        addSingleRole: PropTypes.func,
    }

    render() {
        const { classes, roleItems, handleEvent, selectedRole, userRoles, addSingleRole } = this.props

        return (
            <React.Fragment>
                <div style={{ display: 'inline-block' }}>
                    <SelectInput
                        sorted
                        name="role"
                        classes={classes}
                        items={[{ id: ' ', text: 'Role' }, ...roleItems]}
                        value={selectedRole}
                        onChange={handleEvent('selectedRole')}
                    />
                </div>
                <IconButton
                    className={classes.withMarginButton}
                    component="span"
                    onClick={() => {
                        addSingleRole(userRoles)
                    }}
                    disabled={selectedRole === ' '}
                >
                    <AddIcon color={'primary'} />
                </IconButton>
            </React.Fragment>
        )
    }
}

export default AddRole
