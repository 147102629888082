import React from 'react'
import { object, string, func, bool, array } from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'

// Components
import IconButton from 'components/IconButton'
import AppPaper from 'components/AppPaper'
import ErrorMessage from 'components/ErrorMessage'

class EditTaskFooterView extends React.Component {
    static propTypes = {
        classes: object.isRequired,
        history: object,
        remark: string,
        saveAndMapTask: func,
        editError: string,
        editLoading: bool,
        trainLocations: array,
        taskType: string,
        referenceOrg: string,
        referenceDepot: string,
        selectedReferencePerformanceNumber: string,
        diagramNumberError: bool,
    }

    goBack = () => {
        const { history } = this.props
        history.goBack()
    }
    /* eslint-disable-next-line max-len */
    editTaskDisable(taskType, trainLocations, referenceOrg, referenceDepot, selectedReferencePerformanceNumber, diagramNumberError) {
        if(diagramNumberError){
            return true
        }
        if (taskType.toUpperCase() === 'DRIVE' || taskType === 'HLP') {
            return trainLocations.length < 1
        }
        if (taskType.includes('AFREL')) {
            if (referenceDepot === ' ' && referenceOrg === ' ' && selectedReferencePerformanceNumber === ' ') return false
            else if (referenceDepot !== ' ' && referenceOrg !== ' ' && selectedReferencePerformanceNumber !== ' ')
                return false
            else return true
        }
        return false
    }

    render() {
        const {
            classes,
            saveAndMapTask,
            editError,
            editLoading,
            trainLocations,
            taskType,
            referenceOrg,
            referenceDepot,
            selectedReferencePerformanceNumber,
            diagramNumberError,
        } = this.props
        return (
            <AppPaper className={classes.root}>
                <Translate>
                    {({ translate }) => (
                        <React.Fragment>
                            <IconButton
                                className={classes.marginButton}
                                icon=""
                                color="primary"
                                title={translate('CancelCaps')}
                                onClick={() => {
                                    this.goBack()
                                }}
                            />
                            <IconButton
                                disabled={
                                    editLoading ||
                                    this.editTaskDisable(
                                        taskType,
                                        trainLocations,
                                        referenceOrg,
                                        referenceDepot,
                                        selectedReferencePerformanceNumber,
                                        diagramNumberError
                                    )
                                }
                                icon="note_add"
                                color="primary"
                                title={translate('PerformanceSaveTask')}
                                onClick={() => {
                                    saveAndMapTask()
                                }}
                            />
                            {editError && <ErrorMessage error={editError} />}
                        </React.Fragment>
                    )}
                </Translate>
            </AppPaper>
        )
    }
}

export default withRouter(EditTaskFooterView)
