import React, { useState, useEffect, useCallback } from 'react'
import sortBy from 'lodash/sortBy'
import { withStyles } from '@material-ui/styles'
import { Typography, Button } from '@material-ui/core'
import { object, func } from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'
import getPerformancesByTrainNumber from './services/getPerformancesByTrainNumber'
import styles from './styles'
import { DateTime } from 'luxon'
import PerformanceByTrainListView from './components/PerformanceByTrainListView'
import Loading from 'components/Loading'
import { withStore } from 'react-ion-store'
import TaskDrawerView from './components/TaskDrawerView'
import Header from 'components/Header'
import ActionBar from 'components/ActionBar'
import DateTimePicker from 'components/DateTimePicker'
import { apiDateTimeParser } from 'utils/dateParser'
import getStoredRefreshRate from 'utils/getStoredRefreshRate'

const performanceListByTask = data => {
    let list = []
    for (let perf of data) {
        let tasks = perf.tasks
        for (let task of tasks) {
            let performance = { ...perf }
            performance.task = { ...task }
            list.push(performance)
        }
    }

    //Sort first by perf date and then by plannedStartTime
    return sortBy(list, ['performanceDate', 'task.plannedStartTime'])
}
const mapColors = performances => {
    let colors = { yellow: 0, red: 0 }
    if (performances && performances.length > 0) {
        // eslint-disable-next-line array-callback-return
        performances.map(p => {
            if (p.status === 1) {
                return (colors.yellow += 1)
            }
            if (p.status === 2) {
                return (colors.red += 1)
            }
        })
    }
    return colors
}
const PerformancesByTrain = props => {
    const { classes, translate, store } = props
    const [trainNumber, setTrainNumber] = useState('')
    const [performanceData, setPerformanceData] = useState([])
    const [loading, setLoading] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [rowData, setRowData] = useState()
    const [colorValues, setColorvalues] = useState(null)
    const [realTime, setRealTime] = useState(true)
    const [startTime, setStartTime] = useState(DateTime.local())
    const [filterValue, setFilterValue] = useState('')
    const [refresh, setRefresh] = useState('')
    const handleLoadPerformances = useCallback(
        async trainNumberToSearch => {
            if (trainNumberToSearch) {
                setLoading(true)
                setTrainNumber(trainNumberToSearch)
                let response = await getPerformancesByTrainNumber(
                    trainNumberToSearch,
                    apiDateTimeParser(startTime.toMillis())
                )
                let perfList = performanceListByTask(response)
                let mappedColors = mapColors(perfList)
                setColorvalues(mappedColors)
                setLoading(false)
                setPerformanceData(perfList)
            }
        },
        [startTime]
    )

    useEffect(() => {
        document.title = translate('module_train_task_overview')
        const refreshValue = getStoredRefreshRate('trainTaskOverview')
        const interval = setInterval(() => {
            if (realTime) {
                setStartTime(DateTime.local())
            }
        }, refreshValue * 1000)
        return () => {
            clearInterval(interval)
        }
    }, [refresh, realTime])

    const onRowClick = useCallback(
        rowClickedData => {
            setOpenDrawer(true)
            setRowData(rowClickedData)
        },
        [rowData]
    )
    const onClose = () => {
        setOpenDrawer(false)
        setRowData()
    }
    const persist = type => value => {
        if (type === 'time') {
            setStartTime(value)
        } else if (type === 'date') {
            setRealTime(false)
            if (trainNumber) handleLoadPerformances(trainNumber)
        }
    }

    const toggleRealTime = () => {
        if (!realTime) {
            setStartTime(DateTime.local())
        }
        setRealTime(!realTime)
    }
    const handleFilterValue = e => {
        setFilterValue(e.target.value)
    }
    const reloadData = () => {
        setRefresh(DateTime.local())
        setStartTime(DateTime.local())
    }
    return (
        <React.Fragment>
            <Header
                isMenu
                title={translate('train_ready_page_title')}
                pageTitle="trainTaskOverview"
                resetIntervalTaskDetail={reloadData}
            />
            <ActionBar>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            className={classes.customInput}
                            placeholder={translate('search')}
                            value={trainNumber}
                            onChange={e => setTrainNumber(e.target.value)}
                        />
                        <Button
                            style={{ marginLeft: '12px' }}
                            disabled={loading || trainNumber.length < 2}
                            variant="contained"
                            color="primary"
                            onClick={() => handleLoadPerformances(trainNumber)}
                        >
                            {translate('add_task_load_train')}
                        </Button>
                        {colorValues && (
                            <div className={classes.labelContainer}>
                                {colorValues && colorValues.red !== 0 && (
                                    <Typography>
                                        <span className={classes.roundedRed}>{colorValues.red}</span>
                                    </Typography>
                                )}
                                {colorValues && colorValues.yellow !== 0 && (
                                    <Typography>
                                        <span className={classes.roundedYellow}>{colorValues.yellow}</span>
                                    </Typography>
                                )}
                                {colorValues && colorValues.length > 0 && (
                                    <Typography>{`${translate('firstDepartures_label')}`}</Typography>
                                )}
                            </div>
                        )}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <DateTimePicker
                            identifier="trainTaskOverview"
                            startValue={startTime}
                            translate={translate}
                            persist={persist}
                            trainView={true}
                            toggleRealTime={toggleRealTime}
                            realTime={realTime}
                            filterValue={filterValue}
                            handleFilterValue={handleFilterValue}
                        />
                    </div>
                </div>
            </ActionBar>

            {loading && !performanceData.length ? (
                <Loading />
            ) : performanceData.length ? (
                <PerformanceByTrainListView data={performanceData} classes={classes} onRowClick={onRowClick} />
            ) : (
                <div style={{ margin: '16px' }}>No Data</div>
            )}
            {openDrawer && (
                <TaskDrawerView
                    isOpen={openDrawer}
                    onClose={onClose}
                    rowData={rowData}
                    classes={classes}
                    translate={translate}
                    store={store}
                />
            )}
        </React.Fragment>
    )
}
PerformancesByTrain.propTypes = {
    classes: object,
    translate: func,
    store: object,
}
export default compose(withStore, withLocalize, withStyles(styles))(PerformancesByTrain)
