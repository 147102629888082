import React, { useState } from 'react'
import Header from 'components/Header'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Typography, CircularProgress } from '@material-ui/core'
import { createWorker } from 'tesseract.js'

import styles from './styles'
import getRandomInt from 'utils/getRandomInt'

const Caman = window.Caman
function OcrPoc(props) {
    const [output, setOutput] = useState('')
    const [rectangle, setRectangle] = useState(true)
    const [recoRunning, setRecoRunning] = useState(false)
    const [confirmedOutput, setConfirmedOutput] = useState('')
    const [allResults, setAllResults] = useState([])
    const [borderClass, setBorderClass] = useState('doing')
    const { translate, classes } = props

    let stopIt = false
    const sleep = function(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    // crop out the required pieces
    const captureImage = async function(x, y, width, height) {
        let video = await document.getElementById('videoElement')

        if (video === null) sleep(1000)
        let canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        if (video !== null) canvas.getContext('2d').drawImage(video, x, y, width, height, 0, 0, width, height)
        return canvas
    }

    // render the image with Caman with random exposure and contrast
    const renderImage = function(canvas, name, callback) {
        let outputElement = document.getElementById('output')

        let exposure = Math.floor(getRandomInt(0, 100) % 10) * 10 //Math.floor(Math.random() * Math.floor(10)) * 10
        let contrast = Math.floor(getRandomInt(0, 100) % 10) * 10 //Math.floor(Math.random() * Math.floor(10)) * 10
        let img = document.getElementById('image' + name + exposure + contrast)

        if (img === null) {
            img = document.createElement('img')
            img.id = 'image' + name + exposure + contrast
        }

        img.src = canvas.toDataURL()
        outputElement.append(img)
        outputElement.append(document.createElement('br'))

        Caman(img, function() {
            this.greyscale()
            this.exposure(exposure)
            this.contrast(contrast)
            this.render(callback(this.canvas))
        })
    }

    const customRecognize = async (image, langs, options) => {
        const worker = createWorker()

        await worker.load()
        await worker.loadLanguage(langs)
        await worker.initialize(langs)
        await worker.setParameters(options)
        return worker.recognize(image).finally(async () => {
            await worker.terminate()
        })
    }
    // do the actual OCR with Tesseract doing the heavy lifting
    const doOCR = async function(image, characters, callback) {
        let min_length = 3
        customRecognize(image.canvas, 'eng', { tessedit_char_whitelist: characters, confidence: 80 }).then(function({
            data,
        }) {
            const result = data

            let confidence = 0
            let best_match
            for (let word of result.words) {
                if (word.text.length > min_length && word.confidence > confidence) {
                    best_match = word
                }
            }
            callback(best_match)
        })
    }

    // tie it all together
    const getWord = function(canvas, name, characters, callback) {
        renderImage(canvas, name, function(context) {
            let img = context.getContext('2d')
            doOCR(img, characters, function(word) {
                if (word !== undefined) {
                    console.log('confidence', word.confidence)
                    console.log('word', word.text)
                }
                callback(word)
            })
        })
    }

    const grabMaterialNumber = async function() {
        setRecoRunning(true)
        let canvas = await captureImage(0, 0, 640, 480)
        if (rectangle) {
            canvas = await captureImage(235, 230, 200, 50)
        }
        let elid = 'number'
        let characters = '-0123456789'
        grab(canvas, elid, characters, grabMaterialNumber)
    }

    const grab = function(canvas, elid, characters, callback) {
        let confidence = 0
        let best_match = ''

        getWord(canvas, elid, characters, function(word) {
            if (word !== undefined && confidence < word.confidence) {
                confidence = word.confidence
                best_match = word.text
            }

            if (confidence > 80) {
                setRecoRunning(false)
                setOutput(word?.text?.length > 0 ? word.text.toUpperCase() : '')
                setBorderClass('complete')
                return
            }

            if (confidence > 60) {
                setOutput(best_match.toUpperCase())

                setBorderClass('best_match')
            }

            if (confidence < 80) {
                if (stopIt) {
                    return
                }
                sleep(2)
                callback()
            }
        })
    }

    const onLoad = () => {
        grabMaterialNumber()
        setOutput('')
        setBorderClass('doing')

        let video = document.querySelector('#videoElement')
        const constraints = {
            video: {
                width: 640,
                height: 480,
                facingMode: 'environment',
            },
        }
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(function(stream) {
                    video.srcObject = stream
                })
                .catch(function(err) {
                    console.log(err.name + ': ' + err.message)
                })
        }
        video.onpause = function() {
            stopIt = true
        }
        video.onplaying = function() {
            stopIt = false
            grabMaterialNumber()
        }
    }
    const outputNumber = () => {
        stopIt = true

        setConfirmedOutput(output)
        let outputs = allResults
        outputs.push(output.toString())
        setAllResults(outputs)
    }
    const returnClassname = () => {
        if (borderClass === 'doing') {
            return classes.doing
        }
        if (borderClass === 'best_match') {
            return classes.bestMatch
        }
        if (borderClass === 'complete') {
            return classes.complete
        }
        return classes.doing
    }
    const showAllResults = () => {
        return allResults.map(item => {
            return <li key="item">{item}</li>
        })
    }
    return (
        <React.Fragment>
            <Header isMenu title={translate('ocr_poc')} />
            <div>
                <div
                    className="container"
                    style={{ position: 'relative', width: '640px', height: '480px', padding: '12px' }}
                >
                    <video autoPlay controls id="videoElement" style={{ width: '640px', height: '480px' }}></video>
                    {rectangle && (
                        <div
                            className="block MaterialNumber"
                            style={{
                                border: '1px solid black',
                                position: 'absolute',
                                backgroundColor: 'white',
                                opacity: 0.6,
                                left: '235px',
                                top: '230px',
                                width: '200px',
                                height: '50px',
                            }}
                        ></div>
                    )}
                </div>
                {recoRunning && (
                    <div style={{ height: '50px', display: 'block', width: '200px', padding: '16px' }}>
                        <h2>
                            Analyseren <CircularProgress />
                        </h2>
                    </div>
                )}
                <div id="output" style={{ position: 'relative', display: 'none' }}></div>
                <input
                    type="text"
                    id="number"
                    value={output}
                    readOnly
                    style={{
                        width: '600px',
                        fontSize: '20px',
                        marginTop: '50px',
                        marginLeft: '12px',
                    }}
                    className={returnClassname()}
                />

                {/* <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        let video = document.getElementById('videoElement')
                        stopIt = false
                        video.play()
                        grabMaterialNumber()
                        setOutput('')
                        setBorderClass('doing')
                    }}
                >
                    Opnieuw / Start
                </Button> */}
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        outputNumber()
                    }}
                >
                    Bevestigen
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        setRectangle(!rectangle)
                    }}
                >
                    {`Rectangle ${rectangle ? 'aan' : 'uit'}`}
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        onLoad()
                    }}
                >
                    {`start herkenning`}
                </Button>
                <Typography variant="subtitle1"> Geconfirmeerd: {confirmedOutput}</Typography>

                {allResults && <ul>{showAllResults()}</ul>}
            </div>
        </React.Fragment>
    )
}

OcrPoc.propTypes = {
    translate: PropTypes.func,
    classes: PropTypes.object,
}

export default compose(withLocalize, withStyles(styles))(OcrPoc)
