import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus, enums, withCall } from 'react-ion-store'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'
import UsersAndRolesView from './UsersAndRolesView'

import getUserGroups from './Services/getUserGroups'

import styles from './styles'
import { useLocalStorageReload } from 'utils/hooks/useLocalstorageReload'
import getPortalUserRoleTypes from './Services/getPortalUserRoleTypes'

const {
    CallStatus: { LOADING, FAILED },
} = enums

const Failed = () => <div>Error retrieving Roles!</div>

const UsersAndRoles = props => {
    const [searchValue, setSearchValue] = useState('')
    const [selectedItem, setSelectedItem] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [deleteGroupId, setDeleteGroupId] = useState('')
    const [deleteName, setDeleteName] = useState('')
    const [editUserGroupId, setEditUserGroupId] = useState('')
    const [addNewuserDrawer, setAddNewuserDrawer] = useState(false)
    const [editUserDrawer, setEditUserDrawer] = useState(false)
    const [userGroupList, setUserGroupList] = useState([])
    const [addNewUserGroupDrawer, setAddNewUserGroupDrawer] = useState(false)
    const [addNewUserToGroupId, setGroupIdToAddUser] = useState('')
    const [openAddUserDrawer, setOpenAddUserDrawer] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [openFindUserDrawer, setOpenFindUserDrawer] = useState(false)
    const [portalUserRoles, setPortalUserRoles] = useState([])
    const { invertLocalState } = useLocalStorageReload('userRoleChange')

    useEffect(() => {
        let unmounted = false
        setShowLoading(true)
        loadData(unmounted)
        return () => {
            unmounted = true
        }
    }, [])

    const loadData = async unmounted => {
        try {
            setShowLoading(true)
            var userGroups = await getUserGroups()
            var roles = await getPortalUserRoleTypes()
            if (unmounted === undefined || unmounted === false) {
                setUserGroupList(userGroups.data)
                setPortalUserRoles(
                    roles.data.map(x => {
                        return { id: x.id.toString(), text: x.name }
                    })
                )
                setShowLoading(false)
            }
        } catch {
            if (unmounted === undefined || unmounted === false) {
                setUserGroupList([])
                setPortalUserRoles([])
                setShowLoading(false)
            }
        }
    }

    const reloadPage = () => {
        setIsOpen(false)
        loadData()
    }

    useEffect(() => {
        document.title = props.translate('module_users')
        if(props.history.location.search != ""){
            props.history.push("/users")
        }
    }, [])

    const handleAddNewUserDrawer = prop => {
        setAddNewuserDrawer(prop)
        setAddNewUserGroupDrawer(prop)
    }

    const handleRedirectToManageEndpoints = () => {
        props.history.push('/manageendpoints')
    }

    const handleSearchValue = e => {
        if (e === null) {
            setSearchValue('')
            setSelectedItem('')
        } else {
            setSearchValue(e.value)
            setSelectedItem(e)
        }
    }

    const clearValue = () => {
        setSearchValue('')
    }

    const handleClose = () => {
        setIsOpen(false)
        setDeleteGroupId('')
    }

    const handleOpen = (id, name) => {
        setIsOpen(true)
        setDeleteGroupId(id)
        setDeleteName(name)
    }

    const filterData = data => {
        if (searchValue === '') return data
        return data.filter(item => item.id === searchValue)
    }

    const setGroupIdForEditAndOpenDrawer = id => {
        setEditUserGroupId(id)
        setEditUserDrawer(true)
    }
    const resetIdfAndCloseDrawer = () => {
        setEditUserGroupId('')
        setEditUserDrawer(false)
    }
    const setGroupIdAndOpenDrawer = groupId => {
        setGroupIdToAddUser(groupId)
        setOpenAddUserDrawer(true)
    }
    const resetGroupIdAndCloseDrawer = () => {
        setGroupIdToAddUser('')
        setOpenAddUserDrawer(false)
    }

    const renderOrgSelectList = data => {
        let newArray = []

        if (data && data.length > 0)
            data.forEach(org => {
                const newObj = {
                    value: org.code,
                    label: `${org.description[0].translation}/${org.description[1].translation} (${org.type})`,
                    idString: org.code,
                }
                newArray.push(newObj)
            })

        return newArray
    }
    const handleFindUser = () => {
        setOpenFindUserDrawer(true)
    }
    const closeFindUserDrawer = () => {
        setOpenFindUserDrawer(false)
    }

    const { translate, store, __ion_status, history } = props
    const districtData = props.store.get('DistrictData')
    const organisationData = props.store.get('OrganisationsData')

    const filteredData = filterData(userGroupList)
    const orgSelectList = renderOrgSelectList(organisationData)

    const { userRoles } = __ion_status

    const moduleList = store.get('moduleList')
    const { users: usersAndRoles } = store.get('roles') || { users: {} }

    const rolesLoading = !!userRoles && userRoles === LOADING
    const rolesFailed = !!userRoles && userRoles === FAILED

    if (rolesFailed)
        return (
            <React.Fragment>
                <Failed />
            </React.Fragment>
        )
    else if (rolesLoading)
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        )
    else if (!!moduleList && !moduleList.includes('/users')) {
        history.replace(`/`)
    }

    if (showLoading)
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        )
    else
        return (
            <React.Fragment>
                <UsersAndRolesView
                    translate={translate}
                    mapData={userGroupList}
                    data={filteredData}
                    handleSearchValue={handleSearchValue}
                    searchValue={searchValue}
                    clearValue={clearValue}
                    selectedItem={selectedItem}
                    districtData={districtData}
                    OrganisationsData={orgSelectList}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    isOpen={isOpen}
                    deleteGroupId={deleteGroupId}
                    deleteName={deleteName}
                    setGroupIdForEditAndOpenDrawer={setGroupIdForEditAndOpenDrawer}
                    resetIdfAndCloseDrawer={resetIdfAndCloseDrawer}
                    setGroupIdAndOpenDrawer={setGroupIdAndOpenDrawer}
                    resetGroupIdAndCloseDrawer={resetGroupIdAndCloseDrawer}
                    editUserGroupId={editUserGroupId}
                    usersAndRoles={usersAndRoles}
                    checkFunctionalityDisabled={checkFunctionalityDisabled}
                    addNewuserDrawer={addNewuserDrawer}
                    editUserDrawer={editUserDrawer}
                    handleAddNewUserDrawer={handleAddNewUserDrawer}
                    handleRedirectToManageEndpoints={handleRedirectToManageEndpoints}
                    setEditUserDrawer={setEditUserDrawer}
                    addNewUserGroupDrawer={addNewUserGroupDrawer}
                    openAddUserDrawer={openAddUserDrawer}
                    addNewUserToGroupId={addNewUserToGroupId}
                    reloadPage={reloadPage}
                    invertLocalState={invertLocalState}
                    openFindUserDrawer={openFindUserDrawer}
                    handleFindUser={handleFindUser}
                    closeFindUserDrawer={closeFindUserDrawer}
                    roleItems={portalUserRoles}
                    {...props}
                />
            </React.Fragment>
        )
    // }
}
UsersAndRoles.propTypes = {
    activeLanguage: PropTypes.object,
    translate: PropTypes.func,
    store: PropTypes.object,
    history: PropTypes.object,
    __ion_status: PropTypes.object,
}

export default compose(
    withRouter,
    withLocalize,
    withStyles(styles),
    withStore,
    withStatus,
    Component => props =>
        withCall('DistrictData', {
            config: {
                method: 'get',
                url: '/legacyReferenceData/districts',
            },
            reducer: ({ data }) => {
                return data
            },
        })(Component)(props),
    Component => props =>
        withCall('OrganisationsData', {
            config: {
                method: 'get',
                url: '/legacyReferenceData/organisations',
            },
            reducer: ({ data }) => {
                return data
            },
        })(Component)(props)
)(UsersAndRoles)
