import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'

import { apiDateTimeParser } from 'utils/dateParser'
import { DateTime } from 'luxon'
import { clear, valid, get } from 'utils/tokenUtils'

import { allZones, districtFilter, districts, searchFilter, sortBy } from './esemes.util'
import { getESemesMessages, getPubuids } from './esemes.service'
import { REDUCER_STATES, REDUCER_ACTIONS, REDUCER_REDUCERS } from './esemes.reducer'
import { TranslationContext } from 'context/translation'
import DefaultSettings from 'components/DefaultSettings'
import { useForceUpdate } from 'utils/hooks/useForceUpdate'

import { MenuContext } from 'context/menuStore'

// eslint-disable-next-line no-unused-vars
import { mockData } from './util/profileInfo'

export const INITIAL_STATE = {
    eSemesData: [],
}

export const ESemesContext = React.createContext(INITIAL_STATE)

const StoreProvider = ( {store, children} ) => {
    const [apiState, apiDispatch] = React.useReducer(REDUCER_REDUCERS.apiReducer, REDUCER_STATES.initialApiState)
    const [toggleState, toggleDispatch] = React.useReducer(REDUCER_REDUCERS.toggleReducer, REDUCER_STATES.initialToggleState)
    const [timeState, timeDispatch] = React.useReducer(REDUCER_REDUCERS.timeReducer, REDUCER_STATES.initialTimeState)
    const [filterState, filterDispatch] = React.useReducer(REDUCER_REDUCERS.filterReducer, REDUCER_STATES.initialFilterState)

    const settings = JSON.parse(localStorage.getItem('userSettings')) || DefaultSettings
    const eSemesSettings =
        settings.filter(el => el.pageReference === 'esemesOverview')[0] ||
        DefaultSettings.filter(el => el.pageReference === 'esemesOverview')[0]

    const { filter } = useParams()

    const history = useHistory()

    const force = useForceUpdate()

    const { activeLanguage } = React.useContext(TranslationContext)

    const { apiActions, toggleActions, timeActions, filterActions } = REDUCER_ACTIONS

    const { allowedDistricts } = React.useContext(MenuContext)

    const STATE = {
        filterState,
        toggleState,
        timeState,
        apiState,
    }

    const withLoadToggle = async cb => {
        toggleDispatch({ type: toggleActions.loading, payload: true })
        await cb()
        toggleDispatch({ type: toggleActions.loading, payload: false })
    }

    const METHODS = {
        handleDistrictFilter(activeDistricts, maxLength) {
            if (activeDistricts.length === 0) {
                return null
            } else {
                if (activeDistricts.includes('Alle districten') || activeDistricts.includes('Tous les districts')) {
                    const areAllActive =
                        filterState.district.includes('Alle districten') ||
                        filterState.district.includes('Tous les districts')
                    if (maxLength > activeDistricts.length && areAllActive) {
                        const allRemoved = activeDistricts.filter(
                            el => el !== 'Alle districten' && el !== 'Tous les districts'
                        )
                        filterDispatch({ type: filterActions.district, payload: { district: allRemoved } })
                    } else {
                        const getTranslated = allowedDistricts.data.map(el => {
                            if (activeLanguage.code === 'nl') {
                                return el.name[1].translation
                            } else {
                                return el.name[0].translation
                            }
                        })
                        activeLanguage.code === 'nl'
                            ? getTranslated.push('Alle districten')
                            : getTranslated.push('Tous les districts')

                        filterDispatch({ type: filterActions.district, payload: { district: getTranslated } })
                    }
                } else {
                    filterDispatch({ type: filterActions.district, payload: { district: activeDistricts } })
                }
            }
        },
        getIdFromParam(ids) {
            if (districtFilter(filter)) {
                apiDispatch({ type: apiActions.district, payload: filter })
                return ids
            }
            return ids.filter(id => {
                return filter?.toUpperCase().includes(id.name[0].translation.toUpperCase())
            })
        },
        toggleZone(zone, current) {
            const prev = filterState.zoneToggler
            const ind = prev.findIndex(el => el.id === zone)
            prev[ind].isOpen = !current
            filterDispatch({ type: filterActions.zoneToggler, payload: prev })
        },
        filterResults(filter) {
            if (!filter) {
                filterDispatch({ type: filterActions.dataFilter, payload: false })
            } else {
                filterDispatch({
                    type: filterActions.setFilterdResults,
                    payload: {
                        dataFilter: filter,
                        filterdResults: apiState.eSemesData.filter(el => {
                            return searchFilter(el, filter)
                        }),
                    },
                })
            }
        },
        async handleNewTime(date) {
            withLoadToggle(() => {
                timeDispatch({ type: timeActions.allowRefresh, payload: false })
                timeDispatch({ type: timeActions.apiDate, payload: date })
                timeDispatch({ type: timeActions.realTime, payload: false })
            })
        },
        toggleGroupPerZone() {
            toggleDispatch({ type: toggleActions.groupPerZone, payload: !toggleState.groupPerZone })
        },
        toggleSortOrder(key) {
            filterDispatch({
                type: filterActions.sort,
                payload: {
                    dir: key === filterState.sort.key ? (filterState.sort.dir === 'asc' ? 'desc' : 'asc') : 'asc',
                    key: key,
                },
            })
            const sort = sortBy(
                apiState.eSemesData,
                key === filterState.sort.key ? (filterState.sort.dir === 'asc' ? 'desc' : 'asc') : 'asc',
                key,
                activeLanguage.code
            )
            METHODS.filterResults(filterState.dataFilter)
            apiDispatch({ type: apiActions.semes, payload: sort })
        },
        async handleRealTime() {
            if (timeState.allowRefresh) {
                timeDispatch({ type: timeActions.allowRefresh, payload: false })
            } else {
                if (timeState.realTime) {
                    timeDispatch({ type: timeActions.realTime, payload: false })
                } else {
                    withLoadToggle(async () => {
                        timeDispatch({ type: timeActions.apiDate, payload: DateTime.local() })
                        timeDispatch({ type: timeActions.realTime, payload: true })
                        const esemesData = await SERVICES.getEsemes(filterState.activeIds)
                        if (location.href.includes('tasks')) {
                            const haveReadStatus = esemesData.filter(el => el.profileInfo.length > 0)
                            apiDispatch({
                                type: apiActions.semes,
                                payload: sortBy(haveReadStatus, filterState.sort.dir, filterState.sort.key),
                            })
                        } else {
                            apiDispatch({
                                type: apiActions.semes,
                                payload: sortBy(esemesData, filterState.sort.dir, filterState.sort.key),
                            })
                        }
                    })
                }
                timeDispatch({ type: timeActions.allowRefresh, payload: true })
            }
        },
        async handleNewDate() {
            withLoadToggle(async () => {
                const backupIds = await SERVICES.getPubuId()
                const esemesData = await SERVICES.getEsemes(
                    filterState.activeIds.length > 0 ? filterState.activeIds : METHODS.getIdFromParam(backupIds)
                )
                if (location.href.includes('tasks')) {
                    const haveReadStatus = esemesData.filter(el => el.profileInfo.length > 0)
                    apiDispatch({
                        type: apiActions.semes,
                        payload: sortBy(haveReadStatus, filterState.sort.dir, filterState.sort.key),
                    })
                } else {
                    apiDispatch({
                        type: apiActions.semes,
                        payload: sortBy(esemesData, filterState.sort.dir, filterState.sort.key),
                    })
                }
            })
        },
        async changeActiveZonesNew(zones) {
            const prevZones = filterState.activeIds
            if (zones.includes('ALLE ZONES')) {
                if (zones.length !== 6) {
                    if (prevZones.length !== 6) {
                        history.push('/eSemes/zones/IINWGent&IICECentre&IINOAntwerpen&IISOCharleroi&IISELiège')
                        force()
                        return null
                    } else {
                        const newZones = zones.filter(el => el !== 'ALLE ZONES')
                        let queryString = newZones.join('&').replaceAll(' ', '')
                        history.push(`/eSemes/zones/${queryString}`)
                        force()
                        return null
                    }
                } else {
                    history.push('/eSemes/zones')
                    force()
                    return null
                }
            } else {
                if (zones.length === 5 && prevZones.filter) {
                    history.push('/eSemes/zones')
                    force()
                    return null
                }
            }

            let queryString = zones.join('&').replaceAll(' ', '')
            history.push(`/eSemes/zones/${queryString}`)
            force()
            return null
        },
        async autoRefresh() {
            if (timeState.allowRefresh) {
                withLoadToggle(async () => {
                    timeState.realTime ? timeDispatch({ type: timeActions.apiDate, payload: DateTime.local() }) : null
                    const backupIds = await SERVICES.getPubuId()
                    const esemesData = await SERVICES.getEsemes(
                        filterState.activeIds.length > 0 ? filterState.activeIds : METHODS.getIdFromParam(backupIds)
                    )
                    if (location.href.includes('tasks')) {
                        const haveReadStatus = esemesData.filter(el => el.profileInfo.length > 0)
                        apiDispatch({
                            type: apiActions.semes,
                            payload: sortBy(haveReadStatus, filterState.sort.dir, filterState.sort.key),
                        })
                    } else {
                        apiDispatch({
                            type: apiActions.semes,
                            payload: sortBy(esemesData, filterState.sort.dir, filterState.sort.key),
                        })
                    }
                })
            }
        },
    }

    const SERVICES = {
        async getEsemes(ids) {
            let customIds = ids
            timeDispatch({
                type: timeActions.timeMargins,
                payload: { lower: eSemesSettings.startHour, upper: eSemesSettings.endHour },
            })
            const result = await getESemesMessages(
                apiDateTimeParser(timeState.apiDate.ts),
                timeState.timeMargins.lower,
                timeState.timeMargins.upper,
                customIds.map(el => el.pubuId)
            )
            if (result) {
                timeDispatch({
                    type: timeActions.syncTime,
                    payload: {
                        latestSuccessfullSyncTime: DateTime.local().ts / 1000,
                        nextSyncTime: DateTime.local().plus({ seconds: eSemesSettings.refreshRate }).ts / 1000,
                    },
                })
                return result
            } else {
                return []
            }
        },
        getPubuId() {
            const result = getPubuids()
            if (result) {
                return result
            }
        },
    }

    const initializer = async () => {
        withLoadToggle(async () => {
            const ids = await SERVICES.getPubuId()
            ids.unshift({
                pubuId: '6',
                name: [
                    {
                        translation: 'ALLE ZONES',
                    },
                ],
            })
            apiDispatch({ type: apiActions.pubu, payload: ids })

            let activeId
            if (location.href.includes('zones')) {
                activeId = METHODS.getIdFromParam(ids)
            } else {
                activeId = allZones
            }

            if (activeId.length === 5) {
                activeId.unshift({
                    pubuId: '6',
                    name: [
                        {
                            translation: 'ALLE ZONES',
                        },
                    ],
                })
            }

            if (filter === undefined && location.href.includes('zones')) {
                filterDispatch({
                    type: filterActions.changeActiveZones,
                    payload: {
                        activeIds: [],
                        zoneToggler: [],
                    },
                })
                apiDispatch({ type: apiActions.semes, payload: [] })
                return null
            }

            filterDispatch({
                type: filterActions.changeActiveZones,
                payload: {
                    activeIds: activeId,
                    zoneToggler: activeId.map(el => {
                        return {
                            id: el.pubuId,
                            isOpen: false,
                        }
                    }),
                },
            })
            const esemesData = await SERVICES.getEsemes(activeId)
            // eslint-disable-next-line no-empty
            if (location.href.includes('tasks')) {
                const haveReadStatus = esemesData.filter(el => el.profileInfo.length > 0)
                apiDispatch({
                    type: apiActions.semes,
                    payload: sortBy(haveReadStatus, filterState.sort.dir, filterState.sort.key),
                })
            } else {
                apiDispatch({
                    type: apiActions.semes,
                    payload: sortBy(esemesData, filterState.sort.dir, filterState.sort.key),
                })
            }
        })
    }

    
    const checkJwtExpiry = () => {
        const [idToken, accessToken] = get()
        const validToken = valid(idToken, accessToken)
        if(!validToken){
            clear()
            store.set({ auth: undefined })
        }
    }

    React.useEffect(() => {
        //HasTaskApplicable
        if (location.href.includes('zones')) {
            filterDispatch({ type: filterActions.setHasTaskApplicable, payload: { hasTaskApplicable: false } })
        } else {
            filterDispatch({ type: filterActions.setHasTaskApplicable, payload: { hasTaskApplicable: true } })
            let loc = location.href
            const endIndex = loc.indexOf('/tasks/') + 7
            let d = loc.slice(endIndex)
            d === 'Centrale-directie' ? (d = 'Centrale directie') : null
            if (allowedDistricts.data) {
                const withTranslation = allowedDistricts.data.filter(
                    el => el.name[0].translation === d || el.name[1].translation === d
                )
                const filterdTranslations = withTranslation.map(el => {
                    if (activeLanguage.code === 'nl') {
                        return el.name[1].translation
                    } else {
                        return el.name[0].translation
                    }
                })

                filterDispatch({ type: filterActions.district, payload: { district: filterdTranslations } })
            } else {
                const ad = Object.keys(districts).filter(el => d.includes(districts[el]))
                const AllActiveDistricts = ad.map(el => districts[el])
                filterDispatch({ type: filterActions.district, payload: { district: AllActiveDistricts } })
            }
        }
        if (timeState.realTime === true && timeState.allowRefresh === true) {
            initializer()
        }
        const AUTO_REFRESH = window.setInterval(() => {
            checkJwtExpiry()
            METHODS.autoRefresh(timeState.realTime)
        }, parseInt(timeState.refreshInterval) * 1000)
        window.addEventListener('storage', () => {
            location.reload()
        })

        return () => {
            clearInterval(AUTO_REFRESH)
            window.removeEventListener('storage', () => {
                return null
            })
        }
    }, [timeState.realTime, timeState.apiDate, filter, timeState.allowRefresh, activeLanguage, history.location])

    return <ESemesContext.Provider value={{ STATE, METHODS }}>{children}</ESemesContext.Provider>
}

StoreProvider.propTypes = {
    children: PropTypes.element.isRequired,
    store: PropTypes.object,
}

export default StoreProvider
