import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, Column, Table, defaultTableRowRenderer } from 'react-virtualized'
import { PtcarPlatformsContext } from '../../ptcarPlatforms.store'

import { columns } from '../../ptcarPlatform.state'

function PtcarPlatformTable(props) {
    let defaultHeaderHeight = 56
    let defaultRowHeight = 56

    const [columnSettings, setColumnSettings] = useState(columns)
    const { STATE } = useContext(PtcarPlatformsContext)
    const { classes, headerRenderer, cellRenderer, getRowClassName } = props

    useEffect(() => {
        const resize = () => {
            const newSettings = columns
            newSettings[3].width = window.innerWidth - 500
            setColumnSettings(newSettings)
        }

        window.addEventListener('resize', resize)

        resize()

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])

    return (
        <div className={classes.documentTableWrapper}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        className={classes.table}
                        height={height}
                        width={width}
                        rowCount={STATE.platformsFilterd.length}
                        rowClassName={getRowClassName}
                        rowRenderer={defaultTableRowRenderer}
                        rowGetter={({ index }) => STATE.platformsFilterd[index]}
                        rowHeight={defaultRowHeight}
                        headerHeight={defaultHeaderHeight}
                    >
                        {columnSettings.map(({ dataKey, ...other }) => (
                            <Column
                                key={dataKey}
                                headerRenderer={headerRenderer}
                                className={classes.flexContainer}
                                cellRenderer={cellRenderer}
                                dataKey={dataKey}
                                {...other}
                            />
                        ))}
                    </Table>
                )}
            </AutoSizer>
        </div>
    )
}
PtcarPlatformTable.propTypes = {
    classes: PropTypes.object,
    headerRenderer: PropTypes.func,
    cellRenderer: PropTypes.func,
    getRowClassName: PropTypes.func,
}
export default PtcarPlatformTable
