export default theme => ({
    paperRoot: {
        // Width inputform - (padding * 2)
        width: theme.readStatusBoxes - theme.spacing(3) * 2,
        minHeight: theme.spacing(8),
        position: 'absolute',
        zIndex: 3,
        marginTop: '5px',
        backgroundColor: theme.palette.grey['900'],
    },
    pending: {
        height: theme.spacing(8),
    },
    finished: {
        maxHeight: '40vh',
        overflowY: 'auto',
        color: theme.customColors.white,
    },
    menuItem: {
        height: theme.spacing(8),
        overflowX: 'hidden',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        color: theme.customColors.lightGrey,
        paddingTop: 0,
        paddingBottom: 0,
    },
})
