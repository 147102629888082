import React from 'react'
import { object, func } from 'prop-types'
import { Translate } from 'react-localize-redux'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import Header from 'components/Header'
import IconButton from 'components/IconButton'
import AddPerformanceDrawer from './AddPerformanceDrawer'

const AddPerformanceView = ({ classes, drawers, persistDrawers, roles, history, store }) => {
    const { addPerformance } = drawers

    return (
        <Translate>
            {({ translate }) => (
                <React.Fragment>
                    <Header isMenu title={translate('module_performances')} />
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {((roles && roles.manageperformance.write && roles.manageperformance.write === true) ||
                                (Array.isArray(roles.manageperformance.write) &&
                                    roles.manageperformance.write.length > 0)) && (
                                <div className={classes.submenu}>
                                    <Typography className={classes.submenuTitle} variant="h6">
                                        {translate('manage_label_performances')}
                                    </Typography>
                                    <IconButton
                                        className={classes.button}
                                        icon="add"
                                        title={translate('management_button_add')}
                                        onClick={() => {
                                            persistDrawers('addPerformance')(true)
                                        }}
                                        disabled={checkFunctionalityDisabled(
                                            store,
                                            'add',
                                            'performances.functionality.management.performances'
                                        )}
                                    />
                                </div>
                            )}
                        </Grid>
                    </Grid>

                    <React.Fragment>
                        <AddPerformanceDrawer
                            history={history}
                            open={addPerformance}
                            onClose={() => persistDrawers('addPerformance')(false)}
                        />
                    </React.Fragment>
                </React.Fragment>
            )}
        </Translate>
    )
}

AddPerformanceView.propTypes = {
    classes: object.isRequired,
    persistDrawers: func.isRequired,
    drawers: object.isRequired,
    roles: object.isRequired,
    history: object.isRequired,
    store: object.isRequired,
}

export default AddPerformanceView
