import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DeleteDialog from 'components/DeleteDialog'
import deletePtcarType from 'modules/Manage/Services/deletePtcarType'

const DeletePtcarTypeDialog = props => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const handleDelete = async () => {
        setLoading(true)
        const response = await deletePtcarType(deleteData.Id)
        if (response) {
            props.reloadData()
            setLoading(false)
        } else {
            setLoading(false)
            setError(true)
        }
    }
    const onClosing = () => {
        setError(false)
        props.onDialogClose()
    }

    const { dialogOpen, deleteData, translate } = props
    return (
        <DeleteDialog
            dialogOpen={dialogOpen}
            onClose={onClosing}
            title={translate('manage_ptcar_types_delete_title')}
            translate={translate}
            handleDelete={handleDelete}
            error={error}
            errorMsg={translate('platform_delete_platform_dialog_error')}
            deleteMsg={translate('manage_ptcar_type_delete_msg')}
            deleteInfo={deleteData?.Id}
            loading={loading}
        />
    )
}
DeletePtcarTypeDialog.propTypes = {
    onDialogClose: PropTypes.func,
    translate: PropTypes.func,
    reloadData: PropTypes.func,
    dialogOpen: PropTypes.bool,
    deleteData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
export default DeletePtcarTypeDialog
