import React, { useEffect, useReducer } from 'react'
import ErrorMessage from 'components/ErrorMessage'
import Header from 'components/Header'
import Loading from 'components/Loading'
import BrakingDetailsReducer, { INITIAL_STATE } from './BrakingDetailsReducer'
import getBrakingDetailsPulledTrainsData from './Services/getBrakingDetailsPulledTrainsData'
import BrakingDetailsView from './BrakingDetailsView'
import BrakingDetailsActions from './BrakingDetailsActions'
import { withStyles } from '@material-ui/styles'
import { object } from 'prop-types'
import styles from './styles'
import { apiDateParser } from 'utils/dateParser'
import HeaderWithDatePicker from './HeaderWithDatePicker'
import { DateTime } from 'luxon'
import { TranslationContext } from 'context/translation'

const BrakingDetailsPulledTrains = props => {
    const [state, dispatch] = useReducer(BrakingDetailsReducer, INITIAL_STATE)
    const { translate } = React.useContext(TranslationContext)
    const loadBrakingPulledTrainsData = () => {
        dispatch({ type: BrakingDetailsActions.LOADING, payload: true })
        const brakingResponse = getBrakingDetailsPulledTrainsData(apiDateParser(state.date.toMillis()))
        brakingResponse
            .then(res => {
                dispatch({
                    type: BrakingDetailsActions.LOAD_VEHICLE_DATA,
                    payload: res.data,
                })
            })
            .catch(() => {
                dispatch({ type: BrakingDetailsActions.SET_ERROR, payload: true })
            })
    }
    useEffect(() => {
        if (state.realTime) loadBrakingPulledTrainsData()
    }, [state.realTime])
    const persist = type => value => {
        if (type === 'time') {
            dispatch({ type: BrakingDetailsActions.SET_DATE, payload: value })
        }
        if (type === 'date') {
            dispatch({ type: BrakingDetailsActions.REAL_TIME, payload: false })
            dispatch({
                type: BrakingDetailsActions.LOAD_VEHICLE_DATA,
                payload: null,
            })
            loadBrakingPulledTrainsData()
        }
    }
    const toggleRealTime = (button = false) => {
        if (button == 'button' && !state.realTime) {
            dispatch({ type: BrakingDetailsActions.SET_DATE, payload: DateTime.local() })
        }
        dispatch({ type: BrakingDetailsActions.REAL_TIME, payload: !state.realTime })
    }

    useEffect(() => {
        document.title = translate('braking_details_menu_item')
    }, [])
    let id = state?.vehicleData?.length > 0 ? state?.vehicleData[0]?.validityId : null
    return (
        <React.Fragment>
            <Header isMenu title={translate('braking_bulletin_header')} pageTitle="brakingPulledTrain" />
            <HeaderWithDatePicker
                persist={persist}
                realTime={state.realTime}
                startValue={state.date}
                translate={translate}
                identifier="brakingPulledTrain"
                trainNumber={state.trainNumber}
                validityId={id}
                toggleRealTime={toggleRealTime}
            />
            {state.loading && <Loading />}
            {state.error && <ErrorMessage error={'No Data'} spacing={false} />}
            {!state.loading && state.vehicleData && (
                <BrakingDetailsView translate={translate} vehicleData={state.vehicleData} classes={props.classes} />
            )}
        </React.Fragment>
    )
}
BrakingDetailsPulledTrains.propTypes = {
    classes: object,
    store: object,
}
export default withStyles(styles)(BrakingDetailsPulledTrains)
