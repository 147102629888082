import { createMuiTheme } from '@material-ui/core'
import blue from '@material-ui/core/colors/blue'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'

export const dark = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: blue,
        type: 'dark',
        secondary: red,
    },
    headerHeight: {
        sm: 56,
        md: 64,
    },
    languagePicker: blue['500'],
    moduleMenu: 300,
    readStatusBoxes: 500,
    readList: 452,
    performanceView: 324,
    taskList: 250,
    driveDetailList: 330,
    driveDetailsListForXl: 800,
    spacing: 8,
    customColors: {
        expansionBg: grey['900'],
        errorText: red['400'],
        lightGrey: '#979797',
        toast: '#979797',
        toolbar: '#212121',
        iconBg: 'black',
        iconFront: 'white',
        menuIconBg: '#424242',
        darkBox: '#424242',
        yellow: '#494332',
        darkRed: '#442A2A',
        detailGrey: '#424242',
        white: '#fafafa',
        black: 'black',
        primaryBlue: '#1976d2',
        versionYellow: 'yellow',
        green: 'green',
        red: 'red',
        orange: 'orange',
        grey: 'grey',
        whiteColor: 'white',
        blackColor: 'black',
        muicFont: '#424242',
        m510Background: 'white',
        perfRowBackGroudColor: 'none',
        roleOrgBackGround: grey['800'],
    },
})
