import React from 'react'
import PropTypes from 'prop-types'

import { sortBy } from 'modules/eSemes/esemes.util'
import { REDUCER_STATES, REDUCER_ACTIONS, REDUCER_REDUCERS } from './semes.reducer'

import { TranslationContext } from 'context/translation'

export const TaskSemesContext = React.createContext({})

const TaskSemesProvider = ({ children, data }) => {
    const [apiState, apiDispatch] = React.useReducer(REDUCER_REDUCERS.apiReducer, REDUCER_STATES.initialApiState)
    const [filterState, filterDispatch] = React.useReducer(REDUCER_REDUCERS.filterReducer, REDUCER_STATES.initialFilterState)

    const { activeLanguage } = React.useContext(TranslationContext)

    const { apiActions, filterActions } = REDUCER_ACTIONS

    const STATE = {
        filterState,
        apiState,
    }

    const METHODS = {
        toggleSortOrder(key) {
            filterDispatch({
                type: filterActions.sort,
                payload: {
                    dir: key === filterState.sort.key ? (filterState.sort.dir === 'asc' ? 'desc' : 'asc') : 'asc',
                    key: key,
                },
            })
            const sort = sortBy(
                apiState.eSemesData,
                key === filterState.sort.key ? (filterState.sort.dir === 'asc' ? 'desc' : 'asc') : 'asc',
                key,
                activeLanguage.code
            )
            apiDispatch({ type: apiActions.semes, payload: sort })
        },
    }

    React.useEffect(() => {
        apiDispatch({
            type: apiActions.semes,
            payload: data,
        })
    }, [])

    return <TaskSemesContext.Provider value={{ STATE, METHODS }}>{children}</TaskSemesContext.Provider>
}

TaskSemesProvider.propTypes = {
    children: PropTypes.element.isRequired,
    data: PropTypes.array,
}

export default TaskSemesProvider
