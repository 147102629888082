/* eslint-disable no-unused-vars */
import { blue, grey } from '@material-ui/core/colors'

export default theme => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '70px 50px 1fr 1fr 1fr 1fr',
        alignItems: 'center',
        gridGap: '5px',
    },
    title: {
        fontWeight: 'bold',
    },
    row: {
        display: 'grid',
        gridTemplateColumns: '70px 50px 1fr 1fr 1fr 1fr',
        alignItems: 'center',
        gridGap: '5px',
    },
    listView: {
        margin: theme.spacing(2),
        backgroundColor: `${grey['800']}`,
    },
    semesTitle: {
        fontWeight: 'bold',
        margin: 0,
    },
    semesTime: {
        margin: 0,
        display: 'block',
    },
    colorSpan: {
        display: 'block',
        width: '50px',
        height: '50px',
        lineHeight: '50px',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '12px',
    },
    redPercentage: {
        color: 'red',
    },
    acco: {
        background: 'none',
        borderTop: 'solid 1px grey',
        boxShadow: 'none',
        '& .Mui-disabled': {
            opacity: 100,
        },
    },
    accoSum: {
        padding: 0,
        width: '100%',
    },
    extraPadding: {
        paddingRight: '40px',
    },
    noIcon: {
        display: 'block',
        width: '23px',
    },
    summary: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        padding: '10px 25px',
        borderBottom: 'solid 1px grey',
        backgroundColor: theme.palette.grey['800'],
    },
    loading: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        padding: '10px 25px',
        borderBottom: 'solid 1px grey',
        backgroundColor: theme.palette.grey['800'],
    },
    summaryGrid: {
        margin: '0',
    },
    summaryTitle: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        padding: '10px 25px',
        borderBottom: 'solid 1px grey',
        backgroundColor: theme.palette.grey['800'],
    },
    error: {
        display: 'block',
        width: '100%',
        textAlign: 'center',
    },
})
