export default theme => ({
    documentTableWrapper: {
        padding: `0 ${theme.spacing(3)}px`,
        [theme.breakpoints.down('xs')]: {
            padding: `0 ${theme.spacing(1)}px`,
        },
        width: '100%',
        minHeight: theme.driveDetailList,
        height: `calc(100vh - ${theme.performanceView}px)`,
    },
    tableRow: {
        backgroundColor: 'transparent',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingTop: '4px',
        },
        width: '100%',
        borderColor: theme.customColors.grey,
        borderWidth: '1px',
    },
    actionsTableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
        borderWidth: '1px',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    statusDone: {
        background: 'rgba(76,175,80, 0.2)',
    },
    statusStarted: {
        background: 'rgba(3,169,244, 0.2)',
    },
    statusAbolished: {
        background: 'rgba(244,67,54, 0.2)',
    },
    remark: {
        background: 'yellow',
    },
    table: {
        marginBottom: '100px',
    },
    delActionIcon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        color: theme.customColors.red,
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
    abolishActionIcon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer',

        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
    multipleIcons: {
        display: 'flex',
        alignContent: 'space-between',
        right: '0',
        position: 'fixed',
        paddingRight: '48px',

        [theme.breakpoints.down('md')]: {
            paddingRight: '24px',
        },
        [theme.breakpoints.down('sm')]: {
            paddingRight: '0px',
        },
    },
    checkBox: {
        paddingTop: 0,
    },
    customFont: {
        fontFamily: 'nmbs',
    },
})
