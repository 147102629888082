export default theme => ({
    drawerRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
        '@media (max-width: 577px)': {
            width: '85vw',
            padding: `${theme.spacing(1)}px`,
        },
    },
    readList: {
        maxHeight: `calc(100vh - ${theme.readList + 116}px)`,
        overflowY: 'auto',
        marginBottom: theme.spacing(2),
    },
    profileInfo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    profileInfoTitle: {
        fontWeight: 'bold',
    },
    topSpacingTitle: {
        marginTop: theme.spacing(3),
    },
    button: {
        marginTop: theme.spacing(3),
    },
    error: {
        color: theme.customColors.red,
    },
    content: { width: '100%', justifyContent: 'space-between' },
    expansionPanelDetails: {
        backgroundColor: theme.customColors.expansionBg,
        display: 'block',
    },
    backgroundPaper: {
        marginTop: '18px',
        background: theme.customColors.roleOrgBackGround,
    },
    noBackgroundPaper: {
        marginTop: '8px',
        marginBottom: '18px',
    },
    noPadButton: {
        padding: '0',
    },
    withMarginButton: {
        padding: '0',
        marginTop: '3px',
        '&:hover': {
            background: theme.customColors.lightGrey,
        },
    },
    buttonContainer: {
        marginTop: '18px',
        display: 'flex',
        justifyContent: 'space-between',
    },
})
