import React from 'react'
import { string, func, any, object } from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import { TranslationContext } from 'context/translation'

const PerformancesFilter = props => {
    const [isTablet, setIsTablet] = React.useState(window.matchMedia('(max-width:1920px)').matches)
    const { translate } = React.useContext(TranslationContext)

    React.useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })

    const handleResize = () => {
        setIsTablet(window.matchMedia('(max-width:1920px)').matches)
    }

    const { filterValue, handleFilterValue, colorValues, classes } = props
    return (
        <div data-testid="PerformancesFilter-root" className={isTablet ? classes.tabletContainer : classes.container}>
            <input
                className={classes.customInput}
                placeholder={translate('search')}
                value={filterValue}
                onChange={e => handleFilterValue(e)}
            />
            <div
                data-testid="PerformancesFilter-child"
                className={isTablet ? classes.tabletLabelContainer : classes.labelContainer}
            >
                {colorValues && colorValues.red !== 0 && (
                    <Typography>
                        <span className={classes.roundedRed}>{colorValues.red}</span>
                        {` performances`}
                    </Typography>
                )}
                {colorValues && colorValues.yellow !== 0 && (
                    <Typography className={isTablet ? classes.tabletYellowLabelContainer : classes.yellowLabelMargin}>
                        <span className={classes.roundedYellow}>{colorValues.yellow}</span>
                        {` performances`}
                    </Typography>
                )}
            </div>
        </div>
    )
}

PerformancesFilter.propTypes = {
    filterValue: string.isRequired,
    handleFilterValue: func.isRequired,
    colorValues: object,
    classes: any.isRequired,
}

export default withStyles(styles)(PerformancesFilter)
