import React from 'react'
import { object } from 'prop-types'
import { withStore } from 'react-ion-store'
import { compose } from 'recompose'

import DistrictsMapper from 'components/DistrictsMapper'
import DistrictsView from './DistrictsView'
import { isPerformanceMenuAllowed } from 'utils/isAgendaAllowed'
const storeKey = 'mappedDistrictData'

class Districts extends React.Component {
    static propTypes = {
        store: object.isRequired,
        history: object.isRequired,
    }

    selectAllDepots = filteredDistrictData => {
        let returnValue = []
        filteredDistrictData.tcts.map(tct =>
            tct.depots.map(depot => {
                return returnValue.push(depot.id)
            })
        )
        return returnValue
    }
    componentDidMount() {
        document.title = 'Select District'
    }

    handleChooseDistrict = (e, selectedDistrict) => {
        e.preventDefault()
        const { store, history } = this.props
        const mappedDistrictData = store.get('mappedDistrictData')

        const filteredDistrictData = mappedDistrictData.find(district => district.id === selectedDistrict)
        const selectedTCTs = filteredDistrictData ? filteredDistrictData.tcts.map(tct => tct.id) : []

        let depotsArray = this.selectAllDepots(filteredDistrictData)
        depotsArray.push('performances_label_all_depots')

        store.set({ selectedDistrict, selectedTCTs, selectedDepot: depotsArray })
        history.push('/performances')
    }

    render() {
        let districts = []
        if (!isPerformanceMenuAllowed(this.props.store.get('userRoles'))) districts = this.props.store.get(storeKey) || []

        return (
            <DistrictsMapper>
                <DistrictsView chooseDistrict={this.handleChooseDistrict} districts={districts} />
            </DistrictsMapper>
        )
    }
}

export default compose(withStore)(Districts)
