export default theme => ({
    contentDiv: {
        padding: theme.spacing(1) * 2,
    },
    table: {
        border: '1px solid black',
        padding: 0,
        borderSpacing: 0,
    },
    tableFixed: {
        tableLayout: 'fixed',
        border: '1px solid black',
        padding: 0,
        borderSpacing: 0,
        wordWrap: 'break-word',
    },
    th: {
        backgroundColor: '#DDDDDD',
        border: '1px solid black',
        padding: theme.spacing(1),
    },
    td: {
        border: '1px solid black',
        padding: theme.spacing(1),
    },
    OK: {
        backgroundColor: 'green',
        opacity: 0.75,
    },
    WARNING: {
        backgroundColor: 'yellow',
        opacity: 0.75,
    },
    ERROR: {
        backgroundColor: theme.customColors.red,
        opacity: 0.75,
    },
    darkText: {
        color: 'black',
    },
    withIcon: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
    },
})
