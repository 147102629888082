import { grey, blue } from '@material-ui/core/colors'

export default theme => ({
    documentTableWrapper: {
        padding: `0 ${theme.spacing(3)}px`,
        width: '100%',
        minHeight: theme.readList,
        height: `calc(100vh - 210px)`,
    },
    tableRow: {
        backgroundColor: 'transparent',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
        alignContent: 'center',
    },
    tableActionCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
        alignContent: 'center',
    },
    header: {
        backgroundColor: theme.customColors.lightGrey,
    },
    editIcon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        color: theme.customColors.white,
        '&:hover': {
            color: theme.customColors.grey,
        },
    },
    parentDiv: {
        justifyContent: 'space-between',
        padding: `${theme.spacing(3)}px`,
    },
    left: { width: '100%', display: 'flex' },
    right: { width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: '13px', alignItems: 'end' },
    customInput: {
        backgroundColor: grey['600'],
        height: '35px',
        minWidth: '200px',
        border: `2px solid ${grey['700']}`,
        fontSize: '14px',
        color: theme.customColors.white,
        fontFamily: 'roboto',
        paddingLeft: '8px',
        '&::placeholder': {
            color: `${grey['400']}`,
        },
        '&:focus': {
            outline: 'none',
            borderBottom: `2px solid ${blue['500']}`,
        },
    },
    customInputDisabled: {
        backgroundColor: theme.customColors.darkBox,
        height: '35px',
        minWidth: '200px',
        border: `none`,
        fontSize: '14px',
        color: theme.customColors.white,
        fontFamily: 'roboto',
        paddingLeft: '8px',
    },
    multipleIcons: {
        display: 'flex',
        alignContent: 'space-between',
    },
})
