import React from 'react'
import soundFile from './hollow.mp3'
import PropTypes from 'prop-types'

const MusicPlayer = props => {
    let storedSettings = localStorage.getItem('userSettings') ? JSON.parse(localStorage.getItem('userSettings')) : false
    if (storedSettings) {
        let isSoundEnabled = storedSettings.filter(setting => setting.pageReference === props.identifier)[0]?.isWarningSound
        if (isSoundEnabled) return <audio autoPlay src={soundFile} />
        else return null
    }
    return null
}
MusicPlayer.propTypes = {
    identifier: PropTypes.string,
}
export default MusicPlayer
