import React from 'react'
import { array, string, node, bool, func, object } from 'prop-types'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'

import styles from './styles'

class AppDialog extends React.Component {
    static propTypes = {
        buttons: array.isRequired,
        classes: object.isRequired,
        children: node.isRequired,
        isOpen: bool.isRequired,
        onClose: func.isRequired,
        title: string.isRequired,
    }

    renderButtons = () => {
        const { buttons, classes } = this.props
        return buttons.map(btn => (
            <Button
                color="primary"
                data-testid="primaryButton"
                key={btn.title}
                onClick={btn.action}
                className={classes.actionButton}
                disabled={btn.disabled}
            >
                {btn.title}
            </Button>
        ))
    }

    render() {
        const { classes, children, isOpen, onClose, title } = this.props
        return (
            <Dialog
                data-testid="root"
                classes={{ paper: classes.root }}
                onClose={onClose}
                open={isOpen}
                PaperProps={{ square: true }}
            >
                <Paper className={classes.header} elevation={0} square>
                    <DialogTitle data-testid="dialogTitle">{title}</DialogTitle>
                    <IconButton data-testid="iconButton" className={classes.closeButton} component="span" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Paper>

                {children}

                <DialogActions data-testid="dialogActions">{this.renderButtons()}</DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(AppDialog)
