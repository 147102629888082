export default theme => ({
    documentTableWrapper: {
        padding: `0 ${theme.spacing(3)}px`,
        width: '100%',
        minHeight: theme.readList,
        height: `calc(100vh - ${theme.performanceView}px)`,
        // marginBottom: '100px',
    },
    tableRow: {
        backgroundColor: 'transparent',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
    },
    table: {
        marginBottom: '100px',
    },
    syncIcon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        color: '#66bb6a',
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
})
