import React from 'react'
import { object, func, string, bool } from 'prop-types'
import { compose } from 'recompose'
import some from 'lodash/some'
import { withStore } from 'react-ion-store'
import { withLocalize, Translate } from 'react-localize-redux'

import SelectInput from 'components/SelectInput'

import { Paper } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
const storeKey = 'mappedDistrictData'

class SearchBarTrainDriver extends React.Component {
    static propTypes = {
        handleEvent: func.isRequired,
        persist: func.isRequired,
        className: string.isRequired,
        store: object.isRequired,
        org: string.isRequired,
        selectedProfile: object.isRequired,
        depot: string.isRequired,
        disabled: bool,
    }

    setName = selectedProfile => this.props.persist('idf')(selectedProfile)

    clearName = () => this.props.persist('idf')({})

    render() {
        const { store, handleEvent, disabled } = this.props

        const districts = store.get(storeKey) || []
        const tcts = districts.reduce(
            (aggregrate, district) => [...aggregrate, ...district.tcts.map(tct => ({ id: `${tct.tctId}`, text: tct.id }))],
            [{ id: ' ', text: 'documents_label_select_org' }]
        )

        const chosenDistrict = districts.find(district => some(district.tcts, { tctId: parseInt(this.props.org, 10) })) || {
            tcts: [],
        }
        const chosenTct = chosenDistrict.tcts.find(tct => tct.tctId === parseInt(this.props.org, 10)) || {
            depots: [],
        }
        const depots = chosenTct.depots.reduce(
            (aggregate, depot) => [...aggregate, { id: `${depot.depotId}`, text: depot.id }],
            [{ id: ' ', text: 'documents_label_select_depot' }]
        )

        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Paper>
                        <Translate>
                            {({ translate }) => (
                                <div style={{ display: 'block', padding: '16px' }}>
                                    <SelectInput
                                        sorted
                                        name="org"
                                        items={tcts.map(tct => ({ ...tct, text: translate(tct.text) }))}
                                        value={this.props.org.toString()}
                                        onChange={handleEvent('org')}
                                        disabled={disabled}
                                    />
                                </div>
                            )}
                        </Translate>
                    </Paper>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '16px' }}>
                    <Paper>
                        <Translate>
                            {({ translate }) => (
                                <div style={{ display: 'block', padding: '16px' }}>
                                    <SelectInput
                                        sorted
                                        name="depot"
                                        items={depots.map(depot => ({ ...depot, text: translate(depot.text) }))}
                                        value={this.props.org === ' ' ? ' ' : this.props.depot.toString()}
                                        onChange={handleEvent('depot')}
                                        disabled={disabled}
                                    />
                                </div>
                            )}
                        </Translate>
                    </Paper>
                </Grid>
            </React.Fragment>
        )
    }
}

export default compose(withStore, withLocalize)(SearchBarTrainDriver)
