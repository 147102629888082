export default theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        color: theme.customColors.lightGrey,
        backgroundColor: theme === 'dark' ? theme.detailGrey : theme.black,
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            minWidth: '50vw',
        },
        zIndex: '3',
    },
})
