import React from 'react'
import Footer from './Footer'
import styles from './styles'
import { withStyles } from '@material-ui/styles'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'

const FooterView = props => {
    return <Footer {...props} />
}
export default compose(withLocalize, withStyles(styles))(FooterView)
