export default (currentTime, roles = []) =>
    // eslint-disable-next-line array-callback-return
    roles.filter(role => {
        const filtered = role.organisations.filter(org => org.validFrom <= currentTime && org.validTo > currentTime)

        if (filtered.length > 0) {
            role.organisations = filtered
            return role
        }
    })
