import React from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { Typography } from '@material-ui/core'

import ErrorMessage from 'components/ErrorMessage'
import AppDialog from 'components/AppDialog'

const DeleteDialog = props => {
    const { dialogOpen, deleteInfo, translate, onClose, handleDelete, loading, error, errorMsg, title, deleteMsg } = props
    return (
        <AppDialog
            isOpen={dialogOpen}
            onClose={onClose}
            title={title}
            buttons={[
                {
                    title: translate('manage_switch_dialog_cancel'),
                    action: onClose,
                },
                {
                    title: translate('add_user_delete'),
                    action: handleDelete,
                    disabled: loading,
                },
            ]}
        >
            <DialogContent style={{ minWidth: '600px' }}>
                <DialogContentText>{deleteMsg}</DialogContentText>
                <Typography variant="subtitle1">{deleteInfo}</Typography>
                {error && <ErrorMessage error={errorMsg} />}
            </DialogContent>
        </AppDialog>
    )
}
DeleteDialog.propTypes = {
    onClose: PropTypes.func,
    translate: PropTypes.func,
    reloadData: PropTypes.func,
    dialogOpen: PropTypes.bool,
    loading: PropTypes.bool,
    handleDelete: PropTypes.func,
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    title: PropTypes.string,
    deleteMsg: PropTypes.string,
    deleteInfo: PropTypes.string,
}
export default DeleteDialog
