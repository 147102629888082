import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
const getPerformanceTasks = async (perf, idf) => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/performance/${perf}/detail?idfNumber=${idf}`
    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            return ''
        }
        if (response.status === 200) {
            return response.data
        }
    } catch (e) {
        console.error(e) // eslint-disable-line

        return ''
    }
}
export default getPerformanceTasks
