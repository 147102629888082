import { white } from 'ansi-colors'

export default theme => ({
    districtBody: {
        width: '100%',
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: white,
    },
    header: {
        backgroundColor: '#303030',
    },
    actionButton: {
        marginLeft: theme.spacing(2),
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(2),
    },

    scrollPaper: {
        alignItems: 'center',
    },
    [theme.breakpoints.down('md')]: {
        scrollPaper: {
            alignItems: 'start',
        },
    },
})
