import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import axios from 'axios'
import some from 'lodash/some'
import { Translate } from 'react-localize-redux'
import { object, func, string, bool, array } from 'prop-types'

import { withLocalize } from 'react-localize-redux'

import SelectInput from 'components/SelectInput'

import PerformanceSelect from './performanceSelect'

import mapKeysToLowerCase from 'utils/mapKeysToLowerCase'
import mapApiTranslations from 'translations/mapApiTranslations'

const storeKey = 'mappedDistrictDataNoFilter'
const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export class ReferencedPerformance extends Component {
    static propTypes = {
        persist: func.isRequired,
        addTranslation: func,
        translate: func,
        store: object,
        classes: object,
        referenceOrg: string,
        taskType: string,
        referenceDepot: string,
        selectedReferencePerformanceNumber: string,
        loadingReferencePerformance: bool,
        loadReferencePerformanceNumbers: func,
        referencePerformanceNumbersData: array,
    }

    handleEvent = type => e => this.props.persist(type)(e.target.value)

    constructor() {
        super()
        this.state = {
            loadNonFiltered: false,
            nonFilterData: [],
        }
    }

    checkPerformanceManager = userRoles => {
        // this returns true for performanceManagers and admins
        let returnValue = false
        userRoles.forEach(role => {
            if (role.portalUserRoleId === 0) {
                returnValue = true
            }
            if (role.portalUserRoleId === 3) {
                returnValue = true
            }
        })
        return returnValue
    }

    doCall = async () => {
        this.setState({ loadNonFiltered: true })

        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/legacyReferenceData/districts?filterByRoles=false`

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            if (response.status === 204) {
                this.setState({ loadNonFiltered: false, nonFilterData: [] })

                return true
            }
            if (response.status === 200) {
                this.setState({ loadNonFiltered: false, nonFilterData: this.mapDistrictData(response.data) })

                return true
            }
        } catch (e) {
            this.setState({ loadNonFiltered: false })
            console.error(e) // eslint-disable-line
        }
    }

    returnNonFiltered = async () => {
        const data = await this.doCall()
        return data
    }

    mapDistrictData = districts => {
        return districts.map(district => {
            const { id, name, tcts } = mapKeysToLowerCase(district)

            this.props.addTranslation(mapApiTranslations({ id, translations: name }))
            return { id, tcts: this.mapTCTData(tcts) }
        })
    }

    mapTCTData = tcts => {
        return tcts.map(tct => {
            const { id, name, symbolicName, tctId, depots } = mapKeysToLowerCase(tct)

            this.props.addTranslation(mapApiTranslations({ id, translations: name }))
            return {
                id,
                symbolicName,
                tctId,
                depots: this.mapDepotData(depots),
            }
        })
    }

    mapDepotData = depots => {
        return depots.map(depot => {
            const { depotId, name, symbolicName } = mapKeysToLowerCase(depot)

            const id = `DEPOT-${symbolicName}`
            this.props.addTranslation(mapApiTranslations({ id, translations: name }))
            return {
                id,
                depotId,
                symbolicName,
            }
        })
    }

    render() {
        const {
            store,
            classes,
            translate,
            taskType,
            referenceDepot,
            referenceOrg,
            selectedReferencePerformanceNumber,
            loadingReferencePerformance,
            loadReferencePerformanceNumbers,
            referencePerformanceNumbersData,
        } = this.props

        const userRoles = store.get('userRoles') || []
        let districts = []

        if (taskType === 'AFREL' && this.checkPerformanceManager(userRoles)) {
            if (this.state.nonFilterData.length === 0 && !this.state.loadNonFiltered) {
                this.returnNonFiltered()
            }
            districts = this.state.nonFilterData
        } else {
            districts = store.get(storeKey) || []
        }

        const tcts = districts.reduce(
            (aggregrate, district) => [...aggregrate, ...district.tcts.map(tct => ({ id: `${tct.tctId}`, text: tct.id }))],
            [{ id: ' ', text: 'documents_label_select_org' }]
        )

        let chosenDistrict = districts.find(district => some(district.tcts, { tctId: parseInt(referenceOrg, 10) })) || {
            tcts: [],
        }

        const chosenTct = chosenDistrict.tcts.find(tct => tct.tctId === parseInt(referenceOrg, 10)) || {
            depots: [],
        }

        const depots = chosenTct.depots.reduce(
            (aggregate, depot) => [...aggregate, { id: `${depot.depotId}`, text: depot.id }],
            [{ id: ' ', text: 'documents_label_select_depot' }]
        )

        return !this.state.loadNonFiltered ? (
            <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant="h6">{translate('performance_task_add_label_select_performance')}</Typography>
                </Grid>
                <React.Fragment>
                    <Grid item xs={4}>
                        <Translate>
                            {({ translate }) => (
                                <div style={{ display: 'inline-block' }}>
                                    <SelectInput
                                        sorted
                                        name="referenceOrg"
                                        classes={classes}
                                        items={tcts.map(tct => ({ ...tct, text: translate(tct.text) }))}
                                        value={referenceOrg}
                                        onChange={this.handleEvent('referenceOrg')}
                                    />
                                </div>
                            )}
                        </Translate>
                    </Grid>
                    <Grid item xs={4}>
                        <Translate>
                            {({ translate }) => (
                                <div style={{ display: 'inline-block' }}>
                                    <SelectInput
                                        sorted
                                        name="referenceDepot"
                                        classes={classes}
                                        items={depots.map(depot => ({ ...depot, text: translate(depot.text) }))}
                                        value={referenceOrg === ' ' ? ' ' : referenceDepot}
                                        onChange={this.handleEvent('referenceDepot')}
                                    />
                                </div>
                            )}
                        </Translate>
                    </Grid>
                    <Grid item xs={4}>
                        {referenceDepot !== ' ' && referenceDepot && (
                            <PerformanceSelect
                                depot={referenceDepot}
                                handleEvent={this.handleEvent}
                                selectedReferencePerformanceNumber={selectedReferencePerformanceNumber}
                                loadingReferencePerformance={loadingReferencePerformance}
                                loadReferencePerformanceNumbers={loadReferencePerformanceNumbers}
                                referencePerformanceNumbersData={referencePerformanceNumbersData}
                                referenceDepot={referenceDepot}
                                classes={classes}
                                depots={chosenTct}
                            />
                        )}
                    </Grid>
                </React.Fragment>
            </React.Fragment>
        ) : (
            <Typography>Loading...</Typography>
        )
    }
}

export default compose(withStore, withLocalize)(ReferencedPerformance)
