import React from 'react'
import { oneOfType, object, bool, func, string, number } from 'prop-types'
import { compose } from 'recompose'
import { withStore, withStatus } from 'react-ion-store'

// Components
import DocumentActionDrawerView from './DocumentActionDrawerView'

class DocumentActionDrawer extends React.Component {
    static propTypes = {
        performanceInfo: oneOfType([object, bool]),
        close: func,
        store: object.isRequired,
        persist: func,
        org: string,
        depot: string,
        changeRadio: func,
        selectedRadio: number,
        read: bool,
        unread: bool,
        handleCheckbox: func,
        setName: func,
        selectedName: string,
        clearName: func,
        file: string,
        onClick: func,
        buttonDisabled: bool,
        initCall: func,
        setDate: func,
        open: bool,
    }

    render() {
        const {
            performanceInfo,
            persist,
            changeRadio,
            selectedRadio,
            read,
            unread,
            handleCheckbox,
            setName,
            selectedName,
            clearName,
            file,
            onClick,
            buttonDisabled,
            initCall,
            setDate,
            open,
        } = this.props

        return (
            <DocumentActionDrawerView
                {...this.props}
                persist={persist}
                isOpen={open}
                performanceInfo={performanceInfo}
                initCall={initCall}
                changeRadio={changeRadio}
                selectedRadio={selectedRadio}
                read={read}
                unread={unread}
                handleCheckbox={handleCheckbox}
                setName={setName}
                selectedName={selectedName}
                clearName={clearName}
                file={file}
                onClick={onClick}
                buttonDisabled={buttonDisabled}
                setDate={setDate}
            />
        )
    }
}

export default compose(withStore, withStatus)(DocumentActionDrawer)
