import React from 'react'
import { object, bool, func, string } from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import VacmaRestrictionForm from './VacmaRestrictionForm'

const VacmaRestrictionDrawer = props => {
    const { isOpen, onClose, editDrawer, classes, translate, addEditError } = props

    return (
        <Drawer open={isOpen} anchor="right" onClose={onClose}>
            <div className={classes.drawerRoot}>
                <Typography variant="h6">
                    {editDrawer
                        ? translate('compositions_edit_vacma_drawer_title')
                        : translate('compositions_add_vacma_drawer_title')}
                </Typography>
                <VacmaRestrictionForm error={addEditError} {...props} />
            </div>
        </Drawer>
    )
}

VacmaRestrictionDrawer.propTypes = {
    classes: object.isRequired,
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    translate: func,
    editDrawer: bool,
    addEditError: string,
}

export default VacmaRestrictionDrawer
