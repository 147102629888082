import React from 'react'
import { object, bool, func, string } from 'prop-types'
import { Translate } from 'react-localize-redux'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import AddPerformance from './AddPerformance'
import PrimaryButton from 'components/PrimaryButton'
import ErrorMessage from 'components/ErrorMessage'
import AddDriverToPerformance from './AddDriverToPerformance'
import { DateAndTimePickerWithKeyboard } from 'components/Pickers'

import { Grid, Switch } from '@material-ui/core'

const AddPerformanceDrawerView = ({
    classes,
    open,
    onClose,
    onStartTimeChange,
    plannedStartTime,
    onEndTimeChange,
    plannedEndTime,
    onClick,
    persist,
    referenceDepot,
    referenceOrg,
    buttonDisabled,
    errorMsg,
    setName,
    selectedName,
    clearName,
    performanceType,
    isThirdPartyPerformance,
    onThirdPartyPerformanceChange,
}) => {
    return (
        <Drawer open={open} anchor="right" onClose={onClose}>
            <Grid container spacing={2} style={{ width: 500, margin: 16 }}>
                <Typography variant="h6">
                    <Translate id="add_performance" />
                </Typography>
            </Grid>
            <div className={classes.drawerRoot}>
                <div>
                    <Typography variant="h6">
                        <Translate id="search_label_org" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <AddPerformance
                        classes={classes}
                        persist={persist}
                        referenceOrg={referenceOrg}
                        referenceDepot={referenceDepot}
                        performanceType={performanceType}
                    />
                    <AddDriverToPerformance clearName={clearName} setName={setName} selectedName={selectedName} />
                    <Typography variant="h6" style={{ marginTop: 16 }}>
                        <Translate id="date_lable" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <Translate>
                        {({ translate }) => (
                            <div style={{ height: '70px' }}>
                                <div className={classes.column}>
                                    <DateAndTimePickerWithKeyboard
                                        disablePast={true}
                                        format="dd/MM/yyyy HH:mm"
                                        openTo={'hours'}
                                        ampm={false}
                                        label={translate('start_time')}
                                        value={plannedStartTime}
                                        onChange={onStartTimeChange}
                                    />
                                </div>
                                <div className={classes.column}>
                                    <DateAndTimePickerWithKeyboard
                                        disablePast={true}
                                        format="dd/MM/yyyy HH:mm"
                                        openTo={'hours'}
                                        ampm={false}
                                        label={translate('end_time')}
                                        value={plannedEndTime}
                                        onChange={onEndTimeChange}
                                    />
                                </div>
                            </div>
                        )}
                    </Translate>
                    <Typography variant="h6" style={{ marginTop: 16 }}>
                        Third Party Performance
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    {performanceType !== 'ABSENCES' ? (
                        <>
                            Off
                            <Switch
                                color="primary"
                                checked={isThirdPartyPerformance}
                                onChange={e => onThirdPartyPerformanceChange(e.target.checked)}
                            />
                            On
                        </>
                    ) : null}
                </div>
                {errorMsg && errorMsg.length !== 0 && <ErrorMessage spacing={false} error={errorMsg} />}
            </div>
            <div>
                <Translate>
                    {({ translate }) => (
                        <PrimaryButton
                            className={classes.buttonBottom}
                            title={translate('button_commit_change')}
                            onClick={onClick}
                            disabled={buttonDisabled}
                        />
                    )}
                </Translate>
            </div>
        </Drawer>
    )
}

AddPerformanceDrawerView.propTypes = {
    classes: object.isRequired,
    open: bool.isRequired,
    onClose: func.isRequired,
    selectedName: string,
    selectedProfile: object,
    persist: func.isRequired,
    onClick: func.isRequired,
    plannedStartTime: object.isRequired,
    plannedEndTime: object.isRequired,
    onStartTimeChange: func.isRequired,
    onEndTimeChange: func.isRequired,
    referenceDepot: string.isRequired,
    performanceType: string,
    referenceOrg: string.isRequired,
    setSelectedDepot: string,
    buttonDisabled: bool.isRequired,
    errorMsg: string.isRequired,
    setName: func.isRequired,
    clearName: func.isRequired,
    isThirdPartyPerformance: bool.isRequired,
    onThirdPartyPerformanceChange: func.isRequired,
}

export default AddPerformanceDrawerView
