import React from 'react'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'

import { func, object, string, number, array } from 'prop-types'
import classNames from 'classnames'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'

import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { dateTimeFromEpochSeconds } from 'utils/dateParser'

import StatusLabel from 'components/StatusLabel'
import PerformanceByTrainTable from '../PerformanceByTrainTable'
import ThemedTyphography from 'components/ThemedTyphography'

const columns = [
    {
        width: 300,
        label: 'datatable_header_perfByTrain_date',
        dataKey: 'date',
    },
    {
        width: 200,
        label: 'performances_label_performance_detail_train_number',
        dataKey: 'trainNumber',
    },
    {
        width: 250,
        label: 'performance_info_label_from',
        dataKey: 'from',
    },
    {
        width: 250,
        label: 'performances_label_performance_detail_diagram_number',
        dataKey: 'diagramNumber',
    },
    {
        width: 250,
        label: 'performance_info_label_till',
        dataKey: 'to',
    },

    {
        width: 200,
        label: 'datatable_header_performance',
        dataKey: 'performanceNumber',
    },
    {
        width: 250,
        label: 'datatable_header_task_status',
        dataKey: 'status',
    },
]

function PerformanceByTrainListView(props) {
    let defaultHeaderHeight = 56
    let defaultRowHeight = 56
    const getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    const headerRenderer = headerProps => {
        const { classes, headerHeight } = props
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: headerHeight || defaultHeaderHeight,
                    paddingTop: 34,
                    paddingBottom: 34,
                    paddingRight: 0,
                }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }
    const getVehicleNumber = rowData => {
        return rowData.materials.reduce((accumulator, currentValue) => {
            let vNumber = currentValue.vehicleNumber ? currentValue.vehicleNumber : currentValue?.diagramNumber
            return vNumber ? `${accumulator + vNumber} ` : accumulator
        }, '')
    }

    const cellRenderer = cellProps => {
        const { classes } = props
        const { dataKey, rowData } = cellProps
        let task = rowData.task
        let isRedBG = rowData.status === 2
        let isYellowBG = rowData.status === 1

        let row = null
        if (dataKey === 'date') {
            row = (
                <ThemedTyphography
                    isRedBG={isRedBG}
                    data={dateTimeFromEpochSeconds(task.plannedStartDateTime)}
                ></ThemedTyphography>
            )
        }
        if (dataKey === 'trainNumber') {
            row = (
                <>
                    <ThemedTyphography isRedBG={isRedBG} data={task.trainComposed}></ThemedTyphography>
                    <ThemedTyphography isRedBG={isRedBG} data={task.code}></ThemedTyphography>
                </>
            )
        }
        if (dataKey === 'from') {
            row = (
                <ThemedTyphography
                    isRedBG={isRedBG}
                    data={
                        task.code === 'AFREL'
                            ? rowData.task.referencedPerformance?.performanceId
                            : task?.code === 'VK.PC'
                            ? task?.taskInfo
                            : task?.locationFrom
                    }
                ></ThemedTyphography>
            )
        }
        if (dataKey === 'diagramNumber') {
            let vehicleNumber = getVehicleNumber(rowData.task)
            let diagramNumber = vehicleNumber?.length ? vehicleNumber : rowData.task['diagramNumber']
            row = <ThemedTyphography isRedBG={isRedBG} data={diagramNumber}></ThemedTyphography>
        }
        if (dataKey === 'to') {
            row = <ThemedTyphography isRedBG={isRedBG} data={rowData.task.locationTo}></ThemedTyphography>
        }
        if (dataKey === 'performanceNumber') {
            row = (
                <ThemedTyphography
                    isRedBG={isRedBG}
                    data={`${rowData.depot} ${rowData.performanceNumber}`}
                ></ThemedTyphography>
            )
        }
        if (dataKey === 'status') {
            row = (
                <StatusLabel
                    disableButtons={true}
                    status={rowData.task[dataKey]}
                    isAbolished={rowData.task['isAbolished']}
                />
            )
        }

        return (
            <TableCell
                className={classNames(classes.tableCell, {
                    [classes.tableRowRed]: isRedBG,
                    [classes.tableRowYellow]: isYellowBG,
                })}
                component="div"
                variant="body"
                style={{ height: props.rowHeight || defaultRowHeight }}
            >
                {row}
            </TableCell>
        )
    }

    const { classes, data, onRowClick } = props
    return (
        <div>
            <PerformanceByTrainTable
                columns={columns}
                data={data}
                classes={classes}
                headerRenderer={headerRenderer}
                cellRenderer={cellRenderer}
                getRowClassName={getRowClassName}
                rowClickHandler={onRowClick}
            />
        </div>
    )
}
PerformanceByTrainListView.propTypes = {
    classes: object.isRequired,
    rowClassName: string,
    onRowClick: func,
    headerHeight: number,
    rowHeight: number,
    history: object,
    match: object,
    data: array,
    store: object,
}

export default compose(withStore, withRouter)(PerformanceByTrainListView)
