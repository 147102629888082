import React from 'react'
import { object, bool, func, string } from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import DefectTypeForm from './DefectTypeForm'
import { TranslationContext } from 'context/translation'

const AddDefectTypeDrawer = props => {
    const { isOpen, onClose, editDrawer, classes, addError } = props
    const { translate } = React.useContext(TranslationContext)

    return (
        <Drawer open={isOpen} anchor="right" onClose={onClose}>
            <div className={classes.drawerRoot}>
                <Typography variant="h6">
                    {editDrawer ? translate('compos_drawer_edit_defect_type') : translate('compos_drawer_add_defect_type')}
                </Typography>
                <DefectTypeForm error={addError} {...props} />
            </div>
        </Drawer>
    )
}

AddDefectTypeDrawer.propTypes = {
    classes: object.isRequired,
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    editDrawer: bool,
    addError: string,
}

export default AddDefectTypeDrawer
