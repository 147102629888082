import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import IconButton from 'components/IconButton'
import Grid from '@material-ui/core/Grid'
import ErrorMessage from 'components/ErrorMessage'
import Switch from '@material-ui/core/Switch'
import { FormControlLabel } from '@material-ui/core'
import Loading from 'components/Loading'
import { TranslationContext } from 'context/translation'

const textField = (label, value, callback, register, errors, name, isRequired, type = 'text') => {
    return (
        <TextField
            margin="dense"
            inputRef={register !== undefined ? register({ required: isRequired }) : null}
            error={errors && !!errors[`${name}`]}
            id={name}
            name={name}
            label={label}
            type={type}
            fullWidth
            defaultValue={value}
            onChange={e => {
                callback(e.target.value)
            }}
        />
    )
}
const readOnlyTextField = (label, value, callback, register, errors, name, type = 'text') => {
    return (
        <TextField
            margin="dense"
            inputRef={register !== undefined ? register({ required: true }) : null}
            error={errors && !!errors[`${name}`]}
            id={name}
            name={name}
            label={label}
            type={type}
            InputProps={{
                readOnly: true,
            }}
            fullWidth
            defaultValue={value}
            onChange={e => {
                callback(e.target.value)
            }}
        />
    )
}

function getCommercialNameFr(nameObj) {
    return nameObj && nameObj.length > 0 ? nameObj[0].translation : ''
}

function getCommercialNameNl(nameObj) {
    return nameObj && nameObj.length > 0 ? nameObj[1].translation : ''
}
function DefectTypeForm(props) {
    const { handleAdd, error, editDrawer, editData, addEditLoading } = props
    const { translate } = React.useContext(TranslationContext)

    const [code, setCode] = useState(editDrawer ? editData?.code || '' : '')
    const [descriptionNl, setDescriptionNl] = useState(editDrawer ? getCommercialNameNl(editData.description) || '' : '')
    const [descriptionFr, setDescriptionFr] = useState(editDrawer ? getCommercialNameFr(editData.description) || '' : '')
    const [speedLimit, setSpeedLimit] = useState(editDrawer ? editData?.speedLimit || null : null)
    const [ItrisSpecific, setItrisSpecific] = useState(editDrawer ? editData?.itrisSpecific || false : false)
    const [brakeDamage, setBrakeDamage] = useState(editDrawer ? editData?.brakeDamage || false : false)
    const [remarkCode, setRemarkCode] = useState(editDrawer ? editData.remarkCode || '' : '')
    const { register, handleSubmit, errors } = useForm()

    const submitTheForm = data => {
        data.code = parseInt(data.code)
        data.speedLimit = parseInt(data.speedLimit)
        data.ItrisSpecific = ItrisSpecific
        data.brakeDamage = brakeDamage
        handleAdd(data, editDrawer)
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(submitTheForm)} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {editDrawer
                            ? readOnlyTextField('Code', code, setCode, register, errors, 'code', 'number')
                            : textField('Code', code, setCode, register, errors, 'code', true, 'number')}
                    </Grid>
                    <Grid item xs={12}>
                        {textField(
                            'Description NL',
                            descriptionNl,
                            setDescriptionNl,
                            register,
                            errors,
                            'descriptionNl',
                            true
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {textField(
                            'Description FR',
                            descriptionFr,
                            setDescriptionFr,
                            register,
                            errors,
                            'descriptionFr',
                            true
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {textField('SpeedLimit', speedLimit, setSpeedLimit, register, errors, 'speedLimit', false, 'number')}
                    </Grid>
                    <Grid item xs={12}>
                        {textField('Remarks code', remarkCode, setRemarkCode, register, errors, 'remarkCode', false)}
                    </Grid>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={ItrisSpecific}
                                onChange={() => setItrisSpecific(!ItrisSpecific)}
                                color="primary"
                            />
                        }
                        label="ItrisSpecific"
                    />

                    <FormControlLabel
                        control={
                            <Switch checked={brakeDamage} onChange={() => setBrakeDamage(!brakeDamage)} color="primary" />
                        }
                        label="brakeDamage"
                    />
                    <Grid item xs={12}>
                        {error && error?.length > 0 && <ErrorMessage error={error} spacing={false} />}
                        {editDrawer && (
                            <IconButton
                                disabled={addEditLoading}
                                type="submit"
                                icon="save"
                                title={translate('add_user_confirm_save')}
                            />
                        )}
                        {!editDrawer && (
                            <IconButton
                                disabled={addEditLoading}
                                icon="save"
                                type="submit"
                                title={translate('button_add')}
                            />
                        )}
                        {addEditLoading && <Loading />}
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    )
}

DefectTypeForm.propTypes = {
    handleAdd: PropTypes.func,
    error: PropTypes.string,
    editDrawer: PropTypes.bool,
    addEditLoading: PropTypes.bool,
    editData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default DefectTypeForm
