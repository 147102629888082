const initialApiState = {
    eSemesData: [],
    pubuIds: [],
    district: '',
    districtId: '',
}

const initialFilterState = {
    filterdResults: [],
    activeIds: [],
    zoneToggler: [],
    dataFilter: '',
    sort: {
        dir: 'asc',
        key: 'line',
    },
}

const apiActions = {
    semes: 'semes',
    pubu: 'pubu',
    district: 'district',
    districtId: 'districtId',
}

const apiReducer = (state, action) => {
    switch (action.type) {
        case apiActions.semes:
            return { ...state, eSemesData: action.payload }
        case apiActions.pubu:
            return { ...state, pubuIds: action.payload }
        case apiActions.district:
            return { ...state, district: action.payload }
        case apiActions.districtId:
            return { ...state, districtId: action.payload }
        default:
            return state
    }
}

const filterActions = {
    filterdResults: 'filterdResults',
    activeIds: 'activeIds',
    zoneToggler: 'zoneToggler',
    dataFilter: 'dataFilter',
    changeActiveZones: 'changeActiveZones',
    setFilterdResults: 'setFilterdResults',
    sort: 'sort',
}

const filterReducer = (state, action) => {
    switch (action.type) {
        case filterActions.filterdResults:
            return { ...state, filterdResults: action.payload }
        case filterActions.activeIds:
            return { ...state, activeIds: action.payload }
        case filterActions.zoneToggler:
            return { ...state, zoneToggler: action.payload }
        case filterActions.dataFilter:
            return { ...state, dataFilter: action.payload }
        case filterActions.changeActiveZones:
            return {
                ...state,
                activeIds: action.payload.activeIds,
                zoneToggler: action.payload.zoneToggler,
            }
        case filterActions.setFilterdResults:
            return {
                ...state,
                dataFilter: action.payload.dataFilter,
                filterdResults: action.payload.filterdResults,
            }
        case filterActions.sort:
            return {
                ...state,
                sort: {
                    dir: action.payload.dir,
                    key: action.payload.key,
                },
            }
        default:
            return state
    }
}

export const REDUCER_STATES = {
    initialApiState,
    initialFilterState,
}

export const REDUCER_ACTIONS = {
    apiActions,
    filterActions,
}

export const REDUCER_REDUCERS = {
    apiReducer,
    filterReducer,
}
