/* eslint-disable max-len */
import React from 'react'
import { object, arrayOf, string } from 'prop-types'
import { withStore, withCall, enums, withStatus } from 'react-ion-store'
import { withRouter, Switch, Route } from 'react-router-dom'
import { compose, mapProps } from 'recompose'
import { decode } from 'jsonwebtoken'

import Login from 'modules/Login'
import LockedView from 'modules/Login/LockedView'
import Performances from 'modules/Performances'
import Documents from 'modules/Documents'
import ExtendOrganisations from 'modules/Documents/views/ExtendedOrganisations/districtWrapper'
import SuperUser from 'modules/Documents/views/SuperUser'
import MarkAsRead from 'modules/Documents/views/MarkAsRead'
import Menu from 'modules/Menu'
import Manage from 'modules/Manage'
import Monitoring from 'modules/Monitoring'
import LindaRoles from 'modules/LindaRoles'
import DefectTypes from 'modules/Compositions/DefectTypes'
import PneumaticSuspensionRestrictionView from 'modules/Compositions/PneumaticSuspensionRestrictionView'
import VacmaRestrictionView from 'modules/Compositions/VacmaRestrictionView'
import ESemes from 'modules/eSemes'
import ESpeed from 'modules/ESpeed'
import Loading from 'components/Loading'

import allowedPaths from 'roles/allowedPaths'
import mapValidRoles from 'roles/mapValidRoles'
import mapRolesToModules from 'roles/mapRolesToModules'
import detectBrowser from 'utils/detectBrowser'
import AddNewTask from './Performances/views/AddNewTask'
import NoMatch from './NoMatch'
import NoRights from './NoRights'

import UsersAndRoles from './UsersAndRoles'
import MobileFeatures from './MobileFeatures'
import FeedbackCategories from './FeedbackCategories'
import PtcarOverview from './Manage/PtcarOverview'
import PtdesOverview from './Manage/PtdesOverview'
import MapView from './Manage/PtcarOverview/components/MapView'
import DriverAgenda from './DriverAgenda'
import Feedback from './Performances/views/Feedback'
import AddNewPerformance from './Performances/views/AddNewPerformance'
import PerformanceReport from 'modules/Performances/views/PerformanceReport'
import ManageProfile from './Manage/components/ManageProfile'
import Platforms from './Manage/components/Platforms'
import PtcarPlatform from './Manage/components/PtcarPlatform'

import SearchAgenda from './DriverAgenda/SearchAgenda'
import PerformanceAsJson from './Performances/views/PerformanceAsJson'
// eslint-disable-next-line max-len
import PrintPerformanceDetail from './Performances/views/PerformanceOverview/components/PerformanceActionDrawer/PrintPerformanceDetail'
import PerformanceThreshold from 'modules/Monitoring/PerformanceThreshold'
import ReadyForDeparture from './Performances/views/ReadyForDeparture'
import PerformancesByTrain from './Performances/views/PerformancesByTrain'
import ManagePtcarTypes from './Manage/components/ManagePtcarTypes'
import ManagePtcarClassifications from './Manage/components/ManagePtcarClassifications'
import OcrPoc from './OcrPoc'
import TC286Report from './Performances/views/TaskDetails/components/TC286Report'
import DefaultSettings from 'components/DefaultSettings'
import CompositionWithPtcar from './Performances/views/ReadyForDeparture/components/CompositionWithPtcar'
// eslint-disable-next-line max-len
import CompositionViewWithPtcar from './Performances/views/ReadyForDeparture/components/CompositionWithPtcar/CompositionViewWithPtcar'
import VehicleDetails from './Compositions/VehicleDetails'
import BrakingDetailsPulledTrains from './Compositions/BrakingDetailsPulledTrains'
import MenuProvider from 'context/menuStore'
import ManageEndpoints from './ManageEndpoints'
import FeedbackCategoryScope from './FeedbackCategories/Enums/FeedbackCategoryScope.js'
import queryString from 'query-string'
const {
    CallStatus: { LOADING, FAILED },
} = enums

const storeKeys = ['moduleList', 'auth', 'roles', 'redirectUrl', 'userRoles']

const Failed = () => <div>Error retrieving Roles!</div>

class ModuleRouter extends React.Component {
    static propTypes = {
        store: object.isRequired,
        location: object.isRequired,
        history: object.isRequired,
        match: object.isRequired,
        __ion_status: object.isRequired,
        parsedPath: arrayOf(string).isRequired,
    }

    isIE = false

    constructor(props) {
        super(props)
        const browserVersion = detectBrowser()
        if (browserVersion) this.isIE = browserVersion < 12 // 12 >= Edge
    }

    componentDidUpdate() {
        if (!this.isIE) this.isAuthorized()
        else if (this.props.parsedPath[0] !== '/auth') {
            this.setLocationState()
            this.props.history.replace('/auth')
        }
    }

    isAuthorized = () => {
        const { parsedPath } = this.props
        const { moduleList, auth, roles, userRoles } = this.props.store.get(storeKeys)

        if (!!auth && !!userRoles) {
            if (!roles && !moduleList) {
                const newRoles = mapRolesToModules(userRoles)
                this.props.store.set({ moduleList: allowedPaths(newRoles), roles: newRoles })
            } else if (!!roles && !moduleList) this.props.store.set({ moduleList: allowedPaths(roles) })
            else if ((parsedPath[0] === '/' || parsedPath[0] === '/auth') && moduleList.includes('/agenda'))
                this.redirectToPath(`/agenda`)
            else if ((parsedPath[0] === '/' || parsedPath[0] === '/auth') && moduleList.includes('/performances'))
                this.redirectToPath('/performances')
            else if ((parsedPath[0] === '/' || parsedPath[0] === '/auth') && moduleList.includes('/documents'))
                this.redirectToPath('/documents')
            else if ((parsedPath[0] === '/' || parsedPath[0] === '/auth') && moduleList.includes('/monitoring'))
                this.redirectToPath('/monitoring')
        }

        if (!auth && parsedPath[0] !== '/auth') {
        if (parsedPath[0] == '/users') {
            const values = queryString.parse(this.props.history.location.search)
            if(values.autoSignIn == 'true'){
                this.props.store.set({ autoSignIn: true })
            }
        }
            this.setLocationState()
            this.props.history.replace('/auth')
        }
    }

    redirectToPath = path => {
        let locationState = localStorage.getItem('locationState')
        if (locationState !== undefined && locationState !== null && locationState !== '' && locationState !== '/') {
            localStorage.removeItem('locationState')
            this.props.history.push(locationState)
        } else {
            this.props.history.push(path)
        }
    }

    setLocationState = () => {
        if (
            window.location.pathname !== '/' &&
            window.location.pathname !== '' &&
            localStorage.getItem('loggedOut') != true
        ) {
            localStorage.setItem('locationState', window.location.pathname)
        }
        localStorage.removeItem('loggedOut')
    }

    render() {
        const { store, __ion_status } = this.props
        const { userRoles } = __ion_status
        const { auth } = store.get()

        let storedSettings = localStorage.getItem('userSettings') ? JSON.parse(localStorage.getItem('userSettings')) : null
        if (!storedSettings || storedSettings !== store.get('screenSettings')){
            storedSettings = store.get('screenSettings')
            if (Array.isArray(storedSettings)) {localStorage.setItem('userSettings', JSON.stringify(storedSettings))
        }
        }

        const rolesLoading = !!userRoles && userRoles === LOADING
        const rolesFailed = !!userRoles && userRoles === FAILED

        if (rolesFailed)
            return (
                <React.Fragment>
                    <Failed />
                </React.Fragment>
            )
        else if (rolesLoading)
            return (
                <React.Fragment>
                    <Loading />
                </React.Fragment>
            )

        if (this.isIE)
            return (
                <React.Fragment>
                    <Switch>
                        <Route path="/auth" exact component={LockedView} />
                    </Switch>
                </React.Fragment>
            )
        return auth ? (
            <MenuProvider>
                <Switch>
                    <Route path="/" exact component={NoRights} />
                    <Route path="/auth" exact component={Login} />
                    <Route path="/performances" component={Performances} />
                    <Route path="/documents" component={Documents} />
                    <Route path="/extendorganisations" component={ExtendOrganisations} />
                    <Route path="/superuser" component={SuperUser} />
                    <Route path="/markasread" component={MarkAsRead} />
                    <Route path="/users" component={UsersAndRoles} />
                    <Route path="/mobileFeatures" component={MobileFeatures} />
                    <Route path="/manageendpoints" component={ManageEndpoints} />
                    <Route path="/manage" component={Manage} />
                    <Route path="/monitoring" component={Monitoring} />
                    <Route path="/add/task" component={AddNewTask} />
                    <Route path="/feedbackCategories" render={(props) => <FeedbackCategories {...props} scope={FeedbackCategoryScope.eDrive} />} />
                    <Route path="/feedbackCategoriesEshunting" render={(props) => <FeedbackCategories {...props} scope={FeedbackCategoryScope.eShunting} />} />
                    <Route path="/performancesfeedback" component={Feedback} />
                    <Route path="/accessdenied" component={NoRights} />
                    <Route path="/manageperformance" component={AddNewPerformance} />
                    <Route path="/ptcarOverview" exact component={PtcarOverview} />
                    <Route path="/ptcarOverview/mapview" component={MapView} />
                    <Route path="/ptdesOverview" component={PtdesOverview} />
                    <Route path="/agenda/:idfNumber?" component={DriverAgenda} />
                    <Route path="/searchCalendar" component={SearchAgenda} />
                    <Route path="/profiles" component={ManageProfile} />
                    <Route path="/jsonFormat" component={PerformanceAsJson} />
                    <Route path="/platforms" component={Platforms} />
                    <Route path="/ptcarplatforms" component={PtcarPlatform} />
                    <Route
                        path="/performanceDetail/getPrint/:performanceNumber/:idfNumber"
                        component={PrintPerformanceDetail}
                    />
                    <Route
                        path="/performancereport/:performanceNumber/:idfNumber/:depot/:date"
                        component={PerformanceReport}
                    />
                    <Route path="/lindaroles" component={LindaRoles} />
                    <Route path="/manageCompositionDefectTypes" component={DefectTypes} />
                    <Route path="/manageCompositionPneumaticRestrictions" component={PneumaticSuspensionRestrictionView} />
                    <Route path="/manageCompositionVacmaRestrictions" component={VacmaRestrictionView} />
                    <Route path="/performancethreshold" component={PerformanceThreshold} />
                    <Route path="/readyfordeparture" component={ReadyForDeparture} />
                    <Route path="/traintaskoverview" component={PerformancesByTrain} />
                    <Route path="/managePtcarTypes" component={ManagePtcarTypes} />
                    <Route path="/managePtcarClassifications" component={ManagePtcarClassifications} />
                    <Route path="/ocr" component={OcrPoc} />

                    <Route
                        path="/compositionPtcarView/:performanceId/:taskId/:trainNumber/:date/:fromPtcar/:idfNumber?/:locationFrom?/:toPtcar?/:locationTo?"
                        component={CompositionViewWithPtcar}
                    />
                    <Route
                        path="/compositionOverview/:trainNumber/:date/:fromPtcar/:idfNumber?/:locationFrom?/:toPtcar?/:locationTo?"
                        component={CompositionViewWithPtcar}
                    />
                    <Route
                        path="/compositionPtcarViewNonDriving/:id?/:date?/:idfNumber?"
                        component={CompositionViewWithPtcar}
                    />
                    <Route path="/compositionWithPtcar" component={CompositionWithPtcar} />
                    <Route
                        path="/TC286-report/:trainComposed/:performanceId/:taskId/:idfNumber/:time/:fromPtcar/:toPtcar"
                        component={TC286Report}
                    />
                    <Route path="/vehicleDetails" component={VehicleDetails} />
                    <Route path="/brakingDetailsPulledTrains" component={BrakingDetailsPulledTrains} />
                    <Route path="/eSemes/zones/:filter" component={ESemes} />
                    <Route path="/eSemes" component={ESemes} />
                    <Route path="/eSpeed" component={ESpeed} />
                    <Route component={NoMatch} />
                </Switch>
                <Menu />
            </MenuProvider>
        ) : (
            <React.Fragment>
                <Switch>
                    <Route path="/auth" exact component={Login} />
                </Switch>
            </React.Fragment>
        )
    }
}

export default compose(
    withRouter,
    withStore,
    withStatus,
    mapProps(props => ({
        ...props,
        parsedPath: props.location.pathname
            .split('/')
            .map(x => `/${x}`)
            .splice(1), // base / is always passed along
    })),

    Component => props =>
        // eslint-disable-next-line react/prop-types
        props.store.get('auth') ? (
            withCall('userRoles', {
                // eslint-disable-next-line react/prop-types
                config: { method: 'get', url: `/profile/${decode(props.store.get('auth')).b_employeeid}/roles` },
                // Filter Roles with no valid dates for the organisations.
                // eslint-disable-next-line react/prop-types
                reducer: ({ data }) => mapValidRoles(props.store.get('currentTime'), data),
            })(Component)(props)
        ) : (
            <Component {...props} />
        ),
    Component => props =>
        // eslint-disable-next-line react/prop-types
        props.store.get('auth') && !props.store.get('screenSettings') ? (
            withCall('screenSettings', {
                // eslint-disable-next-line react/prop-types
                config: { method: 'get', url: `profile/portal/pagesettings` },

                // eslint-disable-next-line react/prop-types
                reducer: ({ data }) => (data && data.length > 0 ? data : DefaultSettings),
            })(Component)(props)
        ) : (
            <Component {...props} />
        )
)(ModuleRouter)
