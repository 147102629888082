export default theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        position: 'fixed',
        bottom: '0px',
        width: '100%',
        zIndex: 2,
    },
    flex: {
        display: 'flex',
    },
})
