import React from 'react'
import { object, node, string } from 'prop-types'

// Material UI
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

// Components
import AppPaper from 'components/AppPaper'

import styles from './styles'

const ActionBar = ({ classes, title, children }) => (
    <AppPaper data-testid='parent' className={classes.root}>
        <div className={classes.flex}>
            {!!title && (
                <Typography data-testid='titleContainer' className={classes.text} variant="h6">
                    {title}
                </Typography>
            )}

            {!!children && children}
        </div>
    </AppPaper>
)

ActionBar.propTypes = {
    classes: object.isRequired,
    children: node,
    title: string,
}

export default withStyles(styles)(ActionBar)
