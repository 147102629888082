export default theme => ({
    PocContainer: {
        padding: theme.spacing(2),
        width: '100%',
        height: '100%',
        minHeight: theme.driveDetailList,
    },
    doing: {
        border: '3px solid',
        borderColor: 'black',
    },
    bestMatch: {
        border: '3px solid',
        borderColor: 'orange',
    },
    complete: {
        border: '3px solid',
        borderColor: 'green',
    },
})
