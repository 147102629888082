export default theme => ({
    container: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(8),
    },
    loading: {
        height: theme.spacing(8) * 2,
    },
    noPaddingList: {
        padding: 0,
    },
    listItem: {
        minHeight: theme.spacing(8),
    },
})
