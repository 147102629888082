import React from 'react'
import { string } from 'prop-types'

// Material UI
import Button from '@material-ui/core/Button'

const PrimaryButton = ({ title, color = 'primary', ...props }) => {
    return title ? (
        <Button data-testid="button" color={color} variant="contained" style={{ minHeight: 36 }} {...props}>
            {title}
        </Button>
    ) : (
        <Button color={color} variant="contained" style={{ minHeight: 36 }} {...props} />
    )
}

PrimaryButton.propTypes = {
    title: string,
    color: string,
}

export default PrimaryButton
