import React from 'react'
import { object, func, string } from 'prop-types'
import { compose } from 'recompose'
import { withStore, withTake } from 'react-ion-store'
import { withLocalize, Translate } from 'react-localize-redux'

import { Paper } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import SelectInput from 'components/SelectInput'

class SearchBarTbs extends React.Component {
    static propTypes = {
        handleEvent: func.isRequired,
        persist: func.isRequired,
        search: string.isRequired,
        className: string.isRequired,
        map: string.isRequired,
        doc: string.isRequired,
        documentFolders: object,
    }

    render() {
        const { handleEvent } = this.props
        const baseListElement = { id: ' ', text: 'documents_label_select_folder' }

        const mapIdToText = element => ({ id: element.folderId, text: element.id })

        let folders
        switch (this.props.doc) {
            case 'doc':
                folders = [baseListElement, ...this.props.documentFolders.document.map(mapIdToText)]
                break
            case 'comm':
                folders = [baseListElement, ...this.props.documentFolders.communication.map(mapIdToText)]
                break
            default:
                folders = [baseListElement]
        }
        return (
            // <Translate>
            //   {({ translate }) => (

            <React.Fragment>
                <Grid item xs={12}>
                    <Paper>
                        <Translate>
                            {({ translate }) => (
                                <div style={{ display: 'block', padding: '16px' }}>
                                    <SelectInput
                                        // classes={classes}
                                        sorted
                                        name="doc"
                                        items={[
                                            { id: ' ', text: 'documents_label_select_doc' },
                                            { id: 'doc', text: 'documents_radio_label_doc' },
                                            { id: 'comm', text: 'documents_label_select_comm' },
                                        ].map(doc => ({
                                            ...doc,
                                            text: translate(doc.text),
                                        }))}
                                        value={this.props.doc}
                                        onChange={handleEvent('doc')}
                                    />
                                </div>
                            )}
                        </Translate>
                    </Paper>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '16px' }}>
                    <Paper>
                        <Translate>
                            {({ translate }) => (
                                <div style={{ display: 'block', padding: '16px' }}>
                                    <SelectInput
                                        // classes={classes}
                                        sorted
                                        name="map"
                                        items={folders.map(folder => ({
                                            ...folder,
                                            text: translate(folder.text),
                                        }))}
                                        value={this.props.map}
                                        onChange={handleEvent('map')}
                                    />
                                </div>
                            )}
                        </Translate>
                    </Paper>
                </Grid>

                {/* <Translate>
          {({ translate }) => (
            <TextField
              fullWidth
              value={this.props.search}
              placeholder={translate("documents_prompt")}
              onChange={e => this.props.persist("file")(e.target.value)}
            />
          )}
        </Translate> */}
            </React.Fragment>

            // <div>
            //   <div>
            // <SelectInput
            //   sorted
            //   name="doc"
            //   items={[
            //     { id: " ", text: "documents_label_select_doc" },
            //     { id: "doc", text: "documents_radio_label_doc" },
            //     { id: "comm", text: "documents_label_select_comm" }
            //   ].map(doc => ({
            //     ...doc,
            //     text: translate(doc.text)
            //   }))}
            //   value={this.props.doc}
            //   onChange={handleEvent("doc")}
            // />
            //   </div>
            //   <div>
            //     <SelectInput
            //       sorted
            //       name="map"
            //       items={folders.map(folder => ({
            //         ...folder,
            //         text: translate(folder.text)
            //       }))}
            //       value={this.props.map}
            //       onChange={handleEvent("map")}
            //     />
            //   </div>
            //   <div>
            //     <TextField
            //       fullWidth
            //       value={this.props.search}
            //       placeholder={translate("documents_prompt")}
            //       onChange={e => this.props.persist("file")(e.target.value)}
            //     />
            //   </div>
            // </div>
            //   )}
            // </Translate>
        )
    }
}

export default compose(withStore, withLocalize, withTake('documentFolders'))(SearchBarTbs)
