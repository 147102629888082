import React from 'react'
import { object, bool, func, string } from 'prop-types'
import { Translate } from 'react-localize-redux'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import PrimaryButton from 'components/PrimaryButton'

import PersonSearcher from 'components/PersonSearcher'

const DriverDrawerView = ({
    classes,
    open,
    onClose,
    selectedName,
    selectedProfile,
    setName,
    clearName,
    openCalenderView,
    translate,
}) => {
    return (
        <Drawer open={open} anchor="right" style={{ zIndex: 1500 }} onClose={onClose}>
            <div className={classes.drawerRoot}>
                <div>
                    <Typography variant="h6" style={{ marginBottom: 24 }}>
                        <Translate id="driver_agenda" />
                    </Typography>
                    <Typography variant="h6">
                        <Translate id="driver_select_person" />
                    </Typography>
                    <hr style={{ marginBottom: 16 }} />
                    <div style={{ position: 'relative' }} name="PersonSearcherWrapper" tabIndex={0}>
                        <PersonSearcher {...{ selectedName, setName, clearName }} />
                    </div>

                    <PrimaryButton
                        className={classes.buttonOnBottom}
                        title={translate('driver_open_calender')}
                        onClick={() => openCalenderView(selectedProfile)}
                        disabled={!(selectedName && selectedProfile)}
                    />
                </div>
            </div>
        </Drawer>
    )
}

DriverDrawerView.propTypes = {
    classes: object.isRequired,
    open: bool.isRequired,
    onClose: func.isRequired,
    selectedName: string.isRequired,
    selectedProfile: object,
    setName: func.isRequired,
    clearName: func.isRequired,
    openCalenderView: func,
    translate: func,
}

export default DriverDrawerView
