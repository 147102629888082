import React, { useContext, useState } from 'react'
import { Grid, InputLabel, TextField, FormControlLabel, Checkbox} from '@material-ui/core'
import PropTypes from 'prop-types'
import { TranslationContext } from 'context/translation'
import { PtcarPlatformsContext } from '../../ptcarPlatforms.store'
import { useForm } from 'react-hook-form'
import PtcarSelect from './PtcarSelect'
import ErrorMessage from 'components/ErrorMessage'
import IconButton from 'components/IconButton'

const AddPtcarPlatform = props => {
    const { classes, error } = props

    const { translate } = useContext(TranslationContext)
    const { STATE, METHODS } = useContext(PtcarPlatformsContext)
    const [titles, setTitles] = useState({ nl: '', fr: '' })
    const [ptcar, setPtcar] = useState({})
    const [ptcarData, setPtcarData] = useState({})

    const { register, errors } = useForm()

    const submitTheForm = () => {
        if (titles.nl.length > 0 && titles.fr.length > 0) {
            const data = {
                platformNameNl: titles.nl,
                platformNameFr: titles.fr,
                ptCars: ptcar.code && ptcar.code.length > 0 ? [parseInt(ptcar.code)] : [],
                designation: ptcarData.designation,
                intPhoneNumber: ptcarData.intPhoneNumber,
                pubPhoneNumber: ptcarData.pubPhoneNumber,
                managedByLio: ptcarData.managedByLio,
            }
            METHODS.handleAddPlatform(data)
        }
    }

    const handlePtcarSearchValue = event => {
        setPtcar(event)
    }

    const changeHandler = (value, target) => {
        if (target === 'nl') {
            setTitles(prevState => ({
                ...prevState,
                nl: value,
            }))
        } else if (target === 'fr') {
            setTitles(prevState => ({
                ...prevState,
                fr: value,
            }))
        }
        else if (target === 'name') {
            setPtcarData(prevState => ({
                ...prevState,
                designation: value
            }))
        }
        else if (target === 'int_phone_number') {
            setPtcarData(prevState => ({
                ...prevState,
                intPhoneNumber: value
            }))
        }
        else if (target === 'pub_phone_number') {
            setPtcarData(prevState => ({
                ...prevState,
                pubPhoneNumber: value
            }))
        }
    }
    return (
        <>
            <div className={classes.textfieldContainer}>
                <div>
                <InputLabel className={classes.inputLabel}>{translate('datatable_header_linda_description_nl')}</InputLabel>
                <TextField
                    onChange={e => {
                        changeHandler(e.target.value, 'nl')
                    }}
                    type="text"
                    defaultValue={titles.nl}
                    />
                </div>
                <div>
                <InputLabel className={classes.inputLabel}>{translate('datatable_header_linda_description_fr')}</InputLabel>
                <TextField
                    onChange={e => {
                        changeHandler(e.target.value, 'fr')
                    }}
                    type="text"
                    defaultValue={titles.fr}
                    />
                </div>
            </div>
            <div className={classes.textfieldContainer}>
                <div>
                <InputLabel className={classes.inputLabel}>{translate('ptcarplatform_int_phone_number')}</InputLabel>
                <TextField
                    onChange={e => {
                        changeHandler(e.target.value, 'int_phone_number')
                    }}
                    type="text"
                    defaultValue={ptcarData.intPhoneNumber}
                    />
                </div>
                <div>
                <InputLabel className={classes.inputLabel}>{translate('ptcarplatform_public_net')}</InputLabel>
                <TextField
                onChange={e => {
                    changeHandler(e.target.value, 'pub_phone_number')
                }}
                type="text"
                defaultValue={ptcarData.pubPhoneNumber}
                />
                </div>
            </div>
            <div style={{marginBottom: '32px'}} className={classes.textfieldContainer}>
                <div>
                <InputLabel className={classes.inputLabel}>{translate('ptcarplatform_name')}</InputLabel>
                <TextField
                    onChange={e => {
                        changeHandler(e.target.value, 'name')
                    }}
                    type="text"
                    defaultValue={ptcarData.designation}
                />
                </div>
                <div className={classes.checkboxContainer}>
                <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ptcarData.managedByLio ?? false}
                                    onChange={e => setPtcarData(prevState => ({
                                        ...prevState,
                                        managedByLio: e.target.checked
                                    }))}
                                    color="primary"
                                />
                            }
                            label={translate('datatable_header_managed_LIB')}
                        />
                </div>
            </div>
            <PtcarSelect
                handleSearchValue={handlePtcarSearchValue}
                data={STATE.ptcars}
                ptcarSelectValue={ptcar}
                classes={classes}
                register={register !== undefined ? register : null}
                errors={errors}
            />
            <Grid item xs={12}>
                {error && error?.length > 0 && <ErrorMessage error={error} spacing={false} />}
                <div className={classes.buttonBottom}>
                    <IconButton icon="save" onClick={submitTheForm} title={translate('button_add')} />
                </div>
            </Grid>
        </>
    )
}

AddPtcarPlatform.propTypes = {
    classes: PropTypes.object,
    error: PropTypes.string,
}

export default AddPtcarPlatform
