import React, { useEffect } from 'react'
import { PropTypes, object } from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AddFeedbackDrawer from '../AddFeedbackDrawer'
import SortFeedbackDrawer from '../SortFeedbackDrawer'
import FeedbackCategoryGroup from '../FeedbackCategoryGroup'

import { Divider, ListItemIcon } from '@material-ui/core'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import GetTitle from '../../util'
import Loading from 'components/Loading'
import styles from '../../styles'
import FeedbackCategoryIcon from '../FeedbackCategoryIcon'
import { feedbackCategoriesContext } from '../../store.feedbackCategories'
import { TranslationContext } from 'context/translation'

const FeedbackListView = props => {
    const [feedback, setFeedback] = React.useState('')
    const { STATE, METHODS } = React.useContext(feedbackCategoriesContext)
    const { translate, activeLanguage } = React.useContext(TranslationContext)
    const { classes, scope } = props

    useEffect(() => {
        setFeedback(props.store.get('feedbacklistnumber') ? props.store.get('feedbacklistnumber') : '')
        return () => {
            props.store.set({ feedbacklistnumber: '' })
            props.store.set({ feedbackcategorygroup: '' })
        }
    }, [])
    const openFeedback = (store, feedbackCom) => {
        store.set({ feedbacklistnumber: feedbackCom === feedback ? '' : feedbackCom })
        setFeedback(feedbackCom.id === feedback.id ? '' : feedbackCom)
    }
    let showList = STATE.data.length > 0
    return (
        <div>
            {STATE.loading ? (
                <Loading />
            ) : (
                <div className={classes.documentTableWrapper}>
                    <div className={classes.buttonContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                METHODS.openSortDrawer()
                            }}
                        >
                            {translate('sort_feedback_category')}
                        </Button>
                    </div>
                    {showList && (
                        <React.Fragment>
                            <List>
                                {STATE.data &&
                                    STATE.data.map(performance => (
                                        <React.Fragment key={performance.id}>
                                            <Paper square elevation={0}>
                                                <ListItem button onClick={() => openFeedback(props.store, performance)}>
                                                    <Typography color={'secondary'}>{performance.weight}</Typography>
                                                    <ListItemText
                                                        primary={
                                                            GetTitle(activeLanguage, performance.description) +
                                                            (performance.subCategories.length > 0
                                                                ? ` (${performance.subCategories.length})`
                                                                : '')
                                                        }
                                                    />
                                                    <ListItemIcon>
                                                        <FeedbackCategoryIcon
                                                            signed={performance.signed}
                                                            isFirstDeparture={performance.isFirstDeparture}
                                                            e360={performance.e360}
                                                        />
                                                    </ListItemIcon>
                                                    <EditIcon
                                                        className={classes.editIcon}
                                                        onClick={e => METHODS.openDrawer(e, performance)}
                                                    />

                                                    <ListItemSecondaryAction>
                                                        {feedback && feedback.id === performance.id ? (
                                                            <ExpandLess className={classes.icon} />
                                                        ) : (
                                                            <ExpandMore className={classes.icon} />
                                                        )}
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </Paper>
                                            {feedback && feedback.id === performance.id && (
                                                <FeedbackCategoryGroup
                                                    performances={performance}
                                                    store={props.store}
                                                ></FeedbackCategoryGroup>
                                            )}
                                            <Divider className={classes.divider}></Divider>
                                        </React.Fragment>
                                    ))}
                            </List>
                        </React.Fragment>
                    )}
                    {!showList && (
                        <Typography className={classes.submenu} variant="h6">
                            {translate('no_data_available')}
                        </Typography>
                    )}
                    {STATE.isOpen && <AddFeedbackDrawer scope={scope}/>}
                    <SortFeedbackDrawer classes={classes} translate={translate} scope={scope}/>
                    <Button variant="contained" color="primary" onClick={e => METHODS.openDrawer(e, '')}>
                        {translate('add_feedback_category')}
                    </Button>
                </div>
            )}
        </div>
    )
}

FeedbackListView.propTypes = {
    classes: object.isRequired,
    store: object,
    scope: PropTypes.string,
}
export default compose(withStyles(styles), withRouter)(FeedbackListView)
