import React from 'react'
import { bool, func } from 'prop-types'

// Components
import AssignDrawerView from './AssignDrawerView'

export default class AssignDrawer extends React.Component {
    static propTypes = {
        isOpen: bool.isRequired,
        toggleDrawer: func.isRequired,
    }
    render() {
        const { isOpen, toggleDrawer } = this.props
        return <AssignDrawerView isOpen={isOpen} toggleDrawer={toggleDrawer} />
    }
}
