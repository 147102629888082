export const columns = [
    {
        width: 400,
        label: 'datatable_header_platforms',
        dataKey: 'platformName',
    },
    {
        width: 300,
        label: 'datatable_header_intern_nmbs_number',
        dataKey: 'intPhoneNumber',
    },
    {
        width: 300,
        label: 'datatable_header_managed_LIB',
        dataKey: 'managedByLio',
    },
    {
        width: 200,
        label: 'datatable_header_actions',
        dataKey: 'actions',
    },
]
