import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ErrorMessage from 'components/ErrorMessage'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

import { Paper, Typography } from '@material-ui/core'

export class DeletePerformanceDialog extends PureComponent {
    render() {
        const {
            open,
            handleClose,
            id,
            deletePerformance,
            deletePerformanceLoading,
            deletePerformanceError,
            translate,
        } = this.props

        return (
            <Dialog open={open} onClose={() => handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{translate('add_user_delete')}</DialogTitle>
                <Paper>
                    <DialogContent style={{ minWidth: '600px' }}>
                        <DialogContentText>{translate('performance_delete')}</DialogContentText>
                        <Typography variant="subtitle1">{translate('performance_delete_sure')}</Typography>
                        <Typography variant="subtitle1">{id}</Typography>
                        {deletePerformanceError && deletePerformanceError.length > 0 && (
                            <ErrorMessage error={translate('performance_delete_performance_dialog_error')} spacing={false} />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                handleClose()
                            }}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                deletePerformance(id)
                            }}
                            color="primary"
                            disabled={deletePerformanceLoading}
                        >
                            {translate('add_user_delete')}
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        )
    }
}
DeletePerformanceDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    id: PropTypes.string,
    deletePerformance: PropTypes.func,
    deletePerformanceLoading: PropTypes.bool,
    deletePerformanceError: PropTypes.string,
    translate: PropTypes.func,
}

export default withStyles(styles)(DeletePerformanceDialog)
