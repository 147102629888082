import React, { useEffect, useState } from 'react'
import { bool, func, string, number } from 'prop-types'
import AppDrawer from 'components/AppDrawer'
import LindaActionsList from 'modules/LindaActions/components/LindaActionsList'
import LindaUserList from 'modules/LindaActions/components/LindaUserList'
import getLindaActions from 'modules/LindaActions/services/getLindaActions'
import Loading from 'components/Loading'
import { TranslationContext } from 'context/translation'
import getLindaUsers from 'modules/LindaActions/services/getLindaUsers'
const LindaDrawer = props => {
    const [lindaData, setLindaData] = useState(null)
    const [lindaUsers, setLindaUsers] = useState(null)
    const [isLoading, setIsLoadig] = useState(false)

    const { translate, activeLanguage } = React.useContext(TranslationContext)

    useEffect(async () => {
        let isMounted = true
        setIsLoadig(true)
        const performance = props.storage.get('performanceDetail')
        const data = await getLindaActions(
            props.trainNumberPrefix || '',
            props.trainNumber,
            props.performanceDate,
            performance?.idfNumber || '',
            props.taskId
        )
        const users = await getLindaUsers(props.trainNumberPrefix || '', props.trainNumber, props.performanceDate)
        if (isMounted) {
            setLindaData(data)
            setLindaUsers(users)
            setIsLoadig(false)
        }
        return () => (isMounted = false)
    }, [])
    return (
        <AppDrawer isOpen={props.open} width="840px" onClose={props.onClose} title={translate('label_linda')}>
            {isLoading && <Loading />}
            {!isLoading && (
                <>
                    <LindaUserList data={lindaUsers} activeLanguage={activeLanguage} />
                    <LindaActionsList data={lindaData} activeLanguage={activeLanguage} />
                </>
            )}
        </AppDrawer>
    )
}
LindaDrawer.propTypes = {
    open: bool.isRequired,
    onClose: func.isRequired,
    trainNumberPrefix: string,
    trainNumber: string,
    performanceDate: number,
    storage: Object,
    taskId: string,
}
export default LindaDrawer
