export const mockData = [
    {
        semesId: 12178959,
        beginApplicationDate: 1581606000,
        endApplicationDate: 4102441199,
        reasonDescription: [
            {
                language: 'fr-BE',
                translation: "Avis d' implantation d'un signal de repérage d'extrémité de quai",
            },
            {
                language: 'nl-BE',
                translation: 'Merksein einde perron',
            },
        ],
        additionSemesInfrabelNr: 'ICE-50/136/20-02',
        setyDescription: [
            {
                language: 'fr-BE',
                translation: 'ARQ',
            },
            {
                language: 'nl-BE',
                translation: 'BIP',
            },
        ],
        validityPeriod: {
            validityPeriodId: 120627,
            beginDate: 1581912000,
            endDate: 4102441199,
            beginSemesDate: 1581912000,
            endSemesDate: 4102441199,
        },
        lineSection: {
            lineId: '10',
            userLineName: '0/1',
            trackType: [
                {
                    language: 'fr-BE',
                    translation: 'En gare',
                },
                {
                    language: 'nl-BE',
                    translation: 'In station',
                },
            ],
            speedLimitation: 'H',
            lineSectionId: 122613,
            ptCarFromCommercialMediumName: [
                {
                    language: 'fr-BE',
                    translation: 'Bruxelles-Nord',
                },
                {
                    language: 'nl-BE',
                    translation: 'Brussel-Noord',
                },
            ],
            ptCarToCommercialMediumName: [
                {
                    language: 'fr-BE',
                    translation: 'Bruxelles-Nord',
                },
                {
                    language: 'nl-BE',
                    translation: 'Brussel-Noord',
                },
            ],
        },
        receivedCount: 0,
        openedCount: 1,
        numberOfTrainDriversReceivedSemes: 0,
        numberOfTrainDriversReceivedSemesPercentage: 0,
        numberOfTrainDriversReadSemes: 0,
        numberOfTrainDriversReadSemesPercentage: 0,
        pubuId: '2',
        pubuDescription: [
            {
                language: 'fr-BE',
                translation: 'Centre',
            },
            {
                language: 'nl-BE',
                translation: 'Centre',
            },
        ],
        profileInfo: [
            {
                idfNumber: 123,
                firstName: 'bjarne',
                lastName: 'verdonck',
                firstReceived: undefined,
                firstOpened: 1843418,
                lastOpened: 684813,
                numberOfTasksApplicable: 5,
                numberOfTasksApplicableRead: 1,
                districtDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'Nord-Ouest',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'Noord-West',
                    },
                ],
                tctDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'TC',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'TC',
                    },
                ],
                depotDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'Leuven',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'Louvain',
                    },
                ],
            },
            {
                idfNumber: 456,
                firstName: 'christopher',
                lastName: 'rup',
                firstReceived: 108080,
                firstOpened: 1843418,
                lastOpened: 684813,
                numberOfTasksApplicable: 6,
                numberOfTasksApplicableRead: 6,
                districtDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'Centre',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'Centre',
                    },
                ],
                tctDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'FA',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'FA',
                    },
                ],
                depotDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'Luik',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'Liege',
                    },
                ],
            },
        ],
    },
    {
        semesId: 12178959846,
        beginApplicationDate: 1581606000,
        endApplicationDate: 4102441199,
        reasonDescription: [
            {
                language: 'fr-BE',
                translation: "Avis d' implantation d'un signal de repérage d'extrémité de quai",
            },
            {
                language: 'nl-BE',
                translation: 'Merksein einde perron',
            },
        ],
        additionSemesInfrabelNr: 'ICE-50/136/20-02',
        setyDescription: [
            {
                language: 'fr-BE',
                translation: 'ARQ',
            },
            {
                language: 'nl-BE',
                translation: 'BIP',
            },
        ],
        validityPeriod: {
            validityPeriodId: 120627,
            beginDate: 1581912000,
            endDate: 4102441199,
            beginSemesDate: 1581912000,
            endSemesDate: 4102441199,
        },
        lineSection: {
            lineId: '10',
            userLineName: '0/1',
            trackType: [
                {
                    language: 'fr-BE',
                    translation: 'En gare',
                },
                {
                    language: 'nl-BE',
                    translation: 'In station',
                },
            ],
            speedLimitation: 'H',
            lineSectionId: 122613,
            ptCarFromCommercialMediumName: [
                {
                    language: 'fr-BE',
                    translation: 'Bruxelles-Nord',
                },
                {
                    language: 'nl-BE',
                    translation: 'Brussel-Noord',
                },
            ],
            ptCarToCommercialMediumName: [
                {
                    language: 'fr-BE',
                    translation: 'Bruxelles-Nord',
                },
                {
                    language: 'nl-BE',
                    translation: 'Brussel-Noord',
                },
            ],
        },
        receivedCount: 0,
        openedCount: 1,
        numberOfTrainDriversReceivedSemes: 0,
        numberOfTrainDriversReceivedSemesPercentage: 0,
        numberOfTrainDriversReadSemes: 0,
        numberOfTrainDriversReadSemesPercentage: 0,
        pubuId: '2',
        pubuDescription: [
            {
                language: 'fr-BE',
                translation: 'Centre',
            },
            {
                language: 'nl-BE',
                translation: 'Centre',
            },
        ],
        profileInfo: [
            {
                idfNumber: 123,
                firstName: 'bjarne',
                lastName: 'verdonck',
                firstReceived: undefined,
                firstOpened: 1843418,
                lastOpened: 684813,
                numberOfTasksApplicable: 5,
                numberOfTasksApplicableRead: 1,
                districtDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'Nord-Est',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'Noord-Oost',
                    },
                ],
                tctDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'TC',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'TC',
                    },
                ],
                depotDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'Leuven',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'Louvain',
                    },
                ],
            },
            {
                idfNumber: 456,
                firstName: 'christopher',
                lastName: 'rup',
                firstReceived: 108080,
                firstOpened: 1843418,
                lastOpened: 684813,
                numberOfTasksApplicable: 6,
                numberOfTasksApplicableRead: 5,
                districtDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'Centre',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'Centre',
                    },
                ],
                tctDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'FA',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'FA',
                    },
                ],
                depotDescription: [
                    {
                        language: 'fr-BE',
                        translation: 'Luik',
                    },
                    {
                        language: 'nl-BE',
                        translation: 'Liege',
                    },
                ],
            },
        ],
    },
]
