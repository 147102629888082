import React from 'react'

import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { ThemeContext } from 'theme/ThemeProvider'

const ErrorMessage = ({ error, spacing = true }) => {
    const { themeName } = React.useContext(ThemeContext)
    const darkMode = themeName !== 'light'
    return (
        <Typography
            data-testid='error-message'
            variant="subtitle1"
            style={{
                padding: darkMode ? '0px' : '4px 8px',
                paddingLeft: spacing ? '12px' : 0,
                color: darkMode ? 'yellow' : 'black',
                backgroundColor: darkMode ? null : 'yellow',
            }}
        >
            {error}
        </Typography>
    )
}

ErrorMessage.propTypes = {
    error: PropTypes.string.isRequired,
    spacing: PropTypes.bool,
}
export default ErrorMessage
