export default theme => ({
    flexContainer: {
        height: 50,
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableCell: {
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
    },
    tableRow: {
        height: '50px !important',
        display: 'flex',
        backgroundColor: 'transparent',
    },
    header: {
        backgroundColor: theme.customColors.lightGrey,
    },
    documentTableWrapper: {
        padding: `0 ${theme.spacing(3)}px`,
        width: '100%',
        minHeight: theme.readList,
        height: `calc(100vh - ${theme.performanceView}px)`,
        marginBottom: theme.spacing(2),
    },
    table: { fontFamily: theme.typography.fontFamily },
    multipleIcons: {
        display: 'flex',
        alignContent: 'space-between',
    },
    editIcon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        fill: theme.customColors.white,
    },
    deleteIcon: {
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        color: theme.customColors.red,
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
    drawerRoot: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: '500px',
        padding: `${theme.spacing(3)}px`,
    },
    addButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(2)}px`,
    },
})
