import { grey } from '@material-ui/core/colors'

export default theme => ({
    infoContainer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        },
        borderBottom: `2px solid ${grey['A400']}`,
    },
    infoContainerForMob: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        borderBottom: `2px solid ${grey['A400']}`,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    driverActivity: {
        padding: `${theme.spacing(2)}px`,
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(1) / 2}px ${theme.spacing(1)}px`,
        },
    },
    spacing: {
        borderRight: `2px solid ${grey['A400']}`,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    button: {
        marginRight: '5px',
        [theme.breakpoints.down('sm')]: {
            margin: '5px',
        },
    },
    notificationIcon: {
        marginLeft: theme.spacing(1),
    },
})
