import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, Column, Table, defaultTableRowRenderer } from 'react-virtualized'

const PneumaticSuspensionTable = props => {
    PneumaticSuspensionTable.defaultProps = {
        headerHeight: 56,
        rowHeight: 56,
    }
    PneumaticSuspensionTable.propTypes = {
        data: PropTypes.array,
        columns: PropTypes.array,
        classes: PropTypes.object,
        headerHeight: PropTypes.number,
        rowHeight: PropTypes.number,
        headerRenderer: PropTypes.func,
        cellRenderer: PropTypes.func,
        getRowClassName: PropTypes.func,
    }

    const getRowHeight = index => {
        let height = props.rowHeight
        let size = data[index].restrictionPercentages.length
        return height + (size - 1) * 20
    }
    const { data, columns, classes, headerHeight, headerRenderer, cellRenderer, getRowClassName } = props
    return (
        <div data-testid="pneumatic-suspension-table" className={classes.documentTableWrapper}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        className={classes.table}
                        height={height}
                        width={width}
                        rowCount={data.length}
                        rowClassName={getRowClassName}
                        rowRenderer={defaultTableRowRenderer}
                        rowGetter={({ index }) => data[index]}
                        rowHeight={({ index }) => getRowHeight(index)}
                        headerHeight={headerHeight}
                    >
                        {columns.map(({ dataKey, ...other }) => (
                            <Column
                                key={dataKey}
                                headerRenderer={headerRenderer}
                                className={classes.flexContainer}
                                cellRenderer={cellRenderer}
                                dataKey={dataKey}
                                {...other}
                            />
                        ))}
                    </Table>
                )}
            </AutoSizer>
        </div>
    )
}

export default PneumaticSuspensionTable
