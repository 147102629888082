import React from 'react'
import { string, func, object, bool } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize, Translate } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ChevronRight from '@material-ui/icons/ChevronRight'

import styles from './styles'

class SubAppButton extends React.PureComponent {
    static propTypes = {
        classes: object.isRequired,
        id: string.isRequired,
        path: string.isRequired,
        goToPath: func.isRequired,
        isChild: bool,
        external: bool,
        isSecondLevel: bool,
    }

    static defaultProps = {
        isChild: false,
    }

    render() {
        const { classes, id, path, goToPath, isChild, external, isSecondLevel } = this.props

        return (
            <React.Fragment>
                <ListItem
                    onClick={
                        !external
                            ? goToPath(path)
                            : () => {
                                  goToPath(path)
                              }
                    }
                    button
                    className={
                        isChild ? (isSecondLevel ? classes.listItemSubChild : classes.listItemChild) : classes.listItem
                    }
                >
                    <Typography variant="h6" className={classes.buttonText}>
                        <Translate id={id} />
                    </Typography>
                    <ListItemSecondaryAction>
                        <ChevronRight style={{cursor: 'pointer'}} className={classes.menuIcon} onClick={
                        !external
                            ? goToPath(path)
                            : () => {
                                  goToPath(path)
                              }
                    }/>
                    </ListItemSecondaryAction>
                </ListItem>
                {!isChild && <hr className={classes.divider} />}
            </React.Fragment>
        )
    }
}

export default compose(withLocalize, withStyles(styles))(SubAppButton)
