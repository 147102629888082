export default theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(2),
        position: 'fixed',
        width: '100%',
        bottom: 0,
        left: 0,
    },
    flex: {
        display: 'flex',
    },
    marginButton: {
        marginRight: theme.spacing(2),
    },
})
