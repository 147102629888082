const decapitalize = string => string.charAt(0).toLowerCase() + string.slice(1)

export default obj => {
    const newObj = {}

    for (let key in obj) {
        newObj[decapitalize(key)] = obj[key]
    }

    return newObj
}
