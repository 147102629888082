import React from 'react'
import { PropTypes } from 'prop-types'
import FeedbackDrawerView from './FeedbackDrawerView'

const AddFeedbackDrawer = ({scope}) => <FeedbackDrawerView scope={scope}/>


AddFeedbackDrawer.propTypes = {
    scope: PropTypes.string,
}
export default AddFeedbackDrawer
