import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { DateTime } from 'luxon'
import { withStyles } from '@material-ui/core/styles'
import { withLocalize } from 'react-localize-redux'
import getPerformanceTasks from './getPerformanceTasks'
import Loading from 'components/Loading'
import styles from './styles'

const PrintPerformanceDetail = props => {
    PrintPerformanceDetail.propTypes = {
        match: PropTypes.object,
        classes: PropTypes.object,
        translate: PropTypes.func,
    }
    const [tasksData, setTasksData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function fetchData() {
            const data = await getPerformanceTasks(props.match.params.performanceNumber, props.match.params.idfNumber)
            if (data) {
                setTasksData(data)
                setIsLoading(false)
            }
        }
        fetchData()
    }, [])

    if (isLoading)
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        )
    else
        return (
            <React.Fragment>
                <Paper className={props.classes.root}>
                    <Grid container spacing={0}>
                        <Grid style={{ paddingLeft: '8px' }} item xs={12}>
                            <Typography>{tasksData.name}</Typography>
                        </Grid>
                        <Grid style={{ paddingLeft: '8px' }} item xs={12}>
                            <Typography>{tasksData.idfNumber}</Typography>
                        </Grid>
                        <Grid style={{ paddingLeft: '8px' }} item xs={12}>
                            <Typography>{`${tasksData.depot} ${tasksData.performanceNumber} ${DateTime.fromSeconds(
                                tasksData.performanceDate
                            ).toFormat('dd/MM/yyyy')}`}</Typography>
                        </Grid>
                        <Grid item className={props.classes.column} xs={2}>
                            <Typography>{props.translate('datatable_header_task_code')}</Typography>
                        </Grid>
                        <Grid item className={props.classes.column} xs={3}>
                            <Typography>{props.translate('datatable_header_task_info')}</Typography>
                        </Grid>
                        <Grid item className={props.classes.column} xs={3}>
                            <Typography>{props.translate('datatable_header_task_diagram')}</Typography>
                        </Grid>
                        <Grid item className={props.classes.column} xs={2}>
                            <Typography>{props.translate('datatable_header_task_start')}</Typography>
                        </Grid>
                        <Grid item className={props.classes.column} xs={2}>
                            <Typography>{props.translate('datatable_header_task_end')}</Typography>
                        </Grid>
                        {tasksData.tasks.map(task => (
                            <React.Fragment key={task.id}>
                                <Grid className={props.classes.column} item xs={2}>
                                    <Typography variant={'caption'}>
                                        {task.code === 'DRIVE' ? task.trainComposed : task.code}
                                    </Typography>
                                </Grid>
                                <Grid className={props.classes.column} item xs={3}>
                                    <Typography variant={'caption'}>{task.taskInfo}</Typography>
                                </Grid>
                                <Grid className={props.classes.column} item xs={3}>
                                    <Typography variant={'caption'}>{task.diagramNumber}</Typography>
                                </Grid>
                                <Grid className={props.classes.column} item xs={2}>
                                    <Typography variant={'caption'}>
                                        {task.plannedStartTime ? task.plannedStartTime.substring(0, 5) : ''}
                                    </Typography>
                                </Grid>
                                <Grid className={props.classes.column} item xs={2}>
                                    <Typography variant={'caption'}>
                                        {task.plannedEndTime ? task.plannedEndTime.substring(0, 5) : ''}
                                    </Typography>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Paper>
            </React.Fragment>
        )
}
export default compose(withStyles(styles), withLocalize)(PrintPerformanceDetail)
