import React from 'react'

import PerformanceInfo from './components/PerformanceInfo'
import RemarkFooter from './components/RemarkFooter'
import TaskList from './components/TaskList'
import { object, func, bool, array } from 'prop-types'

const PerformanceDetailView = ({
    performanceDetail,
    storage,
    isOpen,
    handleClose,
    handleOpen,
    noteIsOpen,
    handleNoteOpen,
    handleNoteClose,
    deleteMultipleTasks,
    AbolishMultipleTasks,
    handleCheckTasks,
    checkedTasks,
    allowDeleteMultipleTasks,
    isMultiDialogOpen,
    handleMultiClose,
    showAbolishTitle,
    refreshAfterBulkOperation,
    refreshRate,
}) => {
    return (
        <React.Fragment>
            <PerformanceInfo
                deleteMultipleTasks={deleteMultipleTasks}
                allowDeleteMultipleTasks={allowDeleteMultipleTasks}
                AbolishMultipleTasks={AbolishMultipleTasks}
                handleMultiClose={handleMultiClose}
                isMultiDialogOpen={isMultiDialogOpen}
                showAbolishTitle={showAbolishTitle}
                checkedTasks={checkedTasks}
                refreshAfterBulkOperation={refreshAfterBulkOperation}
                storage={storage}
                {...performanceDetail}
            />
            <TaskList
                tasks={performanceDetail.tasks}
                storage={storage}
                performanceId={performanceDetail.performanceId}
                idfNumber={performanceDetail.idfNumber}
                isOpen={isOpen}
                noteIsOpen={noteIsOpen}
                handleClose={handleClose}
                handleOpen={handleOpen}
                disableButtons={performanceDetail.signOutTime !== null}
                handleCheckTasks={handleCheckTasks}
                checkedTasks={checkedTasks}
                refreshRate={refreshRate}
            />
            <RemarkFooter
                remark={performanceDetail.remark}
                {...performanceDetail}
                storage={storage}
                noteIsOpen={noteIsOpen}
                handleNoteOpen={handleNoteOpen}
                handleNoteClose={handleNoteClose}
                refreshAfterBulkOperation={refreshAfterBulkOperation}
            />
        </React.Fragment>
    )
}
PerformanceDetailView.propTypes = {
    performanceDetail: object.isRequired,
    storage: object.isRequired,
    isOpen: bool,
    handleClose: func,
    handleOpen: func,
    noteIsOpen: bool,
    handleNoteOpen: func,
    handleNoteClose: func,
    deleteMultipleTasks: func,
    AbolishMultipleTasks: func,
    handleCheckTasks: func,
    checkedTasks: array,
    allowDeleteMultipleTasks: bool,
    isMultiDialogOpen: bool,
    handleMultiClose: func,
    showAbolishTitle: bool,
    refreshAfterBulkOperation: func,
    refreshRate: bool,
}
export default PerformanceDetailView
