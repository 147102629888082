const TaskTypes = [
    { id: 'FREETEXT_P', text: 'FREETEXT_P', group: 1 },
    { id: 'FREETEXT_M', text: 'FREETEXT_M', group: 1 },
    { id: 'OPMRMQ', text: 'OPMRMQ', group: 1 },
    { id: 'OPMREM', text: 'OPMREM', group: 14 },
    { id: 'CAR', text: 'CAR', group: 2 },
    { id: 'BUS', text: 'BUS', group: 3 },
    { id: 'HLP', text: 'HLP', group: 11 },
    { id: 'AUTO', text: 'AUTO', group: 4 },
    { id: 'TAXI', text: 'TAXI', group: 4 },
    { id: 'VOETPIED', text: 'VOETPIED', group: 4 },
    { id: 'DECUIT', text: 'DECUIT', group: 5 },
    { id: 'UITDEC', text: 'UITDEC', group: 5 },
    { id: 'PAU', text: 'PAU', group: 5 },
    { id: 'RES', text: 'RES', group: 5 },
    { id: 'STABY EU', text: 'STABY EU', group: 5 },
    { id: 'STABY LO', text: 'STABY LO', group: 5 },
    { id: 'STABY MO', text: 'STABY MO', group: 5 },
    { id: 'STABY RC', text: 'STABY RC', group: 5 },
    { id: 'STABY TC', text: 'STABY TC', group: 5 },
    { id: 'RAMAN EU', text: 'RAMAN EU', group: 6 },
    { id: 'RAMAN LO', text: 'RAMAN LO', group: 6 },
    { id: 'RAMAN MO', text: 'RAMAN MO', group: 6 },
    { id: 'RAMAN TC', text: 'RAMAN TC', group: 6 },
    { id: 'RAMAN', text: 'RAMAN', group: 6 },
    { id: 'BEVAP', text: 'BEVAP', group: 6 },
    { id: 'PLAT', text: 'PLAT', group: 6 },
    { id: 'PLANT MO', text: 'PLANT MO', group: 6 },
    { id: 'PLANT TC', text: 'PLANT TC', group: 6 },
    { id: 'AFREL', text: 'AFREL', group: 7 },
    { id: 'AFREL DP', text: 'AFREL DP', group: 8 },
    { id: 'AFLOS DP', text: 'AFLOSDP', group: 8 },
    { id: 'VK.PC', text: 'VKPC', group: 9 },
    { id: 'BK.PR', text: 'BK.PR', group: 9 },
    { id: 'PC-EJ', text: 'PC-EJ', group: 9 },
    { id: 'BEGAR', text: 'BEGAR', group: 9 },
    { id: 'IDREM', text: 'IDREM', group: 9 },
    { id: 'INMAIN', text: 'INMAIN', group: 9 },
    { id: 'CAR-WASH', text: 'CAR-WASH', group: 10 },
    { id: 'DRIVE', text: 'DRIVE', group: 11 },
    { id: 'UITGAR', text: 'UITGAR', group: 11 },
    { id: 'PERQUAI', text: 'PERQUAI', group: 11 },
    { id: 'TRANSFER', text: 'TRANSFER', group: 11 },
    { id: 'ONTDC DP', text: 'ONTDC DP', group: 12 },
    { id: 'KOPCP DP', text: 'KOPCP DP', group: 12 },
    { id: 'ONTDC', text: 'ONTDC', group: 13 },
    { id: 'KOPCP', text: 'KOPCP', group: 13 },
    // { id: 'START', text: 'START', group: 15 },
    // { id: 'END', text: 'END', group: 16 },
]

export default TaskTypes
