import React from 'react'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles, Typography } from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

import { PropTypes } from 'prop-types'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'

import TaskDetailFooterView from './TaskDetailFooterView'

import styles from './styles'

class TaskdetailFooter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentTask: props.currentTask,
            mounted: false,
        }
    }

    static propTypes = {
        currentTask: PropTypes.string.isRequired,
        tasks: PropTypes.array.isRequired,
        location: PropTypes.any,
        history: PropTypes.any,
        classes: PropTypes.any,
        width: PropTypes.string,
        isDriveDetailsLoading: PropTypes.bool,
    }
    getCorrectIndex = taskId => {
        const { tasks } = this.props
        const getIndex = tasks.findIndex(item => item.id === taskId)
        return getIndex
    }

    componentDidMount() {
        const { location, tasks } = this.props
        const { currentTask } = this.state
        let nextTask = 0
        let previousTask = 0

        if (typeof currentTask === 'number') {
            nextTask = parseInt(currentTask) + 1
            previousTask = parseInt(currentTask) - 1
        }

        if (typeof currentTask !== 'number') {
            nextTask = parseInt(this.getCorrectIndex(currentTask)) + 1
            previousTask = parseInt(this.getCorrectIndex(currentTask)) - 1
        }

        const strip = location.pathname.substr(0, location.pathname.lastIndexOf('/'))
        const max = tasks.length - 1

        this.setState({
            max,
            currentTask: parseInt(this.getCorrectIndex(currentTask)),
            nextTask,
            previousTask,
            strip,
            tasks,
            mounted: true,
        })
    }

    updateState = newTask => {
        const { location, tasks } = this.props

        const nextTask = parseInt(newTask) + 1
        const previousTask = parseInt(newTask) - 1
        const strip = location.pathname.substr(0, location.pathname.lastIndexOf('/'))

        this.setState({
            currentTask: newTask,
            nextTask,
            previousTask,
            strip,
            tasks,
            mounted: true,
        })
    }

    goToNext = () => {
        const { strip, nextTask, tasks } = this.state
        const { history, isDriveDetailsLoading } = this.props
        if (!isDriveDetailsLoading) {
            this.updateState(nextTask)
            history.push(`${strip}/${tasks[nextTask].id}`)
        }
    }

    goToPrevious = () => {
        const { strip, previousTask, tasks } = this.state
        const { history, isDriveDetailsLoading } = this.props
        if (!isDriveDetailsLoading) {
            this.updateState(previousTask)
            history.push(`${strip}/${tasks[previousTask].id}`)
        }
    }

    returnTime = value => {
        if (value === null) return null
        return value.substr(0, 5)
    }

    render() {
        const { max, nextTask, previousTask, mounted, currentTask } = this.state
        const { classes, tasks } = this.props

        if (!mounted) return <div />

        return (
            <TaskDetailFooterView {...this.props}>
                {parseInt(currentTask) > 0 && (
                    <div>
                        <ArrowBack className={classes.navIcon} onClick={this.goToPrevious} />
                        <Typography
                            className={classes.text}
                            variant={isWidthUp('sm', this.props.width) ? 'h5' : 'body1'}
                        >{` ${tasks[previousTask].code} (${this.returnTime(
                            tasks[previousTask].plannedStartTime
                        )})`}</Typography>
                    </div>
                )}

                {parseInt(currentTask) === 0 && <div />}

                {parseInt(currentTask) < max && (
                    <div>
                        <Typography
                            className={classes.text}
                            variant={isWidthUp('sm', this.props.width) ? 'h5' : 'body1'}
                        >{`${tasks[nextTask].code} (${this.returnTime(tasks[nextTask].plannedStartTime) ||
                            this.returnTime(tasks[nextTask].plannedEndTime)}) `}</Typography>
                        <ArrowForward className={classes.navIcon} onClick={this.goToNext} />
                    </div>
                )}
            </TaskDetailFooterView>
        )
    }
}

export default compose(withWidth(), withRouter, withStyles(styles), withLocalize)(TaskdetailFooter)
