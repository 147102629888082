import React from 'react'
import { object, func } from 'prop-types'
import { withLocalize, Translate } from 'react-localize-redux'
import { compose } from 'recompose'
import { withStore, util, withStatus, enums, withClear } from 'react-ion-store'

import getNormalizedMessage from 'utils/getNormalizedMessage'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import PersonSearcher from 'components/PersonSearcher'
import PrimaryButton from 'components/PrimaryButton'
import ErrorMessage from 'components/ErrorMessage'
import AssignDriverCall from '../../AssignDriverDialog/AssignDriverCall'
import { ReplaceDialog } from '../../Dialogs/ReplaceDialog'
import { DeselectedPerformanceDialog } from '../../Dialogs/DeselectedPerformanceDialog'

import styles from './styles'
import Loading from 'components/Loading'

const resetState = {
    selectedName: '',
    selectedProfile: undefined,
    allreadyClosed: false,
}

const {
    CallStatus: { LOADING, READY },
} = enums

class NotAssignedDetailView extends React.PureComponent {
    static propTypes = {
        classes: object.isRequired,
        closeDrawer: func.isRequired,
        performance: object.isRequired,
        __ion_status: object.isRequired,
        store: object.isRequired,
        clearAssignedCall: func,
        handleCheckbox: func,
        translate: func,
    }

    state = {
        ...resetState,
        actionWasSet: false,
        isOpen: false,
        savedDriver: {},
        responseData: [],
        isLocked: this.props.performance.isLocked,
        isLockedError: '',
        openDeselectedPerformanceDialog: false,
    }

    setName = selectedProfile =>
        this.setState({
            selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
            selectedProfile,
        })

    clearName = () => this.setState(resetState)

    persistAssignDriver = (idf, id, replace = false) => {
        this.setState(
            {
                ...resetState,
                actionWasSet: 'assignDriverCall',
                savedDriver: { idf, id },
                replace: replace,
                allreadyClosed: false,
            },
            () => {
                const assignDriverCall = util.CreateStoreObject({ idf, id, replace })
                this.props.store.call({ assignDriverCall })
            }
        )
    }

    handleClose = () => {
        this.setState({ isOpen: false, allreadyClosed: true, responseData: [] })
        this.props.clearAssignedCall()
    }

    handleOpen = data => {
        this.setState({ isOpen: true, responseData: data })
    }

    lockUnlock = async (e, id) => {
        const { isLocked } = this.state
        const { handleCheckbox, translate } = this.props

        const doCall = await handleCheckbox(e, id, isLocked, null)

        if (typeof doCall === 'string' && doCall.indexOf('Action not allowed') !== -1) {
            this.setState({ isLockedError: translate('roles_error') })
            return
        }
        if (doCall) {
            this.setState({ isLocked: !isLocked })
        } else {
            this.setState({ isLockedError: 'An error occured' })
        }
    }

    componentDidUpdate(prevProps) {
        const { actionWasSet } = this.state

        if (
            actionWasSet &&
            prevProps.__ion_status[actionWasSet] &&
            prevProps.__ion_status[actionWasSet] !== READY &&
            this.props.__ion_status[actionWasSet] === READY
        ) {
            this.props.closeDrawer(true)
        }
    }

    render() {
        const { performance, classes, __ion_status } = this.props
        const { selectedName, selectedProfile, isOpen, savedDriver, allreadyClosed, isLocked, isLockedError } = this.state
        const { assignDriverCall } = __ion_status

        if (assignDriverCall === 4 && this.props.store.assignDriverCall !== undefined && !allreadyClosed) {
            if (
                getNormalizedMessage(this.props.store.assignDriverCall._root.entries[2][1].response.data).indexOf(
                    'Profile already has an overlapping performance'
                ) !== -1
            ) {
                this.handleOpen(this.props.store.assignDriverCall._root.entries[2][1].response.data)
            }
            if (
                getNormalizedMessage(this.props.store.assignDriverCall._root.entries[2][1].response.data).indexOf(
                    'Profile has an deselected performance'
                ) !== -1
            ) {
                this.setState({ openDeselectedPerformanceDialog: true })
                this.handleOpen(this.props.store.assignDriverCall._root.entries[2][1].response.data)
            }
        }

        return (
            <Translate>
                {({ translate }) => (
                    <React.Fragment>
                        <Typography variant="h6">{translate('performances_label_assign_driver')}</Typography>
                        <hr style={{ marginBottom: 16 }} />
                        <div
                            className={classes.mb}
                            name="PersonSearcherWrapper"
                            tabIndex={0}
                            style={{ position: 'relative' }}
                        >
                            <PersonSearcher
                                selectedName={selectedName}
                                setName={this.setName}
                                clearName={this.clearName}
                                type="app"
                                disabled={checkFunctionalityDisabled(
                                    this.props.store,
                                    'change driver',
                                    'performances.functionality.sidebar'
                                )}
                            />
                        </div>
                        <PrimaryButton
                            disabled={!selectedName || assignDriverCall === LOADING}
                            onClick={() => {
                                if (selectedProfile)
                                    this.persistAssignDriver(selectedProfile.idfNumber, performance.performanceId)
                            }}
                        >
                            {translate('performances_label_assign_driver')}
                        </PrimaryButton>
                        {assignDriverCall === 4 &&
                            this.props.store.assignDriverCall !== undefined &&
                            getNormalizedMessage(
                                this.props.store.assignDriverCall._root.entries[2][1].response.data
                            ).indexOf('Action not allowed') !== -1 && (
                                <ErrorMessage spacing={false} error={translate('roles_error')} />
                            )}

                        {assignDriverCall === 4 &&
                            this.props.store.assignDriverCall !== undefined &&
                            getNormalizedMessage(
                                this.props.store.assignDriverCall._root.entries[2][1].response.data
                            ).indexOf('No current profile found in request') !== -1 && (
                                <ErrorMessage spacing={false} error="No current profile found in request" />
                            )}

                        <AssignDriverCall />

                        {assignDriverCall === LOADING && (
                            <div style={{ height: '100px' }}>
                                <Loading />
                            </div>
                        )}
                        {this.state.openDeselectedPerformanceDialog ? 
                        <DeselectedPerformanceDialog
                            __ion_status={__ion_status}
                            open={isOpen}
                            handleClose={this.handleClose}
                            translate={translate}
                            // persistAssignDriver={this.persistAssignDriver}
                            savedDriver={savedDriver}
                            util={util}
                            store={this.props.store}
                            responseData={this.state.responseData}
                        />
                        :
                        <ReplaceDialog
                            __ion_status={__ion_status}
                            open={isOpen}
                            handleClose={this.handleClose}
                            translate={translate}
                            // persistAssignDriver={this.persistAssignDriver}
                            savedDriver={savedDriver}
                            util={util}
                            store={this.props.store}
                            responseData={this.state.responseData}
                        />
                        }
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isLocked}
                                        onChange={() => this.lockUnlock('isLocked', performance.performanceId)}
                                        value="isLocked"
                                        color="primary"
                                        disabled={checkFunctionalityDisabled(
                                            this.props.store,
                                            'lock_unlock_performance',
                                            'performances.functionality.sidebar'
                                        )}
                                    />
                                }
                                label="Locked"
                            />
                            {isLockedError.length > 0 && <ErrorMessage spacing={false} error={isLockedError} />}
                        </div>
                    </React.Fragment>
                )}
            </Translate>
        )
    }
}

export default compose(
    withStyles(styles),
    withLocalize,
    withStore,
    withStatus,
    withClear('clearAssignedCall', 'assignDriverCall')
)(NotAssignedDetailView)
