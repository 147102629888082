import React, { useEffect, useState } from 'react'
import { func, string, object, array } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus } from 'react-ion-store'
import { withStyles } from '@material-ui/core/styles'
import { DateTime } from 'luxon'

import AddNewUserDrawerView from './AddNewUserDrawerView'

import removeUserFromFeature from 'modules/MobileFeatures/Services/removeUserFromFeature'
import getUsersByFeatureId from 'modules/MobileFeatures/Services/getUsersByFeatureId'
import addUserToFeature from 'modules/MobileFeatures/Services/addUserToFeature'
import addOrganisationToFeature from 'modules/MobileFeatures/Services/addOrganisationToFeature'
import getOrganisationsByFeatureId from 'modules/MobileFeatures/Services/getOrganisationsByFeatureId'
import removeOrganisationFromFeature from 'modules/MobileFeatures/Services/removeOrganisationFromFeature'

import styles from './styles'

const AddNewUserDrawer = props => {
    AddNewUserDrawer.propTypes = {
        onClose: func.isRequired,
        addNewUserToFeatureId: string,
        featureName: string,
        store: object.isRequired,
        OrganisationsData: array,
        translate: func,
    }
    const [selectedName, setSelectedName] = useState('')
    const [selectedProfile, setSelectedProfile] = useState(undefined)
    const [userListForFeatureId, setUserList] = useState([])
    const [organosationListForFeatureId, setOrganosationListForFeatureId] = useState([])
    const [addNewUserCallError, setAddNewUserError] = useState('')
    const [addNewUserCallLoading, setAddNewUserCallLoading] = useState(false)
    const [existingUserId, setUserId] = useState(null)
    const [isUserListLoading, setIsUserListLoading] = useState(false)
    const [locationSearchValue, setLocationSearchValue] = useState({})
    const [selectedLocationSearchItem, setSelectedLocationSearchItem] = useState({})
    const [searchValue, setSearchValue] = useState('')
    const [userListFolded, setUserListFolded] = useState(true)
    const [organisationListFolded, setOrganisationListFolded] = useState(true)
    const [addNewOrganisationCallLoading, setAddNewOrganisationCallLoading] = useState(false)
    const [isOrganisationListLoading, setIsOrganisationListLoading] = useState(false)
    const [addNewOrganisationError, setAddNewOrganisationError] = useState('')
    const [addNewOrganisationDatesError, setAddNewOrganisationDatesError] = useState('')
    const [validFrom, setValidFrom] = useState(DateTime.local().setZone('Europe/Brussels'))
    const [validTo, setValidTo] = useState(DateTime.local().plus({ years: 20 }).setZone('Europe/Brussels'))
    const [isValidDates, setIsValidDates] = useState(true)
    
    const handleLocationSearchValue = e => {
        if (e === null) {
            setLocationSearchValue({})
            setSelectedLocationSearchItem({})
            setValidFrom(DateTime.local().setZone('Europe/Brussels'))
            setValidTo(DateTime.local().plus({ years: 20 }).setZone('Europe/Brussels'))
            setAddNewOrganisationError('')
        } else {
            setLocationSearchValue(e.value)
            setSelectedLocationSearchItem(e)
            setAddNewOrganisationError('')
        }
    }

    useEffect(() => {
        validateOrgDates()
    }, [validFrom, validTo])

    const validateOrgDates = () => {
        setAddNewOrganisationDatesError('')
        if(!validFrom.invalid && !validTo.invalid){
            if(validFrom < validTo){
                return setIsValidDates(true)
            }
            setAddNewOrganisationDatesError(props.translate('mobile_features_invalid_dates_error'))
            return setIsValidDates(false)
        }
        return setIsValidDates(false)
    }

    const clearLocationSearchValue = () => {
        setSearchValue('')
        setAddNewOrganisationError('')
    }

    const filterLocationData = data => {
        if (locationSearchValue === '') return data
        return data.filter(item => item.label === locationSearchValue || item.code === locationSearchValue)
    }


    const setName = profile => {
        setSelectedName(`${profile.firstName} ${profile.lastName}`)
        setSelectedProfile(profile)
        let index = isUserExistInFeature(profile)
        if (index > -1) setUserId(userListForFeatureId[index].idfNumber)
    }
    const isUserExistInFeature = profile => {
        if (userListForFeatureId)
            return userListForFeatureId.findIndex(function(el) {
                return el.idfNumber === profile.idfNumber
            })
        else return -1
    }
    useEffect(() => {
        getUserListForFeatureId()
        getOrganisationListForFeatureId()
    }, [])
    const getUserListForFeatureId = async () => {
        setIsUserListLoading(true)
        if (props.addNewUserToFeatureId) {
            let response = await getUsersByFeatureId(props.addNewUserToFeatureId)

            if (typeof response.data === 'string') {
                setUserList([])
            } else {
                setUserList(response.data)
            }
            resetToDefault()
        }
        setIsUserListLoading(false)
    }

    const getOrganisationListForFeatureId = async () => {
        setIsOrganisationListLoading(true)
        let response = await getOrganisationsByFeatureId(props.addNewUserToFeatureId)
        if (response.status === 200) {
            setOrganosationListForFeatureId(response.data)
        } else {
            setOrganosationListForFeatureId([])
        }
        setIsOrganisationListLoading(false)
    }
    const resetToDefault = () => {
        setAddNewUserCallLoading(false)
        setAddNewUserError('')
        setUserId(null)
        setSelectedName('')
        setSelectedProfile(undefined)
    }
    const clearName = () => {
        setSelectedName('')
        setSelectedProfile(undefined)
        setAddNewUserError('')
    }

    const onClose = () => {
        clearName()
        props.onClose()
    }

    const handleDeleteUser = idfNumber => {
        setUserListFolded(false)
        setAddNewUserError('')
        setAddNewUserCallLoading(true)
        let response = removeUserFromFeature(props.addNewUserToFeatureId, idfNumber)
        response
            .then(() => {
                getUserListForFeatureId()
            })
            .catch(e => {
                console.error(e)
                setAddNewUserCallLoading(false)
                setAddNewUserError(e.response.data.Message)
            })
    }

    const handleDeleteOrganisation = featureOrganisationId => {
        setAddNewOrganisationCallLoading(true)
        setAddNewOrganisationError('')
        let response = removeOrganisationFromFeature(props.addNewUserToFeatureId, featureOrganisationId)
        response
            .then(() => {
                getOrganisationListForFeatureId()
                setAddNewOrganisationCallLoading(false)
            })
            .catch(e => {
                console.error(e)
                setAddNewOrganisationCallLoading(false)
                setAddNewOrganisationError(e.response.data.Message)
            })
        
        
    }
    const addNewUserCall = () => {
        setUserListFolded(false)
        setAddNewUserCallLoading(true)
        setAddNewUserError('')  
        let response = addUserToFeature(props.addNewUserToFeatureId, selectedProfile.idfNumber)
        response
            .then(() => {
                getUserListForFeatureId()
            })
            .catch(e => {
                console.error(e)
                setAddNewUserCallLoading(false)
                setAddNewUserError(e.response.data.Message)
            })
    }

    const addOrganisation = () => {
        setAddNewOrganisationCallLoading(true)
        setAddNewOrganisationError('')
        if(validFrom > validTo){
            setAddNewOrganisationError(props.translate('mobile_features_invalid_dates_error'))
            setAddNewOrganisationCallLoading(false)
            return
        }
        {/* eslint-disable-next-line max-len */}
        let response = addOrganisationToFeature(props.addNewUserToFeatureId, selectedLocationSearchItem.idString, validFrom.toISO(), validTo.toISO())
        response
            .then(() => {
                getOrganisationListForFeatureId()
                setAddNewOrganisationCallLoading(false)
                setOrganisationListFolded(false)
            })
            .catch(e => {
                console.error(e)
                if (
                    e.response.status === 400
                ) {
                    setAddNewOrganisationCallLoading(false)
                    setOrganisationListFolded(false)
                    setAddNewOrganisationError(e.response.data.Message)
                }
            })
    }

    const locationList = props.OrganisationsData

    const filtered = locationList ? filterLocationData(locationList) : []
    return (
        <AddNewUserDrawerView
            {...props}
            selectedName={selectedName}
            selectedProfile={selectedProfile}
            handleDeleteUser={handleDeleteUser}
            setName={setName}
            clearName={clearName}
            onClose={onClose}
            userListForFeatureId={userListForFeatureId}
            addNewUserCall={addNewUserCall}
            addNewUserCallError={addNewUserCallError}
            addNewUserCallLoading={addNewUserCallLoading}
            featureName={props.featureName}
            existingUserId={existingUserId}
            isUserListLoading={isUserListLoading}
            OrganisationsData={locationList}
            filteredLocations={filtered}
            handleLocationSearchValue={handleLocationSearchValue}
            selectedLocationSearchItem={selectedLocationSearchItem}
            addOrganisation={addOrganisation}
            clearLocationSearchValue={clearLocationSearchValue}
            searchValue={searchValue}
            locationSearchValue={""}
            userListFolded={userListFolded}
            setUserListFolded={setUserListFolded}
            organisationListFolded={organisationListFolded}
            setOrganisationListFolded={setOrganisationListFolded}
            organosationListForFeatureId={organosationListForFeatureId}
            handleDeleteOrganisation={handleDeleteOrganisation}
            addNewOrganisationCallLoading={addNewOrganisationCallLoading}
            isOrganisationListLoading={isOrganisationListLoading}
            addNewOrganisationError={addNewOrganisationError}
            setOrganisationErrorMessage={setAddNewOrganisationError}
            validFrom={validFrom}
            setValidFrom={setValidFrom}
            validTo={validTo}
            setValidTo={setValidTo}
            addNewOrganisationDatesError={addNewOrganisationDatesError}
            isValidDates={isValidDates}
        />
    )
}

export default compose(withStore, withStatus, withStyles(styles), withLocalize)(AddNewUserDrawer)
