export const getTime = time => (time ? time.substring(0, 5) : '')

const getDiff = (start, end) => {
    let startHours = start.substring(0, 2)
    let startMin = start.substring(3, 5)
    let endHours = end.substring(0, 2)
    let endMin = end.substring(3, 5)

    endHours = endHours < startHours ? endHours + 24 : endHours
    let startD = new Date().setHours(startHours, startMin)
    let endD = new Date().setHours(endHours, endMin)
    return endD - startD
}
const getCategoryValue = (value3, value2, value1) => {
    if (value3) return value3
    if (value2) return value2
    return value1
}
// export const getTimeDiff = (start, end) => {
//     if (start && end) {
//         let diff = getDiff(start, end)
//         let mins = Math.floor(diff / 60000)
//         let hrs = Math.floor(mins / 60)
//         hrs = hrs < 10 ? '0' + hrs : hrs
//         let min = mins % 60
//         return `${hrs}:${min < 10 ? '0' + min : min}`
//     }
//     return ''
// }

export const timeDiffOver5Min = (start, end) => {
    if (start && end) {
        let diff = getDiff(start, end)
        return Math.floor(diff / 60000) > 5
    }
    return ''
}
// export const getFeedbackList = (row, businessTag) => {
//     return row
//         .filter(data => data.businessTags.includes(businessTag))
//         .map(({ feedbackFreeText, feedbackCategory3Value, feedbackCategory2Value, feedbackCategory1Value }) => ({
//             freetext: feedbackFreeText,
//             category: getCategoryValue(feedbackCategory3Value, feedbackCategory2Value, feedbackCategory1Value),
//         }))
// }
export const getTasksWithFeedback = data =>
    data.tasks.filter(task => {
        const routeFeedback = task.route.filter(driveRoute => driveRoute.feedback.length > 0)
        return task.feedback.length > 0 || routeFeedback.length > 0
    })
export const getFeedbackWithRoute = data => {
    let tasksWithFeedback = getTasksWithFeedback(data)
    let dataArray = []
    tasksWithFeedback.map(dataObj => {
        let copyDataObj = { ...dataObj }
        if (copyDataObj.code === 'DRIVE') {
            let feedbackArray = [...copyDataObj.feedback]
            let routeFeedback = copyDataObj.route.filter(routeObj => routeObj.feedback.length > 0)
            let newArray = routeFeedback.map(routeRow => {
                let { feedback, ...withoutFeedback } = routeRow
                let array = []
                feedback.map(routeLevelFeedback => {
                    let withoutFeedbackObj = { ...withoutFeedback }
                    withoutFeedbackObj.feedback = routeLevelFeedback
                    array.push(withoutFeedbackObj)
                })
                return array
            })
            let merge = [...feedbackArray, ...newArray]
            copyDataObj.feedback = merge.flat()
        }
        dataArray.push(copyDataObj)
    })
    return dataArray
}
export const getDeepFeedback = feedback => {
    return feedback.map(
        ({
            feedbackFreeText,
            feedbackCategory3Value,
            feedbackCategory2Value,
            feedbackCategory1Value,
            feedbackImagesWithBlobUrl,
        }) => ({
            freetext: feedbackFreeText,
            category: getCategoryValue(feedbackCategory3Value, feedbackCategory2Value, feedbackCategory1Value),
            imageLinks: feedbackImagesWithBlobUrl,
        })
    )
}
export const getVehicleNumber = row => {
    return row
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .reduce((acc, data) => acc + `${data.vehicleNumber ? data.vehicleNumber : data.diagramNumber} `, '')
}

// export const getFeedbackCategoryCount = (data, tag) => {
//     return data.filter(feedback => feedback.businessTags.includes(tag)).length
// }

export const getFeedbackOnCategoryLevel = data => {
    const feedbackArray = data.feedback
    let tasksFeedback = []
    data.tasks.map(feedbackObj => {
        feedbackObj.feedback.map(row => {
            tasksFeedback.push(row)
        })
        if (feedbackObj.code === 'DRIVE') {
            feedbackObj.route.map(routeObj => {
                let routefeedback = routeObj.feedback
                if (routefeedback.length > 0) {
                    routefeedback.map(routefeedbackObj => {
                        let copyRouteFeedbackObj = { ...routefeedbackObj }
                        copyRouteFeedbackObj.arrivalTime = routeObj.arrivalTime
                        copyRouteFeedbackObj.departureTime = routeObj.departureTime
                        copyRouteFeedbackObj.realArrivalTime = routeObj.realArrivalTime
                        copyRouteFeedbackObj.realDepartureTime = routeObj.realDepartureTime
                        copyRouteFeedbackObj.locationFr = routeObj.locationFr
                        copyRouteFeedbackObj.locationNl = routeObj.locationNl
                        copyRouteFeedbackObj.passTroughTime = routeObj.passTroughTime
                        copyRouteFeedbackObj.realPassTroughTime = routeObj.realPassTroughTime
                        tasksFeedback.push(copyRouteFeedbackObj)
                    })
                }
            })
        }
    })

    const merger = [...feedbackArray, ...tasksFeedback]

    const groupByAllCat = groupBy(['feedbackCategory1Value', 'feedbackCategory2Value', 'feedbackCategory3Value'])
    return groupByAllCat(merger)
}

const groupBy = keys => array =>
    array.reduce((objectsByKeyValue, obj) => {
        const value = keys.map(key => obj[key]).join('-')
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
        return objectsByKeyValue
    }, {})

export const getOthersCategoryCount = data => {
    return data.filter(
        function(e) {
            return this.indexOf(e) < 0
        },
        ['M', 'S', 'E', 'TC', 'D233']
    ).length
}

export const checkForEdriveApp = () => {
    const UA = navigator.userAgent

    return UA.includes('eDriveApp')
}
export const handleImageClick = link => {
    if (checkForEdriveApp()) {
        let image_window = window.location.replace(`${link}`)
        image_window.document.write(`
      <html>
        <head>
        </head>
        <body>
          <img src=${link} alt="Example" height="100%" width="auto">
        </body>
      </html>
`)
    } else {
        let image_window = window.open('', '_blank')
        image_window.document.write(`
      <html>
        <head>
        </head>
        <body>
          <img src=${link} alt="Example" height="100%" width="auto">
        </body>
      </html>
`)
    }
}
