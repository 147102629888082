import React, { useState, useEffect } from 'react'
import { object } from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Header from 'components/Header'
import IconButton from 'components/IconButton'
import DriverDrawer from '../Manage/components/DriverDrawer'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import { TranslationContext } from 'context/translation'

const SearchAgenda = ({ classes, history }) => {
    const [isOpen, setOpen] = useState(false)

    const { translate } = React.useContext(TranslationContext)

    useEffect(() => {
        document.title = translate('driver_agenda')
    })

    return (
        <React.Fragment>
            <Header isMenu title={translate('driver_agenda')} />
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <div className={classes.submenu}>
                        <Typography className={classes.submenuTitle} variant="h6">
                            {translate('driver_agenda')}
                        </Typography>
                        <IconButton
                            className={classes.button}
                            icon="calendar"
                            title={translate('module_calendar')}
                            onClick={() => {
                                setOpen(true)
                            }}
                        />
                    </div>
                </Grid>
            </Grid>

            <React.Fragment>
                <DriverDrawer history={history} open={isOpen} onClose={() => setOpen(false)} />
            </React.Fragment>
        </React.Fragment>
    )
}

SearchAgenda.propTypes = {
    classes: object.isRequired,
    history: object.isRequired,
}
export default withStyles(styles)(SearchAgenda)
