import React from 'react'
import { func, bool } from 'prop-types'

import PrimaryButton from 'components/PrimaryButton'
import { Translate } from 'react-localize-redux'

const ClearCacheButtonView = ({ setToClear, disabled }) => {
    return (
        <Translate>
            {({ translate }) => (
                <PrimaryButton
                    title={translate('documents_label_clear_cache_send_notice')}
                    onClick={setToClear}
                    disabled={disabled}
                />
            )}
        </Translate>
    )
}

ClearCacheButtonView.propTypes = {
    setToClear: func.isRequired,
    disabled: bool.isRequired,
}

export default ClearCacheButtonView
