import React from 'react'
import { bool, array, object, func, number } from 'prop-types'
import { compose } from 'recompose'
import orderBy from 'lodash/orderBy'
import { withStore, withTake } from 'react-ion-store'
import { DateTime } from 'luxon'
// Material UI
import { withStyles } from '@material-ui/core'

// Components
import Loading from 'components/Loading'
import StatusTab from '../StatusTab'
import PerformancesTable from '../PerformancesTable'
import withMappedPerformances from '../withMappedPerformances'

import { statusEnum, statusToTab, tabToStatus } from 'utils/statusEnum'
import mapWithBitwise from 'utils/mapWithBitwise'
// import dateParser from "utils/dateParser";

import styles from './../../styles'
import MusicPlayer from '../PerformancesTable/components/MusicPlayer'
import DefaultSettings from 'components/DefaultSettings'

const mapStatusToColor = ['normal', 'yellow', 'red']

class PerformancesByDay extends React.Component {
    static propTypes = {
        classes: object.isRequired,
        date: number.isRequired,
        performances: array.isRequired,
        performancesIsLoading: bool.isRequired,
        selectPerformance: func.isRequired,
        openAssignDriverDialog: func,
        openUnassignDriverDialog: func,
        openDelayReasonDialog: func,
        toggleCollapse: func,
        storage: object,
        saveScrollPosition: func,
        scrollPosition: number,
        openPerformanceDriveDetailsDrawer: func,
        openDeletePerformanceDialog: func,
        openPerformanceNotificationDrawer: func,
        saveActiveTab: func,
    }

    state = {
        activeTabIndex: statusEnum.NOT_SIGNED_IN,
    }

    getPerformanceData = status => {
        // returns getPerformances and getTabData
        const statusAggregate = tabToStatus.map(() => ({ red: 0, yellow: 0 }))

        const performances = orderBy(
            this.props.performances.filter(p => {
                const rowColor = mapStatusToColor[p.status]
                if (rowColor === 'red' || rowColor === 'yellow') {
                    const mappedTab = statusToTab[p.tab]
                    statusAggregate[mappedTab][rowColor] = statusAggregate[mappedTab][rowColor] + 1
                }

                return mapWithBitwise(status, p.tab)
            }),
            status === statusEnum.SIGNED_IN ? ['plannedEndTime'] : ['plannedStartTime'],
            'asc'
        )

        return [performances, statusAggregate]
    }

    handleTabIndexChanged = (_, value) => {
        this.setState({ activeTabIndex: tabToStatus[value], scrollPosition: 0 })
        this.props.storage.set({ trainNumber: undefined }) // trainNumber undefine on index change
        this.props.saveActiveTab(tabToStatus[value])
    }
    checkPerformanceForSound = performances => {
        const settings = localStorage.getItem('userSettings')
            ? JSON.parse(localStorage.getItem('userSettings'))
            : DefaultSettings
        let userSettingsSoundTime = settings.filter(obj => obj.pageReference === 'performanceOverview')[0].warningSignal
        const alertSeconds = parseInt(userSettingsSoundTime) * 60
        const currentTime = parseInt(DateTime.local().toSeconds())

        for (let i = 0; i < performances.length; i++) {
            const plannedStart = performances[i].plannedStartTime
            const diff = plannedStart - currentTime

            if (alertSeconds < 0 && diff > 0) {
                //alert sound shoud beep once. we are adding 20 seconds range for the refersh.
                //diff should be less than alertSeconds upto 20 seconds. 20 seconds is for byparam call time.
                if (diff - 10 <= Math.abs(alertSeconds) && diff + 40 >= Math.abs(alertSeconds)) {
                    return true
                }
            }
            if (alertSeconds >= 0 && diff)
                if (performances[i].tab === 2 && diff < alertSeconds && performances[i].plannedStartDelay > 0) {
                    if (alertSeconds + diff <= 0) {
                        return true
                    }
                }
        }
        return false
    }
    render() {
        const {
            classes,
            selectPerformance,
            performancesIsLoading,
            storage,
            saveScrollPosition,
            scrollPosition,
            openPerformanceDriveDetailsDrawer,
            openDeletePerformanceDialog,
            openPerformanceNotificationDrawer,
        } = this.props
        const [performances, tabData] = this.getPerformanceData(this.state.activeTabIndex)

        return (
            <React.Fragment>
                <div className={classes.performancesRoot}>
                    <StatusTab
                        activeTabIndex={statusToTab[this.state.activeTabIndex]}
                        onTabIndexChanged={this.handleTabIndexChanged}
                        tabData={tabData}
                    />

                    {performancesIsLoading ? (
                        <Loading />
                    ) : (
                        <React.Fragment>
                            <PerformancesTable
                                saveScrollPosition={saveScrollPosition}
                                selectPerformance={selectPerformance}
                                activeTab={this.state.activeTabIndex}
                                scrollPosition={scrollPosition}
                                performances={performances}
                                storage={storage}
                                openPerformanceDriveDetailsDrawer={openPerformanceDriveDetailsDrawer}
                                // openDelayReasonDialog={this.props.openDelayReasonDialog}
                                openDeletePerformanceDialog={openDeletePerformanceDialog}
                                openPerformanceNotificationDrawer={openPerformanceNotificationDrawer}
                            />
                            {this.checkPerformanceForSound(performances) && (
                                <MusicPlayer identifier={'performanceOverview'} />
                            )}
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        )
    }
}

export default compose(withStore, withTake('currentTime'), withStyles(styles), withMappedPerformances)(PerformancesByDay)
