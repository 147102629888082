import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/styles'
import { object, func } from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core'

import Header from 'components/Header'
import DeleteDialog from './DeletePtcarClassificationDialog'
import Loading from 'components/Loading'
import ManagePtcarClassificationTable from './ManagePtcarClassificationTable'
import PtcarClassificationDrawer from './PtcarClassificationDrawer'

import getPtcarClassification from 'modules/Manage/Services/getPtcarClassification'
import addOrUpdateClassification from 'modules/Manage/Services/addOrUpdateClassification'

import styles from './styles'
import { withStore } from 'react-ion-store'

const ManagePtcarClassifications = props => {
    const [ptcarClassifications, setPtcarClassifications] = useState([])
    const [isDrawerOpen, setDrawerOpen] = useState(false)
    const [isDialogOpen, setDialogOpen] = useState(false)
    const [openDrawerForEditing, setOpenDrawerForEditing] = useState(false)
    const [dataForEditing, setDataForEditing] = useState(null)
    const [deleteData, setDataForDelete] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const onDrawerClose = () => {
        setDrawerOpen(false)
    }

    const onDrawerOpen = (data, isEdit) => {
        setDrawerOpen(true)
        setOpenDrawerForEditing(isEdit)
        setDataForEditing(data)
    }
    const onDialogClose = () => {
        setDialogOpen(false)
    }

    const onDialogOpen = (e, rowData) => {
        setDialogOpen(true)
        setDataForDelete(rowData)
    }
    const handleAddOrEditClassification = () => {
        setDrawerOpen(true)
        setDataForEditing(null)
        setOpenDrawerForEditing(false)
    }
    const handleAddOrEdit = async (data, edit) => {
        const response = await addOrUpdateClassification(data, edit)
        if (response) {
            loadClassifications()
            setDrawerOpen(false)
        }
    }
    const loadClassifications = async () => {
        setIsLoading(true)
        setDialogOpen(false)
        const response = await getPtcarClassification()
        if (response.status === 204) {
            setPtcarClassifications([])
            setIsLoading(false)
            return
        }
        if (response.status === 200) {
            let types = response.data.map(({ edriveId, shortName, longName }) => ({
                Id: edriveId,
                ShortNameNl: shortName[1].translation,
                LongNameNl: longName[1].translation,
                ShortNameFr: shortName[0].translation,
                LongNameFr: longName[0].translation,
            }))
            setPtcarClassifications(types)
            setIsLoading(false)
        }
    }
    useEffect(() => {
        function fetchData() {
            loadClassifications()
        }
        fetchData()
    }, [])

    return (
        <>
            <Header title={props.translate('manage_ptcar_classification_button')} />

            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className={props.classes.addButtonContainer}>
                        <Typography variant="h6">Ptcar Classfications</Typography>
                        <Button variant="contained" color="primary" onClick={handleAddOrEditClassification}>
                            {props.translate('manage_ptcar_classification_add_button')}
                        </Button>
                    </div>

                    <ManagePtcarClassificationTable
                        classes={props.classes}
                        ptcarClassifications={ptcarClassifications}
                        onDrawerClose={onDrawerClose}
                        onDrawerOpen={onDrawerOpen}
                        onDialogClose={onDialogClose}
                        onDialogOpen={onDialogOpen}
                        store={props.store}
                    />

                    {isDrawerOpen && (
                        <PtcarClassificationDrawer
                            isOpen={isDrawerOpen}
                            onClose={onDrawerClose}
                            openDrawerForEditing={openDrawerForEditing}
                            data={dataForEditing}
                            handleAddOrEdit={handleAddOrEdit}
                            {...props}
                        />
                    )}
                    <DeleteDialog
                        deleteData={deleteData}
                        dialogOpen={isDialogOpen}
                        onDialogClose={onDialogClose}
                        translate={props.translate}
                        reloadData={loadClassifications}
                    />
                </>
            )}
        </>
    )
}
ManagePtcarClassifications.propTypes = {
    classes: object,
    activeLanguage: object,
    translate: func,
    store: object,
}
export default compose(withLocalize, withStore, withStyles(styles))(ManagePtcarClassifications)
