/* eslint-disable react/prop-types */
import React from 'react'
import { func, object, bool, string } from 'prop-types'
import { compose, mapProps } from 'recompose'
import { withStore, withCall, enums, withClear, util, withStatus } from 'react-ion-store'

import AutocompleteView from './AutocompleteView'

import queryParamEncoder from 'utils/queryParamEncoder'

const {
    CallStatus: { LOADING, READY, FAILED },
} = enums

const callKey = 'suggestions'
const callKeyApp = 'appSuggestions'

class Autocomplete extends React.PureComponent {
    static propTypes = {
        clearSuggestions: func.isRequired,
        __ion_status: object.isRequired,
        visible: bool.isRequired,
        selectedOrganisation: string,
        store: object,
        closeSuggestions: func,
        props: object,
    }

    state = {
        infoVisible: true,
    }

    componentDidUpdate = () => {
        this.loadMore()
    }

    loadMore = () => {
        const { store, __ion_status } = this.props
        const { token, usedTokens, data } = store.get(callKey) || {}

        if (token) {
            let newUsedTokens

            if (usedTokens && !usedTokens.includes(token)) {
                newUsedTokens = [...usedTokens, token]
            } else if (!usedTokens) {
                newUsedTokens = [token]
            }

            if (newUsedTokens && __ion_status[callKey] === READY) {
                const suggestions = util.CreateStoreObject({
                    data,
                    token,
                    usedTokens: newUsedTokens,
                })

                store.call({ suggestions })
            }
        }
    }

    componentWillUnmount() {
        this.props.clearSuggestions()
        this.props.clearAppSuggestions()
    }

    dismiss = e => {
        this.props.closeSuggestions(e)
    }

    render() {
        const { store, __ion_status, ...restProps } = this.props
        let suggestions = store.get(callKey)

        if (suggestions && suggestions?.data?.length === 0) {
            suggestions = store.get(callKeyApp)
        }
        if (__ion_status[callKey] === READY && __ion_status[callKeyApp] === LOADING) {
            __ion_status[callKey] = LOADING
        }

        switch (__ion_status[callKey]) {
            case READY:
                return (
                    <AutocompleteView.Finished
                        {...restProps}
                        tabIndex={0}
                        suggestions={suggestions && suggestions.data}
                        dismiss={this.dismiss}
                        infoVisible={this.props.visible && this.state.infoVisible}
                        isLoading={false}
                    />
                )
            case FAILED:
                return (
                    <AutocompleteView.Failed
                        dismiss={this.dismiss}
                        tabIndex={0}
                        infoVisible={this.props.visible && this.state.infoVisible}
                    />
                )
            case LOADING:
            default:
                if (suggestions && suggestions.data)
                    return (
                        <AutocompleteView.Finished
                            {...restProps}
                            tabIndex={0}
                            suggestions={suggestions && suggestions.data}
                            dismiss={this.dismiss}
                            infoVisible={this.props.visible && this.state.infoVisible}
                            isLoading
                        />
                    )
                return <AutocompleteView.Loading infoVisible={this.props.visible && this.state.infoVisible} />
        }
    }
}

const withFilledCall = Component => props => {
    // eslint-disable-next-line no-unused-vars
    const { token } = props.store.get(callKey) || { data: [] }
    const params = {}

    const { typedName: query, timestamp } = props.getCurrentState()

    if (token) {
        params.continuationtoken = token
    }

    let url = `/profile/search?type=portal&text=${query.toLowerCase()}`

    if (props.selectedOrganisation && props.selectedOrganisation.length > 0) {
        url = `/profile/search?type=portal&text=${query.toLowerCase()}&OrganisationId=${props.selectedOrganisation}`
    }

    return props.visible && query.length >= 2 ? (
        withCall(callKey, {
            config: {
                method: 'get',
                params,
                paramsSerializer: queryParamEncoder,
                url: url,
            },
            reducer: ({ data, headers }) => {
                const current = props.getCurrentState()

                if (query === current.typedName && timestamp === current.timestamp)
                    return {
                        data: [...data],
                        token: headers.continuationtoken || undefined,
                    }
            },
            progressHandlers: false,
        })(Component)(props)
    ) : (
        <Component {...props} />
    )
}
const withFilledAppProfileCall = Component => props => {
    // eslint-disable-next-line no-unused-vars
    const { data: oldData, token } = props.store.get(callKey) || { data: [] }
    const params = {}

    const { typedName: query, timestamp } = props.getCurrentState()

    if (token) {
        params.continuationtoken = token
    }
    let url = `/profile/search?type=app&text=${query.toLowerCase()}`

    if (props.selectedOrganisation && props.selectedOrganisation.length > 0) {
        url = `/profile/search?type=app&text=${query.toLowerCase()}&OrganisationId=${props.selectedOrganisation}`
    }

    return props.visible && query.length >= 2 && props.__ion_status[callKey] === READY && oldData && oldData.length === 0 ? (
        withCall(callKeyApp, {
            config: {
                method: 'get',
                params,
                paramsSerializer: queryParamEncoder,
                url: url,
            },
            reducer: ({ data, headers }) => {
                const current = props.getCurrentState()
                if (query === current.typedName && timestamp === current.timestamp)
                    return {
                        data: [...data],
                        token: headers.continuationtoken || undefined,
                    }
            },
            progressHandlers: false,
        })(Component)(props)
    ) : (
        <Component {...props} />
    )
}

export default compose(
    withStore,
    withStatus,
    withClear('clearSuggestions', 'suggestions'),
    withClear('clearAppSuggestions', 'appSuggestions'),
    mapProps(props => ({
        ...props,
        getCurrentCallData: () => props.store.get(callKey),
    })),
    withFilledCall,
    withFilledAppProfileCall
)(Autocomplete)
