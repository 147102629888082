import React from 'react'
import { object } from 'prop-types'
import { withStore } from 'react-ion-store'
import { compose } from 'recompose'

import DistrictsMapper from '../../../../../../components/DistrictsMapper'
import DistrictsView from '../../../../../Performances/views/Districts/DistrictsView'

const storeKey = 'mappedDistrictData'

const ProfileMap = props => {
    ProfileMap.propTypes = {
        history: object,
        store: object,
    }
    const selectAllDepots = filteredDistrictData => {
        let returnValue = []
        filteredDistrictData.tcts.map(tct =>
            tct.depots.map(depot => {
                return returnValue.push(depot.id)
            })
        )
        return returnValue
    }

    const handleChooseDistrict = (e, selectedDistrict) => {
        e.preventDefault()
        const { store, history } = props
        const mappedDistrictData = store.get('mappedDistrictData')

        const filteredDistrictData = mappedDistrictData.find(district => district.id === selectedDistrict)
        const selectedTCTs = filteredDistrictData ? filteredDistrictData.tcts.map(tct => tct.id) : []

        let depotsArray = selectAllDepots(filteredDistrictData)
        depotsArray.push('performances_label_all_depots')

        store.set({ selectedDistrict, selectedTCTs, selectedDepot: depotsArray })
        history.push('/profiles/profilestatus')
    }

    const districts = props.store.get(storeKey) || []
    return (
        <React.Fragment>
            <DistrictsMapper>
                <DistrictsView chooseDistrict={handleChooseDistrict} districts={districts} />
            </DistrictsMapper>
        </React.Fragment>
    )
}

export default compose(withStore)(ProfileMap)
