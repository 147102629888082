import { DateTime } from 'luxon'
import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

async function addNewPtcarCall(data, onClose, setError, loadPtcars, translate) {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/legacyReferenceData/ptcar/add`

    try {
        const response = await axios.post(fullUrl, {
            ValidFrom:
                data.validFrom !== undefined
                    ? parseInt(DateTime.fromFormat(data.validFrom, 'dd/MM/yyyy').toSeconds())
                    : parseInt(DateTime.local().toSeconds()),
            ValidTo:
                data.validTo !== undefined
                    ? parseInt(DateTime.fromFormat(data.validTo, 'dd/MM/yyyy').toSeconds())
                    : parseInt(DateTime.local().toSeconds()),
            LineId: parseInt(data.lineNumbers) || null,
            MileId: parseInt(data.mileId) || null,
            Distance: parseFloat(data.distance) || null,
            Code: data.code,
            ShortNameFr: data.shortNameFr,
            ShortNameNl: data.shortNameNl,
            LongNameFr: data.longNameFr,
            LongNameNl: data.longNameNl,
            CommercialNameFr: data.commercialNameFr,
            CommercialNameNl: data.commercialNameNl,
            CommercialMediumNameFr: data.commercialMediumNameFr,
            CommercialMediumNameNl: data.commercialMediumNameNl,
            CommercialShortNameFr: data.commercialShortNameFr,
            CommercialShortNameNl: data.commercialShortNameNl,
            NetId: data.netId !== undefined ? data.netId : '',
            Classification: parseInt(data.classification),
            Type: parseInt(data.type),
            PtCarGroup: data.ptCarGroup,
            Latitude: parseFloat(data.latitude),
            Longitude: parseFloat(data.longitude),
            Altitude: parseFloat(data.altitude),
            InnerBox: parseInt(data.innerBox),
            OuterBox: parseInt(data.outerBox),
            UicCode: parseInt(data.uic),
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 204) {
            onClose()
        }
        if (response.status === 200) {
            onClose()
            loadPtcars()
        }
    } catch (e) {
        if (e.response && e.response.status && e.response.status === 400) {
            setError(e.response.data.Message)
        } else {
            setError(translate('add_ptcar_error'))
        }
        console.error(e) // eslint-disable-line
    }
}

export default addNewPtcarCall
