import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography, withStyles } from '@material-ui/core'
import { withStore } from 'react-ion-store'
import some from 'lodash/some'
import { object, func, string, oneOfType, number, bool, array } from 'prop-types'

import { withLocalize } from 'react-localize-redux'

import SelectInput from 'components/SelectInput'
import styles from './styles'
import PerformanceSelect from '../PerformanceSelect'
import { DatePickerWithKeyboard } from 'components/Pickers'
import withHocs from 'utils/hocs/withHocs'
const storeKey = 'mappedDistrictData'

const SelectPerformanceForCopyAndPaste = props => {
    SelectPerformanceForCopyAndPaste.propTypes = {
        persist: func.isRequired,
        store: object.isRequired,
        orgPt: string.isRequired,
        depotPt: string.isRequired,
        handleDateChange: func.isRequired,
        selectedDate: oneOfType([object, number]).isRequired,
        classes: object,
        selectedPerformanceNumber: string,
        translate: func,
        loadingPerformance: bool,
        loadPerformanceNumbers: func,
        performanceNumbersData: array,
    }

    const handleEvent = (type, depot = null) => e => {
        props.persist(type)(e.target.value)
        if (depot !== null) {
            const depotNamePt = depot.filter(dep => dep.id === e.target.value)

            props.persist('depotNamePt')(depotNamePt[0].text || '')
        }
    }

    const {
        store,
        classes,
        depotPt,
        orgPt,
        selectedDate,
        selectedPerformanceNumber,
        translate,
        loadingPerformance,
        loadPerformanceNumbers,
        performanceNumbersData,
        handleDateChange,
    } = props
    const districts = store.get(storeKey) || []
    const tcts = districts.reduce(
        (aggregrate, district) => [...aggregrate, ...district.tcts.map(tct => ({ id: `${tct.tctId}`, text: tct.id }))],
        [{ id: ' ', text: 'documents_label_select_org' }]
    )

    let chosenDistrict = districts.find(district => some(district.tcts, { tctId: parseInt(orgPt, 10) })) || {
        tcts: [],
    }

    const chosenTct = chosenDistrict.tcts.find(tct => tct.tctId === parseInt(orgPt, 10)) || {
        depots: [],
    }

    const depots = chosenTct.depots.reduce(
        (aggregate, depot) => [...aggregate, { id: `${depot.depotId}`, text: depot.id }],
        [{ id: ' ', text: 'documents_label_select_depot' }]
    )

    return (
        <React.Fragment>
            <Grid data-testid="title_grid" item xs={12}>
                <Typography variant="h6">{translate('performance_task_add_label_select_performance')}</Typography>
            </Grid>

            <React.Fragment>
                <Grid data-testid="date_grid" item xs={3}>
                    <DatePickerWithKeyboard
                        label="DD/MM/YYYY"
                        value={selectedDate}
                        onChange={e => handleDateChange(e)}
                        format="dd/MM/yyyy"
                    />
                </Grid>
                <Grid data-testid="orgPt_grid" item xs={3}>
                    <div style={{ display: 'inline-block' }}>
                        <SelectInput
                            sorted
                            name="org"
                            classes={classes}
                            items={tcts.map(tct => ({ ...tct, text: translate(tct.text) }))}
                            value={orgPt}
                            onChange={handleEvent('orgPt')}
                        />
                    </div>
                </Grid>
                <Grid data-testid="depotPt_grid" item xs={3}>
                    <div style={{ display: 'inline-block' }}>
                        <SelectInput
                            sorted
                            name="depot"
                            classes={classes}
                            items={depots.map(depot => ({ ...depot, text: translate(depot.text) }))}
                            value={orgPt === ' ' ? ' ' : depotPt}
                            onChange={handleEvent('depotPt', depots)}
                        />
                    </div>
                </Grid>
                <Grid data-testid="selectPerforamnce_grid" item xs={3}>
                    {depotPt !== ' ' && depotPt && (
                        <PerformanceSelect
                            depot={depotPt}
                            handleEvent={handleEvent}
                            selectedPerformanceNumber={selectedPerformanceNumber}
                            loadingPerformance={loadingPerformance}
                            loadPerformanceNumbers={loadPerformanceNumbers}
                            performanceNumbersData={performanceNumbersData}
                            classes={classes}
                            depots={chosenTct}
                            translate={translate}
                        />
                    )}
                </Grid>
            </React.Fragment>
        </React.Fragment>
    )
}

export default withHocs(withStore, withLocalize, withStyles(styles))(SelectPerformanceForCopyAndPaste)
