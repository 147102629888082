export default theme => ({
    drawer: {
        margin: '16px',
        padding: '16px',
        [theme.breakpoints.down('xs')]: {
            // width: 'auto',
            // display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '70vw',
            minWidth: '60vw',
        },
        minWidth: '400px',
        display: 'grid',
        gridTemplateRows: 'repeat(5, auto)',
    },
    text: {
        marginBottom: '16px',
    },
    select: {
        marginTop: '40px',
        marginBottom: '40px',
        width: '200px',
    },
    textField: {
        marginBottom: '20px',
    },
    button: {
        marginTop: '20px',
    },
})
