import React, { useContext, useState } from 'react'

import DeleteDialog from 'components/DeleteDialog'
import { PtcarPlatformsContext } from '../../ptcarPlatforms.store'
import { TranslationContext } from 'context/translation'

function DeletePtcarPlatformDialog() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const { STATE, METHODS, SERVICES } = useContext(PtcarPlatformsContext)
    const { translate } = useContext(TranslationContext)

    const handleDeleteClick = async () => {
        setLoading(true)
        let response = await SERVICES.deletePtcarPlatform(STATE.deleteRowData.id)
        if (response.status === 200) {
            METHODS.loadPtcarPlatforms()
            METHODS.onDialogClose()
        } else {
            setLoading(false)
            setError(response.data.Message)
        }
    }

    return (
        <DeleteDialog
            dialogOpen={STATE.openDeleteDialog}
            onClose={METHODS.onDialogClose}
            title={translate('platform_delete_dialog_title')}
            translate={translate}
            handleDelete={handleDeleteClick}
            deleteMsg={translate('platform_delete_role_dialog_confirm_text')}
            error={error}
            errorMsg={translate('platform_delete_platform_dialog_error')}
            deleteInfo={STATE.deleteRowData?.ptCarUicCode}
            loading={loading}
        />
    )
}
export default DeletePtcarPlatformDialog
