import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

export default (trainComposed, date, fromPtCarUic, toPtCarUic) => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/composition/${trainComposed}/date/${date}/from/${fromPtCarUic}/to/${toPtCarUic}/latestid`

    return axios.get(fullUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
    })
}
