/* eslint-disable max-len */
import React from 'react'
import { Table, TableBody, TableCell, TableRow, TableHead, Typography, Box } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import { object, array, func, bool } from 'prop-types'
import { withStore } from 'react-ion-store'
import { UnfoldMore, UnfoldLess } from '@material-ui/icons'
import Loading from 'components/Loading'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

const OrganisationListForFeature = props => {
    const { organosationListForFeatureId, classes, translate, addNewOrganisationCallLoading, organisationListFolded, setOrganisationListFolded, handleDeleteOrganisation, isOrganisationListLoading } = props
     
    const mapOrganisations = orgId => {
        const { locationList } = props
        const item = locationList.find(orgs => orgs.value === orgId.toString())
        return item ? item.label : ''
    }

    const toggleFold = () => {
        if(!addNewOrganisationCallLoading && !isOrganisationListLoading){
            setOrganisationListFolded(!organisationListFolded)
        }
    }

    return (
            <Table >
                {organosationListForFeatureId.length > 0 ? (
                    <TableHead style={{ height: '32px' }}>
                        <TableRow onClick={() => {toggleFold()}} style={!addNewOrganisationCallLoading && !isOrganisationListLoading ? {cursor: 'pointer'} : null}>
                            <TableCell style={{ width: '50%', padding: '0px' }}>
                            <Typography variant="h6">{translate('search_label_orgs')}</Typography>
                            </TableCell>
                            <TableCell align="right" style={{ padding: '8px', paddingRight: '0px' }}>
                                    {!organisationListFolded ? (
                                    <UnfoldMore
                                    className={classes.actionIcon}
                                    />
                          ) : (
                              <UnfoldLess
                                    className={classes.actionIcon}
                            />
                        )}
                                        
            </TableCell>
                        </TableRow>
                    </TableHead>
                ) : null}

                {!addNewOrganisationCallLoading && !isOrganisationListLoading ? (
                <TableBody>
                    {organosationListForFeatureId && !organisationListFolded &&
                        organosationListForFeatureId.map(item => (
                            <React.Fragment key={item.organisationId}>
                                <TableRow style={{ height: '56px' }} >
                                    <TableCell style={{paddingLeft : '8px'}}>
                                        <Typography variant="body2">{mapOrganisations(item.organisationId)}</Typography>
                                    </TableCell>

                                    <TableCell align="right" style={{paddingRight: '0px'}}>
                                        <Delete
                                            disabled={addNewOrganisationCallLoading}
                                            className={classes.actionIcon}
                                            style={{
                                                cursor:
                                                    checkFunctionalityDisabled(
                                                        props.store,
                                                        'edit',
                                                        'users.functionality.management.users'
                                                    ) || addNewOrganisationCallLoading
                                                        ? 'none'
                                                        : 'pointer',
                                                color:
                                                    checkFunctionalityDisabled(
                                                        props.store,
                                                        'edit',
                                                        'users.functionality.management.users'
                                                    ) || addNewOrganisationCallLoading
                                                        ? 'grey'
                                                        : '',
                                            }}
                                            onClick={() => {
                                                handleDeleteOrganisation(item.organisationId)
                                            }}
                                        />
                                    </TableCell>
                                    
                                </TableRow>
                            </React.Fragment>
                        ))}
                </TableBody>
                ) : (
                    <Box xs={{ margin: '0px auto', width: '200px' }}>
                        <Loading />
                    </Box>
                )}
            </Table>
    )
}
OrganisationListForFeature.propTypes = {
    classes: object,
    store: object,
    organosationListForFeatureId: array,
    translate: func,
    handleDeleteOrganisation: func,
    addNewOrganisationCallLoading: bool,
    organisationListFolded: bool,
    setOrganisationListFolded: func,
    locationList: array,
    isOrganisationListLoading: bool,
}
export default withStore(OrganisationListForFeature)
