import React from 'react'
import { func, object } from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { enums, withStore, withStatus } from 'react-ion-store'
import { compose } from 'recompose'
import { withRouter, Route, Switch } from 'react-router-dom'

import ProfileMap from './components/ProfileMap'
import Header from 'components/Header'
import ProfileStatus from './components/ProfileStatus'
import Loading from 'components/Loading'

const Failed = () => <div>Error retrieving Roles!</div>

const {
    CallStatus: { LOADING, FAILED },
} = enums
class ManageProfile extends React.Component {
    static propTypes = {
        onClose: func,
        translate: func,
        history: object,
        store: object,
        location: object,
        match: object,
        __ion_status: object,
    }
    componentDidMount() {
        document.title = this.props.translate('manage_profile_status')
    }
    render() {
        const { store, history, location, match, __ion_status, translate } = this.props
        const { userRoles } = __ion_status

        const moduleList = store.get('moduleList')

        const rolesLoading = !!userRoles && userRoles === LOADING
        const rolesFailed = !!userRoles && userRoles === FAILED

        if (rolesFailed)
            return (
                <React.Fragment>
                    <Failed />
                </React.Fragment>
            )
        else if (rolesLoading)
            return (
                <React.Fragment>
                    <Loading />
                </React.Fragment>
            )
        else if (!!moduleList && !moduleList.includes('/profiles')) {
            history.replace(`/`)
        }

        const { selectedDistrict } = store.get(['moduleList', 'selectedDistrict'])
        if (location.pathname === `${match.path}/profilestatus` && !selectedDistrict) {
            history.replace(`${match.path}`)
        }

        return (
            <React.Fragment>
                <React.Fragment>
                    <Header title={translate('manage_profile_status')} />
                </React.Fragment>

                <Switch>
                    <Route exact path={`${match.path}`} render={props => <ProfileMap {...props} />} />
                    <Route exact path={`${match.path}/profilestatus`} component={ProfileStatus} />
                </Switch>
            </React.Fragment>
        )
    }
}
export default compose(withLocalize, withRouter, withStore, withStatus)(ManageProfile)
