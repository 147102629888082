import React from 'react'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import EventIcon from '@material-ui/icons/Event'
import PersonIcon from '@material-ui/icons/Person'
import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import NoteAdd from '@material-ui/icons/NoteAdd'
import UpdateIcon from '@material-ui/icons/Update'
import EditIcon from '@material-ui/icons/Edit'
import DomainIcon from '@material-ui/icons/Domain'
import AddIcon from '@material-ui/icons/Add'
import TrackIcon from '@material-ui/icons/TrackChanges'
import RefreshIcon from '@material-ui/icons/Refresh'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Block from '@material-ui/icons/Block'
import Restore from '@material-ui/icons/Restore'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda'
import ViewWeekIcon from '@material-ui/icons/ViewWeek'
import ViewDayIcon from '@material-ui/icons/ViewDay'
import TodayIcon from '@material-ui/icons/Today'
import GroupIcon from '@material-ui/icons/Group'
import styles from './../styles'

// Higher-Order magic, wraps margin for IconButton
const styleWrapped = Component => withStyles(styles)(({ classes }) => <Component className={classes.icon} />)

const Event = styleWrapped(EventIcon)
const Person = styleWrapped(PersonIcon)
const Search = styleWrapped(SearchIcon)
const FilterList = styleWrapped(FilterListIcon)
const PersonAdd = styleWrapped(PersonAddIcon)
const PlayArrow = styleWrapped(PlayArrowIcon)
const Stop = styleWrapped(StopIcon)
const Check = styleWrapped(CheckIcon)
const Delete = styleWrapped(DeleteIcon)
const Info = styleWrapped(InfoIcon)
const AddNote = styleWrapped(NoteAdd)
const Update = styleWrapped(UpdateIcon)
const Edit = styleWrapped(EditIcon)
const Domain = styleWrapped(DomainIcon)
const Add = styleWrapped(AddIcon)
const Track = styleWrapped(TrackIcon)
const Refresh = styleWrapped(RefreshIcon)
const Download = styleWrapped(SaveAlt)
const Blocker = styleWrapped(Block)
const Reactivate = styleWrapped(Restore)
const Calendar = styleWrapped(CalendarIcon)
const NavigationPrevious = styleWrapped(NavigateBeforeIcon)
const NavigationNext = styleWrapped(NavigateNextIcon)
const ViewAgenda = styleWrapped(ViewAgendaIcon)
const ViewWeek = styleWrapped(ViewWeekIcon)
const ViewDay = styleWrapped(ViewDayIcon)
const Today = styleWrapped(TodayIcon)
const Group = styleWrapped(GroupIcon)
export default {
    event: <Event />,
    person: <Person />,
    search: <Search />,
    filter_list: <FilterList />,
    person_add: <PersonAdd />,
    start: <PlayArrow />,
    check: <Check />,
    delete: <Delete />,
    stop: <Stop />,
    info: <Info />,
    note_add: <AddNote />,
    update: <Update />,
    edit: <Edit />,
    domain: <Domain />,
    add: <Add />,
    track: <Track />,
    refresh: <Refresh />,
    download: <Download />,
    block: <Blocker />,
    reactivate: <Reactivate />,
    calendar: <Calendar />,
    navigationPrevious: <NavigationPrevious />,
    navigationNext: <NavigationNext />,
    viewAgenda: <ViewAgenda />,
    viewWeek: <ViewWeek />,
    viewDay: <ViewDay />,
    today: <Today />,
    group: <Group />,
}
