/**
 *
 * @param {valueToCompare} a
 * @param {valueToCompare} b
 */
const numberSorter = (a, b) => Math.abs(a) - Math.abs(b)

/* This method calculate the time difference
       It takes planned time and real time as input parameter
       It returns the time difference after some calculation */
const calculateTimeDifferenceWithTimestamps = (planned, real) => {
    if (!real) return 0

    let oneDay = 24 * 60 * 60
    let timeDifferences = []
    // Formula to calculate time difference when no midnight cross
    timeDifferences.push(Math.round((parseInt(real) - parseInt(planned)) / 60))
    // Formula to calculate time difference when backward midnight cross
    timeDifferences.push(Math.round((parseInt(real) - (parseInt(planned) + oneDay)) / 60))
    // Formula to calculate time difference when forward midnight cross
    timeDifferences.push(Math.round((parseInt(real) + oneDay - parseInt(planned)) / 60))
    let timeDifference = [...timeDifferences].sort(numberSorter)
    return timeDifference[0]
}

export default calculateTimeDifferenceWithTimestamps
