export default theme => ({
    table: {
        fontFamily: theme.typography.fontFamily,
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableCell: {
        flex: 1,
        wordWrap: 'break-word',
        borderColor: theme.customColors.grey,
    },
    noClick: {
        cursor: 'initial',
    },
    tableCellRed: {
        backgroundColor: theme.customColors.darkRed,
    },
    tableRow: {
        backgroundColor: 'transparent',
    },
    documentTableWrapper: {
        width: '100%',
        height: '55vh',
    },
})
