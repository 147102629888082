import red from '@material-ui/core/colors/red'

export default theme => ({
    indicator: {
        color: theme.customColors.white,
        padding: theme.spacing(1) / 2,
        backgroundColor: red[500],
        display: 'inline-block',
    },
})
