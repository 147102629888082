import React from 'react'
import { object, func, arrayOf, string, bool, array } from 'prop-types'
import SelectInput from 'components/SelectInput'
// Material UI
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MultipleSelect from '../../components/MultipleSelect'

// Components
import AppPaper from 'components/AppPaper'

const Footer = props => {
    Footer.propTypes = {
        classes: object.isRequired,
        selectedTCTs: arrayOf(string),
        departureState: string,
        translate: func.isRequired,
        onChange: func.isRequired,
        changePtcarCheckbox: func,
        changeDepartureState: func,
        performancesGrouped: bool,
        performancesIsLoading: bool,
        isMobile: bool,
        selectedPlatformPtcarsList: array,
        selectedLindaRoles: array,
        changeLindaRoles: func,
    }

    const toggleGroup = () => {
        props.onChange()
    }

    const {
        classes,
        translate,
        performancesGrouped,
        performancesIsLoading,
        selectedPlatformPtcarsList,
        changePtcarCheckbox,
        departureState,
        changeDepartureState,
        isMobile,
        selectedLindaRoles,
        changeLindaRoles,
    } = props

    const departureStates = [
        { id: 'TrainsNotLeft', text: 'TrainsNotLeft' },
        { id: 'TrainsLeft', text: 'TrainsLeft' },
        { id: 'All', text: 'All' },
    ]

    if (!isMobile) {
        return (
            <AppPaper className={classes.root}>
                <div>
                    <MultipleSelect
                        items={selectedPlatformPtcarsList}
                        name="platform"
                        onChange={changePtcarCheckbox}
                        value={selectedPlatformPtcarsList}
                        multiple
                    />
                </div>
                <div className={classes.flex}>
                    <div style={{ display: 'inline-block', marginRight: '8px', marginTop: '5px' }}>
                        <MultipleSelect
                            items={selectedLindaRoles}
                            name="lindaroles"
                            onChange={changeLindaRoles}
                            value={selectedLindaRoles}
                            multiple
                        />
                    </div>
                    <div style={{ display: 'inline-block', marginRight: '8px' }}>
                        <SelectInput
                            sorted
                            name="org"
                            classes={classes}
                            items={departureStates}
                            value={departureState}
                            onChange={e => changeDepartureState(e.target.value)}
                        />
                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={() => toggleGroup()}
                                checked={performancesGrouped}
                                disabled={performancesIsLoading}
                                color="primary"
                            />
                        }
                        label={translate('group_performances_by_ptcar')}
                    />
                </div>
            </AppPaper>
        )
    } else {
        return (
            <AppPaper className={classes.root}>
                <div style={{ width: '50%', display: 'block', marginLeft: '5px' }}>
                    <MultipleSelect
                        items={selectedPlatformPtcarsList}
                        name="platform"
                        onChange={changePtcarCheckbox}
                        value={selectedPlatformPtcarsList}
                        multiple
                    />
                </div>
                <div style={{ width: '48%', display: 'block', marginLeft: '5px' }}>
                    <MultipleSelect
                        items={selectedLindaRoles}
                        name="lindaroles"
                        onChange={changeLindaRoles}
                        value={selectedLindaRoles}
                        multiple
                    />
                </div>
                <div style={{ display: 'block', width: 'auto', marginRight: '5px' }}>
                    <SelectInput
                        sorted
                        name="org"
                        classes={classes}
                        items={departureStates}
                        value={departureState}
                        onChange={e => changeDepartureState(e.target.value)}
                    />
                </div>

                <div style={{ width: '100%', display: 'block' }}>
                    <FormControlLabel
                        style={{
                            width: '100%',
                            display: 'block',
                            // textOverflow: 'ellipsis',
                            margin: 0,
                            padding: 0,
                        }}
                        control={
                            <Checkbox
                                onChange={() => toggleGroup()}
                                checked={performancesGrouped}
                                disabled={performancesIsLoading}
                                color="primary"
                            />
                        }
                        label={translate('group_performances_by_ptcar')}
                    />
                </div>
            </AppPaper>
        )
    }
}

export default Footer
