export default theme => ({
    drawer: {
        margin: '16px',
        padding: '16px',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            maxWidth: '70vw',
            minWidth: '60vw',
        },
        maxWidth: '500px',
        display: 'grid',
        gridTemplateRows: 'repeat(5, auto)',
    },
    text: {
        marginBottom: '16px',
        wordBreak: 'break-word',
    },
})
