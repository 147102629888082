import React, { PureComponent } from 'react'
import { withStyles, Paper, Typography } from '@material-ui/core'
import { compose } from 'recompose'
import { object, func, string } from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import styles from './styles'

export class DistrictMarker extends PureComponent {
    static propTypes = {
        classes: object.isRequired,
        clickHandler: func.isRequired,
        classVariable: string,
        id: string,
    }

    render() {
        const { classes, id, classVariable, clickHandler } = this.props
        return (
            <Paper className={`${classes.map_marker} ${classes[classVariable]}`} onClick={e => clickHandler(e, id)}>
                <Typography
                    variant="subtitle1"
                    style={{ color: id !== 'DISTRICT-6' ? '#424242' : 'auto', minWidth: '80px' }}
                >
                    <Translate id={id} />
                </Typography>
            </Paper>
        )
    }
}

export default compose(withLocalize, withStyles(styles))(DistrictMarker)
