export default theme => ({
    searchBarRoot: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
    },
    radioGroup: {
        display: 'block',
    },
    paddedGroup: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    dropdownGroup: {
        display: 'inline-block',
        marginRight: theme.spacing(2),
    },
})
