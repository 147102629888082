import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DefectTypesTable from './components/DefectTypesTable'
import { TranslationContext } from 'context/translation'

function DefectTypesView(props) {
    const { classes, addDefectTypeHandler } = props
    const { translate } = React.useContext(TranslationContext)
    return (
        <React.Fragment>
            <div data-testid="defect-types-view" className={classes.FlexHeader}>
                <Typography variant="h6">{translate('defect_Type_title')}</Typography>
                <Button variant="contained" color="primary" onClick={addDefectTypeHandler}>
                    {translate('compos_add_defect-type')}
                </Button>
            </div>
            <DefectTypesTable {...props} />
        </React.Fragment>
    )
}

DefectTypesView.propTypes = {
    classes: PropTypes.object,
    addDefectTypeHandler: PropTypes.func,
}

export default DefectTypesView
