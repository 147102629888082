import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus } from 'react-ion-store'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import AddPerformanceView from './AddPerformanceView'
import styles from './styles'
import DistrictsMapper from 'components/DistrictsMapper'
import withFunctionalityCheck from 'utils/hocs/withFunctionalityCheck'

class AddNewPerformance extends React.Component {
    static propTypes = {
        store: PropTypes.object,
        __ion_status: PropTypes.object,
        history: PropTypes.object,
        translate: PropTypes.func,
    }

    state = {
        drawers: {
            addPerformance: true,
        },
    }

    openDialog = () => {
        this.setState({ openDailogForDBChange: true })
    }

    persistDrawers = type => props => {
        const toSetState = {
            drawers: {
                ...this.state.drawers,
                [type]: props,
            },
        }

        this.setState(toSetState)
    }
    componentDidMount() {
        document.title = this.props.translate('module_performances')
    }
    render() {
        const { store } = this.props
        const { roles } = store.get(['roles'])

        return (
            <DistrictsMapper>
                <AddPerformanceView
                    {...this.props}
                    persistDrawers={this.persistDrawers}
                    drawers={this.state.drawers}
                    roles={roles}
                    showDBSwitch={false}
                />
            </DistrictsMapper>
        )
    }
}

export default compose(
    withRouter,
    withLocalize,
    withStyles(styles),
    withStore,
    withStatus,
    withFunctionalityCheck
)(AddNewPerformance)
