import React from 'react'
import { bool, func, object } from 'prop-types'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

// Components
import PrimaryButton from 'components/PrimaryButton'
import SectionHeader from 'components/SectionHeader'
import SelectInput from 'components/SelectInput'

import styles from './styles'

const AssignDrawerView = ({ classes, isOpen, toggleDrawer }) => (
    <Drawer anchor="right" open={isOpen} onClose={() => toggleDrawer(false)}>
        <div className={classes.drawerRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Persoon aan-/afmelden</Typography>
                </Grid>

                <Grid item xs={12}>
                    <SectionHeader title="Actie" />
                    <RadioGroup
                        name="action"
                        // onChange={onChange}
                        value="1"
                    >
                        <FormControlLabel control={<Radio />} label="Aanmelden" value="1" />
                        <FormControlLabel control={<Radio />} label="Afmelden" value="2" />
                    </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                    <SectionHeader title="Persoon" />
                    <br />
                    <TextField autoFocus fullWidth placeholder="Geef hier de naam of ID in?" type="text" />
                </Grid>

                <Grid item xs={12}>
                    <SectionHeader title="Locatie en prestatie" />

                    <br />

                    <div>
                        <SelectInput items={[{ id: 'id', text: 'Selecteer het depot' }]} name="depot" value="id" />
                    </div>

                    <br />

                    <SelectInput items={[{ id: 'id', text: 'Selecteer een prestatie' }]} name="depot" value="id" />
                </Grid>

                <Grid item xs={12}>
                    <SectionHeader title="Tijdstip" />
                </Grid>

                <Grid item xs={12}>
                    <PrimaryButton title="Toepassen" />
                </Grid>
            </Grid>
        </div>
    </Drawer>
)

AssignDrawerView.propTypes = {
    classes: object.isRequired,
    isOpen: bool.isRequired,
    toggleDrawer: func.isRequired,
}

export default withStyles(styles)(AssignDrawerView)
