import React from 'react'
import { func, object } from 'prop-types'
import { compose } from 'recompose'
import { withLocalize } from 'react-localize-redux'
import { withStore, withClear, withStatus, enums, util } from 'react-ion-store'

import { withStyles } from '@material-ui/core/styles'

import ClearCacheDrawerView from './ClearCacheDrawerView'

import styles from './styles'

const {
    CallStatus: { READY },
} = enums

const resetState = {
    selectedName: '',
    selectedProfile: undefined,
    clearCacheWasSet: false,
    deviceId: '',
}

class ClearCacheDrawer extends React.Component {
    static propTypes = {
        __ion_status: object.isRequired,
        resetClearCache: func.isRequired,
        onClose: func.isRequired,
        store: object,
    }

    state = resetState

    componentDidUpdate = () => {
        const { __ion_status } = this.props
        const { documentsClear, documentsClearActions } = __ion_status

        let idf
        if (this.state.selectedProfile) idf = this.state.selectedProfile.idfNumber || this.state.selectedProfile.id

        if (
            this.state.clearCacheWasSet &&
            idf === this.state.refetchForUser &&
            this.state.deviceId === this.state.refetchForDevice &&
            documentsClearActions &&
            documentsClearActions === READY &&
            documentsClear &&
            documentsClear === READY
        )
            this.setState({ clearCacheWasSet: false, refetchForUser: undefined }, () => {
                const documentsClearActionsObj = util.CreateStoreObject(this.props.store.get('documentsClearActions'))
                this.props.store.call({ documentsClearActionsObj })
            })
    }

    setName = selectedProfile =>
        this.setState(
            {
                selectedName: `${selectedProfile.firstName} ${selectedProfile.lastName}`,
                selectedProfile,
                clearCacheWasSet: false,
            },
            () => {
                this.props.resetClearCache()
            }
        )

    setDeviceId = deviceId => this.setState({ deviceId })

    clearName = () => this.setState(resetState, () => this.props.resetClearCache())

    onClose = () => {
        this.clearName()
        this.props.onClose()
    }

    setToClear = () => {
        let idf
        if (this.state.selectedProfile) idf = this.state.selectedProfile.idfNumber || this.state.selectedProfile.id

        this.setState({ clearCacheWasSet: true, refetchForUser: idf, refetchForDevice: this.state.deviceId })
    }

    render() {
        return (
            <ClearCacheDrawerView
                {...this.props}
                {...this.state}
                setToClear={this.setToClear}
                setName={this.setName}
                deviceId={this.state.deviceId}
                setDeviceId={this.setDeviceId}
                clearName={this.clearName}
                onClose={this.onClose}
            />
        )
    }
}

export default compose(
    withStore,
    withStatus,
    withClear('resetClearCache', ['documentsClear', 'documentsClearActions']),
    withStyles(styles),
    withLocalize
)(ClearCacheDrawer)
