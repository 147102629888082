export const INITIAL_STATE = {
    loading: false,
    vehicleData: null,
    trainNumber: '',
    date: DateTime.local(),
    realTime: true,
}
import { DateTime } from 'luxon'
import BrakingDetailsActions from './BrakingDetailsActions'
export default function BrakingDetailsReducer(state, action) {
    switch (action.type) {
        case BrakingDetailsActions.LOAD_VEHICLE_DATA:
            return { ...state, vehicleData: action.payload, error: false, loading: false }
        case BrakingDetailsActions.LOADING:
            return { ...state, error: false, loading: action.payload }
        case BrakingDetailsActions.SET_DATE:
            return { ...state, date: action.payload }
        case BrakingDetailsActions.SET_ERROR:
            return { ...state, error: true, vehicleData: null, loading: false }
        case BrakingDetailsActions.REAL_TIME:
            return { ...state, error: false, realTime: action.payload }
        default:
            return INITIAL_STATE
    }
}
