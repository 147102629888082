import React from 'react'
import { object, func, node } from 'prop-types'
import { compose } from 'recompose'
import { withStore, withCall, enums } from 'react-ion-store'
import { withRouter } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'

import Loading from 'components/Loading'

import mapKeysToLowerCase from 'utils/mapKeysToLowerCase'
import mapApiTranslations from 'translations/mapApiTranslations'

const { CallStatus } = enums

const Failed = () => <div>Error retrieving Districts!</div>
const ProgressHandlers = {
    [CallStatus.LOADING]: Loading,
    [CallStatus.FAILED]: Failed,
}

const callKey = 'districtsAndTctsAndDepots'
const storeKey = 'mappedDistrictData'

class DistrictsMapper extends React.Component {
    static propTypes = {
        children: node.isRequired,
        store: object.isRequired,
        // history: object.isRequired,
        addTranslation: func.isRequired,
    }
    _isMounted = false

    componentDidMount = () => {
        this._isMounted = true
        if (this.props.store.get(callKey) !== undefined) {
            // could have already been mapped, will only be undefined if the component processed the API call
            const storeData = this.props.store.get(callKey).data
            const districtData = storeData && storeData.length > 0 ? storeData : []
            if (this._isMounted)
                this.props.store.set({
                    [storeKey]: this.mapDistrictData(districtData),
                    [callKey]: undefined, // clear API data
                })
        }
    }
    componentWillUnmount() {
        this._isMounted = false
    }

    mapDistrictData = districts => {
        return districts.map(district => {
            const { id, name, tcts } = mapKeysToLowerCase(district)

            this.props.addTranslation(mapApiTranslations({ id, translations: name }))
            return { id, tcts: this.mapTCTData(tcts) }
        })
    }

    mapTCTData = tcts => {
        return tcts.map(tct => {
            const { id, name, symbolicName, tctId, depots } = mapKeysToLowerCase(tct)

            this.props.addTranslation(mapApiTranslations({ id, translations: name }))
            return {
                id,
                symbolicName,
                tctId,
                depots: this.mapDepotData(depots),
            }
        })
    }

    mapDepotData = depots => {
        return depots.map(depot => {
            const { depotId, name, symbolicName } = mapKeysToLowerCase(depot)

            const id = `DEPOT-${symbolicName}`
            this.props.addTranslation(mapApiTranslations({ id, translations: name }))
            return {
                id,
                depotId,
                symbolicName,
            }
        })
    }

    render() {
        return this.props.children
    }
}

export default compose(
    withStore,
    withCall(callKey, {
        config: {
            method: 'get',
            url: '/legacyReferenceData/districts?filterByRoles=true',
        },
        progressHandlers: ProgressHandlers,
    }),
    withRouter,
    withLocalize
)(DistrictsMapper)
