import React from 'react'
import { object, bool, func, string } from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import TaskDetailDrawerView from './TaskDetailDrawerView'
const TaskDrawerView = props => {
    const { isOpen, onClose, classes, translate, rowData, store } = props

    return (
        <Drawer open={isOpen} anchor="right" onClose={onClose}>
            <div className={classes.drawerRoot}>
                <Typography variant="h6">{translate('perfByTrain_drawer_title_task_detail')}</Typography>
                <TaskDetailDrawerView rowData={rowData} translate={translate} store={store} />
            </div>
        </Drawer>
    )
}

TaskDrawerView.propTypes = {
    classes: object.isRequired,
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    translate: func,
    editDrawer: bool,
    addEditError: string,
    rowData: object,
    store: object,
}

export default TaskDrawerView
