import React, { useState, useEffect } from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import { compose } from 'recompose'
import { object, func } from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { Grid, Typography, Divider, Checkbox, FormControlLabel } from '@material-ui/core'
import getBrakingReport from './services/getBrakingReport'
import Loading from 'components/Loading'
import RoundedButton from 'components/RoundedButton'
import ReportData from './ReportData'
import styles from './styles'
import Header from 'components/Header'
import { TranslationContext } from 'context/translation'
import getCompositionByTrain from 'modules/Performances/views/ReadyForDeparture/components/services/getCompositionByTrain.js'
import simulateBrakingReport from 'modules/Performances/Services/simulateBrakingReport'
import { compoDateFromEpochSeconds} from 'utils/dateParser'

const TC286Report = props => {
    const [reportData, setReportData] = useState('')
    const [isBrakingReportSimulated, setIsBrakingReportSimulated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedVersion, setSelectedVersion] = useState()
    const [trainNumber, setTrainNumber] = useState()
    const [time, setTime] = useState()
    const { translate} = React.useContext(TranslationContext)
    const GreyTextTypography = withStyles({
        root: {
            color: '#9e9e9e',
        },
    })(Typography)
    const WhiteTextTypography = withStyles({
        root: {
            color: 'white',
        },
    })(Typography)

    const getBrakingReportSimulation = async composition => {
        const brakingReport = await simulateBrakingReport(composition) 
        return brakingReport
    }

    useEffect(() => {
        let mounted = true
        const { trainComposed, performanceId, taskId, idfNumber, time, fromPtcar, toPtcar} = props.match.params
        const day = new Date(0)
        day.setUTCSeconds(time)
        const formatDate = day
            .toJSON()
            .slice(0, 10)
            .split('-')
        const search = async () => {
            setIsLoading(true)
            const response = await getBrakingReport(performanceId, taskId, idfNumber)
            if (mounted) {
                setTrainNumber(trainComposed)
                setTime(`${formatDate[2] + '-' + formatDate[1] + '-' + formatDate[0]}`)
                response.sort((firstItem, secondItem) => parseInt(firstItem.version) - parseInt(secondItem.version));
                setReportData(response)
                setSelectedVersion(response.length - 1)
                if (response.length == 0){
                    const composition = await getCompositionByTrain(trainComposed, fromPtcar, 
                    compoDateFromEpochSeconds(parseInt(time)), idfNumber, toPtcar)
                    if(composition.status == 200){
                        const brakingReport = await getBrakingReportSimulation(composition.data)
                        if(brakingReport != undefined) {
                            setReportData([brakingReport])
                            setIsBrakingReportSimulated(true)
                            setSelectedVersion(0)
                        }
                    }
                }
                setIsLoading(false)
            }
        }
        search()
        return () => {
            mounted = false
        }
    }, [])

    const handleVersionClick = index => {
        setSelectedVersion(index)
    }

    const getUiField = (fieldNumber, title, ...values) => {
        return (
            <Grid container>
                <Grid item xs={2}>
                    <GreyTextTypography>{fieldNumber}</GreyTextTypography>
                </Grid>
                <Grid item xs={10}>
                    <GreyTextTypography>
                        <Translate id={title} />
                    </GreyTextTypography>
                    {getUiFieldData(values, fieldNumber)}
                </Grid>
            </Grid>
        )
    }
    const getWhiteUiField = (fieldNumber, title, ...values) => {
        return (
            <Grid container>
                <Grid item xs={2}>
                    <WhiteTextTypography>{fieldNumber}</WhiteTextTypography>
                </Grid>
                <Grid item xs={10}>
                    <GreyTextTypography>
                        <Translate id={title} />
                    </GreyTextTypography>
                    {getUiFieldData(values, fieldNumber)}
                </Grid>
            </Grid>
        )
    }
    const getUiFieldData = (values, fieldNumber) => {
        let isField80 = fieldNumber === '80'
        let showExclamationMark =
            isField80 &&
            (reportData[selectedVersion]?.lessThan3InterLocomotives || reportData[selectedVersion]?.hasFirstOrLastIsolated)
        let addMark = showExclamationMark ? '!' : ''
        return values.map((value, index) => {
            if (Array.isArray(value)) value = value.join(', ')
            return (
                <p
                    key={index}
                    style={
                        reportData[selectedVersion]?.display11_5 && isField80
                            ? {
                                  color: 'white',
                                  textDecorationLine: 'line-through',
                                  textDecorationStyle: 'solid',
                              }
                            : { color: 'white' }
                    }
                >
                    {value ? `${value} ${addMark}` : ''}
                </p>
            )
        })
    }

    const getUiFieldWithCheckBox = (fieldNumber, title, isChecked, empty=false) => {
        return (
            <Grid container>
                <Grid item xs={2}>
                    <GreyTextTypography>{fieldNumber}</GreyTextTypography>
                </Grid>
                <Grid item xs={10}>
                    <GreyTextTypography>
                        <Translate id={title} />
                    </GreyTextTypography>

                    <FormControlLabel
                        control={<Checkbox style={{ color: 'grey' }} disabled checked={empty? false :isChecked} />}
                        label={<span style={{ color: 'grey' }}>{props.translate('label_yes')}</span>}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        control={<Checkbox style={{ color: 'grey' }} disabled checked={empty? false :!isChecked} />}
                        label={<span style={{ color: 'grey' }}>{props.translate('label_no')}</span>}
                        labelPlacement="start"
                    />
                </Grid>
            </Grid>
        )
    }
    if (isLoading) return <Loading />
    else
        return (
            <>
                <Header title={'TC286'} />

                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12}>
                            {reportData.length > 0 && !isBrakingReportSimulated &&
                                reportData.map((data, index) => (
                                    <RoundedButton
                                        key={data.version}
                                        title={'version ' + data.version}
                                        onClick={() => handleVersionClick(index)}
                                        isActive={index === selectedVersion}
                                    />
                                ))}
                            {isBrakingReportSimulated &&
                                <RoundedButton
                                title={translate('TC286_report_simulation_label')}
                                disabled={true}
                                />
                            } 
                            {reportData[selectedVersion]?.brakeCalculatorVersion != null ?
                            // eslint-disable-next-line max-len
                                <Typography style={{ marginLeft: '8px', display: 'inline-block' }}>v{reportData[selectedVersion]?.brakeCalculatorVersion}</Typography>
                                : null}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ marginLeft: '8px' }}>{reportData[selectedVersion]?.userName}</Typography>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div>
                                <ReportData title={props.translate('TC286_report_section1_title')}>
                                    <div style={{ margin: '8px' }}>
                                        {getUiField('1', 'TC286_report_train_number', trainNumber)}
                                        <Divider />
                                        {getUiField('2', 'TC286_report_train_start_time', time)}
                                        <Divider />
                                        {getUiField('3', 'TC286_label_3', reportData[selectedVersion]?.symbolicNameCreation)}
                                        <Divider />
                                        {getUiField('63', 'TC286_label_3_A', reportData[selectedVersion]?.technicalSpeedHLV)}
                                        <Divider />
                                        {getUiField('10', 'TC286_label_10', reportData[selectedVersion]?.numberOfLocomotives 
                                        > 0 ? '' 
                                        : reportData[selectedVersion]?.motorUnitPositions)}
                                    </div>
                                    <Divider />
                                </ReportData>
                                <ReportData title={props.translate('TC286_report_section3_title')}>
                                    <div className={props.classes.reportSubSection}>
                                        {getWhiteUiField(
                                            <Translate id="TC286_label_20_header" />,
                                            'TC286_label_20',
                                            reportData[selectedVersion]?.materialTypesLocomotives
                                        )}
                                    </div>
                                    <Divider />
                                    <Typography className={props.classes.reportSubHeader} variant="subtitle1">
                                        <Translate id="TC286_label_21" />
                                    </Typography>
                                    <Divider />
                                    <div className={props.classes.reportSubSection}>
                                        {getUiField(
                                            '21.1',
                                            'TC286_label_21_1',
                                            reportData[selectedVersion]?.numberOfLocomotives
                                        )}

                                        <Divider />
                                        {getUiField(
                                            '21.2',
                                            'TC286_label_21_2',
                                            reportData[selectedVersion]?.numberOfTowedLocomotives
                                        )}

                                        <Divider />
                                        {getUiField(
                                            '21.3',
                                            'TC286_label_21_3',
                                            reportData[selectedVersion]?.numberOfCarriages
                                        )}
                                        <Divider />
                                        {getUiField('21.4', 'TC286_label_21_4', reportData[selectedVersion]?.numberTotal)}
                                    </div>
                                    <Divider />
                                    <Typography className={props.classes.reportSubHeader} variant="subtitle1">
                                        <Translate id="TC286_label_22" />
                                    </Typography>
                                    <Divider />
                                    <div className={props.classes.reportSubSection}>
                                        {getUiField(
                                            '22.1',
                                            'TC286_label_22_1',
                                            reportData[selectedVersion]?.lengthLocomotives
                                        )}

                                        <Divider />
                                        {getUiField(
                                            '22.2',
                                            'TC286_label_22_2',
                                            reportData[selectedVersion]?.lengthTowedLocomotives
                                        )}

                                        <Divider />
                                        {getUiField(
                                            '22.3',
                                            'TC286_label_22_3',
                                            reportData[selectedVersion]?.lengthCarriages
                                        )}

                                        <Divider />
                                        {getUiField('22.4', 'TC286_label_22_4', reportData[selectedVersion]?.lengthTotal)}
                                    </div>
                                    <Divider />
                                    <Typography className={props.classes.reportSubHeader} variant="subtitle1">
                                        <Translate id="TC286_label_23" />
                                    </Typography>
                                    <Divider />
                                    <div className={props.classes.reportSubSection}>
                                        {getUiField(
                                            '23.1',
                                            'TC286_label_23_1',
                                            reportData[selectedVersion]?.massLocomotives
                                        )}

                                        <Divider />
                                        {getUiField(
                                            '23.2',
                                            'TC286_label_23_2',
                                            reportData[selectedVersion]?.massTowedLocomotives
                                        )}

                                        <Divider />
                                        {getUiField(
                                            '23.3',
                                            'TC286_label_23_3',
                                            reportData[selectedVersion]?.massCarriages
                                        )}

                                        <Divider />
                                        {getUiField('23.4', 'TC286_label_23_4', reportData[selectedVersion]?.massTotal)}
                                    </div>
                                    <Divider />
                                    <Typography className={props.classes.reportSubHeader} variant="subtitle1">
                                        <Translate id="TC286_label_24" />
                                    </Typography>
                                    <Divider />
                                    <div className={props.classes.reportSubSection}>
                                        {getUiField(
                                            '24.1',
                                            'TC286_label_24_1',
                                            reportData[selectedVersion]?.brakingMassLocomotives
                                        )}

                                        <Divider />
                                        {getUiField(
                                            '24.2',
                                            'TC286_label_24_2',
                                            reportData[selectedVersion]?.brakingMassTowedLocomotives
                                        )}

                                        <Divider />
                                        {getUiField(
                                            '24.3',
                                            'TC286_label_24_3',
                                            reportData[selectedVersion]?.brakingMassCarriages
                                        )}

                                        <Divider />
                                        {getUiField(
                                            '24.4',
                                            'TC286_label_24_4',
                                            `${reportData[selectedVersion]?.brakingPercentageTotal} %`,
                                            `${reportData[selectedVersion]?.brakingMassTotal} Ton`
                                        )}
                                    </div>
                                    <Divider />
                                    <Typography className={props.classes.reportSubHeader} variant="subtitle1">
                                        <Translate id="TC286_label_25" />
                                    </Typography>
                                    <Divider />
                                    <div className={props.classes.reportSubSection}>
                                        {getUiField(
                                            '25',
                                            'TC286_label_25_1',
                                            `${reportData[selectedVersion]?.minimumBrakingPercentageTotal} %`,
                                            `${reportData[selectedVersion]?.minimumBrakingMassTotal} Ton`
                                        )}
                                        <Divider />
                                    </div>
                                </ReportData>
                            </div>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <div>
                                <ReportData title={props.translate('TC286_report_section2_title')}>
                                    <div style={{ margin: '8px' }}>
                                        {getUiField(
                                            '11.1',
                                            'TC286_label_11_1',
                                            reportData[selectedVersion]?.vehiclesWithIsolatedBrakes.join()
                                        )}

                                        <Divider />
                                        {getUiField('11.2', 'TC286_label_11_2', reportData[selectedVersion]?.comments)}

                                        <Divider />
                                        {getUiField(
                                            '11.5',
                                            'TC286_label_11_5',
                                            reportData[selectedVersion]?.display11_5
                                                ? reportData[selectedVersion]?.maxSpeedLimit
                                                : ''
                                        )}
                                        <Divider />
                                    </div>
                                </ReportData>
                                <ReportData title={props.translate('TC286_report_section4_title')}>
                                    <div style={{ margin: '8px' }}>
                                        {getUiFieldWithCheckBox(
                                            '301',
                                            'TC286_label_301',
                                            reportData[selectedVersion]?.m6OrM7CarriagesPresent
                                        )}
                                        <Divider />
                                        {/* UI FIELD TODO */}
                                        {getUiField(
                                            '100',
                                            'TC286_label_100',
                                            // eslint-disable-next-line max-len
                                            reportData[selectedVersion]?.materialTypesTowedLocomotives
                                        )}
                                        <Divider />
                                        {getUiFieldWithCheckBox(
                                            '102',
                                            'TC286_label_102',
                                            reportData[selectedVersion]?.hasCarriagesWithPneumaticSuspension
                                        )}
                                        <Divider />
                                        {getUiFieldWithCheckBox(
                                            '302',
                                            'TC286_label_302',
                                            reportData[selectedVersion]?.hasCarriagesWithIsolatedPneumaticSuspension
                                        )}

                                        <Divider />
                                        {getUiFieldWithCheckBox(
                                            ' ',
                                            'TC286_label_302_B',
                                            reportData[selectedVersion]?.areTravellersTransferred,
                                            !reportData[selectedVersion]?.hasCarriagesWithIsolatedPneumaticSuspension
                                        )}

                                        <Divider />
                                    </div>
                                </ReportData>
                                <ReportData title={props.translate('TC286_report_section5_title')}>
                                    <div style={{ margin: '8px' }}>
                                        {getUiField('80', 'TC286_label_80', reportData[selectedVersion]?.normalSpeed)}

                                        <Divider />
                                    </div>
                                </ReportData>
                            </div>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </>
        )
}
TC286Report.propTypes = {
    classes: object,
    translate: func,
    match: object,
}
export default compose(withLocalize, withStyles(styles))(TC286Report)
