export const statusEnum = {
    SIGNED_IN: 1 << 0,
    NOT_SIGNED_IN: 1 << 1,
    NOT_ASSIGNED: 1 << 2,
    NOT_VALIDATED: 1 << 3,
    SIGNED_OFF: 1 << 4,
    // Allow for statusEnum[myvalue] === "NOT_SIGNED_IN" f.e.
    [1 << 0]: 'SIGNED_IN',
    [1 << 1]: 'NOT_SIGNED_IN',
    [1 << 2]: 'NOT_ASSIGNED',
    [1 << 3]: 'NOT_VALIDATED',
    [1 << 4]: 'SIGNED_OFF',
}

export const tabToStatus = [
    statusEnum.NOT_SIGNED_IN,
    statusEnum.SIGNED_IN,
    statusEnum.NOT_ASSIGNED,
    statusEnum.NOT_VALIDATED,
    statusEnum.SIGNED_OFF,
]

export const statusToTab = {
    [statusEnum.NOT_SIGNED_IN]: 0,
    [statusEnum.SIGNED_IN]: 1,
    [statusEnum.NOT_ASSIGNED]: 2,
    [statusEnum.NOT_VALIDATED]: 3,
    [statusEnum.SIGNED_OFF]: 4,
}
