import React from 'react'
import { withStyles } from '@material-ui/styles'
import ListHeaderStyles from './ListHeaderStyles'
import PropTypes from 'prop-types'
import { ESemesContext } from 'modules/eSemes/esemes.store'
import { ListAccordion } from './ListAccordion'

const ListRows = ({ classes }) => {
    const { STATE } = React.useContext(ESemesContext)

    const sortForTasks = messages => {
        return messages.filter(el => {
            const allDistrictsForMessage = el.profileInfo
                .map(f => [f.districtDescription[0].translation, f.districtDescription[1].translation])
                .flat()
            const s = allDistrictsForMessage.map(el => STATE.filterState.district.includes(el))
            return s.includes(true)
        })
    }

    const Messages = STATE.filterState.dataFilter.length > 0 ? STATE.filterState.filterdResults : STATE.apiState.eSemesData
    if (STATE.filterState.hasTaskApplicable) {
        const districtSorted = sortForTasks(Messages)
        return districtSorted.map((el, i) => <ListAccordion key={i} classes={classes} el={el} i={i} />)
    } else {
        return Messages.map((el, i) => <ListAccordion key={i} classes={classes} el={el} i={i} />)
    }
}

ListRows.propTypes = {
    classes: PropTypes.object,
}

export default withStyles(ListHeaderStyles)(ListRows)
