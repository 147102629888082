import React, { useState } from 'react'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

import FeedbackListView from '../components/FeedbackListView'
import styles from '../styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { getFeedbackWithRoute } from '../Utils'
import RouteView from './RouteView'

const isRouteData = rowData => Object.prototype.hasOwnProperty.call(rowData, 'feedback')

const Page2 = ({ data, translate, classes, activeLanguage, handleImageClick }) => {
    const [openFeedbackIndex, setIndex] = useState(-1)
    let page2Data = getFeedbackWithRoute(data)

    return (
        <div data-testid="page-2" className={classes.root}>
            <Paper>
                <Grid container spacing={0} className={classes.outerPadding}>
                    <Grid className={classes.column} item xs={12}>
                        <Typography className={classes.text}>Overview per task type</Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={2}>
                        <Typography className={classes.text}>{translate('datatable_header_task_code')}</Typography>
                    </Grid>
                    <Grid className={classes.column} item xs={10}>
                        <Typography className={classes.text}>{translate('module_feedback')}</Typography>
                    </Grid>

                    {page2Data.map((row, index) => (
                        <React.Fragment key={index}>
                            <Grid className={classes.column} item xs={2}>
                                <div>
                                    <Typography className={classes.text}>
                                        {row.code === 'DRIVE' ? row.trainNumber : row.code}
                                    </Typography>
                                    {row.feedback.length > 1 && (
                                        <Typography className={classes.text}>{` (${row.feedback.length})`}</Typography>
                                    )}
                                    {row.feedback.length > 1 ? (
                                        openFeedbackIndex !== -1 && openFeedbackIndex === index ? (
                                            <ExpandLess className={classes.icon} onClick={() => setIndex(-1)} />
                                        ) : (
                                            <ExpandMore className={classes.icon} onClick={() => setIndex(index)} />
                                        )
                                    ) : null}
                                </div>
                            </Grid>
                            <Grid className={classes.categoryColor} item xs={10}>
                                {row.feedback.length > 1 && openFeedbackIndex === index ? (
                                    <FeedbackListView
                                        feedbacks={row.feedback}
                                        classes={classes}
                                        activeLanguage={activeLanguage}
                                        handleImageClick={handleImageClick}
                                    />
                                ) : (
                                    row.feedback.length && (
                                        <Grid item xs={12}>
                                            {isRouteData(row.feedback[0]) ? (
                                                <RouteView
                                                    rowData={row.feedback[0]}
                                                    classes={classes}
                                                    activeLanguage={activeLanguage}
                                                    handleImageClick={handleImageClick}
                                                />
                                            ) : (
                                                <React.Fragment>
                                                    <Grid className={classes.column} item xs={12}>
                                                        <Typography className={classes.text}>
                                                            {row.feedback[0].feedbackFreeText
                                                                ? row.feedback[0].feedbackFreeText
                                                                : row.feedback[0].feedback?.feedbackFreeText}
                                                        </Typography>
                                                        <hr />
                                                        <Typography className={classes.text}>
                                                            {row.feedback[0].feedbackCategory1Value
                                                                ? row.feedback[0].feedbackCategory1Value +
                                                                  `${
                                                                      row.feedback[0].feedbackCategory2Value
                                                                          ? ' > ' +
                                                                            row.feedback[0].feedbackCategory2Value +
                                                                            (row.feedback[0].feedbackCategory3Value
                                                                                ? ' > ' +
                                                                                  row.feedback[0].feedbackCategory3Value
                                                                                : '')
                                                                          : ''
                                                                  }`
                                                                : row.feedback[0]?.feedback?.feedbackCategory1Value
                                                                ? row.feedback[0]?.feedback?.feedbackCategory1Value +
                                                                  `${
                                                                      row.feedback[0]?.feedback?.feedbackCategory2Value
                                                                          ? ' > ' +
                                                                            row.feedback[0]?.feedback
                                                                                ?.feedbackCategory2Value +
                                                                            (row.feedback[0]?.feedback
                                                                                ?.feedbackCategory3Value
                                                                                ? ' > ' +
                                                                                  row.feedback[0]?.feedback
                                                                                      ?.feedbackCategory3Value
                                                                                : '')
                                                                          : ''
                                                                  }`
                                                                : ''}
                                                        </Typography>
                                                        {row.feedback[0].feedback
                                                            ? row.feedback[0].feedback.feedbackImagesWithBlobUrl &&
                                                              row.feedback[0].feedback.feedbackImagesWithBlobUrl.length >
                                                                  0 && (
                                                                  <React.Fragment>
                                                                      <hr />
                                                                      <div style={{ display: 'flex' }}>
                                                                          {/* eslint-disable-next-line max-len */}
                                                                          {row.feedback[0].feedback.feedbackImagesWithBlobUrl.map(
                                                                              (link, linkIndex) => (
                                                                                  <Typography
                                                                                      key={linkIndex}
                                                                                      className={classes.link}
                                                                                      onClick={() => handleImageClick(link)}
                                                                                  >
                                                                                      {` Open Image${linkIndex + 1}, `}
                                                                                  </Typography>
                                                                              )
                                                                          )}
                                                                      </div>
                                                                  </React.Fragment>
                                                              )
                                                            : row.feedback[0].feedbackImagesWithBlobUrl &&
                                                              row.feedback[0].feedbackImagesWithBlobUrl.length > 0 && (
                                                                  <React.Fragment>
                                                                      <hr />
                                                                      <div style={{ display: 'flex' }}>
                                                                          {row.feedback[0].feedbackImagesWithBlobUrl.map(
                                                                              (link, linkIndex) => (
                                                                                  <Typography
                                                                                      data-testid="imageLink"
                                                                                      key={linkIndex}
                                                                                      className={classes.link}
                                                                                      onClick={() => handleImageClick(link)}
                                                                                  >
                                                                                      {` Open Image${linkIndex + 1}, `}
                                                                                  </Typography>
                                                                              )
                                                                          )}
                                                                      </div>
                                                                  </React.Fragment>
                                                              )}
                                                    </Grid>
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </Paper>
        </div>
    )
}
Page2.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    classes: PropTypes.object,
    translate: PropTypes.func,
    handleImageClick: PropTypes.func,
    activeLanguage: PropTypes.object,
}
export default compose(withStore, withLocalize, withStyles(styles))(Page2)
