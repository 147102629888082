const netIdData = [
    { label: ' ', netId: '' },
    { label: '10-FI', netId: 10 },
    { label: '20-RU', netId: 20 },
    { label: '21-BY', netId: 21 },
    { label: '22-UA', netId: 22 },
    { label: '24-LT', netId: 24 },
    { label: '25-LV', netId: 25 },
    { label: '26-EE', netId: 26 },
    { label: '27-KZ', netId: 27 },
    { label: '28-GE', netId: 28 },
    { label: '29-UZ', netId: 29 },
    { label: '30-KP', netId: 30 },
    { label: '31-MN', netId: 31 },
    { label: '32-VN', netId: 32 },
    { label: '33-CN', netId: 33 },
    { label: '40-CU', netId: 40 },
    { label: '41-AL', netId: 41 },
    { label: '42-JP', netId: 42 },
    { label: '44-BA', netId: 44 },
    { label: '49-BA', netId: 49 },
    { label: '50-BA', netId: 50 },
    { label: '51-PL', netId: 51 },
    { label: '52-BG', netId: 52 },
    { label: '53-RO', netId: 53 },
    { label: '54-CZ', netId: 54 },
    { label: '55-HU', netId: 55 },
    { label: '56-SK', netId: 56 },
    { label: '57-AZ', netId: 57 },
    { label: '58-AM', netId: 58 },
    { label: '59-KG', netId: 59 },
    { label: '60-IE', netId: 60 },
    { label: '61-KR', netId: 61 },
    { label: '62-ME', netId: 62 },
    { label: '65-MK', netId: 65 },
    { label: '66-TJ', netId: 66 },
    { label: '67-TM', netId: 67 },
    { label: '68-AF', netId: 68 },
    { label: '70-GB', netId: 70 },
    { label: '71-ES', netId: 71 },
    { label: '72-RS', netId: 72 },
    { label: '73-GR', netId: 73 },
    { label: '74-SE', netId: 74 },
    { label: '75-TR', netId: 75 },
    { label: '76-NO', netId: 76 },
    { label: '78-HR', netId: 77 },
    { label: '79-SI', netId: 79 },
    { label: '80-DE', netId: 80 },
    { label: '81-AT', netId: 81 },
    { label: '82-LU', netId: 82 },
    { label: '83-IT', netId: 83 },
    { label: '84-NL', netId: 84 },
    { label: '85-CH', netId: 85 },
    { label: '86-DK', netId: 86 },
    { label: '87-FR', netId: 87 },
    { label: '88-BE', netId: 88 },
    { label: '90-EG', netId: 90 },
    { label: '91-TN', netId: 91 },
    { label: '92-DZ', netId: 92 },
    { label: '93-MA', netId: 93 },
    { label: '94-PT', netId: 94 },
    { label: '95-IL', netId: 95 },
    { label: '96-IR', netId: 96 },
    { label: '97-SY', netId: 97 },
    { label: '98-LB', netId: 98 },
    { label: '99-IQ', netId: 99 },
]
export default netIdData
