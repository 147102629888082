import React, { useContext } from 'react'

import PneumaticListView from './PneumaticListView'
import { withStyles } from '@material-ui/styles'
import styles from './styles'
import { TranslationContext } from 'context/translation'

const PneumaticSuspensionRestrictionView = props => {
    const { translate } = useContext(TranslationContext)
    return <PneumaticListView {...props} translate={translate} />
}

export default withStyles(styles)(PneumaticSuspensionRestrictionView)
