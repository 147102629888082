export default theme => ({
    button: {
        marginRight: theme.spacing(1),
        height: theme.spacing(3) + theme.spacing(1) * 2,
    },
    text: {
        flexGrow: 1,
    },
    underlined: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
})
