import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import LocationSearchView from './LocationSearchView'
import styles from './styles'

class LocationSearch extends Component {
    render() {
        return <LocationSearchView {...this.props} />
    }
}

export default withStyles(styles)(LocationSearch)
