import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TableSortLabel } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { UnfoldMore } from '@material-ui/icons'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const EndpointList = props => {
    const [sortActiveProperty, setSortActiveProperty] = useState('')
    const [sortOrder, setSortOrder] = useState('asc')

    const handleSort = propertyName => {
        if (sortActiveProperty === propertyName) {
            sortOrder === 'desc' ? setSortOrder('asc') : setSortOrder('desc')
        } else {
            setSortOrder('asc')
            setSortActiveProperty(propertyName)
        }
    }
    const SortArray = (propertyName, order) => (x, y) => {
        const collator = new Intl.Collator('en')
        if (order === 'asc') return collator.compare(x[propertyName], y[propertyName])
        else return collator.compare(y[propertyName], x[propertyName])
    }

    const sortData = () => {
        if (!data) return []

        if (sortActiveProperty == '') return data

        return data.sort(SortArray(sortActiveProperty, sortOrder))
    }

    const { data, translate } = props
    const sortedData = Array.isArray(data) ? sortData() : []

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ width: '20%', cursor: 'pointer' }}
                            onClick={() => handleSort('endpointGroupName')}
                        >
                            <TableSortLabel active={sortActiveProperty === 'endpointGroupName'} direction={sortOrder}>
                                {translate('manage_endpoints_group_header')}
                                {sortActiveProperty !== 'endpointGroupName' && <UnfoldMore fontSize={'small'} />}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: '30%', cursor: 'pointer' }} onClick={() => handleSort('name')}>
                            <TableSortLabel active={sortActiveProperty === 'name'} direction={sortOrder}>
                                {translate('manage_endpoints_action_header')}
                                {sortActiveProperty !== 'name' && <UnfoldMore fontSize={'small'} />}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: '40%', cursor: 'pointer' }} onClick={() => handleSort('description')}>
                            <TableSortLabel active={sortActiveProperty === 'description'} direction={sortOrder}>
                                {translate('manage_endpoints_description_header')}
                                {sortActiveProperty !== 'description' && <UnfoldMore fontSize={'small'} />}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: '10%', cursor: 'pointer' }} onClick={() => handleSort('httpMethod')}>
                            <TableSortLabel active={sortActiveProperty === 'httpMethod'} direction={sortOrder}>
                                {translate('manage_endpoints_http_header')}
                                {sortActiveProperty !== 'httpMethod' && <UnfoldMore fontSize={'small'} />}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData &&
                        sortedData.map((item, idx) => (
                            <React.Fragment key={idx}>
                                <TableRow style={{ height: '56px' }} key={idx}>
                                    <TableCell>
                                        <Typography variant="body1">{`${item.endpointGroupName}`}</Typography>
                                    </TableCell>
                                    {props.showCheckboxes && (
                                        <TableCell>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={item.isSelected}
                                                        onChange={() => props.handleCheckboxChange(item)}
                                                        color="primary"
                                                    />
                                                }
                                                label={item.name}
                                            />
                                        </TableCell>
                                    )}
                                    {!props.showCheckboxes && <TableCell variant="body1">{item.name}</TableCell>}
                                    <TableCell variant="body1">{item.description}</TableCell>
                                    <TableCell variant="body1">{item.httpMethod}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                </TableBody>
            </Table>
        </div>
    )
}
EndpointList.propTypes = {
    data: PropTypes.array,
    translate: PropTypes.func,
    handleCheckboxChange: PropTypes.func,
    showCheckboxes: PropTypes.bool,
}
export default EndpointList
