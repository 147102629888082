// export default theme => ({
//   drawerRoot: {
//     padding: theme.spacing(2),
//     width: "500px"
//   }
// });

export default theme => ({
    drawerRoot: {
        // display: "flex",
        // justifyContent: "space-between",
        // flexDirection: "column",
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        width: `${theme.readStatusBoxes}px`,
        padding: `${theme.spacing(3)}px`,
        '@media (max-width: 577px)': {
            width: '85vw',
            overflowX: 'hidden',
        },
    },
    drawerHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: `${theme.readStatusBoxes - 2 * theme.spacing(3)}px`,
        marginBottom: theme.spacing(3),
    },
    performanceInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
    },
    mb: {
        marginBottom: theme.spacing(2),
    },
    performanceInfoCell: {
        width: '33%',
    },

    root: {
        'font-size': 12,
        marginLeft: '5px',
    },
    boldText: {
        fontWeight: 'bold',
        color: theme.customColors.white,
    },
    agendaIcon: {
        'font-size': 17,
        color: theme.customColors.white,
        '&:hover': {
            color: theme.customColors.lightGrey,
        },
    },
    agendaLink: {
        fontWeight: 'bold',
        textDecoration: 'underline',
        color: theme.customColors.white,
    },
    agenda: {
        display: 'flex',
        cursor: 'pointer',
    },
    editButton: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        background: '#2979ff',
    },
})
