import SpeedAdvicesSwitch from './speedAdvicesSwitch'
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'
import { withStore, withStatus, enums } from 'react-ion-store'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'

import styles from './styles'

const {
    CallStatus: { READY, FAILED },
} = enums

const Failed = () => <div>Error retrieving Roles!</div>

class ESpeed extends React.Component {
    static propTypes = {
        store: PropTypes.object,
        __ion_status: PropTypes.object,
        history: PropTypes.object,
        translate: PropTypes.func,
    }

    render() {
        const { store, __ion_status, history } = this.props
        const { userRoles } = __ion_status

        const { roles, moduleList } = store.get(['roles', 'moduleList'])

        const rolesLoading = userRoles !== READY || !roles
        const rolesFailed = userRoles === FAILED && !roles
        if (rolesFailed)
            return (
                <React.Fragment>
                    <Failed />
                </React.Fragment>
            )
        else if (rolesLoading)
            return (
                <React.Fragment>
                    <Loading />
                </React.Fragment>
            )
        else if (!!moduleList && !moduleList.includes('/eSpeed')) {
            history.replace(`/`)
        }
        return (
            <React.Fragment>
                <SpeedAdvicesSwitch {...this.props} roles={roles}/>
            </React.Fragment>
        )
    }
}

export default compose(withRouter, withLocalize, withStyles(styles), withStore, withStatus)(ESpeed)
