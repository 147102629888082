import React from 'react'
import { object } from 'prop-types'
import { Translate } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import PrimaryButton from 'components/PrimaryButton'
import LanguagePicker from 'components/LanguagePicker'
import PortalLogo from 'components/PortalLogo'

import styles from './styles'

const LockedView = ({ classes }) => (
    <React.Fragment>
        <AppBar color="default" elevation={1}>
            <Toolbar className={classes.flex}>
                <PortalLogo className={classes.logo} />
            </Toolbar>
        </AppBar>
        <div className={classes.loginBody}>
            <Paper className={classes.loginDialog}>
                <Typography variant="h6" component="h2" className={classes.loginTitle}>
                    <Translate id="page_error_ie" />
                </Typography>
                <Translate>
                    {({ translate }) => (
                        <PrimaryButton
                            title={translate('label_chrome')}
                            className={classes.loginButton}
                            href="https://www.google.com/chrome/"
                        />
                    )}
                </Translate>
            </Paper>
            <div className={classes.languagePicker}>
                <LanguagePicker alwaysWhite />
            </div>
        </div>
    </React.Fragment>
)

LockedView.propTypes = {
    classes: object.isRequired,
}

export default withStyles(styles)(LockedView)
