import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { object } from 'prop-types'
import { ESemesContext } from 'modules/eSemes/esemes.store'

import { Container } from '@material-ui/core'

import ListHeader from './customList/ListHeader'
import ListRows from './customList/ListRows'
import ListRowsPerGroup from './customList/ListRowsPerGroup'

const CustomList = ({ classes }) => {
    const { STATE } = React.useContext(ESemesContext)

    if (STATE.toggleState.groupPerZone) {
        return (
            <Container maxWidth="xl" className={classes.documentTableWrapper} style={{ marginTop: '35px' }}>
                <ListRowsPerGroup />
            </Container>
        )
    }
    return (
        <Container maxWidth="xl" className={classes.documentTableWrapper} style={{ marginTop: '35px' }}>
            <ListHeader />
            <ListRows />
            <div style={{ height: '100px' }}></div>
        </Container>
    )
}

CustomList.propTypes = {
    classes: object,
}

export default withStyles(styles)(CustomList)
