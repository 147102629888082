import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { Translate, withLocalize } from 'react-localize-redux'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import StatusLabel from 'components/StatusLabel'
import TaskSelectListView from './TaskSelectListView'

import styles from './styles'
const columns = [
    {
        width: 100,
        label: 'datatable_header_actions',
        dataKey: 'checkbox',
    },
    {
        width: 200,
        label: 'datatable_header_task_code',
        dataKey: 'code',
    },
    {
        width: 400,
        label: 'datatable_header_task_info',
        dataKey: 'taskInfo',
    },
    {
        width: 200,
        label: 'datatable_header_task_diagram',
        dataKey: 'diagramNumber',
    },
    {
        width: 200,
        label: 'datatable_header_task_start',
        dataKey: 'plannedStartTime',
    },
    {
        width: 200,
        label: 'datatable_header_task_end',
        dataKey: 'plannedEndTime',
    },
    {
        width: 250,
        label: 'datatable_header_task_status',
        dataKey: 'status',
    },
]

export class TaskSelectList extends Component {
    static propTypes = {
        tasks: PropTypes.arrayOf(PropTypes.object),
        columns: PropTypes.array,
        classes: PropTypes.object,
        headerHeight: PropTypes.number,
        handleCheckTasks: PropTypes.func,
        checkedTasks: PropTypes.array,
        addMultipleTasksLoading: PropTypes.bool,
        handleCheckAll: PropTypes.func,
        checkAll: PropTypes.bool,
        rowHeight: PropTypes.number,
        rowClassName: PropTypes.string,
    }

    static defaultProps = {
        rowHeight: 56,
        headerHeight: 56,
    }

    headerRenderer = headerProps => {
        const {
            classes,
            headerHeight,

            addMultipleTasksLoading,
            handleCheckAll,
            checkAll,
        } = this.props

        if (headerProps.dataKey === 'checkbox') {
            return (
                <TableCell
                    component="div"
                    className={classNames(classes.tableCell, classes.flexContainer)}
                    variant="head"
                    style={{ height: headerHeight }}
                >
                    <Checkbox
                        disabled={addMultipleTasksLoading}
                        checked={checkAll}
                        onChange={() => handleCheckAll(checkAll)}
                        value={'all'}
                        color="primary"
                    />
                </TableCell>
            )
        }
        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer)}
                variant="head"
                style={{ height: headerHeight }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }

    cellRenderer = cellProps => {
        const { classes, rowHeight, handleCheckTasks, checkedTasks, addMultipleTasksLoading } = this.props
        const { dataKey, rowData } = cellProps

        let data = rowData[dataKey]
        if (dataKey === 'checkbox' && rowData.code !== 'START' && rowData.code !== 'END') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Checkbox
                        disabled={addMultipleTasksLoading}
                        checked={checkedTasks.indexOf(rowData['id']) > -1}
                        onChange={() => handleCheckTasks(rowData['id'])}
                        value={rowData['id']}
                        color="primary"
                    />
                </TableCell>
            )
        }

        if (dataKey === 'plannedEndTime' || dataKey === 'plannedStartTime') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{rowData[dataKey] ? rowData[dataKey].substr(0, 5) : ''}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'code' && rowData.code.toUpperCase() === 'DRIVE') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{rowData.trainComposed}</Typography>
                </TableCell>
            )
        }

        return (
            <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                {dataKey === 'status' && <StatusLabel status={rowData[dataKey]} isAbolished={rowData['isAbolished']} />}

                {dataKey !== 'status' && (
                    <Typography>
                        {dataKey === 'taskInfo' && rowData.code === 'START' && (
                            <Translate id="datatable_label_service_start" />
                        )}
                        {dataKey === 'taskInfo' && rowData.code === 'END' && <Translate id="datatable_label_service_end" />}
                        {dataKey === 'checkbox' && rowData.code === 'START' && <Translate id="datatable_header_actions" />}
                        {dataKey === 'checkbox' && rowData.code === 'END' && <Translate id="datatable_header_actions" />}
                        {(dataKey !== 'taskInfo' ||
                            (dataKey === 'taskInfo' && rowData.code !== 'END' && rowData.code !== 'START')) &&
                            data}
                    </Typography>
                )}
            </TableCell>
        )
    }

    getRowClassName = () => {
        const { classes, rowClassName } = this.props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName)
    }

    render() {
        const { tasks, classes, rowHeight, headerHeight } = this.props

        if (tasks?.length > 0)
            return (
                <TaskSelectListView
                    tasks={tasks}
                    columns={columns}
                    classes={classes}
                    getRowClassName={this.getRowClassName}
                    cellRenderer={this.cellRenderer}
                    headerRenderer={this.headerRenderer}
                    rowHeight={rowHeight}
                    headerHeight={headerHeight}
                />
            )
        return <Typography>No tasks available</Typography>
    }
}

export default compose(withStyles(styles), withLocalize)(TaskSelectList)
