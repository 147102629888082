import React from 'react'
import { Lottie } from '@crello/react-lottie'
import animationData from './loading.json'

export default class Loading extends React.PureComponent {
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,

            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        }

        return <Lottie style={{ marginLeft: 'auto', marginRight: 'auto' }} config={defaultOptions} width={300} />
    }
}
