import React from 'react'
import PropTypes from 'prop-types'

import { TranslationContext } from 'context/translation'

import { Accordion, AccordionSummary, Container, Typography, AccordionDetails, Grid } from '@material-ui/core'

import Summary from './Summary'
import SummaryTitle from './SummaryTitle'

import { apiDateTimeParser } from 'utils/dateParser'

import { semesDateTime } from 'utils/dateParser'
import { colorFilter, districtIdMapper } from 'modules/eSemes/esemes.util'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import returnTranslationForLocale from 'utils/returnTranslationForLocale'
import { ESemesContext } from 'modules/eSemes/esemes.store'
import { getSemesReadStatusBySemesId } from 'modules/eSemes/esemes.service'

export const ListAccordion = ({ classes, el, i }) => {
    const { activeLanguage } = React.useContext(TranslationContext)
    const { STATE } = React.useContext(ESemesContext)

    // eslint-disable-next-line no-unused-vars
    const [semesResult, setSemesResult] = React.useState([])
    const [isLoadingDetails, setIsLoadingDetails] = React.useState(false)

    const ref = React.useRef(null)
    const NoIcon = () => <span className={classes.noIcon}></span>

    const getTaskData = () => {
        setIsLoadingDetails(true)
        const classListArray = Object.keys(ref.current.classList).map(el => ref.current.classList[el])
        if (!classListArray.includes('Mui-expanded') && semesResult.length === 0) {
            const districtIds = el.profileInfo.map(el => districtIdMapper(el.districtDescription[1].translation))
            getSemesReadStatusBySemesId(
                el.semesId,
                apiDateTimeParser(STATE.timeState.apiDate.ts),
                STATE.timeState.timeMargins.lower,
                STATE.timeState.timeMargins.upper,
                districtIds,
                el.lineSection.lineSectionId,
                el.validityPeriod.validityPeriodId
            ).then(res => {
                const allIdfs = el.profileInfo.map(p => p.idfNumber)
                if (res) {
                    const filterdResults = res.filter(f => allIdfs.includes(f.idfNumber))
                    setSemesResult(filterdResults)
                    setIsLoadingDetails(false)
                } else {
                    setSemesResult([])
                    setIsLoadingDetails(false)
                }
            })
        } else {
            setIsLoadingDetails(false)
            null
        }
    }

    return (
        <Accordion
            className={classes.acco}
            key={i}
            ref={ref}
            onClick={() => {
                getTaskData()
            }}
        >
            <AccordionSummary
                width="xl"
                className={classes.accoSum}
                expandIcon={
                    el.profileInfo.length > 0 && STATE.filterState.hasTaskApplicable ? <ExpandMoreIcon /> : <NoIcon />
                }
                disabled={el.profileInfo.length > 0 && STATE.filterState.hasTaskApplicable ? false : true}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Container maxWidth="xl" className={classes.row}>
                    <Typography>
                        <span
                            className={classes.colorSpan}
                            style={{
                                backgroundColor: colorFilter(
                                    returnTranslationForLocale(
                                        el.setyDescription,
                                        activeLanguage.code === 'nl' ? 'nl-BE' : 'fr-BE'
                                    )
                                ),
                            }}
                        >
                            {returnTranslationForLocale(
                                el.setyDescription,
                                activeLanguage.code === 'nl' ? 'nl-BE' : 'fr-BE'
                            )}
                        </span>
                    </Typography>
                    <Typography className={classes.title}>{el.lineSection.userLineName || ''}</Typography>
                    <Typography>
                        <>
                            <span className={classes.semesTitle}>
                                {el.additionSemesInfrabelNr} -{' '}
                                {returnTranslationForLocale(
                                    el.lineSection.ptCarFromCommercialMediumName,
                                    activeLanguage.code === 'nl' ? 'nl-BE' : 'fr-BE'
                                ) || ''}{' '}
                                -{' '}
                                {returnTranslationForLocale(
                                    el.lineSection.ptCarToCommercialMediumName,
                                    activeLanguage.code === 'nl' ? 'nl-BE' : 'fr-BE'
                                ) || ''}
                            </span>
                            <span className={classes.semesTime}>
                                {semesDateTime(parseInt(el.validityPeriod.beginSemesDate))} tot{' '}
                                {semesDateTime(parseInt(el.validityPeriod.endSemesDate))}
                            </span>
                        </>
                    </Typography>
                    <Typography>
                        {returnTranslationForLocale(
                            el.lineSection.trackType,
                            activeLanguage.code === 'nl' ? 'nl-BE' : 'fr-BE'
                        )}
                        {el.lineSection.speedLimitation ? ': ' + el.lineSection.speedLimitation : ''}
                    </Typography>
                    {STATE.filterState.hasTaskApplicable && (
                        <>
                            <Typography>
                                <span
                                    className={
                                        Math.round(el.numberOfTrainDriversReceivedSemesPercentage) < 75
                                            ? classes.redPercentage
                                            : null
                                    }
                                    style={{ fontWeight: 'bold' }}
                                >
                                    {el.numberOfTrainDriversReceivedSemes} (
                                    {Math.round(el.numberOfTrainDriversReceivedSemesPercentage)}
                                    %)
                                </span>
                            </Typography>
                            <Typography>
                                <span
                                    className={
                                        Math.round(el.numberOfTrainDriversReadSemesPercentage) < 75
                                            ? classes.redPercentage
                                            : null
                                    }
                                    style={{ fontWeight: 'bold' }}
                                >
                                    {el.numberOfTrainDriversReadSemes} (
                                    {Math.round(el.numberOfTrainDriversReadSemesPercentage)}%)
                                </span>
                            </Typography>
                        </>
                    )}
                </Container>
            </AccordionSummary>
            {el.profileInfo.length > 0 && (
                <AccordionDetails>
                    <Grid container width="100%">
                        <Grid item xs={12} className={classes.summaryGrid}>
                            <SummaryTitle />
                        </Grid>
                        {el.profileInfo
                            .sort((a, b) => a.lastName.localeCompare(b.lastName))
                            .map((pr, i) => {
                                return (
                                    <Grid item xs={12} key={i} className={classes.summaryGrid}>
                                        <Summary data={pr} semesData={semesResult} isLoading={isLoadingDetails} />
                                    </Grid>
                                )
                            })}
                    </Grid>
                </AccordionDetails>
            )}
        </Accordion>
    )
}

ListAccordion.propTypes = {
    classes: PropTypes.object,
    el: PropTypes.object,
    i: PropTypes.number,
}
