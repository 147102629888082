import React from 'react'
import { array, func, object, number, string } from 'prop-types'
import { AutoSizer, Column, Table } from 'react-virtualized'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

// Material UI
import { withStyles } from '@material-ui/core/styles'

import cellRenderer from './components/CellRenderer'
import headerRenderer from './components/HeaderRenderer'

import { statusEnum } from 'utils/statusEnum'

import { notSignedInColumns, signedInColumns, signedOffColumns } from './columns'
import styles from './styles'
import { compose } from 'recompose'
import withMediaQuery from 'utils/hocs/withMediaQuery'

class DataTable extends React.Component {
    static propTypes = {
        classes: object.isRequired,
        storage: object,
        rowHeight: number,
        headerHeight: number,
        performances: array,
        activeTab: number.isRequired,
        selectPerformance: func.isRequired,
        openDelayReasonDialog: func,
        openAssignDriverDialog: func,
        openUnassignDriverDialog: func,
        saveScrollPosition: func,
        scrollPosition: number,
        rowClassName: string,
        onRowClick: func,
        openPerformanceDriveDetailsDrawer: func,
        openDeletePerformanceDialog: func,
    }

    getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = this.props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    cellRenderer = cellProps => cellRenderer(cellProps, this.props)

    headerRenderer = headerProps => headerRenderer(headerProps, this.props)

    render() {
        const {
            classes,
            activeTab,
            selectPerformance,
            storage,
            scrollPosition,
            saveScrollPosition,
            openPerformanceDriveDetailsDrawer,
            openDeletePerformanceDialog,
        } = this.props

        let columns = []
        let { performances } = this.props
        let sortedPerformances = [...performances]
        switch (activeTab) {
            case statusEnum.NOT_SIGNED_IN:
                columns = notSignedInColumns
                break
            case statusEnum.SIGNED_IN:
                columns = signedInColumns
                sortedPerformances = performances.sort((a, b) => (a.plannedStartTime > b.plannedStartTime ? 1 : -1))
                break
            case statusEnum.NOT_ASSIGNED:
            case statusEnum.NOT_VALIDATED:
                columns = signedInColumns
                break
            case statusEnum.SIGNED_OFF:
                columns = signedOffColumns
                break
            default:
                break
        }
        const performanceCount = sortedPerformances.length + 2 // +2 is for some spacing
        return (
            <div
                className={classes.documentTableWrapper}
                style={{ height: performanceCount < 10 ? performanceCount * this.props.rowHeight : '' }}
            >
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            data-testid="performance-table"
                            className={classes.table}
                            height={height}
                            width={width}
                            rowClassName={this.getRowClassName}
                            rowCount={sortedPerformances.length}
                            rowGetter={({ index }) => sortedPerformances[index]}
                            rowHeight={this.props.rowHeight}
                            headerHeight={this.props.headerHeight}
                            onRowClick={({ rowData }) => selectPerformance({ activeTab, performance: rowData })}
                            onScroll={({ scrollTop }) => saveScrollPosition(scrollTop)}
                            scrollTop={scrollPosition}
                        >
                            {columns.map(({ className, dataKey, ...other }) => (
                                <Column
                                    key={dataKey}
                                    headerRenderer={this.headerRenderer}
                                    className={classNames(classes.flexContainer, className)}
                                    cellRenderer={this.cellRenderer}
                                    storage={storage}
                                    dataKey={dataKey}
                                    openPerformanceDriveDetailsDrawer={openPerformanceDriveDetailsDrawer}
                                    openDeletePerformanceDialog={openDeletePerformanceDialog}
                                    {...other}
                                />
                            ))}
                        </Table>
                    )}
                </AutoSizer>
            </div>
        )
    }
}

export default compose(withRouter, withStyles(styles), withMediaQuery('(max-width:600px)', 80, 56, 80, 56))(DataTable)
