import React from 'react'
import { Paper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from 'components/IconButton'
import Select from 'react-select'
import { PropTypes } from 'prop-types'

const RoleTypeSearch = props => {
    const mapForSelect = data => {
        const mapped = []
        if (!Array.isArray(data)) return []
        data.forEach(item => {
            return mapped.push({ value: item.name, label: item.name })
        })
        return mapped
    }

    const { classes, translate, handleSearchValue, data, selectedItem, clearValue, mapData } = props

    const searchList = data ? mapForSelect(mapData) : []
    const customStyles = {
        option: (provided, state) => ({
            fontFamily: 'Roboto',
            background: '#424242',
            borderBottom: '1px solid grey',
            fontWeight: state.isSelected ? '600' : '8OO',
            color: 'white',
            padding: 20,
            cursor: 'pointer',
        }),
        menu: provided => ({
            ...provided,
            left: 0,
            background: '#424242',
            fontFamily: 'Roboto',
        }),
        input: provided => ({
            ...provided,
            fontFamily: 'Roboto',
            background: '#424242',
            color: '#979797',
        }),
        container: provided => ({
            ...provided,
            paddingLeft: '35px',
            border: 'none',
            background: 'transparent',
            '&:focus': { outline: 'none' },
            zIndex: '99999999999',
        }),
        control: provided => ({
            ...provided,
            background: 'transparent',
            border: 0,
            fontFamily: 'Roboto',
            cursor: 'pointer',
            // This line disable the blue border
            boxShadow: 0,
            '&:hover': {
                border: 0,
            },
        }),
        valueContainer: provided => ({
            ...provided,
            background: '#424242',
            color: '#979797',
            fontFamily: 'Roboto',
        }),
        singleValue: provided => ({
            ...provided,
            color: '#979797',
            fontFamily: 'Roboto',
        }),
    }

    return (
        <Paper className={classes.searchBar}>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <Select
                    styles={customStyles}
                    isSearchable={true}
                    blurInputOnSelect={true}
                    isClearable={true}
                    placeholder={translate('add_user_roles_search')}
                    name="portalUserRoleType"
                    value={selectedItem}
                    options={searchList}
                    onChange={e => {
                        handleSearchValue(e)
                    }}
                    onFocus={() => clearValue()}
                />
            </div>
            <IconButton
                className={classes.button}
                title={translate('icon_button_save_manage_endpoints')}
                disabled={selectedItem === '' || props.isSubmitting}
                onClick={() => props.handleSave()}
            />
        </Paper>
    )
}

RoleTypeSearch.propTypes = {
    classes: PropTypes.object,
    store: PropTypes.object,
    translate: PropTypes.func,
    handleSearchValue: PropTypes.func,
    data: PropTypes.array,
    clearValue: PropTypes.func,
    selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    mapData: PropTypes.array,
    handleSave: PropTypes.func,
    isSubmitting: PropTypes.bool,
}

export default RoleTypeSearch
