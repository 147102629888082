// locale = 'nl-BE' or 'fr-BE', obj = translationObject
const returnTranslationForLocale = (obj, locale = 'nl-BE') => {
    if (obj && obj.length > 0) {
        const translation = obj.find(item => {
            if (item.language === locale) {
                return item.translation
            }
            return false
        })
        if (translation) {
            return translation.translation
        }
        return 'N/A'
    } else {
        return 'Invalid'
    }
}

export default returnTranslationForLocale
