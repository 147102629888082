import React from 'react'
import { array, bool, func, string, number } from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import FilledInput from '@material-ui/core/FilledInput'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

// Components
import AppPaper from 'components/AppPaper'

import sortByName from 'utils/sortByName'
import { dateTimeFromEpochSeconds } from 'utils/dateParser'
// Assets
import styles from './styles'

const StyledSelect = withStyles(styles)(Select)

const getTime = time => {
    if (time) return `( ${dateTimeFromEpochSeconds(time)} )`
    else return ''
}
const SelectInputLocation = ({
    items,
    filled,
    label,
    name,
    onChange,
    value,
    disabled,
    autoWidth = true,
    sorted = false,
    minWidth = 0,
    maxWidth = '100%',
}) => {
    let sortedItems = items

    if (sorted) {
        const [header, ...unsortedItems] = items
        sortedItems = [header, ...unsortedItems.sort((a, b) => sortByName(a.text, b.text))]
    }

    return (
        <AppPaper>
            <FormControl style={{ minWidth: `${minWidth}px`, maxWidth: maxWidth }} variant={filled ? 'filled' : 'outlined'}>
                {!!label && <InputLabel htmlFor={name}>{label}</InputLabel>}

                <StyledSelect
                    autoWidth={autoWidth}
                    input={filled ? <FilledInput id={name} /> : <Input id={name} />}
                    name={name}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    native={false}
                >
                    {sortedItems.map((item, index) => {
                        if (
                            (index === sortedItems.length - 1 &&
                                name === 'locationFrom' &&
                                item.plannedDepartureTime === null) ||
                            item.text === 'Choose location'
                        ) {
                            return null
                        } else {
                            return (
                                <MenuItem key={item.id} value={item.text} data-code={item.code}>
                                    <Typography noWrap={true}>
                                        {name === 'locationFrom'
                                            ? `${item.text} ${getTime(item.plannedDepartureTime)} `
                                            : `${item.text} ${getTime(item.plannedArrivalTime)} `}
                                    </Typography>
                                </MenuItem>
                            )
                        }
                    })}
                </StyledSelect>
            </FormControl>
        </AppPaper>
    )
}

SelectInputLocation.propTypes = {
    items: array.isRequired,
    label: string,
    filled: bool,
    autoWidth: bool,
    sorted: bool,
    name: string.isRequired,
    onChange: func,
    value: string.isRequired,
    disabled: bool,
    minWidth: number,
    maxWidth: string,
}

export default SelectInputLocation
