export default theme => ({
    documentTableWrapper: {
        width: '95%',
        minHeight: theme.driveDetailList,
        height: `calc(80vh / 2)`,
        marginBottom: `${theme.spacing(6)}px`,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableRow: {
        backgroundColor: 'transparent',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '100%',
        borderColor: theme.customColors.grey,
        minHeight: '50px',
        height: 'auto',
    },
    tableTypo: {
        fontSize: '14px',
    },
    header: {
        backgroundColor: theme.customColors.lightGrey,
    },
    noData: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    title: {
        display: 'block',
        width: '95%',
        borderBottom: `solid 2px ${theme.customColors.white}`,
        paddingBottom: `${theme.spacing(1)}px`,
        fontSize: '18px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
})
