import React from 'react'
import PropTypes from 'prop-types'

import EsemesActionBar from './ActionBar'
import EsemesZoneFilter from './ZoneFilter'
import CustomList from './list/listMapper'
import Loading from 'components/Loading'

import { ESemesContext } from '../esemes.store'
import { TranslationContext } from 'context/translation'

import { withStyles } from '@material-ui/core'
import styles from './styles'

const ESemesViewer = ({ classes }) => {
    const { STATE } = React.useContext(ESemesContext)
    const { translate } = React.useContext(TranslationContext)

    return (
        <div>
            <EsemesActionBar />
            {STATE.toggleState.loading ? (
                <Loading />
            ) : STATE.apiState.eSemesData.length > 0 ? (
                <CustomList />
            ) : (
                <p className={classes.error}>{translate('semes_no_messages')}</p>
            )}
            <EsemesZoneFilter />
        </div>
    )
}

ESemesViewer.propTypes = {
    classes: PropTypes.object,
}

export default withStyles(styles)(ESemesViewer)
