import { withStyles } from '@material-ui/styles'
import Header from 'components/Header'
import Loading from 'components/Loading'
import styles from 'modules/UsersAndRoles/styles'
import React, { useEffect, useRef, useState } from 'react'
import { withStatus, withStore } from 'react-ion-store'
import { withLocalize } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import getPortalEndpoints from './Services/getPortalEndpoints'
import getPortalUserRoleTypes from './Services/getPortalUserRoleTypes'
import PropTypes from 'prop-types'
import RoleTypeSearch from './RoleTypeSearch'
import EndpointList from './EndpointList'
import getPortalActions from './Services/getPortalActions'
import updatePortalActions from './Services/updatePortalActions'

const Failed = () => <div>Error fetching data</div>

const ManageEndpoints = props => {
    const isMounted = useRef(false)
    const { translate } = props
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [portalEndpoints, setPortalEndpoints] = useState([])
    const [portalUserRoleTypes, setPortalUserRoleTypes] = useState([])
    const [selectedItem, setSelectedItem] = useState('')
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        isMounted.current = true
        loadData()
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (selectedItem !== '' && selectedItem.value != '') {
            loadSelectedPortalActions()
        }
    }, [selectedItem])

    const loadData = async () => {
        try {
            setHasError(false)
            setShowLoading(true)
            let endpoints = await getPortalEndpoints()
            let roles = await getPortalUserRoleTypes()
            if (isMounted.current) {
                setPortalEndpoints(
                    endpoints.data
                        .map(e => {
                            e.isSelected = false
                            return e
                        })
                        .sort((a, b) => {
                            if (a.endpointGroupName === b.endpointGroupName) {
                                return a.name < b.name ? -1 : 1
                            } else {
                                return a.endpointGroupName < b.endpointGroupName ? -1 : 1
                            }
                        })
                )
                setPortalUserRoleTypes(roles.data.filter(x => x.id !== 142))
                setShowLoading(false)
            }
        } catch (err) {
            if (isMounted.current) {
                setShowLoading(false)
                setHasError(true)
            }
        }
    }

    const loadSelectedPortalActions = async () => {
        try {
            setHasError(false)
            setIsListLoading(true)
            let actionsResponse = await getPortalActions(selectedItem.value)
            let actions = Array.isArray(actionsResponse.data) ? actionsResponse.data : []
            let endpoints = portalEndpoints.map(x => {
                if (actions.find(a => a === x.portalAction) !== undefined) {
                    x.isSelected = true
                } else {
                    x.isSelected = false
                }
                return x
            })
            setPortalEndpoints(endpoints)
            setIsListLoading(false)
        } catch (err) {
            setHasError(true)
        }
    }

    const handleCheckboxChange = endpoint => {
        let endpoints = [...portalEndpoints]
        let originalEndpoint = endpoints.find(x => x.name === endpoint.name)
        originalEndpoint.isSelected = !originalEndpoint.isSelected
        setPortalEndpoints(endpoints)
    }

    const handleSaveEndpointSelection = async () => {
        try {
            setIsSubmitting(true)
            let selectedActions = portalEndpoints.filter(x => x.isSelected).map(x => x.portalAction)
            await updatePortalActions(selectedItem.value, selectedActions)
            setIsSubmitting(false)
        } catch (err) {
            setIsSubmitting(false)
            setHasError(true)
        }
    }

    const handleSearchValue = e => {
        if (e === null) {
            setSearchValue('')
            setSelectedItem('')
        } else {
            setSearchValue(e.value)
            setSelectedItem(e)
        }
    }

    const clearValue = () => {
        setSearchValue('')
    }

    const filterData = data => {
        if (searchValue === '') return data
        return data.filter(item => item.name === searchValue)
    }

    const filteredData = filterData(portalUserRoleTypes)

    return (
        <React.Fragment>
            <Header isMenu title={translate('module_manage_endpoints')} />

            {hasError && !showLoading && <Failed />}
            {!hasError && showLoading && <Loading />}
            {!hasError && !showLoading && (
                <>
                    <RoleTypeSearch
                        handleSearchValue={handleSearchValue}
                        clearValue={clearValue}
                        selectedItem={selectedItem}
                        data={portalUserRoleTypes}
                        mapData={filteredData}
                        handleSave={handleSaveEndpointSelection}
                        isSubmitting={isSubmitting}
                        {...props}
                    />
                    {isListLoading && <Loading />}
                    {!isListLoading && (
                        <EndpointList
                            translate={translate}
                            data={portalEndpoints}
                            handleCheckboxChange={handleCheckboxChange}
                            showCheckboxes={selectedItem !== ''}
                        />
                    )}
                </>
            )}
        </React.Fragment>
    )
}

ManageEndpoints.propTypes = {
    translate: PropTypes.func,
}

export default compose(withRouter, withLocalize, withStyles(styles), withStore, withStatus)(ManageEndpoints)
