import React from 'react'
import { object, func, array } from 'prop-types'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Divider } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'

const DamageList = props => {
    const isRegistered = damage => {
        return !!props.registeredDamages.includes(damage.code)
    }

    return (
        <List>
            {props.allowedDamages.map(damage => (
                <React.Fragment key={damage.code}>
                    <Paper square elevation={0}>
                        <ListItem style={{ paddingLeft: '0px', paddingRight: '0px' }} disableGutters={false}>
                            <Checkbox
                                color="primary"
                                checked={isRegistered(damage)}
                                onChange={() => props.handleCheckbox(damage.code)}
                            />
                            <ListItemText primary={damage.description} />
                        </ListItem>
                    </Paper>

                    <Divider />
                </React.Fragment>
            ))}
        </List>
    )
}
DamageList.propTypes = {
    classes: object,
    handleCheckbox: func,
    allowedDamages: array,
    registeredDamages: array,
}
export default DamageList
