import React, { useContext } from 'react'

import VacmaRestrictionList from './VacmaRestrictionList'
import { withStyles } from '@material-ui/styles'
import styles from './styles'
import { TranslationContext } from 'context/translation'

const VacmaRestrictionView = props => {
    const { translate } = useContext(TranslationContext)
    return <VacmaRestrictionList {...props} translate={translate} />
}

export default withStyles(styles)(VacmaRestrictionView)
