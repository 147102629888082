export default theme => ({
    dateText: {
        marginRight: theme.spacing(1),
    },
    submenu: {
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    divider: {
        marginBottom: theme.spacing(4),
    },
    submenuTitle: {
        marginBottom: theme.spacing(2),
    },
})
