import React from 'react'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import FeatureList from './FeatureList'
import AddNewUserDrawer from './Drawers/AddNewUserDrawer'


const getFeatureName = (featureId, data) => {
    if (featureId) {
        return data.length > 0 ? [data.filter(feature => feature.id === featureId)[0].feature] : ''
    }
}
const MobileFeaturesView = props => {
    const {
        updateStatus,
        featureData,
        drawers,
        translate,
        OrganisationsData,
        openAddUserOrOrganisationDrawer,
        resetFeatureIdAndCloseDrawer,
        addNewUserToFeatureId,
        setFeatureIdAndOpenDrawer,
    } = props
    let featureName = getFeatureName(addNewUserToFeatureId, featureData)

    return (
        <React.Fragment>
            <Header isMenu title={translate('module_mobile_features')} />
            <FeatureList
                updateStatus={updateStatus}
                featureData={featureData}
                {...props}
                drawers={drawers}
                OrganisationsData={OrganisationsData}
                translate={translate}
                setFeatureIdAndOpenDrawer={setFeatureIdAndOpenDrawer}
            />
             
            {openAddUserOrOrganisationDrawer && (
                <AddNewUserDrawer
                    open={openAddUserOrOrganisationDrawer}
                    onClose={resetFeatureIdAndCloseDrawer}
                    addNewUserToFeatureId={addNewUserToFeatureId}
                    featureName={featureName}
                    OrganisationsData={OrganisationsData}
                />
            )}
        </React.Fragment>
    )
}
MobileFeaturesView.propTypes = {
    updateStatus: PropTypes.func,
    featureData: PropTypes.array,
    translate: PropTypes.func,
    drawers: PropTypes.object,
    OrganisationsData: PropTypes.array,
    addNewUserCallLoading: PropTypes.bool,
    handleAddNewUserDrawer: PropTypes.func,
    addNewUserToFeatureId: PropTypes.string,
    resetFeatureIdAndCloseDrawer: PropTypes.func,
    openAddUserOrOrganisationDrawer: PropTypes.bool,
    openFindUserDrawer: PropTypes.bool,
    closeFindUserDrawer: PropTypes.func,
    setFeatureIdAndOpenDrawer: PropTypes.func,
}
export default MobileFeaturesView
