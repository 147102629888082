import React from 'react'
import { string, object, func, number, oneOfType, bool } from 'prop-types'
import { compose } from 'recompose'
import { Translate, withLocalize } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { profilesInErrorText, LoadingProfilesInError } from '../DistrictsMap/labels'

import styles from './styles'
import { useLocation } from 'react-router-dom'

const District = ({
    classes: { districtButton, districtBody, icon, leftAlign, iconWrapper },
    id,
    chooseDistrict,
    numberOfProfilesWithError,
    profilesLoading,
}) => {
    const { pathname } = useLocation()

    const getProfiles = () => {
        if (profilesLoading) return LoadingProfilesInError()
        return profilesInErrorText(numberOfProfilesWithError)
    }
    return (
        <ButtonBase
            className={districtButton}
            onClick={e => {
                chooseDistrict(e, id)
            }}
        >
            <Paper className={districtBody}>
                <>
                    <Typography variant="h5" className={leftAlign}>
                        <Translate id={id} />
                    </Typography>
                    {pathname === '/profiles' ? (
                        <div className={leftAlign}>
                            <span>{getProfiles()}</span>
                        </div>
                    ) : null}
                </>
                <div className={iconWrapper}>
                    <ChevronRight className={icon} />
                </div>
                <Typography color="primary" className={leftAlign}>
                    <strong>
                        <Translate id="districts_goto_district" />
                    </strong>
                </Typography>
            </Paper>
        </ButtonBase>
    )
}

District.propTypes = {
    classes: object.isRequired,
    id: string.isRequired,
    chooseDistrict: func.isRequired,
    numberOfProfilesWithError: oneOfType([number, string, bool]),
    profilesLoading: bool.isRequired,
}

export default compose(withLocalize, withStyles(styles))(District)
