import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from '../../../../components/Header'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

export class AddNewTaskView extends Component {
    static propTypes = {
        performanceDetail: PropTypes.object,
        children: PropTypes.any,
        classes: PropTypes.object,
        translate: PropTypes.func,
    }

    render() {
        const { performanceDetail, children, classes, translate } = this.props

        return (
            <React.Fragment>
                {!performanceDetail && <Header title={translate('PerformanceAddTask')} />}
                <div className={classes.generalContainer}>{children}</div>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(AddNewTaskView)
