import { DateTime } from 'luxon'
import DefaultSettings from 'components/DefaultSettings'
const settings = JSON.parse(localStorage.getItem('userSettings')) || DefaultSettings
const eSemesSettings = settings.filter(el => el.pageReference === 'esemesOverview')[0]

const initialApiState = {
    eSemesData: [],
    pubuIds: [],
    district: '',
    districtId: '',
}

const initialToggleState = {
    loading: true,
    groupPerZone: false,
}

const initialTimeState = {
    timeMargins: { lower: 1, upper: 1 },
    apiDate: DateTime.local(),
    syncTime: { latestSuccessfullSyncTime: 0, nextSyncTime: 0 },
    refreshInterval:
        eSemesSettings !== undefined
            ? eSemesSettings.refreshRate
            : DefaultSettings.filter(el => el.pageReference === 'esemesOverview')[0].refreshRate,
    realTime: true,
    initialTime: DateTime.local(),
    allowRefresh: true,
}

const initialFilterState = {
    filterdResults: [],
    activeIds: [],
    zoneToggler: [],
    dataFilter: '',
    sort: {
        dir: 'asc',
        key: 'line',
    },
    hasTaskApplicable: true,
    district: ['Noord-West'],
}

const apiActions = {
    semes: 'semes',
    pubu: 'pubu',
    district: 'district',
    districtId: 'districtId',
}

const apiReducer = (state, action) => {
    switch (action.type) {
        case apiActions.semes:
            return { ...state, eSemesData: action.payload }
        case apiActions.pubu:
            return { ...state, pubuIds: action.payload }
        case apiActions.district:
            return { ...state, district: action.payload }
        case apiActions.districtId:
            return { ...state, districtId: action.payload }
        default:
            return state
    }
}

const toggleActions = {
    loading: 'loading',
    groupPerZone: 'groupPerZone',
}

const toggleReducer = (state, action) => {
    switch (action.type) {
        case toggleActions.loading:
            return { ...state, loading: action.payload }
        case toggleActions.groupPerZone:
            return { ...state, groupPerZone: action.payload }
        default:
            return state
    }
}

const timeActions = {
    timeMargins: 'timeMargins',
    apiDate: 'apiDate',
    syncTime: 'syncTime',
    refreshInterval: 'refreshInterval',
    realTime: 'realTime',
    allowRefresh: 'allowRefresh',
}

const timeReducer = (state, action) => {
    switch (action.type) {
        case timeActions.timeMargins:
            return { ...state, timeMargins: action.payload }
        case timeActions.apiDate:
            return { ...state, apiDate: action.payload }
        case timeActions.syncTime:
            return { ...state, syncTime: action.payload }
        case timeActions.refreshInterval:
            return { ...state, refreshInterval: action.payload }
        case timeActions.realTime:
            return { ...state, realTime: action.payload }
        case timeActions.allowRefresh:
            return { ...state, allowRefresh: action.payload }
        default:
            return state
    }
}

const filterActions = {
    filterdResults: 'filterdResults',
    activeIds: 'activeIds',
    zoneToggler: 'zoneToggler',
    dataFilter: 'dataFilter',
    changeActiveZones: 'changeActiveZones',
    setFilterdResults: 'setFilterdResults',
    sort: 'sort',
    setHasTaskApplicable: 'setHasTaskApplicable',
    district: 'changeActiveDistrict',
}

const filterReducer = (state, action) => {
    switch (action.type) {
        case filterActions.filterdResults:
            return { ...state, filterdResults: action.payload }
        case filterActions.activeIds:
            return { ...state, activeIds: action.payload }
        case filterActions.zoneToggler:
            return { ...state, zoneToggler: action.payload }
        case filterActions.dataFilter:
            return { ...state, dataFilter: action.payload }
        case filterActions.changeActiveZones:
            return {
                ...state,
                activeIds: action.payload.activeIds,
                zoneToggler: action.payload.zoneToggler,
            }
        case filterActions.setFilterdResults:
            return {
                ...state,
                dataFilter: action.payload.dataFilter,
                filterdResults: action.payload.filterdResults,
            }
        case filterActions.sort:
            return {
                ...state,
                sort: {
                    dir: action.payload.dir,
                    key: action.payload.key,
                },
            }
        case filterActions.setHasTaskApplicable:
            return {
                ...state,
                hasTaskApplicable: action.payload.hasTaskApplicable,
            }
        case filterActions.district:
            return {
                ...state,
                district: action.payload.district,
            }
        default:
            return state
    }
}

export const REDUCER_STATES = {
    initialApiState,
    initialToggleState,
    initialTimeState,
    initialFilterState,
}

export const REDUCER_ACTIONS = {
    apiActions,
    toggleActions,
    timeActions,
    filterActions,
}

export const REDUCER_REDUCERS = {
    apiReducer,
    toggleReducer,
    timeReducer,
    filterReducer,
}
