import React, { Component } from 'react'
import { Paper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from 'components/IconButton'
import Select from 'react-select'
import { PropTypes } from 'prop-types'
import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'

export default class UserSearchView extends Component {
    static propTypes = {
        classes: PropTypes.object,
        store: PropTypes.object,
        translate: PropTypes.func,
        handleSearchValue: PropTypes.func,
        data: PropTypes.array,
        clearValue: PropTypes.func,
        selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        mapData: PropTypes.array,
        handleAddNewUserDrawer: PropTypes.func,
        handleFindUser: PropTypes.func,
        handleRedirectToManageEndpoints: PropTypes.func,
    }

    mapForSelect = data => {
        const mapped = []
        if (!Array.isArray(data)) return []
        data.forEach(item => {
            return mapped.push({ value: item.id, label: `${item.name} ` })
        })
        return mapped
    }

    render() {
        const {
            classes,
            translate,
            handleSearchValue,
            data,
            selectedItem,
            clearValue,
            mapData,
            handleAddNewUserDrawer,
            handleFindUser,
            handleRedirectToManageEndpoints,
        } = this.props
        const searchList = data ? this.mapForSelect(mapData) : []
        const customStyles = {
            option: (provided, state) => ({
                fontFamily: 'Roboto',
                background: '#424242',
                borderBottom: '1px solid grey',
                fontWeight: state.isSelected ? '600' : '8OO',
                color: 'white',
                padding: 20,
            }),
            menu: provided => ({
                ...provided,
                left: 0,
                background: '#424242',
                fontFamily: 'Roboto',
            }),
            input: provided => ({
                ...provided,
                fontFamily: 'Roboto',
                background: '#424242',
                color: '#979797',
            }),
            container: provided => ({
                ...provided,
                paddingLeft: '35px',
                border: 'none',
                background: 'transparent',
                '&:focus': { outline: 'none' },
            }),
            control: provided => ({
                ...provided,
                background: 'transparent',
                border: 0,
                fontFamily: 'Roboto',
                // This line disable the blue border
                boxShadow: 0,
                '&:hover': {
                    border: 0,
                },
            }),
            valueContainer: provided => ({
                ...provided,
                background: '#424242',
                color: '#979797',
                fontFamily: 'Roboto',
            }),
            singleValue: provided => ({
                ...provided,
                color: '#979797',
                fontFamily: 'Roboto',
            }),
        }

        return (
            <Paper className={classes.searchBar}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <Select
                        styles={customStyles}
                        isSearchable={true}
                        blurInputOnSelect={true}
                        isClearable={true}
                        placeholder={translate('add_user_roles_search')}
                        name="portalUser"
                        value={typeof selectedItem === 'object' ? selectedItem.code : selectedItem}
                        options={searchList}
                        onChange={e => {
                            handleSearchValue(e)
                        }}
                        onFocus={() => clearValue()}
                    />
                </div>
                <IconButton
                    className={classes.button}
                    icon="person_add"
                    title={translate('icon_button_find_userGroup')}
                    onClick={() => handleFindUser(true)}
                    disabled={checkFunctionalityDisabled(this.props.store, 'add', 'users.functionality.management.users')}
                />
                <IconButton
                    className={classes.button}
                    icon="person_add"
                    title={translate('icon_button_add_user')}
                    onClick={() => handleAddNewUserDrawer(true)}
                    disabled={checkFunctionalityDisabled(this.props.store, 'add', 'users.functionality.management.users')}
                />
                <IconButton
                    className={classes.button}
                    icon="edit"
                    title={translate('icon_button_manage_endpoints')}
                    onClick={() => handleRedirectToManageEndpoints()}
                    disabled={checkFunctionalityDisabled(this.props.store, 'edit', 'users.functionality.management.users')}
                />
            </Paper>
        )
    }
}
