import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, Column, Table, defaultTableRowRenderer } from 'react-virtualized'

export class VacmaRestrictionTable extends Component {
    static defaultProps = {
        headerHeight: 56,
        rowHeight: 56,
    }
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.object),
        columns: PropTypes.array,
        classes: PropTypes.object,
        headerHeight: PropTypes.number,
        rowHeight: PropTypes.number,
        headerRenderer: PropTypes.func,
        cellRenderer: PropTypes.func,
        getRowClassName: PropTypes.func,
    }

    render() {
        const { data, columns, classes, rowHeight, headerHeight, headerRenderer, cellRenderer, getRowClassName } = this.props
        return (
            <div className={classes.documentTableWrapper}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            className={classes.table}
                            height={height}
                            width={width}
                            rowCount={data.length}
                            rowClassName={getRowClassName}
                            rowRenderer={defaultTableRowRenderer}
                            rowGetter={({ index }) => data[index]}
                            rowHeight={rowHeight}
                            headerHeight={headerHeight}
                        >
                            {columns.map(({ dataKey, ...other }) => (
                                <Column
                                    key={dataKey}
                                    headerRenderer={headerRenderer}
                                    className={classes.flexContainer}
                                    cellRenderer={cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            ))}
                        </Table>
                    )}
                </AutoSizer>
            </div>
        )
    }
}

export default VacmaRestrictionTable
