import axios from 'axios'

export default async () => {
    const fullUrl = `/compositionReferenceData/materialSubTypes`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status === 204) {
            return []
        }
        return response.data
    } catch (e) {
        console.error(e) // eslint-disable-line
        return []
    }
}
