import React from 'react'
import { object, func, array } from 'prop-types'
import { compose } from 'recompose'
import { withStore, withStatus } from 'react-ion-store'

import ProfileDetailView from './ProfileDetailView'

class ProfileDetail extends React.Component {
    render() {
        const { viewData, store } = this.props

        const sortParams = this.props.store.get('params') || undefined

        let params = { sort: 'name', sortDirection: 'asc', filter: 'All' }

        if (sortParams) {
            params = { ...params, sort: sortParams.sort, sortDirection: sortParams.sortDirection }
        }

        return <ProfileDetailView storePar={store} params={params} viewData={viewData} />
    }
}

ProfileDetail.propTypes = {
    clearDetailView: func.isRequired,
    viewData: array.isRequired,
    store: object.isRequired,
    __ion_status: object.isRequired,
}

export default compose(withStore, withStatus)(ProfileDetail)
