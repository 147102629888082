import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { withLocalize, Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withStore, util, withStatus, withClear } from 'react-ion-store'

import checkWriteAccess from 'utils/checkWriteAccess'
import AddNewTaskView from './AddNewTaskView'
import FirstStep from './components/FirstStep'
import SecondStep from './components/SecondStep'
import LastStep from './components/LastStep'
import ThirdStep from './components/ThirdStep'
import DistrictsMapperNoFilter from '../../../../components/DistrictsMapperNoFilter'
import NewTaskFooter from './components/NewTaskFooter'
import Loading from 'components/Loading'

import getTrainLocations from '../../Services/getTrainLocations'
import getAllLocations from '../../Services/getAllLocations'
import getPerformanceNumbers from './services/getPerformanceNumbersWithDate'
import getLindaRoles from '../../Services/getLindaRoles'

import groups from '../../config/groups'
import taskTypes from '../../config/taskTypes'
import AddTaskCall from '../../Services/addTaskCall'
import NoRights from '../../../NoRights'
import { DateTime } from 'luxon'
import { toDateTimeFromSeconds } from 'utils/dateParser'
const storeKey = 'mappedDistrictDataNoFilter'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}
const initialState = {
    validateForm: 1,
    loading: false,
    loadingPerformance: false,
    loadingReferencePerformance: false,
    actionWasSet: false,
    disabled: true,
    trainLocations: [],
    trainPrefix: ' ',
    trainNumber: '',
    location: ' ',
    locationPtcarId: '',
    locationFrom: ' ',
    locationFromPtcarId: '',
    locationTo: ' ',
    locationToPtcarId: '',
    remarkInput: '',
    diagramNumber: '',
    startTime: null,
    endTime: null,
    locationToSelected: '',
    locationFromSelected: '',
    locationToSearchValue: '',
    locationFromSearchValue: '',
    locationSelected: '',
    locationSearchValue: '',
    trainLocationsTo: [],
    trainLocationsFrom: [],
    timePickerDisable: false,
    ptcarUicTo: '',
    ptcarUicFrom: '',
    noTrainData: false,
    chooseFromOtherPerformance: false,
    depotPt: ' ',
    orgPt: ' ',
    depotNamePt: '',
    selectedPerformanceDetails: null,
    selectedPerformanceDetailsLoading: false,
    checkedTasks: [],
    addMultipleTasksLoading: false,
    addMultipleTasksError: '',
    checkAll: false,
    driveRole: '',
    lindaRoles: [],
    radio: 'Actual',
    selectedPerformanceForJsonLoading: false,
    selectedPerformanceForJson: [],
    selectedJsonPerformance: '',
    locationListLoading: false,
}

export class AddNewTask extends Component {
    static propTypes = {
        translate: PropTypes.func,
        performanceDetail: PropTypes.object,
        store: PropTypes.object,
        __ion_status: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.object,
        activeLanguage: PropTypes.object,
        clearAddTaskCall: PropTypes.func,
        checkedTasks: PropTypes.array,
    }

    constructor(props) {
        super(props)
        this.state = {
            validateForm: 1,
            loading: false,
            loadingPerformance: false,
            loadingReferencePerformance: false,
            actionWasSet: false,
            disabled: true,
            showStepThree: false,
            showStepThreeWithBus: false,
            trainLocations: [],
            org: ' ',
            referenceOrg: ' ',
            depot: ' ',
            referenceDepot: ' ',
            taskType: ' ',
            trainPrefix: ' ',
            trainNumber: '',
            location: ' ',
            locationPtcarId: '',
            locationFrom: ' ',
            locationFromPtcarId: '',
            locationTo: ' ',
            locationToPtcarId: '',
            remarkInput: '',
            diagramNumber: '',
            selectedPerformanceNumber: ' ',
            selectedPerformanceNumberPt: ' ',
            performanceNumbersData: [],
            startTime: '',
            endTime: '',
            performanceId: props.performanceDetail ? props.performanceDetail.performanceId : null,
            idfNumber: props.performanceDetail ? props.performanceDetail.idfNumber || null : null,
            locationToSelected: '',
            locationFromSelected: '',
            locationToSearchValue: '',
            locationFromSearchValue: '',
            locationSelected: '',
            locationSearchValue: '',
            referencePerformanceNumbersData: [],
            selectedReferencePerformanceNumber: ' ',
            selectedDate: props.performanceDetail ? props.performanceDetail.performanceDate : DateTime.local(),
            selectedDatePt: props.performanceDetail
                ? DateTime.fromMillis(props.performanceDetail.performanceDate * 1000)
                : DateTime.local(),
            trainLocationsTo: [],
            trainLocationsFrom: [],
            timePickerDisable: false,
            ptcarUicTo: '',
            ptcarUicFrom: '',
            noTrainData: false,
            isTablet: window.matchMedia('(max-width:1025px)').matches,
            chooseFromOtherPerformance: false,
            depotPt: ' ',
            orgPt: ' ',
            depotNamePt: '',
            selectedPerformanceDetails: null,
            selectedPerformanceDetailsLoading: false,
            checkedTasks: [],
            addMultipleTasksLoading: false,
            addMultipleTasksError: '',
            checkAll: false,
            driveRole: '',
            lindaRoles: [],
            radio: 'Original',
            selectedPerformanceForJsonLoading: false,
            selectedPerformanceForJson: [],
            selectedJsonPerformance: '',
            locationListLoading: false,
        }
    }

    chooseFromOtherPerformanceClick = () => {
        this.setState({ chooseFromOtherPerformance: true, taskType: ' ' })
    }

    handleResize() {
        this.setState({ isTablet: window.matchMedia('(max-width:1025px)').matches })
    }

    getSelectedPerformanceDetails = (id, date) => {
        this.setState({ selectedPerformanceDetailsLoading: true })
        this.doGetSelectedPerformanceDetailsCall(id, date)
    }

    loadPerformanceForList = (selectedDate, depot, performanceNumber, documentType) => {
        this.setState({ selectedPerformanceForJsonLoading: true })
        this.doGetSelectedPerformanceForJson(selectedDate, depot, performanceNumber, documentType)
    }

    resetCheckAllAndErrors = () => {
        this.setState({
            checkedTasks: [],
            addMultipleTasksError: '',
            checkAll: false,
            selectedPerformanceForJsonLoading: false,
            selectedPerformanceForJson: [],
            selectedJsonPerformance: '',
        })
    }

    doGetSelectedPerformanceForJson = async (selectedDate, depot, performanceNumber, documentType) => {
        const { url, subscriptionKey } = requestData

        let date

        if (typeof selectedDate === 'number') {
            date = DateTime.fromSeconds(selectedDate).toFormat('ddMMyyyy')
        } else {
            date = selectedDate.toFormat('ddMMyyyy')
        }
        const selectedDepot = depot.substring(depot.indexOf('-') + 1, depot.length)
        const perfNumber = performanceNumber.substring(
            performanceNumber.indexOf('-') + 1,
            performanceNumber.indexOf('-') + 5
        )

        const fullUrl = `${url}/performance/ByParamsInJson/${perfNumber}/${selectedDepot}/${date}/${documentType}`

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.setState({ selectedPerformanceForJsonLoading: false, selectedPerformanceForJson: [] })
            }
            if (response.status === 200) {
                this.setState(
                    { selectedPerformanceForJsonLoading: false, selectedPerformanceForJson: response.data },
                    () => {
                        if (response.data.length === 1) this.setSelectedJsonPerformance(0)
                    }
                )
            }
        } catch (e) {
            this.setState({ selectedPerformanceForJsonLoading: false, selectedPerformanceForJson: [] })
            console.error(e) // eslint-disable-line
        }
    }

    doGetSelectedPerformanceDetailsCall = async (id, idf) => {
        const { url, subscriptionKey } = requestData

        let fullUrl = `${url}/performance/${id}/detail`

        if (idf !== null) {
            fullUrl = `${url}/performance/${id}/detail?idfNumber=${idf}`
        }

        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                this.setState({ selectedPerformanceDetailsLoading: false, selectedPerformanceDetails: null })
            }
            if (response.status === 200) {
                this.setState({ selectedPerformanceDetailsLoading: false, selectedPerformanceDetails: response.data })
            }
        } catch (e) {
            this.setState({ selectedPerformanceDetailsLoading: false, selectedPerformanceDetails: null })
            console.error(e) // eslint-disable-line
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.handleResize())

        const { performanceDetail } = this.props
        const { store } = this.props
        const districts = store.get(storeKey) || []
        this.loadLocations()

        if (performanceDetail !== undefined && Object.keys(performanceDetail).length > 0) {
            this.setState({
                initialDepot: performanceDetail.depot,
                initialTct: performanceDetail.tctId,
                initialIdf: performanceDetail.idfNumber,
                districts,
            })
        }
    }

    componentDidUpdate = () => {
        const { __ion_status, history, location } = this.props

        if (__ion_status.addTaskCall !== undefined && __ion_status.addTaskCall === 3 && this.state.actionWasSet !== false) {
            if (history.location.state.prevPath !== undefined && history.location.state.prevPath !== '/manageperformance') {
                history.push(history.location.state.prevPath, { prevPath: location.pathname })
                window.location.reload()
            } else {
                history.goBack()
            }
        }
    }

    componentWillUnmount = () => {
        this.props.clearAddTaskCall()
    }

    handleDateChange = e => {
        this.setState({ selectedDate: e, performanceNumbersData: [], depot: ' ', org: ' ', taskType: ' ' })
    }

    handleDateChangePt = e => {
        this.setState({ selectedDatePt: e, performanceNumbersData: [], depotPt: ' ', orgPt: ' ', taskType: ' ' })
    }

    setFormValidation = value => {
        this.setState({ validateForm: value })
    }

    addTask = async () => {
        const {
            org,
            depot,
            taskType,
            trainPrefix,
            trainNumber,
            location,
            locationPtcarId,
            locationFrom,
            locationFromPtcarId,
            locationTo,
            locationToPtcarId,
            remarkInput,
            diagramNumber,
            startTime,
            endTime,
            performanceId,
            idfNumber,
            selectedPerformanceNumber,
            selectedReferencePerformanceNumber,
            ptcarUicTo,
            ptcarUicFrom,
            checkedTasks,
            chooseFromOtherPerformance,
            driveRole,
        } = this.state

        if (checkedTasks && checkedTasks.length > 0 && chooseFromOtherPerformance) {
            await this.addMultipleTasks()
            return
        }
        this.setState({ actionWasSet: 'addTaskCall' }, () => {
            if (
                taskType === 'FREETEXT_P' ||
                taskType === 'FREETEXT_M' ||
                taskType === 'OPMRMQ' ||
                taskType === 'OPMREM' ||
                taskType === 'AFREL' ||
                taskType === 'AFRELDP' ||
                taskType === 'AFLOSDP'
            ) {
                const addTaskCall = util.CreateStoreObject({
                    remarkInput,
                    org,
                    depot,
                    taskType,
                    trainPrefix,
                    trainNumber,
                    location,
                    locationPtcarId,
                    locationFrom,
                    locationFromPtcarId,
                    locationTo,
                    locationToPtcarId,
                    diagramNumber,
                    performanceId,
                    idfNumber,
                    selectedPerformanceNumber,
                    selectedReferencePerformanceNumber,
                    startTime: startTime !== '' ? new DateTime(startTime).toFormat('HH:mm') : '',
                    endTime: startTime !== '' ? new DateTime(startTime).toFormat('HH:mm') : '',
                    ptcarUicTo,
                    ptcarUicFrom,
                    LindaRole: driveRole,
                    type: 'addTask',
                })
                this.props.store.call({ addTaskCall })
            } else {
                const addTaskCall = util.CreateStoreObject({
                    remarkInput,
                    org,
                    depot,
                    taskType,
                    trainPrefix,
                    trainNumber,
                    location,
                    locationPtcarId,
                    locationFrom,
                    locationFromPtcarId,
                    locationTo,
                    locationToPtcarId,
                    diagramNumber,
                    performanceId,
                    idfNumber,
                    selectedPerformanceNumber,
                    selectedReferencePerformanceNumber,
                    startTime: startTime !== '' && startTime !== null ? new DateTime(startTime).toFormat('HH:mm') : '',
                    endTime: endTime !== '' && endTime !== null ? new DateTime(endTime).toFormat('HH:mm') : '',
                    ptcarUicTo,
                    ptcarUicFrom,
                    LindaRole: driveRole,
                    type: 'addTask',
                })
                this.props.store.call({ addTaskCall })
            }
        })
    }

    addMultipleTasks = async () => {
        const { checkedTasks, selectedPerformanceDetails, performanceId, idfNumber } = this.state

        this.setState({ addMultipleTasksLoading: true })
        let tasksArray = []
        for (const item of checkedTasks) {
            const taskToAdd = selectedPerformanceDetails.tasks.find(task => task.id === item)
            if (taskToAdd) {
                tasksArray.push({
                    TaskId: taskToAdd.id,
                    Code: taskToAdd.code,
                })
            }
        }

        await this.doCallForAddMultipleTask(
            tasksArray,
            performanceId,
            idfNumber,
            selectedPerformanceDetails.performanceId,
            selectedPerformanceDetails.idfNumber
        )
    }

    doCallForAddMultipleTask = async (tasksArray, currentPerformanceId, idf, fromPerformanceId, sourceIdf) => {
        const { url, subscriptionKey } = requestData
        const { translate } = this.props

        let fullUrl = `${url}/performance/${currentPerformanceId}/task/copy`

        try {
            const response = await axios.post(fullUrl, {
                PerformanceId: fromPerformanceId,
                IdfNumber: idf,
                SourceIdfNumber: sourceIdf,
                Tasks: tasksArray,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            if (response.status === 200) {
                this.setState(
                    {
                        addMultipleTasksLoading: false,
                        selectedPerformanceDetailsLoading: false,
                        checkedTasks: [],
                        addMultipleTasksError: '',
                    },
                    () => {
                        this.props.store.call('performanceDetail')
                        this.props.history.goBack()
                    }
                )
                return
            }
        } catch (e) {
            console.error(e) // eslint-disable-line
            if (e.response && e.response.status && e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({
                        addMultipleTasksLoading: false,
                        addMultipleTasksError: translate('roles_error'),
                    })
                    return
                }

                this.setState({
                    addMultipleTasksLoading: false,
                    addMultipleTasksError: e.response.data.Message,
                })
                return
            } else {
                this.setState({
                    addMultipleTasksLoading: false,
                    addMultipleTasksError: 'Something went wrong',
                })
                return
            }
        }
    }

    checkStepOne = () => {
        const { org, depot, performanceId, selectedPerformanceNumber } = this.state

        if (org !== ' ' && depot !== ' ' && selectedPerformanceNumber !== ' ') {
            return true
        }

        if (performanceId !== null) {
            return true
        }

        return false
    }

    checkStepTwo = () => {
        const { taskType } = this.state

        if (taskType !== ' ') {
            return true
        }
        return false
    }

    checkStepThree = () => {
        const { trainNumber, showStepThree, taskType, trainLocations } = this.state

        if (showStepThree === false) return true

        if (
            (taskType.toUpperCase() === 'DRIVE' || taskType.toUpperCase() === 'HLP') &&
            trainNumber !== ' ' &&
            trainNumber !== '' &&
            trainNumber !== undefined &&
            trainLocations.length !== 0
        ) {
            return true
        }
        if (
            taskType.toUpperCase() !== 'DRIVE' &&
            taskType.toUpperCase() !== 'HLP' &&
            trainNumber !== ' ' &&
            trainNumber !== '' &&
            trainNumber !== undefined
        ) {
            return true
        }
        return false
    }

    checkComplete = () => {
        const { validateForm } = this.state
        // if validateForm = 0  it means that all required fields are filled in
        if (this.checkStepOne() && this.checkStepTwo() && this.checkStepThree() && validateForm === 0) {
            return false
        }
        return true
    }

    trainNumberNeeded = taskType => {
        if (taskType && taskType !== ' ') {
            const group = taskTypes.find(type => type.id === taskType)
            const groupDetail = groups.find(item => item.group === group.group)
            if (groupDetail.trainNumber) return true
        }

        return false
    }
    busNumberNeeded = taskType => {
        if (taskType && taskType !== ' ') {
            const group = taskTypes.find(type => type.id === taskType)
            const groupDetail = groups.find(item => item.group === group.group)
            if (groupDetail.busNumber) {
                this.setState({ loading: true })
                return true
            }
        }

        return false
    }

    reduceTrainData = data => {
        const { activeLanguage } = this.props
        const defaultValue = { id: ' ', text: 'Choose location' }

        if (data === undefined || data.length === 0) return []
        if (activeLanguage.code === 'nl') {
            const forSelect = data.locations
                .filter(
                    (obj, index) => (index === 0 && !obj.passThrough) || index === data.locations.length || !obj.passThrough
                )
                .map(({ ptcarId, shortName, plannedArrivalTime, plannedDepartureTime, ptcarUic, passTroughTime }) => ({
                    id: ptcarId,
                    text: shortName && shortName[1] !== undefined ? shortName[1].translation : '',
                    plannedArrivalTime,
                    plannedDepartureTime,
                    ptcarUic,
                    passTroughTime: passTroughTime ? this.getSeconds(passTroughTime) : '',
                }))

            return [defaultValue, ...forSelect]
        }
        if (activeLanguage.code === 'fr') {
            const forSelect = data.locations
                .filter(
                    (obj, index) => (index === 0 && !obj.passThrough) || index === data.locations.length || !obj.passThrough
                )
                .map(({ ptcarId, shortName, plannedArrivalTime, plannedDepartureTime, ptcarUic, passTroughTime }) => ({
                    id: ptcarId,
                    text: shortName && shortName[0] !== undefined ? shortName[0].translation : '',
                    plannedArrivalTime,
                    plannedDepartureTime,
                    ptcarUic,
                    passTroughTime: passTroughTime ? this.getSeconds(passTroughTime) : '',
                }))

            return [defaultValue, ...forSelect]
        }
    }

    getLabelName = name => {
        const { activeLanguage } = this.props

        let fr = name && name[0] !== undefined ? name[0].translation : ''
        let nl = name && name[1] !== undefined ? name[1].translation : ''

        if (activeLanguage === undefined) return nl
        if (activeLanguage.code === 'nl') return nl
        return fr
    }

    getSearchLabel = name => {
        let fr = name && name[0] !== undefined ? name[0].translation : ''
        let nl = name && name[1] !== undefined ? name[1].translation : ''

        return `${nl} / ${fr}`
    }

    reduceLocations = data => {
        const defaultValue = { value: '', searchValue: '', label: 'Choose location' }

        if (data === undefined || data.length === 0) return []
        const forSelect = data.map(({ id, name, code, uic }) => ({
            value: id,
            label: this.getLabelName(name),
            searchValue: this.getSearchLabel(name),
            code: code,
            uic,
        }))

        return [defaultValue, ...forSelect]
    }

    getLocationToArray(reducedData) {
        let copiedForToArray = [...reducedData]
        copiedForToArray.splice(1, 1)

        return copiedForToArray
    }
    getLocationFromArray(reducedData) {
        let copiedForFromArray = [...reducedData]
        if (copiedForFromArray.length > 2) {
            copiedForFromArray.pop()
        }

        return copiedForFromArray
    }

    loadTrain = async () => {
        this.setState({ loading: true, noTrainData: false })
        const { trainPrefix, trainNumber, taskType, selectedDate } = this.state

        let start = this.props.performanceDetail.plannedStartTime
        let end = this.props.performanceDetail.plannedEndTime

        let data = []
        let reducedData = []

        if (
            (trainPrefix !== ' ' && trainNumber !== '') ||
            ((taskType === 'DRIVE' || taskType === 'HLP') && trainNumber !== '')
        ) {
            data = await getTrainLocations(trainNumber, trainPrefix, selectedDate, start, end)
            if (data.length === 0) {
                this.setState({ noTrainData: true, loading: false })
                return
            }
            reducedData = this.reduceTrainData(data)
        }
        let timePickerDisable = false
        if (taskType === 'DRIVE' || taskType === 'HLP') {
            timePickerDisable = true
        }

        this.setState({
            trainLocations: reducedData,
            trainLocationsTo: this.getLocationToArray(reducedData),
            trainLocationsFrom: this.getLocationFromArray(reducedData),
            loading: false,
            startTime: reducedData.length > 0 ? toDateTimeFromSeconds(reducedData[1]?.plannedDepartureTime) : '',
            endTime:
                reducedData.length > 0 ? toDateTimeFromSeconds(reducedData[reducedData.length - 1]?.plannedArrivalTime) : '',
            locationFrom: reducedData.length > 0 ? reducedData[1]?.text : '',
            locationTo: reducedData.length > 0 ? reducedData[reducedData.length - 1]?.text : '',
            timePickerDisable: timePickerDisable,
            ptcarUicTo: reducedData.length > 0 ? reducedData[reducedData.length - 1]?.ptcarUic : '',
            ptcarUicFrom: reducedData.length > 0 ? reducedData[1]?.ptcarUic : '',
            locationToPtcarId: reducedData.length > 0 ? reducedData[reducedData.length - 1]?.id : '',
            locationFromPtcarId: reducedData.length > 0 ? reducedData[1]?.id : '',
        })
    }

    loadLocations = async () => {
        this.setState({ locationListLoading: true })
        let data = []

        data = await getAllLocations()
        const reducedData = this.reduceLocations(data)

        this.setState({ allLocations: reducedData, locationListLoading: false })
    }

    loadPerformanceNumbers = async depot => {
        this.setState({ loadingPerformance: true })

        let data = []
        if (depot !== '') {
            data = await getPerformanceNumbers(depot, this.state.selectedDate)
        }

        this.setState({ performanceNumbersData: data, loadingPerformance: false, depotSymbolic: depot })
    }

    loadPerformanceNumbersPt = async depot => {
        this.setState({ loadingPerformance: true })

        let data = []
        if (depot !== '') {
            data = await getPerformanceNumbers(depot, this.state.selectedDatePt)
        }

        this.setState({ performanceNumbersData: data, loadingPerformance: false, depotSymbolic: depot })
    }

    loadReferencePerformanceNumbers = async depot => {
        this.setState({ loadingReferencePerformance: true })

        let data = []

        if (depot !== '') {
            data = await getPerformanceNumbers(depot, this.state.selectedDate)
        }

        this.setState({ referencePerformanceNumbersData: data, loadingReferencePerformance: false })
    }

    handleCheckTasks = id => {
        const { checkedTasks } = this.state

        if (checkedTasks.indexOf(id) > -1) {
            const removeFromList = checkedTasks.filter(item => item !== id)
            this.setState({ checkedTasks: removeFromList })
            return
        }

        const list = [...checkedTasks, id]

        this.setState({ checkedTasks: list })
    }
    handleCheckAll = checkAll => {
        const { selectedPerformanceDetails } = this.state
        const tasks = selectedPerformanceDetails.tasks
        let checkedArray = []

        if (checkAll) {
            this.setState({ checkedTasks: [], checkAll: false })
        } else {
            tasks.forEach(item => {
                if (item.code.toUpperCase() === 'START') return
                if (item.code.toUpperCase() === 'END') return
                checkedArray.push(item.id)
            })

            this.setState({ checkedTasks: checkedArray, checkAll: true })
        }
    }

    loadLindaRoles = async taskType => {
        let data = await getLindaRoles()

        if (data.length) {
            let defaultLindaRole = null
            if (
                taskType.toUpperCase() === 'DRIVE' ||
                taskType.toUpperCase() === 'UITGAR' ||
                taskType.toUpperCase() === 'PERQUAI'
            )
                defaultLindaRole = data.filter(role => role.lindaRoleCode === 'Tdlm')[0].id
            else if (taskType.toUpperCase() === 'HLP')
                defaultLindaRole = data.filter(role => role.lindaRoleCode === 'Tdsh')[0].id

            const { activeLanguage } = this.props
            if (activeLanguage.code === 'nl') {
                const forSelect = data.map(({ id, description }) => ({
                    id: id,
                    text: description && description[1] !== undefined ? description[1].translation : '',
                }))

                this.setState({ lindaRoles: forSelect, driveRole: defaultLindaRole })
            }
            if (activeLanguage.code === 'fr') {
                const forSelect = data.map(({ id, description }) => ({
                    id: id,
                    text: description && description[0] !== undefined ? description[0].translation : '',
                }))

                this.setState({ lindaRoles: forSelect, driveRole: defaultLindaRole })
            }
        }
    }
    persist = type => props => {
        const toSetState = { [type]: props }
        const { showStepThreeWithBus } = this.state
        let showBus = showStepThreeWithBus
        if (type !== 'taskType') {
            if (type === 'org') toSetState.depot = ' '
            if (type === 'depot') toSetState.selectedPerformanceNumber = ' '
            if (type === 'referenceOrg') toSetState.referenceDepot = ' '
            if (type === 'referenceDepot') toSetState.selectedReferencePerformanceNumber = ' '
            if (type === 'locationFrom') {
                const { trainLocations } = this.state
                const index = trainLocations.findIndex(x => x.text === props)
                const from = trainLocations[index]
                const startTime = from?.plannedDepartureTime ? toDateTimeFromSeconds(from?.plannedDepartureTime) : ''
                const list = trainLocations.slice(index + 1, trainLocations.length)
                toSetState.trainLocationsTo = list
                toSetState.startTime = startTime
                toSetState.ptcarUicFrom = from.ptcarUic || ''
            }
            if (type === 'locationTo') {
                const { trainLocations } = this.state
                const index = trainLocations.findIndex(x => x.text === props)
                const to = trainLocations[index]
                const endTime = to?.plannedArrivalTime ? toDateTimeFromSeconds(to?.plannedArrivalTime) : ''
                const list = trainLocations.slice(0, index)
                toSetState.trainLocationsFrom = list
                toSetState.endTime = endTime
                toSetState.ptcarUicTo = to.ptcarUic || ''
            }
            this.setState(toSetState)
        }

        if (type === 'taskType') {
            const isGroup11 =
                props.toUpperCase() === 'DRIVE' ||
                props.toUpperCase() === 'HLP' ||
                props.toUpperCase() === 'PERQUAI' ||
                props.toUpperCase() === 'UITGAR' ||
                props.toUpperCase() === 'TRANSFER'
            let show = this.trainNumberNeeded(props)
            showBus = this.busNumberNeeded(props)

            this.setState({ taskType: props, showStepThree: show, showStepThreeWithBus: showBus, ...initialState })
            if (isGroup11) {
                this.loadLindaRoles(props)
            }
        }
        if (type === 'trainNumber' || type === 'trainPrefix') {
            toSetState.trainLocations = []
            toSetState.trainLocationsTo = []
            toSetState.trainLocationsFrom = []
        }
        if (type === 'orgPt') {
            toSetState.selectedPerformanceNumberPt = ' '
            toSetState.depotPt = ' '
            toSetState.selectedPerformanceDetails = null
        }
        if (type === 'depotPt') {
            toSetState.selectedPerformanceNumberPt = ' '
            toSetState.selectedPerformanceDetails = null
        }
        if (type === 'selectedDatePt') {
            toSetState.selectedPerformanceNumberPt = ' '
            toSetState.selectedPerformanceDetails = null
        }
        if (type === 'selectedPerformanceNumberPt') {
            toSetState.selectedPerformanceDetails = null
        }

        this.setState(toSetState)
    }

    setStartTime = value => {
        const { validateForm } = this.state
        if (value === null) value = ''
        this.setState({ startTime: value, validateForm: validateForm + 1 })
    }

    setSelectedJsonPerformance = index => {
        this.setState({
            selectedJsonPerformance: index,
            selectedPerformanceDetails: this.state.selectedPerformanceForJson[index],
            checkedTasks: [],
            checkAll: false,
        })
    }

    setEndTime = value => {
        const { validateForm } = this.state
        if (value === null) value = ''
        this.setState({ endTime: value, validateForm: validateForm + 1 })
    }

    handleLocationToSearchValue = e => {
        if (e === null) {
            this.setState({
                locationToSearchValue: '',
                locationTo: '',
            })
        } else {
            this.setState({
                locationToSearchValue: e.value,
                locationToSelected: e,
                locationTo: e.label,
                locationToPtcarId: e.value,
                ptcarUicTo: e.uic,
            })
        }
    }

    clearLocationToValue = () => {
        this.setState({
            locationToSearchValue: '',
            locationToSelected: '',
            locationTo: ' ',
        })
    }

    handleLocationSearchValue = e => {
        if (e === null) {
            this.setState({
                locationSearchValue: '',
                location: '',
            })
        } else {
            this.setState({
                locationSearchValue: e.value,
                locationSelected: e,
                location: e.label,
                locationPtcarId: e.value,
            })
        }
    }

    clearLocationValue = () => {
        this.setState({
            locationSearchValue: '',
            locationSelected: '',
            location: ' ',
        })
    }

    handleLocationFromSearchValue = e => {
        if (e === null) {
            this.setState({
                locationFromSearchValue: '',
                locationFrom: '',
            })
        } else {
            this.setState({
                locationFromSearchValue: e.value,
                locationFromSelected: e,
                locationFrom: e.label,
                locationFromPtcarId: e.value,
                ptcarUicFrom: e.uic,
            })
        }
    }

    clearLocationFromValue = () => {
        this.setState({
            locationFromSearchValue: '',
            locationFromSelected: '',
            locationFrom: ' ',
        })
    }

    render() {
        const disabled = this.checkComplete()
        const { showStepThree, showStepThreeWithBus, loading, isTablet, checkAll, locationListLoading } = this.state

        if (checkWriteAccess(this.props, 'add', 'performances.functionality.management.tasks')) {
            return (
                <Translate>
                    {({ translate }) => (
                        <React.Fragment>
                            <AddNewTaskView translate={translate} {...this.props}>
                                <DistrictsMapperNoFilter>
                                    <Grid container spacing={2}>
                                        <FirstStep
                                            {...this.state}
                                            isTablet={isTablet}
                                            persist={this.persist}
                                            loadPerformanceNumbers={this.loadPerformanceNumbers}
                                            handleDateChange={this.handleDateChange}
                                            selectedDate={this.state.selectedDate}
                                            {...this.props}
                                        />
                                        {this.checkStepOne() && (
                                            <SecondStep
                                                {...this.state}
                                                persist={this.persist}
                                                {...this.props}
                                                chooseFromOtherPerformanceClick={this.chooseFromOtherPerformanceClick}
                                                loadPerformanceNumbers={this.loadPerformanceNumbersPt}
                                                handleDateChange={this.handleDateChangePt}
                                                getSelectedPerformanceDetails={this.getSelectedPerformanceDetails}
                                                handleCheckTasks={this.handleCheckTasks}
                                                handleCheckAll={this.handleCheckAll}
                                                checkAll={checkAll}
                                                resetCheckAllAndErrors={this.resetCheckAllAndErrors}
                                                trainRoleValues={this.state.lindaRoles}
                                                radio={this.state.radio}
                                                loadPerformanceForList={this.loadPerformanceForList}
                                                setSelectedJsonPerformance={this.setSelectedJsonPerformance}
                                            />
                                        )}
                                        {this.checkStepOne() &&
                                            this.checkStepTwo() &&
                                            (showStepThree || showStepThreeWithBus) && (
                                                <ThirdStep
                                                    isTablet={isTablet}
                                                    loading={loading}
                                                    loadTrain={this.loadTrain}
                                                    {...this.state}
                                                    persist={this.persist}
                                                    {...this.props}
                                                />
                                            )}
                                        {this.checkStepOne() && this.checkStepTwo() && this.checkStepThree() && !loading && (
                                            <LastStep
                                                {...this.state}
                                                isTablet={isTablet}
                                                setEndTime={this.setEndTime}
                                                setStartTime={this.setStartTime}
                                                persist={this.persist}
                                                setFormValidation={this.setFormValidation}
                                                handleLocationToSearchValue={this.handleLocationToSearchValue}
                                                clearLocationToValue={this.clearLocationToValue}
                                                handleLocationFromSearchValue={this.handleLocationFromSearchValue}
                                                clearLocationFromValue={this.clearLocationFromValue}
                                                handleLocationSearchValue={this.handleLocationSearchValue}
                                                clearLocationValue={this.clearLocationValue}
                                                referencePerformanceNumbersData={this.state.referencePerformanceNumbersData}
                                                loadReferencePerformanceNumbers={this.loadReferencePerformanceNumbers}
                                                selectedReferencePerformanceNumber={
                                                    this.state.selectedReferencePerformanceNumber
                                                }
                                                trainLocationsTo={this.state.trainLocationsTo}
                                                trainLocationsFrom={this.state.trainLocationsFrom}
                                                timePickerDisable={this.state.timePickerDisable}
                                                locationListLoading={locationListLoading}
                                                {...this.props}
                                            />
                                        )}
                                        {loading && <Loading />}
                                    </Grid>
                                </DistrictsMapperNoFilter>
                            </AddNewTaskView>
                            <NewTaskFooter
                                disabled={disabled}
                                addTask={this.addTask}
                                chooseFromOtherPerformance={this.state.chooseFromOtherPerformance}
                                checkedTasks={this.state.checkedTasks}
                                addMultipleTasksLoading={this.state.addMultipleTasksLoading}
                                addMultipleTasksError={this.state.addMultipleTasksError}
                                {...this.props}
                            />
                            <AddTaskCall />
                        </React.Fragment>
                    )}
                </Translate>
            )
        } else {
            return <NoRights />
        }
    }
}
export default compose(
    withClear('clearAddTaskCall', 'addTaskCall'),
    withLocalize,
    withStore,
    withStatus,
    withRouter
)(AddNewTask)
