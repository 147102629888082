import React from 'react'
import { array, func, object, bool } from 'prop-types'
import { Translate } from 'react-localize-redux'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'

import Loading from 'components/Loading'

import mapKeysToLowerCase from 'utils/mapKeysToLowerCase'

import styles from './styles'

const AutocompleteView = ({ suggestions, setName, infoVisible, dismiss, classes, isLoading }) => {
    return infoVisible ? (
        <Paper data-testid="auto_complete_view" className={`${classes.paperRoot} ${classes.finished}`}>
            {isLoading && (
                <MenuItem data-testid="with_loading" component="div" disabled className={`${classes.pending}`}>
                    <Loading />
                </MenuItem>
            )}
            {suggestions &&
                suggestions.length > 0 &&
                suggestions.map(_suggestion => {
                    const suggestion = mapKeysToLowerCase(_suggestion)

                    return (
                        <MenuItem
                            data-testid="with_suggestions"
                            component="div"
                            key={suggestion.id}
                            onClick={() => setName(suggestion)}
                        >
                            <span id={suggestion.id} style={{ color: 'white' }}>
                                {suggestion.firstName} {suggestion.lastName} - {suggestion.idfNumber}
                            </span>
                        </MenuItem>
                    )
                })}
            {(!suggestions || suggestions.length === 0) && (
                <MenuItem
                    data-testid="without_suggestions"
                    component="div"
                    onClick={dismiss}
                    className={`${classes.menuItem}`}
                >
                    <Translate id="districts_read_prompt_no_results" />
                </MenuItem>
            )}
        </Paper>
    ) : null
}

AutocompleteView.propTypes = {
    classes: object.isRequired,
    infoVisible: bool.isRequired,
    isLoading: bool.isRequired,
    dismiss: func.isRequired,
    setName: func.isRequired,
    suggestions: array,
}

const AutocompletePending = ({ classes, infoVisible }) => {
    return infoVisible ? (
        <Paper data-testid="pending" className={`${classes.paperRoot} ${classes.pending}`}>
            <MenuItem component="div" disabled className={classes.pending}>
                <Loading />
            </MenuItem>
        </Paper>
    ) : null
}

AutocompletePending.propTypes = {
    infoVisible: bool.isRequired,
    classes: object.isRequired,
}

const AutocompleteFailed = ({ classes, infoVisible, dismiss }) => {
    return infoVisible ? (
        <Paper data-testid="failed" className={`${classes.paperRoot}`} tabIndex={0}>
            <MenuItem component="div" onClick={dismiss} className={`${classes.menuItem}`}>
                <Translate id="label_person_search_error" />
            </MenuItem>
        </Paper>
    ) : null
}

AutocompleteFailed.propTypes = {
    infoVisible: bool.isRequired,
    dismiss: func.isRequired,
    classes: object.isRequired,
}

export default {
    Loading: withStyles(styles)(AutocompletePending),
    Failed: withStyles(styles)(AutocompleteFailed),
    Finished: withStyles(styles)(AutocompleteView),
}
