import React from 'react'
import { bool, func, object } from 'prop-types'
import { withStyles } from '@material-ui/styles'

import styles from './styles'
import AppDrawer from 'components/AppDrawer'
import { Typography } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
const VehicleInfoDrawer = props => {
    const { isOpen, onClose, vehicleInfo } = props

    const getField = (title, value) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '70%' }}>
                <Typography>{props.translate(title)}</Typography>
                <Typography>{value}</Typography>
            </div>
        )
    }
    const getYesOrNo = value => (value ? <DoneIcon color="primary" /> : <CloseIcon style={{ color: 'yellow' }} />)

    return (
        <AppDrawer isOpen={isOpen} onClose={onClose} title={props.translate('vehicle_info_drawer_title')}>
            {getField('label_type', vehicleInfo.materialTypeCode)}
            {getField('label_sub_type', vehicleInfo.materialSubTypeCode)}
            {getField('label_uicCode', vehicleInfo.uicCode)}
            {getField('label_nmbs_nr', vehicleInfo.vehicleNumber)}
            {getField('label_sap_profile', vehicleInfo.catalogProfile)}
            {getField('label_etcs', vehicleInfo.etcsLevel)}
            {getField('label_no_of_units', vehicleInfo.numberVehicleUnits)}
            {getField('label_assen', vehicleInfo.numberAxles)}
            {getField('label_bogies', vehicleInfo.numberBogies)}
            {getField('label_lengte', `${vehicleInfo.lengthMeter}m`)}
            {getField('label_max_speed', `${vehicleInfo.maxSpeed} km/h`)}
            {getField('label_max_speed_empty', `${vehicleInfo.maxSpeedEmpty} km/h`)}
            {getField('label_max_speed_loc_train', `${vehicleInfo.maxSpeedLocTrain} km/h`)}
            {getField('label_bruto', `${vehicleInfo.brutoWeightTon}T`)}
            {getField('label_tarra', `${vehicleInfo.tarraWeightTon}T`)}
            {getField('label_regime_r', vehicleInfo.brakeMassR)}
            {getField('label_regime_g', vehicleInfo.brakeMassG)}
            {getField('label_regime_p', vehicleInfo.brakeMassP)}
            {getField('label_one_class', vehicleInfo.numberSeatsClass1)}
            {getField('label_second_class', vehicleInfo.numberSeatsClass2)}
            {getField('label_stuurpost', getYesOrNo(vehicleInfo.hasDriverCockpit))}
            {getField('label_pneumatic_suspension', getYesOrNo(vehicleInfo.hasPneumaticSuspension))}
            {getField('label_magneetrem', getYesOrNo(vehicleInfo.hasMagnetBrake))}
            {getField('label_hogedrukrem', getYesOrNo(vehicleInfo.hasHighPressureBrake))}
            {getField('label_electic_pneumatic', getYesOrNo(vehicleInfo.hasElectroPneumaticBrake))}
            {getField('label_dynamische', getYesOrNo(vehicleInfo.hasDynamicBrake))}
            {getField('label_afzonderen', getYesOrNo(vehicleInfo.hasBrakePerBogie))}
        </AppDrawer>
    )
}

VehicleInfoDrawer.propTypes = {
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    translate: func.isRequired,
    classes: object,
    vehicleInfo: object,
    data: object,
    openDrawerForAddVehicle: bool,
}

export default withStyles(styles)(VehicleInfoDrawer)
