import React, { useState } from 'react'
import PersonSearcher from 'components/PersonSearcher'
import { Typography } from '@material-ui/core'
import IconButton from 'components/IconButton'
import ErrorMessage from 'components/ErrorMessage'
import getGroupForAUser from 'modules/UsersAndRoles/Services/getGroupForAUser'
import { func } from 'prop-types'
import Loading from 'components/Loading'

const FindUserGroupDrawerView = props => {
    FindUserGroupDrawerView.propTypes = {
        translate: func,
    }
    const [selectedName, setSelectedName] = useState('')
    const [selectedProfile, setSelectedProfile] = useState(undefined)
    const [groupName, setGroupName] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const setName = profile => {
        setSelectedName(`${profile.firstName} ${profile.lastName}`)
        setSelectedProfile(profile)
    }

    const clearName = () => {
        setSelectedName('')
        setSelectedProfile(undefined)
        setGroupName('')
        setError('')
    }
    const getGroupName = () => {
        setIsLoading(true)
        let groupNameRes = getGroupForAUser(selectedProfile.idfNumber)
        groupNameRes
            .then(res => {
                setGroupName(res.data.name)
                setIsLoading(false)
            })
            .catch(() => {
                setError(props.translate('error_msg_no_user_group_found'))
                setIsLoading(false)
            })
    }
    const isFindDisable = !selectedProfile || groupName || isLoading
    return (
        <div>
            <Typography variant="h6">{props.translate('districts_read_label_person')}</Typography>
            <hr style={{ marginBottom: 16 }} />
            <div style={{ position: 'relative' }} name="PersonSearcherWrapper" tabIndex={0}>
                <PersonSearcher {...{ selectedName, setName, clearName }} />
            </div>

            <Typography style={{ marginTop: '16px' }} variant="subtitle1">
                {`${props.translate('user_group_name')}:  ${groupName}`}
            </Typography>
            {isLoading && <Loading />}
            {error && <ErrorMessage error={error} spacing={false} />}
            <IconButton
                data-testid="button"
                size={'medium'}
                style={{ marginTop: '16px' }}
                color={'primary'}
                icon="group"
                title={props.translate('icon_button_find_userGroup')}
                onClick={() => getGroupName()}
                disabled={isFindDisable}
            />
        </div>
    )
}
export default FindUserGroupDrawerView
