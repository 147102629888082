import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from '../../../../components/Header'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

export class EditTaskView extends Component {
    static propTypes = {
        classes: PropTypes.object,
        performanceDetail: PropTypes.object,
        children: PropTypes.any,
    }
    render() {
        const { performanceDetail, children, classes } = this.props
        return (
            <React.Fragment>
                {!performanceDetail && <Header title={'Taak Bewerken'} />}
                <div className={classes.generalContainer}>{children}</div>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(EditTaskView)
