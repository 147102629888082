import React from 'react'
import classNames from 'classnames'
import axios from 'axios'
import { DateTime } from 'luxon'
import { util } from 'react-ion-store'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { any, arrayOf, object, string, func, number, array, bool } from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { AutoSizer, Column, defaultTableRowRenderer, Table } from 'react-virtualized'

import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { Delete, Block, Restore } from '@material-ui/icons'
import WarningIcon from '@material-ui/icons/Warning'

import checkFunctionalityDisabled from 'utils/checkFunctionalityDisabled'
import StyledTooltip from 'components/StyledTooltip'
import DeleteDialog from '../Dialogs/DeleteTaskDialog'
import AbolishDialog from '../Dialogs/AbolishDialog'
import StatusLabel from 'components/StatusLabel'
import calculateTimeDifferenceWithTimestamps from 'utils/calculateTimeDifferenceWithTimestamps'
import Checkbox from '@material-ui/core/Checkbox'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { isMobile } from 'react-device-detect'
// import { decode } from 'jsonwebtoken'
import { clear, valid, get } from 'utils/tokenUtils'

import styles from './styles'
import getStoredRefreshRate from 'utils/getStoredRefreshRate'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

const columnsForDesk = [
    {
        width: 150,
        label: 'datatable_header_task_code',
        dataKey: 'code',
    },
    {
        width: 100,
        label: 'datatable_header_task_linda_role',
        dataKey: 'lindaRole',
    },
    {
        width: 100,
        label: 'datatable_header_task_info_text',
        dataKey: 'info',
    },
    {
        width: 300,
        flexGrow: 1.0,
        label: 'datatable_header_task_info',
        dataKey: 'taskInfo',
    },
    {
        width: 200,
        label: 'datatable_header_task_diagram',
        dataKey: 'diagramNumber',
    },
    {
        width: 150,
        label: 'datatable_header_task_start',
        dataKey: 'plannedStartTime',
    },
    {
        width: 150,
        label: 'datatable_header_task_end',
        dataKey: 'plannedEndTime',
    },
    {
        width: 200,
        label: 'datatable_header_task_status',
        dataKey: 'status',
    },
    {
        width: 200,
        label: 'datatable_header_task_actions',
        dataKey: 'actions',
    },
]

const columnsForMob = [
    {
        width: 200,
        label: 'datatable_header_task_code',
        dataKey: 'code',
    },
    {
        width: 250,
        flexGrow: 1.0,
        label: 'datatable_header_task_info',
        dataKey: 'taskInfo',
    },
    {
        width: 350,
        label: 'datatable_header_task_diagram_mob',
        dataKey: 'diagramNumber',
    },
]

class TaskList extends React.Component {
    constructor() {
        super()
        this.state = {
            taskId: '',
            isDeleteOpen: false,
            isAbolishOpen: false,
            isAbolished: false,
            isTaskCallOn: false,
            errorOpen: false,
        }
    }

    static propTypes = {
        classes: object.isRequired,
        tasks: arrayOf(object).isRequired,
        history: any,
        match: any,
        performanceId: string,
        idfNumber: string,
        handleOpen: func,
        handleClose: func,
        rowClassName: string,
        onRowClick: func,
        width: string,
        headerHeight: number,
        handleCheckTasks: func,
        checkedTasks: array,
        rowHeight: number,
        storage: object,
        disableButtons: bool,
        noteIsOpen: bool,
        isOpen: bool,
        translate: func,
        refreshRate: bool,
    }

    static defaultProps = {
        rowHeight: 56,
        headerHeight: 56,
    }

    //
    handleDeleteOpen = taskId => {
        const { handleOpen } = this.props
        this.setState({ taskId: taskId })
        handleOpen()
    }

    closeError = () => {
        this.setState({ errorOpen: false })
    }

    handleDeleteClose = () => {
        const { handleClose } = this.props
        //reset state and close drawer
        this.setState({ taskId: '' })
        handleClose()
    }

    handleAbolishOpen = (taskId, isAbolished) => {
        this.setState({ isAbolishOpen: true, taskId: taskId, isAbolished: isAbolished })
    }

    handleAbolishClose = () => {
        //reset state and close drawer
        this.setState({ isAbolishOpen: false, taskId: '' })
    }

    onRowClickDeleteTask = (e, id) => {
        this.handleDeleteOpen(id)

        e.stopPropagation()
    }

    onRowClickAbolishTask = (e, id, isAbolished = false) => {
        this.handleAbolishOpen(id, isAbolished)
        e.stopPropagation()
    }

    getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick, tasks } = this.props

        if (tasks[index] && tasks[index].isAbolished === true) {
            return classNames(classes.statusAbolished, classes.flexContainer, rowClassName, {
                [classes.tableRowHover]: index !== -1 && onRowClick != null,
            })
        }

        if (tasks[index] && tasks[index].status === 2) {
            return classNames(classes.statusDone, classes.flexContainer, rowClassName, {
                [classes.tableRowHover]: index !== -1 && onRowClick != null,
            })
        }
        if (tasks[index] && tasks[index].status === 1) {
            return classNames(classes.statusStarted, classes.flexContainer, rowClassName, {
                [classes.tableRowHover]: index !== -1 && onRowClick != null,
            })
        }
        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    headerRenderer = headerProps => {
        const { classes } = this.props
        let headerHeight
        if (isWidthUp('sm', this.props.width)) {
            headerHeight = this.props.headerHeight
        } else {
            headerHeight = 75
        }

        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{ height: headerHeight }}
            >
                <Typography>
                    <Translate id={headerProps.label} />
                </Typography>
            </TableCell>
        )
    }

    printRealStartTime = (realStartTime, plannedStartTime, realStartDateTime, plannedStartDateTime) => {
        if (!realStartTime && !plannedStartTime) {
            return '--:--'
        }
        if (!realStartTime && plannedStartTime) {
            return DateTime.fromFormat(plannedStartTime, 'HH:mm:ss').toFormat('HH:mm')
        }
        if (realStartTime) {
            const diff = calculateTimeDifferenceWithTimestamps(plannedStartDateTime, realStartDateTime)
            const check = Math.sign(diff)

            if (check === 0) {
                return DateTime.fromFormat(plannedStartTime, 'HH:mm:ss').toFormat('HH:mm')
            }
            if (check === 1) {
                return `${DateTime.fromFormat(plannedStartTime, 'HH:mm:ss').toFormat('HH:mm')} (+${diff})`
            } else {
                return `${DateTime.fromFormat(plannedStartTime, 'HH:mm:ss').toFormat('HH:mm')} (${diff})`
            }
        }
    }

    printRealEndTime = (realEndTime, plannedEndTime, realEndDateTime, plannedEndDateTime) => {
        if (!realEndTime && !plannedEndTime) {
            return '--:--'
        }
        if (realEndTime && !plannedEndTime) {
            return '--:--'
        }
        if (!realEndTime && plannedEndTime) {
            return DateTime.fromFormat(plannedEndTime, 'HH:mm:ss').toFormat('HH:mm')
        }
        if (realEndTime) {
            const diff = calculateTimeDifferenceWithTimestamps(plannedEndDateTime, realEndDateTime)
            const check = Math.sign(diff)

            if (check === 0) {
                return DateTime.fromFormat(plannedEndTime, 'HH:mm:ss').toFormat('HH:mm')
            }
            if (check === 1) {
                return `${DateTime.fromFormat(plannedEndTime, 'HH:mm:ss').toFormat('HH:mm')} (+${diff})`
            } else {
                return `${DateTime.fromFormat(plannedEndTime, 'HH:mm:ss').toFormat('HH:mm')} (${diff})`
            }
        }
    }
    handleCheckClicks = (e, id) => {
        e.stopPropagation()
        this.props.handleCheckTasks(id)
    }
    getVehicleNumber = rowData => {
        return rowData.materials.reduce((accumulator, currentValue) => {
            let vNumber = currentValue.vehicleNumber ? currentValue.vehicleNumber : currentValue?.diagramNumber
            return vNumber ? `${accumulator + vNumber} ` : accumulator
        }, '')
    }
    MobcellRenderer = cellProps => {
        const { classes } = this.props
        const { dataKey, rowData } = cellProps
        let data = rowData[dataKey]
        let rowHeight = 75
        if (dataKey === 'code' && data === 'DRIVE') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{rowData['trainComposed'] || 'DRIVE'}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'code' && rowData.trainComposed && data !== 'DRIVE') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{rowData.code}</Typography>
                    <Typography>{rowData['trainComposed']}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'code') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{rowData.code}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'taskInfo') {
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>
                        {dataKey === 'taskInfo' && rowData.code === 'START' && (
                            <Translate id="datatable_label_service_start" />
                        )}
                        {dataKey === 'taskInfo' && rowData.code === 'END' && <Translate id="datatable_label_service_end" />}

                        {rowData['taskInfo']}
                    </Typography>
                </TableCell>
            )
        }
        if (dataKey === 'diagramNumber') {
            let vehicleNumber = this.getVehicleNumber(rowData)
            let diagramNumber = vehicleNumber.length ? vehicleNumber : rowData['diagramNumber']
            return (
                <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: rowHeight }}>
                    <Typography>{diagramNumber}</Typography>
                    <div style={{ display: 'flex' }}>
                        <Typography>
                            {/* eslint-disable-next-line max-len */}
                            {this.printRealStartTime(rowData['realStartTime'], rowData['plannedStartTime'], rowData['realStartDateTime'], rowData['plannedStartDateTime'])}
                        </Typography>
                        <Typography style={{ marginLeft: '5px' }}>
                            {/* eslint-disable-next-line max-len */}
                            {this.printRealEndTime(rowData['realEndTime'], rowData['plannedEndTime'], rowData['realEndDateTime'], rowData['plannedEndDateTime'])}
                        </Typography>
                    </div>
                </TableCell>
            )
        }
    }
    cellRenderer = cellProps => {
        const { classes, performanceId, idfNumber, checkedTasks } = this.props
        const { dataKey, rowData } = cellProps
        const isGroup11 =
            rowData.code.toUpperCase() === 'DRIVE' ||
            rowData.code.toUpperCase() === 'HLP' ||
            rowData.code.toUpperCase() === 'PERQUAI' ||
            rowData.code.toUpperCase() === 'UITGAR' ||
            rowData.code.toUpperCase() === 'TRANSFER'
        let data = rowData[dataKey]

        if (dataKey === 'plannedStartTime') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    {/* eslint-disable-next-line max-len */}
                    <Typography>{this.printRealStartTime(rowData['realStartTime'], rowData['plannedStartTime'], rowData['realStartDateTime'], rowData['plannedStartDateTime'])}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'plannedEndTime') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    {/* eslint-disable-next-line max-len */}
                    <Typography>{this.printRealEndTime(rowData['realEndTime'], rowData['plannedEndTime'], rowData['realEndDateTime'], rowData['plannedEndDateTime'])}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'lindaRole' && isGroup11) {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData.lindaRoleCode}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'info' && rowData.code === 'DRIVE') {
            let lineInfo = null
            let { driveDetails } = rowData

            const result = driveDetails.filter(obj => {
                if (!lineInfo) lineInfo = obj.lineInfo
                return obj.lineInfo
            }).length
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>
                        <span className={classes.customFont}>{lineInfo} </span>
                        {` ${result - 1 > 0 ? '+' + (result - 1) : ''}`}
                    </Typography>
                </TableCell>
            )
        }

        if (dataKey === 'actions') {
            return (
                <TableCell
                    className={classes.actionsTableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    {rowData.code !== 'END' && rowData.code !== 'START' && !this.props.disableButtons && (
                        <div className={classes.multipleIcons}>
                            <Checkbox
                                className={classes.checkBox}
                                color="primary"
                                checked={checkedTasks.indexOf(rowData.id) > -1}
                                onClick={e => this.handleCheckClicks(e, rowData.id)}
                                disabled={checkFunctionalityDisabled(
                                    this.props.storage,
                                    'deleteBulk',
                                    'performances.functionality.management.tasks'
                                )}
                            />

                            <Delete
                                className={classes.delActionIcon}
                                style={{
                                    pointerEvents: checkFunctionalityDisabled(
                                        this.props.storage,
                                        'delete',
                                        'performances.functionality.management.tasks'
                                    )
                                        ? 'none'
                                        : 'auto',
                                    color: checkFunctionalityDisabled(
                                        this.props.storage,
                                        'delete',
                                        'performances.functionality.management.tasks'
                                    )
                                        ? 'grey'
                                        : '',
                                }}
                                onClick={e => {
                                    this.onRowClickDeleteTask(e, rowData.id)
                                }}
                            />
                            {!rowData.isAbolished && idfNumber !== null && (
                                <Block
                                    className={classes.abolishActionIcon}
                                    style={{
                                        pointerEvents: checkFunctionalityDisabled(
                                            this.props.storage,
                                            'delete',
                                            'performances.functionality.management.tasks'
                                        )
                                            ? 'none'
                                            : 'auto',
                                        color: checkFunctionalityDisabled(
                                            this.props.storage,
                                            'delete',
                                            'performances.functionality.management.tasks'
                                        )
                                            ? 'grey'
                                            : '',
                                    }}
                                    onClick={e => {
                                        this.onRowClickAbolishTask(e, rowData.id)
                                    }}
                                />
                            )}
                            {rowData.isAbolished && idfNumber !== null && (
                                <Restore
                                    className={classes.abolishActionIcon}
                                    style={{
                                        pointerEvents: checkFunctionalityDisabled(
                                            this.props.storage,
                                            'delete',
                                            'performances.functionality.management.tasks'
                                        )
                                            ? 'none'
                                            : 'auto',
                                        color: checkFunctionalityDisabled(
                                            this.props.storage,
                                            'delete',
                                            'performances.functionality.management.tasks'
                                        )
                                            ? 'grey'
                                            : '',
                                    }}
                                    onClick={e => {
                                        this.onRowClickAbolishTask(e, rowData.id, rowData.isAbolished)
                                    }}
                                />
                            )}
                        </div>
                    )}
                </TableCell>
            )
        }

        if (dataKey === 'code' && data === 'DRIVE') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData['trainComposed'] || 'DRIVE'}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'code' && rowData.trainComposed && data !== 'DRIVE') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{rowData.code}</Typography>
                    <Typography>{rowData['trainComposed']}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'diagramNumber') {
            let vehicleNumber = this.getVehicleNumber(rowData)
            let diagramNumber = vehicleNumber.length ? vehicleNumber : rowData['diagramNumber']
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: this.props.rowHeight }}
                >
                    <Typography>{diagramNumber}</Typography>
                </TableCell>
            )
        }

        return (
            <TableCell className={classes.tableCell} component="div" variant="body" style={{ height: this.props.rowHeight }}>
                {dataKey === 'status' && (
                    <StatusLabel
                        disableButtons={
                            this.props.disableButtons ||
                            this.state.isTaskCallOn ||
                            checkFunctionalityDisabled(
                                this.props.storage,
                                'change status',
                                'performances.functionality.management.tasks'
                            )
                        }
                        changeStatusOnClick={
                            !this.props.disableButtons &&
                            !checkFunctionalityDisabled(
                                this.props.storage,
                                'change status',
                                'performances.functionality.management.tasks'
                            )
                                ? rowData.code === 'START' || rowData.code === 'END' || idfNumber === null
                                    ? () => {}
                                    : e => {
                                          e.stopPropagation()
                                          this.changeTaskStatus(
                                              rowData[dataKey],
                                              performanceId,
                                              rowData.id,
                                              idfNumber,
                                              rowData.plannedStartTime,
                                              rowData.realEndTime ? rowData.realEndTime : rowData.plannedEndTime
                                          )
                                      }
                                : ''
                        }
                        status={rowData[dataKey]}
                        isAbolished={rowData['isAbolished']}
                        startEnd={rowData.code === 'START' || rowData.code === 'END' || idfNumber === null ? true : false}
                    />
                )}
                {dataKey !== 'status' && (
                    <StyledTooltip title={data || ''}>
                        <Typography>
                            {dataKey === 'taskInfo' && rowData.code === 'START' && (
                                <Translate id="datatable_label_service_start" />
                            )}
                            {dataKey === 'taskInfo' && rowData.code === 'END' && (
                                <Translate id="datatable_label_service_end" />
                            )}
                            {(dataKey !== 'taskInfo' ||
                                (dataKey === 'taskInfo' && rowData.code !== 'END' && rowData.code !== 'START')) &&
                                data}
                        </Typography>
                    </StyledTooltip>
                )}
            </TableCell>
        )
    }

    getRowRenderer = props => {
        if (
            props.rowData.code === 'FREETEXT_P' ||
            props.rowData.code === 'FREETEXT_M' ||
            props.rowData.code === 'OPMRMQ' ||
            props.rowData.code === 'OPMREM'
        ) {
            return (
                <div
                    style={{
                        paddingLeft: '24px',
                        ...props.style,
                    }}
                    className={props.className}
                    key={props.key}
                >
                    <StyledTooltip title={props.rowData.taskInfo || props.rowData.comment}>
                        <div
                            style={{
                                height: props.rowHeight,
                                padding: 0,
                                paddingTop: '15px',
                                borderLeft: 'solid 3px yellow',
                                maxWidth: '80%',
                                minWidth: '20%',
                            }}
                            onClick={() => props.onRowClick(props)}
                        >
                            <span
                                style={{
                                    position: 'absolute',
                                    left: 10,
                                    background: 'yellow',
                                    borderRadius: '15px',
                                    paddingTop: '2px',
                                    width: '30px',
                                    height: '30px',
                                }}
                            >
                                <WarningIcon
                                    style={{
                                        color: 'black',
                                        position: 'relative',
                                        display: 'block',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                />
                            </span>
                            <Typography
                                variant="h6"
                                style={{
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                    lineHeight: '28px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {props.rowData.taskInfo || props.rowData.comment}
                            </Typography>
                        </div>
                    </StyledTooltip>
                    {!this.props.disableButtons && (
                        <div className={this.props.classes.multipleIcons}>
                            <Checkbox
                                className={this.props.classes.checkBox}
                                color="primary"
                                checked={this.props.checkedTasks.indexOf(props.rowData.id) > -1}
                                onClick={e => this.handleCheckClicks(e, props.rowData.id)}
                                disabled={checkFunctionalityDisabled(
                                    this.props.storage,
                                    'deleteBulk',
                                    'performances.functionality.management.tasks'
                                )}
                            />
                            <Delete
                                className={this.props.classes.delActionIcon}
                                style={{
                                    pointerEvents: checkFunctionalityDisabled(
                                        this.props.storage,
                                        'delete',
                                        'performances.functionality.management.tasks'
                                    )
                                        ? 'none'
                                        : 'auto',
                                    color: checkFunctionalityDisabled(
                                        this.props.storage,
                                        'delete',
                                        'performances.functionality.management.tasks'
                                    )
                                        ? 'grey'
                                        : '',
                                }}
                                onClick={e => {
                                    this.onRowClickDeleteTask(e, props.rowData.id)
                                }}
                            />
                            {this.props.idfNumber !== null && (
                                <Block style={{ visibility: 'hidden' }} className={this.props.classes.abolishActionIcon} />
                            )}
                        </div>
                    )}
                </div>
            )
        } else {
            return defaultTableRowRenderer(props)
        }
    }

    getCorrectIndex = e => {
        return e.rowData.id
    }

    componentDidMount() {
        this.setRefreshInterval()
    }

    componentWillUnmount = () => {
        clearInterval(this.refreshInterval)
    }
    componentDidUpdate = prevProps => {
        if (prevProps.refreshRate !== this.props.refreshRate) {
            clearInterval(this.refreshInterval)
            this.setRefreshInterval()
        }
    }

    checkForEdriveAppOrMobile = () => {
        const UA = navigator.userAgent
        const isUA = UA.includes('eDriveApp')

        if (isUA) return isUA
        if (isMobile) return isMobile
        return false
    }

    setRefreshInterval = () => {
        const refreshValue = getStoredRefreshRate('taskDetail')
        const eDriveApp = this.checkForEdriveAppOrMobile()

        if (eDriveApp) return

        this.refreshInterval = setInterval(() => {
            this.checkJwtExpiry()
            this.performanceDetailCall()
        }, refreshValue * 1000)
    }

    performanceDetailCall = () => {
        const { isAbolishOpen } = this.state
            const { noteIsOpen, isOpen, checkedTasks } = this.props
            if (!isOpen && !isAbolishOpen && !noteIsOpen && checkedTasks.length === 0) {
                this.props.storage.call('performanceDetail')
            }
    }

    checkJwtExpiry = () => {
        const [idToken, accessToken] = get()
        const validToken = valid(idToken, accessToken)
        if(!validToken){
            clear()
            this.props.storage.set({ auth: undefined })
        }
    }

    changeTaskStatus = (status, performanceNumber, taskNumber, performanceIdf, start, end) => {
        this.setState({ errorMessage: '', taskId: taskNumber, isTaskCallOn: true }, () => {
            let allActions = false

            let caseProp = 0
            if (end !== null && start !== null) allActions = true

            if (allActions && status === 0) {
                caseProp = 1
            }
            if (!allActions && status === 0) {
                caseProp = 2
            }
            if (status === 1) {
                caseProp = 2
            }

            if (status === 2) {
                caseProp = 0
            }
            this.chooseRightCall(caseProp, performanceNumber, taskNumber, performanceIdf, start, end)
        })
    }

    chooseRightCall = (status, performanceNumber, taskNumber, performanceIdf, start, end) => {
        //set tasknumber so we can force a render afterwards

        let startedTasks = []
        switch (status) {
            case 0:
                this.doResetCall(performanceNumber, taskNumber, performanceIdf)
                break
            case 1:
                //Check if there is allready a started task
                startedTasks = this.findStartedTask()
                this.doStartCall(performanceNumber, taskNumber, performanceIdf, startedTasks, start)
                break
            case 2:
                this.doFinishCall(performanceNumber, taskNumber, performanceIdf, end)
                break

            default:
                break
        }
    }

    findStartedTask = () => {
        const { tasks } = this.props

        return tasks.filter(task => task.status === 1)
    }

    doResetCall = async (performanceNumber, taskNumber, performanceIdf) => {
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/performance/${performanceNumber}/task/${taskNumber}/resetstatus?idfNumber=${performanceIdf}`

        try {
            const response = await axios.post(fullUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })
            if (response.status === 204) {
                return []
            }
            if (response.status === 200) {
                this.updateTaskStatusInStore(taskNumber, 0)

                this.performanceDetailCall()
                return response.data
            }
        } catch (e) {
            const { translate } = this.props
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({
                        errorMessage: translate('roles_error'),
                        errorOpen: true,
                        isTaskCallOn: false,
                    })
                    return false
                }
            }
            this.setState({
                errorMessage: translate('performance_change_task_status_error'),
                errorOpen: true,
                isTaskCallOn: false,
            })
            console.error(e) // eslint-disable-line
        }
    }

    doStartCall = async (performanceNumber, taskNumber, performanceIdf, startedTasks, start) => {
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/performance/${performanceNumber}/task/${taskNumber}/start`

        const now = DateTime.utc()

        const StatusOngoing = now.toISO()
        try {
            const response = await axios.post(fullUrl, {
                IdfNumber: performanceIdf,
                RealStartTime: start,
                StatusOngoing: StatusOngoing,
                RealStartDateTime: StatusOngoing,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            if (response.status === 204) {
                return []
            }
            if (response.status === 200) {
                this.updateTaskStatusInStore(taskNumber, 1)
                if (startedTasks.length > 0) {
                    this.setState({ isTaskCallOn: true })
                    startedTasks
                        .slice()
                        .reverse()
                        .forEach(task => {
                            this.updateTaskStatusInStore(task.id, 2)
                        })
                }
                this.performanceDetailCall()
                return response.data
            }
        } catch (e) {
            const { translate } = this.props
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({
                        errorMessage: translate('roles_error'),
                        errorOpen: true,
                        isTaskCallOn: false,
                    })
                    return false
                }
            }
            this.setState({
                errorMessage: translate('performance_change_task_status_error'),
                errorOpen: true,
                isTaskCallOn: false,
            })
            console.error(e) // eslint-disable-line
        }
    }

    doFinishCall = async (performanceNumber, taskNumber, performanceIdf, end) => {
        const { url, subscriptionKey } = requestData

        const fullUrl = `${url}/performance/${performanceNumber}/task/${taskNumber}/finish`

        const now = DateTime.utc()

        const StatusEnded = now.toISO()
        try {
            const response = await axios.post(fullUrl, {
                IdfNumber: performanceIdf,
                RealEndTime: end,
                StatusEnded: StatusEnded,
                RealEndDateTime: StatusEnded,
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                },
            })

            if (response.status === 204) {
                return []
            }
            if (response.status === 200) {
                this.updateTaskStatusInStore(taskNumber, 2)

                this.performanceDetailCall()
                return response.data
            }
        } catch (e) {
            const { translate } = this.props
            if (e.response.status === 400) {
                if (e.response.data.Message.indexOf('Action not allowed') !== -1) {
                    this.setState({
                        errorMessage: translate('roles_error'),
                        errorOpen: true,
                        isTaskCallOn: false,
                    })
                    return false
                }
            }
            this.setState({
                errorMessage: translate('performance_change_task_status_error'),
                errorOpen: true,
                isTaskCallOn: false,
            })
            console.error(e) // eslint-disable-line
        }
    }

    updateTaskStatusInStore = (taskId, status) => {
        let oldPerformanceDetail = this.props.storage.get('performanceDetail')

        const task = oldPerformanceDetail.tasks.find(task => task.id === taskId)

        task.status = status

        util.CreateStoreObject(oldPerformanceDetail)

        //Do setState to force render
        this.setState({ taskId: '', isTaskCallOn: false })
    }

    render() {
        const { classes, tasks, history, match, performanceId, idfNumber, storage, isOpen } = this.props
        const { taskId, isAbolishOpen, isAbolished, errorOpen } = this.state
        let columns = null
        let cellrenderer = null
        let rowHeight
        let headerHeight
        if (isWidthUp('sm', this.props.width)) {
            columns = columnsForDesk
            cellrenderer = this.cellRenderer
            rowHeight = this.props.rowHeight
            headerHeight = this.props.headerHeight
        } else {
            columns = columnsForMob
            cellrenderer = this.MobcellRenderer
            rowHeight = 75
            headerHeight = 75
        }

        return (
            <React.Fragment>
                <div className={classes.documentTableWrapper}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                className={classes.table}
                                height={height}
                                width={width}
                                rowClassName={this.getRowClassName}
                                rowCount={tasks.length}
                                rowGetter={({ index }) => tasks[index]}
                                rowHeight={rowHeight}
                                headerHeight={headerHeight}
                                rowRenderer={this.getRowRenderer}
                                onRowClick={e => {
                                    if (
                                        !this.state.isTaskCallOn ||
                                        !checkFunctionalityDisabled(
                                            this.props.storage,
                                            'taskDetail',
                                            'performances.functionality.management.performanceDetail'
                                        )
                                    ) {
                                        const correctIndex = this.getCorrectIndex(e)
                                        history.push(`${match.url}/task/${correctIndex}`)
                                    }
                                }}
                            >
                                {columns.map(({ dataKey, ...other }) => (
                                    <Column
                                        key={dataKey}
                                        headerRenderer={this.headerRenderer}
                                        className={classes.flexContainer}
                                        cellRenderer={cellrenderer}
                                        dataKey={dataKey}
                                        {...other}
                                    />
                                ))}
                            </Table>
                        )}
                    </AutoSizer>
                </div>
                <Snackbar open={errorOpen} autoHideDuration={6000} onClose={this.closeError}>
                    <MuiAlert evation={6} variant="filled" onClose={this.closeError} severity="warning">
                        {this.state.errorMessage}
                    </MuiAlert>
                </Snackbar>
                <DeleteDialog
                    open={isOpen}
                    handleClose={this.handleDeleteClose}
                    performanceId={performanceId}
                    idfNumber={idfNumber}
                    taskId={taskId}
                    performanceDetailPage={false}
                    history={history}
                    storage={storage}
                />
                <AbolishDialog
                    open={isAbolishOpen}
                    handleClose={this.handleAbolishClose}
                    performanceId={performanceId}
                    idfNumber={idfNumber}
                    taskId={taskId}
                    history={history}
                    storage={storage}
                    isAbolished={isAbolished}
                />
            </React.Fragment>
        )
    }
}

export default compose(withWidth(), withStyles(styles), withRouter, withLocalize)(TaskList)
