import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, Column, Table, defaultTableRowRenderer } from 'react-virtualized'

export class ProfileStatusListView extends Component {
    static propTypes = {
        profiles: PropTypes.arrayOf(PropTypes.object),
        columns: PropTypes.array,
        classes: PropTypes.object,
        headerHeight: PropTypes.number,
        rowHeight: PropTypes.number,
        headerRenderer: PropTypes.func,
        cellRenderer: PropTypes.func,
        getRowClassName: PropTypes.func,
    }

    render() {
        const {
            profiles,
            columns,
            classes,
            rowHeight,
            headerHeight,
            headerRenderer,
            cellRenderer,
            getRowClassName,
        } = this.props
        return (
            <div className={classes.documentTableWrapper}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            height={height}
                            width={width}
                            rowCount={profiles.length}
                            rowClassName={getRowClassName}
                            rowRenderer={defaultTableRowRenderer}
                            rowGetter={({ index }) => profiles[index]}
                            rowHeight={rowHeight}
                            headerHeight={headerHeight}
                        >
                            {columns.map(({ dataKey, ...other }) => (
                                <Column
                                    key={dataKey}
                                    headerRenderer={headerRenderer}
                                    className={classes.flexContainer}
                                    cellRenderer={cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            ))}
                        </Table>
                    )}
                </AutoSizer>
            </div>
        )
    }
}

export default ProfileStatusListView
