import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'
import { withStore, withStatus } from 'react-ion-store'
import PtcarListView from './PtcarListView'

import getMapSettings from './getMapSettings'
import { withLocalize } from 'react-localize-redux'
import { getLinesInformation } from './getLinesInformation'
import withFunctionlityCheck from 'utils/hocs/withFunctionalityCheck'
import withHocs from 'utils/hocs/withHocs'

function PtcarOverview(props) {
    const [lineNumberData, setLineNumberData] = useState([])
    const [useInfrabelUrl, setUseInfrabelUrl] = useState(false)

    const getName = nameObj => {
        let { activeLanguage } = props
        if (nameObj === undefined || nameObj[0] === undefined || nameObj[1] === undefined) return ''
        if (activeLanguage.code === 'fr') return nameObj[0].translation
        else return nameObj[1].translation
    }
    useEffect(() => {
        let response = getLinesInformation()
        let mapSettingRes = getMapSettings()
        response
            .then(res => {
                let lineNumbers = res.data.map(({ code, name }) => ({ code: code, label: getName(name) }))
                setLineNumberData(lineNumbers)
            })
            .catch(() => setLineNumberData([]))
        mapSettingRes
            .then(res => {
                setUseInfrabelUrl(res.data.useInfrabelMapUrl)
            })
            .catch(e => {
                setUseInfrabelUrl(false)
                console.error(e)
            })
    }, [])

    return <PtcarListView lineNumberData={lineNumberData} useInfrabelUrl={useInfrabelUrl} />
}
PtcarOverview.propTypes = {
    activeLanguage: PropTypes.object,
}
export default withHocs(withLocalize, withRouter, withStore, withStatus, withFunctionlityCheck)(PtcarOverview)
