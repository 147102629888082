import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const withMediaQuery = (
    query,
    rowHeightForMobile,
    rowHeightForDesktop,
    headerHeightForMobile,
    headerHeightForDesktop
) => Component => props => {
    const isMobile = useMediaQuery(query)
    let rowHeight = isMobile ? rowHeightForMobile : rowHeightForDesktop
    let headerHeight = isMobile ? headerHeightForMobile : headerHeightForDesktop
    return <Component rowHeight={rowHeight} headerHeight={headerHeight} {...props} />
}

export default withMediaQuery
