import { Tooltip, withStyles } from '@material-ui/core'

const StyledTooltip = withStyles({
    tooltip: {
        backgroundColor: '#424242',
        color: 'white',
        maxWidth: 300,
        wordWrap: 'break-word',
        fontSize: '1.2rem',
        border: '1px solid white',
    },
})(Tooltip)

export default StyledTooltip
