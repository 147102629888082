import { decode } from 'jsonwebtoken'

export const getIdfFromStore = auth => {
    const applyAnonymization =
        process.env.REACT_APP_APPLY_ANONYMIZATION === undefined
            ? false
            : process.env.REACT_APP_APPLY_ANONYMIZATION.toString() === 'true'

    const idf = `${decode(auth).b_employeeid}`
    if (applyAnonymization) {
        return getReversedString(idf)
    }

    return idf
}

const getReversedString = originalString => {
    var newString = ''
    for (var i = originalString.length - 1; i >= 0; i--) {
        newString += originalString[i]
    }
    return newString
}

// export const checkForAdmin = props => checkWriteAccess(props, 'viewall', 'agenda.functionality.management.agenda')

export const isPerformanceMenuAllowed = userRoles => {
    let rolesWithoutPerformance = [10, 8, 1, 9]
    let returnValue = false
    if (userRoles)
        returnValue = userRoles.every(el => {
            return rolesWithoutPerformance.indexOf(el.portalUserRoleId) !== -1
        })
    return returnValue
}

export const isCompositionRefMenuAllowed = userRoles => {
    const rolesWithoutPermission = [2, 3]
    let returnValue = false
    if (userRoles) {
        returnValue = userRoles.every(el => {
            return rolesWithoutPermission.indexOf(el.portalUserRoleId) !== -1
        })
    }
    return returnValue
}
const isAgendaAllowed = props => {
    const idf = props.match.params.idfNumber
    const { roles } = props.store.get(['roles'])
    const isAllowed = roles && roles.searchagenda && roles.searchagenda.read.length
    if (idf === `${decode(props.store.get('auth')).b_employeeid}` 
    || idf === getReversedString(`${decode(props.store.get('auth')).b_employeeid}`)) {
        return true
    } else if (isAllowed) return true
    else return false
}

export const isIdfSameAsLoggedInUser = (idf, store) => {
    return idf === `${decode(store.get('auth')).b_employeeid}`
}

export default isAgendaAllowed
