import React from 'react'
import { object, func } from 'prop-types'
import { compose } from 'recompose'
import { Route, Switch, withRouter } from 'react-router-dom'
import { withStore, withStatus, enums } from 'react-ion-store'
import { withLocalize, Translate } from 'react-localize-redux'

import Loading from 'components/Loading'
import Header from 'components/Header'

import EdriveMonitor from './EdriveMonitor'

const {
    CallStatus: { LOADING, FAILED },
} = enums

const Failed = () => <div>Error retrieving Roles!</div>

class Monitoring extends React.Component {
    componentDidMount() {
        document.title = this.props.translate('module_monitoring')
    }
    render() {
        const { store, history, match, __ion_status } = this.props
        const { userRoles } = __ion_status

        const moduleList = store.get('moduleList')

        const rolesLoading = !!userRoles && userRoles === LOADING
        const rolesFailed = !!userRoles && userRoles === FAILED

        if (rolesFailed)
            return (
                <React.Fragment>
                    <Failed />
                </React.Fragment>
            )
        else if (rolesLoading || !moduleList)
            return (
                <React.Fragment>
                    <Loading />
                </React.Fragment>
            )
        else if (!!moduleList && !moduleList.includes('/monitoring')) {
            history.replace(`/`)
            return <div />
        }

        return (
            <React.Fragment>
                <Translate>
                    {({ translate }) => (
                        <React.Fragment>
                            <Header isMenu title={translate('module_monitoring')} 
                            pageTitle="monitoringTable" 
                            refreshNotRequired={false}
                            />
                        </React.Fragment>
                    )}
                </Translate>
                <Switch>
                    <Route exact path={`${match.path}`} component={EdriveMonitor} />
                </Switch>
            </React.Fragment>
        )
    }
}

Monitoring.propTypes = {
    history: object.isRequired,
    location: object.isRequired,
    store: object.isRequired,
    __ion_status: object.isRequired,
    match: object.isRequired,
    translate: func,
}

export default compose(withRouter, withStore, withStatus, withLocalize)(Monitoring)
