import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography, withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'
import some from 'lodash/some'
import { object, func, string, oneOfType, number, bool, array, any } from 'prop-types'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { withLocalize } from 'react-localize-redux'

import SelectInput from 'components/SelectInput'
import PrimaryButton from 'components/PrimaryButton'
import styles from './styles'
import PerformanceSelect from './performanceSelect'
import { DatePickerWithKeyboard } from 'components/Pickers'
const storeKey = 'mappedDistrictDataNoFilter'

export class SelectPerformanceForTasks extends Component {
    static propTypes = {
        persist: func.isRequired,
        store: object.isRequired,
        orgPt: string.isRequired,
        depotPt: string,
        performanceDetail: object,
        classes: object,
        handleDateChange: func.isRequired,
        resetCheckAllAndErrors: func.isRequired,
        selectedDatePt: oneOfType([object, number]).isRequired,
        selectedPerformanceNumberPt: string,
        translate: func,
        loadingPerformance: bool,
        loadPerformanceNumbers: func,
        performanceNumbersData: array,
        selectedPerformanceForJson: array,
        radio: string,
        depotNamePt: string,
        loadPerformanceForList: func,
        setSelectedJsonPerformance: func,
        selectedJsonPerformance: any,
        selectedPerformanceForJsonLoading: bool,
    }

    handleEvent = (type, depot = null) => e => {
        this.props.persist(type)(e.target.value)
        if (depot !== null) {
            const depotNamePt = depot.filter(dep => dep.id === e.target.value)

            this.props.persist('depotNamePt')(depotNamePt[0].text || '')
        }
        if (type === 'selectedPerformanceNumberPt') {
            this.props.resetCheckAllAndErrors()
        }
        if (type === 'radio') {
            this.props.resetCheckAllAndErrors()
        }
        if (type === 'orgPt') {
            this.props.resetCheckAllAndErrors()
        }
        if (type === 'depotPt') {
            this.props.resetCheckAllAndErrors()
        }
    }

    constructor() {
        super()
        this.state = {}
    }

    render() {
        const {
            store,
            classes,
            depotPt,
            orgPt,
            selectedDatePt,
            selectedPerformanceNumberPt,
            translate,
            loadingPerformance,
            loadPerformanceNumbers,
            performanceNumbersData,
            handleDateChange,
            radio,
            loadPerformanceForList,
            selectedPerformanceForJson,
            depotNamePt,
            setSelectedJsonPerformance,
            selectedJsonPerformance,
            selectedPerformanceForJsonLoading,
        } = this.props
        const districts = store.get(storeKey) || []

        const tcts = districts.reduce(
            (aggregrate, district) => [...aggregrate, ...district.tcts.map(tct => ({ id: `${tct.tctId}`, text: tct.id }))],
            [{ id: ' ', text: 'documents_label_select_org' }]
        )

        let chosenDistrict = districts.find(district => some(district.tcts, { tctId: parseInt(orgPt, 10) })) || {
            tcts: [],
        }

        const chosenTct = chosenDistrict.tcts.find(tct => tct.tctId === parseInt(orgPt, 10)) || {
            depots: [],
        }

        const depots = chosenTct.depots.reduce(
            (aggregate, depot) => [...aggregate, { id: `${depot.depotId}`, text: depot.id }],
            [{ id: ' ', text: 'documents_label_select_depot' }]
        )

        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Typography variant="h6">{translate('performance_task_add_label_select_performance')}</Typography>
                </Grid>

                <React.Fragment>
                    <Grid item xs={3}>
                        <DatePickerWithKeyboard
                            label="DatePicker"
                            value={selectedDatePt}
                            onChange={e => handleDateChange(e)}
                            format="dd/MM/yyyy"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{ display: 'inline-block' }}>
                            <SelectInput
                                sorted
                                name="org"
                                classes={classes}
                                items={tcts.map(tct => ({ ...tct, text: translate(tct.text) }))}
                                value={orgPt}
                                onChange={this.handleEvent('orgPt')}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{ display: 'inline-block' }}>
                            <SelectInput
                                sorted
                                name="depot"
                                classes={classes}
                                items={depots.map(depot => ({ ...depot, text: translate(depot.text) }))}
                                value={orgPt === ' ' ? ' ' : depotPt}
                                onChange={this.handleEvent('depotPt', depots)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        {depotPt !== ' ' && depotPt && (
                            <PerformanceSelect
                                depot={depotPt}
                                handleEvent={this.handleEvent}
                                selectedPerformanceNumberPt={selectedPerformanceNumberPt}
                                loadingPerformance={loadingPerformance}
                                loadPerformanceNumbers={loadPerformanceNumbers}
                                performanceNumbersData={performanceNumbersData}
                                classes={classes}
                                depots={chosenTct}
                            />
                        )}
                    </Grid>
                    <div>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="Gender"
                                name="radio"
                                style={{ display: 'block' }}
                                value={radio}
                                onChange={this.handleEvent('radio')}
                            >
                                <FormControlLabel
                                    value="Original"
                                    control={<Radio color="primary" />}
                                    label={translate('performance_original')}
                                />
                                <FormControlLabel
                                    value="Actual"
                                    control={<Radio color="primary" />}
                                    label={translate('performance_actual')}
                                />
                            </RadioGroup>
                        </FormControl>
                        <PrimaryButton
                            className={classes.loadButton}
                            title={translate('add_task_load_train')}
                            onClick={() =>
                                loadPerformanceForList(selectedDatePt, depotNamePt, selectedPerformanceNumberPt, radio)
                            }
                            disabled={selectedPerformanceForJsonLoading || selectedPerformanceNumberPt.length <= 1}
                        />
                    </div>
                    <Grid item xs={12}>
                        {selectedPerformanceForJson && selectedPerformanceForJson.length > 0 && (
                            <div className={classes.textField}>
                                {selectedPerformanceForJson.map((obj, index) => (
                                    <Typography
                                        className={selectedJsonPerformance === index ? classes.selectedRow : classes.row}
                                        key={`${obj.idfNumber}-${obj.name}`}
                                        variant="h6"
                                        onClick={() => setSelectedJsonPerformance(index)}
                                    >{`${obj.name} - ${obj.idfNumber}`}</Typography>
                                ))}
                            </div>
                        )}
                    </Grid>
                </React.Fragment>
            </React.Fragment>
        )
    }
}

export default compose(withStore, withLocalize, withStyles(styles))(SelectPerformanceForTasks)
