import axios from 'axios'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

// eslint-disable-next-line no-unused-vars
export const postMonitoringActions = async (testCase, status, details, action) => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/monitoring/testcase/${testCase}/status/${status}/actionstobeperformed`
    const data = {
        status: status,
        action: action,
        details: details,
    }
    try {
        const response = await axios.post(fullUrl, {
            headers: {
                'Ocp-Apim-Subscription-Key': subscriptionKey,
                'Content-Type': 'application/json',
            },
            ...data,
        })
        if (response.status === 200) {
            return response.data
        }
        if (response.status === 204) {
            return false
        } else {
            return false
        }
    } catch (error) {
        console.log(error)
    }
}
