import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import { Typography } from '@material-ui/core'

const StatusLabel = ({ status, classes, isAbolished, changeStatusOnClick, startEnd, disableButtons }) => {
    if (isAbolished)
        return (
            <div data-testid="abolished_task" className={classes.redLabel}>
                <Typography>
                    <Translate id="performance_task_label_abolished" />
                </Typography>
            </div>
        )
    const getDisable = () => {
        if (!disableButtons) return classes.clickAble
    }

    switch (status) {
        case 1:
            return (
                <div
                    data-testid="label_started"
                    className={!startEnd ? `${classes.blueLabel} ${getDisable()}` : classes.blueLabel}
                    onClick={changeStatusOnClick}
                >
                    <Typography className={classes.blackText}>
                        <Translate id="performance_task_label_started" />
                    </Typography>
                </div>
            )
        case 2:
            return (
                <div
                    data-testid="label_finished"
                    className={!startEnd ? `${classes.greenLabel} ${getDisable()}` : classes.greenLabel}
                    onClick={changeStatusOnClick}
                >
                    <Typography>
                        <Translate id="performance_task_label_finished" />
                    </Typography>
                </div>
            )

        case 0:
        default:
            return (
                <div
                    data-testid="label_to_start"
                    className={!startEnd ? `${classes.grayLabel} ${getDisable()}` : classes.grayLabel}
                    onClick={!disableButtons ? changeStatusOnClick : null}
                >
                    <Typography className={classes.blackText}>
                        <Translate id="performance_task_label_to_start" />
                    </Typography>
                </div>
            )
    }
}
StatusLabel.propTypes = {
    status: PropTypes.number,
    classes: PropTypes.object,
    isAbolished: PropTypes.bool,
    changeStatusOnClick: PropTypes.func,
    startEnd: PropTypes.bool,
    disableButtons: PropTypes.bool,
}
export default withStyles(styles)(StatusLabel)
