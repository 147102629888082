export default theme => ({
    icon: {
        fill: theme.customColors.white,
        // marginRight: theme.spacing(3)
    },
    divider: {
        height: '2px',
    },
    editIcon: {
        marginLeft: '50px',
        fill: theme.customColors.white,
        cursor: 'pointer',
    },
})
