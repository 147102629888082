import axios from 'axios'
import { ArrayToSpaceString } from './esemes.util'

const requestData = {
    url: process.env.REACT_APP_API_PATH,
    subscriptionKey: process.env.REACT_APP_SUBKEY,
}

// eslint-disable-next-line no-unused-vars
export const getESemesMessages = async (date, lower, upper, pubIds) => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/semes/messages`
    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
            params: {
                dateTime: date,
                lower,
                upper,
                pubus: ArrayToSpaceString(pubIds),
            },
        })
        if (response.status === 200 && response.data.length > 0) {
            return response.data
        }
        if (response.status === 204) {
            return false
        } else {
            return false
        }
    } catch (error) {
        console.log(error)
    }
}

export const getPubuids = async () => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/legacyReferenceData/pubus`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 200) {
            return response.data
        }
        if (response.status === 204) {
            return false
        } else {
            return false
        }
    } catch (error) {
        console.log(error)
    }
}

export const getDistricts = async () => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/legacyReferenceData/districts`

    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
        })
        if (response.status === 200) {
            return response.data
        } else {
            return false
        }
    } catch (error) {
        console.log(error)
    }
}

export const getSemesReadStatusBySemesId = async (
    semesId,
    date,
    lower,
    upper,
    districts,
    lineSectionId,
    validityPeriodId
) => {
    const { url, subscriptionKey } = requestData
    const fullUrl = `${url}/semes/messages/${semesId}/readstatus`
    try {
        const response = await axios.get(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
            },
            params: {
                dateTime: date,
                lower,
                upper,
                districts,
                lineSectionId,
                validityPeriodId,
            },
        })
        if (response.status === 200) {
            return response.data
        } else {
            return false
        }
    } catch (error) {
        console.log(error)
    }
}
